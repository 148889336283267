import { handleActions } from 'redux-actions';
import { find } from 'lodash';
import {
  handleLocationsData,
} from '../../helpers/ExpandedLocations';

const initialState = {
  locationsData: {
    companies: [],
    locations: [],
  },
  locations: [],
  allLocations: false,
};

export default handleActions(
  {
    ADD_AD_PROGRAM_LOCATION: (state, action) => {
      const location = action.payload;
      const hasLocation = find(state.locations, location) !== undefined;

      if (hasLocation) {
        // Do not add the location twice
        return state;
      }

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        locations = locations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...locations, location],
      };
    },

    REMOVE_AD_PROGRAM_LOCATION: (state, action) => {
      const locationData = action.payload;
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    },

    REMOVE_AD_PROGRAM_ALL_LOCATIONS: (state) => {
      return {
        ...state,
        locationsData: initialState.locationsData,
        locations: initialState.locations,
      };
    },

    SET_AD_PROGRAM_ALL_LOCATIONS: (state, action) => {
      const allLocations = action.payload;
      const locations = allLocations ? [] : state.locations;

      return {
        ...state,
        allLocations,
        locations,
      };
    },

    RESET_AD_PROGRAM: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
