import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  hexToRGB,
} from '../../utils/ColorUtil';
import VibeIcon from '../VibeIcon/VibeIcon';
import viChevronDoubleLeft from '../../icons/viChevronDoubleLeft';
import viChevronDoubleRight from '../../icons/viChevronDoubleRight';
import './SwapButton.scss';

class SwapButton extends PureComponent {
  constructor(props) {
    super(props);

    const {
      color,
    } = props;

    const rgb = hexToRGB(color);

    this.state = {
      isHover: false,
      hoverBackground: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.16)`,
    };
  }

  onMouseEnter = () => {
    this.setState({
      isHover: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHover: false,
    });
  };

  render() {
    const {
      className,
      text,
      size,
      color,
      hover,
      arrowPlacement,
      arrowPosition,
      arrowOnly,
      onClick,
    } = this.props;

    const {
      isHover,
      hoverBackground,
    } = this.state;

    const showHover = hover || isHover;

    return (
      <div
        className={classNames('SwapButton', { 'arrow-only': arrowOnly }, className)}
        style={{
          width: text
            ? 'auto'
            : size,
          height: size,
          border: `1px solid ${color}`,
          background: showHover
            ? hoverBackground
            : 'transparent',
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={onClick}
      >
        {arrowPlacement === 'left' ? (
          <VibeIcon
            className="status-icon status-left"
            icon={arrowPosition === 'left'
              ? viChevronDoubleLeft
              : viChevronDoubleRight}
            color={color}
            size={16}
          />
        ) : null}

        {!arrowOnly ? (
          <span
            className="status-text"
            style={{
              color,
            }}
          >
            {text}
          </span>
        ) : null}

        {arrowPlacement === 'right' ? (
          <VibeIcon
            className="status-icon status-right"
            icon={arrowPosition === 'left'
              ? viChevronDoubleLeft
              : viChevronDoubleRight}
            color={color}
            size={16}
          />
        ) : null}
      </div>
    );
  }
}

SwapButton.propTypes = {
  /** Custom Class */
  className: PropTypes.string,
  /** Text for button */
  text: PropTypes.string,
  /** Size of button */
  size: PropTypes.number,
  /** Color of the button */
  color: PropTypes.string,
  /** When to force hover state on the button */
  hover: PropTypes.bool,
  /** Arrow placement (left/right) */
  arrowPlacement: PropTypes.oneOf([
    'left',
    'right',
  ]),
  /** Arrow position (left/right) */
  arrowPosition: PropTypes.oneOf([
    'left',
    'right',
  ]),
  /** Only show the arrow icon */
  arrowOnly: PropTypes.bool,
  /** When the button is clicked */
  onClick: PropTypes.func,
};

SwapButton.defaultProps = {
  className: null,
  text: null,
  size: 32,
  color: '#6d6db5',
  hover: false,
  arrowPlacement: 'left',
  arrowPosition: 'right',
  arrowOnly: false,
  onClick: () => {},
};

export default SwapButton;
