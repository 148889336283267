import { handleActions } from 'redux-actions';

/*
 * Reducer handles the state for the loading in the application
 */

export default handleActions(
  {
    MARK_PAGE_LOADING: (state, action) => {
      const pageLoading = action.payload;

      return {
        ...state,
        pageLoading,
      };
    },
  },
  // Initial State
  {
    pageLoading: false,
  },
);
