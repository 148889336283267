import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  find,
  size,
  clone,
  forEach,
  isUndefined,
  isArray,
  isEmpty,
  orderBy,
} from 'lodash';
import { Menu, MenuItem } from '@mui/material';
import {
  ArrowDropDown,
} from '@mui/icons-material';
import SearchInput from '../SearchInput/SearchInput';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeButton from '../VibeButton/VibeButton';
import './PageLayout.scss';

const formatSortParameter = ({ attr, direction }) => {
  if (!attr) {
    return '';
  }
  const sortObj = {};

  sortObj[attr] = direction;

  return sortObj;
};

class PageLayout extends PureComponent {
  constructor(props) {
    super(props);

    const {
      sortOptions,
      searchOptions,
    } = props;

    // Collection from search result
    this.collection = [];
    // Total items from search
    this.totalItems = 0;

    // Timeout for running search
    this.fetchTimeout = null;
    // Delay for running timeout (milliseconds)
    this.fetchTimeoutDelay = 500;

    this.state = {
      pageNumber: 1,
      hasMoreItems: false,
      showSortDropdown: false,
      showSearchDropdown: false,
      sortOption: get(sortOptions, '[0]', ''),
      searchOption: get(searchOptions, '[0]', ''),
      searchQuery: '',
      isAtBottom: false,
      isToggleOn: false,
      isFetchingItems: false,
    };
  }

  componentDidMount() {
    const {
      scrollRef,
    } = this.props;

    try {
      if (scrollRef !== null && scrollRef.addEventListener) {
        scrollRef.addEventListener('scroll', this.onScroll);
        // scrollRef.onscroll = this.onScroll;
      }
    } catch (e) {
      // Could not attach onScroll to the scrollRef
      console.error('onScroll Error', e);
    }

    this.fetchItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchOptions,
      selectedSortOption,
      collection,
      filter,
      filterSearch,
      filterParamFirst,
      filterParamLast,
      scrollRef,
      loadMore,
      refresh,
    } = this.props;

    const {
      collection: prevCollection,
      filter: prevFilter,
      filterSearch: prevFilterSearch,
      filterParamFirst: prevFilterParamFirst,
      filterParamLast: prevFilterParamLast,
      scrollRef: prevScrollRef,
      loadMore: prevLoadMore,
      refresh: prevRefresh,
      selectedSortOption: prevSelectedSortOption,
    } = prevProps;

    const {
      sortOption: currentSortOption,
      isAtBottom,
      hasMoreItems,
      isToggleOn,
      isFetchingItems,
    } = this.state;

    const {
      isAtBottom: prevIsAtBottom,
      isToggleOn: prevIsToggleOn,
    } = prevState;

    if (scrollRef !== prevScrollRef) {
      // Scroll ref was updated
      try {
        if (scrollRef !== null) {
          if (prevScrollRef !== null && prevScrollRef.removeEventListener) {
            prevScrollRef.removeEventListener('scroll', this.onScroll);
          }

          if (scrollRef.removeEventListener) {
            scrollRef.removeEventListener('scroll', this.onScroll);
          }

          if (scrollRef.addEventListener) {
            scrollRef.addEventListener('scroll', this.onScroll);
          }
        }
      } catch (e) {
        // Could not attach onScroll to the scrollRef
        console.error('onScroll Error', e);
      }

      // console.warn('Scroll Ref Updated');
    }

    if (
      (refresh === true && !prevRefresh)
      || filter !== prevFilter
      || JSON.stringify(filterSearch) !== JSON.stringify(prevFilterSearch)
      || filterParamFirst !== prevFilterParamFirst
      || filterParamLast !== prevFilterParamLast
      || selectedSortOption !== prevSelectedSortOption
      || isToggleOn !== prevIsToggleOn
      || (collection.length > 0 && JSON.stringify(collection) !== JSON.stringify(prevCollection))
    ) {
      // A filter parameter was changed, fetch items again
      const sortOption = !isEmpty(selectedSortOption)
        ? selectedSortOption
        : currentSortOption;

      const searchOption = get(searchOptions, '[0]', '');

      this.setState({
        pageNumber: 1,
        sortOption,
        searchOption,
      }, () => {
        this.collection = [];
        this.totalItems = 0;

        this.fetchItems();
      });
    } else if (loadMore && !prevLoadMore) {
      // Manually load the next set of items
      this.fetchItems();
    }

    if (isAtBottom !== prevIsAtBottom) {
      // At bottom status changed
      // console.warn('at bottom changed');

      if (isAtBottom && hasMoreItems && !isFetchingItems) {
        // Fetch more items
        this.fetchItems();
      } else if (isAtBottom && !hasMoreItems) {
        console.log('No more items...');
      }
    }
  }

  componentWillUnmount() {
    const {
      scrollRef,
    } = this.props;

    // Remove scroll event listener
    try {
      if (scrollRef !== null && scrollRef.removeEventListener) {
        scrollRef.removeEventListener('scroll', this.onScroll);
      }
    } catch (e) {
      // Could not attach onScroll to the scrollRef
      console.error('Remove onScroll Listener Error', e);
    }
  }

  onScroll = (e) => {
    const {
      target: {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
    } = e;

    const {
      isAtBottom,
    } = this.state;

    // Pad the bottom to trigger the next page before hitting the bottom
    const padBottom = 100;
    const scrollBottom = scrollTop + clientHeight;

    const atBottom = scrollBottom >= scrollHeight - padBottom;

    if (atBottom && !isAtBottom) {
      // At the bottom
      this.setState({
        isAtBottom: true,
      });
    } else if (!atBottom && isAtBottom) {
      // Not at the bottom
      this.setState({
        isAtBottom: false,
      });
    }
  };

  onSearchChanged = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      onChangeSearch,
    } = this.props;

    this.setState({
      searchQuery: value,
      pageNumber: 1,
    }, () => {
      this.collection = [];

      // Delay fetch to prevent multiple API calls
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => {
        this.fetchItems();
        onChangeSearch(value);
      }, this.fetchTimeoutDelay);
    });
  };

  onFocus = () => {
    const {
      onFocus,
    } = this.props;

    onFocus();
  };

  onToggle = () => {
    const {
      onChangeToggle,
    } = this.props;

    this.setState((state) => {
      const isToggleOn = !state.isToggleOn;

      onChangeToggle(isToggleOn);

      return {
        isToggleOn,
      };
    });
  };

  fetchItems = async () => {
    const {
      collection,
      filter,
      filterSearch,
      filterParamFirst,
      filterParamLast,
      filterProps,
      selectedCompanyId,
      onFilter,
      selectedSortOption,
      sortOptions,
      tableSort,
      pageSize,
      user,
    } = this.props;

    const {
      sortOption,
      searchOption,
      searchQuery = '',
      pageNumber,
      isToggleOn,
    } = this.state;

    const sort = formatSortParameter(
      tableSort
        ? selectedSortOption
        : ((option => {
          const attr = option.attr || sortOptions[0].attr;

          let {
            direction,
          } = option;

          if (!direction && (
            sortOption.attr === 'modifiedDate'
            || sortOption.attr === 'createdDate'
            || sortOption.attr === 'playerOnline'
          )) {
            direction = 'desc';
          } else if (!direction) {
            // Default to Ascending order
            direction = 'asc';
          }

          return {
            attr,
            direction,
          };
        }))(sortOption));

    const {
      attr,
    } = searchOption;

    const filterOptions = {
      ps: pageSize,
      pn: pageNumber,
      pageNumber,
      pageSize,
      ...filterProps.fetchFilters,
    };

    let filterFunc;
    const filters = clone(get(filterProps, 'filters', {}));

    filters.active = !isToggleOn;

    if (searchQuery) {
      filters[attr] = searchQuery;
    } else if (!searchQuery && filters[attr]) {
      // clear the search query
      delete filters[attr];
    }

    filterOptions.filters = filters;
    filterOptions.sort = sort;

    if (filterSearch) {
      // Add custom filters from the component
      forEach(filterSearch, (filter, key) => {
        filterOptions.filters[key] = filter;
      });
    }

    if (collection.length > 0 || !filter) {
      // No filter function defined
      this.filterCollection();
      return;
    }

    if (filterParamFirst === 'skip' || filterParamLast === 'skip') {
      // Do not fetch if the filter parameters are set to skip
      return;
    }

    if (filterProps.requireFilter) {
      // Only request data if there are search filters (other than "active")
      // Originally added for Message Blocks because requesting all was too high a server load
      const hasFilters = size(filterOptions.filters) > 1;

      if (!hasFilters) {
        console.log('this page requires search filters before requesting from the API');

        this.setState({
          isFetchingItems: false,
          pageNumber: 1,
        });

        onFilter({
          collection: [],
          totalItems: 0,
        });

        return;
      }
    }

    let firstFilterParam = null;
    if ((user.companyId !== selectedCompanyId) && selectedCompanyId) {
      firstFilterParam = selectedCompanyId;
    } else {
      firstFilterParam = filterParamFirst;
    }

    if (firstFilterParam) {
      filterFunc = filter(firstFilterParam, filterOptions);
    } else if (filterParamLast) {
      filterFunc = filter(filterOptions, filterParamLast);
    } else {
      filterFunc = filter(filterOptions);
    }

    if (!get(filterFunc, 'then', null)) {
      // Filter function is not ready
      return;
    }

    this.setState({
      isFetchingItems: true,
    });

    filterFunc.then((response) => {
      const {
        data,
        pageNumber = 1,
        totalItems,
        totalPages,
        // Playlist response
        musicItems,
        musicItemsCount,
        // Blocked songs response
        blockList,
        blockListCount = get(response, 'blockList.length', 0),
      } = response;

      // Only received an array of items from API
      const arrayData = isArray(response) ? response : null;

      const {
        searchQuery: newSearchQuery,
      } = this.state;

      if (searchQuery === newSearchQuery) {
        // Only update when the search query matches the response
        this.setState((state) => {
          const collectionItems = data || musicItems || blockList || arrayData || [];
          const totalItemCount = totalItems || musicItemsCount || blockListCount || 0;
          const totalPageCount = totalPages || 1;
          const hasMoreItems = pageNumber !== totalPageCount;

          // Send the filtered collection back to the parent
          this.collection = [...this.collection, ...collectionItems];
          this.totalItems = totalItemCount;

          onFilter({
            collection: this.collection,
            totalItems: this.totalItems,
            sortOption,
            hasMoreItems,
          });

          return {
            isFetchingItems: false,
            pageNumber: state.pageNumber + 1,
            hasMoreItems,
          };
        });
      }

      // this.setState({
      //   isFetchingItems: false,
      // });
    });
  };

  selectSortOption = (option) => {
    const {
      sortOptions,
    } = this.props;

    const sortOption = find(sortOptions, option);

    this.setState({
      sortOption,
      pageNumber: 1,
    }, () => {
      this.collection = [];
      this.fetchItems();
    });
    this.closeSortDropdown();
  };

  openSortDropdown = () => {
    this.setState({
      showSortDropdown: true,
    });
  };

  closeSortDropdown = () => {
    this.setState({
      showSortDropdown: false,
    });
  };

  selectSearchOption = (option) => {
    this.setState({
      searchOption: option,
      pageNumber: 1,
    }, () => {
      this.collection = [];
      this.fetchItems();
    });

    this.closeSearchDropdown();
  };

  openSearchDropdown = () => {
    this.setState({
      showSearchDropdown: true,
    });
  };

  closeSearchDropdown = () => {
    this.setState({
      showSearchDropdown: false,
    });
  };

  filterCollection = () => {
    const {
      collection,
      onFilter,
      selectedSortOption,
    } = this.props;

    const {
      sortOption: currentSortOption,
      searchOption,
      searchQuery = '',
    } = this.state;
    const {
      attr,
    } = searchOption;

    const sortOption = !isEmpty(selectedSortOption)
      ? selectedSortOption.attr
      : currentSortOption.attr;

    const direction = !isEmpty(selectedSortOption)
      ? selectedSortOption.direction
      : currentSortOption.direction;

    if (collection.length) {
      const query = searchQuery.toLowerCase();
      const filteredCollection = [];

      collection.forEach((item) => {
        item = isUndefined(item) ? {} : item;
        const itemAttr = (item[attr] || '').toLowerCase();

        if (itemAttr.indexOf(query) >= 0) {
          // Collection item matches search query
          filteredCollection.push(item);
        }
      });

      const sortedAndFiltered = orderBy(filteredCollection, [sortOption], [direction]);

      // Send the filtered collection back to the parent
      onFilter({
        collection: sortedAndFiltered,
        totalItems: collection.length,
        hasMoreItems: false,
      });
    } else {
      console.log('Collection is empty');
    }
  };

  render() {
    const {
      className,
      disableSort,
      disableSearch,
      autoFocus,
      placeholder,
      tableSort,
      sortOptions,
      searchOptions,
      searchTheme,
      searchStyle,
      preIcons,
      layout,
      customComponent,
      showToggle,
      toggleTooltip,
      toggleLabel,
      align,
      btn,
      btnText,
      btnIcon,
      btnLink,
      onBtnClick,
    } = this.props;

    const {
      showSortDropdown,
      showSearchDropdown,
      sortOption,
      searchOption,
      searchQuery,
      isToggleOn,
      isFetchingItems,
    } = this.state;

    const sortMenuItems = [];

    sortOptions.forEach((option, index) => {
      sortMenuItems.push(
        <MenuItem
          key={index}
          className="menu-item"
          onClick={() => { this.selectSortOption(option); }}
        >
          {option.label}
        </MenuItem>,
      );
    });

    const sortOptionsHtml = (
      <div className="dropdown-container">
        <div
          className="label-dropdown"
          aria-haspopup="true"
          ref={(ref) => { this.sortDropdownRef = ref; }}
          onClick={this.openSortDropdown}
        >
          <div className="label-name">
            {sortOption.label || 'Unknown'}
          </div>

          <ArrowDropDown className="icon" />
        </div>

        <Menu
          id="label-menu"
          className="PageLayoutDropdown"
          classes={{
            paper: 'PageLayoutPaper',
          }}
          anchorEl={this.sortDropdownRef}
          open={showSortDropdown}
          onClose={this.closeSortDropdown}
        >
          {sortMenuItems}
        </Menu>
      </div>
    );

    const searchMenuItems = [];

    searchOptions.forEach((option, index) => {
      searchMenuItems.push(
        <MenuItem
          key={index}
          className="menu-item"
          onClick={() => { this.selectSearchOption(option); }}
        >
          {option.label}
        </MenuItem>,
      );
    });

    const searchOptionsHtml = (
      <div className="dropdown-container">
        <div
          className="label-dropdown"
          aria-haspopup="true"
          ref={(ref) => { this.searchDropdownRef = ref; }}
          onClick={this.openSearchDropdown}
        >
          <div className="label-name">
            {searchOption.label}
          </div>

          <ArrowDropDown className="icon" />
        </div>

        <Menu
          id="label-menu"
          className="PageLayoutDropdown"
          classes={{
            paper: 'PageLayoutPaper',
          }}
          anchorEl={this.searchDropdownRef}
          open={showSearchDropdown}
          onClose={this.closeSearchDropdown}
        >
          {searchMenuItems}
        </Menu>
      </div>
    );

    return (
      <div className={`PageLayout layout-${layout} align-${align} ${className}`}>
        <div className="card-options-container">
          {preIcons.map((preIcon) => {
            return preIcon;
          })}

          {!disableSort && !tableSort ? (
            <div className="sorting-options option">
              <p className="option-title">
                Sort
              </p>

              {sortOptionsHtml}
            </div>
          ) : null}

          {!disableSearch ? (
            <div
              className="search-options option"
              style={searchStyle}
            >
              <p className="option-title">
                Search By
              </p>

              {searchOptionsHtml}

              <div className="search-container">
                <SearchInput
                  theme={searchTheme}
                  value={searchQuery}
                  autoFocus={autoFocus}
                  placeholder={placeholder}
                  loading={isFetchingItems}
                  onFocus={this.onFocus}
                  onChange={this.onSearchChanged}
                />
              </div>
            </div>
          ) : null}

          {showToggle ? (
            <div className="toggle-option option">
              {!toggleTooltip ? (
                <div className="toggle-container">
                  <label htmlFor="layout-toggle" className="switch">
                    <p className="option-title">
                      {toggleLabel}
                    </p>
                  </label>
                  <input
                    id="layout-toggle"
                    className="switch__input"
                    type="checkbox"
                    onChange={this.onToggle}
                    checked={isToggleOn}
                  />
                  <div
                    className="switch__checkbox"
                    onClick={this.onToggle}
                  />
                </div>
              ) : (
                <VibeTooltip
                  title={toggleLabel}
                  placement="left"
                >
                  <div className="toggle-container">
                    <input
                      id="layout-toggle"
                      className="switch__input"
                      type="checkbox"
                      onChange={this.onToggle}
                      checked={isToggleOn}
                    />
                    <div
                      className="switch__checkbox"
                      onClick={this.onToggle}
                    />
                  </div>
                </VibeTooltip>
              )}
            </div>
          ) : null}

          {btn ? (
            <div className="extra-option option">
              <VibeButton
                text={btnText}
                btnLink={btnLink}
                btnColor="green"
                textColor="white"
                icon={btnIcon}
                iconPlacement="left"
                onClick={onBtnClick}
              />
            </div>
          ) : null}

          {customComponent}
        </div>
      </div>
    );
  }
}

PageLayout.propTypes = {
  className: PropTypes.string,
  /** Theme layout */
  layout: PropTypes.oneOf([
    'white',
    'purple',
  ]),
  /** Page Size */
  pageSize: PropTypes.number,
  /** Disable Sort Options */
  disableSort: PropTypes.bool,
  /** Disable Search Options */
  disableSearch: PropTypes.bool,
  /** Auto focus the search box */
  autoFocus: PropTypes.bool,
  /** Placeholder for the search input */
  placeholder: PropTypes.string,
  /** Table Sort Only */
  tableSort: PropTypes.bool,
  /** List of sortable fields */
  sortOptions: PropTypes.arrayOf(PropTypes.shape({
    /** Text to display in dropdown */
    label: PropTypes.string,
    /** Object attribute to filter on */
    attr: PropTypes.string,
    /** Direction to sort by */
    direction: PropTypes.string,
  })),
  /** List of searchable fields */
  searchOptions: PropTypes.arrayOf(PropTypes.shape({
    /** Text to display in dropdown */
    label: PropTypes.string,
    /** Object attribute to filter on */
    attr: PropTypes.string,
  })),
  /** Search theme */
  searchTheme: PropTypes.oneOf([
    'light',
    'dark',
  ]),
  /** Search Style */
  searchStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected Sort Option for Table View */
  selectedSortOption: PropTypes.shape({
    /** Text to display in dropdown */
    label: PropTypes.string,
    /** Object attribute to filter on */
    attr: PropTypes.string,
    /** Direction to sort by */
    direction: PropTypes.string,
  }),
  /** Collection to filter search query on */
  collection: PropTypes.arrayOf(PropTypes.object),
  /** Scroll Ref element */
  scrollRef: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
  ]),
  /** Icons to show at the start of the layout toolbar */
  preIcons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Manually load the next page of items */
  loadMore: PropTypes.bool,
  /** Show toggle */
  showToggle: PropTypes.bool,
  /** Show Toggle Tooltip */
  toggleTooltip: PropTypes.bool,
  /** Toggle label */
  toggleLabel: PropTypes.string,
  /** Align layout to the left or right */
  align: PropTypes.oneOf([
    'left',
    'right',
  ]),
  /** Extra Button Stuff */
  btn: PropTypes.bool,
  btnText: PropTypes.string,
  btnIcon: PropTypes.oneOfType([
    PropTypes.element,
  ]),
  /** Link for button */
  btnLink: PropTypes.string,
  onBtnClick: PropTypes.func,
  /** Filter search to the API function passed in filter prop */
  filterSearch: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** 1st paramater to call on the filter function */
  filterParamFirst: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      filters: PropTypes.string,
    }),
  ]),
  /** Last paramater to call on the filter function */
  filterParamLast: PropTypes.string,
  /** Function to call when searching */
  filter: PropTypes.func,
  /** Props for the filter function */
  filterProps: PropTypes.shape({
    requireFilter: PropTypes.bool,
    fetchFilters: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    filters: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Company Id for Filter Func */
  selectedCompanyId: PropTypes.string,
  /** Custom component to show next to the search input */
  customComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** When the collection has been filtered */
  onFilter: PropTypes.func,
  /** When the search box has focus */
  onFocus: PropTypes.func,
  /** When the toggle is changed */
  onChangeToggle: PropTypes.func,
  /** When the search input is changed */
  onChangeSearch: PropTypes.func,
};

PageLayout.defaultProps = {
  className: 'page-container',
  layout: 'white',
  pageSize: 50,
  disableSort: false,
  disableSearch: false,
  autoFocus: false,
  placeholder: 'Search...',
  tableSort: false,
  sortOptions: [{}],
  searchOptions: [{}],
  searchTheme: 'light',
  searchStyle: {},
  selectedSortOption: {},
  collection: [],
  scrollRef: <div />,
  preIcons: [],
  loadMore: false,
  showToggle: false,
  toggleTooltip: true,
  toggleLabel: '',
  align: 'left',
  btn: false,
  btnText: '',
  btnIcon: null,
  btnLink: null,
  onBtnClick: () => {},
  filterSearch: null,
  filterParamFirst: null,
  filterParamLast: null,
  filter: null,
  filterProps: {
    requireFilter: false,
    fetchFilters: {},
    filters: {},
  },
  selectedCompanyId: '',
  customComponent: null,
  onFilter: () => {},
  onFocus: () => {},
  onChangeToggle: () => {},
  onChangeSearch: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(PageLayout);
