// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageRequestsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 80px;
}
.MessageRequestsContainer.hide-side-menu {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Messages/Request/RequestsContainer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;AACF;AACE;EACE,cAAA;AACJ","sourcesContent":[".MessageRequestsContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  margin-left: 80px;\n\n  &.hide-side-menu {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
