import { handleActions } from 'redux-actions';

/*
 * Reducer handles the state for the locations */
const initialState = {
  editLocation: false,
  currentLocation: null,
  selectedLocationId: '',
};

export default handleActions(
  {
    EDIT_LOCATION: (state, action) => {
      const editLocation = action.payload;

      return {
        ...state,
        editLocation,
      };
    },

    SET_CURRENT_LOCATION: (state, action) => {
      const location = action.payload;

      return {
        ...state,
        currentLocation: {
          tags: [],
          ...location,
        },
      };
    },

    UPDATE_CURRENT_LOCATION: (state, action) => {
      const location = action.payload;

      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          ...location,
        },
      };
    },

    UPDATE_LOCATION_COMPANY: (state, action) => {
      const company = action.payload;

      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          company,
        },
      };
    },

    SAVE_LOCATION_DETAILS: state => {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          company: {},
        },
      };
    },

    SET_SELECTED_LOCATION_ID: (state, action) => {
      const selectedLocationId = action.payload;

      return {
        ...state,
        selectedLocationId,
      };
    },

    RESET_CURRENT_LOCATION: (state) => {
      return {
        ...state,
        currentLocation: initialState.currentLocation,
      };
    },
  },
  // Initial State
  initialState,
);
