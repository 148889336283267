import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  NavigationSide,
  NavigationSideItem,
  viStore,
  viActivityFeed,
  viAlert,
  viCloseCircle,
  viFolder,
} from 'vibeguide';

function LocationNavigation({
  user,
  className,
}) {
  return (
    <NavigationSide className={classNames('LocationNavigation', className)}>
      <NavigationSideItem
        text="Basic Info"
        url="/locations"
        icon={viStore}
        show={user.can('location.view')}
      />

      <NavigationSideItem
        text="Integration Location Files"
        url="/integration/files"
        icon={viFolder}
        show={user.can('location_control_file.view')}
      />

      <NavigationSideItem
        text="Support Dashboard"
        url="/dashboard/support"
        icon={viActivityFeed}
        show={user.can([
          'admin.system_admin',
          'location.view_telemetry',
        ])}
      />

      <NavigationSideItem
        text="Alerts Dash"
        url="/dashboard/alerts"
        matchUrl="/alerts"
        icon={viAlert}
        show={user.can('alert.view')}
      />

      <NavigationSideItem
        text="Excluded Locations"
        url="/dashboard/excluded"
        matchUrl="/excluded"
        icon={viCloseCircle}
        show={user.can('alert.view')}
      />

    </NavigationSide>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(LocationNavigation);
