import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  find,
  uniqueId,
  isUndefined,
} from 'lodash';
import {
  API,
  TimeUtil,
  ToastActions,
  VibeButton,
} from 'vibeguide';
import WeekDates from '../../../../Locations/IndividualLocation/Schedule/Controls/WeekDates';
import ScheduleData from '../../../../Locations/IndividualLocation/Schedule/ScheduleData';
import MusicOverrideEditor from '../../../../Locations/IndividualLocation/Schedule/MusicOverride/MusicOverrideEditor';
import './ScheduleOverrideEditor.scss';

class ScheduleOverrideEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      playlists: [],
      mixes: [],
      musicOverrideDay: null,
      showMusicOverrideEditor: false,
    };
  }

  componentDidMount() {
    this.populateOverrideSchedule();
  }

  onSaveAndExit = async () => {
    const {
      onClose,
    } = this.props;

    await this.save();

    onClose();
  };

  /**
   * When editing the music override, open the editor when the day is clicked
   */
  onOpenOverrideEditor = (day) => {
    this.setState({
      musicOverrideDay: day,
      showMusicOverrideEditor: true,
    });
  };

  /**
   * Close the editor
   */
  onCloseOverrideEditor = () => {
    this.setState({
      musicOverrideDay: null,
      showMusicOverrideEditor: false,
    });
  };

  /**
   * When copying override items to other days
   */
  onCopyToDays = ({
    copyToDays,
    items,
  }) => {
    this.setState((state) => {
      if (!copyToDays.includes(state.musicOverrideDay)) {
        // automatically include the day being copied from to update any changes to the items
        copyToDays.push(state.musicOverrideDay);
      }

      const overridePlaylists = items.filter(item => !isUndefined(item.playlist));
      const overrideMixes = items.filter(item => !isUndefined(item.mix));

      let copyPlaylists = [];
      let copyMixes = [];

      copyToDays.forEach((day) => {
        const addPlaylists = overridePlaylists.map((playlist) => {
          return {
            ...playlist,
            day,
          };
        });

        const addMixes = overrideMixes.map((mix) => {
          return {
            ...mix,
            day,
          };
        });

        copyPlaylists = [
          ...copyPlaylists,
          ...addPlaylists,
        ];
        copyMixes = [
          ...copyMixes,
          ...addMixes,
        ];
      });

      // playlists/mixes that aren't being copied
      const otherPlaylists = state.playlists.filter(playlist => !copyToDays.includes(playlist.day));
      const otherMixes = state.mixes.filter(mix => !copyToDays.includes(mix.day));

      return {
        playlists: [
          ...copyPlaylists,
          ...otherPlaylists,
        ],
        mixes: [
          ...copyMixes,
          ...otherMixes,
        ],
        showMusicOverrideEditor: false,
      };
    });
  };

  /**
   * Clear a specific day of items
   */
  onClearOverrideDay = () => {
    this.setState((state) => {
      return {
        playlists: state.playlists.filter(playlist => playlist.day !== state.musicOverrideDay),
        mixes: state.mixes.filter(mix => mix.day !== state.musicOverrideDay),
        showMusicOverrideEditor: false,
      };
    });
  };

  /**
   * Save the music override
   */
  onSaveOverride = (overrideItems) => {
    const overridePlaylists = overrideItems.filter(item => !isUndefined(item.playlist));
    const overrideMixes = overrideItems.filter(item => !isUndefined(item.mix));

    this.setState((state) => {
      // other playlists/mixes not on this day
      const otherDayPlaylists = state.playlists.filter(playlist => playlist.day !== state.musicOverrideDay);
      const otherDayMixes = state.mixes.filter(mix => mix.day !== state.musicOverrideDay);

      return {
        playlists: [
          ...otherDayPlaylists,
          ...overridePlaylists.map((playlist) => {
            return {
              ...playlist,
              day: state.musicOverrideDay,
            };
          }),
        ],
        mixes: [
          ...otherDayMixes,
          ...overrideMixes.map((mix) => {
            return {
              ...mix,
              day: state.musicOverrideDay,
            };
          }),
        ],
        showMusicOverrideEditor: false,
      };
    });
  };

  getItemsForDay = (day) => {
    const {
      playlists,
      mixes,
    } = this.state;

    const dayPlaylists = playlists.filter(playlist => playlist.day === day);
    const dayMixes = mixes.filter(mix => mix.day === day);
    const musicParts = [];

    dayPlaylists.forEach((playlist) => {
      const startHour = parseInt(playlist.startTime.split(':')[0], 10);
      const startMinute = playlist.startTime.split(':')[1];
      const stopHour = parseInt(playlist.stopTime.split(':')[0], 10);
      const stopMinute = playlist.stopTime.split(':')[1];
      const startTime = `${startHour < 10 ? `0${startHour}` : startHour}:${startMinute}`;
      const endTime = `${stopHour < 10 ? `0${stopHour}` : stopHour}:${stopMinute}`;

      musicParts.push({
        musicId: playlist.playlist._id,
        type: 'playlist',
        startTime,
        endTime,
      });
    });

    dayMixes.forEach((mix) => {
      const startHour = parseInt(mix.startTime.split(':')[0], 10);
      const startMinute = mix.startTime.split(':')[1];
      const stopHour = parseInt(mix.stopTime.split(':')[0], 10);
      const stopMinute = mix.stopTime.split(':')[1];
      const startTime = `${startHour < 10 ? `0${startHour}` : startHour}:${startMinute}`;
      const endTime = `${stopHour < 10 ? `0${stopHour}` : stopHour}:${stopMinute}`;

      musicParts.push({
        musicId: mix.mix._id,
        type: 'mix',
        startTime,
        endTime,
      });
    });

    return {
      musicParts,
    };
  };

  /**
   * Populate the override schedule
   */
  populateOverrideSchedule = () => {
    const {
      schedule,
    } = this.props;

    const playlists = [];
    const mixes = [];

    const days = get(schedule, 'scheduleOverride.days', []) || [];
    const playlistRefs = get(schedule, 'refs.playlists', {});
    const mixRefs = get(schedule, 'refs.mixes', {});

    days.forEach((dayContent, index) => {
      const {
        musicParts,
      } = dayContent;

      musicParts.forEach((music) => {
        if (music.type === 'playlist') {
          const playlistDetail = find(playlistRefs, { _id: music.musicId });

          playlists.push({
            _uniqueId: `playlist-${uniqueId()}`,
            playlist: playlistDetail,
            startTime: music.startTime,
            stopTime: music.endTime,
            day: TimeUtil.getDayFromIndex(index, 'ddd'),
          });
        } else if (music.type === 'mix') {
          const mixDetail = find(mixRefs, { _id: music.musicId });

          mixes.push({
            _uniqueId: `station-${uniqueId()}`,
            mix: mixDetail,
            startTime: music.startTime,
            stopTime: music.endTime,
            day: TimeUtil.getDayFromIndex(index, 'ddd'),
          });
        }
      });
    });

    this.setState({
      playlists,
      mixes,
    });
  };

  save = async () => {
    const {
      locationId,
      queueToast,
    } = this.props;

    const data = {
      _id: locationId,
      days: [
        this.getItemsForDay('Sun'),
        this.getItemsForDay('Mon'),
        this.getItemsForDay('Tue'),
        this.getItemsForDay('Wed'),
        this.getItemsForDay('Thu'),
        this.getItemsForDay('Fri'),
        this.getItemsForDay('Sat'),
      ],
    };

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const response = await API.Location.ScheduleOverride.update(data);
    const responseType = get(response, '[0].type');
    const success = responseType === 'LOCATION.SCHEDULE_OVERRIDE_UPDATED';

    if (success) {
      queueToast({
        type: 'success',
        title: 'Saved!',
        allowClose: true,
        delay: 500,
      });
    } else {
      queueToast({
        type: 'error',
        title: 'Error Saving',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  render() {
    const {
      className,
      // companyId,
      // locationId,
    } = this.props;

    const {
      playlists,
      mixes,
      musicOverrideDay,
      showMusicOverrideEditor,
    } = this.state;

    return (
      <div className={classNames('ScheduleOverrideEditor', className)}>
        <div className="header-container">
          <div className="header">
            <div className="details">
              <div className="title">
                Current Music Override
              </div>
            </div>

            <div className="toolbar">
              <div className="toolbar-items">
                <VibeButton
                  className="item"
                  text="Save"
                  btnColor="green"
                  variant="outlined"
                  onClick={this.save}
                />

                <VibeButton
                  className="item"
                  text="Save and Exit"
                  btnColor="green"
                  textColor="white"
                  onClick={this.onSaveAndExit}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="schedule-editor">
          <WeekDates
            startOfWeek={moment().startOf('week')}
            musicOverride
          />

          <ScheduleData
            type="schedule-override"
            style={{
              height: 'calc(100% - 65px)',
            }}
            activeDisplay="24h"
            startOfWeek={moment().startOf('week')}
            playlists={playlists}
            mixes={mixes}
            musicOverride
            allowClickDay
            onClickDay={this.onOpenOverrideEditor}
          />

          {showMusicOverrideEditor && (
            <MusicOverrideEditor
              day={musicOverrideDay}
              playlists={playlists.filter(playlist => playlist.day === musicOverrideDay)}
              mixes={mixes.filter(mix => mix.day === musicOverrideDay)}
              onCopyToDays={this.onCopyToDays}
              onClearDay={this.onClearOverrideDay}
              onClose={this.onCloseOverrideEditor}
              onSave={this.onSaveOverride}
            />
          )}
        </div>
      </div>
    );
  }
}

ScheduleOverrideEditor.propTypes = {
  className: PropTypes.string,
  // companyId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

ScheduleOverrideEditor.defaultProps = {
  className: '',
  onClose: () => {},
};

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(null, mapDispatchToProps)(ScheduleOverrideEditor);
