import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isUndefined,
} from 'lodash';
import {
  GlobalActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  TableCompanies,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import AllocationLocations from './AllocationLocations';
import './AllocationCompanies.scss';

function AllocationCompanies({
  className,
  companies,
  setPanel,
  onClose,
}) {
  // set the active attribute on each company if not present
  // used for a fix for tables with a local collection of rows (active must be present)
  companies.forEach((company) => {
    if (isUndefined(company.active)) {
      company.active = true;
    }
  });

  const onClosePanel = () => {
    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);

    onClose();
  };

  const onClickCompany = (row) => {
    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <AllocationLocations
            companyName={row.name}
            locations={row.locations}
          />
        ),
      },
    });
  };

  return (
    <SidePanelContainer className={classNames('AllocationCompanies', className)}>
      <SidePanelHeader
        className="sidepanel-header"
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClosePanel}
          />
        )}
      >
        <div className="title">
          Allocations - Companies
        </div>
      </SidePanelHeader>

      <SidePanelContent className="sidepanel-content">
        <TableCompanies
          columns={[
            'Company (Text Only)',
            'Target Impressions',
            'Total Impressions',
            'Total Spots',
            'Frequency',
            '...',
          ]}
          collection={companies}
          defaultSortBy={{
            label: 'Company',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Companies',
            urlPaging: false,
            urlFilters: false,
          }}
          onClickRow={onClickCompany}
        />
      </SidePanelContent>
    </SidePanelContainer>
  );
}

AllocationCompanies.propTypes = {
  className: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  onClose: PropTypes.func,
};

AllocationCompanies.defaultProps = {
  className: '',
  companies: [],
  onClose: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(AllocationCompanies);
