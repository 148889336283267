import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/users`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  getLocations: (data, options) => Http.post(`${baseUrl}/${data._id}/locations`, data, options),
  getCompanies: (data, options) => Http.post(`${baseUrl}/${data._id}/companies`, data, options),
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  create: data => Http.post(baseUrl, data),
  modify: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  changePassword: (data) => Http.post(`${baseUrl}/${data._id}/password`, data),
  uploadImage: (id, file) => Http.postFileUpload(`${baseUrl}/${id}/image`, file, 'image'),
  listVoiceTalent: options => Http.get(`${baseUrl}/voicetalent`, options),
  bulkAssignPermissionSet: (data) => Http.put(`${baseUrl}/permissions`, data),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (users) => Http.delete(baseUrl, {
    users,
  }),

  // Get all users matching permissions
  Permission: {
    // match "any" permission
    getAny: (permissions, options) => {
      const data = {
        pageSize: -1,
        filters: {
          active: true,
          permissions: {
            matchType: 'any',
            permissions,
          },
        },
      };

      return Http.post(`${baseUrl}/search`, data, options);
    },
    // match "all" permissions
    getAll: (permissions, options) => {
      const data = {
        pageSize: -1,
        filters: {
          active: true,
          permissions: {
            matchType: 'all',
            permissions,
          },
        },
      };

      return Http.post(`${baseUrl}/search`, data, options);
    },
  },
};
