import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import {
  Close,
  Lock,
  LockOpen,
} from '@mui/icons-material';
import defaultPlaylistImage from '../../assets/default_playlist.png';
import color from '../../sass/color.scss';
import { updateMixPlaylist, removeMixPlaylist } from '../../actions/Mix/NewMixActions';
import './MixPlaylist.scss';

function drawActive(activeBorderRef, handleRef, endPercent, i = 0) {
  if (endPercent < 0) {
    endPercent = 0;
  } else if (endPercent > 100) {
    endPercent = 100;
  }

  const current = (100 * i) / 360;
  const borderCss = i <= 180
    ? `background-image:
        linear-gradient(${i + 90}deg, transparent 50%, #fff 50%),
        linear-gradient(90deg, #fff 50%, transparent 50%)`
    : `background-image:
        linear-gradient(${i - 90}deg, transparent 50%, ${color.aquaForest} 50%),
        linear-gradient(90deg, #fff 50%, transparent 50%)`;

  activeBorderRef.style.cssText = borderCss;
  handleRef.style.cssText = `transform: rotate(${i - 90}deg);`;

  if (current < endPercent) {
    setTimeout(() => {
      drawActive(activeBorderRef, handleRef, endPercent, ++i);
    }, 1);
  }
}

class MixPlaylist extends Component {
  constructor(props) {
    super(props);

    const {
      playlist: {
        mixPercent,
      },
    } = this.props;

    this.sliderRef = React.createRef();
    this.handleRef = React.createRef();
    this.activeBorderRef = React.createRef();

    // The starting point for the slider handle (default to top)
    this.startOffset = 270;
    // When the handle is active (on mouse down)
    this.handleActive = false;

    this.state = {
      // The percentage the playlist is used in the mix
      mixPercent,
    };
  }

  componentDidMount() {
    const {
      mixPercent,
    } = this.state;

    const {
      handleRef: {
        current: handleRef,
      },
      activeBorderRef: {
        current: activeBorderRef,
      },
    } = this;

    // Animate the slider to the playlist mix percentage
    drawActive(activeBorderRef, handleRef, mixPercent);
  }

  componentDidUpdate(prevProps) {
    const {
      playlist: {
        mixPercent,
      },
    } = this.props;

    const {
      mixPercent: stateMixPercent,
    } = this.state;

    const {
      playlist: {
        mixPercent: prevMixPercent,
      },
    } = prevProps;

    const {
      handleRef: {
        current: handleRef,
      },
      activeBorderRef: {
        current: activeBorderRef,
      },
    } = this;

    if (mixPercent !== prevMixPercent && mixPercent !== stateMixPercent) {
      // Mix percentage has changed
      // Animate the slider to the playlist mix percentage
      drawActive(activeBorderRef, handleRef, mixPercent);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        mixPercent,
      });
    }
  }

  /**
   * When the handle is selected
   */
  onMouseDownHandle = (e) => {
    e.preventDefault();

    const {
      allowChanges,
    } = this.props;

    if (this.handleActive || !allowChanges) {
      return;
    }

    this.handleActive = true;

    // When the user moves the handle
    document.addEventListener('mousemove', this.onMouseMoveHandle, false);

    // When the user lets go of the handle
    document.addEventListener('mouseup', this.onMouseUpHandle, false);
  };

  /**
   * When the handle is deselected
   */
  onMouseUpHandle = (e) => {
    const {
      playlist: {
        _id,
      },
      updateMixPlaylist,
    } = this.props;

    const {
      mixPercent: percentage,
    } = this.state;

    console.log('%%%', percentage);

    this.handleActive = false;

    updateMixPlaylist({
      playlistId: _id,
      mixPercent: percentage,
    });

    // Remove events when the user stops moving the handle
    document.removeEventListener('mousemove', this.onMouseMoveHandle, false);

    // Remove this event handler after firing
    e.currentTarget.removeEventListener('mouseup', this.onMouseUpHandle, false);
  };

  /**
   * When the handle is moved
   */
  onMouseMoveHandle = (e) => {
    if (!this.handleActive) {
      // Move handle is not active
      return;
    }

    const {
      playlist: {
        mixPercentMax,
      },
    } = this.props;

    const {
      handleRef: {
        current: handleRef,
      },
      activeBorderRef: {
        current: activeBorderRef,
      },
    } = this;

    const angle = this.getRawAngle(e);
    const outputAngle = this.formatOutputAngle(angle);
    const mixPercent = this.getPercentFromAngle(outputAngle);

    if (mixPercent > mixPercentMax) {
      // Mix percentage cannot exceed its maximum
      console.warn('mix percent is maxed');
      return;
    }

    // Move the handle
    handleRef.style.cssText = `transform: rotate(${angle}deg);`;

    const borderCss = outputAngle <= 180
      ? `background-image:
          linear-gradient(${outputAngle + 90}deg, transparent 50%, #fff 50%),
          linear-gradient(90deg, #fff 50%, transparent 50%)`
      : `background-image:
          linear-gradient(${outputAngle - 90}deg, transparent 50%, ${color.aquaForest} 50%),
          linear-gradient(90deg, #fff 50%, transparent 50%)`;

    activeBorderRef.style.cssText = borderCss;

    this.setState({
      mixPercent,
    });
  };

  getPercentFromAngle(angle) {
    return Math.ceil((angle / 360) * 100);
  }

  getCenter(elem) {
    const rect = elem.getBoundingClientRect();
    return {
      x: rect.left + (rect.width / 2),
      y: rect.top + (rect.height / 2),
    };
  }

  getRawAngle = (e) => {
    const {
      sliderRef: {
        current: sliderRef,
      },
    } = this;

    const pivot = this.getCenter(sliderRef);

    const mouse = {
      x: e.clientX,
      y: e.clientY,
    };

    const angle = (this.radToDeg(Math.atan2(mouse.y - pivot.y, mouse.x - pivot.x))) % 360;
    return angle;
  };

  formatOutputAngle = (angle) => {
    const outputAngle = this.modulo(((360 + Math.round(angle)) - this.startOffset), 360);
    return outputAngle;
  };

  toggleLock = () => {
    const {
      playlist: {
        _id,
        locked,
      },
      allowChanges,
      updateMixPlaylist,
    } = this.props;

    if (!allowChanges) {
      return;
    }

    updateMixPlaylist({
      playlistId: _id,
      locked: !locked,
    });
  };

  removePlaylist = () => {
    const {
      playlist: {
        _id,
      },
      removeMixPlaylist,
      onDeselect,
    } = this.props;

    removeMixPlaylist(_id);
    onDeselect(_id);
  };

  radToDeg(rad) {
    return rad * (180 / Math.PI);
  }

  modulo(n, m) {
    return ((n % m) + m) % m;
  }

  render() {
    const {
      playlist,
      allowChanges,
    } = this.props;

    const {
      mixPercent,
    } = this.state;

    const imageUrl = playlist.imageUrl || defaultPlaylistImage;

    return (
      <div className="MixPlaylist">
        <div className="playlist-details">
          <div className="playlist-image-container">
            <img
              src={imageUrl}
              className="playlist-image"
              alt="Playlist"
            />
          </div>

          <div className="playlist-name">
            {playlist.name}
          </div>

          {allowChanges ? (
            <div className="remove-playlist">
              <Tooltip title="Remove this Playlist">
                <Close
                  className="remove-playlist-icon"
                  onClick={this.removePlaylist}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>

        <div
          ref={this.sliderRef}
          className="percent-slider"
        >
          <div
            ref={this.activeBorderRef}
            className="active-border"
          />
          <div className="active-border-inner" />

          <div className="inner-circle">
            <div className="percentage">
              {mixPercent}%
            </div>
          </div>

          <div
            ref={this.handleRef}
            className="handle-container"
          >
            <div
              className="handle"
              onMouseDown={this.onMouseDownHandle}
            />
          </div>
        </div>

        <div className="lock-container">
          <span
            className={`lock-status ${playlist.locked ? 'locked' : ''}`}
            onClick={this.toggleLock}
          >
            {playlist.locked ? (
              <Lock className="lock-icon" />
            ) : (
              <LockOpen className="lock-icon" />
            )}
          </span>
        </div>
      </div>
    );
  }
}

MixPlaylist.propTypes = {
  playlist: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    locked: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string,
    musicItemsCount: PropTypes.number.isRequired,
    durationSeconds: PropTypes.number.isRequired,
    mixPercent: PropTypes.number.isRequired,
  }).isRequired,
  allowChanges: PropTypes.bool,
  onDeselect: PropTypes.func,
};

MixPlaylist.defaultProps = {
  allowChanges: false,
  onDeselect: () => {},
};

const mapDispatchToProp = {
  updateMixPlaylist,
  removeMixPlaylist,
};

export default connect(null, mapDispatchToProp)(MixPlaylist);
