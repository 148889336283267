import { each, isUndefined } from 'lodash';
import FlightSummary from './FlightSummary';

class OrderSummary extends FlightSummary {
  constructor(data = {}) {
    super(data);

    each(data, (val, key) => {
      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to Order Summary Model`);
      }
    });
  }
}

export default OrderSummary;
