import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeTooltip from '../../VibeTooltip/VibeTooltip';

function CellTooltip({
  className,
  title,
  placement,
  children,
}) {
  return (
    <VibeTooltip
      className={classNames('CellTooltip', className)}
      title={title || ''}
      placement={placement}
      enterDelay={500}
    >
      {children}
    </VibeTooltip>
  );
}

CellTooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CellTooltip.defaultProps = {
  className: '',
  title: '',
  placement: 'top',
};

export default CellTooltip;
