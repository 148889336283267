import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ImportField.scss';

function ImportField({
  field,
  importStatus,
  required,
}) {
  const {
    name,
    example,
    detected,
    formatted,
  } = field;

  const isError = importStatus === 'error';

  return (
    <div className="ImportField">
      <div
        className={classNames('field-name', {
          error: isError && required && detected === false,
          success: detected && formatted,
        })}
      >
        {name}
      </div>

      <div className={classNames('field-example', { error: isError && detected && formatted === false })}>
        {example}
      </div>
    </div>
  );
}

ImportField.propTypes = {
  /** Field data */
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    example: PropTypes.string.isRequired,
    detected: PropTypes.bool,
    formatted: PropTypes.bool,
  }).isRequired,
  /** Status of the import job */
  importStatus: PropTypes.string,
  /** Is the field required */
  required: PropTypes.bool,
};

ImportField.defaultProps = {
  importStatus: null,
  required: false,
};

export default ImportField;
