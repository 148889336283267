import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import {
  find,
  uniqueId,
  isFinite,
} from 'lodash';
import moment from 'moment';
import { Event } from '@mui/icons-material';
import {
  addField,
  removeField,
  updateField,
} from '../../actions/Form/FormActions';
import TimeField from './TimeField';
import ToggleField from './ToggleField';
import BaselineSelector from '../BaselineSelector/BaselineSelector';
import CompanySelector from '../CompanySelector/CompanySelector';
import UploadDropzone from '../UploadDropzone/UploadDropzone';
import VibeIcon from '../VibeIcon/VibeIcon';
import viExpandMore from '../../icons/viExpandMore';
import color from '../../sass/color.scss';
import './Field.scss';

class Field extends Component {
  constructor(props) {
    super(props);

    this.fieldId = uniqueId('vibeField');
    // Has the field been changed at all
    this.hasBeenChanged = false;
    // Has the field ever had an error
    this.fieldHadError = false;
  }

  componentDidMount() {
    const {
      value,
      addField,
    } = this.props;

    // const fieldData = clone(this.props);
    // delete fieldData.onChange;
    // delete fieldData.addField;
    // delete fieldData.removeField;
    // delete fieldData.updateField;

    addField({
      id: this.fieldId,
      value,
      ...this.props,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      value,
      fields,
      required,
      updateField,
    } = this.props;

    const {
      value: prevValue = '',
      required: prevRequired,
    } = prevProps;

    const val = (value || '').toString().trimLeft();
    const prevVal = (prevValue || '').toString().trimLeft();

    const field = find(fields, { id: this.fieldId });

    if (field.hasError && !this.fieldHadError) {
      // Field has had at least 1 error, trigger validation on each change
      this.fieldHadError = true;
    }

    if (val !== prevVal) {
      let validateResult = {};
      field.value = val;

      // Only validate if the field has or did have an error
      if (this.fieldHadError) {
        validateResult = field.validate();
      }

      updateField({
        id: this.fieldId,
        value: val,
        ...validateResult,
      });
    } else if (required !== prevRequired) {
      // Field requirement changed
      updateField({
        id: this.fieldId,
        required,
      });
    }
  }

  componentWillUnmount() {
    const {
      removeField,
    } = this.props;

    removeField(this.fieldId);
  }

  onFocus = () => {
    const {
      onFocus,
    } = this.props;

    onFocus();
  };

  onDatepickerKeyDown = (e) => {
    if (e.key === 'Tab') {
      this.onDatepickerBlur();
    }
  };

  onDrop = (files) => {
    const {
      onDrop,
    } = this.props;

    onDrop(files);
  };

  onChange = (e) => {
    let {
      target: {
        value,
      },
    } = e;

    const {
      type,
      fields,
      numberProps: {
        min,
        max,
      },
      onChange,
      updateField,
    } = this.props;

    value = (value || '').trimLeft();

    if (type === 'number' && (isFinite(min) || isFinite(max))) {
      const num = parseInt(value, 10);

      if (isFinite(min) && num < min) {
        // Number is lower than the minimum allowed
        console.warn('Minimum number allowed', min);
        return;
      }

      if (isFinite(max) && num > max) {
        // Number is lower than the minimum allowed
        console.warn('Maximum number allowed', max);
        return;
      }
    }

    const field = find(fields, { id: this.fieldId });

    let validateResult = {};
    field.value = value;

    // Only validate if the field has or did have an error
    if (this.fieldHadError) {
      validateResult = field.validate();
    }

    updateField({
      id: this.fieldId,
      value,
      ...validateResult,
    });

    this.hasBeenChanged = true;
    onChange(e);
  };

  onChangeDate = (value) => {
    const {
      fields,
      dateProps,
      onChange,
      updateField,
    } = this.props;

    const field = find(fields, { id: this.fieldId });
    const newDate = new moment(new Date(value));

    // Set the date to the start of the day
    if (dateProps.startOfDay) {
      // Set the date to the start of the day
      newDate.startOf('day');
    } else if (dateProps.endOfDay) {
      // Set the date to the end of the day
      newDate.endOf('day');
    }

    const date = newDate.toDate();

    let validateResult = {};
    field.value = date;

    // Only validate if the field has or did have an error
    if (this.fieldHadError) {
      validateResult = field.validate();
    }

    updateField({
      id: this.fieldId,
      value: date,
      ...validateResult,
    });

    this.hasBeenChanged = true;
    onChange(newDate.format());
  };

  onBlur = (e) => {
    const {
      type,
      fields,
      updateField,
      onBlur,
    } = this.props;

    // const {
    //   field,
    // } = this.state;

    const field = find(fields, { id: this.fieldId });

    const validateResult = field.validate({
      ignoreEmpty: !this.hasBeenChanged,
    });

    let newValue = (field.value || '');

    if (type !== 'number') {
      newValue.trim();
    }

    if (type === 'tel') {
      // Format phone number
      newValue = field.formatPhone(field.value);
    }

    updateField({
      id: this.fieldId,
      value: newValue,
      ...validateResult,
    });

    onBlur(e);
  };

  render() {
    const {
      type,
      rootClassName,
      className,
      label,
      placeholder,
      name,
      tabIndex,
      autoFocus,
      autoComplete,
      disabled,
      dataId,
      marginTop,
      marginBottom,
      options,
      minLength,
      requiredLabel,
      requiredLabelSize,
      required,
      baselineProps,
      companyProps,
      timeProps,
      dropzoneProps,
      toggleProps,
      fields,
      dropdownPosition,
      children,
      onClick,
      onChange,
      onKeyDown,
    } = this.props;

    const field = find(fields, { id: this.fieldId });

    if (!field) {
      return null;
    }

    const {
      errorMinLength,
      errorRequired,
      errorEmail,
      errorPhone,
      hasError,
    } = field;

    const isInput = type !== 'company'
      && type !== 'advertiser'
      && type !== 'baseline'
      && type !== 'datepicker'
      && type !== 'textarea'
      && type !== 'select'
      && type !== 'time'
      && type !== 'toggle'
      && type !== 'dropzone'
      && type !== 'tags'
      && type !== 'label'
      && type !== 'custom';

    const isTextarea = type === 'textarea';
    const isSelect = type === 'select';
    const isDropzone = type === 'dropzone';
    const isCompany = type === 'company';
    const isBaseline = type === 'baseline';
    const isTime = type === 'time';
    const isToggle = type === 'toggle';
    const isDatepicker = type === 'datepicker';
    const isTags = type === 'tags';
    const isHidden = type === 'hidden';
    const isCustom = type === 'custom';

    let dateValue;

    if (isDatepicker && field.value) {
      dateValue = moment(field.value).toDate();
    }

    return (
      <div
        className={`Field ${rootClassName} ${hasError ? 'has-error' : ''}`}
        style={{
          marginTop,
          marginBottom,
        }}
      >
        {label !== null && !isTags && !isHidden ? (
          <label
            className={classNames('field-label', { required })}
            htmlFor={this.fieldId}
          >
            {label}
          </label>
        ) : null}

        {isInput ? (
          <input
            id={this.fieldId}
            name={name}
            className={`field-input ${className}`}
            type={type}
            tabIndex={tabIndex}
            placeholder={placeholder}
            value={field.value}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            data-id={dataId}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onKeyDown={onKeyDown}
            onFocus={this.onFocus}
          />
        ) : null}

        {isTextarea ? (
          <textarea
            id={this.fieldId}
            name={name}
            className={`field-textarea ${className}`}
            tabIndex={tabIndex}
            placeholder={placeholder}
            value={field.value}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onKeyDown={onKeyDown}
            onFocus={this.onFocus}
          />
        ) : null}

        {isSelect ? (
          <div className="select-container">
            <select
              id={this.fieldId}
              name={name}
              className={`field-input ${className}`}
              tabIndex={tabIndex}
              placeholder={placeholder}
              value={field.value}
              autoFocus={autoFocus}
              disabled={disabled}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
            >
              {options.map((option) => {
                return (
                  <option
                    key={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </select>

            <VibeIcon
              className="arrow-down"
              icon={viExpandMore}
              color={color.manatee}
              size={16}
            />
          </div>
        ) : null}

        {isTime ? (
          <TimeField
            fieldId={this.fieldId}
            placeholder={placeholder}
            time={field.value}
            min={timeProps.min}
            max={timeProps.max}
            inputStyle={timeProps.inputStyle}
            disabled={disabled}
            onChange={onChange}
          />
        ) : null}

        {isToggle ? (
          <ToggleField
            fieldId={this.fieldId}
            className={className}
            label={toggleProps.label}
            helpText={toggleProps.helpText}
            helpTextStyle={toggleProps.helpTextStyle}
            toggled={field.value === 'on'}
            disabled={disabled}
            onChange={onChange}
          />
        ) : null}

        {isDropzone ? (
          <UploadDropzone
            fieldId={this.fieldId}
            style={dropzoneProps.style}
            dragOverStyle={dropzoneProps.dragOverStyle}
            placeholder={dropzoneProps.placeholder}
            type={dropzoneProps.type}
            image={dropzoneProps.image}
            acceptedMimeTypes={dropzoneProps.mimeTypes}
            allowUpload={dropzoneProps.allowUpload}
            disabled={disabled}
            onDrop={this.onDrop}
          />
        ) : null}

        {isCompany ? (
          <CompanySelector
            fieldId={this.fieldId}
            companyName={companyProps.companyName}
            companyId={companyProps.companyId}
            disabled={disabled}
            tabIndex={tabIndex}
            setCompany={companyProps.setCompany}
            onClick={onClick}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
        ) : null}

        {isBaseline ? (
          <BaselineSelector
            fieldId={this.fieldId}
            baselineName={baselineProps.baselineName}
            baselineId={baselineProps.baselineId}
            locationId={baselineProps.locationId}
            dropdownPosition={dropdownPosition}
            disabled={disabled}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            onClick={onClick}
            onChange={onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
        ) : null}

        {isDatepicker ? (
          <div className="datepicker-container">
            <Event className="datepicker-icon" />

            <DatePicker
              className="field-input"
              placeholderText={placeholder}
              selected={dateValue || ''}
              disabled={disabled}
              tabIndex={tabIndex}
              onKeyDown={this.onDatepickerKeyDown}
              onChange={this.onChangeDate}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </div>
        ) : null}

        {isCustom ? (
          <div>
            {children}
          </div>
        ) : null}

        {hasError ? (
          <div className="field-errors">
            {errorMinLength ? (
              <div className="field-error">
                Must be at least {minLength} characters
              </div>
            ) : null}

            {errorRequired ? (
              <div
                className="field-error"
                style={{
                  fontSize: requiredLabelSize,
                }}
              >
                {requiredLabel}
              </div>
            ) : null}

            {errorEmail ? (
              <div className="field-error">
                Not a valid email address
              </div>
            ) : null}

            {errorPhone ? (
              <div className="field-error">
                Phone number must be 10 digits long
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

Field.propTypes = {
  /** What type of input field */
  type: PropTypes.oneOf([
    'text',
    'password',
    'number',
    'checkbox',
    'radio',
    'email',
    'tel',
    'hidden',
    'textarea',
    'select',
    'toggle',
    'time',
    'dropzone',
    'company',
    'advertiser',
    'baseline',
    'datepicker',
    'tags',
    'label',
    'custom',
  ]).isRequired,
  /** Root component class */
  rootClassName: PropTypes.string,
  /** Field class */
  className: PropTypes.string,
  /** Field label */
  label: PropTypes.string,
  /** Field placeholder */
  placeholder: PropTypes.string,
  /** Field name */
  name: PropTypes.string,
  /** Field value */
  value: PropTypes.string,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Allow AutoComplete */
  autoComplete: PropTypes.oneOf([
    'on',
    'off',
  ]),
  /** Auto focus the field */
  autoFocus: PropTypes.bool,
  /** Field is disabled */
  disabled: PropTypes.bool,
  /** Field ID for data attribute on input */
  dataId: PropTypes.string,
  /** Margin at the top of the field */
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Margin at the bottom of the field */
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Options for select field */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  /** Minimum length of field */
  minLength: PropTypes.number,
  /** Text for the required error label */
  requiredLabel: PropTypes.string,
  /** Size for the required label */
  requiredLabelSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Is the field required */
  required: PropTypes.bool,
  /** Number properties for number field */
  numberProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  /** Company properties for company selector */
  companyProps: PropTypes.shape({
    companyName: PropTypes.string,
    companyId: PropTypes.string,
    setCompany: PropTypes.func,
  }),
  /** Advertiser properties for advertiser selector */
  advertiserProps: PropTypes.shape({
    advertiserName: PropTypes.string,
    setAdvertiserName: PropTypes.func,
    setAdvertiserId: PropTypes.func,
  }),
  /** Baseline properties for baseline selector */
  baselineProps: PropTypes.shape({
    baselineName: PropTypes.string,
    baselineId: PropTypes.string,
    locationId: PropTypes.string,
  }),
  /** Time properties for time field */
  timeProps: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
    inputStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Date properties for tags field */
  dateProps: PropTypes.shape({
    startOfDay: PropTypes.bool,
    endOfDay: PropTypes.bool,
  }),
  /** Dropzone properties for upload field */
  dropzoneProps: PropTypes.shape({
    type: PropTypes.oneOf([
      'image',
      'document',
      'audio',
    ]),
    /** Placeholder element */
    placeholder: PropTypes.element,
    /** Custom style for the dropzone */
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    /** Custom style for the dropzone drag over container */
    dragOverStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    image: PropTypes.string,
    mimeTypes: PropTypes.object,
    /** Allow a file to be dropped */
    allowUpload: PropTypes.bool,
  }),
  /** Toggle field properties */
  toggleProps: PropTypes.shape({
    label: PropTypes.string,
    helpText: PropTypes.string,
    helpTextStyle: PropTypes.object,
  }),
  /** Dropdown Position for the AutoComplete Selections */
  dropdownPosition: PropTypes.oneOf([
    'bottom',
    'top',
  ]),
  /** Child elements for custom field */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** When the field is clicked */
  onClick: PropTypes.func,
  /** When the field changes */
  onChange: PropTypes.func,
  /** When a key is pressed */
  onKeyDown: PropTypes.func,
  /** When the field recieves focus */
  onFocus: PropTypes.func,
  /** When the field loses focus */
  onBlur: PropTypes.func,
  /** When the field recieved a drop event */
  onDrop: PropTypes.func,
};

Field.defaultProps = {
  rootClassName: '',
  className: '',
  label: null,
  placeholder: '',
  name: '',
  value: '',
  tabIndex: -1,
  autoFocus: false,
  autoComplete: 'on',
  disabled: false,
  dataId: null,
  marginTop: 0,
  marginBottom: 0,
  options: [],
  minLength: 0,
  requiredLabel: 'Required',
  requiredLabelSize: 10,
  required: false,
  numberProps: {
    min: null,
    max: null,
  },
  companyProps: {
    companyName: '',
    companyId: '',
    setCompany: () => {},
  },
  advertiserProps: {
    advertiserName: '',
    setAdvertiserName: () => {},
    setAdvertiserId: () => {},
  },
  baselineProps: {
    baselineName: '',
    baselineId: '',
    locationId: '',
  },
  timeProps: {
    min: '',
    max: '',
    inputStyle: {},
  },
  dateProps: {
    startOfDay: false,
    endOfDay: false,
  },
  dropzoneProps: {
    type: 'audio',
    placeholder: null,
    style: {},
    dragOverStyle: {},
    image: null,
    mimeTypes: {},
    allowUpload: false,
  },
  toggleProps: {
    label: '',
    helpText: null,
    helpTextStyle: {},
  },
  dropdownPosition: 'bottom',
  children: null,
  onClick: () => {},
  onChange: () => {},
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onDrop: () => {},
};

function mapStateToProps(state) {
  return {
    fields: state.form2.fields,
  };
}

const mapDispatchToProps = {
  addField,
  removeField,
  updateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Field);
