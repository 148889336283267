import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    ADD_UPDATE_USER_IMAGE: (state, action) => {
      const {
        id,
        imageUrl,
      } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id],
          imageUrl,
        },
      };
    },
  },
  // Initial State
  initialState,
);
