import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  VibeIcon,
  viEdit,
  color,
} from 'vibeguide';
import './AddPicture.scss';

class AddPicture extends Component {
  constructor(props) {
    super(props);

    const {
      imageUrl = '',
    } = props;

    this.fileRef = React.createRef();

    this.state = {
      picLocation: imageUrl,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      imageUrl,
    } = this.props;

    const {
      imageUrl: prevImageUrl,
    } = prevProps;

    if (imageUrl !== prevImageUrl) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        picLocation: imageUrl,
      });
    }
  }

  openFileDialog = () => {
    const {
      fileRef: {
        current: fileRef,
      },
    } = this;

    if (fileRef) {
      fileRef.click();
    }
  };

  fileChange = e => {
    const { getImageURL } = this.props;
    // Creating the file reader using the File Reader API
    const reader = new FileReader();
    // Getting in targeted image uploaded using the input
    const imageLocation = e.target.files[0];
    if (imageLocation) {
      // On load function for the when the reader loads and is ready to set the state
      reader.onload = event => {
        // After we set the picture location to a dateURI in the state
        // we then need to get that dataURI back to the component using it with getImageURL()
        const dataURI = event.target.result;
        this.setState({
          picLocation: dataURI,
        }, () => getImageURL(imageLocation, dataURI));
      };
      // Turning the imagelocation into a datauri
      reader.readAsDataURL(imageLocation);
    }
  };

  render() {
    const {
      picLocation,
    } = this.state;

    const {
      size,
      silhouette,
      shape,
      showEditIcon,
    } = this.props;

    return (
      <div className={`AddPicture ${!picLocation ? 'empty' : ''}`}>
        <div className={`picture-container picture-${size} picture-${shape}`} onClick={this.openFileDialog}>
          {picLocation ? (
            <img className={`add-picture-img ${shape}`} src={picLocation} alt="addedImage" />
          ) : (
            silhouette
          )}
        </div>

        <input
          ref={this.fileRef}
          type="file"
          className="add-picture-input"
          name="pic"
          onChange={this.fileChange}
        />

        {showEditIcon ? (
          <VibeIcon
            icon={viEdit}
            color={color.violetVibe}
            hoverColor={color.obsidian}
            size={20}
            onClick={this.openFileDialog}
          />
        ) : null}
      </div>
    );
  }
}

AddPicture.propTypes = {
  size: PropTypes.oneOf([
    'small',
    'normal',
  ]),
  getImageURL: PropTypes.func,
  showEditIcon: PropTypes.bool,
};

AddPicture.defaultProps = {
  size: 'normal',
  getImageURL: () => {},
  showEditIcon: false,
};

export default AddPicture;
