import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {
  Field2 as Field,
  TimeUtil,
  DaySelector,
  VibeToggle,
  VibeIcon,
  viRadioOn,
  viRadioOff,
  color,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './DateAndTime.scss';

class DateAndTime extends PureComponent {
  constructor(props) {
    super(props);

    const {
      startDate,
      stopDate,
    } = props;

    this.state = {
      // minimum date to select for the event
      minDate: new moment().startOf('day').toDate(),
      currentStartDate: new moment(startDate).format('YYYY-MM-DD'),
      currentStopDate: stopDate
        ? new moment(stopDate).format('YYYY-MM-DD')
        : null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      startDate,
      stopDate,
    } = this.props;

    const {
      startDate: prevStartDate,
      stopDate: prevStopDate,
    } = prevProps;

    if (startDate !== prevStartDate) {
      this.setState({
        currentStartDate: new moment(startDate).format('YYYY-MM-DD'),
      });
    }

    if (stopDate !== prevStopDate) {
      this.setState({
        currentStopDate: stopDate
          ? new moment(stopDate).format('YYYY-MM-DD')
          : null,
      });
    }
  }

  onChangeStartDate = (startDate) => {
    const {
      startTime,
      onUpdate,
    } = this.props;

    if (startDate.length === 10) {
      const hour = startTime.split(':')[0];
      const minute = startTime.split(':')[1];

      const newStartDate = new moment(startDate)
        .hour(hour)
        .minute(minute)
        .second(0);

      onUpdate({
        day: newStartDate.format('dddd[s]'),
        startDate: newStartDate.toDate(),
        updateRule: true,
      });
    }

    this.setState({
      currentStartDate: startDate,
    });
  };

  onChangeStartTime = (data) => {
    const {
      startDate,
      scheduleOriginTimeZone,
      onUpdate,
    } = this.props;

    // Get the timezone offset of the event
    const offset = new moment().tz(scheduleOriginTimeZone).utcOffset();
    // Get the local timezone offset
    const localOffset = new moment().utcOffset();
    // Combine the offsets to get the hour to set the date to
    const combinedOffset = offset - localOffset;

    const dtstartUTC = new moment(startDate).utc().format();
    const dtstart = new moment(dtstartUTC).add(combinedOffset, 'minutes');

    const spltTime = data.time24.split(':');
    const hour = spltTime[0];
    const minute = spltTime[1];

    const newStartDate = new moment(startDate)
      .month(dtstart.month())
      .day(dtstart.day())
      .hour(hour)
      .minute(minute)
      .second(0);

    onUpdate({
      startDate: newStartDate.toDate(),
      startTime: data.time24,
      updateRule: true,
    });
  };

  onChangeStopDate = (stopDate) => {
    const {
      onUpdate,
    } = this.props;

    if (stopDate.length === 10) {
      const newStopDate = new moment(stopDate).toDate();

      onUpdate({
        stopDate: newStopDate,
        updateRule: true,
      });
    }

    this.setState({
      currentStopDate: stopDate,
    });
  };

  onChangeStopTime = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      stopTime: data.time24,
      hasError: data.hasError,
    });
  };

  onChangeRepeatInterval = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    try {
      let interval = value;

      if (interval === '') {
        onUpdate({
          repeatInterval: interval,
          updateRule: true,
        });

        return;
      }

      interval = parseInt(value, 10);

      if (interval <= 0) {
        console.warn('Interval cannot be less than 1');
        return;
      }

      onUpdate({
        repeatInterval: interval,
        updateRule: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  onChangeRepeatFrequency = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      repeatFrequency: value,
      updateRule: true,
    });
  };

  onChangeRepeatDays = (days) => {
    const {
      disableInput,
      onUpdate,
    } = this.props;

    if (disableInput) {
      // field is disabled
      return;
    }

    onUpdate({
      repeatDays: days,
      updateRule: true,
    });
  };

  setRepeatEndNever = () => {
    const {
      disableInput,
      onUpdate,
    } = this.props;

    if (!disableInput) {
      onUpdate({
        stopDate: null,
        updateRule: true,
      });
    }
  };

  setRepeatEndOn = () => {
    const {
      disableInput,
      stopDate,
      onUpdate,
    } = this.props;

    if (disableInput) {
      // field is disabled
      return;
    }

    const newStopDate = new moment().endOf('day').toDate();

    if (stopDate) {
      // Stop date already exists
      return;
    }

    onUpdate({
      stopDate: newStopDate,
      updateRule: true,
    });
  };

  toggleRepeatEvent = () => {
    const {
      repeat,
      onUpdate,
    } = this.props;

    onUpdate({
      repeat: !repeat,
      updateRule: true,
    });
  };

  render() {
    const {
      className,
      eventColor,
      // startDate,
      stopDate,
      startTime,
      stopTime,
      repeat,
      repeatDays,
      repeatInterval,
      repeatFrequency,
      // scheduleOriginTimeZone,
      disableInput,
    } = this.props;

    const {
      minDate,
      currentStartDate,
      currentStopDate,
    } = this.state;

    // // Get the timezone offset of the event
    // const offset = new moment().tz(scheduleOriginTimeZone).utcOffset();
    // // Get the local timezone offset
    // const localOffset = new moment().utcOffset();
    // // Combine the offsets to get the hour to set the date to
    // const combinedOffset = offset - localOffset;

    // const dtstartUTC = new moment(startDate).utc().format();
    // const dtstart = new moment(dtstartUTC).add(combinedOffset, 'minutes');

    return (
      <div className={classNames('DateAndTime', className)}>
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Date &amp; Time
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={6} item>
            <Field
              type="datepicker"
              label={repeat ? 'Start Date' : 'Date'}
              // value={dtstart.format('YYYY-MM-DD')}
              value={currentStartDate}
              dateProps={{
                placement: 'top',
                minDate,
                format: 'YYYY-MM-DD',
              }}
              disabled={disableInput}
              onChange={this.onChangeStartDate}
            />
          </Grid>

          <Grid xs={3} item>
            <Field
              type="time"
              label="Start"
              value={startTime}
              // timeProps={{
              //   // start time must be at least 1 minute before stop
              //   max: TimeUtil.convertMinutes(TimeUtil.getMinutes(stopTime) - 1),
              // }}
              disabled={disableInput}
              onChange={this.onChangeStartTime}
            />
          </Grid>

          <Grid xs={3} item>
            <Field
              type="time"
              label="End"
              value={stopTime}
              timeProps={{
                // stop time must be at least 1 minute after start
                min: TimeUtil.convertMinutes(TimeUtil.getMinutes(startTime) + 1),
                max: '24:00',
                endOfDay: true,
              }}
              disabled={disableInput}
              onChange={this.onChangeStopTime}
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <div className="repeat-event-container">
              <VibeToggle
                label="Repeat Event"
                labelStyle={{
                  fontSize: 12,
                }}
                height={16}
                checked={repeat}
                color={eventColor}
                disabled={disableInput}
                onChange={this.toggleRepeatEvent}
              />

              {repeat ? (
                <div className="repeat-event-content">
                  <div className="repeat-row">
                    <div className="row-item">
                      <Field
                        type="label"
                        label="Repeat Every"
                      />

                      <div className="event-repeat-every">
                        <Field
                          className="every-interval"
                          type="number"
                          placeholder="1"
                          value={repeatInterval.toString()}
                          disabled={disableInput}
                          onChange={this.onChangeRepeatInterval}
                        />

                        <Field
                          className="every-frequency"
                          type="select"
                          value={repeatFrequency}
                          options={[
                            {
                              label: 'Week',
                              value: 'week',
                            },
                            {
                              label: 'Month',
                              value: 'month',
                            },
                            {
                              label: 'Year',
                              value: 'year',
                            },
                          ]}
                          disabled={disableInput}
                          onChange={this.onChangeRepeatFrequency}
                        />
                      </div>
                    </div>

                    <div className="row-item row-repeat-on">
                      <Field
                        type="label"
                        label="Repeat On"
                      />

                      <DaySelector
                        dayFormat="dddd"
                        selectedColor={eventColor}
                        disabled={disableInput}
                        selectedDays={repeatDays}
                        toggleAll
                        onChange={this.onChangeRepeatDays}
                      />
                    </div>
                  </div>

                  <div className="event-repeat-end">
                    <Field
                      type="label"
                      label="Ends"
                    />

                    <div className="radio-row">
                      <div
                        className={classNames('radio-item', { selected: !stopDate })}
                        style={{
                          border: !stopDate
                            ? `1px solid ${eventColor}`
                            : 'none',
                        }}
                        onClick={this.setRepeatEndNever}
                      >
                        <div className="radio-content">
                          <VibeIcon
                            icon={!stopDate ? viRadioOn : viRadioOff}
                            color={!stopDate
                              ? eventColor
                              : color.manatee}
                            size={24}
                          />

                          <div className="radio-title">
                            Never
                          </div>
                        </div>
                      </div>

                      <div
                        className={classNames('radio-item', { selected: stopDate })}
                        style={{
                          border: stopDate
                            ? `1px solid ${eventColor}`
                            : 'none',
                        }}
                        onClick={this.setRepeatEndOn}
                      >
                        <div className="radio-content">
                          <VibeIcon
                            className="radio-icon"
                            icon={stopDate ? viRadioOn : viRadioOff}
                            color={stopDate
                              ? eventColor
                              : color.manatee}
                            size={24}
                          />

                          <div className="radio-title">
                            On
                          </div>
                        </div>
                      </div>

                      <Field
                        type="datepicker"
                        // value={stopDate
                        //   ? moment(stopDate).format('YYYY-MM-DD')
                        //   : null}
                        value={currentStopDate}
                        dateProps={{
                          endOfDay: true,
                          format: 'YYYY-MM-DD',
                        }}
                        disabled={disableInput}
                        onChange={this.onChangeStopDate}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DateAndTime.propTypes = {
  className: PropTypes.string,
  eventColor: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  stopDate: PropTypes.instanceOf(Date),
  startTime: PropTypes.string,
  stopTime: PropTypes.string,
  repeat: PropTypes.bool,
  repeatDays: PropTypes.arrayOf(PropTypes.string),
  repeatInterval: PropTypes.number,
  repeatFrequency: PropTypes.string,
  scheduleOriginTimeZone: PropTypes.string,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

DateAndTime.defaultProps = {
  className: '',
  eventColor: '',
  startDate: new Date(),
  stopDate: null,
  startTime: '',
  stopTime: '',
  repeat: false,
  repeatDays: [],
  repeatInterval: 0,
  repeatFrequency: '',
  scheduleOriginTimeZone: TimeUtil.resolveLocalTimeZone(),
  disableInput: false,
  onUpdate: () => {},
};

export default DateAndTime;
