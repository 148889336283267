import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import CellTooltip from '../VibeTable/CellTypes/CellTooltip';

/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
moment.calendarFormat = (myMoment) => {
  const now = new moment();
  const diff = myMoment.diff(now, 'days', true);
  const minDiff = now.diff(myMoment, 'minutes', true);
  const retVal = diff < -6 ? 'sameElse'
    // introduce thisMonth and nextMonth
    : (minDiff < 1 && myMoment.day() === now.day() && now.year() === myMoment.year()) ? 'withinMinute'
    : (minDiff < 60 && myMoment.day() === now.day() && now.year() === myMoment.year()) ? 'withinHour'
    : (minDiff > 60 && minDiff < 120 && myMoment.day() === now.day() && now.year() === myMoment.year()) ? 'isHour'
    : (minDiff > 60 && myMoment.day() === now.day() && now.year() === myMoment.year()) ? 'sameDay'
    : diff < -1 ? 'lastWeek'
    : diff < 0 ? 'lastDay'
    : diff < 2 ? 'nextDay'
    : diff < 7 ? 'nextWeek'
    : 'sameElse';
  return retVal;
};
/* eslint-enable indent */
/* eslint-enable no-nested-ternary */

function RelativeTime({
  className,
  style,
  time,
  format,
}) {
  if (!time) {
    // No time to compare to
    return null;
  }

  const then = new moment(time);
  const now = new moment();
  const minsDiff = now.diff(then, 'minutes');
  const minsAgo = moment.duration(minsDiff, 'minutes').asMinutes();
  const hoursAgo = parseInt(minsAgo / 60, 10);

  const requestedHtml = format
    ? then.format(format)
    : then.calendar(now, {
      // when the date is closer, specify custom values
      lastDay: '[Yesterday,] h:mma',
      nextDay: '[Tomorrow,] h:mma',
      withinMinute: '[Just Now]',
      withinHour: `[${minsAgo}] [min ago]`,
      isHour: '[1 hour ago]',
      sameDay: `[${hoursAgo}] [hours ago]`,
      // when the date is further away
      lastWeek: 'ddd, MMM DD, YYYY, h:mma',
      nextWeek: 'ddd, MMM DD, YYYY, h:mma',
      sameElse: () => {
        return 'ddd, MMM DD, YYYY, h:mma';
      },
    });

  return (
    <CellTooltip title={requestedHtml}>
      <div
        className={classNames('RelativeTime', className)}
        style={style}
      >
        {requestedHtml}
      </div>
    </CellTooltip>
  );
}

RelativeTime.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  /** String of time to use */
  time: PropTypes.string,
  /** Custom format of date/time */
  format: PropTypes.string,
};

RelativeTime.defaultProps = {
  className: '',
  style: {},
  time: null,
  format: null,
};

export default RelativeTime;
