import React from 'react';

const viCalendarEventFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 34"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M1,17.0004884 C1,8.1639324 8.163444,1.0004884 17,1.0004884 C20.3333333,0.979324379 23,1.64599105 25,3.0004884 C30.7142857,6.81001221 36.047619,11.4766789 41,17.0004884 C36.047619,22.5242979 30.7142857,27.1909646 25,31.0004884 C23.1427396,32.2190084 20.7105765,32.8808767 17.7035108,32.9860931 C17.5735926,32.9909111 17.4444202,32.9949514 17.3148707,32.997452 L17,33.0004884 L17,33.0004884 C8.163444,33.0004884 1,25.8370444 1,17.0004884 Z" />
    </g>
  </svg>
);

export default viCalendarEventFilled;
