import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  LoadingContent,
} from 'vibeguide';
import SongDetails from './SongDetails';
import './SongSidebar.scss';

class SongSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      song: {
        playlistCount: 0,
        tempo: 0,
      },
    };
  }

  componentDidMount() {
    const {
      songId,
    } = this.props;

    if (songId && songId !== 'new') {
      this.getSong();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      songId,
      isNew,
    } = this.props;

    const {
      songId: prevSongId,
    } = prevProps;

    if (songId && !isNew && songId !== prevSongId) {
      this.getSong();
    }
  }

  /**
   * Update the song
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        song: {
          ...state.song,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtra = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  getSong = async () => {
    const {
      songId,
    } = this.props;

    const song = await API.Music.getById(songId);

    this.setState((state) => {
      return {
        song: {
          proDefinitions: state.song.proDefinitions,
          ...song,
        },
      };
    });
  };

  render() {
    const {
      songId,
    } = this.props;

    const {
      song,
    } = this.state;

    return (
      <div className="SongSidebar">
        <SongDetails
          song={song}
          onClose={this.onClose}
          onCloseExtra={this.onCloseExtra}
          onUpdate={this.onUpdate}
        />

        {songId && !song._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

SongSidebar.propTypes = {
  songId: PropTypes.string,
  /** New Song */
  isNew: PropTypes.bool,
};

SongSidebar.defaultProps = {
  songId: null,
  isNew: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(SongSidebar);
