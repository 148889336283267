// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users .user-card-grid {
  margin: 0 auto;
}
.Users .user-card-grid:first-child {
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Users/Users.scss"],"names":[],"mappings":"AAEE;EACE,cAAA;AADJ;AAGI;EACE,gBAAA;AADN","sourcesContent":[".Users {\n\n  .user-card-grid {\n    margin: 0 auto;\n\n    &:first-child {\n      margin-top: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
