import React from 'react';
import PropTypes from 'prop-types';
import './VibeButtonGroup.scss';

function VibeButtonGroup({
  className,
  children,
}) {
  return (
    <div className={`VibeButtonGroup ${className}`}>
      {children}
    </div>
  );
}

VibeButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

VibeButtonGroup.defaultProps = {
  className: '',
};

export default VibeButtonGroup;
