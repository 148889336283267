import React from 'react';
import { Provider } from 'react-redux';
import {
  store,
} from 'vibeguide';
import VibenomicsApp from './components/VibenomicsApp';

function App() {
  return (
    <Provider store={store}>
      <VibenomicsApp />
    </Provider>
  );
}

export default App;
