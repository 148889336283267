import { each, isUndefined } from 'lodash';
import moment from 'moment';

class Message {
  constructor(data = {}) {
    this._id = data._id || data.messageId || '';
    this.messageId = data.messageId || data._id || '';
    this.requestId = data.requestId || '';
    this.companyId = data.companyId || '';
    this.companyName = data.companyName || '';
    this.companyImageUrl = data.companyImageUrl || '';
    this.name = data.name || '';
    this.messageType = data.messageType || '';
    this.advertiserId = data.advertiserId || '';
    this.advertiserName = data.advertiserName || '';
    this.url = data.url || '';
    this.durationSeconds = data.durationSeconds || 0;
    this.fileSizeBytes = data.fileSizeBytes || 0;
    this.locations = data.locations || [];
    this.locationsData = data.locationsData || {
      companies: [],
      locations: [],
    };
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.daysOfWeek = data.daysOfWeek || [];
    this.active = data.active || false;
    this.tags = data.tags || [];
    this.sequence = data.sequence || 0;
    this.script = data.script || '';
    this.createdDate = data.createdDate || null;
    this.createdByUserName = data.createdByUserName || null;
    this.createdByUserFname = data.createdByUserFname || null;
    this.createdByUserLname = data.createdByUserLname || null;
    this.createdByUserImageUrl = data.createdByUserImageUrl || null;
    this.createdBy = data.createdBy || null;
    this.messageBlocks = data.messageBlocks || [];
    this.modifiedDate = data.modifiedDate || null;
    this.modifiedBy = data.modifiedBy || null;
    this.voiceTalentUserId = data.voiceTalentUserId || null;
    this.voiceTalentUserFname = data.voiceTalentUserFname || null;
    this.voiceTalentUserLname = data.voiceTalentUserLname || null;
    this.voiceTalentUserImageUrl = data.voiceTalentUserImageUrl || null;
    this.voiceTalentUserName = data.voiceTalentUserName || null;
    this.lastArchivedBy = data.lastArchivedBy || null;
    this.lastArchivedDate = data.lastArchivedDate || null;
    this.lastUnarchivedBy = data.lastUnarchivedBy || null;
    this.lastUnarchivedDate = data.lastUnarchivedDate || null;

    // merge last, first names
    if (!this.createdByUserName
      && (this.createdByUserFname || this.createdByUserLname)
    ) {
      this.createdByUserName = this.createdByUserLname
        // last name, first name
        ? `${this.createdByUserLname}, ${this.createdByUserFname}`
        // first name
        : this.createdByUserFname;
    }

    // Has the message expired
    this.expired = false;
    // Has the message started
    this.started = true;

    if (this.startDate || this.endDate) {
      const currDate = new moment();
      const startAt = this.startDate
        ? new moment(this.startDate).startOf('day')
        : null;
      const stopAt = this.endDate
        ? new moment(this.endDate).endOf('day')
        : null;

      if (currDate.isAfter(stopAt)) {
        // The message is past the stop date
        this.expired = true;
      } else if (currDate.isBefore(startAt)) {
        // The message hasn't started yet
        this.started = false;
      }
    }

    each(data, (val, key) => {
      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to Message Model`);
      }
    });
  }
}

export default Message;
