import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
} from '@mui/material';
import ProfileContent from '../../../Locations/IndividualLocation/EditLocation/Profile/ProfileContent';
import TokenTable from './TokenTable/TokenTable';

function IntegrationToken({
  title,
  user,
  className,
}) {
  return (
    <div className={classNames('IntegrationToken', className)}>
      <Grid
        className="row extra-space"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={12}
          item
        >
          {title}
        </Grid>
      </Grid>

      <ProfileContent
        style={{
          padding: 0,
          userSelect: 'auto',
        }}
      >
        <TokenTable user={user} />
      </ProfileContent>
    </div>
  );
}

IntegrationToken.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
};

IntegrationToken.defaultProps = {
  className: '',
  title: '',
  user: {},
};

export default IntegrationToken;
