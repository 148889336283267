/**
 * Add version header
 *
 * @param {Object} options - the options to send to the request
 * @returns {Object}
 */
export function addVersion(version, options = {}) {
  if (!options.headers) {
    options.headers = [];
  }

  options.headers = {
    ...options.headers,
    'VAPI-Version': version,
  };

  return options;
}

export default {
  addVersion,
};
