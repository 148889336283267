/**
 * Get the item from local storage, or a default value if it doesn't exist
 */
export function getLocal(key, defaultValue) {
  const item = localStorage.getItem(key) || defaultValue;

  // convert boolean values
  if (item === 'true') {
    return true;
  }

  if (item === 'false') {
    return false;
  }

  if (
    typeof item === 'string'
    && (item.substring(0, 1) === '[' || item.substring(0, 1) === '{')
  ) {
    // item is a stringified object
    return JSON.parse(item);
  }

  return item;
}

/**
 * Save the item to local storage, converting to a string if necessary
 */
export function setLocal(key, value) {
  if (typeof value === 'object') {
    // stringify the object before saving it
    value = JSON.stringify(value);
  }

  localStorage.setItem(key, value);
}

/**
 * Remove item from local storage
 */
export function removeLocal(key) {
  localStorage.removeItem(key);
}

/**
 * Clear local storage
 */
export function clearLocal() {
  localStorage.clear();
}

export default {
  getLocal,
  setLocal,
  removeLocal,
  clearLocal,
};
