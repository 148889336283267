const MULTIPLIER = 1000;
const DATA_UNIT_NAME = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];

/**
 * Format the size from bytes
 */
export function formatByteSize(size) {
  const unit = Math.floor(Math.log(size) / Math.log(MULTIPLIER));

  return new Intl.NumberFormat(
    'en',
    {
      style: 'unit',
      unit: DATA_UNIT_NAME[unit],
    },
  ).format(size / MULTIPLIER ** unit);
}

export default {
  formatByteSize,
};
