// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdvancedSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.AdvancedSearchDialog {
  z-index: 1302 !important;
}
.AdvancedSearchDialog .fields {
  margin-top: 24px;
}
.AdvancedSearchDialog .fields > div {
  margin-top: 8px;
}
.AdvancedSearchDialog .fields > div:first {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeTable/CellTypes/AdvancedSearch.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;AACF;;AAEA;EACE,wBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEI;EACE,eAAA;AAAN;AAEM;EACE,aAAA;AAAR","sourcesContent":[".AdvancedSearch {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n}\n\n.AdvancedSearchDialog {\n  z-index: 1302 !important;\n\n  .fields {\n    margin-top: 24px;\n\n    // for every field give some spacing\n    > div {\n      margin-top: 8px;\n\n      &:first {\n        margin-top: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
