import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viExpandMore from '../../icons/viExpandMore';
import viInformation from '../../icons/viInformation';
import color from '../../sass/color.scss';
import './VibeSelect.scss';

let onBlurTimeout = null;

function VibeSelect({
  placeholder,
  className,
  style,
  options,
  value,
  disabled,
  tabIndex,
  onChange,
  onBlur,
}) {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const inputRef = useRef(null);

  /**
   * Open the autocomplete menu
   */
  const onOpen = () => {
    setOpen(!open);
    setVisible(true);
  };

  const selectItem = (item) => {
    onChange(item.saveValue);
  };

  const getOptionText = (item) => {
    if (options.filter(option => option.saveValue === item).length === 0) {
      return '';
    }
    return options.filter(option => option.saveValue === item)[0].value;
  };

  /**
   * Close the autocomplete menu
   */
  const onClose = () => {
    setVisible(false);

    // Ensure blur fires after selecting an item
    clearTimeout(onBlurTimeout);
    onBlurTimeout = setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const onBlurField = (e) => {
    onClose();
    onBlur(e);
  };

  return (
    <div
      className={classNames('VibeSelect', className, { disabled })}
      style={style}
      onClick={!disabled
        ? onOpen
        : null}
    >
      <div className="input-container">
        <input
          ref={inputRef}
          tabIndex={tabIndex}
          className="field-input item-input"
          type="text"
          placeholder={placeholder}
          name="vibe-select"
          value={value}
          autoComplete="off"
          disabled={disabled}
          onBlur={onBlurField}
          onChange={onChange}
        />

        <span className={classNames('select-display', { disabled })}>
          {getOptionText(value)}
        </span>

        <VibeIcon
          className="arrow-down"
          icon={viExpandMore}
          color={color.manatee}
          size={16}
          disabled={disabled}
        />
      </div>

      <Popper
        className="VibeSelectPopper"
        open={open}
        anchorEl={inputRef.current}
        placement="bottom-start"
        style={{
          width: get(inputRef, 'current.offsetWidth', 350),
          zIndex: 1302,
          opacity: visible
            ? 1
            : 0,
        }}
      >
        <div
          className="item-select"
        >
          {options.map((item) => {
            return (
              <div
                key={item.saveValue}
                className={classNames('item')}
                data-id={item.saveValue}
                data-name={item.value}
                onClick={!disabled
                  ? selectItem.bind(this, item)
                  : null}
              >
                <div className="item-content">
                  <div className="text">{item.value}</div>
                  <VibeTooltip
                    title={item.info}
                    placement="bottom"
                  >
                    <div className="playback-info">
                      <VibeIcon
                        icon={viInformation}
                        color={color.white}
                        size={16}
                        style={{
                          marginLeft: 8,
                        }}
                      />
                    </div>
                  </VibeTooltip>
                </div>
              </div>
            );
          })}

          {options.length <= 0 && (
            <div className="item disabled">
              Nothing found...
            </div>
          )}
        </div>
      </Popper>
    </div>
  );
}

VibeSelect.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    value: PropTypes.string,
    saveValue: PropTypes.string,
    info: PropTypes.string,
  })),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
};

VibeSelect.defaultProps = {
  placeholder: '',
  className: '',
  style: {},
  options: [],
  value: '',
  disabled: false,
  tabIndex: 0,
  onChange: () => {},
};

export default VibeSelect;
