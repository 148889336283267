import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  EventModel,
} from 'vibeguide';
import ScheduleGrid from './Grid/ScheduleGrid';
import './ScheduleData.scss';

class ScheduleData extends PureComponent {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();

    this.state = {
      gridWidth: 0,
      gridHeight: 0,
    };

    window.addEventListener('resize', this.onResize);
  }

  componentDidMount() {
    const {
      gridWidth,
      gridHeight,
    } = this.getGridSize();

    this.setState({
      gridWidth,
      gridHeight,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      activeDisplay,
    } = this.props;

    const {
      activeDisplay: prevActiveDisplay,
    } = prevProps;

    if (activeDisplay !== prevActiveDisplay) {
      // active display was changed, update the grid reference element
      const {
        gridWidth,
        gridHeight,
      } = this.getGridSize();

      this.setState({
        gridWidth,
        gridHeight,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  /**
   * When the window is resized
   */
  onResize = () => {
    const {
      activeDisplay,
    } = this.props;

    if (activeDisplay !== '24h') {
      // only need to calculate the grid for the 24 hour view
      return;
    }

    // calculate the new grid size
    const {
      gridWidth,
      gridHeight,
    } = this.getGridSize();

    this.setState({
      gridWidth,
      gridHeight,
    });
  };

  /**
   * Get Grid Size
   */
  getGridSize = () => {
    const {
      gridRef: {
        current: gridRef,
      },
    } = this;

    if (gridRef) {
      const gridRect = gridRef.getBoundingClientRect();
      return {
        gridWidth: gridRect.width,
        gridHeight: gridRect.height,
      };
    }

    return {
      gridWidth: 0,
      gridHeight: 0,
    };
  };

  render() {
    const {
      className,
      style,
      type,
      activeDisplay,
      musicOverride,
      startOfWeek,
      currTime,
      timezone,
      playlists,
      mixes,
      messageBlocks,
      interrupts,
      baselineSeparators,
      events,
      spotPools,
      spotValues,
      adProgramProps,
      adPrograms,
      activeEvent,
      allowClickDay,
      readOnly,
      onClickDay,
      // onUpdateEvent,
      onUpdateItems,
    } = this.props;

    const {
      gridWidth,
      gridHeight,
    } = this.state;

    return (
      <div
        ref={this.gridRef}
        className={classNames('ScheduleData', className)}
        style={style}
      >
        {gridWidth > 0 || gridHeight > 0 ? (
          <ScheduleGrid
            type={type}
            gridWidth={gridWidth}
            gridHeight={gridHeight}
            activeDisplay={activeDisplay}
            musicOverride={musicOverride}
            startOfWeek={startOfWeek}
            currTime={currTime}
            timezone={timezone}
            playlists={playlists}
            mixes={mixes}
            messageBlocks={messageBlocks}
            interrupts={interrupts}
            baselineSeparators={baselineSeparators}
            events={events}
            spotPools={spotPools}
            spotValues={spotValues}
            adProgramProps={adProgramProps}
            adPrograms={adPrograms}
            activeEvent={activeEvent}
            allowClickDay={allowClickDay}
            readOnly={readOnly}
            onClickDay={onClickDay}
            // onUpdateEvent={onUpdateEvent}
            onUpdateItems={onUpdateItems}
          />
        ) : null}
      </div>
    );
  }
}

ScheduleData.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'schedule',
    'schedule-day',
    'schedule-override',
    'schedule-override-day',
    'baseline',
    'event',
  ]).isRequired,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  activeDisplay: PropTypes.string,
  musicOverride: PropTypes.bool,
  startOfWeek: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  currTime: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  timezone: PropTypes.string,
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interrupts: PropTypes.arrayOf(PropTypes.object),
  baselineSeparators: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  spotPools: PropTypes.arrayOf(PropTypes.object),
  spotValues: PropTypes.arrayOf(PropTypes.object),
  /** Config for the ad program */
  adProgramProps: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  adPrograms: PropTypes.shape({
    SU: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    MO: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    TU: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    WE: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    TH: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    FR: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    SA: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Active Event */
  activeEvent: PropTypes.instanceOf(EventModel),
  /** Allow the day to be clicked (change the style on hover) */
  allowClickDay: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
  /** When the day is clicked on the schedule day */
  onClickDay: PropTypes.func,
  // onUpdateEvent: PropTypes.func,
  onUpdateItems: PropTypes.func,
};

ScheduleData.defaultProps = {
  className: '',
  style: {},
  activeDisplay: '',
  musicOverride: false,
  startOfWeek: {},
  currTime: {},
  timezone: null,
  playlists: [],
  mixes: [],
  messageBlocks: [],
  interrupts: [],
  baselineSeparators: [],
  events: [],
  spotPools: [],
  spotValues: [],
  adProgramProps: {},
  adPrograms: {},
  activeEvent: null,
  allowClickDay: false,
  readOnly: false,
  onClickDay: () => {},
  // onUpdateEvent: () => {},
  onUpdateItems: () => {},
};

export default ScheduleData;
