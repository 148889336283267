import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  PieChart,
  NavigationHelper,
  withRouter,
  color,
} from 'vibeguide';

class DeviceStates extends PureComponent {
  /**
   * When the pie chart is clicked
   */
  onClickPieChart = (data) => {
    const {
      includeFlaggedLocations,
      history,
    } = this.props;

    // Get the label of the area clicked
    const label = get(data, 'name', null);

    if (!label) {
      console.error('No label found for the chart');
      return;
    }

    let playerOnline = null;
    let onboardStatus = null;
    let deviceOnline = null;

    switch (label) {
      case 'OFFLINE': {
        playerOnline = false;
        onboardStatus = 'complete';
        deviceOnline = false;
        break;
      }

      case 'ONLINE': {
        playerOnline = true;
        onboardStatus = 'complete';
        deviceOnline = true;
        break;
      }

      case 'SHIPPING': {
        onboardStatus = 'shipping';
        break;
      }

      default:
        console.warn('Unknown label');
        break;
    }

    const urlData = {
      playerOnline,
      onboardStatus,
      deviceOnline,
      lastConnectTime: null,
    };

    if (!includeFlaggedLocations) {
      urlData.flagEnabled = false;
    }

    // Add a parameter for the table to automatically apply
    const url = NavigationHelper.updateParams(urlData, {
      pathname: '/dashboard/support',
    });

    history(url);
  };

  render() {
    const {
      data,
      includeFlaggedLocations,
    } = this.props;

    const onlineData = {
      playerOnline: true,
      onboardStatus: 'complete',
      deviceOnline: true,
      lastConnectTime: null,
    };

    const offlineData = {
      playerOnline: false,
      onboardStatus: 'complete',
      deviceOnline: false,
      lastConnectTime: null,
    };

    const shippingData = {
      playerOnline: null,
      onboardStatus: 'shipping',
      deviceOnline: null,
      lastConnectTime: null,
    };

    if (!includeFlaggedLocations) {
      onlineData.flagEnabled = false;
      shippingData.flagEnabled = false;
      offlineData.flagEnabled = false;
    }

    const onlineUrl = NavigationHelper.updateParams(onlineData, {
      pathname: '/dashboard/support',
    });

    const offlineUrl = NavigationHelper.updateParams(offlineData, {
      pathname: '/dashboard/support',
    });

    const shippingUrl = NavigationHelper.updateParams(shippingData, {
      pathname: '/dashboard/support',
    });

    const donutData = [
      {
        name: 'ONLINE',
        value: data.ONLINE,
        color: color.aquaForest,
        url: onlineUrl,
      },
      {
        name: 'SHIPPING',
        value: data.SHIPPING,
        color: color.flamingo,
        url: shippingUrl,
      },
      {
        name: 'OFFLINE',
        value: data.OFFLINE,
        color: color.fireBrick,
        url: offlineUrl,
      },
    ];

    return (
      <div className="DeviceStates">
        <PieChart
          title="Device States"
          width={275}
          legend="right"
          data={donutData}
          onClick={this.onClickPieChart}
        />
      </div>
    );
  }
}

DeviceStates.propTypes = {
  data: PropTypes.shape({
    ONLINE: PropTypes.number,
    OFFLINE: PropTypes.number,
    SHIPPING: PropTypes.number,
  }).isRequired,
  includeFlaggedLocations: PropTypes.bool,
};

DeviceStates.defaultProps = {
  includeFlaggedLocations: false,
};

export default withRouter(DeviceStates);
