import { handleActions } from 'redux-actions';
import User from '../../models/User';

const initialState = {
  authenticated: false,
  resetSuccess: false,
  user: new User(),
};

export default handleActions(
  {
    AUTHENTICATE_USER: (state, action) => {
      const userAuthenticated = action.payload;

      if (!userAuthenticated.authenticated) {
        return {
          ...state,
          authenticated: userAuthenticated.authenticated,
          user: new User(),
          availablePermissions: [],
        };
      }

      const userPermissions = userAuthenticated.user.permissions;

      const user = new User(userAuthenticated.user);

      return {
        ...state,
        user,
        authenticated: userAuthenticated.authenticated,
        availablePermissions: userPermissions,
      };
    },

    RESET_SUCCESS: (state, action) => {
      const resetSuccess = action.payload;
      return {
        ...state,
        resetSuccess,
      };
    },

    CLOSE_ACCOUNT_SETTINGS: (state) => {
      return {
        ...state,
        updatedAttributes: {
          name: '',
          family_name: '',
          email: '',
          phone: '',
        },
      };
    },

    UPDATE_USER: (state, action) => {
      const user = action.payload;

      return {
        ...state,
        user: new User({
          ...state.user,
          ...user,
        }),
      };
    },

    UPDATE_USER_PROFILE: (state, action) => {
      const updates = action.payload;
      switch (updates.attributeName) {
        case 'first_name':
          return {
            ...state,
            updatedAttributes: {
              ...state.updatedAttributes,
              name: updates.updatedAttribute,
            },
          };

        case 'last_name':
          return {
            ...state,
            updatedAttributes: {
              ...state.updatedAttributes,
              family_name: updates.updatedAttribute,
            },
          };

        case 'email':
          return {
            ...state,
            updatedAttributes: {
              ...state.updatedAttributes,
              email: updates.updatedAttribute,
            },
          };

        case 'phone':
          return {
            ...state,
            updatedAttributes: {
              ...state.updatedAttributes,
              phone: updates.updatedAttribute,
            },
          };

        default:
          return state;
      }
    },
  },
  // Initial State
  initialState,
);
