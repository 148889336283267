import React, { Component } from 'react';
import {
  MimeTypeHelper,
  VibeButton,
  VibeDialog,
  UploadDropzone,
} from 'vibeguide';
import { Grid } from '@mui/material';
import {
  ArrowForward,
  CloudUpload,
} from '@mui/icons-material';
import UploadStatus from './UploadStatus';
import './UploadContainer.scss';

class UploadContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      isUploadComplete: false,
    };
  }

  /**
   * Set the files to upload
   */
  setFilesToUpload = (files) => {
    this.setState(() => {
      return {
        files,
      };
    });
  };

  /**
   * Reset the upload status to allow more items to be uploaded
   */
  uploadMoreSongs = () => {
    this.setState(() => {
      return {
        files: [],
        isUploadComplete: false,
      };
    });
  };

  /**
   * All Uploading has been Completed
   */
  uploadComplete = () => {
    this.setState(() => {
      return {
        isUploadComplete: true,
      };
    });
  };

  render() {
    const {
      files,
      isUploadComplete,
    } = this.state;

    const editPendingSongsIcon = (
      <ArrowForward />
    );
    const uploadMoreSongsIcon = (
      <CloudUpload />
    );
    const isDropzone = files.length <= 0;

    return (
      <VibeDialog
        title="Upload Songs"
        closeDialogLink="/music/songs"
        open
        fullScreen
      >
        <div className="UploadContainer">
          <div className="header">
            <Grid container>
              <Grid item className="title" xs={6}>
                File Upload
              </Grid>

              {!isDropzone ? (
                <Grid item className="toolbar" xs={6}>
                  <VibeButton
                    className="toolbar-btn"
                    text="Upload More Songs"
                    btnColor="purple"
                    textColor="white"
                    icon={uploadMoreSongsIcon}
                    iconPlacement="left"
                    disabled={!isUploadComplete}
                    onClick={this.uploadMoreSongs}
                  />

                  <VibeButton
                    className="toolbar-btn"
                    text="Edit Pending Songs"
                    btnColor="green"
                    btnLink="/music/songs/publish"
                    textColor="white"
                    icon={editPendingSongsIcon}
                    iconPlacement="right"
                    disabled={!isUploadComplete}
                  />
                </Grid>
              ) : null}
            </Grid>
          </div>
          <div className={`content ${isDropzone ? 'is-dropzone' : ''}`}>
            {!isDropzone ? (
              <UploadStatus
                files={files}
                onComplete={this.uploadComplete}
              />
            ) : (
              <UploadDropzone
                acceptedMimeTypes={MimeTypeHelper.music}
                allowUpload
                onDrop={this.setFilesToUpload}
              />
            )}
          </div>
        </div>
      </VibeDialog>
    );
  }
}

export default UploadContainer;
