import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  toInteger,
} from 'lodash';
import {
  Field2 as Field,
  TimeUtil,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

const rankings = [
  {
    label: '',
    value: '',
  },
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'B',
    value: 'B',
  },
  {
    label: 'C',
    value: 'C',
  },
  {
    label: 'D',
    value: 'D',
  },
  {
    label: 'E',
    value: 'E',
  },
  {
    label: 'F',
    value: 'F',
  },
  {
    label: 'G',
    value: 'G',
  },
];

const ratings = [
  {
    label: '',
    value: '',
  },
  {
    label: 'G',
    value: 'g',
  },
  {
    label: 'Radio Edit',
    value: 'radio-edit',
  },
  {
    label: 'Explicit',
    value: 'explicit',
  },
];

const tempos = [
  {
    label: '',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
];

class Information extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    let updatedValue = value;

    if (name === 'genre' || name === 'iswc') {
      updatedValue = value.split(',');
    } else if (name === 'tempo') {
      updatedValue = toInteger(value);
    }

    onUpdate({
      [name]: updatedValue,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  onBlur = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    let updatedValue = value;

    if (name === 'genre' || name === 'iswc') {
      updatedValue = value.split(',').map(item => item.trim());
    }

    onUpdate({
      [name]: updatedValue,
    });
  };

  render() {
    const {
      user,
      name,
      artist,
      album,
      genre,
      copyright,
      isrc,
      iswc,
      releaseYear,
      durationSeconds,
      ranking,
      rating,
      ratingReason,
      tempo,
      source,
      tags,
      disableInput,
    } = this.props;

    const durationText = durationSeconds
      ? TimeUtil.convertDuration(durationSeconds, 'm:ss')
      : '';

    return (
      <div className="Information">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Name"
              name="name"
              placeholder="Name"
              value={name || ''}
              tabIndex={1}
              disabled={disableInput}
              onChange={this.onChange}
              required
              autoFocus
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Artist"
              name="artist"
              placeholder="Artist"
              value={artist || ''}
              tabIndex={2}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Album"
              name="album"
              placeholder="Album"
              value={album || ''}
              tabIndex={3}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Genre"
              name="genre"
              placeholder="Genre"
              value={genre || ''}
              tabIndex={4}
              disabled={disableInput}
              onChange={this.onChange}
              onBlur={this.onBlur}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Record Label"
              name="copyright"
              placeholder="Record Label"
              value={copyright || ''}
              tabIndex={5}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Release Year"
              name="releaseYear"
              placeholder="Release Year"
              value={releaseYear || ''}
              tabIndex={6}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="ISRC"
              name="isrc"
              placeholder="ISRC"
              value={isrc || ''}
              tabIndex={7}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="ISWC"
              name="iswc"
              placeholder="ISWC"
              value={iswc || ''}
              tabIndex={8}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="select"
              label="Rank"
              name="ranking"
              value={ranking || ''}
              options={rankings}
              tabIndex={9}
              disabled={disableInput}
              onChange={this.onChange}
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="select"
              label="Rating"
              name="rating"
              value={rating || ''}
              options={ratings}
              tabIndex={10}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="select"
              label="Tempo"
              name="tempo"
              value={tempo || 0}
              options={tempos}
              tabIndex={11}
              disabled={disableInput}
              onChange={this.onChange}
              required
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Reason"
              name="ratingReason"
              placeholder="Reason"
              value={ratingReason || ''}
              tabIndex={12}
              disabled={disableInput}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Source"
              name="source"
              placeholder="Source"
              value={source || ''}
              tabIndex={13}
              disabled={disableInput}
              onChange={this.onChange}
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="text"
              label="Duration"
              name="duration"
              placeholder="Duration"
              value={durationText || ''}
              tabIndex={14}
              readOnly
              disabled
              onChange={this.onChange}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                tags,
                allowTypes: ['admin'],
                showAs: 'list',
                assign: user.can('music.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={15}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  artist: PropTypes.string,
  album: PropTypes.string,
  genre: PropTypes.arrayOf(PropTypes.string),
  copyright: PropTypes.string,
  isrc: PropTypes.string,
  iswc: PropTypes.arrayOf(PropTypes.string),
  releaseYear: PropTypes.string,
  durationSeconds: PropTypes.number,
  tempo: PropTypes.number,
  source: PropTypes.string,
  ranking: PropTypes.string,
  rating: PropTypes.string,
  ratingReason: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  artist: '',
  album: '',
  genre: [],
  copyright: '',
  isrc: '',
  iswc: [],
  releaseYear: '',
  durationSeconds: 0,
  tempo: 0,
  source: '',
  ranking: '',
  rating: '',
  ratingReason: '',
  tags: [],
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
