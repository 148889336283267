import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  SidePanelTabs,
  VibeIcon,
  viArrowLeft,
  viSend,
  viChat,
  viTimeHistory,
  viClose,
  color,
} from 'vibeguide';
import ChatMessage from './Chat/ChatMessage';
import './MessageChat.scss';

class MessageChat extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      message,
    } = props;

    this.messagesRef = null;

    const isContentCurator = user.can('message.content_curator');
    const isVoiceTalent = user.voiceTalent && user._id === message.voiceTalentId;
    const isUser = !isContentCurator && !isVoiceTalent;

    this.state = {
      reply: '',
      // Reply to voice talent or client if your the content curator
      // Reply to the curator if you are the voice talent or client
      replyTo: isUser
        ? 'curator'
        : 'client',
      tabs: [{
        name: 'Client',
        selected: true,
      },
      {
        name: 'Voice Talent',
        selected: false,
      }],
      currentMessages: [],
    };
  }

  componentDidMount() {
    const {
      messagesRef,
    } = this;

    this.setState({
      currentMessages: this.getCurrentMessages(),
    }, () => {
      // Scroll to bottom of conversation
      messagesRef.scrollTop = messagesRef.scrollHeight;
    });
  }

  componentDidUpdate(prevProps) {
    const {
      message,
    } = this.props;

    const {
      message: prevMessage,
    } = prevProps;

    const {
      messagesRef,
    } = this;

    const activity = JSON.stringify(get(message, 'activity', []));
    const prevActivity = JSON.stringify(get(prevMessage, 'activity', []));

    if (message._id !== prevMessage._id) {
      messagesRef.scrollTop = messagesRef.scrollHeight;
    }

    if (activity !== prevActivity
    ) {
      this.setState({
        currentMessages: this.getCurrentMessages(),
      }, () => {
        // Scroll to bottom of conversation
        messagesRef.scrollTop = messagesRef.scrollHeight;
      });
    }
  }

  onRef = (ref) => {
    this.messagesRef = ref;
  };

  onChangeReply = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      reply: value,
    });
  };

  onKeyUpReply = (e) => {
    if (e.keyCode === 13) {
      this.sendChatMessage();
    }
  };

  /**
   * When a tab is selected
   */
  onSelectTab = (name) => {
    const {
      messagesRef,
    } = this;

    this.setState((state) => {
      return {
        tabs: state.tabs.map((tab) => {
          if (tab.name === name) {
            return {
              ...tab,
              selected: true,
            };
          }

          return {
            ...tab,
            selected: false,
          };
        }),
        replyTo: state.replyTo === 'client'
          ? 'voice-talent'
          : 'client',
      };
    }, () => {
      this.setState({
        currentMessages: this.getCurrentMessages(),
      }, () => {
        // Scroll to bottom of conversation
        messagesRef.scrollTop = messagesRef.scrollHeight;
      });
    });
  };

  getCurrentMessages = () => {
    const {
      user,
      message,
    } = this.props;

    const {
      replyTo,
    } = this.state;

    const isContentCurator = user.can('message.content_curator');
    // All messages in the active message
    const messages = get(message, 'activity', []);
    // Current array of messages to show in the conversation window
    const currentMessages = isContentCurator
      ? messages.filter((msg) => {
        const from = get(msg, 'detail.from', null);
        const rcpt = get(msg, 'detail.rcpt', null);

        if (
          msg.activityType !== 'conversation'
          || (from === 'curator' && rcpt === replyTo)
          || (from === replyTo && rcpt === 'curator')
        ) {
          // Add messages between curator and current selected reply to role
          return msg;
        }

        return false;
      })
      : messages;

    return currentMessages;
  };

  sendChatMessage = async () => {
    const {
      user,
      message,
      onRefresh,
    } = this.props;

    const {
      reply,
      replyTo,
    } = this.state;

    const isContentCurator = user.can('message.content_curator');
    const isVoiceTalent = user.voiceTalent && user._id === message.voiceTalentId;

    // From User Role
    let fromUserRole;
    // Recipient User Role
    let recipientUserRole;

    if (isContentCurator) {
      // User is a Content Curator
      fromUserRole = 'curator';
      recipientUserRole = 'client';

      if (replyTo === 'voice-talent') {
        // Send the message to the voice talent instead
        recipientUserRole = 'voice-talent';
      }
    } else if (isVoiceTalent) {
      // User is Voice Talent
      fromUserRole = 'voice-talent';
      // Voice Talent may only talk to the content curator
      recipientUserRole = 'curator';
    } else {
      // User is just a User
      fromUserRole = 'client';
      // User may only talk to the content curator
      recipientUserRole = 'curator';
    }

    console.warn('From', fromUserRole, 'To', recipientUserRole);

    if (!reply) {
      console.warn('No reply to send');
      return;
    }

    // Send a normal comment request
    await API.MessageRequest.addComment(message._id, {
      _id: message._id,
      from: fromUserRole,
      rcpt: recipientUserRole,
      comment: reply,
    });

    // Reset the reply box
    this.setState({
      reply: '',
    });

    // Refresh message
    onRefresh();
  };

  render() {
    const {
      user,
      message,
      type,
      onToggleDetails,
      onToggleHistory,
      onClose,
    } = this.props;

    const {
      currentMessages,
      reply,
      replyTo,
      tabs,
    } = this.state;

    const isContentCurator = user.can('message.content_curator');
    const voiceTalentFirst = get(message, 'voiceTalentFname', 'Voice Talent');
    const voiceTalentLast = get(message, 'voiceTalentLname', '');
    const creatorFirst = get(message, 'createdByUserFname', 'Client');
    const creatorLast = get(message, 'createdByUserLname', '');

    const voiceTalentName = `${voiceTalentFirst} ${voiceTalentLast}`;
    const creatorName = `${creatorFirst} ${creatorLast}`;
    let placeholder = 'Type additional comments...';

    if (isContentCurator && replyTo === 'voice-talent') {
      placeholder = `Talk to ${voiceTalentName}...`;
    } else if (isContentCurator && replyTo === 'client') {
      placeholder = `Talk to ${creatorName}...`;
    }

    return (
      <SidePanelContainer className="MessageChat">
        <SidePanelHeader
          icons={(
            <div>
              <VibeIcon
                id="chat"
                icon={viChat}
                color={message.changeRequested
                  ? color.flamingo
                  : color.violetVibe}
                hoverColor={color.obsidian}
                tooltip="Chat"
                size={24}
                onClick={onToggleDetails}
              />

              {isContentCurator ? (
                <VibeIcon
                  id="history"
                  icon={viTimeHistory}
                  color={color.manatee}
                  hoverColor={color.obsidian}
                  tooltip="History"
                  size={24}
                  onClick={onToggleHistory}
                />
              ) : null}

              <VibeIcon
                id="close-sidebar"
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
            </div>
          )}
        >
          <div className="flex-horizontal">
            <VibeIcon
              id="back"
              className="left-icon"
              icon={viArrowLeft}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onToggleDetails}
            />

            <div className="title">
              Chat
            </div>
          </div>
        </SidePanelHeader>

        {isContentCurator ? (
          <SidePanelTabs
            tabs={tabs}
            onSelectTab={this.onSelectTab}
          />
        ) : null}

        <SidePanelContent
          onRef={this.onRef}
        >
          <div className="messages">
            {currentMessages.map((msg, index) => {
              return (
                <ChatMessage
                  key={`${message.userId}-${index}`}
                  message={msg}
                />
              );
            })}
          </div>
        </SidePanelContent>

        {type === 'request' ? (
          <SidePanelFooter>
            <div className="input-container">
              <input
                ref={this.userInputRef}
                type="text"
                className="chat-message"
                placeholder={placeholder}
                value={reply}
                disabled={message.status === 'completed'}
                onChange={this.onChangeReply}
                onKeyUp={this.onKeyUpReply}
              />
            </div>

            <VibeIcon
              className="send-icon"
              icon={viSend}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={this.sendChatMessage}
            />
          </SidePanelFooter>
        ) : null}
      </SidePanelContainer>
    );
  }
}

MessageChat.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onToggleDetails: PropTypes.func,
  onToggleHistory: PropTypes.func,
  onRefresh: PropTypes.func,
};

MessageChat.defaultProps = {
  message: {},
  onClose: () => {},
  onToggleDetails: () => {},
  onToggleHistory: () => {},
  onRefresh: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(MessageChat);
