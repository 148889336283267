import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SnapshotDay from './SnapshotDay';
import './SnapshotGrid.scss';

function SnapshotGrid({
  className,
  playlists,
  mixes,
  messageBlocks,
  interruptions,
  disabled,
  onClickDay,
}) {
  const slots = [
    '12AM',
    '4AM',
    '8AM',
    '12PM',
    '4PM',
    '8PM',
    '12AM',
  ];

  return (
    <div className={classNames('SnapshotGrid', className)}>
      <div className="snapshot-grid">
        {slots.map((slot, index) => {
          const position = index + 1;

          return (
            <div
              key={`slot-${slot}-${index}`}
              className="time-grid"
              style={{
                top: 24 * position,
              }}
            />
          );
        })}
      </div>

      <div className="snapshot-columns">
        <div className="snapshot-col">
          {slots.map((slot, index) => {
            return (
              <div
                key={`slot-time-${slot}-${index}`}
                className="time"
              >
                {slot}
              </div>
            );
          })}
        </div>

        <div className="snapshot-col">
          S

          <div className="snapshot-data">
            <SnapshotDay
              day="Sun"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>

        <div className="snapshot-col">
          M

          <div className="snapshot-data">
            <SnapshotDay
              day="Mon"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>

        <div className="snapshot-col">
          T

          <div className="snapshot-data">
            <SnapshotDay
              day="Tue"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>

        <div className="snapshot-col">
          W

          <div className="snapshot-data">
            <SnapshotDay
              day="Wed"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>

        <div className="snapshot-col">
          T

          <div className="snapshot-data">
            <SnapshotDay
              day="Thu"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>

        <div className="snapshot-col">
          F

          <div className="snapshot-data">
            <SnapshotDay
              day="Fri"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>

        <div className="snapshot-col">
          S

          <div className="snapshot-data">
            <SnapshotDay
              day="Sat"
              playlists={playlists}
              stations={mixes}
              messageBlocks={messageBlocks}
              interruptions={interruptions}
              disabled={disabled}
              onClickDay={onClickDay}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SnapshotGrid.propTypes = {
  className: PropTypes.string,
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interruptions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onClickDay: PropTypes.func,
};

SnapshotGrid.defaultProps = {
  className: '',
  playlists: [],
  mixes: [],
  messageBlocks: [],
  interruptions: [],
  disabled: false,
  onClickDay: () => {},
};

export default SnapshotGrid;
