import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import CellTooltip from '../CellTypes/CellTooltip';
import color from '../../../sass/color.scss';

const tableId = 'table:visual:loops';
const permissionPrefix = 'visual_loop_template';

class TableVisualLoopTemplates extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Planned Duration',
      searchAttr: 'plannedDurationSeconds',
      defaultWidth: 110,
      resizable: true,
    },
    {
      name: 'Actual Duration',
      searchAttr: 'actualDurationSeconds',
      defaultWidth: 110,
      resizable: true,
    },
    {
      name: 'Max Hourly Ad Spots',
      searchAttr: 'maxHourlyAdSpots',
      defaultWidth: 110,
      resizable: true,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveVisualLoopTemplate', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveVisualLoopTemplate', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
        companyId,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = await API.Company.LoopTemplate.deactivate({
          _id,
          companyId,
        });

        const successText = 'VISUALLOOPTEMPLATE.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.Company.LoopTemplate.reactivate({
          _id,
          companyId,
        });

        const successText = 'VISUALLOOPTEMPLATE.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableVisualLoopTemplates', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          // bulkArchive={API.ScreenResolution.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableVisualLoopTemplates.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableVisualLoopTemplates.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableVisualLoopTemplates);
