import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EmptyState.scss';

function EmptyState({
  className,
  style,
  image,
  imageStyle,
  title,
  description,
  buttonHtml,
}) {
  // align the button position with the bottom of the empty state content area
  const [buttonPosition, setButtonPosition] = useState({
    bottom: 0,
    left: 0,
  });
  const contentRef = useRef(null);

  /**
   * Set the button position
   */
  const setButton = () => {
    const el = contentRef.current.getBoundingClientRect();

    // place button underneath the empty state text
    setButtonPosition({
      bottom: el.bottom - el.y,
    });
  };

  useEffect(() => {
    if (buttonHtml && contentRef && contentRef.current) {
      // set button position
      setButton();
    }
  }, []);

  return (
    <>
      <div
        className={classNames('EmptyState', className)}
        style={style}
      >
        <div className="empty-state-content">
          {image && (
            <div
              className={classNames('empty-state-image', {
                horizontal: style.height < 300,
              })}
            >
              <img
                src={image}
                style={{
                  maxHeight: 150,
                  ...imageStyle,
                }}
                alt="Empty"
              />
            </div>
          )}

          <div
            ref={contentRef}
            className={classNames('empty-state-text', {
              horizontal: style.height < 300,
            })}
          >
            <h2 className="title">
              {title}
            </h2>

            <div className="description">
              {description}
            </div>
          </div>
        </div>
      </div>

      {buttonHtml !== null && (
        <div
          className="button-wrapper"
          style={{
            bottom: buttonPosition.bottom,
            left: 0,
            right: 0,
          }}
        >
          {buttonHtml}
        </div>
      )}
    </>
  );
}

EmptyState.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Image */
  image: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Image Style */
  imageStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Title */
  title: PropTypes.string.isRequired,
  /** Description */
  description: PropTypes.string.isRequired,
  /** Button HTML */
  buttonHtml: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EmptyState.defaultProps = {
  className: '',
  image: '',
  style: {},
  imageStyle: {},
  buttonHtml: null,
};

export default EmptyState;
