import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import {
  API,
  NavigationSide,
  NavigationSideItem,
  viDataFlow,
} from 'vibeguide';

function ReportingContainer({
  className,
  style,
  user,
}) {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);

  /**
   * Get the Reports
   */
  const getReports = async () => {
    const response = await API.Partner.Reports.list(user.partnerId);
    setReports(response);
    setLoading(false);
  };

  /**
   * When the component loads, fetch the reports
   */
  useEffect(() => {
    getReports();
  }, []);

  return (
    <div
      className={classNames('ReportingContainer', className)}
      style={{
        height: '100%',
        marginLeft: 80,
        overflow: 'auto',
        ...style,
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Reporting Dashboard"
          url="/reporting/dashboard"
          matchUrl="/reporting"
          icon={viDataFlow}
          show={user.can('partner.view_report')}
        />
      </NavigationSide>

      <Outlet
        context={[
          reports,
          loading,
        ]}
      />

    </div>
  );
}

ReportingContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

ReportingContainer.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(ReportingContainer);
