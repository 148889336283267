import { handleActions } from 'redux-actions';
import { find } from 'lodash';

const initialState = {
  _id: null,
  name: '',
  companyId: '',
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  rating: '',
  long: '',
  lat: '',
  coordsLocked: false,
  locationImage: null,
  image: null,
  tags: [],
  salesforceId: '',
  bannerName: '',
  enableAdPrograms: false,
  thirdPartyData: {},
};

export default handleActions(
  {
    SET_LOCATION_DATA: (state, action) => {
      const data = action.payload;

      return {
        ...state,
        ...data,
      };
    },

    SET_LOCATION_ID: (state, action) => {
      const _id = action.payload;

      return {
        ...state,
        _id,
      };
    },

    SET_LOCATION_NAME: (state, action) => {
      const name = action.payload;

      return {
        ...state,
        name,
      };
    },

    SET_LOCATION_COMPANY: (state, action) => {
      const data = action.payload;

      const companyId = data.id !== undefined
        ? data.id
        : state.companyId;

      const companyName = data.value !== undefined
        ? data.value
        : state.companyName;

      return {
        ...state,
        companyId,
        companyName,
        // Only keep admin tags since client tags are linked to the company
        tags: state.companyId && state.companyId !== companyId
          ? state.tags.filter(tag => tag.type === 'admin')
          : state.tags,
      };
    },

    SET_ADDRESS1: (state, action) => {
      const address1 = action.payload;

      return {
        ...state,
        address1,
      };
    },

    SET_ADDRESS2: (state, action) => {
      const address2 = action.payload;

      return {
        ...state,
        address2,
      };
    },

    SET_CITY: (state, action) => {
      const city = action.payload;

      return {
        ...state,
        city,
      };
    },

    SET_LOCATION_STATE: (state, action) => {
      const locationState = action.payload;

      return {
        ...state,
        state: locationState,
      };
    },

    SET_ZIP: (state, action) => {
      const zip = action.payload;

      return {
        ...state,
        zip,
      };
    },

    SET_RATING: (state, action) => {
      const rating = action.payload;

      return {
        ...state,
        rating,
      };
    },

    SET_LATITUDE: (state, action) => {
      const lat = action.payload;

      return {
        ...state,
        lat,
      };
    },

    SET_LONGITUDE: (state, action) => {
      const long = action.payload;

      return {
        ...state,
        long,
      };
    },

    SET_COORDS_LOCKED_UNLOCKED: (state, action) => {
      const coordsLocked = action.payload;

      return {
        ...state,
        coordsLocked,
      };
    },

    SET_LOCATION_IMAGE: (state, action) => {
      const image = action.payload;

      return {
        ...state,
        image,
      };
    },

    SET_LOCATION_IMAGE_URL: (state, action) => {
      const imageUrl = action.payload;

      return {
        ...state,
        imageUrl,
      };
    },

    SET_LOCATION_TAGS: (state, action) => {
      const tags = action.payload;

      return {
        ...state,
        tags,
      };
    },

    ADD_LOCATION_TAG: (state, action) => {
      const tag = action.payload;
      const existingTag = find(state.tags, { _id: tag._id });

      // Tag is already added (and not queued for removal)
      if (existingTag && existingTag.status !== 'remove') {
        return state;
      }

      // Tag is already added (and queued for removal)
      // Update the tag to include its new status
      if (existingTag && existingTag.status === 'remove') {
        return {
          ...state,
          tags: state.tags.map((t) => {
            if (t._id === existingTag._id) {
              return {
                ...t,
                ...tag,
                status: existingTag.initialTag
                  ? 'unedited'
                  : tag.status,
              };
            }

            return t;
          }),
        };
      }

      // Add the new tag
      return {
        ...state,
        tags: [...state.tags, tag],
      };
    },

    REMOVE_LOCATION_TAG: (state, action) => {
      const tag = action.payload;
      // Find the tag if it already exists
      const existingTag = find(state.tags, { _id: tag._id });

      // Tag exists, add a remove status
      if (existingTag) {
        return {
          ...state,
          tags: state.tags.map((t) => {
            if (t._id === existingTag._id) {
              return {
                ...t,
                ...tag,
              };
            }

            return t;
          }),
        };
      }

      // Remove the tag
      return {
        ...state,
        tags: state.tags.filter(t => t._id !== tag._id),
      };
    },

    SET_LOCATION_BANNER_NAME: (state, action) => {
      const bannerName = action.payload;

      return {
        ...state,
        bannerName,
      };
    },

    SET_LOCATION_SALESFORCE_ID: (state, action) => {
      const salesforceId = action.payload;

      return {
        ...state,
        salesforceId,
      };
    },

    SET_LOCATION_ENABLE_AD_PROGRAMS: (state, action) => {
      const enableAdPrograms = action.payload;

      return {
        ...state,
        enableAdPrograms,
      };
    },

    RESET_NEW_LOCATION: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
