import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  sortBy,
} from 'lodash';
import {
  Field2 as Field,
  DaySelector,
  VibeButton,
} from 'vibeguide';
import DayPart from './DayPart';
import './SegmentDateTime.scss';

const hourOptions = [];
for (let i = 0; i <= 24; i++) {
  const hour = i < 10 ? `0${i}:00` : `${i}:00`;
  hourOptions.push({
    label: hour,
    value: hour,
  });
}

function SegmentDateTime({
  advertiserId,
  segmentLocations,
  startDate,
  endDate,
  daysOfWeek,
  dayParts,
  disableMedia,
  canPartMultiple,
  onUpdate,
}) {
  const onChangeStartDate = (startDate) => {
    onUpdate({
      startDate,
    });
  };

  const onChangeEndDate = (endDate) => {
    onUpdate({
      endDate,
    });
  };

  const onChangeDays = (days) => {
    onUpdate({
      daysOfWeek: days,
    });
  };

  const updateDayParts = (updatedPart, index) => {
    const updatedDayParts = [
      ...dayParts.slice(0, index),
      ...dayParts.slice(index + 1),
      updatedPart,
    ];

    onUpdate({
      dayParts: sortBy(updatedDayParts, 'startTime'),
    });
  };

  const onDayPartCreativeChange = (data, index) => {
    const updatedPart = {
      ...dayParts[index],
      creatives: data.creatives,
    };

    updateDayParts(updatedPart, index);
  };

  const onDayPartTimeChange = (e, index) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const updatedPart = {
      ...dayParts[index],
      [name]: value,
    };

    updateDayParts(updatedPart, index);
    e.target.blur();
  };

  const onDeleteDayPart = (dayPartIndex) => {
    const updatedDayParts = dayParts.filter((part, index) => index !== dayPartIndex);
    onUpdate({
      dayParts: sortBy(updatedDayParts, 'startTime'),
    });
  };

  const onAddDayPart = () => {
    onUpdate({
      dayParts: [
        ...dayParts,
        {
          startTime: null,
          endTime: null,
        },
      ],
    });
  };

  return (
    <div className="SegmentDateTime">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Date & Time Selection
        </Grid>
      </Grid>
      <Grid
        spacing={2}
        container
      >
        <Grid
          xs={6}
          item
        >
          <Field
            type="datepicker"
            label="Start Date"
            placeholder="YYYY-MM-DD"
            value={startDate}
            dateProps={{
              format: 'YYYY-MM-DD',
            }}
            tabIndex={12}
            onChange={onChangeStartDate}
            required
          />
        </Grid>
        <Grid
          xs={6}
          item
        >
          <Field
            type="datepicker"
            label="End Date"
            placeholder="YYYY-MM-DD"
            value={endDate}
            dateProps={{
              format: 'YYYY-MM-DD',
            }}
            tabIndex={13}
            onChange={onChangeEndDate}
            required
          />
        </Grid>
        <Grid
          xs={12}
          item
        >
          <Field
            type="custom"
            label="Days"
            tabIndex={14}
            required
          >
            <DaySelector
              style={{
                width: 250,
              }}
              dayFormat="dd"
              selectedDays={daysOfWeek}
              toggleAll
              onChange={onChangeDays}
            />
          </Field>
        </Grid>
        {(dayParts.length > 0) && (
          <Grid
            className="day-parts"
            xs={12}
            item
          >
            {dayParts.map((part, index) => (
              <DayPart
                key={index}
                canAddMedia={canPartMultiple}
                advertiserId={advertiserId}
                segmentLocations={segmentLocations}
                partIndex={index}
                part={part}
                totalParts={dayParts.length}
                hourOptions={hourOptions}
                disableMedia={disableMedia}
                onTimeChange={(e) => onDayPartTimeChange(e, index)}
                onCreativeChange={(data) => onDayPartCreativeChange(data, index)}
                onDelete={onDeleteDayPart}
              />
            ))}
          </Grid>
        )}
        {canPartMultiple && (
          <Grid
            xs={12}
            item
          >
            <VibeButton
              text="Add Day Part"
              btnColor="purple"
              textColor="white"
              onClick={onAddDayPart}
              disabled={dayParts.some(part => !part.startTime || !part.endTime)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

SegmentDateTime.propTypes = {
  advertiserId: PropTypes.string,
  segmentLocations: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  daysOfWeek: PropTypes.arrayOf(PropTypes.string),
  dayParts: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    creatives: PropTypes.arrayOf(PropTypes.shape({
      creativeId: PropTypes.string,
      weight: PropTypes.string,
    })),
  })),
  canPartMultiple: PropTypes.bool,
  disableMedia: PropTypes.bool,
  onUpdate: PropTypes.func,
};

SegmentDateTime.defaultProps = {
  advertiserId: '',
  segmentLocations: {},
  startDate: '',
  endDate: '',
  daysOfWeek: [],
  dayParts: [],
  canPartMultiple: false,
  disableMedia: false,
  onUpdate: () => {},
};

export default SegmentDateTime;
