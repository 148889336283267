import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MoreMenu from '../MoreMenu/MoreMenu';
import SwapButton from '../SwapButton/SwapButton';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCheckCircle from '../../icons/viCheckCircle';
import color from '../../sass/color.scss';
import './VibeCard.scss';

function VibeCard({
  children,
  className,
  width,
  height,
  style,
  menuItems,
  hideSelectedIcon,
  selected,
  disabled,
  link,
  swap,
  swapDirection,
  allowClick,
  onSelectMenuItem,
  onClickSwapButton,
  onClick,
}) {
  const hasMoreMenu = menuItems.length > 0;
  const vibeCard = (
    <div
      className={classNames('VibeCard', className, {
        clickable: allowClick,
        selected,
        disabled,
      })}
      style={{
        width,
        height,
        minWidth: width,
        padding: 16,
        ...style,
      }}
      onClick={allowClick
        ? onClick
        : null}
    >
      <div className="menu-container">
        {hasMoreMenu ? (
          <MoreMenu
            items={menuItems}
            icon="vertical"
            onSelect={onSelectMenuItem}
          />
        ) : null}

        {swap ? (
          <SwapButton
            className="swap-btn"
            arrowPlacement={swapDirection}
            arrowPosition={swapDirection}
            size={32}
            onClick={onClickSwapButton}
            arrowOnly
          />
        ) : null}
      </div>

      {children}

      {!hideSelectedIcon ? (
        <div className={`selected-check ${disabled ? 'disabled' : ''}`}>
          <VibeIcon
            icon={viCheckCircle}
            color={color.aquaForest}
            size={24}
          />
        </div>
      ) : null}
    </div>
  );

  if (link && allowClick) {
    return (
      <Link
        className="VibeCardLink"
        to={link}
      >
        {vibeCard}
      </Link>
    );
  }

  return vibeCard;
}

VibeCard.propTypes = {
  /** Custom card classes */
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** Allow card to be clicked */
  allowClick: PropTypes.bool,
  /** Width of card (in pixels) */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Height of card (in pixels) */
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Custom style for the card */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Array of menu items for the more menu */
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    // Check if user can perform the action
    userCan: PropTypes.string,
    // Navigate to a link when clicking the item
    link: PropTypes.string,
  })),
  /** Hide the selected icon */
  hideSelectedIcon: PropTypes.bool,
  /** When the card is selected */
  selected: PropTypes.bool,
  /** When the card is disabled */
  disabled: PropTypes.bool,
  /** Card link when clicked */
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Show swap icon */
  swap: PropTypes.bool,
  /** Swap direction */
  swapDirection: PropTypes.oneOf([
    'left',
    'right',
  ]),
  /** When a card is clicked */
  onClick: PropTypes.func,
  /** When a menu item is clicked */
  onSelectMenuItem: PropTypes.func,
  /** When the swap button is clicked */
  onClickSwapButton: PropTypes.func,
};

VibeCard.defaultProps = {
  className: '',
  allowClick: false,
  width: '100%',
  height: 'auto',
  style: {},
  menuItems: [],
  hideSelectedIcon: false,
  selected: false,
  disabled: false,
  link: null,
  swap: false,
  swapDirection: null,
  onClick: () => {},
  onSelectMenuItem: () => {},
  onClickSwapButton: () => {},
};

export default VibeCard;
