import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  get,
  forEach,
  isObject,
} from 'lodash';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import {
  getParams,
  updateParams,
} from '../../helpers/Navigation';
import VibeIcon from '../VibeIcon/VibeIcon';
import viMoreHorizontal from '../../icons/viMoreHorizontal';
import color from '../../sass/color.scss';
import './MoreMenu.scss';

class MoreMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.anchorRef = React.createRef();

    this.state = {
      menuOpen: false,
    };
  }

  handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      onMenuClose,
    } = this.props;

    onMenuClose();

    this.setState({
      menuOpen: false,
    });
  };

  onClickMenu = (e) => {
    // Prevent clicking on anything except menu items from triggering
    e.preventDefault();
    e.stopPropagation();
  };

  onClickMenuItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      onSelect,
    } = this.props;

    const {
      target: {
        dataset: {
          id,
          name = '',
        },
      },
    } = e;

    onSelect(name.toLowerCase(), id);
    this.handleClose(e);
  };

  toggleMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      items,
      onMenuOpen,
    } = this.props;

    if (items.length <= 0) {
      // No items to expand
      return;
    }

    this.setState((state) => {
      const menuOpen = !state.menuOpen;

      if (menuOpen) {
        onMenuOpen();
      }

      return {
        menuOpen,
      };
    });
  };

  render() {
    const {
      user,
      id,
      data,
      items,
      customIcon,
      anchorOrigin,
      transformOrigin,
      paperClassName,
    } = this.props;

    const {
      menuOpen,
    } = this.state;

    const {
      anchorRef: {
        current: anchorRef,
      },
    } = this;

    return (
      <div
        ref={this.anchorRef}
        onClick={this.toggleMenu}
      >
        {!customIcon ? (
          <VibeIcon
            icon={viMoreHorizontal}
            color={color.manatee}
            hoverColor={color.obsidian}
            disabled={items.length <= 0}
            size={24}
          />
        ) : customIcon}

        <Menu
          className="MoreMenu"
          classes={{
            paper: `MoreMenuPaper ${paperClassName}`,
          }}
          anchorEl={anchorRef}
          open={menuOpen}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={this.handleClose}
          onClick={this.onClickMenu}
        >
          {items.map((item) => {
            const {
              name,
              link,
              disabled,
              userCan,
              customHtml,
            } = item;

            const userCanType = typeof userCan;
            const nameType = typeof name;
            const disabledType = typeof disabled;

            // if name is a function, run to determine the name to use
            const useName = nameType === 'function'
              // pass the data row back to the parent to determine the name
              ? name(data)
              : name;

            // if disabled is a function, run to determine the disabled state of the row
            const useDisabled = disabledType === 'function'
              // pass the data row back to the parent to determine the name
              ? disabled(data)
              : disabled;

            const isDisabled = useDisabled
              // standard check if a user has a permission
              || (userCanType === 'string' && !user.can(userCan))
              // pass the data back to the parent to see if the user can do the action
              // (i.e. TableFlights/FlightContainer check row data to enable Archive menu item)
              || (userCanType === 'function' && !userCan(data));

            let linkDisplay;

            if (link && isObject(link)) {
              // Use URL parameters
              const urlObj = getParams();

              forEach(link, (value, key) => {
                // Replace the value of the object with the value from the item passed in
                // if no value is found in the object, use the value supplied in the URL obj
                urlObj[key] = get(data, value, value);
              });

              linkDisplay = updateParams(urlObj);
            } else if (link) {
              // Use a full URL link
              // Get the variable between the brackets in {varName}
              const fetchVar = link.substring(
                link.lastIndexOf('{') + 1,
                link.lastIndexOf('}'),
              );

              linkDisplay = link.replace(`{${fetchVar}}`, get(data, fetchVar, null));
            }

            if (customHtml) {
              // Show custom HTML for this item instead of a link
              return (
                <div
                  key={useName}
                  className="more-menu-item static"
                >
                  {customHtml}
                </div>
              );
            }

            return (
              <MenuItem
                key={useName}
                className="more-menu-item"
                style={{
                  // height: item.link ? 'auto' : 14,
                  height: 'auto',
                  // padding: item.link ? 0 : 'auto',
                  padding: 0,
                }}
                disabled={isDisabled}
                data-id={id}
                data-name={useName}
                onClick={this.onClickMenuItem}
              >
                {link ? (
                  <Link
                    to={linkDisplay}
                    data-id={id}
                    data-name={useName}
                  >
                    {useName}
                  </Link>
                ) : (
                  <div className="non-link">
                    {useName}
                  </div>
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

MoreMenu.propTypes = {
  /** Item ID */
  id: PropTypes.string,
  /** Data for the more menu link */
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Items */
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]).isRequired,
    disabled: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    // Check if user can perform the action
    userCan: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    // Navigate to a link when clicking the item
    link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    // Custom HTML for the item
    customHtml: PropTypes.element,
  })),
  /** Custom icon */
  customIcon: PropTypes.element,
  /** Anchor Origin */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Transform Origin */
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Paper classes */
  paperClassName: PropTypes.string,
  /** When a menu item is selected */
  onSelect: PropTypes.func,
  /** When a menu is opened */
  onMenuOpen: PropTypes.func,
  /** When a menu is closed */
  onMenuClose: PropTypes.func,
};

MoreMenu.defaultProps = {
  id: null,
  data: {},
  items: [],
  customIcon: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  paperClassName: '',
  onSelect: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(MoreMenu);
