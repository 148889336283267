import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  findIndex,
  forEach,
  uniq,
  size,
  omit,
  isObject,
} from 'lodash';
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CircularProgress } from '@mui/material';
import withRouter from '../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../types/tablePropsType';
import {
  getSettings,
  removeSetting,
  saveColumn,
  saveDensity,
  savePageSize,
  removeSettings,
} from '../../helpers/Table';
import {
  getParams,
  updateParams,
} from '../../helpers/Navigation';
import {
  setDrag,
  resetDrag,
} from '../../actions/Global/GlobalActions';
import EmptyState from '../EmptyState/EmptyState';
import Submenu from './Submenu';
import Toolbar from './Toolbar';
import VibeCell from './VibeCell';
import HeaderCell from './CellTypes/HeaderCell';
import ManageColumns from './CellTypes/ManageColumns';
import MoreMenu from '../MoreMenu/MoreMenu';
import VibeCheckbox from '../VibeCheckbox/VibeCheckbox';
import VibeModal from '../VibeModal/VibeModal';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCloseCircle from '../../icons/viCloseCircle';
import viAddCircle from '../../icons/viAddCircle';
import color from '../../sass/color.scss';
import './VibeTable.scss';

class VibeTable extends PureComponent {
  constructor(props) {
    super(props);

    const {
      submenu,
      submenuProps: {
        items: submenuItems,
      },
      draggable,
      droppable,
    } = props;

    this.tableRef = React.createRef();
    this.staticGridRef = React.createRef();
    this.staticBulkEditGridRef = React.createRef();
    this.dataGridRef = React.createRef();

    // mouse Y position when dragging a cell
    this.dragY = 0;
    // drop placement for the row (above or below)
    this.dropPlacement = null;

    if (draggable) {
      this.dragRef = React.createRef();
    }

    if (droppable) {
      this.dragLineRef = React.createRef();
    }

    // current scroll position for when other grids are rendered
    this.scrollLeft = 0;

    const settings = this.getSettings();

    // Get submenu items that are shown and disable the submenu if 1 or less
    const numSubmenuItems = submenu && submenuItems
      ? submenuItems.filter(item => item.show === true).length
      : 0;

    this.state = {
      allColumns: settings.allColumns,
      columns: settings.columns,
      density: settings.density,
      selected: [],
      avgColumnWidth: 0,
      totalColumnWidth: 0,
      tableRect: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      showSubmenu: numSubmenuItems > 1,
      // show the bulk edit table header (animation for the table grid)
      showBulkEdit: false,
      // bulk edit is active for the table
      isBulkEdit: false,
      // bulk edit data
      bulkEditData: {},
      // confirm add/remove from the table header
      confirmAddRemove: false,
      // when the columns change do not render the header cell changes
      columnVisibilityChanged: false,
      // when loading the data when a user confirms add/remove all
      loadingAddRemoveAll: false,
    };

    window.addEventListener('resize', this.onResize);
    document.addEventListener('onUpdateTable', this.onUpdateTable);
    document.addEventListener('onRenderTable', this.onRenderTable);
    document.addEventListener('onToggleTableCollapse', this.onToggleTableCollapse);
    document.addEventListener('onResetSelectedRows', this.onResetSelectedRows);
  }

  componentDidMount() {
    this.handleColumnWidths();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('onUpdateTable', this.onUpdateTable);
    document.removeEventListener('onRenderTable', this.onRenderTable);
    document.removeEventListener('onToggleTableCollapse', this.onToggleTableCollapse);
    document.removeEventListener('onResetSelectedRows', this.onResetSelectedRows);
  }

  /**
   * Determine if the columns need to be resized based on the browser window size
   */
  handleColumnWidths = (options = {}) => {
    const {
      columns,
    } = this.state;

    const {
      ignoreColumns = [],
    } = options;

    // add the select / more actions columns to the ignore list
    ignoreColumns.push('.');
    ignoreColumns.push('...');

    const totalColumnWidth = columns.reduce((acc, column) => {
      return acc + column.width;
    }, 0);

    const avgColumnWidth = totalColumnWidth / columns.length;
    const {
      top,
      left,
      width,
      height,
    } = this.getTableDimensions();

    if (totalColumnWidth < width) {
      // not enough columns to fill the viewport
      this.extendColumns({
        tableWidth: width,
        ignoreColumns,
      });
    }

    this.setState({
      avgColumnWidth,
      totalColumnWidth,
      tableRect: {
        top,
        left,
        width,
        height,
      },
    });
  };

  /**
   * When the browser is resized
   */
  onResize = () => {
    const {
      rows,
    } = this.props;

    // when there are no results, update the table container size
    if (rows.length <= 0) {
      this.setState({
        tableRect: this.getTableDimensions(),
      });
    }
  };

  /**
   * When a table is collapsed or expanded
   */
  onToggleTableCollapse = () => {
    this.setState({
      tableRect: this.getTableDimensions(),
    });
  };

  /**
   * When the table data needs to be updated
   */
  onUpdateTable = () => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      refresh: true,
      data: {
        pageNumber: 1,
      },
    });

    this.updateSelectedRows([]);
  };

  /**
   * Force re-render the table rows/columns
   */
  onRenderTable = () => {
    this.resizeCells({
      rowIndex: 0,
      columnIndex: 0,
    });
  };

  /**
   * When the table selected rows need to be cleared
   */
  onResetSelectedRows = () => {
    this.updateSelectedRows([]);
  };

  /**
   * Scroll the header grid horizontally with the data grid
   */
  onScroll = ({
    scrollLeft,
    scrollUpdateWasRequested,
  }) => {
    if (!scrollUpdateWasRequested) {
      const {
        isBulkEdit,
      } = this.state;

      const {
        staticGridRef: {
          current: staticGridRef,
        },
        staticBulkEditGridRef: {
          current: staticBulkEditGridRef,
        },
      } = this;

      staticGridRef.scrollTo({
        scrollTop: 0,
        scrollLeft,
      });

      // scroll the bulk edit header if shown
      if (isBulkEdit && staticBulkEditGridRef) {
        staticBulkEditGridRef.scrollTo({
          scrollTop: 0,
          scrollLeft,
        });
      }

      // set the current scroll left position when other grids are rendered
      this.scrollLeft = scrollLeft;
    }
  };

  /**
   * Scroll the table header columns
   * Mainly used when tabbing through columns
   */
  onScrollTableHeader = ({
    scrollLeft,
  }) => {
    const {
      dataGridRef: {
        current: dataGridRef,
      },
    } = this;

    if (dataGridRef && dataGridRef.scrollLeft !== scrollLeft) {
      dataGridRef.scrollTo({
        // scrollTop: 0,
        scrollLeft,
      });
    }
  };

  onResizeCell = ({
    columnIndex,
    width,
    save = false,
  }) => {
    const {
      tableId,
    } = this.props;

    const {
      columns,
    } = this.state;

    const column = columns[columnIndex];
    column.width = width;

    // Recompute the data cell widths
    this.resizeCells({
      columnIndex,
    });

    if (save) {
      // save the column width in local storage
      saveColumn({
        tableId,
        name: column.name,
        width: column.width,
      });

      // re-compute the column widths if there is empty space in the viewport
      this.handleColumnWidths({
        ignoreColumns: [
          columns[columnIndex].name,
        ],
      });
    }
  };

  /**
   * When the select all checkbox is toggled
   */
  onChangeSelectAll = ({
    checked,
  }) => {
    const {
      rows,
    } = this.props;

    const {
      selected,
    } = this.state;

    const indeterminate = selected.length < rows.length;

    if (checked || (!checked && indeterminate)) {
      // Select all rows currently unselected on this page
      const unselected = rows.filter(row => find(selected, { _rowId: row._rowId }) === undefined);

      this.updateSelectedRows([...selected, ...unselected]);
    } else {
      // Deselect all rows currently selected on this page
      this.updateSelectedRows([]);
    }
  };

  /**
   * When the select checkbox is toggled
   */
  onChangeSelect = ({
    name,
    checked,
  }) => {
    const {
      rows,
    } = this.props;

    const {
      selected,
    } = this.state;

    const row = find(rows, { _rowId: name });

    if (row) {
      const selectedRows = checked
        ? [...selected, row]
        : selected.filter(item => item._rowId !== row._rowId);

      this.updateSelectedRows(selectedRows);
    }
  };

  onChangePage = (pageNumber) => {
    const {
      onUpdate,
    } = this.props;

    this.resetScroll({
      top: 0,
    });

    onUpdate({
      refresh: true,
      data: {
        pageNumber,
      },
    });

    // this.updateSelectedRows([]);
  };

  onClearFilters = () => {
    const {
      onUpdate,
    } = this.props;

    this.resetScroll({
      top: 0,
      left: 0,
    });

    onUpdate({
      refresh: true,
      data: {
        filters: {},
      },
    });

    this.updateSelectedRows([]);
  };

  onToggleArchive = () => {
    const {
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
      toggleProps: {
        onToggle,
      },
      active: currActive,
      history,
      onUpdate,
    } = this.props;

    const active = !currActive;

    if (urlPaging || urlFilters) {
      const params = {};

      if (urlPaging) {
        // back to page 1
        params.page = null;
      }

      if (urlFilters) {
        params.active = !active
          ? false
          : null;
      }

      const path = updateParams(params);
      history(path);
    }

    this.resetScroll({
      top: 0,
      left: 0,
    });

    onUpdate({
      refresh: true,
      data: {
        active,
        pageNumber: 1,
      },
    });

    this.updateSelectedRows([]);

    // tell the parent component the archive toggle was triggered
    if (onToggle) {
      onToggle(active);
    }
  };

  onChangeDensity = (density) => {
    const {
      tableId,
    } = this.props;

    this.resizeCells({
      rowIndex: 0,
    });

    if (density === 'compact') {
      // default density is compact, remove the custom setting
      removeSetting({
        tableId,
        setting: 'density',
      });
    } else {
      // save the custom density setting
      saveDensity({
        tableId,
        density,
      });
    }

    this.setState({
      density,
    });
  };

  onChangePageSize = (pageSize) => {
    const {
      tableId,
      paginatorProps: {
        urlPaging,
      },
      history,
      onUpdate,
    } = this.props;

    savePageSize({
      tableId,
      pageSize,
    });

    onUpdate({
      refresh: true,
      data: {
        pageSize,
        pageNumber: 1,
      },
    });

    this.updateSelectedRows([]);

    this.resetScroll({
      top: 0,
    });

    if (urlPaging) {
      // reset page number back to the first page
      const path = updateParams({
        page: null,
      });

      history(path);
    }
  };

  /**
   * Toggle a column to be shown/hidden from the table
   */
  onToggleColumnVisible = (columnNames = []) => {
    const {
      tableId,
    } = this.props;

    const {
      allColumns,
    } = this.state;

    // toggle visibility for each pending column change
    columnNames.forEach((columnName) => {
      const column = find(allColumns, { name: columnName });

      saveColumn({
        tableId,
        name: columnName,
        hide: !column.hide,
      });
    });

    const settings = this.getSettings();

    this.setState({
      allColumns: settings.allColumns,
      columns: settings.columns,
      columnVisibilityChanged: true,
    }, () => {
      // toggle visibility for each pending column change
      this.handleColumnWidths();

      this.resizeCells({
        columnIndex: 0,
      });

      this.setState({
        columnVisibilityChanged: false,
      });
    });
  };

  /**
   * When changing the column sort by
   */
  onToggleColumnSort = () => {
    this.resetScroll({
      top: 0,
    });

    this.updateSelectedRows([]);
  };

  onClickSubmenuItem = () => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      refresh: true,
      data: {
        pageNumber: 1,
      },
    });

    this.resetScroll({
      top: 0,
      left: 0,
    });

    this.updateSelectedRows([]);
  };

  onChangeActiveSubmenuItem = (data) => {
    const {
      columns,
    } = data;

    const {
      tableId,
      columnsDef,
      columnNames: columnNamesProp,
      onChangeActiveSubmenuItem,
    } = this.props;

    const columnNames = !columns ? columnNamesProp : columns;

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.setState({
      columns: settings.columns,
      allColumns: settings.allColumns,
      density: settings.density,
    }, () => {
      // toggle visibility for each pending column change
      this.handleColumnWidths();

      this.resizeCells({
        columnIndex: 0,
      });

      this.setState({
        columnVisibilityChanged: false,
      });
    });

    onChangeActiveSubmenuItem(data);
  };

  onBulkEdit = (isBulkEdit) => {
    const {
      bulkProps: {
        editType,
        onToggle = () => {},
      },
    } = this.props;

    const {
      selected,
    } = this.state;

    if (editType === 'sidebar') {
      onToggle('edit', selected);
      return;
    }

    const renderBulkEditTimeout = isBulkEdit
      // render the bulk edit header immediately
      ? 0
      // remove the bulk edit header after the animation completes
      : 600;

    setTimeout(() => {
      this.setState({
        isBulkEdit,
      });
    }, renderBulkEditTimeout);

    // animate the bulk edit header sliding into view
    setTimeout(() => {
      const {
        staticBulkEditGridRef: {
          current: staticBulkEditGridRef,
        },
        scrollLeft,
      } = this;

      // scroll the bulk edit header to the current left position
      staticBulkEditGridRef.scrollTo({
        scrollLeft,
      });

      // animate the header
      this.setState({
        showBulkEdit: isBulkEdit,
      });
    }, 100);

    if (!isBulkEdit) {
      // closing the bulk edit header, reset the bulk edit data
      this.setState({
        bulkEditData: {},
      });
    }
  };

  /**
   * When bulk edits are saved
   */
  onBulkEditApply = () => {
    const {
      onBulkEditApply,
    } = this.props;

    const {
      selected,
      bulkEditData,
    } = this.state;

    // animate bulk edit closing
    this.setState({
      showBulkEdit: false,
    });

    // remove bulk edit header after animation completes
    setTimeout(() => {
      this.setState({
        isBulkEdit: false,
      });
    }, 600);

    if (size(bulkEditData) > 0) {
      // tell parent table to apply the edits
      onBulkEditApply({
        selected,
        data: bulkEditData,
      });

      this.updateSelectedRows([]);

      // Reset bulk edit data
      this.setState({
        bulkEditData: {},
      });
    }
  };

  /**
   * Bulk Add Selected
   */
  onBulkAdd = () => {
    const {
      bulkProps: {
        onToggle = () => {},
      },
    } = this.props;

    const {
      selected,
    } = this.state;

    onToggle('add', selected);
  };

  /**
   * Bulk Block Selected
   */
  onBulkBlock = () => {
    const {
      bulkProps: {
        onToggle = () => {},
      },
    } = this.props;

    const {
      selected,
    } = this.state;

    onToggle('block', selected);
  };

  onBulkArchive = async () => {
    const {
      bulkArchive,
      onBulkArchive,
    } = this.props;

    const {
      selected,
    } = this.state;

    const selectedIds = selected.map(item => item._id);
    const response = await bulkArchive(selectedIds);

    const success = response.filter(result => result.type.indexOf('DEACTIVATED') < 0).length <= 0;

    if (success) {
      onBulkArchive();
      this.updateSelectedRows([]);
    }
  };

  /**
   * When a bulk edit column is changed
   */
  onChangeBulkEditColumn = ({
    columnName,
    value,
  }) => {
    const {
      columns,
    } = this.state;

    const column = find(columns, { name: columnName });
    const attr = column.valueAttr || column.searchAttr;

    if (!value) {
      // remove the bulk edit data for this column
      this.setState((state) => {
        return {
          bulkEditData: omit(state.bulkEditData, [attr]),
        };
      });

      return;
    }

    switch (attr) {
      // Set the bulk data based on the attribute being set
      // This could be different for each column type
      default:
        this.setState((state) => {
          return {
            bulkEditData: {
              ...state.bulkEditData,
              [attr]: value,
            },
          };
        });
        break;
    }
  };

  /**
   * When a cell begins to be dragged
   */
  onDragStart = (e, _rowId) => {
    const {
      tableId,
      rows,
      draggable,
      dragProps: {
        dragMultiple,
        contentDrawer,
      },
      setDrag,
    } = this.props;

    const {
      selected,
    } = this.state;

    if (!draggable) {
      return;
    }

    const {
      dragRef: {
        current: dragRef,
      },
    } = this;

    const {
      dataTransfer,
    } = e;

    const row = find(rows, { _rowId });
    const width = 300;
    const height = 50;

    const dragData = dragMultiple && selected.length > 1
      ? selected
      : [row];

    let dragType = null;

    switch (tableId) {
      case 'table:baselines':
        dragType = 'Baseline';
        break;
      case 'table:playlists':
        dragType = 'Playlist';
        break;
      case 'table:stations':
        dragType = 'Mix';
        break;
      case 'table:messages':
        dragType = 'Message';
        break;
      case 'table:message:blocks':
        dragType = 'Message Block';
        break;
      case 'table:songs':
        dragType = 'Song';
        break;
      default:
        break;
    }

    dataTransfer.setData('row:data', JSON.stringify(dragData));

    const dragText = dragMultiple && selected.length > 1
      ? `${row.name} (+${selected.length - 1} more)`
      : row.name;

    // Safari 3.0+ "[object HTMLElementConstructor]"
    // eslint-disable-next-line wrap-iife, func-names, dot-notation, no-undef, max-len
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    const dragRefRect = dragRef.getBoundingClientRect();
    const crt = dragRef.cloneNode(true);
    const dragWidth = width || dragRefRect.width;

    crt.style.width = `${width}px`;
    crt.style.height = `${height}px`;
    crt.style.display = 'flex';
    crt.style.flexDirection = 'column';
    crt.style.justifyContent = 'center';
    crt.style.position = 'absolute';
    crt.style.top = `${dragRefRect.top}px`;
    crt.style.left = `-${width}px`;
    crt.style.zIndex = '2000';
    crt.innerHTML = dragText;

    document.body.appendChild(crt);

    // Set the drag image to follow the drop line
    const dragImage = isSafari ? dragRef : crt;
    dataTransfer.setDragImage(dragImage, dragWidth / 2, 10);

    setDrag({
      tableId,
      dragging: true,
      dragIds: dragData.map(item => item._rowId),
      dragType,
      contentDrawer,
    });

    document.dispatchEvent(new Event('onDragStartVibeTable'));
  };

  onDragOver = (e, cellRef) => {
    e.preventDefault();

    const {
      droppable,
    } = this.props;

    const {
      clientY,
    } = e;

    if (!droppable) {
      return;
    }

    if (clientY !== this.dragY) {
      const {
        dragLineRef: {
          current: dragLineRef,
        },
      } = this;

      const rowRect = cellRef.getBoundingClientRect();
      const {
        top,
        bottom,
        height,
      } = rowRect;

      // get the halfway mark for the cell
      const halfHeight = height / 2;
      const halfway = top + halfHeight;
      const isTopHalf = clientY <= halfway;

      if (isTopHalf) {
        // drop on the row above
        this.dropPlacement = 'above';
        dragLineRef.style.top = `${top}px`;
      } else {
        // drop on the row below
        this.dropPlacement = 'below';
        dragLineRef.style.top = `${bottom}px`;
      }

      this.dragY = clientY;
    }
  };

  /**
   * When a cell stops being dragged
   * If the table row isn't rendered when the item is dropped this event will not be called
   */
  onDragEnd = () => {
    const {
      draggable,
      droppable,
      resetDrag,
    } = this.props;

    resetDrag();
    document.dispatchEvent(new Event('onDragEndVibeTable'));

    if (!draggable || !droppable) {
      return;
    }

    const {
      dragLineRef: {
        current: dragLineRef,
      },
    } = this;

    if (dragLineRef) {
      dragLineRef.style.top = '-4px';
    }

    this.dropPlacement = null;
    this.dragY = 0;
  };

  /**
   * When an item being dragged is dropped on a column
   */
  onDrop = (e, _rowId) => {
    const {
      collection,
      rows,
      onUpdate,
      onChange,
    } = this.props;

    const {
      dataTransfer,
    } = e;

    const dataTransferData = JSON.parse(dataTransfer.getData('row:data'));
    // get the item being dropped
    const dropItem = get(dataTransferData, '[0]');

    // use collection for tables with local (non-API) data
    const useData = collection.length > 0
      ? collection
      : rows;

    const dropRowIndex = findIndex(useData, { _rowId });
    const newRows = [];

    useData.forEach((row, index) => {
      // do not add the original row if it was the one dropped (move the row)
      const isOriginalRow = row._rowId === dropItem._rowId;

      if (dropRowIndex === index) {
        // drop the new data in the proper place
        if (this.dropPlacement === 'above') {
          // add dropped data before the row
          newRows.push(dropItem);

          if (!isOriginalRow) {
            newRows.push(row);
          }
        } else if (this.dropPlacement === 'below') {
          // add dropped data after the row
          if (!isOriginalRow) {
            newRows.push(row);
          }

          newRows.push(dropItem);
        }
      } else if (!isOriginalRow) {
        // add the existing row
        newRows.push(row);
      }
    });

    onChange(newRows);

    onUpdate({
      data: {
        rows: newRows,
      },
    });
  };

  /**
   * When a cell is clicked, trigger the row click
   */
  onClickRow = (_rowId) => {
    const {
      rows,
      onClickRow,
    } = this.props;

    if (onClickRow) {
      const row = find(rows, { _rowId });
      onClickRow(row);
    }
  };

  /**
   * When clicking add/remove all in the header
   */
  onClickAddRemoveAll = () => {
    this.setState({
      confirmAddRemove: true,
    });
  };

  /**
   * Remove all items matching filter criteria
   */
  onConfirmRemoveAll = async () => {
    const {
      onRemove,
      onFetch,
    } = this.props;

    this.setState({
      loadingAddRemoveAll: true,
    });

    const allItems = await onFetch({
      pageSize: -1,
    });

    this.setState({
      confirmAddRemove: false,
      loadingAddRemoveAll: false,
    });

    onRemove(allItems);
  };

  /**
   * Add all items matching filter criteria
   */
  onConfirmAddAll = async () => {
    const {
      highlight,
      onAdd,
      onFetch,
    } = this.props;

    this.setState({
      loadingAddRemoveAll: true,
    });

    const allItems = await onFetch({
      pageSize: -1,
    });

    const addItems = [];

    // add each item on this page to the assigned table
    allItems.forEach((item) => {
      // is the item assigned already
      const assigned = find(highlight, { _id: item._id }) !== undefined;

      if (!assigned) {
        // add the item if it isn't already assigned
        addItems.push(item);
      }
    });

    this.setState({
      confirmAddRemove: false,
      loadingAddRemoveAll: false,
    });

    if (addItems.length > 0) {
      onAdd(addItems);
    }
  };

  /**
   * Cancel adding/removing all items matching filter criteria
   */
  onCancelAddRemoveAll = () => {
    this.setState({
      confirmAddRemove: false,
    });
  };

  /**
   * When a menu item is selected on a row
   */
  onSelectMenuItem = (itemName, _rowId) => {
    const {
      onSelectMenuItem,
    } = this.props;

    // reset the table selected rows
    this.updateSelectedRows([]);

    // pass the selection onto the parent component
    onSelectMenuItem(itemName, _rowId);
  };

  /**
   * Reset the table settings back to default
   */
  onReset = () => {
    const {
      tableId,
      onReset,
    } = this.props;

    removeSettings(tableId);

    const settings = this.getSettings();

    this.setState({
      allColumns: settings.allColumns,
      columns: settings.columns,
      density: settings.density,
      selected: [],
    }, () => {
      this.handleColumnWidths();

      this.resizeCells({
        columnIndex: 0,
        rowIndex: 0,
      });
    });

    onReset();
  };

  /**
   * Get table settings
   */
  getSettings = () => {
    const {
      tableId,
      columnsDef,
      columnNames,
    } = this.props;

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    return settings;
  };

  /**
   * Get the link for all cells in the table (rowLink)
   */
  getCellLink = (row) => {
    const {
      rowLink,
    } = this.props;

    if (!rowLink) {
      return null;
    }

    // send the row data back to the parent component to determine the link
    if (typeof rowLink === 'function') {
      return rowLink(row);
    }

    let linkDisplay;

    if (rowLink && isObject(rowLink)) {
      // Use URL parameters
      const urlObj = getParams();

      forEach(rowLink, (value, key) => {
        // Replace the value of the object with the value from the item passed in
        urlObj[key] = get(row, value, null);
      });

      linkDisplay = updateParams(urlObj);
    } else if (rowLink) {
      // Use a full URL link
      // Get the variable between the brackets in {varName}
      const fetchVar = rowLink.substring(
        rowLink.lastIndexOf('{') + 1,
        rowLink.lastIndexOf('}'),
      );

      linkDisplay = rowLink.replace(`{${fetchVar}}`, get(row, fetchVar, null));
    }

    return linkDisplay;
  };

  /**
   * Get the table dimensions
   */
  getTableDimensions = () => {
    const {
      tableRef: {
        current: tableRef,
      },
    } = this;

    const tableRect = tableRef.getBoundingClientRect();
    const {
      top,
      left,
      width,
      height,
    } = tableRect;

    return {
      top,
      left,
      width,
      height,
    };
  };

  /**
   * Get the row height for the table data
   */
  getRowHeight = () => {
    const {
      density,
    } = this.state;

    switch (density) {
      case 'comfortable':
        return 84;

      case 'compact':
        return 44;

      case 'normal':
      default:
        return 64;
    }
  };

  /**
   * Resize table cells
   */
  resizeCells = ({
    columnIndex = -1,
    rowIndex = -1,
  }) => {
    const {
      isBulkEdit,
    } = this.state;

    const {
      staticGridRef: {
        current: staticGridRef,
      },
      staticBulkEditGridRef: {
        current: staticBulkEditGridRef,
      },
      dataGridRef: {
        current: dataGridRef,
      },
    } = this;

    if (staticGridRef && dataGridRef) {
      if (columnIndex > -1) {
        // Recompute the data cells width
        staticGridRef.resetAfterColumnIndex(columnIndex);
        dataGridRef.resetAfterColumnIndex(columnIndex);

        if (isBulkEdit && staticBulkEditGridRef) {
          // bulk edit header is shown
          staticBulkEditGridRef.resetAfterColumnIndex(columnIndex);
        }
      }

      if (rowIndex > -1) {
        // Recompute the data cells height
        staticGridRef.resetAfterRowIndex(rowIndex);
        dataGridRef.resetAfterRowIndex(rowIndex);

        if (isBulkEdit && staticBulkEditGridRef) {
          // bulk edit header is shown
          staticBulkEditGridRef.resetAfterRowIndex(rowIndex);
        }
      }
    }
  };

  /**
   * Reset the scroll position of both the header/data grids
   */
  resetScroll = ({
    top = -1,
    left = -1,
  }) => {
    const {
      isBulkEdit,
    } = this.state;

    const {
      staticGridRef: {
        current: staticGridRef,
      },
      staticBulkEditGridRef: {
        current: staticBulkEditGridRef,
      },
      dataGridRef: {
        current: dataGridRef,
      },
    } = this;

    const scrollTo = {};

    if (top > -1) {
      scrollTo.scrollTop = top;
    }

    if (left > -1) {
      scrollTo.scrollLeft = left;
    }

    staticGridRef.scrollTo(scrollTo);
    dataGridRef.scrollTo(scrollTo);

    if (isBulkEdit && staticBulkEditGridRef) {
      // bulk edit header is shown
      staticBulkEditGridRef.scrollTo(scrollTo);
    }
  };

  /**
   * Extend the column widths to fill the viewport
   */
  extendColumns = ({
    tableWidth,
    ignoreColumns = [],
  }) => {
    const {
      columns,
    } = this.state;

    // console.log('extend columns to fill viewport except', ignoreColumns);

    // columns to resize (must be resizable) (exclude checkbox and any other column to be ignored)
    const resizeColumns = columns.filter(column => !ignoreColumns.includes(column.name) && column.resizable);

    // if (resizeColumns.length <= 0) {
    //   // no columns left to resize
    //   console.log('no columns to resize, force resizable columns to be resized');
    //   resizeColumns = columns.filter(column => column.resizable);
    // }

    // total percentage of columns being used
    const totalPercentage = columns.reduce((acc, column) => {
      const columnPercentage = (column.width / tableWidth) * 100;
      return acc + columnPercentage;
    }, 0);

    // remaining percentage required to fill empty space
    const remainingPercentage = 100 - totalPercentage;
    // percentage to add to each column
    const avgPercentage = (remainingPercentage / resizeColumns.length) / 100;
    // convert percentage to add into pixels
    const avgPixels = avgPercentage * tableWidth;

    resizeColumns.forEach((column, index) => {
      // add the average pixels to each column to fill the viewport
      column.width += avgPixels;

      if (index === resizeColumns.length - 1) {
        // remove the width of the scrollbar
        column.width -= 16;
      }
    });

    this.resizeCells({
      columnIndex: 0,
    });
  };

  /**
   * Set selected rows
   */
  updateSelectedRows = (selected) => {
    const {
      onChangeSelected,
    } = this.props;

    const {
      selected: currSelected,
    } = this.state;

    // only update if the selected rows have changed
    if (selected.length !== currSelected.length) {
      this.setState({
        selected,
      });

      onChangeSelected(selected);
    }
  };

  /**
   * Standard Header Cells
   */
  renderHeaderCell = ({
    columnIndex,
    style,
  }) => {
    const {
      tableId,
      rows,
      totalItems,
      sortBy,
      defaultSortBy,
      paginatorProps: {
        urlFilters,
      },
      filters,
      permissionPrefix,
      assigned,
      onUpdate,
    } = this.props;

    const {
      allColumns,
      columns,
      selected,
      columnVisibilityChanged,
    } = this.state;

    const column = columns[columnIndex];

    // render the select row checkbox
    if (column.name === '.') {
      const checked = selected.length > 0;
      const indeterminate = selected.length < rows.length;

      return (
        <VibeCell
          style={{
            ...style,
            borderRight: 'none',
          }}
          contentStyle={{
            padding: 0,
          }}
          columnIndex={columnIndex}
          header
        >
          <VibeCheckbox
            checked={checked}
            indeterminate={indeterminate}
            rootStyle={{
              paddingRight: 0,
            }}
            style={{
              marginLeft: 'auto',
            }}
            onChange={this.onChangeSelectAll}
          />
        </VibeCell>
      );
    }

    if (column.name === '...') {
      return (
        <VibeCell
          style={style}
          contentStyle={{
            padding: '0 0 0 24px',
          }}
          columnIndex={columnIndex}
          header
        >
          <ManageColumns
            columns={allColumns}
            onToggleVisible={this.onToggleColumnVisible}
          />
        </VibeCell>
      );
    }

    if (column.name === 'Add/Remove'
      || column.name === 'Add/Remove Quantity'
    ) {
      // convert the total items to a comma separated number
      const totalItemsFormatted = totalItems.toLocaleString();

      const tooltip = assigned
        ? `Remove All ${totalItemsFormatted} Rows`
        : `Add All ${totalItemsFormatted} Rows`;

      return (
        <VibeCell
          style={style}
          columnIndex={columnIndex}
          header
        >
          <VibeIcon
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.fireBrick
              : color.aquaForest}
            hoverColor={assigned
              ? color.fireBrick75
              : color.aquaForest75}
            size={24}
            tooltip={column.allowAddRemoveAll === false
              ? 'Unable to Add/Remove All Rows'
              : tooltip}
            tooltipProps={{
              placement: 'right',
            }}
            disabled={column.allowAddRemoveAll === false}
            onClick={this.onClickAddRemoveAll}
          />
        </VibeCell>
      );
    }

    return (
      <VibeCell
        style={style}
        columnIndex={columnIndex}
        lastColumn={columnIndex === columns.length - 1}
        resizable={column.resizable}
        header
        onResizeCell={this.onResizeCell}
      >
        <HeaderCell
          tableId={tableId}
          column={column}
          sortBy={sortBy}
          defaultSortBy={defaultSortBy}
          autoFocus={column.autoFocus}
          urlFilters={urlFilters}
          filters={filters}
          permissionPrefix={permissionPrefix}
          columnVisibilityChanged={columnVisibilityChanged}
          onUpdate={onUpdate}
          onToggleSort={this.onToggleColumnSort}
          onChangeBulkEditColumn={this.onChangeBulkEditColumn}
        />
      </VibeCell>
    );
  };

  /**
   * Bulk Edit Header Cells
   */
  renderBulkEditHeaderCell = ({
    columnIndex,
    style,
  }) => {
    const {
      tableId,
      rows,
      totalItems,
      sortBy,
      defaultSortBy,
      paginatorProps: {
        urlFilters,
      },
      filters,
      permissionPrefix,
      assigned,
      onUpdate,
    } = this.props;

    const {
      allColumns,
      columns,
      selected,
      isBulkEdit,
      bulkEditData,
    } = this.state;

    const column = columns[columnIndex];

    // render the select row checkbox
    if (column.name === '.') {
      const checked = selected.length > 0;
      const indeterminate = selected.length < rows.length;

      return (
        <VibeCell
          style={{
            ...style,
            borderRight: 'none',
          }}
          contentStyle={{
            padding: 0,
          }}
          columnIndex={columnIndex}
          header
          bulkEdit={isBulkEdit}
        >
          <VibeCheckbox
            checked={checked}
            indeterminate={indeterminate}
            rootStyle={{
              paddingRight: 0,
            }}
            style={{
              marginLeft: 'auto',
            }}
            onChange={this.onChangeSelectAll}
          />
        </VibeCell>
      );
    }

    if (column.name === '...') {
      return (
        <VibeCell
          style={style}
          contentStyle={{
            padding: '0 0 0 24px',
          }}
          columnIndex={columnIndex}
          header
          bulkEdit={isBulkEdit}
        >
          <ManageColumns
            columns={allColumns}
            onToggleVisible={this.onToggleColumnVisible}
          />
        </VibeCell>
      );
    }

    if (column.name === 'Add/Remove'
      || column.name === 'Add/Remove Quantity'
    ) {
      // convert the total items to a comma separated number
      const totalItemsFormatted = totalItems.toLocaleString();

      return (
        <VibeCell
          style={style}
          columnIndex={columnIndex}
          header
          bulkEdit={isBulkEdit}
        >
          <VibeIcon
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.fireBrick
              : color.aquaForest}
            hoverColor={assigned
              ? color.fireBrick75
              : color.aquaForest75}
            size={24}
            tooltip={assigned
              ? `Remove All ${totalItemsFormatted} Rows`
              : `Add All ${totalItemsFormatted} Rows`}
            tooltipProps={{
              placement: 'right',
            }}
            onClick={this.onClickAddRemoveAll}
          />
        </VibeCell>
      );
    }

    // get the company IDs to use tags for client or admin only
    const companyIds = isBulkEdit && column.name === 'Tags'
      ? uniq(selected.map(item => item.companyId)).filter(item => item !== undefined)
      : [];

    return (
      <VibeCell
        style={style}
        columnIndex={columnIndex}
        lastColumn={columnIndex === columns.length - 1}
        resizable={column.resizable}
        header
        bulkEdit={isBulkEdit}
        onResizeCell={this.onResizeCell}
      >
        <HeaderCell
          tableId={tableId}
          column={column}
          sortBy={sortBy}
          defaultSortBy={defaultSortBy}
          autoFocus={column.autoFocus}
          urlFilters={urlFilters}
          filters={filters}
          bulkEdit={isBulkEdit}
          bulkEditData={bulkEditData}
          // company IDs of the selected rows
          companyIds={companyIds}
          permissionPrefix={permissionPrefix}
          onUpdate={onUpdate}
          onToggleSort={this.onToggleColumnSort}
          onChangeBulkEditColumn={this.onChangeBulkEditColumn}
        />
      </VibeCell>
    );
  };

  renderCell = ({
    columnIndex,
    rowIndex,
    style,
  }) => {
    const {
      rows,
      errorIds,
      menuItems,
      draggable,
      available,
      highlight,
      renderCell,
      onClickRow,
    } = this.props;

    const {
      columns,
      selected,
    } = this.state;

    const column = columns[columnIndex];
    const row = rows[rowIndex];
    // const checked = find(selected, { _rowId: row._rowId }) !== undefined;
    const checked = find(selected, { _id: row._id }) !== undefined;
    // is the table for available objects and is it highlighted
    const assigned = available && find(highlight, { _id: row._id }) !== undefined;
    const cellLink = this.getCellLink(row);
    // does the row have an error
    const error = errorIds.includes(row._id);

    if (column.name === '.') {
      // render the select row checkbox
      return (
        <VibeCell
          rowId={row._rowId}
          rowName={row.name}
          style={{
            ...style,
            borderRight: 'none',
          }}
          contentStyle={{
            padding: 0,
          }}
          columnIndex={columnIndex}
          error={error}
          checked={checked}
          assigned={assigned}
          draggable={draggable}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          <VibeCheckbox
            name={row._rowId}
            checked={checked}
            rootStyle={{
              paddingRight: 0,
            }}
            style={{
              marginLeft: 'auto',
            }}
            onChange={this.onChangeSelect}
          />
        </VibeCell>
      );
    }

    if (column.name === '...') {
      return (
        <VibeCell
          rowId={row._rowId}
          rowName={row.name}
          style={style}
          contentStyle={{
            padding: '0 0 0 24px',
          }}
          columnIndex={columnIndex}
          lastColumn={columnIndex === columns.length - 1}
          error={error}
          checked={checked}
          assigned={assigned}
          draggable={draggable}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          <MoreMenu
            id={row._rowId}
            data={row}
            items={typeof menuItems === 'function'
              ? menuItems(row).map((menuItem) => {
                if (menuItem.name === 'Archive') {
                  return row.active
                    ? menuItem
                    : { ...menuItem, name: 'Unarchive' };
                }

                return menuItem;
              })
              : menuItems.map((menuItem) => {
                if (menuItem.name === 'Archive') {
                  return row.active
                    ? menuItem
                    : { ...menuItem, name: 'Unarchive' };
                }

                return menuItem;
              })}
            onSelect={this.onSelectMenuItem}
          />
        </VibeCell>
      );
    }

    return (
      <VibeCell
        rowId={row._rowId}
        rowName={row.name}
        style={style}
        columnIndex={columnIndex}
        lastColumn={columnIndex === columns.length - 1}
        error={error}
        link={cellLink}
        resizable={column.resizable}
        checked={checked}
        assigned={assigned}
        draggable={draggable}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        onResizeCell={this.onResizeCell}
        onClick={onClickRow
          ? this.onClickRow
          : null}
      >
        {renderCell({
          column,
          row,
        })}
      </VibeCell>
    );
  };

  renderEmptyCell = () => {
    return null;
  };

  render() {
    const {
      className,
      style,
      toolbarStyle,
      tableId,
      collection,
      rows,
      active,
      pageNumber,
      pageSize,
      totalItems,
      paginator,
      paginatorProps,
      toggle,
      toggleProps,
      bulk,
      bulkProps,
      submenu,
      submenuProps,
      filters,
      loading,
      draggable,
      droppable,
      toolbarProps,
      emptyProps,
      assigned,
      allowHeaderRowScroll,
    } = this.props;

    const {
      columns,
      selected,
      density,
      avgColumnWidth,
      totalColumnWidth,
      tableRect,
      showSubmenu,
      showBulkEdit,
      isBulkEdit,
      confirmAddRemove,
      loadingAddRemoveAll,
    } = this.state;

    // height of all visible table components except table data
    const preDataHeight = showSubmenu
      // submenu, toolbar, header row
      ? 198
      // toolbar, header row
      : 154;

    // format the total items to a comma separated string
    const totalItemsFormatted = totalItems.toLocaleString();

    return (
      <div
        ref={this.tableRef}
        className={classNames('VibeTable', className)}
        style={style}
      >

        {showSubmenu ? (
          <Submenu
            rootUrl={submenuProps.rootUrl}
            style={submenuProps.style}
            items={submenuProps.items}
            onClickItem={this.onClickSubmenuItem}
            onChangeActiveSubmenuItem={this.onChangeActiveSubmenuItem}
          />
        ) : null}

        <Toolbar
          style={toolbarStyle}
          page={pageNumber}
          density={density}
          submenu={submenu}
          submenuProps={submenuProps}
          paginator={paginator}
          paginatorProps={{
            totalItems,
            ...paginatorProps,
          }}
          toggle={toggle}
          toggleProps={{
            toggled: !active,
            ...toggleProps,
          }}
          bulk={bulk}
          bulkProps={bulkProps}
          filters={filters}
          selectedIds={selected.map(item => item._id)}
          pageSize={pageSize}
          pageSizes={[
            25,
            50,
            100,
            150,
            200,
          ]}
          customButtons={!isBulkEdit
            // show custom buttons when bulk edit is not active
            ? toolbarProps.customButtons
            : []}
          customHtmlLeft={toolbarProps.customHtmlLeft}
          customHtmlRight={toolbarProps.customHtmlRight}
          onChangePageSize={this.onChangePageSize}
          onChangeDensity={this.onChangeDensity}
          onBulkEdit={this.onBulkEdit}
          onBulkEditApply={this.onBulkEditApply}
          onBulkAdd={this.onBulkAdd}
          onBulkBlock={this.onBulkBlock}
          // onBulkRemove={this.onBulkRemove}
          onBulkArchive={this.onBulkArchive}
          // onSingleItemApply={this.onSingleItemApply}
          // onSingleItemCancel={this.onSingleItemCancel}
          onChangeToggle={this.onToggleArchive}
          onChangePage={this.onChangePage}
          onClearFilters={this.onClearFilters}
          onReset={this.onReset}
        />

        <div className="table-grid">
          <AutoSizer>
            {({ height, width }) => (
              <div className="table-grid-content">
                <Grid
                  ref={this.staticGridRef}
                  className="header-grid"
                  style={{
                    overflow: 'hidden',
                    // allow scrolling of the header row
                    overflowX: allowHeaderRowScroll
                      ? 'auto'
                      : 'hidden',
                  }}
                  width={width}
                  height={100}
                  columnCount={columns.length}
                  columnWidth={(index) => {
                    if (index === columns.length - 1) {
                      // last column, give extra width so grid lines stay aligned
                      return columns[index].width + 24;
                    }

                    return columns[index].width;
                  }}
                  estimatedColumnWidth={avgColumnWidth}
                  // renders columns outside of view (used for tabbing to columns outside of view)
                  overscanColumnCount={columns.length}
                  rowCount={1}
                  rowHeight={() => 100}
                  onScroll={this.onScrollTableHeader}
                >
                  {this.renderHeaderCell}
                </Grid>

                {isBulkEdit ? (
                  <Grid
                    ref={this.staticBulkEditGridRef}
                    className={classNames('bulk-edit-header-grid', { show: showBulkEdit })}
                    style={{
                      overflow: 'hidden',
                      position: 'absolute',
                      left: 0,
                    }}
                    width={width}
                    height={100}
                    columnCount={columns.length}
                    columnWidth={(index) => {
                      if (index === columns.length - 1) {
                        // last column, give extra width
                        return columns[index].width + 24;
                      }

                      return columns[index].width;
                    }}
                    estimatedColumnWidth={avgColumnWidth}
                    // renders columns outside of view (used for tabbing to columns outside of view)
                    overscanColumnCount={columns.length}
                    rowCount={1}
                    rowHeight={() => 100}
                  >
                    {this.renderBulkEditHeaderCell}
                  </Grid>
                ) : null}

                <Grid
                  ref={this.dataGridRef}
                  className="data-grid"
                  width={width}
                  height={height - 100}
                  columnCount={columns.length}
                  columnWidth={index => columns[index].width}
                  estimatedColumnWidth={avgColumnWidth}
                  // renders columns outside of view (used for tabbing to columns outside of view)
                  // only use where we need to tab through all the data columns of a table (i.e. songs edit mode)
                  overscanColumnCount={tableId === 'table:songs'
                    ? columns.length
                    : 1}
                  rowCount={rows.length <= 0
                    ? 1
                    : rows.length}
                  rowHeight={this.getRowHeight}
                  onScroll={this.onScroll}
                >
                  {rows.length > 0
                    ? this.renderCell
                    : this.renderEmptyCell}
                </Grid>

                {rows.length <= 0 ? (
                  <EmptyState
                    image={assigned && collection.length > 0
                      // using local data, show no results when searching
                      ? tablePropsDefault.emptyProps.image
                      // show the custom empty data
                      : emptyProps.image}
                    imageStyle={emptyProps.imageStyle}
                    title={assigned && collection.length > 0
                      // using local data, show no results when searching
                      ? tablePropsDefault.emptyProps.title
                      // show the custom empty data
                      : emptyProps.title}
                    description={assigned && collection.length > 0
                      // using local data, show no results when searching
                      ? tablePropsDefault.emptyProps.description
                      // show the custom empty data
                      : emptyProps.description}
                    buttonHtml={emptyProps.buttonHtml}
                    style={{
                      // adding pointer events prevents scrolling horizontally with a trackpad
                      // removing pointer events prevents clicking any element inside
                      // we moved the "View All" button outside of the element to remain clickable
                      pointerEvents: 'none',
                      position: 'absolute',
                      top: 100,
                      left: 0,
                      width: '100%',
                      height:
                        totalColumnWidth < width
                          ? tableRect.height - preDataHeight
                          // offset the horizontal scrollbar height
                          : tableRect.height - preDataHeight - 15,
                    }}
                  />
                ) : null}

                {loading ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 100,
                      left: 0,
                      width: '100%',
                      height: tableRect.height - preDataHeight,
                      paddingTop: 32,
                      backdropFilter: 'blur(6px)',
                      textAlign: 'center',
                      color: color.violetVibe,
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      size={75}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </AutoSizer>
        </div>

        <VibeModal
          show={confirmAddRemove}
          type="confirm"
          confirmProps={{
            text: assigned
              ? (
                <div>
                  {loadingAddRemoveAll ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          flexGrow: 1,
                          textAlign: 'left',
                        }}
                      >
                        Removing
                      </div>

                      <CircularProgress
                        style={{
                          color: color.violetVibe,
                        }}
                        color="inherit"
                        size={12}
                      />
                    </div>
                  ) : 'Yes, Remove All'}
                </div>
              )
              : (
                <div>
                  {loadingAddRemoveAll ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          flexGrow: 1,
                          textAlign: 'left',
                        }}
                      >
                        Adding
                      </div>

                      <CircularProgress
                        style={{
                          color: color.violetVibe,
                        }}
                        color="inherit"
                        size={12}
                      />
                    </div>
                  ) : 'Yes, Add All'}
                </div>
              ),
            color: assigned
              ? color.fireBrick
              : color.aquaForest,
            style: {
              textAlign: 'right',
            },
          }}
          cancelProps={{
            text: 'No Thanks',
            color: color.manatee,
          }}
          title={assigned
            ? `Remove All ${totalItemsFormatted} Rows?`
            : `Assign All ${totalItemsFormatted} Rows?`}
          text={assigned
            ? 'This will remove all matched rows in the table. Continue?'
            : 'This will assign all matched rows in the table. Continue?'}
          onConfirm={assigned
            ? this.onConfirmRemoveAll
            : this.onConfirmAddAll}
          onClose={this.onCancelAddRemoveAll}
        />

        {draggable ? (
          <div
            ref={this.dragRef}
            className="VibeTableDrag"
          />
        ) : null}

        {droppable ? (
          <div
            ref={this.dragLineRef}
            id="drag-line"
            style={{
              pointerEvents: 'none',
              display: 'block',
              position: 'fixed',
              top: -4,
              left: 0,
              width: '100%',
              height: 4,
              background: color.manatee,
              zIndex: 2000,
            }}
          />
        ) : null}
      </div>
    );
  }
}

VibeTable.propTypes = {
  tableId: PropTypes.string.isRequired,
  className: PropTypes.string,
  columnsDef: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    dataType: PropTypes.oneOf([
      'string',
      'array',
      'array-multiple',
    ]),
    searchAttr: PropTypes.string,
    // when search attribute differs from the response attribute
    valueAttr: PropTypes.string,
    defaultWidth: PropTypes.number,
    resizable: PropTypes.bool,
    searchable: PropTypes.bool,
    sortable: PropTypes.bool,
    editable: PropTypes.bool,
    locked: PropTypes.bool,
    hide: PropTypes.bool,
    datepicker: PropTypes.bool,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      disabled: PropTypes.bool,
      placeholder: PropTypes.string,
    })),
  })).isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  permissionPrefix: PropTypes.string,
  loading: PropTypes.bool,
  sortBy: PropTypes.shape({
    label: PropTypes.string,
    attr: PropTypes.string,
    direction: PropTypes.oneOf([
      'asc',
      'desc',
    ]),
  }),
  bulkArchive: PropTypes.func,
  renderCell: PropTypes.func,
  onSelectMenuItem: PropTypes.func,
  onBulkEditApply: PropTypes.func,
  onBulkArchive: PropTypes.func,
  onReset: PropTypes.func,
  /** Fetch data from the API or collection (configured at the table-level) */
  onFetch: PropTypes.func,
  onUpdate: PropTypes.func,
  onChangeActiveSubmenuItem: PropTypes.func,
  ...tablePropsType,
};

VibeTable.defaultProps = {
  className: '',
  rows: [],
  permissionPrefix: '',
  loading: false,
  sortBy: tablePropsDefault.defaultSortBy,
  bulkArchive: () => {},
  renderCell: () => {},
  onSelectMenuItem: () => {},
  onBulkEditApply: () => {},
  onBulkArchive: () => {},
  onReset: () => {},
  onFetch: () => {},
  onUpdate: () => {},
  onChangeActiveSubmenuItem: () => {},
  ...tablePropsDefault,
};

const mapDispatchToProps = {
  setDrag,
  resetDrag,
};

export default withRouter(connect(null, mapDispatchToProps)(VibeTable));
