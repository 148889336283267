import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  get,
  find,
  head,
  capitalize,
} from 'lodash';
import {
  API,
  NavigationHelper,
  GlobalActions,
  Field2 as Field,
  LocationActions,
  Submenu,
  Tag,
  TelemetryStatus,
  VibeButton,
  VibeIcon,
  viAlert,
  viFlag,
  viTrash,
  viCached,
  VibeButtonGroup,
  VibeModal,
  color,
  withRouter,
} from 'vibeguide';
import SupportSidebar from '../../../Dashboard/SupportSidebar/SupportSidebar';
import AdReporting from './Telemetry/AdReporting';
import TelemetryMediaPlayed from './Telemetry/TelemetryMediaPlayed';
import TelemetryLog from './Telemetry/TelemetryLog';
import AlertHistory from './Telemetry/AlertHistory';
import './Telemetry.scss';

class Telemetry extends PureComponent {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;

    this.state = {
      // when the disable the buttons to wait until resolved
      disableRestart: false,
      disableClearCache: false,
      showConfirmRestart: false,
      showConfirmClearCache: false,
      telemetry: {},
      devices: [],
      devicesFetched: false,
      selectedDeviceId: null,
    };
  }

  componentDidMount() {
    const {
      socket,
    } = this.props;

    socket.on('VAPI_EVENT', this.onApiEvent);
    this.getTelemetry();

    const qs = NavigationHelper.getParams() || {};
    const isView = qs.locationId;

    if (isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.locationId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      socket,
      currentLocation,
      location: {
        search,
      },
    } = this.props;

    const {
      socket: prevSocket,
      currentLocation: prevCurrentLocation,
      location: {
        search: prevSearch,
      },
    } = prevProps;

    const locationId = get(currentLocation, '_id');
    const prevLocationId = get(prevCurrentLocation, '_id');

    if (socket !== prevSocket) {
      socket.off('VAPI_EVENT', this.onApiEvent);
      socket.on('VAPI_EVENT', this.onApiEvent);
    }

    if (locationId && !prevLocationId) {
      this.getTelemetry();
    }

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.locationId) {
        this.sidebar(qs.type, qs.locationId);
      }
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    socket.off('VAPI_EVENT', this.onApiEvent);

    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      locationId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  onApiEvent = (e) => {
    if (e.type === 'LOCATION.COMMAND_ISSUED') {
      // Disable the command for clicking for other potential users on the page
      const commandType = get(e, 'data.commandType', null);
      const data = {};

      switch (commandType) {
        case 'clear-media':
          data.disableClearCache = true;
          break;

        case 'restart':
          data.disableRestart = true;
          break;

        default:
          console.warn('Unknown command type', commandType);
          break;
      }

      // Prevent the button from being clicked again
      this.setState(data);
    } else if (e.type === 'LOCATION.COMMAND_RESOLVED') {
      const commandType = get(e, 'data.commandType', null);
      const data = {};

      switch (commandType) {
        case 'clear-media':
          data.disableClearCache = false;
          break;

        case 'restart':
          data.disableRestart = false;
          break;

        default:
          console.warn('Unknown command type', commandType);
          break;
      }

      // Allow the button to be clicked again
      this.setState(data);
    }
  };

  /**
   * When the restart button is clicked
   */
  onClickRestart = (e) => {
    e.stopPropagation();

    this.setState({
      showConfirmRestart: true,
    });
  };

  onConfirmRestart = async () => {
    const {
      currentLocation: {
        _id,
      },
    } = this.props;

    const {
      selectedDeviceId,
    } = this.state;

    const response = await API.Location.Player.command({
      _id,
      deviceId: selectedDeviceId,
      commandType: 'restart',
    });

    const success = get(response, '[0].type') === 'LOCATION.COMMAND_ISSUED';

    if (!success) {
      console.error('Command not sent');
      return;
    }

    this.setState({
      disableRestart: true,
      showConfirmRestart: false,
    });
  };

  onCancelRestart = () => {
    this.setState({
      showConfirmRestart: false,
    });
  };

  /**
   * When the clear cache button is clicked
   */
  onClickClearCache = (e) => {
    e.stopPropagation();

    this.setState({
      showConfirmClearCache: true,
    });
  };

  onConfirmClearCache = async () => {
    const {
      currentLocation: {
        _id,
      },
    } = this.props;

    const {
      selectedDeviceId,
    } = this.state;

    const response = await API.Location.Player.command({
      _id,
      deviceId: selectedDeviceId,
      commandType: 'clear-media',
      parameters: {
        mediaId: '*',
      },
    });

    const success = get(response, '[0].type') === 'LOCATION.COMMAND_ISSUED';

    if (!success) {
      console.error('Command not sent');
      return;
    }

    this.setState({
      disableClearCache: true,
      showConfirmClearCache: false,
    });
  };

  onCancelClearCache = () => {
    this.setState({
      showConfirmClearCache: false,
    });
  };

  /**
   * When the device changes
   */
  onChangeDevice = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      history,
    } = this.props;

    // remove the ?page from the URL if it exists
    history(NavigationHelper.updateParams({
      page: null,
    }));

    this.setState({
      selectedDeviceId: value,
    }, this.getTelemetry);
  };

  /**
   * Get Location Telemetry
   */
  getTelemetry = async () => {
    const {
      currentLocation,
    } = this.props;

    const {
      devices,
      selectedDeviceId,
    } = this.state;

    const locationId = get(currentLocation, '_id');

    if (!locationId) {
      return;
    }

    // Get list of devices
    const deviceList = !selectedDeviceId
      ? await API.DeviceManagement.list({
        filters: {
          locationId,
        },
      })
      : devices;

    const device = selectedDeviceId
      // a device is already selected in the list
      ? find(deviceList, { _id: selectedDeviceId })
      // select the live device, or the first one in the list of devices if none are live
      : find(deviceList, { activationStatus: 'live' }) || head(deviceList);

    if (!device) {
      // needed for loading the ad reporting tab on a location without a selected device
      this.setState({
        devicesFetched: true,
      });

      console.error('No Device Selected');
      return;
    }

    const telemetry = await API.Location.Telemetry.Device.current({
      _id: locationId,
      deviceId: device._id,
    });

    this.setState({
      devicesFetched: true,
      devices: deviceList,
      selectedDeviceId: device._id,
      telemetry,
    });
  };

  sidebar = (type, locationId = null) => {
    const {
      setPanel,
      user,
    } = this.props;

    const canManageSettings = user.can('alert.manage_settings');

    setPanel({
      show: true,
      backdrop: false,
      width: 400,
      children: (
        <SupportSidebar
          locationId={locationId}
          type={type}
          canManageSettings={canManageSettings}
          refresh={this.refresh}
          onClose={this.onCloseSidebar}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
      params,
      currentLocation,
    } = this.props;

    const {
      disableRestart,
      disableClearCache,
      showConfirmRestart,
      showConfirmClearCache,
      telemetry,
      devicesFetched,
      devices,
      selectedDeviceId,
    } = this.state;

    if (!currentLocation) {
      return null;
    }

    const bamboo = get(telemetry, 'info.packageName') === 'Bamboo';
    const mandroid = get(telemetry, 'info.osVersion') === '8.111111.0'
      || get(telemetry, 'info.packageName', '').indexOf('adPlayer') >= 0;

    const subpage = get(params, 'type', 'status');
    const canIssueCommand = user.can('location.issue_command');
    let deviceType = bamboo
      ? 'Bamboo'
      : 'Tablet';

    if (mandroid) {
      deviceType = 'MAndroid';
    }

    const selectedDevice = find(devices, { _id: selectedDeviceId }) || {};

    return (
      <div className="Telemetry">
        <div className="content-header">
          <div className="content-title">
            <Field
              type="select"
              style={{
                minWidth: 200,
              }}
              value={selectedDevice._id || ''}
              options={devices.map((device) => {
                return {
                  label: `${capitalize(device.deviceType)} (${capitalize(device.activationStatus)})`,
                  value: device._id,
                };
              })}
              onChange={this.onChangeDevice}

            />

            <Tag
              tag={{
                name: deviceType,
              }}
              style={{
                marginLeft: 8,
                fontSize: 12,
                background: color.violetVibe16,
                color: color.violetVibe,
              }}
            />
          </div>

          <div className="submenu-wrapper">
            <Submenu
              rootUrl={`/location/${currentLocation._id}/telemetry`}
              style={{
                padding: '0 30px',
                display: 'inline-flex',
              }}
              items={[
                {
                  name: 'Status',
                  show: true,
                  enabled: true,
                },
                {
                  name: 'Ad Reporting',
                  show: user.can('location-ad-program.view'),
                  enabled: true,
                },
                {
                  name: 'Media Played',
                  show: true,
                  enabled: true,
                },
                {
                  name: 'Log',
                  show: true,
                  enabled: true,
                },
                {
                  name: 'Alert History',
                  show: true,
                  enabled: true,
                },
              ]}
            />

            <VibeButtonGroup className="telemetry-button-group">
              {canIssueCommand ? (
                <VibeButton
                  className="toolbar-btn"
                  style={{
                    fontWeight: 900,
                    border: disableRestart
                      ? `1px solid ${color.manatee}`
                      : `1px solid ${color.violetVibe}`,
                  }}
                  icon={(
                    <VibeIcon
                      icon={viCached}
                      color={disableRestart
                        ? color.manatee
                        : color.violetVibe}
                      size={24}
                    />
                  )}
                  text="Restart App"
                  btnColor="white"
                  textColor="purple"
                  disabled={disableRestart}
                  onClick={this.onClickRestart}
                />
              ) : null}

              <VibeButton
                className="toolbar-btn"
                style={{
                  fontWeight: 900,
                  border: `1px solid ${color.violetVibe}`,
                }}
                icon={(
                  <VibeIcon
                    icon={viAlert}
                    color={color.violetVibe}
                    size={24}
                  />
                )}
                text="Manage Alerts"
                btnColor="white"
                textColor="purple"
                btnLink={NavigationHelper.updateParams({
                  locationId: currentLocation._id,
                  type: 'alerts',
                })}
              />

              <VibeButton
                className="toolbar-btn"
                style={{
                  fontWeight: 900,
                  border: `1px solid ${color.fireBrick}`,
                }}
                icon={(
                  <VibeIcon
                    icon={viFlag}
                    color={color.fireBrick}
                    size={24}
                  />
                )}
                text="Flag Device"
                btnColor="white"
                textColor="red"
                btnLink={NavigationHelper.updateParams({
                  locationId: currentLocation._id,
                  type: 'flags',
                })}
              />

              {canIssueCommand ? (
                <VibeButton
                  className="toolbar-btn"
                  style={{
                    fontWeight: 900,
                    border: disableClearCache
                      ? `1px solid ${color.manatee}`
                      : `1px solid ${color.fireBrick}`,
                  }}
                  icon={(
                    <VibeIcon
                      icon={viTrash}
                      color={disableClearCache
                        ? color.manatee
                        : color.fireBrick}
                      size={24}
                    />
                  )}
                  text="Clear Cache"
                  btnColor="white"
                  textColor="red"
                  disabled={disableClearCache}
                  onClick={this.onClickClearCache}
                />
              ) : null}
            </VibeButtonGroup>
          </div>
        </div>

        <div className="content">
          {subpage === 'status' ? (
            <TelemetryStatus
              telemetry={telemetry}
              bamboo={bamboo || mandroid}
            />
          ) : null}

          {subpage === 'ad-reporting' && devicesFetched && (
            <AdReporting
              device={selectedDevice}
            />
          )}

          {subpage === 'media-played' ? (
            <TelemetryMediaPlayed
              device={selectedDevice}
            />
          ) : null}

          {subpage === 'log' ? (
            <TelemetryLog
              device={selectedDevice}
            />
          ) : null}

          {subpage === 'alert-history' ? (
            <AlertHistory
              locationId={currentLocation._id}
            />
          ) : null}
        </div>

        <VibeModal
          show={showConfirmRestart}
          type="confirm"
          title="Restart Device"
          text={(
            <div>
              This will restart the device at {currentLocation.name}.
              <br />
              Are you sure you want to continue?
            </div>
          )}
          confirmProps={{
            text: 'Restart Device',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmRestart}
          onClose={this.onCancelRestart}
        />

        <VibeModal
          show={showConfirmClearCache}
          type="confirm"
          title="Clear Cache"
          text={(
            <div>
              This will clear all cached data on device at {currentLocation.name}.
              <br />
              Are you sure you want to continue?
            </div>
          )}
          confirmProps={{
            text: 'Clear Cache',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmClearCache}
          onClose={this.onCancelClearCache}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
  };
}

const mapDispatchToProps = {
  updateCurrentLocation: LocationActions.updateCurrentLocation,
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Telemetry));
