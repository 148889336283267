import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/partnerusers`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  get: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  getLocations: (data, options) => Http.post(`${baseUrl}/${data._id}/locations`, data, options),
  getCompanies: (data, options) => Http.post(`${baseUrl}/${data._id}/companies`, data, options),
  getExpandedLocations: (data, options) => Http.get(`${baseUrl}/${data._id}/locations/expanded`, options),
  create: data => Http.post(baseUrl, data),
  modify: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  uploadImage: (id, file) => Http.postFileUpload(`${baseUrl}/${id}/image`, file, 'image'),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
};
