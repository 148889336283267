import { handleActions } from 'redux-actions';

/*
 * Reducer handles the state for the programs page in the application
 */

export default handleActions(
  {
    SHOW_BASELINE_DETAILS: state => {
      return {
        ...state,
        newBaseline: true,
      };
    },
    SHOW_EVENT_DETAILS: state => {
      return {
        ...state,
        editEvent: true,
      };
    },
    CLOSE_EDITING_SCREEN: state => {
      return {
        ...state,
        newBaseline: false,
        editBaseline: false,
        editEvent: false,
        mixChange: false,
      };
    },
    SHOW_MIXES: state => {
      return {
        ...state,
        mixChange: true,
      };
    },
  },
  // Initial State
  {
    newBaseline: false,
    editBaseline: false,
    editEvent: false,
    mixChange: false,
  },
);
