// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserAvatar {
  display: flex;
  align-items: center;
}
.UserAvatar > .avatar {
  border-radius: 100%;
  overflow: hidden;
}
.UserAvatar > .name {
  flex-grow: 1;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/UserAvatar/UserAvatar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;EACA,gBAAA;AACJ;AAEE;EACE,YAAA;EACA,gBAAA;AAAJ","sourcesContent":[".UserAvatar {\n  display: flex;\n  align-items: center;\n\n  > .avatar {\n    border-radius: 100%;\n    overflow: hidden;\n  }\n\n  > .name {\n    flex-grow: 1;\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
