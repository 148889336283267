import React from 'react';

const viNowPlaying = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M19 8v12h-2V8h2zm-4-4v16h-2V4h2zm-4 10v6H9v-6h2zm-4-4v10H5V10h2z" />
    </g>
  </svg>
);

export default viNowPlaying;
