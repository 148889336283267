import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableScreenResolutions,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import ScreenResolutionSidebar from './Sidebar/ScreenResolutionSidebar';

function ScreenResolutionContainer({
  className,
  setPanel,
  history,
}) {
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      resolutionId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, resolutionId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      children: (
        <ScreenResolutionSidebar
          resolutionId={resolutionId}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.resolutionId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.resolutionId);
    }
  }, [qs.type, qs.resolutionId]);

  return (
    <div
      className={classNames('ScreenResolutionContainer', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        title="Screen Resolutions"
        buttons={[
          {
            text: 'New Screen Resolution',
            icon: viAdd,
            userCan: [
              'screen_resolution.create',
            ],
            color: color.violetVibe,
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
          },
        ]}
      />

      <TableScreenResolutions
        columns={[
          'Name',
          'Width',
          'Height',
          '...',
        ]}
        fetch={API.ScreenResolution.list}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Screen Resolutions',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Screen Resolutions',
          tooltip: true,
        }}
        rowLink={{
          resolutionId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'screen_resolution.delete' },
        ]}
      />
    </div>
  );
}

ScreenResolutionContainer.propTypes = {
  className: PropTypes.string,
};

ScreenResolutionContainer.defaultProps = {
  className: '',
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(ScreenResolutionContainer));
