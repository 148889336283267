import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import './VibeCell.scss';

class VibeCell extends PureComponent {
  constructor(props) {
    super(props);

    this.cellRef = React.createRef();

    this.state = {
      width: 0,
      resizing: false,
    };
  }

  onClick = () => {
    const {
      rowId,
      onClick,
    } = this.props;

    if (onClick) {
      onClick(rowId);
    }
  };

  onMouseDownResize = (e) => {
    e.preventDefault();

    this.setState({
      resizing: true,
    });

    document.addEventListener('mousemove', this.onMouseMoveResize, false);
    document.addEventListener('mouseup', this.onMouseUpResize, false);
  };

  onMouseMoveResize = (e) => {
    const {
      style: {
        width: currWidth,
      },
      columnIndex,
      onResizeCell,
    } = this.props;

    const {
      cellRef: {
        current: cellRef,
      },
    } = this;

    const {
      pageX,
    } = e;

    const rect = cellRef.getBoundingClientRect();
    const width = pageX - rect.x;

    if (width < 40) {
      console.warn('Column width must be at least 40px');
      return;
    }

    if (width !== currWidth) {
      onResizeCell({
        columnIndex,
        width,
      });

      this.setState({
        width,
      });
    }
  };

  onMouseUpResize = (e) => {
    const {
      columnIndex,
      onResizeCell,
    } = this.props;

    const {
      width,
    } = this.state;

    // ensure the cell is the minimum width
    if (width >= 40) {
      onResizeCell({
        columnIndex,
        width,
        save: true,
      });
    }

    this.setState({
      width: 0,
      resizing: false,
    });

    // Remove this event handler after firing
    e.currentTarget.removeEventListener('mousemove', this.onMouseMoveResize, false);
    e.currentTarget.removeEventListener('mouseup', this.onMouseUpResize, false);
  };

  onDragStart = (e) => {
    const {
      rowId,
      onDragStart,
    } = this.props;

    onDragStart(e, rowId);
  };

  onDragOver = (e) => {
    const {
      onDragOver,
    } = this.props;

    const {
      cellRef: {
        current: cellRef,
      },
    } = this;

    onDragOver(e, cellRef);
  };

  onDrop = (e) => {
    e.preventDefault();

    const {
      rowId,
      onDrop,
    } = this.props;

    onDrop(e, rowId);
  };

  render() {
    const {
      className,
      header,
      bulkEdit,
      lastColumn,
      error,
      link,
      resizable,
      checked,
      assigned,
      draggable,
      style,
      contentStyle,
      children,
      onClick,
      onDragEnd,
    } = this.props;

    const {
      width,
      resizing,
    } = this.state;

    const cellClassName = classNames('VibeCell', className, {
      header,
      'bulk-edit': bulkEdit,
      checked,
      assigned,
      link: link || onClick,
      error,
      'last-column': lastColumn,
    });

    const cellStyle = {
      ...style,
      width:
        width > 0
          ? width
          : style.width,
    };

    const cellHtml = (
      <div className="cell-content">
        <div
          className={classNames('content-container', { header })}
          style={contentStyle}
        >
          {children}
        </div>

        {resizable ? (
          <div
            className="resize-handle"
            onMouseDown={this.onMouseDownResize}
          />
        ) : null}

        {resizing ? (
          <div
            style={{
              cursor: 'ew-resize',
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 3,
            }}
          />
        ) : null}
      </div>
    );

    return link ? (
      <Link
        ref={this.cellRef}
        className={cellClassName}
        style={cellStyle}
        to={link}
        draggable={draggable}
        onDragStart={this.onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        onClick={this.onClick}
      >
        {cellHtml}
      </Link>
    ) : (
      <div
        ref={this.cellRef}
        className={cellClassName}
        style={cellStyle}
        draggable={draggable}
        onDragStart={this.onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        onClick={this.onClick}
      >
        {cellHtml}
      </div>
    );
  }
}

VibeCell.propTypes = {
  className: PropTypes.string,
  columnIndex: PropTypes.number.isRequired,
  header: PropTypes.bool,
  bulkEdit: PropTypes.bool,
  lastColumn: PropTypes.bool,
  /** Item has an error and needs to be highlighted */
  error: PropTypes.bool,
  link: PropTypes.string,
  resizable: PropTypes.bool,
  checked: PropTypes.bool,
  assigned: PropTypes.bool,
  draggable: PropTypes.bool,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  contentStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Row Data for Dragging */
  rowId: PropTypes.string,
  onClick: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  onResizeCell: PropTypes.func,
};

VibeCell.defaultProps = {
  className: '',
  header: false,
  bulkEdit: false,
  lastColumn: false,
  error: false,
  link: null,
  resizable: false,
  checked: false,
  assigned: false,
  draggable: false,
  style: {},
  contentStyle: {},
  children: null,
  rowId: null,
  onClick: null,
  onDragStart: () => {},
  onDragEnd: () => {},
  onDragOver: () => {},
  onDrop: () => {},
  onResizeCell: () => {},
};

export default VibeCell;
