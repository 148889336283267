import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function FieldGroupItem({
  className,
  style,
  children,
}) {
  return (
    <div
      className={classNames('FieldGroupItem', className)}
      style={{
        flexGrow: 1,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

FieldGroupItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FieldGroupItem.defaultProps = {
  className: '',
  style: {},
};

export default FieldGroupItem;
