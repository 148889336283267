import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  isFinite,
} from 'lodash';
import classNames from 'classnames';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import './SnippetCard.scss';

function SnippetCard({
  title,
  route,
  data,
}) {
  const location = useLocation();
  const path = location.pathname.split('/');
  const activeRoute = path[path.length - 1] === route;

  const useData = isFinite(data)
    ? data.toLocaleString()
    : data;

  return (
    <Link
      className="SnippetCardLink"
      to={`./${route}`}
    >
      <Card className={classNames('SnippetCard', { active: activeRoute })}>
        <CardContent
          sx={{
            marginBottom: activeRoute
              ? '24px'
              : 0,
          }}
        >
          <Typography className="title">{title}</Typography>
          <Typography className="data">{useData}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

SnippetCard.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default SnippetCard;
