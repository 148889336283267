import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  forEach,
  find,
  clone,
} from 'lodash';
import {
  API,
} from 'vibeguide';
import PermissionGroup from './PermissionGroup';
import './PermissionList.scss';

class PermissionList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      masterPermissions: {},
    };
  }

  componentDidMount() {
    this.getMasterPermissions();
  }

  componentDidUpdate(prevProps) {
    const {
      permissionSetId,
    } = this.props;

    const {
      permissionSetId: prevPermissionSetId,
    } = prevProps;

    if (permissionSetId && permissionSetId !== prevPermissionSetId) {
      // get the new permission set and update the selected permissions
      this.getPermissionSet(permissionSetId);
    }
  }

  /**
   * When a group is toggled
   */
  onToggleGroup = (title) => {
    const {
      permissionIds,
      onUpdate,
    } = this.props;

    const {
      groups,
    } = this.state;

    const group = find(groups, { title });
    const {
      allToggled,
      someToggled,
    } = group;

    let newIds = clone(permissionIds);
    let newAllToggled;

    if (allToggled || someToggled) {
      // uncheck each permission in the group
      group.permissions.forEach((permission) => {
        newIds = newIds.filter(id => id !== permission._id);
      });

      newAllToggled = false;
    } else {
      // check each permission in the group
      group.permissions.forEach((permission) => {
        if (!newIds.includes(permission._id)) {
          // permission is not already selected
          newIds.push(permission._id);
        }
      });

      newAllToggled = true;
    }

    onUpdate({
      permissionIds: newIds,
    });

    this.setState((state) => {
      return {
        groups: state.groups.map((g) => {
          if (g.title === title) {
            return {
              ...g,
              allToggled: newAllToggled,
              someToggled: false,
            };
          }

          return g;
        }),
      };
    });
  };

  /**
   * When a group is set to on
   */
  onBulkGroupOn = (title) => {
    const {
      updatePermissions,
      onUpdate,
    } = this.props;

    const {
      groups,
    } = this.state;

    const group = find(groups, { title });
    let newUpdatePermissions = clone(updatePermissions);

    if (!group.on) {
      group.permissions.forEach((permission) => {
        permission.on = true;
        permission.off = false;
        const addPerm = {
          _id: permission._id,
          action: 'add',
        };
        newUpdatePermissions.push(addPerm);
      });
    } else {
      group.permissions.forEach((permission) => {
        permission.on = false;
        permission.off = false;
        newUpdatePermissions = newUpdatePermissions.filter(perm => perm._id !== permission._id);
      });
    }

    onUpdate({
      updatePermissions: newUpdatePermissions,
    });

    this.setState((state) => {
      return {
        groups: state.groups.map((g) => {
          if (g.title === title) {
            return {
              ...g,
              on: !g.on,
              off: false,
            };
          }

          return g;
        }),
      };
    });
  };

  /**
   * When a group is set to off
   */
  onBulkGroupOff = (title) => {
    const {
      updatePermissions,
      onUpdate,
    } = this.props;

    const {
      groups,
    } = this.state;

    const group = find(groups, { title });
    let newUpdatePermissions = clone(updatePermissions);

    if (!group.off) {
      group.permissions.forEach((permission) => {
        permission.on = false;
        permission.off = true;
        const removePerm = {
          _id: permission._id,
          action: 'remove',
        };
        newUpdatePermissions.push(removePerm);
      });
    } else {
      group.permissions.forEach((permission) => {
        permission.on = false;
        permission.off = false;
        newUpdatePermissions = newUpdatePermissions.filter(perm => perm._id !== permission._id);
      });
    }

    onUpdate({
      updatePermissions: newUpdatePermissions,
    });

    this.setState((state) => {
      return {
        groups: state.groups.map((g) => {
          if (g.title === title) {
            return {
              ...g,
              on: false,
              off: !g.off,
            };
          }

          return g;
        }),
      };
    });
  };

  /**
   * When an individual permission is toggled
   */
  onTogglePermission = (title, permissionId) => {
    const {
      permissionIds,
      onUpdate,
    } = this.props;

    const {
      groups,
    } = this.state;

    const group = find(groups, { title });
    let newIds = clone(permissionIds);

    if (newIds.includes(permissionId)) {
      // remove the permission
      newIds = newIds.filter(id => id !== permissionId);
    } else {
      // add the permission
      newIds = [...newIds, permissionId];
    }

    // check if the group has all or some permissions
    // number of toggled permissions
    let toggledCount = 0;

    group.permissions.forEach((permission) => {
      if (newIds.includes(permission._id)) {
        // this permission in this group is selected
        toggledCount += 1;
      }
    });

    const allToggled = toggledCount === group.permissions.length;
    const someToggled = !allToggled && toggledCount > 0;

    onUpdate({
      permissionIds: newIds,
    });

    this.setState((state) => {
      return {
        groups: state.groups.map((g) => {
          if (g.title === title) {
            return {
              ...g,
              allToggled,
              someToggled,
            };
          }

          return g;
        }),
      };
    });
  };

  /**
   * When an individual permission is set to 'on'
   */
  onBulkPermissionOn = (title, permissionId) => {
    const {
      updatePermissions,
      onUpdate,
    } = this.props;

    const {
      groups,
    } = this.state;

    let newUpdatePermissions = clone(updatePermissions);

    // is the permission already in the update list?
    const permission = find(newUpdatePermissions, {
      _id: permissionId,
    });

    if (permission && permission.action === 'remove') {
      // permission is queued to remove, change to add
      permission.action = 'add';
    } else if (permission && permission.action === 'add') {
      // permission is queued to add, remove it
      newUpdatePermissions = newUpdatePermissions.filter(permissionItem => permissionItem._id !== permissionId);
    } else {
      // add the permission
      newUpdatePermissions.push({
        _id: permissionId,
        action: 'add',
      });
    }

    const group = find(groups, { title });

    group.permissions.forEach((permission) => {
      if (permission._id === permissionId) {
        permission.on = !permission.on;
        permission.off = false;
      }
    });

    this.setState((state) => {
      return {
        groups: state.groups.map((g) => {
          if (g.title === title) {
            return {
              ...g,
              permissions: group.permissions,
            };
          }

          return g;
        }),
      };
    });

    onUpdate({
      updatePermissions: newUpdatePermissions,
      permissionIds: newUpdatePermissions.map(permissionItem => permissionItem._id),
    });
  };

  /**
   * When an individual permission is set to 'off'
   */
  onBulkPermissionOff = (title, permissionId) => {
    const {
      updatePermissions,
      onUpdate,
    } = this.props;

    const {
      groups,
    } = this.state;

    let newUpdatePermissions = clone(updatePermissions);

    // is the permission already in the update list?
    const permission = find(newUpdatePermissions, {
      _id: permissionId,
    });

    if (permission && permission.action === 'add') {
      // permission is queued to add, change to remove
      permission.action = 'remove';
    } else if (permission && permission.action === 'remove') {
      // permission is queued to add, remove it
      newUpdatePermissions = newUpdatePermissions.filter(permissionItem => permissionItem._id !== permissionId);
    } else {
      // remove the permission
      newUpdatePermissions.push({
        _id: permissionId,
        action: 'remove',
      });
    }

    const group = find(groups, { title });

    group.permissions.forEach((permission) => {
      if (permission._id === permissionId) {
        permission.on = false;
        permission.off = !permission.off;
      }
    });

    this.setState((state) => {
      return {
        groups: state.groups.map((g) => {
          if (g.title === title) {
            return {
              ...g,
              permissions: group.permissions,
            };
          }

          return g;
        }),
      };
    });

    onUpdate({
      updatePermissions: newUpdatePermissions,
      permissionIds: newUpdatePermissions.map(permissionItem => permissionItem._id),
    });
  };

  getMasterPermissions = async () => {
    const {
      permissionSetId,
      permissionIds,
    } = this.props;

    const masterPermissionsList = await API.Permission.list();

    const masterPermissions = {};

    masterPermissionsList.forEach((permission) => {
      if (!masterPermissions[permission.friendlyType]) {
        // add permission type object array if it doesn't exist
        masterPermissions[permission.friendlyType] = [];
      }

      // add permission to its group type
      masterPermissions[permission.friendlyType].push(permission);
    });

    const groups = this.getGroups(masterPermissions, permissionIds);

    this.setState({
      masterPermissions,
      groups,
    }, () => {
      if (permissionSetId) {
        this.getPermissionSet(permissionSetId);
      }
    });
  };

  /**
   * Get the permissionn set and update the selected permissions
   */
  getPermissionSet = async (permissionSetId) => {
    const {
      onUpdate,
    } = this.props;

    const {
      masterPermissions,
    } = this.state;

    const permissionSet = await API.PermissionSet.getById(permissionSetId);
    const permissions = get(permissionSet, 'permissions', []);
    const permissionIds = permissions.map(permission => permission._id);
    const groups = this.getGroups(masterPermissions, permissionIds);

    onUpdate({
      permissionIds,
    });

    this.setState({
      groups,
    });
  };

  /**
   * Get groups of permissions based on type
   */
  getGroups = (masterPermissions, permissionIds) => {
    const groups = [];

    // Add the permission group
    forEach(masterPermissions, (permissions, title) => {
      // number of toggled permissions
      let toggledCount = 0;

      permissions.forEach((permission) => {
        if (permissionIds.includes(permission._id)) {
          // this permission in this group is selected
          toggledCount += 1;
        }
      });

      const allToggled = toggledCount === permissions.length;
      const someToggled = !allToggled && toggledCount > 0;

      groups.push({
        title,
        permissions,
        allToggled,
        someToggled,
      });
    });

    return groups;
  };

  render() {
    const {
      className,
      permissionIds,
      disabled,
      bulk,
    } = this.props;

    const {
      groups,
    } = this.state;

    return (
      <div className={classNames('PermissionList', className)}>
        {groups.map((group) => {
          return (
            <PermissionGroup
              key={`group-${group.title}`}
              title={group.title}
              permissions={group.permissions}
              selectedIds={permissionIds}
              allToggled={group.allToggled}
              someToggled={group.someToggled}
              disabled={disabled}
              bulk={bulk}
              bulkGroupOn={group.on}
              bulkGroupOff={group.off}
              onToggleGroup={this.onToggleGroup}
              onTogglePermission={this.onTogglePermission}
              onBulkGroupOn={this.onBulkGroupOn}
              onBulkGroupOff={this.onBulkGroupOff}
              onBulkPermissionOn={this.onBulkPermissionOn}
              onBulkPermissionOff={this.onBulkPermissionOff}
            />
          );
        })}
      </div>
    );
  }
}

PermissionList.propTypes = {
  className: PropTypes.string,
  permissionSetId: PropTypes.string,
  /** selected permission IDs */
  permissionIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  bulk: PropTypes.bool,
  onUpdate: PropTypes.func,
};

PermissionList.defaultProps = {
  className: '',
  permissionSetId: null,
  permissionIds: [],
  disabled: false,
  bulk: false,
  onUpdate: () => {},
};

export default PermissionList;
