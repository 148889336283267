import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viCompany,
} from 'vibeguide';

function PartnersContainer({
  className,
  style,
  user,
}) {
  return (
    <div
      className={classNames('PartnersContainer', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginLeft: '80px',
        ...style,
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Basic Info"
          url="/partners/list"
          icon={viCompany}
          show={user.can('partner.view')}
        />
      </NavigationSide>

      <Outlet />
    </div>
  );
}

PartnersContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

PartnersContainer.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(PartnersContainer);
