import React from 'react';

const viThumbDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="nonzero">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M15 2H6c-.8 0-1.5.5-1.8 1.2l-3 7.1c-.1.2-.2.4-.2.7v2c0 1.1.9 2 2 2h6.3l-1 4.6v.3c0 .4.2.8.4 1.1l1.1 1 6.6-6.6c.4-.4.6-.9.6-1.4V4c0-1.1-.9-2-2-2zm4 0v12h4V2h-4z" />
    </g>
  </svg>
);

export default viThumbDown;
