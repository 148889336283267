/**
 * List of supported Ad Providers
 */
export const list = [
  {
    name: 'Good Vibes',
    value: 'goodvibes',
  },
  {
    name: 'Triton',
    value: 'triton',
  },
  {
    name: 'Vibenomics',
    value: 'vibenomics',
  },
  {
    name: 'Vistar',
    value: 'vistar',
  },
];

export default {
  list,
};
