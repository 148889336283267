import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  find,
} from 'lodash';
import {
  API,
  GlobalActions,
  EventActions,
  LocationHelper,
  ExpandedLocationsHelper,
  EventModel,
  LoadingContent,
} from 'vibeguide';
import EventDetails from './EventDetails';
import './EventSidebar.scss';

class EventSidebar extends PureComponent {
  constructor(props) {
    super(props);

    const {
      event,
    } = props;

    this.state = {
      event: event || new EventModel(),
    };
  }

  componentDidMount() {
    const {
      eventId,
    } = this.props;

    if (eventId && eventId.indexOf('new-event') < 0) {
      this.getEvent();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      eventId,
      isNew,
    } = this.props;

    const {
      eventId: prevEventId,
    } = prevProps;

    if (eventId && !isNew && eventId !== prevEventId) {
      this.getEvent();
    }
  }

  /**
   * Update the event
   */
  onUpdate = (data) => {
    this.setState((state) => {
      const event = EventActions.updateEvent({
        ...state.event,
        ...data,
      });

      return {
        event,
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtraPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  getEvent = async () => {
    const {
      eventId,
      currentLocation,
    } = this.props;

    const companyId = get(currentLocation, 'companyId', null);
    const locationId = get(currentLocation, '_id', null);
    // only check the interrupts at a location if we are on a specific location
    const skipLocationCheck = !companyId || !locationId;

    const eventData = await API.Event.getById(eventId);
    const expandedLocations = await API.Event.getExpandedLocations(eventId);

    // Set the location data
    eventData.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    // Determine which interrupts are accessible to these location(s)
    const interrupts = get(eventData, 'interrupts', []);
    interrupts.forEach((interrupt) => {
      const interruptDetail = interrupt.detail;
      const interruptLocations = get(interruptDetail, 'locations', []);
      const compareResult = LocationHelper.compare(eventData.locations, interruptLocations);
      // Determine if the interrupt has access to all the locations for this object
      const accessAllLocations = compareResult.parent.missing.length <= 0;
      // Determine if the interrupt has access to this location
      const accessCurrentLocation = accessAllLocations
        || find(interruptLocations, { companyId, locationId }) !== undefined
        || find(interruptLocations, { companyId, locationId: '*' }) !== undefined;

      interrupt.accessAllLocations = accessAllLocations;
      interrupt.accessCurrentLocation = skipLocationCheck || accessCurrentLocation;
    });

    // update the repeat rule on event fetch to ensure the ;BYDAY= string is in weekday order
    // this is needed for Android to parse the RRule properly
    const event = EventActions.updateEvent({
      ...new EventModel(eventData),
      updateRule: true,
    });

    this.setState({
      event,
    });
  };

  render() {
    const {
      isNew,
      eventId,
    } = this.props;

    const {
      event,
    } = this.state;

    return (
      <div className="EventSidebar">
        <EventDetails
          event={event}
          isNew={isNew}
          onClose={this.onClose}
          onCloseExtraPanel={this.onCloseExtraPanel}
          onUpdate={this.onUpdate}
        />

        {eventId && event._id === 'new-event' && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

EventSidebar.propTypes = {
  eventId: PropTypes.string,
  event: PropTypes.instanceOf(EventModel),
  isNew: PropTypes.bool,
};

EventSidebar.defaultProps = {
  eventId: null,
  event: null,
  isNew: false,
};

function mapStateToProps(state) {
  return {
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSidebar);
