import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray, isFunction } from 'lodash';
import { Menu, MenuItem } from '@mui/material';
import {
  ArrowDropDown,
} from '@mui/icons-material';
import './Paginator.scss';

class Paginator extends Component {
  constructor(props) {
    super(props);

    const {
      label,
    } = props;

    const selectedLabel = isArray(label) ? label[0] : label;

    this.state = {
      selectedLabel,
      labelDropdown: false,
    };

    this.showLabelDropdown = this.showLabelDropdown.bind(this);
    this.closeLabelDropdown = this.closeLabelDropdown.bind(this);
    this.selectLabel = this.selectLabel.bind(this);
  }

  /**
   * Show the label dropdown
   */
  showLabelDropdown() {
    this.setState(() => {
      return {
        labelDropdown: true,
      };
    });
  }

  /**
   * Close the label dropdown
   */
  closeLabelDropdown() {
    this.setState(() => {
      return {
        labelDropdown: false,
      };
    });
  }

  /**
   * Select a label
   */
  selectLabel(labelName) {
    const {
      onLabelChange,
    } = this.props;

    this.setState(() => {
      return {
        selectedLabel: labelName,
      };
    }, () => {
      this.closeLabelDropdown();
    });

    if (isFunction(onLabelChange)) {
      // Callback to tell the component the label has changed
      onLabelChange(labelName);
    }
  }

  render() {
    const {
      totalLoaded = 0,
      totalItems = 0,
      label,
      className,
      layout,
    } = this.props;

    const {
      selectedLabel,
      labelDropdown,
    } = this.state;

    const totalLoadedFormat = totalLoaded.toLocaleString('en');
    const totalItemsFormat = totalItems.toLocaleString('en');
    const isMultipleLabels = isArray(label);
    let labelHtml;

    if (isMultipleLabels) {
      // Show links for the labels
      const menuItems = [];

      label.forEach((labelName, index) => {
        menuItems.push(
          <MenuItem
            key={index}
            className="menu-item"
            onClick={() => { this.selectLabel(labelName); }}
          >
            {labelName}
          </MenuItem>,
        );
      });

      labelHtml = (
        <span>
          <span
            className="label-dropdown"
            aria-haspopup="true"
            ref={(ref) => { this.labelRef = ref; }}
            onClick={this.showLabelDropdown}
          >
            {selectedLabel}
            <ArrowDropDown className="icon" />
          </span>
          <Menu
            id="label-menu"
            anchorEl={this.labelRef}
            open={labelDropdown}
            onClose={this.closeLabelDropdown}
          >
            {menuItems}
          </Menu>
        </span>
      );
    } else {
      // Show the label
      labelHtml = (
        <span>
          {label}
        </span>
      );
    }

    return (
      <div className={`Paginator layout-${layout} ${className}`}>
        Showing
        <span className="counts">
          {totalLoadedFormat}
          <span style={{ padding: '0 4px' }}>
            of
          </span>
          {totalItemsFormat}
        </span>
        {labelHtml}
      </div>
    );
  }
}

Paginator.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Theme layout */
  layout: PropTypes.oneOf([
    'white',
    'purple',
  ]),
  /** Number of loaded collection items */
  totalLoaded: PropTypes.number.isRequired,
  /** Total number of collection items */
  totalItems: PropTypes.number.isRequired,
  /** Label to show at the end */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** When the label is changed if there are multiple to choose from */
  onLabelChange: PropTypes.func,
};

Paginator.defaultProps = {
  className: '',
  layout: 'white',
  label: 'Items',
  onLabelChange: null,
};

export default Paginator;
