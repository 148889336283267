// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VibeTooltipPopper {
  z-index: 9000 !important;
}
.VibeTooltipPopper .tooltip-item {
  margin-top: 4px;
}
.VibeTooltipPopper .tooltip-item:first-child {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeTooltip/VibeTooltip.scss"],"names":[],"mappings":"AAAA;EAEE,wBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAEI;EACE,aAAA;AAAN","sourcesContent":[".VibeTooltipPopper {\n  // must be the same or higher than VibeModal\n  z-index: 9000 !important;\n\n  .tooltip-item {\n    margin-top: 4px;\n\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
