import { handleActions } from 'redux-actions';
import { find } from 'lodash';

/**
 * Create a tag object
 */
function createTag(data) {
  return {
    childrenLoaded: !data.hasChildren && data.hasChildren !== undefined,
    allChildrenSelected: !data.hasChildren && data.hasChildren !== undefined,
    someChildrenSelected: false,
    selected: false,
    ...data,
  };
}

const initialState = {
  activeTag: {
    _id: null,
    name: null,
    qualifiedName: null,
    parentId: null,
    active: false,
    hasChildren: false,
  },
  tags: [],
  activeLocationMenuItem: null,
};

export default handleActions(
  {
    SET_TARGET_TAG_ACTIVE: (state, action) => {
      const activeTag = action.payload;

      return {
        ...state,
        activeTag: {
          ...initialState.activeTag,
          ...activeTag,
        },
      };
    },

    ADD_TARGET_TAG: (state, action) => {
      const tag = action.payload;

      const tagExists = find(state.tags, { _id: tag._id }) !== undefined;

      if (tagExists) {
        // Target tag already exists
        return state;
      }

      return {
        ...state,
        tags: [...state.tags, createTag(tag)],
      };
    },

    ADD_TARGET_TAGS: (state, action) => {
      const tags = action.payload;
      const addTags = [];
      const updateTags = [];

      tags.forEach((tag) => {
        const existingTag = find(state.tags, { _id: tag._id });

        if (!existingTag) {
          // Target tag does not exist
          addTags.push(createTag(tag));
        } else {
          // Target tag does exist
          updateTags.push(createTag({
            ...existingTag,
            ...tag,
          }));
        }
      });

      const updatedTags = updateTags.length > 0
        ? state.tags.map((tag) => {
          const updateTag = find(updateTags, { _id: tag._id });

          if (updateTag) {
            return {
              ...tag,
              ...updateTag,
            };
          }

          return tag;
        })
        : state.tags;

      return {
        ...state,
        tags: [
          ...updatedTags,
          ...addTags,
        ],
      };
    },

    REMOVE_TARGET_TAG: (state, action) => {
      const tagId = action.payload;

      return {
        ...state,
        tags: state.tags.filter(tag => tag._id !== tagId),
      };
    },

    UPDATE_TARGET_TAG: (state, action) => {
      const updateTag = action.payload;
      const originalTag = find(state.tags, { _id: updateTag._id });

      return {
        ...state,
        tags: state.tags.map((tag) => {
          if (tag._id === updateTag._id) {
            return {
              ...tag,
              ...updateTag,
            };
          }

          return {
            ...tag,
            // if qualified name changed update the other tags that match
            qualifiedName: updateTag.qualifiedName
              ? tag.qualifiedName.replace(originalTag.qualifiedName, updateTag.qualifiedName)
              : tag.qualifiedName,
          };
        }),
      };
    },

    SET_LOCATION_ACTIVE_MENU_ITEM: (state, action) => {
      const activeLocationMenuItem = action.payload;

      return {
        ...state,
        activeLocationMenuItem,
        tags: state.tags.map((tag) => {
          return {
            ...tag,
            selected: false,
          };
        }),
      };
    },

    RESET_TARGET_TAGS: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
