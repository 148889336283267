import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import Dialog from '@mui/material/Dialog';
import API from '../../api';
import DropdownSelector from '../DropdownSelector/DropdownSelector';
import ManageTags from './ManageTags';
import Tag from './Tag';
import VibeIcon from '../VibeIcon/VibeIcon';
import viTag from '../../icons/viTag';
import viStar from '../../icons/viStar';
import viStarOutline from '../../icons/viStarOutline';
import color from '../../sass/color.scss';
import './Tags.scss';

function Tags({
  className,
  style,
  id,
  dataId,
  name,
  tags,
  label,
  hideLabel,
  assign,
  allowTypes,
  showAs,
  dropdownStyle,
  companyId,
  disabled,
  tabIndex,
  autoFocus,
  multipleValues,
  required,
  onClick,
  onChange,
  onBlur,
  onFocus,
  user,
}) {
  const canManageTags = user.can('tag.view_admin') || (user.can('tag.view') && !isEmpty(companyId));
  const canViewAdminTags = user.can('tag.view_admin');
  const canViewClientTags = user.can('tag.view');
  // const canCreateAdminTags = user.can('tag.create_admin');
  // const canCreateClientTags = user.can('tag.create');

  const allowClientTags = allowTypes.includes('client') && canViewClientTags;
  const allowAdminTags = allowTypes.includes('admin') && canViewAdminTags;

  const disableTags = disabled
    || (!allowClientTags && !allowAdminTags)
    || !assign;

  /**
   * Get default tag type to show
   */
  const getTagType = () => {
    if (
      (user.can('tag.view_admin') && !user.can('tag.view'))
      || (user.can('tag.view_admin') && (allowAdminTags && !allowClientTags))
    ) {
      return 'admin';
    }

    // default to client tags, unless only admin tags are allowed
    return 'client';
  };

  const [value, setValue] = useState('');
  // Is the input looking for admin tags (only default admin tags if they can't view client tags)
  const [activeType, setActiveType] = useState(getTagType());
  // is the dropdown input focused
  const [inputFocus, setInputFocus] = useState(false);
  // is the manage tags modal open
  const [manageTagsModal, setManagedTagsModal] = useState(false);

  /**
   * Get the current tag type label
   */
  const getTagTypeLabel = () => {
    switch (activeType) {
      case 'admin':
        return 'Admin Tags';

      case 'client':
        return 'Client Tags';

      default:
        return 'Unknown Tags';
    }
  };

  /**
   * Get the tooltip used when trying to switch between admin/client tags
   */
  const getSwitchTagTypeTooltip = () => {
    if (allowAdminTags && !allowClientTags) {
      return 'Only Admin Tags Allowed';
    }

    if (activeType === 'admin' && allowClientTags) {
      return 'Switch to Client Tags';
    }

    if (activeType === 'client' && allowAdminTags) {
      return 'Switch to Admin Tags';
    }

    return 'Only Client Tags Allowed';
  };

  /**
   * Get filters to send with the fetch tags request
   */
  const getFilters = () => {
    const filters = {
      type: activeType,
    };

    // admin tags don't need a company ID
    if (activeType !== 'admin') {
      filters.companyId = companyId;
    }

    return filters;
  };

  /**
   * User toggles the tag type being added
   */
  const onToggleTagType = () => {
    if (activeType === 'client' && allowAdminTags) {
      // switch to admin tags
      setActiveType('admin');
    } else if (activeType === 'admin' && allowClientTags) {
      // switch to client tags
      setActiveType('client');
    }
  };

  /**
   * When the tag field is changed
   */
  const onChangeTag = ({
    data: tag,
    value,
  }) => {
    if (!isEmpty(tag)) {
      // a tag was selected
      // clear the input value
      setValue('');
      const existingTag = find(tags, { _id: tag._id });

      if (existingTag && existingTag.status === 'remove') {
        // Tag is already added (and queued for removal)
        onChange({
          tags: tags.map((t) => {
            if (t._id === tag._id) {
              return {
                ...t,
                status: 'add',
              };
            }

            return t;
          }),
        });
      } else if (!existingTag) {
        // Tag is not added, add it to the list of tags
        onChange({
          tags: [
            ...tags,
            {
              ...tag,
              status: 'add',
            },
          ],
        });
      }
    } else {
      // tag was not selected, update the input value
      setValue(value);
    }
  };

  /**
   * When a tag is removed
   */
  const onRemoveTag = (tag) => {
    const existingTag = find(tags, { _id: tag._id });

    // Tag is already added (and not queued for removal)
    if (existingTag) {
      onChange({
        tags: tags.map((t) => {
          if (t._id === tag._id) {
            return {
              ...t,
              status: 'remove',
            };
          }

          return t;
        }),
      });
    }
  };

  /**
   * When the dropdown is focused
   */
  const onDropdownFocus = (e) => {
    setInputFocus(true);
    onFocus(e);
  };

  /**
   * When the dropdown is blurred
   */
  const onDropdownBlur = (e) => {
    setInputFocus(false);
    onBlur(e);
  };

  /**
   * When the user clicks manage tags
   */
  const onOpenManageTagsModal = () => {
    setManagedTagsModal(true);
  };

  /**
   * When the manage tags modal is closed (same action for save/cancel)
   */
  const onCloseManageTagsModal = () => {
    // tell the dropdown to reset
    document.dispatchEvent(new Event('onResetDropdownSelector'));
    setManagedTagsModal(false);
  };

  // data to filter by and create the tag
  const tagData = getFilters();

  return (
    <div
      className={classNames('Tags', className)}
      style={style}
    >
      {!hideLabel && (label || canManageTags) && (
        <div className="label-container">
          {label ? (
            <label
              className={classNames('field-label', { required })}
              htmlFor={`tag-${id}`}
            >
              {getTagTypeLabel()}
            </label>
          ) : null}

          {canManageTags && !disabled && (
            <div className="manage-tags">
              <span
                className="manage-tags-text"
                onClick={onOpenManageTagsModal}
              >
                Manage Tags
              </span>
            </div>
          )}
        </div>
      )}

      <div
        className={classNames('tags-input-container', {
          // 'show-admin': canViewAdminTags,
          focus: inputFocus,
        })}
      >
        {allowClientTags && allowAdminTags && (
          <div className="admin-tag-select">
            <VibeIcon
              type="button"
              icon={activeType === 'admin'
                ? viStar
                : viStarOutline}
              color={color.manatee}
              size={24}
              tooltip={getSwitchTagTypeTooltip()}
              buttonProps={{
                size: 32,
                color: color.lightGray,
                borderColor: color.whiteSmoke,
                style: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  // borderBottomLeftRadius: inputFocus
                  //   ? 0
                  //   : 4,
                  borderTop: inputFocus
                    ? `1px solid ${color.violetVibe}`
                    : `1px solid ${color.whiteSmoke}`,
                  borderBottom: inputFocus
                    ? `1px solid ${color.violetVibe}`
                    : `1px solid ${color.whiteSmoke}`,
                  borderLeft: inputFocus
                    ? `1px solid ${color.violetVibe}`
                    : `1px solid ${color.whiteSmoke}`,
                  borderRight: 'none',
                },
              }}
              onClick={onToggleTagType}
            />
          </div>
        )}

        <DropdownSelector
          name={name}
          value={value}
          type="tag"
          attr="name"
          placeholder={`Search for ${getTagTypeLabel()}...`}
          style={{
            width: '100%',
            ...dropdownStyle,
          }}
          inputStyle={{
            borderTop: inputFocus
              ? `1px solid ${color.violetVibe}`
              : `1px solid ${color.whiteSmoke}`,
            borderLeft: inputFocus
              ? `1px solid ${color.violetVibe}`
              : `1px solid ${color.whiteSmoke}`,
            borderRight: inputFocus
              ? `1px solid ${color.violetVibe}`
              : `1px solid ${color.whiteSmoke}`,
            borderBottom: inputFocus
              ? 'none'
              : `1px solid ${color.whiteSmoke}`,
            borderTopLeftRadius: allowClientTags && allowAdminTags
              ? 0
              : 4,
            borderBottomLeftRadius: allowClientTags && allowAdminTags
              ? 0
              : 4,
            flexGrow: 1,
            minWidth: 50,
            paddingRight: 30,
          }}
          canCreate={user.can('tag.create')}
          filters={tagData}
          fetch={API.Tag.list}
          create={API.Tag.create}
          createData={tagData}
          successMessage="TAG.CREATED"
          disabled={disableTags}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          multipleValues={multipleValues}
          onClick={onClick}
          onChange={onChangeTag}
          onBlur={onDropdownBlur}
          onFocus={onDropdownFocus}
          // prevent assuming if the input text exactly matches an existing tag
          forceSelect
          // focusAfterSelect
        />
      </div>

      {showAs === 'list' && tags.length > 0 && (
        <div className="tags-list">
          <VibeIcon
            type="button"
            icon={viTag}
            color={color.manatee}
            size={16}
            // style={{
            //   cursor: 'default',
            // }}
            buttonProps={{
              size: 24,
              color: color.white,
              borderColor: color.whiteSmoke,
              style: {
                cursor: 'default',
              },
            }}
          />

          {tags.map((tag) => {
            // Hide removed tags from the list under the input
            if (tag.status === 'remove') {
              return null;
            }

            return (
              <Tag
                key={tag._id}
                tag={tag}
                dataId={dataId}
                activeType={activeType}
                allowRemove={!disabled
                  && assign
                  && user.hasAccessToCompany(companyId)}
                onRemove={onRemoveTag}
              />
            );
          })}
        </div>
      )}

      {!disabled ? (
        <Dialog
          className="TagsManageDialog"
          classes={{
            paperFullWidth: 'TagsManageDialogPaper',
          }}
          open={manageTagsModal}
          fullWidth
          // disableEnforceFocus
        >
          <ManageTags
            companyId={companyId}
            onlyAdminTags={allowAdminTags && !allowClientTags}
            onCancel={onCloseManageTagsModal}
            onSave={onCloseManageTagsModal}
          />
        </Dialog>
      ) : null}
    </div>
  );
}

Tags.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  dataId: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  assign: PropTypes.bool,
  /** Allow client/admin tags in this component */
  allowTypes: PropTypes.arrayOf(PropTypes.oneOf([
    'client',
    'admin',
  ])),
  /** Show the tags as a list under the field, inline with the field, or not at all */
  showAs: PropTypes.oneOf([
    'list',
    // 'inline',
    'none',
  ]),
  dropdownStyle: PropTypes.object,
  companyId: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
  multipleValues: PropTypes.bool,
  required: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

Tags.defaultProps = {
  className: '',
  style: {},
  id: '',
  dataId: '',
  name: '',
  tags: [],
  label: '',
  hideLabel: false,
  assign: false,
  /** Allow client/admin tags in this component */
  allowTypes: ['client', 'admin'],
  /** Show the tags as a list under the field, inline with the field, or not at all */
  showAs: 'none',
  dropdownStyle: {},
  companyId: '',
  disabled: false,
  tabIndex: -1,
  autoFocus: false,
  multipleValues: false,
  required: false,
  onClick: () => {},
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Tags);
