import React from 'react';

const viCircleFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <circle cx="12" cy="12" r="12" fill="currentColor" />
  </svg>
);

export default viCircleFilled;
