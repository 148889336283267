import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import Playlist from '../../../../../Programs/Baselines/Calendar/Items/Playlist';
import Mix from '../../../../../Programs/Baselines/Calendar/Items/Mix';
import './MusicItem.scss';

function MusicItem({
  style,
  type,
  playlist,
  station,
  minStartTime,
  maxStopTime,
  color,
  fullView,
  readOnly,
}) {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const archived = type === 'playlist'
    ? !get(playlist, 'playlist.active')
    : !get(station, 'mix.active');

  const itemStyle = {
    background:
      // eslint-disable-next-line no-nested-ternary
      hover
        ? archived
          ? `repeating-linear-gradient(45deg, ${color}40, ${color}40 10px, ${color}59 10px, ${color}59 20px)`
          : `${color}40`
        : archived
          ? `repeating-linear-gradient(45deg, ${color}1A, ${color}1A 10px, ${color}33 10px, ${color}33 20px)`
          : `${color}1A`,
    border: `1px solid ${color}4D`,
    boxSizing: 'border-box',
    ...style,
  };

  return (
    <div
      className="MusicItem"
      style={itemStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {fullView && (
        <div
          style={{
            height: '100%',
          }}
        >
          {type === 'playlist' ? (
            <Playlist
              id={playlist._uniqueId}
              playlist={playlist.playlist}
              startTime={playlist.startTime}
              stopTime={playlist.stopTime}
              minStartTime={minStartTime}
              maxStopTime={maxStopTime}
              archived={archived}
              readOnly={readOnly}
            />
          ) : (
            <Mix
              id={playlist._uniqueId}
              mix={station.mix}
              startTime={station.startTime}
              stopTime={station.stopTime}
              minStartTime={minStartTime}
              maxStopTime={maxStopTime}
              archived={archived}
              readOnly={readOnly}
            />
          )}
        </div>
      )}
    </div>
  );
}

MusicItem.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  type: PropTypes.oneOf([
    'playlist',
    'station',
  ]).isRequired,
  playlist: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  station: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Prevent starting before */
  minStartTime: PropTypes.string,
  /** Prevent stopping after */
  maxStopTime: PropTypes.string,
  color: PropTypes.string.isRequired,
  /** View entire item */
  fullView: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
};

MusicItem.defaultProps = {
  style: {},
  playlist: {},
  station: {},
  minStartTime: '',
  maxStopTime: '',
  fullView: false,
  readOnly: false,
};

export default MusicItem;
