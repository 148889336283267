import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import rootColor from '../../sass/color.scss';
import './VibeToggle.scss';

class VibeToggle extends PureComponent {
  onChange = () => {
    const {
      label,
      disabled,
      onChange,
    } = this.props;

    if (disabled) {
      console.warn('Toggle is disabled');
      return;
    }

    onChange(label);
  };

  render() {
    const {
      className,
      style,
      label,
      labelStyle,
      checked,
      outlined,
      partial,
      disabled,
      color,
      height,
    } = this.props;

    const width = (height * 2);

    let background = rootColor.whiteSmoke;

    if (checked) {
      background = color;
    } else if (partial) {
      background = rootColor.yellow;
    }

    return (
      <div
        className={classNames('VibeToggle', className, { outlined, disabled })}
        style={style}
        onClick={this.onChange}
      >
        <label
          className="toggle-label"
          style={labelStyle}
        >
          {label}
        </label>

        <div
          className="toggle-checkbox"
          style={{
            width,
            height,
            background,
            borderRadius: (width / 2),
          }}
        >
          <div
            className={classNames('toggle-ball', { checked, partial })}
            style={{
              width: height - 2,
              height: height - 2,
            }}
          />
        </div>
      </div>
    );
  }
}

VibeToggle.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Toggle label */
  label: PropTypes.string,
  /** Toggle label style */
  labelStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Outline the whole toggle field with a border */
  outlined: PropTypes.bool,
  /** Is the toggle fully on */
  checked: PropTypes.bool,
  /** Is the toggle partially on (halfway) */
  partial: PropTypes.bool,
  /** Disable the toggle */
  disabled: PropTypes.bool,
  /** Height of toggle */
  height: PropTypes.number,
  /** Color of the toggle */
  color: PropTypes.string,
  /** When the toggle is changed */
  onChange: PropTypes.func,
};

VibeToggle.defaultProps = {
  className: '',
  style: {},
  label: null,
  labelStyle: {},
  outlined: false,
  checked: false,
  partial: false,
  disabled: false,
  height: 16,
  color: '#00a474',
  onChange: () => {},
};

export default VibeToggle;
