import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Field from '../../Field/Field';
import PercentMeter from '../../PercentMeter/PercentMeter';
import './ContentPercent.scss';

class ContentPercent extends PureComponent {
  constructor(props) {
    super(props);

    const {
      percentage,
    } = props;

    this.state = {
      percentage,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      percentage,
    } = this.props;

    const {
      percentage: prevPercentage,
    } = prevProps;

    if (percentage !== prevPercentage) {
      this.setState({
        percentage,
      });
    }
  }

  onChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      percentage: value,
    });
  };

  onBlur = () => {
    const {
      onChange,
    } = this.props;

    const {
      percentage,
    } = this.state;

    onChange(percentage);
  };

  /**
   * Render the Field used for editing
   */
  renderEditField = () => {
    const {
      percentage,
    } = this.state;

    return (
      <Field
        className="edit"
        type="text"
        placeholder="Edit..."
        value={percentage}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  };

  render() {
    const {
      align,
      editable,
      isEdit,
      ranges,
    } = this.props;

    const {
      percentage,
    } = this.state;

    return (
      <div
        className="ContentPercent"
        style={{
          justifyContent: align,
        }}
      >
        <PercentMeter
          percentage={percentage}
          ranges={ranges}
        />

        {editable && isEdit ? (
          <div className="edit-container">
            {this.renderEditField()}
          </div>
        ) : null}
      </div>
    );
  }
}

ContentPercent.propTypes = {
  /** Content is editable */
  editable: PropTypes.bool,
  /** Content is in edit mode */
  isEdit: PropTypes.bool,
  /** Percentage */
  percentage: PropTypes.number,
  /** Ranges of percents with custom options */
  ranges: PropTypes.arrayOf(PropTypes.shape({
    range: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
  })),
  /** Range of percentage settings */
  /** Column Alignment */
  align: PropTypes.oneOf([
    'left',
    'center',
    'right',
  ]),
  /** When content is changed */
  onChange: PropTypes.func,
};

ContentPercent.defaultProps = {
  editable: false,
  isEdit: false,
  percentage: 0,
  ranges: [{
    range: '0-100',
    color: '#6d6db5',
    border: false,
    icon: null,
  }],
  align: 'left',
  onChange: () => {},
};

export default ContentPercent;
