import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ContentLabel.scss';

class ContentLabel extends PureComponent {
  render() {
    const {
      className,
      text,
      textStyle,
      align,
    } = this.props;

    return (
      <div
        className={classNames('ContentLabel', className)}
        style={{
          justifyContent: align,
        }}
      >
        <div
          className="text"
          style={textStyle}
        >
          {text}
        </div>
      </div>
    );
  }
}

ContentLabel.propTypes = {
  className: PropTypes.string,
  /** Content is editable */
  // editable: PropTypes.bool,
  /** Content is in edit mode */
  // isEdit: PropTypes.bool,
  /** Text */
  text: PropTypes.string,
  /** Text Style */
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Column Alignment */
  align: PropTypes.oneOf([
    'left',
    'center',
    'right',
  ]),
};

ContentLabel.defaultProps = {
  className: '',
  // editable: false,
  // isEdit: false,
  text: '',
  textStyle: {},
  align: 'left',
};

export default ContentLabel;
