import React from 'react';
import PropTypes from 'prop-types';
import {
  API,
  VibeIcon,
  viClose,
  color,
  TableUsers,
} from 'vibeguide';
import './CompanyUsers.scss';

function CompanyUsers({
  companyId,
  onClose,
}) {
  return (
    <div className="CompanyUsers">
      <div className="title-container">
        <div className="title">
          Assigned Users
        </div>

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <TableUsers
        columns={[
          '.',
          'First Name',
          'Last Name',
          'Title',
          'Email',
          'Phone #',
          'Company',
          'Tags',
          '...',
        ]}
        fetch={API.Company.getUsers}
        fetchProps={{
          filters: {
            _id: companyId,
          },
        }}
        defaultSortBy={{
          label: 'First Name',
          attr: 'fname',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Users',
          urlPaging: false,
          urlFilters: false,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Users',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: false,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        menuItems={[
          { name: 'Archive', userCan: 'user.delete' },
        ]}
      />
    </div>
  );
}

CompanyUsers.propTypes = {
  companyId: PropTypes.string,
  onClose: PropTypes.func,
};

CompanyUsers.defaultProps = {
  companyId: '',
  onClose: () => {},
};

export default CompanyUsers;
