import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import UserCard from '../UserCard/UserCard';
import Paginator from '../Paginator/Paginator';
import './Users.scss';

class Users extends Component {
  constructor(props) {
    super(props);

    const {
      selectAll,
    } = props;

    this.state = {
      // selectedIds: [],
      selectAll,
    };
  }

  toggleSelected = (userId, selected) => {
    const {
      onSelect,
      onDeselect,
    } = this.props;

    if (selected) {
      // this.setState((state) => {
      //   return {
      //     selectedIds: [...state.selectedIds, userId],
      //   };
      // });

      onSelect(userId);
    } else {
      // this.setState((state) => {
      //   return {
      //     selectedIds: state.selectedIds.filter(id => id !== userId),
      //   };
      // });

      onDeselect(userId);
    }
  };

  render() {
    const {
      cardStyle,
      users,
      selectedIds,
      total,
      allowClick,
      showPaginator,
      slim,
    } = this.props;

    const {
      selectAll,
    } = this.state;

    const userCards = [];
    const sm = slim ? 12 : 6;
    const md = slim ? 10 : 3;

    users.forEach((user, index) => {
      const isSelected = selectedIds.includes(user._id);

      userCards.push(
        <Grid
          key={index}
          className="user-card-grid"
          xs={12}
          sm={sm}
          md={md}
          item
        >
          <UserCard
            key={index}
            style={cardStyle}
            id={user._id}
            user={user}
            toggleSelected={this.toggleSelected}
            disabled={selectAll}
            selected={isSelected}
            allowClick={allowClick}
            slim={slim}
          />
        </Grid>,
      );
    });

    return (
      <div className="Users">
        {showPaginator ? (
          <Grid container>
            <Grid item xs={12}>
              <Paginator
                className="location-paginator"
                totalLoaded={users.length}
                totalItems={total}
                label="Users"
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid spacing={2} container>
          {userCards}
        </Grid>
      </div>
    );
  }
}

Users.propTypes = {
  cardStyle: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    /** First Name */
    fname: PropTypes.string,
    /** Last Name */
    lname: PropTypes.string,
    companyName: PropTypes.string,
    active: PropTypes.bool,
    imageUrl: PropTypes.string,
  })).isRequired,
  /** Total number of locations */
  total: PropTypes.number,
  /** Selected location IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Are all locations selected */
  selectAll: PropTypes.bool,
  allowClick: PropTypes.bool,
  showPaginator: PropTypes.bool,
  /** Slim user card */
  slim: PropTypes.bool,
  /** When a location is selected */
  onSelect: PropTypes.func,
  /** When a location is deselected */
  onDeselect: PropTypes.func,
};

Users.defaultProps = {
  cardStyle: {},
  selectedIds: [],
  selectAll: false,
  total: 0,
  allowClick: false,
  showPaginator: false,
  slim: false,
  onSelect: () => {},
  onDeselect: () => {},
};

export default Users;
