import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  API,
  TableLocationsExcluded,
  GlobalActions,
  NavigationHelper,
  withRouter,
} from 'vibeguide';
import SupportSidebar from './SupportSidebar/SupportSidebar';
import DashboardHeader from './DashboardHeader';
import './DashboardExcluded.scss';

class DashboardExcluded extends PureComponent {
  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isView = qs.locationId;

    if (isView) {
      setTimeout(() => {
        this.sidebar(qs.type, qs.locationId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.locationId) {
        this.sidebar(qs.type, qs.locationId);
      }
    }
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      locationId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `support-locations-${timestamp}.csv`);
  };

  sidebar = (type, locationId = null) => {
    const {
      setPanel,
      user,
    } = this.props;

    const canManageSettings = user.can('alert.manage_settings');

    setPanel({
      show: true,
      backdrop: false,
      width: 400,
      children: (
        <SupportSidebar
          locationId={locationId}
          type="alerts"
          canManageSettings={canManageSettings}
          onClose={this.onCloseSidebar}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    const canManageSettings = user.can('alert.manage_settings');

    return (
      <div className="DashboardExcluded">
        <DashboardHeader
          className="support-header"
          title="Excluded Locations"
        />

        <TableLocationsExcluded
          columns={[
            'Location Name',
            'Company',
            'Volume',
            'Audio',
            'Battery',
            'Memory',
            'Network',
            'Offline',
          ]}
          fetch={API.Location.Alerts.Config.listActive}
          defaultSortBy={{
            label: 'Location Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Locations',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Locations',
            tooltip: true,
          }}
          csv={user.sysAdmin}
          csvProps={{
            onExport: this.onExport,
          }}
          rowLink={canManageSettings
            ? {
              locationId: '_id',
            } : null}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardExcluded));
