import Http from '../Http';

const baseUrl = `${process.env.VAPI_BASE_URL}/devicemanagement`;

/**
 * Sort the list by activation status
 */
const ACTIVATION_STATUS_SORT_VALUES = {
  live: 0,
  standby: 10,
  setup: 20,
  decommissioned: 100,
};

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return response.sort((a, b) => {
      const aWeight = ACTIVATION_STATUS_SORT_VALUES[a.activationStatus];
      const bWeight = ACTIVATION_STATUS_SORT_VALUES[b.activationStatus];

      if (aWeight < bWeight) return -1;
      if (aWeight > bWeight) return 1;

      return 0;
    });
  },
  getById: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  create: (data, options) => Http.post(baseUrl, data, options),
  transfer: (data, options) => Http.post(`${baseUrl}/${data._id}/transfer`, data, options),
  update: (data, options) => Http.put(`${baseUrl}/${data._id}`, data, options),
  activate: (data, options) => Http.post(`${baseUrl}/${data._id}/activate`, data, options),
  decommission: (data, options) => Http.post(`${baseUrl}/${data._id}/decommission`, data, options),
  reset: (data, options) => Http.post(`${baseUrl}/${data._id}/reset`, data, options),
};
