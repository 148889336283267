// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApplyCancel {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.ApplyCancel .btn-cancel {
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeTable/Toolbar/ApplyCancel.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;AACJ","sourcesContent":[".ApplyCancel {\n  display: flex;\n  align-items: center;\n  margin-right: 20px;\n\n  .btn-cancel {\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
