import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
} from '@mui/material';
import SnapshotGrid from './Snapshot/SnapshotGrid';

function Snapshot({
  className,
  playlists,
  mixes,
  messageBlocks,
  interruptions,
  disabled,
  onClickDay,
}) {
  return (
    <div className={classNames('Snapshot', className)}>
      <Grid
        className="row extra-space"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Snapshot
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          xs={12}
          item
        >
          <SnapshotGrid
            playlists={playlists}
            mixes={mixes}
            messageBlocks={messageBlocks}
            interruptions={interruptions}
            disabled={disabled}
            onClickDay={onClickDay}
          />
        </Grid>
      </Grid>
    </div>
  );
}

Snapshot.propTypes = {
  className: PropTypes.string,
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interruptions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onClickDay: PropTypes.func,
};

Snapshot.defaultProps = {
  className: '',
  playlists: [],
  mixes: [],
  messageBlocks: [],
  interruptions: [],
  disabled: false,
  onClickDay: () => {},
};

export default Snapshot;
