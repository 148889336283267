import React from 'react';

const viFrequency = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M9.33 13.52H3.02c-.563 0-1.02-.42-1.02-.938s.457-.938 1.02-.938h4.77L10.786 2l3.18 13.183 1.228-3.54h5.787c.563 0 1.02.421 1.02.94 0 .518-.457.938-1.02.938h-4.462L13.383 22 10.614 9.387 9.33 13.521z" />
    </g>
  </svg>
);

export default viFrequency;
