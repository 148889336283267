import { createAction } from 'redux-actions';

export const setSongs = createAction('SET_SONGS');
export const clearSongs = createAction('CLEAR_SONGS');
export const updateSong = createAction('UPDATE_SONG');

export default {
  setSongs,
  clearSongs,
  updateSong,
};
