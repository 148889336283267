import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import {
  VibeTooltip,
  VibeIcon,
  viEdit,
  viMicrophone,
  viThumbUp,
  viUser,
  viVibeCube,
  viFile,
  color,
} from 'vibeguide';
import './ChatMessage.scss';

class ChatMessage extends PureComponent {
  /**
   * Get the voice talent HTML comment
   */
  getVoiceTalentComment = () => {
    const {
      message,
    } = this.props;

    const firstName = get(message, 'detail.fname', '');
    const lastName = get(message, 'detail.lname', '');

    return (
      <span className="assign-voice-talent">
        Assigned

        <span className="voice-talent-name">
          &nbsp;
          {firstName} {lastName}
          &nbsp;
        </span>

        as voice talent
      </span>
    );
  };

  render() {
    const {
      user,
      message,
    } = this.props;

    const isContentCurator = user.can('message.content_curator');
    const isVoiceTalent = user.voiceTalent && user._id === message.voiceTalentId;
    const isUser = !isContentCurator && !isVoiceTalent;
    const isOwnMessage = message.userId === user._id;
    const isClientReview = message.activityType === 'client-review'
      && get(message, 'detail.response', null) === 'request-change';
    const isClientReviewAccepted = message.activityType === 'client-review'
      && (
        get(message, 'detail.response', null) === 'accept'
        || get(message, 'detail.response', null) === 'auto-accept'
      );
    const isUpload = message.activityType === 'upload';
    const uploadType = get(message, 'detail.type', null);
    const isAttachment = message.activityType === 'attachment';
    const attachmentName = get(message, 'detail.fileName', null);

    const fromNowDate = message.date ? new moment(message.date) : new moment();
    const fromNow = fromNowDate.fromNow();
    const messageTime = fromNowDate.calendar(null, {
      // when the date is closer, specify custom values
      lastDay: '[Yesterday at] h:mm A',
      nextDay: '[Tomorrow at] h:mm A',
      sameDay: () => {
        return `[${fromNow}]`;
      },
      // when the date is further away
      lastWeek: 'dddd [at] h:mm A',
      nextWeek: 'dddd [at] h:mm A',
      sameElse: () => {
        return `[${fromNow}]`;
      },
    });

    // The text to display of the message
    let comment = get(message, 'detail.comment', '');

    if (message.activityType === 'assign-voice-talent') {
      comment = this.getVoiceTalentComment();
    }

    if (
      message.activityType === 'upload'
      && isUser
      && uploadType !== 'final'
    ) {
      // User should only see final cut messages
      return null;
    }

    if (
      message.activityType === 'assign-voice-talent'
      && isUser
    ) {
      // User should not see assigned voice talent messages
      return null;
    }

    const from = get(message, 'detail.from', null);

    let messageImageHtml = (
      <VibeIcon
        icon={viUser}
        color={color.lightGray}
        size={32}
      />
    );

    if (
      (isUser || isVoiceTalent)
      && (from === 'curator' || isUpload)
    ) {
      // Is Client or Voice Talent
      messageImageHtml = (
        <VibeIcon
          icon={viVibeCube}
          color={color.violetVibe}
          size={28}
        />
      );
    } else if (
      (isContentCurator || isOwnMessage)
      && message.imageUrl
    ) {
      // Is Curator (or client's own message)
      messageImageHtml = (
        <img
          src={message.imageUrl}
          alt="avatar"
        />
      );
    }

    return (
      <div className={classNames('ChatMessage', {
        'from-user-message': isOwnMessage,
        'recipient-user-message': !isOwnMessage,
      })}
      >
        <div className="message-header-details">
          <div className="message-date">
            <div className={classNames('date-at', { 'full-width': isOwnMessage })}>
              {messageTime}
            </div>

            {isContentCurator && !isOwnMessage ? (
              <div className="sender-name">
                {message.fname.length + message.lname.length > 13 ? (
                  <VibeTooltip
                    placement="top"
                    title={`${message.fname} ${message.lname}`}
                  >
                    <span>
                      • {message.fname} {message.lname}
                    </span>
                  </VibeTooltip>
                ) : `• ${message.fname} ${message.lname}`}
              </div>
            ) : null}
          </div>
          <div className="message-avatar">
            {messageImageHtml}
          </div>
        </div>

        <div className="chat-message-card">
          <div className="message-content-container">
            {isClientReview ? (
              <div className="client-review">
                <VibeIcon
                  className="client-review-edit-icon"
                  icon={viEdit}
                  color={color.flamingo}
                  size={16}
                />

                <div>
                  {isOwnMessage ? 'You' : message.fname} requested changes.
                </div>
              </div>
            ) : null}

            {isClientReviewAccepted ? (
              <div className="client-review">
                <VibeIcon
                  className="client-review-edit-icon"
                  icon={viThumbUp}
                  color={color.aquaForest}
                  size={16}
                />

                <div>
                  {isOwnMessage ? 'You' : message.fname} approved the message.
                </div>
              </div>
            ) : null}

            {isUpload ? (
              <div className="message-upload">
                <VibeIcon
                  className="message-upload-mic-icon"
                  icon={viMicrophone}
                  color={color.aquaForest}
                  size={16}
                />

                <div>
                  Uploaded latest recording.
                </div>
              </div>
            ) : null}

            {isAttachment ? (
              <div className="message-attachment-upload">
                <VibeIcon
                  className="message-attachment-upload-file-icon"
                  icon={viFile}
                  color={color.aquaForest}
                  size={16}
                />

                <div>
                  Uploaded attachment {attachmentName}.
                </div>
              </div>
            ) : null}

            <div className="message-comment">
              {comment}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    activityType: PropTypes.string,
    date: PropTypes.string,
    detail: PropTypes.shape({
      comment: PropTypes.string,
      from: PropTypes.string,
      rcpt: PropTypes.string,
    }),
    fname: PropTypes.string,
    imageUrl: PropTypes.string,
    lname: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(ChatMessage);
