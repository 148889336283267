import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { find, get } from 'lodash';
import Box from '@mui/material/Box';
import { InputAdornment, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import VibeIcon from '../VibeIcon/VibeIcon';
import viExpandMore from '../../icons/viExpandMore';
import countries from '../../utils/Countries';
import color from '../../sass/color.scss';
import './CountryField.scss';

function CountryField({
  className,
  name,
  placeholder,
  value,
  disabled,
  tabIndex,
  onFocus,
  onChange,
  onBlur,
}) {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);

  const srcRoot = 'https://flagcdn.com/w20';

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.tabIndex = tabIndex;
    }
  }, []);

  const handleChange = ((e, value) => {
    onChange({
      target: {
        value,
        name,
      },
    });
  });

  const handleFocus = (e) => {
    setOpen(true);
    onFocus(e);
  };

  const handleBlur = (e) => {
    setOpen(false);
    onBlur(e);
  };

  const getOptionLabel = (option) => {
    if (option) {
      const country = find(countries, country => country.code === option);
      return get(country, 'label', '') || '';
    }
    return '';
  };

  const renderOption = (props, option) => {
    const country = find(countries, { code: option });

    if (country) {
      return (
        <Box
          key={country}
          component="li"
          sx={{
            fontSize: '11px',
            '& > img': { mr: 1, flexShrink: 0 },
          }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`${srcRoot}/${option.toLowerCase()}.png`}
            alt=""
          />
          {country.label} ({option})
        </Box>
      );
    }

    return null;
  };

  return (
    <div className={classNames('CountryField', className)}>
      <Autocomplete
        classes={{
          root: 'CountryFieldRoot',
        }}
        label={name}
        value={value}
        open={open}
        sx={{ width: 300 }}
        options={[
          '',
          ...countries.map(country => country.code),
        ]}
        disableClearable
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classNames('CountryFieldInput', { disabled })}
            placeholder={placeholder || 'United States'}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              classes: {
                focused: 'CountryFieldInputFocus',
                disabled: disabled ? 'disabled' : '',
              },
              autoComplete: 'new-password',
              startAdornment: (
                <InputAdornment position="start">
                  {value && (
                    <img
                      loading="lazy"
                      width="20"
                      className="country-flag"
                      src={`${srcRoot}/${value.toLowerCase()}.png`}
                      alt=""
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <VibeIcon
                  className="arrow-down"
                  style={{
                    position: 'absolute',
                    right: 0,
                    padding: 8,
                    cursor: 'context-menu',
                  }}
                  icon={viExpandMore}
                  color={color.manatee}
                  size={16}
                />
              ),
            }}
          />
        )}
      />
    </div>
  );
}

CountryField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  tabIndex: PropTypes.number,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

CountryField.defaultProps = {
  className: '',
  name: '',
  placeholder: '',
  value: '',
  tabIndex: 0,
  onFocus: () => {},
  onChange: () => {},
  onBlur: () => {},
};

export default CountryField;
