import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  FieldGroup,
  FieldGroupItem,
  Field2 as Field,
} from 'vibeguide';

function ReportRow({
  className,
  style,
  reportId,
  name,
  url,
  disableInput,
  showLabels,
  onUpdate,
  onRemove,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    }, reportId);
  };

  return (
    <FieldGroup
      className={classNames('ReportRow', className)}
      style={style}
      id={reportId}
      allowRemove={!disableInput}
      removeTooltip="Remove Report"
      onRemove={onRemove}
    >
      <FieldGroupItem>
        <Field
          type="text"
          label={showLabels
            ? 'Report Name'
            : null}
          name="name"
          placeholder="Report Name"
          value={name}
          disabled={disableInput}
          onChange={onChange}
        />
      </FieldGroupItem>

      <FieldGroupItem>
        <Field
          type="text"
          label={showLabels
            ? 'URL'
            : null}
          name="url"
          placeholder="URL"
          value={url}
          disabled={disableInput}
          onChange={onChange}
        />
      </FieldGroupItem>
    </FieldGroup>
  );
}

ReportRow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  reportId: PropTypes.string.isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
  disableInput: PropTypes.bool,
  showLabels: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
};

ReportRow.defaultProps = {
  className: '',
  style: {},
  name: '',
  url: '',
  disableInput: false,
  showLabels: false,
  onUpdate: () => {},
  onRemove: () => {},
};

export default ReportRow;
