import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  VibeButton,
  VibeDialog,
  TableSongs,
  GlobalActions,
  NavigationHelper,
  API,
  withRouter,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import SongSidebar from '../Songs/Sidebar/SongSidebar';
import PlaylistSelector from '../Songs/PlaylistSelector';
import './PublishContainer.scss';

class PublishContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      errorIds: [],
      showPlaylistSelection: false,
    };
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.songId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.songId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.songId || qs.type === 'new') {
        this.sidebar(qs.type, qs.songId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      songId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  onAddToPlaylists = async (data) => {
    const {
      songs,
      playlists,
    } = data;

    const {
      setModal,
    } = this.props;

    const response = await API.Music.reviewPublish({
      musicIds: songs.map(song => song._id),
      playlistIds: playlists.map(playlist => playlist._id),
    });

    // publish errors
    const errorItems = response.filter(result => result.type.indexOf('MUSIC.') >= 0
      && result.type.indexOf('MUSIC.PUBLISHED') < 0);
    const errorIds = errorItems.map(item => item.documentId);
    const errorMessages = errorItems.map(item => item.data.message);

    if (errorMessages.length > 0) {
      setModal({
        type: 'error',
        title: `${errorItems.length} Songs Failed to Publish`,
        message: (
          <div>
            {errorMessages.map((message, index) => {
              return (
                <div
                  key={`publish-error-${index}`}
                >
                  {message}
                </div>
              );
            })}
          </div>
        ),
      });

      this.setState({
        errorIds,
      });
    }

    this.closePlaylistSelection();

    // reset the selected rows
    document.dispatchEvent(new Event('onResetSelectedRows'));
    // update the table to see if any were published
    document.dispatchEvent(new Event('onSaveSong'));
  };

  /**
   * When selected rows are changed
   */
  onChangeSelected = (selectedRows) => {
    this.setState({
      selected: selectedRows,
    });
  };

  sidebar = (type, songId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <SongSidebar
          songId={songId}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  saveChanges = async () => {
    const {
      setModal,
    } = this.props;

    const {
      selected,
    } = this.state;

    const response = await API.Music.reviewPublish({
      musicIds: selected.map(row => row._id),
    });

    // publish errors
    const errorItems = response.filter(result => result.type.indexOf('MUSIC.PUBLISHED') < 0);
    const errorIds = errorItems.map(item => item.documentId);
    const errorMessages = errorItems.map(item => item.data.message);

    if (errorMessages.length > 0) {
      setModal({
        type: 'error',
        title: `${errorItems.length} Songs Failed to Publish`,
        message: (
          <div>
            {errorMessages.map((message, index) => {
              return (
                <div
                  key={`publish-error-${index}`}
                >
                  {message}
                </div>
              );
            })}
          </div>
        ),
      });

      this.setState({
        errorIds,
      });
    }

    // reset the selected rows
    document.dispatchEvent(new Event('onResetSelectedRows'));
    // update the table to see if any were published
    document.dispatchEvent(new Event('onSaveSong'));
  };

  openPlaylistSelection = () => {
    this.setState({
      showPlaylistSelection: true,
    });
  };

  closePlaylistSelection = () => {
    this.setState({
      showPlaylistSelection: false,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    const {
      selected,
      errorIds,
      showPlaylistSelection,
    } = this.state;

    return (
      <div>
        <VibeDialog
          title="Upload Songs"
          closeDialogLink="/music/songs"
          fullScreen
          open
        >
          <div className="PublishContainer">
            <div className="header">
              <Grid container>
                <Grid item className="title" xs={6}>
                  Edit Songs for Publishing
                </Grid>

                <Grid item className="toolbar" xs={6}>
                  <VibeButton
                    className="toolbar-btn"
                    text="Publish Selected Songs"
                    btnColor="purple"
                    textColor="white"
                    disabled={selected.length <= 0}
                    onClick={this.saveChanges}
                  />

                  <VibeButton
                    className="toolbar-btn"
                    text="Publish Selected Songs to Playlists"
                    btnColor="green"
                    textColor="white"
                    disabled={selected.length <= 0}
                    onClick={this.openPlaylistSelection}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="publish-content">
              <TableSongs
                columns={[
                  '.',
                  'Song Title',
                  'Artist',
                  'Album',
                  'Genre',
                  'ISRC',
                  'ISWC',
                  'Record Label',
                  'Source',
                  'Release Year',
                  'Duration',
                  'Tempo',
                  'Ranking',
                  'Rating',
                  'Reason',
                  'Playlists',
                  'PRO',
                  'Modified',
                  'Tags',
                  '...',
                ]}
                fetch={API.Music.listUploaded}
                defaultSortBy={{
                  label: 'Song Title',
                  attr: 'name',
                  direction: 'asc',
                }}
                paginator
                paginatorProps={{
                  label: 'Songs',
                  urlPaging: true,
                  urlFilters: true,
                }}
                toggle
                toggleProps={{
                  label: 'Show Archived Songs',
                  tooltip: true,
                }}
                bulk
                bulkProps={{
                  edit: true,
                  add: false,
                  block: false,
                  remove: false,
                  archive: true,
                }}
                editMode={user.can('music.modify')}
                errorIds={errorIds}
                rowLink={{
                  songId: '_id',
                }}
                menuItems={[
                  { name: 'Archive', userCan: 'music.delete' },
                ]}
                onChangeSelected={this.onChangeSelected}
              />
            </div>
          </div>
        </VibeDialog>

        <VibeDialog
          title="Publish Songs to Playlists"
          open={showPlaylistSelection}
          fullScreen
          onClose={this.closePlaylistSelection}
        >
          <PlaylistSelector
            songs={selected}
            onAddToPlaylists={this.onAddToPlaylists}
          />
        </VibeDialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setModal: GlobalActions.setModal,
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishContainer));
