import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  get,
  noop,
  isFunction,
} from 'lodash';
import {
  CircularProgress,
} from '@mui/material';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import './VibeIcon.scss';

class VibeIcon extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  /**
   * When the button/icon is clicked do not allow bubble events
   */
  onClick = (e) => {
    const {
      disabled,
      loading,
      name,
      onClick,
    } = this.props;

    if (disabled || loading) {
      return;
    }

    if (isFunction(onClick)) {
      onClick(e, name);
    }
  };

  /**
   * When the icon is being hovered over
   */
  onMouseEnter = (e) => {
    const {
      disabled,
      hoverColor,
      onMouseEnter,
    } = this.props;

    if (hoverColor && !disabled) {
      // Only set the hover state if there is a color to change
      this.setState({
        hover: true,
      });
    }

    onMouseEnter(e);
  };

  /**
   * When the icon stops being hovered over
   */
  onMouseLeave = (e) => {
    const {
      disabled,
      hoverColor,
      onMouseLeave,
    } = this.props;

    if (hoverColor && !disabled) {
      // Only set the hover state if there is a color to change
      this.setState({
        hover: false,
      });
    }

    onMouseLeave(e);
  };

  render() {
    const {
      id,
      className,
      type,
      icon,
      color,
      hoverColor,
      size,
      loading,
      link,
      buttonProps,
      style,
      tooltip,
      tooltipProps,
      disabled,
      onMouseDown,
      onMouseUp,
    } = this.props;

    const {
      hover,
    } = this.state;

    const displayIcon = typeof icon === 'function' ? icon() : icon;

    const iconHtml = (
      <span
        id={id
          ? `icon-${id}`
          : null}
        className={classNames('VibeIcon', { disabled }, className)}
        style={{
          height: size,
          width: size,
          fontSize: size,
          color:
            hover
              ? hoverColor
              : color,
          cursor:
            hover
              ? 'pointer'
              : 'inherit',
          ...style,
        }}
        onClick={type === 'button'
          ? noop
          : this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        {!loading
          ? displayIcon
          : (
            <CircularProgress
              color="inherit"
              size={size}
            />
          )}
      </span>
    );

    const iconContainerHtml = type === 'button' ? (
      <div
        className={classNames('VibeIconButton', { disabled }, className)}
        style={{
          width: buttonProps.size,
          height: buttonProps.size,
          background: buttonProps.color,
          border: `1px solid ${buttonProps.borderColor}`,
          ...buttonProps.style,
        }}
        onClick={this.onClick}
      >
        {iconHtml}
      </div>
    ) : iconHtml;

    const iconLinkWrapper = link ? (
      <Link
        to={link}
      >
        {iconContainerHtml}
      </Link>
    ) : iconContainerHtml;

    return tooltip
      ? (
        <VibeTooltip
          title={tooltip}
          placement={tooltipProps.placement}
          className={tooltipProps.className}
          arrow={get(tooltipProps, 'arrow', true)}
        >
          {iconLinkWrapper}
        </VibeTooltip>
      )
      : iconLinkWrapper;
  }
}

VibeIcon.propTypes = {
  /** Unique ID */
  id: PropTypes.string,
  /** Custom Classes */
  className: PropTypes.string,
  /** Type of icon */
  type: PropTypes.oneOf([
    'icon',
    'button',
  ]),
  /** Icon */
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  /** Icon color */
  color: PropTypes.string.isRequired,
  /** Hover color of the icon */
  hoverColor: PropTypes.string,
  /** Icon size */
  size: PropTypes.number,
  /** Change the icon to a loading spinner when actions are performed */
  loading: PropTypes.bool,
  /** Name of icon */
  name: PropTypes.string,
  /** Link URL when clicked */
  link: PropTypes.string,
  /** Button Properties */
  buttonProps: PropTypes.shape({
    size: PropTypes.number,
    color: PropTypes.string,
    borderColor: PropTypes.string,
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Tooltip text */
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  /** Tooltip props */
  tooltipProps: PropTypes.shape({
    className: PropTypes.string,
    placement: PropTypes.string,
    arrow: PropTypes.bool,
  }),
  /** Icon is disabled */
  disabled: PropTypes.bool,
  /** When icon is clicked */
  onClick: PropTypes.func,
  /** When the mouse enters the icon */
  onMouseEnter: PropTypes.func,
  /** When the mouse leaves the icon */
  onMouseLeave: PropTypes.func,
  /** When the mouse is pressed down */
  onMouseDown: PropTypes.func,
  /** When the mouse is let up */
  onMouseUp: PropTypes.func,
};

VibeIcon.defaultProps = {
  id: '',
  className: '',
  type: 'icon',
  hoverColor: '',
  size: 16,
  loading: false,
  name: null,
  link: null,
  buttonProps: {
    size: 0,
    color: null,
    borderColor: null,
    style: {},
  },
  style: null,
  tooltip: null,
  tooltipProps: {
    className: '',
    placement: 'bottom',
    arrow: true,
  },
  disabled: false,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onMouseDown: () => {},
  onMouseUp: () => {},
};

export default VibeIcon;
