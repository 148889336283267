import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  color,
  BarChart,
  NavigationHelper,
  withRouter,
} from 'vibeguide';
import './OfflineDevices.scss';

class OfflineDevices extends PureComponent {
  /**
   * When the bar chart is clicked
   */
  onClickBarChart = (data) => {
    const {
      includeFlaggedLocations,
      history,
    } = this.props;

    // Get the label of the area clicked
    const label = get(data, 'activeLabel', null);

    if (!label) {
      console.error('No label found for the chart');
      return;
    }

    const currDate = moment();
    let rangeStart = null;
    let rangeStop = null;

    switch (label) {
      case '3-6 HRS': {
        rangeStart = currDate
          .clone()
          .subtract(6, 'hours')
          .format();
        rangeStop = currDate
          .clone()
          .subtract(3, 'hours')
          .format();
        break;
      }

      case '6-24 HRS': {
        rangeStart = currDate
          .clone()
          .subtract(1, 'day')
          .format();
        rangeStop = currDate
          .clone()
          .subtract(6, 'hours')
          .format();
        break;
      }

      case '1-7 DAYS': {
        rangeStart = currDate
          .clone()
          .subtract(1, 'week')
          .format();
        rangeStop = currDate
          .clone()
          .subtract(1, 'day')
          .format();
        break;
      }

      case 'WEEK+': {
        rangeStart = currDate
          .clone()
          .subtract(2, 'years')
          .format();
        rangeStop = currDate
          .clone()
          .subtract(1, 'week')
          .format();
        break;
      }

      default:
        console.warn('Unknown label');
        break;
    }

    if (!rangeStart || !rangeStop) {
      console.error('Missing date range start/stop', rangeStart, rangeStop);
      return;
    }

    const urlData = {
      lastDisconnectTime: `${rangeStart}|${rangeStop}`,
      playerOnline: false,
      deviceOnline: false,
      onboardStatus: 'complete',
    };

    if (!includeFlaggedLocations) {
      urlData.flagEnabled = false;
    }

    // Add a parameter for the table to automatically apply
    const url = NavigationHelper.updateParams(urlData, {
      pathname: '/dashboard/support',
    });

    history(url);
  };

  render() {
    const {
      data,
    } = this.props;

    const barData = [
      {
        name: '3-6 HRS',
        value: data['3-6 HOURS'],
      },
      {
        name: '6-24 HRS',
        value: data['6-24 HOURS'],
      },
      {
        name: '1-7 DAYS',
        value: data['1-7 DAYS'],
      },
      {
        name: 'WEEK+',
        value: data['WEEK+'],
      },
    ];

    return (
      <div className="OfflineDevices">
        <div className="header">
          <div className="title">
            Offline Devices
          </div>
        </div>

        <BarChart
          data={barData}
          onClick={this.onClickBarChart}
          barColor={color.fireBrick}
        />
      </div>
    );
  }
}

OfflineDevices.propTypes = {
  data: PropTypes.shape({
    '0-3 HOURS': PropTypes.number,
    '3-6 HOURS': PropTypes.number,
    '6-24 HOURS': PropTypes.number,
    '1-7 DAYS': PropTypes.number,
    'WEEK+': PropTypes.number,
  }).isRequired,
  includeFlaggedLocations: PropTypes.bool,
};

OfflineDevices.defaultProps = {
  includeFlaggedLocations: false,
};

export default withRouter(OfflineDevices);
