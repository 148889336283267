import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Field2 as Field,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function Information({
  className,
  value,
  disableInput,
  onUpdate,
}) {
  /**
   * When a field is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  return (
    <div className={classNames('Information', 'delivery-system', className)}>
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Information
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          xs={12}
          item
        >
          <Field
            type="text"
            label="Name"
            name="value"
            placeholder="Name"
            value={value}
            disabled={disableInput}
            tabIndex={10}
            onChange={onChange}
            autoFocus
            required
          />
        </Grid>
      </Grid>
    </div>
  );
}

Information.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  className: '',
  value: '',
  disableInput: false,
  onUpdate: () => {},
};

export default Information;
