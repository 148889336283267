import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  MusicActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  VibeModal,
  VibeTooltip,
  VibeButton,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  viPlayCircleOutline,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Information';
import SongPlaylists from './SongPlaylists';
import SongPRO from './SongPRO';
import './SongDetails.scss';

class SongDetails extends PureComponent {
  constructor(props) {
    super(props);

    // Store which values are updated that require an endpoint
    this.updated = {};
    this.origSong = {};
    this.origSongSaved = false;

    this.state = {
      confirmArchive: false,
      showConfirmSave: false,
    };
  }

  componentDidUpdate() {
    const {
      song,
    } = this.props;

    if (!this.origSongSaved && song._id) {
      this.origSongSaved = true;
      this.origSong = song;
    }

    const {
      origSong,
    } = this;

    if (this.origSongSaved) {
      // Only track what fields are updated once the original song is saved
      if (song.playlists !== origSong.playlists) {
        this.updated.playlists = true;
      }
    }
  }

  /**
   * When the locations assigned is clicked
   */
  onClickPlaylists = () => {
    const {
      song: {
        _id,
      },
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <SongPlaylists
            songId={_id}
            onClose={this.onCloseExtra}
          />
        ),
      },
    });
  };

  /**
   * When the extra panel is closed
   */
  onCloseExtra = () => {
    const {
      onCloseExtra,
    } = this.props;

    onCloseExtra();
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      song: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Music.reactivate({
      _id,
    });
    onClose();

    document.dispatchEvent(new Event('onSaveSong'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      song: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Music.deactivate(_id);

    this.onCloseArchive();
    onClose();

    document.dispatchEvent(new Event('onSaveSong'));
  };

  /**
   * Check if the song can be saved or if a warning needs to be shown
   */
  onClickSave = () => {
    const {
      user,
      song: {
        proDefinitions = [],
        proSelection = {},
      },
    } = this.props;

    if (!user.can('music.manage_pro')) {
      // save the song since the user cannot change the PRO settings
      this.onSave();
      return;
    }

    let songWillArchive = false;

    proDefinitions.forEach((definition) => {
      if (definition.excluded && proSelection[definition.name]) {
        // this PRO will archive the song. show a confirmation dialog
        songWillArchive = true;
      }
    });

    if (songWillArchive) {
      // Songs with an excluded definition will be archived automatically. Show a confirmation modal
      this.setState({
        showConfirmSave: true,
      });
    } else {
      // Song will not be archived, attempt saving
      this.onSave();
    }
  };

  onConfirmSave = () => {
    this.setState({
      showConfirmSave: false,
    });

    this.onSave();
  };

  onCancelSave = () => {
    this.setState({
      showConfirmSave: false,
    });
  };

  /**
   * Save the Song
   */
  onSave = async () => {
    const {
      user,
      song: {
        _id,
        name,
        artist,
        album,
        genre,
        copyright,
        isrc,
        iswc,
        releaseYear,
        durationSeconds,
        ranking,
        rating,
        ratingReason,
        tempo,
        source,
        tags = [],
        proDefinitions = [],
        proSelection = {},
      },
      queueToast,
    } = this.props;

    document.dispatchEvent(new Event('onSaveSongStart'));

    // Get tags to add/remove
    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const data = {
      _id,
      name,
      artist,
      album,
      genre,
      copyright,
      isrc,
      iswc,
      releaseYear: releaseYear.toString(),
      durationSeconds,
      ranking,
      rating,
      ratingReason,
      tempo,
      source,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
    };

    if (user.can('music.manage_pro')) {
      // Add PRO exclusion data
      data.proSelection = {};

      proDefinitions.forEach((definition) => {
        data.proSelection[definition.name] = proSelection[definition.name] || false;
      });
    }

    try {
      // Create a song request
      const songResponse = await API.Music.update(data);
      const success = get(songResponse, '[0].type') === 'MUSIC.UPDATED';

      if (success) {
        // Song was updated
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
          delay: 500,
        });

        document.dispatchEvent(new Event('onSaveSong'));
      } else {
        // Song was not updated
        const errMessage = get(songResponse, '[0].data.message', 'Unknown');

        queueToast({
          type: 'error',
          title: `Error: ${errMessage}`,
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      document.dispatchEvent(new Event('onSaveSongError'));
    }
  };

  playSong = async () => {
    const {
      setSong,
      clearSong,
      song,
    } = this.props;

    const songToPlay = {
      name: song.name,
      contentUrl: song.contentUrl,
    };
    await clearSong();
    setSong(songToPlay);
  };

  render() {
    const {
      user,
      song,
      onClose,
      onUpdate,
    } = this.props;

    const {
      confirmArchive,
      showConfirmSave,
    } = this.state;

    const disableSave = !song.name
      || !song.artist
      || !song.album
      || !song.genre
      || !song.isrc
      || !song.copyright
      || !song.releaseYear
      || !song.durationSeconds
      || !song.rating
      || !song.tempo;

    const disableInput = !user.can('music.modify');
    const showPro = user.can('music.manage_pro');
    const showArchive = user.can('music.delete');
    const isArchived = !song.active;

    const disablePlaylists = !user.can('playlist.view');

    return (
      <SidePanelContainer className="SongDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          {song._id ? (
            <div className="flex-horizontal">
              <VibeIcon
                className="left-icon"
                icon={viPlayCircleOutline}
                color={color.aquaForest}
                size={16}
                onClick={this.playSong}
              />

              <div className="title">
                <VibeTooltip title={song.name}>
                  <span>
                    {song.name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Song
            </div>
          )}
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            name={song.name}
            artist={song.artist}
            album={song.album}
            genre={song.genre}
            copyright={song.copyright}
            isrc={song.isrc}
            iswc={song.iswc}
            source={song.source}
            releaseYear={song.releaseYear}
            durationSeconds={song.durationSeconds}
            tempo={song.tempo}
            ranking={song.ranking}
            rating={song.rating}
            ratingReason={song.ratingReason}
            tags={song.tags}
            disableInput={disableInput}
            onUpdate={onUpdate}
          />

          <Assignments
            items={[{
              label: 'Playlists Assigned',
              count: song.playlistCount,
              disabled: disablePlaylists,
              onClick: !disablePlaylists
                ? this.onClickPlaylists
                : null,
            }]}
          />

          {showPro ? (
            <SongPRO
              selection={song.proSelection}
              confirmedByFirstName={song.proConfirmedByUserFname}
              confirmedByLastName={song.proConfirmedByUserLname}
              confirmedByAvatar={song.proConfirmedByUserImageUrl}
              confirmedDate={song.proConfirmedDate}
              onUpdate={onUpdate}
            />
          ) : null}
        </SidePanelContent>

        <SidePanelFooter className="panel-footer">
          <VibeButton
            className="btn-save"
            text="Save Changes"
            btnColor="purple"
            textColor="white"
            loadingEvent="onSaveSong"
            disabled={disableSave || disableInput}
            onClick={this.onClickSave}
          />

          {showArchive && !isArchived ? (
            <div className="toolbar-button">
              <VibeIcon
                icon={viArchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.fireBrick,
                }}
                tooltip="Archive"
                color={color.fireBrick}
                size={20}
                onClick={this.onClickArchive}
              />
            </div>
          ) : null}

          {showArchive && isArchived ? (
            <div className="toolbar-button">
              <VibeIcon
                icon={viUnarchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.aquaForest,
                }}
                tooltip="Unarchive"
                color={color.aquaForest}
                size={20}
                onClick={this.onClickUnarchive}
              />
            </div>
          ) : null}
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${song.name}?`}
          confirmProps={{
            text: 'Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />

        <VibeModal
          show={showConfirmSave}
          type="confirm"
          title="Song will be archived"
          text="When either PRO 'GMR' or 'SESAC' is selected, the song is automatically archived. Proceed?"
          confirmProps={{
            text: 'Save and Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmSave}
          onClose={this.onCancelSave}
        />
      </SidePanelContainer>
    );
  }
}

SongDetails.propTypes = {
  /** New Song */
  song: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onCloseExtra: PropTypes.func,
  onUpdate: PropTypes.func,
};

SongDetails.defaultProps = {
  song: {},
  onClose: () => {},
  onCloseExtra: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  setSong: MusicActions.setSong,
  clearSong: MusicActions.clearSong,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SongDetails));
