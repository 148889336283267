import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import {
  API,
  withRouter,
  viShortcut,
} from 'vibeguide';
import { Grid } from '@mui/material';
import OrderStatusCard from '../OrderStatusCard';

function ActiveOrderTables() {
  const [summaries, setSummaries] = useState({});

  /**
   * Get the Order Status Summary
   */
  const getSummaries = async () => {
    const serving = await API.Order.Reporting.Summaries.Live.serving();
    const ending = await API.Order.Reporting.Summaries.Live.ending();

    setSummaries({
      serving,
      ending,
    });
  };

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <Grid container spacing={2} className="ActiveOrderTables">
      <Grid item xs={6}>
        <OrderStatusCard
          title="Serving in the next 7 Days"
          linkUrl="../../live/serving"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1) rotate(90deg)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'startDate', 'endDate'],
            data: get(summaries, 'serving', []),
          }}
          tableStyle={{
            height: 317,
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>

      <Grid item xs={6}>
        <OrderStatusCard
          title="Ends in 30 Days or Less"
          linkUrl="../../live/ending"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'deliveredImpressions', 'endDate'],
            data: get(summaries, 'ending', []),
          }}
          tableStyle={{
            height: 317,
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(ActiveOrderTables);
