import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import {
  transparent,
} from '../../utils/ColorUtil';
import viCloseCircle from '../../icons/viCloseCircle';
import color from '../../sass/color.scss';
import './Chip.scss';

class Chip extends PureComponent {
  /**
   * When the remove icon is clicked, queue the chip to remove
   */
  onRemove = () => {
    const {
      chip,
      allowRemove,
      onRemove,
    } = this.props;

    if (!allowRemove) {
      console.error('You cannot remove this chip');
      return;
    }

    onRemove(chip);
  };

  render() {
    const {
      chip,
      style,
      allowRemove,
      textColor,
    } = this.props;

    let iconColor;
    let iconHtml;

    iconColor = style.color || color.manatee;

    if (allowRemove) {
      iconHtml = (
        <VibeIcon
          className="chip-icon"
          icon={viCloseCircle}
          color={iconColor}
          hoverColor={transparent(iconColor, 80)}
          size={12}
          onClick={this.onRemove}
        />
      );
    }

    if (!allowRemove) {
      // Do not show the icon
      iconHtml = null;
    }

    if (textColor) {
      iconColor = textColor;
    }

    return (
      <VibeTooltip
        title={chip}
        enterDelay={500}
      >
        <div
          className="Chip"
          style={style}
        >
          <div className="chip-name">
            {chip}
          </div>

          {iconHtml}
        </div>
      </VibeTooltip>
    );
  }
}

Chip.propTypes = {
  /** Chip data */
  chip: PropTypes.string.isRequired,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  textColor: PropTypes.string,
  /** Allow the chip to be removed */
  allowRemove: PropTypes.bool,
  /** When a chip is queued to remove */
  onRemove: PropTypes.func,
};

Chip.defaultProps = {
  style: {},
  textColor: '',
  allowRemove: false,
  onRemove: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Chip);
