import PropTypes from 'prop-types';
import {
  submenuPropsType,
  submenuPropsDefault,
} from './submenuPropsType';
import defaultCompanyImage from '../assets/default_company.png';

export const tablePropsType = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  style: PropTypes.object,
  toolbarStyle: PropTypes.object,
  pageSize: PropTypes.number,
  defaultSortBy: PropTypes.shape({
    label: PropTypes.string,
    attr: PropTypes.string,
    direction: PropTypes.oneOf([
      'asc',
      'desc',
    ]),
  }),
  collection: PropTypes.arrayOf(PropTypes.object),
  fetch: PropTypes.func,
  fetchProps: PropTypes.shape({
    // filters to send at the root level of the fetch method (not inside a "filters" object)
    filters: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    // require a filter before requesting API data
    requireFilter: PropTypes.bool,
  }),
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  paginator: PropTypes.bool,
  paginatorProps: PropTypes.shape({
    label: PropTypes.string,
    urlPaging: PropTypes.bool,
    urlFilters: PropTypes.bool,
  }),
  toggle: PropTypes.bool,
  toggleProps: PropTypes.shape({
    label: PropTypes.string,
    tooltip: PropTypes.bool,
    onToggle: PropTypes.func,
  }),
  bulk: PropTypes.bool,
  bulkProps: PropTypes.shape({
    edit: PropTypes.bool,
    editType: PropTypes.oneOf([
      'table',
      'sidebar',
    ]),
    add: PropTypes.bool,
    block: PropTypes.bool,
    remove: PropTypes.bool,
    archive: PropTypes.bool,
    onToggle: PropTypes.func,
  }),
  submenu: PropTypes.bool,
  submenuProps: submenuPropsType,
  toolbarProps: PropTypes.shape({
    customButtons: PropTypes.arrayOf(PropTypes.element),
    customHtmlLeft: PropTypes.element,
    customHtmlRight: PropTypes.element,
    collapseTable: PropTypes.bool,
  }),
  draggable: PropTypes.bool,
  droppable: PropTypes.bool,
  dragProps: PropTypes.shape({
    dragMultiple: PropTypes.bool,
    duplicateOnDrop: PropTypes.bool,
    contentDrawer: PropTypes.bool,
  }),
  csv: PropTypes.bool,
  csvProps: PropTypes.shape({
    onExport: PropTypes.func,
  }),
  /** Empty Table Properties (what to show when no data exists) */
  emptyProps: PropTypes.shape({
    image: PropTypes.string,
    imageStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    buttonHtml: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }),
  cardProps: PropTypes.shape({
    // require a filter before requesting API data
    requireFilter: PropTypes.bool,
    // filters to send when using card view
    filters: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Edit Mode for all visible cells on the table */
  editMode: PropTypes.bool,
  rowLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  /** Array of Object IDs that contain an error and need to be highlighted */
  errorIds: PropTypes.arrayOf(PropTypes.string),
  menuItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      userCan: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      // disable the menu item
      disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
      ]),
      // Custom HTML for the item
      customHtml: PropTypes.element,
    })),
    PropTypes.func,
  ]),
  /** Assigned or unassigned (used for adding/removing) */
  assigned: PropTypes.bool,
  available: PropTypes.bool,
  /** Selected rows to highlight for unassigned content */
  highlight: PropTypes.arrayOf(PropTypes.object),
  /** Allow scrolling of the header cell row */
  allowHeaderRowScroll: PropTypes.bool,
  onClickRow: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  /** When the row data is changed */
  onChange: PropTypes.func,
  /** When the selected rows are changed */
  onChangeSelected: PropTypes.func,
  /** When a menu item is selected */
  onSelectMenuItem: PropTypes.func,
  /** When the table has finished fetching data */
  onFetchComplete: PropTypes.func,
  /** When a submenu item is selected for a table */
  onChangeActiveSubmenuItem: PropTypes.func,
  /** When the data is saved from edit mode changes */
  onSaveEditModeChanges: PropTypes.func,
};

export const tablePropsDefault = {
  className: '',
  rootStyle: {},
  style: {},
  toolbarStyle: {},
  pageSize: 50,
  defaultSortBy: {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  collection: [],
  fetch: null,
  fetchProps: {
    filters: {},
    requireFilter: false,
  },
  filters: {},
  paginator: false,
  paginatorProps: {
    label: 'Items',
    urlPaging: false,
    urlFilters: false,
  },
  toggle: false,
  toggleProps: {
    label: 'Show Archived Items',
    tooltip: true,
    onToggle: () => {},
  },
  bulk: false,
  bulkProps: {
    edit: false,
    editType: 'table',
    add: false,
    block: false,
    remove: false,
    archive: false,
    onToggle: () => {},
  },
  submenu: false,
  submenuProps: submenuPropsDefault,
  toolbarProps: {
    customButtons: [],
    customHtmlLeft: null,
    customHtmlRight: null,
    collapseTable: false,
  },
  draggable: false,
  droppable: false,
  dragProps: {
    dragMultiple: false,
    duplicateOnDrop: false,
    contentDrawer: false,
  },
  csv: false,
  csvProps: {
    onExport: () => {},
  },
  emptyProps: {
    image: defaultCompanyImage,
    imageStyle: {},
    title: 'No Results',
    description: 'Nothing was found, try clearing your filters',
    buttonHtml: null,
  },
  cardProps: {
    requireFilter: false,
    filters: {},
  },
  editMode: false,
  rowLink: null,
  errorIds: [],
  menuItems: [],
  assigned: false,
  available: false,
  highlight: [],
  allowHeaderRowScroll: false,
  onClickRow: null,
  onAdd: () => {},
  onRemove: () => {},
  onChange: () => {},
  onChangeSelected: () => {},
  onSelectMenuItem: () => {},
  onFetchComplete: () => {},
  onChangeActiveSubmenuItem: () => {},
  onSaveEditModeChanges: () => {},
};

export default {
  tablePropsType,
  tablePropsDefault,
};
