import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  MessageBlocksAssigned,
} from 'vibeguide';

class MessageBlocks extends PureComponent {
  constructor(props) {
    super(props);

    const {
      messageBlocks,
    } = props;

    this.state = {
      messageBlocks,
    };
  }

  /**
   * When the message blocks are assigned/unassigned
   */
  onUpdateMessageBlocks = ({
    messageBlocks,
  }) => {
    const {
      onUpdate,
    } = this.props;

    this.setState({
      messageBlocks,
    });

    onUpdate({
      messageBlocks,
      messageLists: messageBlocks,
    });
  };

  render() {
    const {
      companyId,
      locations,
      allowChanges,
    } = this.props;

    const {
      messageBlocks,
    } = this.state;

    return (
      <MessageBlocksAssigned
        companyId={companyId}
        locations={locations}
        messageBlocks={messageBlocks}
        disabled={!allowChanges}
        onUpdate={this.onUpdateMessageBlocks}
      />
    );
  }
}

MessageBlocks.propTypes = {
  companyId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  onUpdate: PropTypes.func,
};

MessageBlocks.defaultProps = {
  companyId: '',
  locations: [],
  messageBlocks: [],
  allowChanges: false,
  onUpdate: () => { },
};

export default MessageBlocks;
