// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridItemColumn {
  position: relative;
  width: 33.333333%;
}
.GridItemColumn:first-child {
  margin-left: 48px;
}
.GridItemColumn > .column-content {
  position: relative;
  height: 100%;
}
.GridItemColumn > .drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Grid/Columns/GridItemColumn.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,kBAAA;EACA,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AADJ","sourcesContent":[".GridItemColumn {\n  position: relative;\n  width: 33.333333%;\n\n  &:first-child {\n    margin-left: 48px;\n  }\n\n  > .column-content {\n    position: relative;\n    height: 100%;\n  }\n\n  > .drag-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 11;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
