import React from 'react';

const viTag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12.628 2.543l7.547 1.282 1.282 7.547c.142.57-.072 1.21-.57 1.709l-7.832 7.832a2.02 2.02 0 0 1-2.848 0l-7.12-7.12a2.02 2.02 0 0 1 0-2.848l7.832-7.832c.499-.498 1.14-.712 1.709-.57zm4.7 6.978a2.014 2.014 0 1 0-2.849-2.848 2.014 2.014 0 0 0 2.848 2.848z" />
    </g>
  </svg>
);

export default viTag;
