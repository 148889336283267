const moment = require('moment');

const spotTypeNames = [
  'adult-beverage',
  'endemic',
  'non-endemic',
  'self-promotion',
  'general',
];

const calculateAdPlaysForRotationDayPart = (standardDurations) => (totalMinutes, frequency, groupSize) => {
  const musicGroupDuration = standardDurations.music * frequency;
  const adGroupDuration = standardDurations.message * groupSize;
  const contentGroupDuration = musicGroupDuration + adGroupDuration;

  const instances = Math.floor(totalMinutes / contentGroupDuration) * groupSize;
  return instances;
};

const calculateAdPlaysForInterruptingDayPart = (totalMinutes, frequency, groupSize) => {
  return Math.floor(totalMinutes / frequency) * groupSize;
};

const durationToMinutes = (duration, $default) => {
  return moment.duration(duration || $default).asMinutes();
};

const getAllowedHourlySpotsFromDistribution = (spotPool) => {
  return spotTypeNames.reduce((total, k) => total + spotPool.distribution[k], 0);
};

const calculateHourlySpots = (calculators) => (spotPool) => {
  const calculator = calculators[spotPool.integrationType]
    || calculators.notSupported;

  const allowedHourlySpots = getAllowedHourlySpotsFromDistribution(spotPool);
  const maxHourlySpots = calculator(60, spotPool.frequency, spotPool.groupSize);

  if (allowedHourlySpots > maxHourlySpots) {
    console.error('Allocation exceeds possible spots');
    // throw new Error('Allocation exceeds possible spots');
  }

  return {
    maxHourlySpots,
    allowedHourlySpots,
  };
};

const createAudioSpotCalculator = ((standardMusicDuration, standardMessageDuration) => {
  const musicDurationMinutes = durationToMinutes(standardMusicDuration, '00:03:30');
  const messageDurationMinutes = durationToMinutes(standardMessageDuration, '00:00:30');

  return calculateHourlySpots({
    rotate:
      calculateAdPlaysForRotationDayPart({
        music: musicDurationMinutes,
        message: messageDurationMinutes,
      }),
    interrupt:
      calculateAdPlaysForInterruptingDayPart,
    notSupported:
      dayPart => {
        throw new Error(`Cannot compute impressions. ${dayPart.integrationType} is not recognized.`);
      },
  });
});

const calculateHourlyVisualSpots = (spotPool) => {
  const allowedHourlySpots = getAllowedHourlySpotsFromDistribution(spotPool);

  const {
    maxHourlySpots,
  } = spotPool;

  if (allowedHourlySpots > maxHourlySpots) {
    throw new Error(`Allocation (${allowedHourlySpots}) exceeds possible spots (${maxHourlySpots}):
      ${JSON.stringify(spotPool.distribution)}`);
  }

  return {
    maxHourlySpots,
    allowedHourlySpots,
  };
};

const calculateAudioSpots = createAudioSpotCalculator('00:03:30', '00:00:30');
const calculateVisualSpots = calculateHourlyVisualSpots;

const calculateHourlySpotsForSpotPool = (spotPool, mediaFormat) => {
  if (mediaFormat === 'visual') {
    return {
      ...spotPool,
      ...calculateVisualSpots(spotPool),
    };
  }

  return {
    ...spotPool,
    ...calculateAudioSpots(spotPool),
  };
};

module.exports = {
  calculateHourlySpotsForSpotPool,
};
