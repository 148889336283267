import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableSongs,
  withRouter,
} from 'vibeguide';
import SongSidebar from '../../../../Music/Songs/Sidebar/SongSidebar';
import './BlockedSongs.scss';

function BlockedSongs({
  user,
  locationId,
  history,
  setPanel,
}) {
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      songId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, songId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <SongSidebar
          songId={songId}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.songId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.songId);
    }
  }, [qs.type, qs.songId]);

  return (
    <div className="BlockedSongs">
      <TableSongs
        columns={[
          '.',
          'Song Title',
          'Artist',
          'Album',
          'Genre',
          'ISRC',
          'ISWC',
          'Record Label',
          'Source',
          'Release Year',
          'Duration',
          'Tempo',
          'Ranking',
          'Rating',
          'Reason',
          'Playlists',
          'PRO',
          'Modified',
          'Tags',
          '...',
        ]}
        fetch={API.Location.listBlocked}
        fetchProps={{
          filters: {
            _id: locationId,
          },
        }}
        defaultSortBy={{
          label: 'Song Title',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Songs',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Songs',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: true,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        editMode={user.can('music.modify')}
        rowLink={{
          songId: '_id',
        }}
        menuItems={[
          { name: 'Unblock', userCan: 'music.block' },
          { name: 'Archive', userCan: 'music.delete' },
        ]}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    locationId: state.locations.currentLocation._id,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockedSongs));
