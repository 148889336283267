import { each, isUndefined } from 'lodash';
import Flight from './Flight';

class Order extends Flight {
  constructor(data = {}) {
    super(data);

    each(data, (val, key) => {
      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to Order Model`);
      }
    });
  }
}

export default Order;
