import { handleActions } from 'redux-actions';

const initialState = {
  toasts: [],
};

export default handleActions(
  {
    ADD_TOAST: (state, action) => {
      const toast = action.payload;

      return {
        ...state,
        toasts: [...state.toasts, toast],
      };
    },

    UPDATE_TOAST: (state, action) => {
      const toast = action.payload;

      return {
        ...state,
        toasts: state.toasts.map((toastItem) => {
          if (toastItem.id === toast.id) {
            return {
              ...toastItem,
              ...toast,
            };
          }

          return toast;
        }),
      };
    },

    REMOVE_TOAST: (state, action) => {
      const id = action.payload;

      return {
        ...state,
        toasts: state.toasts.filter(toast => toast.id !== id),
      };
    },
  },
  // Initial State
  initialState,
);
