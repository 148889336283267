import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  map,
} from 'lodash';
import {
  API,
} from 'vibeguide';
import HistoryScriptItem from './HistoryScriptItem';
import './HistoryScript.scss';

class HistoryScript extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      history: {},
    };
  }

  componentDidMount() {
    this.getScriptHistory();
  }

  componentDidUpdate(prevProps) {
    const {
      message: {
        _id,
      },
    } = this.props;

    const {
      message: {
        _id: prevId,
      },
    } = prevProps;

    if (_id !== prevId) {
      this.getScriptHistory();
    }
  }

  /**
   * Fetch script history
   */
  getScriptHistory = async () => {
    const {
      message: {
        _id,
        requestId,
      },
    } = this.props;

    const history = {};
    const scriptHistory = await API.MessageRequest.scriptHistory(requestId || _id);

    // Group the history by day
    scriptHistory.forEach((item) => {
      const itemDate = new moment(item.modifiedDate);
      const day = itemDate.format('dddd, MMMM D, YYYY');

      if (!history[day]) {
        history[day] = [];
      }

      history[day].push(item);
    });

    this.setState({
      history,
    });
  };

  render() {
    const {
      history,
    } = this.state;

    return (
      <div className="HistoryScript">
        {map(history, (items, day) => {
          return (
            <div
              key={`history-day-${day}`}
              className="history-day"
            >
              <div className="title">
                {day}
              </div>

              <div className="items">
                {items.map((item, index) => {
                  const last = (index + 1) === items.length;

                  return (
                    <HistoryScriptItem
                      key={`history-item-${item._id}`}
                      script={item.script}
                      date={item.modifiedDate}
                      username={`${item.userFname} ${item.userLname}`.trim()}
                      imageUrl={item.userImageUrl}
                      last={last}
                      onToggleExpand={this.onToggleExpand}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className="end-of-history">
          End of History
        </div>
      </div>
    );
  }
}

HistoryScript.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export default HistoryScript;
