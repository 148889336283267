import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  TableSongs,
  TableLocations,
  VibeButton,
} from 'vibeguide';
import './SongBlocker.scss';

class SongBlocker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocations: [],
    };
  }

  onBlockSongs = () => {
    const {
      songs,
      onBlockSongs,
    } = this.props;

    const {
      selectedLocations: locations,
    } = this.state;

    onBlockSongs({
      songs,
      locations,
    });
  };

  onChangeSelected = (selectedRows) => {
    this.setState({
      selectedLocations: selectedRows,
    });
  };

  render() {
    const {
      user,
      songs,
    } = this.props;

    const {
      selectedLocations,
    } = this.state;

    return (
      <div className="SongBlocker">
        <div className="song-blocker-songs">
          <TableSongs
            columns={[
              'Song Title',
              'Artist',
              'Album',
              'Genre',
              'ISRC',
              'ISWC',
              'Record Label',
              'Source',
              'Release Year',
              'Duration',
              'Tempo',
              'Ranking',
              'Rating',
              'Reason',
              'Playlists',
              'Modified',
              'Tags',
              '...',
            ]}
            collection={songs}
            defaultSortBy={{
              label: 'Song Title',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Songs',
              urlPaging: false,
              urlFilters: false,
            }}
            toolbarProps={{
              customButtons: [
                (
                  <VibeButton
                    key="confirm-locations"
                    text="Confirm Locations"
                    btnColor="green"
                    textColor="white"
                    disabled={selectedLocations.length <= 0}
                    onClick={this.onBlockSongs}
                  />
                ),
              ],
            }}
            menuItems={[
              { name: 'Archive', userCan: 'music.delete' },
            ]}
          />
        </div>

        <div className="song-blocker-content">
          <TableLocations
            columns={[
              '.',
              'Name',
              'Company',
              'Status',
              'Media Format',
              'Address 1',
              'Address 2',
              'City',
              'State/Province',
              'Postal Code',
              'DMA',
              'Ad Network',
              'Ad Programs',
              'Tags',
              '...',
            ]}
            fetch={API.User.getLocations}
            fetchProps={{
              filters: {
                _id: user._id,
              },
            }}
            defaultSortBy={{
              label: 'Name',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Locations',
              urlPaging: false,
              urlFilters: false,
            }}
            toggle
            toggleProps={{
              label: 'Show Archived Locations',
              tooltip: true,
            }}
            bulk
            menuItems={[
              { name: 'Archive', userCan: 'location.delete' },
            ]}
            onChangeSelected={this.onChangeSelected}
          />
        </div>
      </div>
    );
  }
}

SongBlocker.propTypes = {
  songs: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    album: PropTypes.string,
    artist: PropTypes.string,
    artworkUrl: PropTypes.string,
    blocked: PropTypes.number,
    contentUrl: PropTypes.string,
    durationSeconds: PropTypes.number,
    genre: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    playlists: PropTypes.number,
    releaseYear: PropTypes.string,
  })).isRequired,
  onBlockSongs: PropTypes.func,
};

SongBlocker.defaultProps = {
  onBlockSongs: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(SongBlocker);
