import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  images as mimeTypeImages,
} from '../../helpers/MimeType';
import Field from '../Field/Field';
import './UploadImage.scss';

class UploadImage extends PureComponent {
  onDrop = (fileObj) => {
    const {
      onDrop,
    } = this.props;

    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    onDrop(file.file);
  };

  render() {
    const {
      image,
      style,
      allowUpload,
    } = this.props;

    return (
      <div
        className="UploadImage"
        style={style}
      >
        <Field
          type="dropzone"
          dropzoneProps={{
            type: 'image',
            image,
            mimeTypes: mimeTypeImages,
            allowUpload,
          }}
          onDrop={this.onDrop}
        />
      </div>
    );
  }
}

UploadImage.propTypes = {
  /** Image to show */
  image: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** If the user can upload a new image */
  allowUpload: PropTypes.bool,
  /** When an image is dropped */
  onDrop: PropTypes.func,
};

UploadImage.defaultProps = {
  image: null,
  style: {},
  allowUpload: false,
  onDrop: () => {},
};

export default UploadImage;
