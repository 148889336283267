import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  VibeTooltip,
} from 'vibeguide';
import './SnapshotDay.scss';

class SnapshotDay extends PureComponent {
  /**
   * When the day is clicked
   */
  onClick = () => {
    const {
      day,
      disabled,
      onClickDay,
    } = this.props;

    if (!disabled) {
      onClickDay(day);
    }
  };

  /**
   * Get start/stop percentages for the item
   */
  getPercentages(item) {
    const spltStart = item.startTime.split(':');
    // interrupts have no stop time
    const spltStop = (item.stopTime || item.startTime).split(':');
    const startHour = parseInt(spltStart[0], 10);
    const startMinute = parseInt(spltStart[1], 10);
    const stopHour = parseInt(spltStop[0], 10);
    const stopMinute = parseInt(spltStop[1], 10);

    // each hour represents this percentage
    const oneHourPercentage = 100 / 24;
    // each minute represents this percentage
    const oneMinutePercentage = 100 / 1440;

    // get the percentage from the top before start time
    const startHourPercentage = startHour * oneHourPercentage;
    const startMinutePercentage = startMinute * oneMinutePercentage;
    const startPercentage = startHourPercentage + startMinutePercentage;

    // get the percentage from the bottom after stop time
    const stopHourPercentage = stopHour * oneHourPercentage;
    const stopMinutePercentage = stopMinute * oneMinutePercentage;
    const stopPercentage = 100 - (stopHourPercentage + stopMinutePercentage);

    return {
      startPercentage,
      stopPercentage,
    };
  }

  render() {
    const {
      className,
      day,
      playlists,
      stations,
      messageBlocks,
      interruptions,
      disabled,
    } = this.props;

    // get the items for this specific day
    const dayPlaylists = playlists.filter(playlist => playlist.day === day);
    const dayStations = stations.filter(station => station.day === day);
    const dayBlocks = messageBlocks.filter(block => block.day === day);
    const dayInterrupts = interruptions.filter(interrupt => interrupt.day === day);

    return (
      <div
        className={classNames('SnapshotDay', className)}
        onClick={this.onClick}
      >
        {!disabled ? (
          <VibeTooltip
            className="SnapshotDayTooltip"
            title={(
              <div>
                <div className="day-row">
                  <div className="item-label">
                    Playlists
                  </div>

                  <div>
                    {dayPlaylists.length}
                  </div>
                </div>

                <div className="day-row">
                  <div className="item-label">
                    Stations
                  </div>

                  <div>
                    {dayStations.length}
                  </div>
                </div>

                <div className="day-row">
                  <div className="item-label">
                    Message Blocks
                  </div>

                  <div>
                    {dayBlocks.length}
                  </div>
                </div>

                <div className="day-row">
                  <div className="item-label">
                    Interrupts
                  </div>

                  <div>
                    {dayInterrupts.length}
                  </div>
                </div>
              </div>
            )}
            placement="top"
          >
            <div className="hover-day" />
          </VibeTooltip>
        ) : null}

        {dayPlaylists.map((item, index) => {
          const active = get(item, 'playlist.active', false);
          const {
            startPercentage,
            stopPercentage,
          } = this.getPercentages(item);

          return (
            <div
              key={`playlist-${item._id}-${index}`}
              className="playlist"
              style={{
                top: `${startPercentage}%`,
                bottom: `${stopPercentage}%`,
                height: `${100 - startPercentage - stopPercentage}%`,
                background:
                  active
                    ? '#F1F1F8'
                    : '#FAE9ED',
              }}
            />
          );
        })}

        {dayStations.map((item, index) => {
          const active = get(item, 'mix.active', false);
          const {
            startPercentage,
            stopPercentage,
          } = this.getPercentages(item);

          return (
            <div
              key={`station-${item._id}-${index}`}
              className="station"
              style={{
                top: `${startPercentage}%`,
                bottom: `${stopPercentage}%`,
                height: `${100 - startPercentage - stopPercentage}%`,
                background:
                  active
                    ? '#F1F1F8'
                    : '#FAE9ED',
              }}
            />
          );
        })}

        {dayBlocks.map((item, index) => {
          const active = get(item, 'messageBlock.active', false);
          const {
            startPercentage,
            stopPercentage,
          } = this.getPercentages(item);

          return (
            <div
              key={`block-${item._id}-${index}`}
              className="block"
              style={{
                top: `${startPercentage}%`,
                bottom: `${stopPercentage}%`,
                height: `${100 - startPercentage - stopPercentage}%`,
                background:
                  active
                    ? '#E1E1F0'
                    : '#F5D4DB',
              }}
            />
          );
        })}

        {dayInterrupts.map((item, index) => {
          const {
            startPercentage,
          } = this.getPercentages(item);

          return (
            <div
              key={`interrupt-${item._id}-${index}`}
              className="interrupt"
              style={{
                top: `${startPercentage}%`,
                height: 2,
              }}
            />
          );
        })}
      </div>
    );
  }
}

SnapshotDay.propTypes = {
  className: PropTypes.string,
  day: PropTypes.string.isRequired,
  playlists: PropTypes.arrayOf(PropTypes.object),
  stations: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interruptions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onClickDay: PropTypes.func,
};

SnapshotDay.defaultProps = {
  className: '',
  playlists: [],
  stations: [],
  messageBlocks: [],
  interruptions: [],
  disabled: false,
  onClickDay: () => {},
};

export default SnapshotDay;
