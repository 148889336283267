import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  get,
  capitalize,
} from 'lodash';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableAlerts,
  withRouter,
} from 'vibeguide';
import SupportSidebar from '../SupportSidebar/SupportSidebar';
import DashboardHeader from '../DashboardHeader';
import './AlertList.scss';

class AlertList extends PureComponent {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isView = qs.locationId;

    if (isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.locationId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.locationId) {
        this.sidebar(qs.type, qs.locationId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
 * When the sidebar is closed
 */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      locationId: null,
      type: null,
    });

    history(url);
    document.dispatchEvent(new Event('onUpdateTableAlerts'));
  };

  onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `alerts-${timestamp}.csv`);
  };

  /**
   * When the data is being refreshed
   */
  onRefresh = () => {
    // tell listening components to update the table
    document.dispatchEvent(new Event('onUpdateTableAlerts'));
  };

  /**
   * Get the type of alert
   */
  getType = () => {
    const qs = NavigationHelper.getParams();
    return capitalize(get(qs, 'category', 'open'));
  };

  sidebar = (type, locationId = null) => {
    const {
      setPanel,
      user,
    } = this.props;

    const canManageSettings = user.can('alert.manage_settings');

    setPanel({
      show: true,
      backdrop: false,
      width: 400,
      children: (
        <SupportSidebar
          locationId={locationId}
          type={type}
          canManageSettings={canManageSettings}
          onClose={this.onCloseSidebar}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    // Get type of alerts
    const type = this.getType();

    return (
      <div className="AlertList">
        <DashboardHeader
          className="alert-header"
          title={`${type} Alerts`}
          goBackLink="/dashboard/alerts"
          refreshEvery={60}
          // pauseRefresh={pauseRefresh}
          onRefresh={this.onRefresh}
        />

        <TableAlerts
          columns={[
            'Alert Category',
            'Alert Type',
            'Company',
            'Location',
            'Time Generated',
            'Flagged',
            '...',
          ]}
          fetch={API.Location.Alerts.list}
          defaultSortBy={{
            label: 'Time Generated',
            attr: 'createdDate',
            direction: 'desc',
          }}
          paginator
          paginatorProps={{
            label: 'Alerts',
            urlPaging: true,
            urlFilters: true,
          }}
          csv={user.sysAdmin}
          csvProps={{
            onExport: this.onExport,
          }}
          rowLink="/location/{locationId}/telemetry"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlertList));
