import React from 'react';

const viCalendarEventOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 34"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path stroke="currentColor" d="M16.9968255,0.500498478 C12.4436508,0.5004884 8.31865081,2.34731381 5.33273811,5.33322651 C2.34682541,8.31913921 0.5,12.4441392 0.5,17.0004884 C0.5,21.5980681 2.37983188,25.7564603 5.50539408,28.7476879 C8.57047336,31.6810321 12.8356185,33.493439 17.7209949,33.4857873 C20.8365899,33.3767735 23.3515506,32.6800173 25.2742799,31.4185441 C31.0253767,27.5844958 36.3906255,22.890727 41.3722847,16.6667159 C36.3906255,11.1102498 31.0253767,6.41648099 25.2773501,2.58446325 C23.2095121,1.18400667 20.4519512,0.478561172 16.9968255,0.500498478 Z" />
    </g>
  </svg>
);

export default viCalendarEventOutline;
