import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ColorUtil,
  VibeTooltip,
  VibeIcon,
  viAdd,
  color,
} from 'vibeguide';
import './SpotPoolGhostItem.scss';

let animateTimer;

function SpotPoolGhostItem({
  startTime,
  stopTime,
  onClick,
}) {
  const [hover, setHover] = useState(false);
  // queue the item for animation
  const [queued, setQueued] = useState(false);

  /**
   * When the mouse hovers over the item
   */
  const onMouseMoveItem = () => {
    if (hover || queued) {
      return;
    }

    setQueued(true);

    // only animate after hovering for a little bit
    animateTimer = setTimeout(() => {
      setHover(true);
      setQueued(false);
    }, 500);
  };

  /**
   * When the mouse stops hovering over the item
   */
  const onMouseLeaveItem = () => {
    clearTimeout(animateTimer);
    setHover(false);
    setQueued(false);
  };

  /**
   * When the item is clicked
   */
  const onClickItem = () => {
    onClick({
      startTime,
      stopTime,
    });
  };

  return (
    <div
      className="SpotPoolGhostItem"
      onMouseMove={onMouseMoveItem}
      onMouseLeave={onMouseLeaveItem}
      onClick={onClickItem}
    >
      <VibeTooltip
        title="Create Spot Pool Here"
        placement="left"
        enterDelay={800}
        leaveDelay={200}
      >
        <div className={classNames('ghost-item-container', { hover })}>
          <div className={classNames('ghost-item', { hover })}>
            <div
              className="animate"
              style={{
                border: `1px solid ${ColorUtil.transparent(color.violetVibe, 15)}`,
                borderRadius: 4,
                boxSizing: 'border-box',
                backgroundColor: ColorUtil.transparent(color.violetVibe, 10),
              }}
            >
              <VibeIcon
                icon={viAdd}
                color={color.violetVibe}
                size={16}
              />
            </div>
          </div>
        </div>
      </VibeTooltip>
    </div>
  );
}

SpotPoolGhostItem.propTypes = {
  startTime: PropTypes.string.isRequired,
  stopTime: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SpotPoolGhostItem.defaultProps = {
  onClick: () => {},
};

export default SpotPoolGhostItem;
