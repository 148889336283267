import React from 'react';

const viChevronUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M12 8l-6 6 1.4 1.4 4.6-4.6 4.6 4.6L18 14z" />
    </g>
  </svg>
);

export default viChevronUp;
