import React from 'react';

const viVibeCube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M.004 27.646V2.558l11.651 30.077zM8.598 4.448L.004 2.558l8.595 1.89 8.9 1.958v21.017z" opacity=".5" />
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M34.996 2.559v25.087l-11.653 4.99zM34.996 2.559l-8.598 1.89L17.5 27.423V6.406L8.598 4.448.004 2.558 17.452.004z" />
    </g>
  </svg>
);

export default viVibeCube;
