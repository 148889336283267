import { createAction } from 'redux-actions';

export const setActiveMessage = createAction('SET_ACTIVE_MESSAGE');
export const updateActiveMessage = createAction('UPDATE_ACTIVE_MESSAGE');
export const clearActiveMessage = createAction('CLEAR_ACTIVE_MESSAGE');
export const addLocation = createAction('ADD_MESSAGE_LOCATION');
export const removeLocation = createAction('REMOVE_MESSAGE_LOCATION');
export const setAllLocations = createAction('SET_MESSAGE_ALL_LOCATIONS');
export const resetNewMessage = createAction('RESET_NEW_MESSAGE');

/**
 * Get file data to use for preview
 */
function getFileData(file, callback) {
  if (!file) {
    // No file to fetch data
    return;
  }

  // Creating the file reader using the File Reader API
  const reader = new FileReader();

  // On load function for the when the reader loads and is ready to set the state
  reader.onload = (e) => {
    const {
      target: {
        result,
      },
    } = e;

    callback(result);
  };

  // Turning the imagelocation into a datauri
  reader.readAsDataURL(file);
}

/**
 * Set a file to upload for a message request
 */
function setUploadMessage(file) {
  return async (dispatch) => {
    getFileData(file, (data) => {
      dispatch(updateActiveMessage({
        uploadFile: {
          file,
          data,
        },
      }));
    });
  };
}

export default {
  getFileData,
  setActiveMessage,
  setUploadMessage,
  updateActiveMessage,
  clearActiveMessage,
  addLocation,
  removeLocation,
  setAllLocations,
  resetNewMessage,
};
