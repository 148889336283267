import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viClose from '../../../icons/viClose';
import color from '../../../sass/color.scss';

function FieldGroup({
  className,
  style,
  id,
  allowRemove,
  alignColumns,
  removeTooltip,
  children,
  onRemove,
}) {
  return (
    <div
      className={classNames('FieldGroup', className)}
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        gap: 24,
        ...style,
      }}
    >
      {children}

      {allowRemove && (
        <VibeIcon
          style={{
            marginBottom: 4,
          }}
          icon={viClose}
          color={color.fireBrick}
          hoverColor={color.fireBrick75}
          size={24}
          tooltip={removeTooltip ?? 'Remove'}
          tooltipProps={{
            placement: 'left',
          }}
          onClick={() => onRemove(id)}
        />
      )}

      {/** align columns if some rows allow remove and some don't */}
      {!allowRemove && alignColumns && (
        <div
          style={{
            width: 24,
          }}
        />
      )}
    </div>
  );
}

FieldGroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
  allowRemove: PropTypes.bool,
  /** Align columns in case some have a remove icon and some do not */
  alignColumns: PropTypes.bool,
  removeTooltip: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onRemove: PropTypes.func,
};

FieldGroup.defaultProps = {
  className: '',
  style: {},
  allowRemove: false,
  alignColumns: false,
  removeTooltip: null,
  onRemove: () => {},
};

export default FieldGroup;
