import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LocationsAssigned,
} from 'vibeguide';

class MessageLocations extends PureComponent {
  constructor(props) {
    super(props);

    const {
      locations,
      locationsData,
    } = props;

    this.state = {
      locations,
      locationsData,
    };
  }

  /**
   * When the locations are assigned from an object
   */
  onUpdateLocations = ({
    locations,
    locationsData,
  }) => {
    const {
      onUpdate,
    } = this.props;

    this.setState({
      locations,
      locationsData,
    });

    onUpdate({
      locations,
      locationsData,
    });
  };

  render() {
    const {
      companyId,
      allowChanges,
    } = this.props;

    const {
      locations,
      locationsData,
    } = this.state;

    return (
      <LocationsAssigned
        companyId={companyId}
        locations={locations}
        locationsData={locationsData}
        disabled={!allowChanges}
        onUpdate={this.onUpdateLocations}
        allowACAF
        allowCompanies
      />
    );
  }
}

MessageLocations.propTypes = {
  companyId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
  }),
  allowChanges: PropTypes.bool,
  onUpdate: PropTypes.func,
};

MessageLocations.defaultProps = {
  companyId: '',
  locations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  allowChanges: false,
  onUpdate: () => {},
};

export default MessageLocations;
