// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpotValueItem {
  cursor: pointer;
}
.SpotValueItem > .value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Grid/Items/SpotValueItem.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".SpotValueItem {\n  cursor: pointer;\n\n  > .value {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    font-size: 12px;\n    text-align: center;\n    user-select: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
