import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clone,
} from 'lodash';
import {
  MixPlaylistSelection,
  NewMixActions,
  LocationHelper,
  API,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';

class MixPlaylists extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      assignedPlaylists: [],
    };
  }

  componentDidMount() {
    this.getPlaylists();
  }

  /**
   * Get existing message playlists
   */
  getPlaylists = async () => {
    const {
      playlists,
    } = this.props;

    const {
      assignedPlaylists,
    } = this.state;

    if (assignedPlaylists.length > 0) {
      // Playlists already fetched
      return;
    }

    const existingPlaylists = playlists || [];

    this.setState((state) => {
      let assignedPlaylists = clone(state.assignedPlaylists);

      assignedPlaylists = existingPlaylists;

      return {
        assignedPlaylists,
      };
    });
  };

  /**
   * When a Playlist is added
   */
  addPlaylist = (playlist) => {
    const {
      onUpdate,
      addMixPlaylist,
    } = this.props;

    addMixPlaylist(playlist);

    this.setState((state) => {
      let assignedPlaylists = clone(state.assignedPlaylists);

      assignedPlaylists = [...assignedPlaylists, playlist];

      // only update if a playlist is added
      onUpdate({
        playlists: assignedPlaylists,
        playlistIds: assignedPlaylists.map(playlist => playlist._id),
      });

      return {
        assignedPlaylists,
      };
    });
  };

  /**
   * When a Playlist is removed
   */
  removePlaylist = (playlistId) => {
    const {
      onUpdate,
      removeMixPlaylist,
    } = this.props;

    removeMixPlaylist(playlistId);

    this.setState((state) => {
      let assignedPlaylists = clone(state.assignedPlaylists);

      assignedPlaylists = assignedPlaylists.filter(playlistItem => playlistItem._id !== playlistId);

      onUpdate({
        playlists: assignedPlaylists,
        playlistIds: assignedPlaylists.map(playlist => playlist._id),
      });

      return {
        assignedPlaylists,
      };
    });
  };

  render() {
    const {
      locations,
      allowChanges,
      onClose,
      onUpdate,
    } = this.props;

    const {
      assignedPlaylists,
    } = this.state;

    const group = LocationHelper.group(locations);

    const filters = {
      active: true,
      locations: {
        matchType: 'contains',
        locations: group,
      },
    };

    return (
      <MixPlaylistSelection
        fetch={API.Playlist.list}
        filters={filters}
        selected={assignedPlaylists}
        customToolbarAssigned={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
        onSelect={this.addPlaylist}
        onDeselect={this.removePlaylist}
        onUpdate={onUpdate}
        allowChanges={allowChanges}
        allowPaginator
      />
    );
  }
}

MixPlaylists.propTypes = {
  playlists: PropTypes.arrayOf(PropTypes.shape),
  allowChanges: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

MixPlaylists.defaultProps = {
  playlists: [],
  allowChanges: false,
  onClose: () => {},
  onUpdate: () => {},
};

const mapDispatchToProps = {
  addMixPlaylist: NewMixActions.addMixPlaylist,
  removeMixPlaylist: NewMixActions.removeMixPlaylist,
};

export default connect(null, mapDispatchToProps)(MixPlaylists);
