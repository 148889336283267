// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventItem {
  width: 100%;
  height: 100%;
  padding: 8px;
}
.EventItem > .time {
  margin-bottom: 4px;
}
.EventItem > .title {
  font-size: 14px;
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Grid/Items/EventItem.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".EventItem {\n  width: 100%;\n  height: 100%;\n  padding: 8px;\n\n  > .time {\n    margin-bottom: 4px;\n  }\n\n  > .title {\n    font-size: 14px;\n    font-weight: 900;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
