import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  LocationHelper,
  LocationsAssigned,
} from 'vibeguide';

function TargetGroupLocations({
  allowChanges,
  locations: propLocations,
  locationsData: propLocationsData,
  mediaFormat,
  onUpdate,
}) {
  const [locations, setLocations] = useState(propLocations);
  const [locationsData, setLocationsData] = useState(propLocationsData);
  const {
    ADMIN_COMPANY_ID: adminCompanyId,
  } = process.env;

  /**
   * When the locations are assigned from an object
   */
  const onUpdateLocations = ({
    locations,
    locationsData,
  }) => {
    setLocations(locations);
    setLocationsData(locationsData);

    // segment "locations" is a group vs. the normal location spec
    onUpdate({
      locationCount: locations.length,
      locationSpec: locations,
      locations: LocationHelper.group(locations),
      locationsData,
    });
  };

  return (
    <LocationsAssigned
      companyId={adminCompanyId}
      locations={locations}
      locationsData={locationsData}
      disabled={!allowChanges}
      filtersUnassigned={{
        adProgramEnabled: true,
        mediaFormat,
      }}
      onUpdate={onUpdateLocations}
    />
  );
}

TargetGroupLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
  }),
  /** Filter the locations by the media format */
  mediaFormat: PropTypes.string,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  onUpdate: PropTypes.func,
};

TargetGroupLocations.defaultProps = {
  locations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  mediaFormat: '',
  allowChanges: false,
  onUpdate: () => {},
};

export default TargetGroupLocations;
