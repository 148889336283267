import React from 'react';

const viMusic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M18.875 2.25a1.04 1.04 0 0 0-.328.047l-9.75 3a1.157 1.157 0 0 0-.574.416c-.149.2-.223.42-.223.662v11.332c-.68-.305-1.43-.457-2.25-.457a5.68 5.68 0 0 0-1.13.123 6.629 6.629 0 0 0-1.214.375 2.663 2.663 0 0 0-1.008.709A1.542 1.542 0 0 0 2 19.5c0 .39.133.738.398 1.043.266.304.602.54 1.008.709.407.168.81.293 1.213.374.403.082.78.123 1.131.123.352 0 .728-.04 1.13-.123a6.632 6.632 0 0 0 1.214-.374 2.665 2.665 0 0 0 1.008-.71c.265-.304.398-.651.398-1.042V11.19l9-2.777v6.293c-.68-.305-1.43-.457-2.25-.457-.352 0-.729.04-1.13.123a6.634 6.634 0 0 0-1.214.374 2.662 2.662 0 0 0-1.008.71A1.542 1.542 0 0 0 12.5 16.5c0 .39.133.738.398 1.043.266.304.602.54 1.008.709.407.168.81.293 1.213.375.402.082.78.122 1.131.122s.728-.04 1.13-.122a6.651 6.651 0 0 0 1.214-.375 2.662 2.662 0 0 0 1.008-.71c.265-.304.398-.652.398-1.042V3.375c0-.313-.11-.578-.328-.797a1.084 1.084 0 0 0-.797-.328z" />
    </g>
  </svg>
);

export default viMusic;
