import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  orderBy,
} from 'lodash';
import {
  API,
  LocationActions,
  EmptyState,
  VibeIcon,
  viRefresh,
  color,
} from 'vibeguide';
import ExternalInfoRow from './ExternalInfoRow';
import './ExternalInfo.scss';

class ExternalInfo extends PureComponent {
  constructor(props) {
    super(props);

    const {
      currentLocation,
    } = props;

    const lookupHistory = get(currentLocation, 'thirdPartyData.lookupHistory', []);

    this.state = {
      locationId: get(currentLocation, '_id'),
      lookupHistory: this.handleHistory(lookupHistory),
      loadingExternalInfo: false,
    };
  }

  /**
   * Save the lookup history and order by newest first
   */
  handleHistory = (lookupHistory) => {
    lookupHistory.forEach((lookup) => {
      // convert lookup time to a readable string
      lookup.lookupTimeStr = moment(lookup.lookupTime).format('ddd, MMM DD, YYYY, h:mma');
    });

    return orderBy(lookupHistory, 'lookupTime', 'desc');
  };

  /**
   * Get External Info
   */
  getExternalInfo = async () => {
    const {
      locationId,
    } = this.state;

    this.setState({
      loadingExternalInfo: true,
    });

    const externalInfo = await API.ThirdParty.lookupThirdPartyId(locationId);
    const success = get(externalInfo, '[0].type') === 'THIRDPARTYPROVIDER.LOOKED_UP_VALUE_FOR_LOCATION';

    if (success) {
      // fetch the history
      this.getLookupHistory();
    } else {
      // failed to lookup external info
      this.setState({
        loadingExternalInfo: false,
      });
    }
  };

  /**
   * Get the lookup history
   */
  getLookupHistory = async () => {
    const {
      currentLocation,
      updateCurrentLocation,
    } = this.props;

    const {
      locationId,
    } = this.state;

    const location = await API.Location.getById(locationId);
    const lookupHistory = get(location, 'thirdPartyData.lookupHistory', []);

    updateCurrentLocation({
      thirdPartyData: {
        ...currentLocation.thirdPartyData,
        lookupHistory,
      },
    });

    this.setState({
      lookupHistory: this.handleHistory(lookupHistory),
      loadingExternalInfo: false,
    });
  };

  render() {
    const {
      className,
      activeThirdPartyItem,
      selectedThirdPartyProvider,
    } = this.props;

    const {
      lookupHistory,
      loadingExternalInfo,
    } = this.state;

    // get the lookup history that matches the selected provider
    const useLookupHistory = lookupHistory.filter(item => item.provider === selectedThirdPartyProvider);

    return (
      <div className={classNames('ExternalInfo', className)}>
        <div className="header">
          <VibeIcon
            type="button"
            icon={viRefresh}
            color={color.violetVibe}
            size={16}
            loading={loadingExternalInfo}
            tooltip="Call API"
            tooltipProps={{
              placement: 'left',
            }}
            buttonProps={{
              size: 32,
              borderColor: color.violetVibe,
            }}
            onClick={this.getExternalInfo}
          />
        </div>

        {useLookupHistory.length > 0 ? (
          <div className="lookup-items">
            {useLookupHistory.map((lookup, index) => {
              // check if the latest lookup matches the current one in the third party data panel
              const match = index === 0
                && lookup.value === activeThirdPartyItem.externalId;

              return (
                <ExternalInfoRow
                  key={`external-row-${lookup.lookupTime}`}
                  provider={lookup.provider}
                  value={lookup.value}
                  time={lookup.lookupTimeStr}
                  username={lookup.lookupByUserName}
                  imageUrl={lookup.lookupByUserImageUrl}
                  match={match}
                />
              );
            })}
          </div>
        ) : (
          <EmptyState
            title="No Lookups Performed"
            description="Lookup the current external information using the refresh button above."
            style={{
              flexGrow: 1,
              padding: 24,
              justifyContent: 'start',
              backgroundColor: color.white,
            }}
          />
        )}
      </div>
    );
  }
}

ExternalInfo.propTypes = {
  className: PropTypes.string,
  selectedThirdPartyProvider: PropTypes.string,
  /** The active third party item based on start/end date */
  activeThirdPartyItem: PropTypes.shape({
    provider: PropTypes.string,
    externalId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

ExternalInfo.defaultProps = {
  className: '',
  selectedThirdPartyProvider: '',
  activeThirdPartyItem: {},
};

function mapStateToProps(state) {
  return {
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  updateCurrentLocation: LocationActions.updateCurrentLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalInfo);
