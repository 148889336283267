import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  getLocationsData,
} from '../../helpers/ExpandedLocations';
import TableLocations from '../VibeTable/Tables/TableLocations';
import API from '../../api';

function TargetGroupDetails({
  className,
  style,
  targetGroupId,
  user,
}) {
  const [locations, setLocations] = useState([]);

  /**
   * Get the target group locations
   */
  const getLocations = async () => {
    const expandedLocations = await API.TargetGroup.getExpandedLocations({
      _id: targetGroupId,
    });

    const locationsData = getLocationsData(expandedLocations);
    setLocations(locationsData.locations);
  };

  /**
   * Export locations to CSV file
   */
  const onExport = async (data) => {
    // save the location IDs to an array to fetch
    const locationIds = data.map(location => location._id);

    // fetch the list of locations filtered to only the assigned location IDs
    const locations = await API.User.getLocations({
      _id: user._id,
      pageSize: -1,
      filters: {
        _id: locationIds,
      },
    }, {
      headers: {
        Accept: 'text/csv',
      },
    });

    const blob = new Blob([locations], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `locations-${timestamp}.csv`);
  };

  /**
   * Get the target group locations whenever the ID changes
   */
  useEffect(() => {
    getLocations();
  }, [targetGroupId]);

  if (locations.length <= 0) {
    // only render when after locations are fetched
    return null;
  }

  return (
    <div
      className={classNames('TargetGroupDetails', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...style,
      }}
    >
      <TableLocations
        columns={[
          'Name',
          'Company',
          'Media Format',
          'Address 1',
          'Address 2',
          'Country',
          'City',
          'State/Province',
          'Postal Code',
          'DMA',
          'Ad Network',
          'Ad Programs',
          'Tags',
          '...',
        ]}
        collection={locations}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Locations',
          urlPaging: false,
          urlFilters: false,
        }}
        csv
        csvProps={{
          onExport,
        }}
      />
    </div>
  );
}

TargetGroupDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  targetGroupId: PropTypes.string.isRequired,
};

TargetGroupDetails.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(TargetGroupDetails);
