import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './Information.scss';

class Information extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  /**
   * When the company field is changed
   */
  onSetCompany = ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      companyId: currCompanyId,
      tags,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  render() {
    const {
      name,
      companyName,
      companyId,
      tags,
      disableInput,
      user,
      isNew,
      isDuplicate,
    } = this.props;

    const canSetCompany = user.sysAdmin;

    return (
      <div className="Information">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >

          <Grid
            xs={12}
            item
          >
            <Grid
              spacing={2}
              container
            >
              <Grid
                xs={canSetCompany ? 6 : 12}
                item
                className="short-item"
              >
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  placeholder="Name"
                  value={name}
                  tabIndex={1}
                  onChange={this.onChange}
                  disabled={disableInput}
                  required
                  autoFocus
                />
              </Grid>

              <Grid
                xs={6}
                item
                className="short-item"
              >
                {canSetCompany ? (
                  <div className="messageBlock-company">
                    <div className="form-container">
                      <Field
                        type="company"
                        label="Company"
                        name="companyName"
                        value={companyName}
                        tabIndex={2}
                        companyProps={{
                          companyId,
                          companyName,
                        }}
                        disabled={disableInput || (!isNew && !isDuplicate)}
                        onChange={this.onSetCompany}
                        required
                      />
                    </div>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                companyId,
                tags,
                allowTypes: ['client', 'admin'],
                showAs: 'list',
                assign: user.can('messagelist.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={5}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  isNew: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  companyName: '',
  companyId: '',
  tags: [],
  disableInput: false,
  isNew: false,
  isDuplicate: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
