import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  LocationHelper,
  NavigationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  ToastActions,
  VibeModal,
  VibeTooltip,
  VibeButton,
  VibeIcon,
  viClose,
  viArchive,
  viCopy,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Information';
import MessageBlockMessages from './MessageBlockMessages';
import MessageBlockLocations from './MessageBlockLocations';
import './MessageBlockDetails.scss';
import Behavior from './Behavior';

class MessageBlockDetails extends PureComponent {
  constructor(props) {
    super(props);

    // Store which values are updated that require an endpoint
    this.updated = {};
    this.origMessageBlock = {};
    this.origMessageBlockSaved = false;
    this.messageBlockImageFile = null;

    this.state = {
      confirmArchive: false,
    };
  }

  componentDidUpdate() {
    const {
      messageBlock,
    } = this.props;

    if (!this.origMessageBlockSaved && messageBlock._id) {
      // Save the original messageBlock
      this.origMessageBlock = messageBlock;
      this.origMessageBlockSaved = true;
    }

    const {
      origMessageBlock,
    } = this;

    if (this.origMessageBlockSaved) {
      // Only track what fields are updated once the original messageBlock is saved
      if (messageBlock.messageBlocks !== origMessageBlock.messageBlocks) {
        this.updated.messageBlocks = true;
      }
    }
  }

  /**
   * When the locations assigned is clicked
   */
  onClickLocations = () => {
    const {
      user,
      messageBlock: {
        _id,
        companyId,
        locations,
        locationsData,
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('messagelist.assign_locations')
      && ((_id && user.can('messagelist.modify'))
        || (!_id && user.can('messagelist.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MessageBlockLocations
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the Messages assigned is clicked
   */
  onClickMessages = () => {
    const {
      user,
      messageBlock: {
        _id,
        messages,
        sequence,
        companyId,
        allLocations,
        locations,
      },
      setPanel,
      onCloseThirdPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.exceedsLocations(locations)
      && user.can('message.view')
      && ((_id && user.can('messagelist.modify'))
        || (!_id && user.can('messagelist.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MessageBlockMessages
            messages={messages}
            sequence={sequence}
            companyId={companyId}
            locations={locations}
            allLocations={allLocations}
            allowChanges={allowChanges}
            onCloseAssigned={this.onCloseExtraPanel}
            onCloseAvailable={onCloseThirdPanel}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the extra panel is closed
   */
  onCloseExtraPanel = () => {
    const {
      onCloseExtraPanel,
    } = this.props;

    onCloseExtraPanel();
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      messageBlock: {
        _id,
        locations,
      },
      onClose,
    } = this.props;

    await API.MessageBlock.reactivate({
      _id,
      locations,
    });
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveMessageBlock'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      messageBlock: {
        _id,
      },
      onClose,
    } = this.props;

    await API.MessageBlock.deactivate(_id);

    this.onCloseArchive();
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveMessageBlock'));
  };

  onUploadImage = (file) => {
    this.messageBlockImageFile = file;
  };

  /**
   * Save the MessageBlock
   */
  onSave = async () => {
    const {
      user,
      messageBlock: {
        _id,
        name,
        locations,
        allLocations,
        companyId,
        messages = [],
        listType,
        frequency,
        groupSize,
        startType,
        sequence,
        tags,
      },
      history,
      isNew,
      queueToast,
      redirectTo,
    } = this.props;

    document.dispatchEvent(new Event('onSaveMessageBlockStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const locationData = user.getLocations({
      locations,
      allLocations,
      companyId,
    });

    // Get tags to add/remove
    const modifyTags = tags ? tags.filter(tag => tag.status === 'add' || tag.status === 'remove') : [];
    const messageBlockId = _id || null;

    const messageIds = messages.map(message => message._id);

    // Data to create message block
    const data = {
      name,
      companyId,
      listType,
      frequency,
      sequence,
      groupSize,
      startType,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
      messages: messageIds.map((messageId, index) => {
        return {
          _id: messageId,
          sequence: index,
        };
      }),
      locations: locationData,
    };

    if (!isNew && messageBlockId) {
      // Editing a Message Block, set the ID
      data._id = messageBlockId;
    }

    try {
      const messageBlockResponse = !isNew
        ? await API.MessageBlock.modify(messageBlockId, data)
        : await API.MessageBlock.create(data);
      const newMessageBlockId = get(messageBlockResponse, '[0].documentId', null);

      if (this.messageBlockImageFile) {
        // Upload messageBlock image
        const uploadMessageBlockImage = await API.MessageBlock.uploadImage(
          newMessageBlockId,
          this.messageBlockImageFile,
        );
        const uploadMessageBlockImageId = get(uploadMessageBlockImage, '[0].documentId', null);

        if (uploadMessageBlockImageId) {
          // Image was successful
          queueToast({
            type: 'success',
            title: 'Saved!',
            allowClose: true,
          });
        } else {
          queueToast({
            type: 'error',
            title: 'Error Saving MessageBlock',
            timeout: 10,
            allowClose: true,
          });
        }
      } else {
        // No image to upload
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });
      }

      if (isNew) {
        // Redirect user to the edit page for the new item
        const link = `${redirectTo || '/messages/blocks'}?messageBlockId=${newMessageBlockId}`;
        history(link);
      }

      // tell listening components the message/request was saved
      document.dispatchEvent(new Event('onSaveMessageBlock'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveMessageBlockError'));
    }
  };

  render() {
    const {
      user,
      messageBlock,
      onClose,
      onUpdate,
      isNew,
      isDuplicate,
    } = this.props;

    const {
      confirmArchive,
    } = this.state;

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId: messageBlock.companyId,
      locations: messageBlock.locations,
    });

    const disableSave = !messageBlock.name
      || !messageBlock.companyId
      || (messageBlock.locations && messageBlock.locations.length <= 0);

    const locationCount = LocationHelper.getLocationCount(get(messageBlock, 'locationsData.companies', []), isACAF);
    const messageCount = get(messageBlock, 'messages.length', 0);
    const exceedsLocations = user.exceedsLocations(messageBlock.locations);

    const disableInput = !user.hasAccessToCompany(messageBlock.companyId)
      || !exceedsLocations
      || (messageBlock._id && !user.can('messagelist.modify'))
      || (!messageBlock._id && !user.can('messagelist.create'));

    const showDuplicate = user.can('messagelist.create')
      && exceedsLocations;

    const disableMessages = !isACAF && locationCount === 0;
    const showArchive = user.can('messagelist.delete')
      && user.hasAccessToCompany(messageBlock.companyId)
      && exceedsLocations;
    const isArchived = !messageBlock.active;

    return (
      <SidePanelContainer className="MessageBlockDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          {messageBlock._id ? (
            <div className="flex-horizontal">
              <div className="title">
                <VibeTooltip title={messageBlock.name}>
                  <span>
                    {messageBlock.name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Message Block
            </div>
          )}
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            name={messageBlock.name}
            category={messageBlock.category}
            description={messageBlock.description}
            image={!isDuplicate
              ? messageBlock.imageUrl
              : null}
            isNew={isNew}
            isDuplicate={isDuplicate}
            companyName={messageBlock.companyName}
            companyId={messageBlock.companyId}
            tags={messageBlock.tags}
            disableInput={disableInput}
            onUpdate={onUpdate}
            onUploadImage={this.onUploadImage}
          />

          <Behavior
            integration={messageBlock.listType}
            frequency={messageBlock.frequency}
            groupSize={messageBlock.groupSize}
            startType={messageBlock.startType}
            messageSequence={messageBlock.sequence}
            disableInput={disableInput}
            onUpdate={onUpdate}
          />

          <Assignments
            items={[{
              label: 'Locations Assigned',
              count: locationCount.display.element,
              tooltip: locationCount.display.tooltip,
              required: true,
              disabled: !messageBlock.companyId
                || !exceedsLocations,
              warning: !exceedsLocations
                ? 'You do not have access to all the locations assigned to this object'
                : null,
              onClick: messageBlock.companyId && exceedsLocations
                ? this.onClickLocations
                : null,
            },
            {
              label: 'Messages Assigned',
              count: messageCount,
              disabled: disableMessages,
              onClick: !disableMessages
                ? this.onClickMessages
                : null,
            }]}
          />
        </SidePanelContent>

        <SidePanelFooter className="panel-footer">
          <VibeButton
            className="btn-save"
            text="Save Changes"
            btnColor="purple"
            textColor="white"
            loadingEvent="onSaveMessageBlock"
            disabled={disableSave || disableInput}
            onClick={this.onSave}
          />

          {!isNew ? (
            <div className="toolbar-buttons">
              {showDuplicate ? (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viCopy}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.violetVibe,
                    }}
                    color={color.violetVibe}
                    size={20}
                    tooltip="Duplicate"
                    link={NavigationHelper.updateParams({
                      type: 'new',
                    })}
                  />
                </div>
              ) : null}

              {showArchive && !isArchived ? (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viArchive}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.fireBrick,
                    }}
                    tooltip="Archive"
                    color={color.fireBrick}
                    size={20}
                    onClick={this.onClickArchive}
                  />
                </div>
              ) : null}

              {showArchive && isArchived ? (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viUnarchive}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.aquaForest,
                    }}
                    tooltip={locationCount.total > 0
                      ? 'Unarchive'
                      : 'Must have at least 1 active location to unarchive'}
                    color={color.aquaForest}
                    size={20}
                    disabled={locationCount.total <= 0}
                    onClick={this.onClickUnarchive}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${messageBlock.name}?`}
          confirmProps={{
            text: 'Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />
      </SidePanelContainer>
    );
  }
}

MessageBlockDetails.propTypes = {
  /** New MessageBlock */
  isNew: PropTypes.bool,
  /** Duplicate MessageBlock */
  isDuplicate: PropTypes.bool,
  messageBlock: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  redirectTo: PropTypes.string,
  onClose: PropTypes.func,
  onCloseExtraPanel: PropTypes.func,
  onCloseThirdPanel: PropTypes.func,
  onUpdate: PropTypes.func,
};

MessageBlockDetails.defaultProps = {
  isNew: false,
  isDuplicate: false,
  messageBlock: {},
  redirectTo: '',
  onClose: () => {},
  onCloseExtraPanel: () => {},
  onCloseThirdPanel: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageBlockDetails));
