import PropTypes from 'prop-types';

export const submenuItemType = PropTypes.shape({
  // Item name
  name: PropTypes.string,
  // Item label (defaults to name if not used)
  label: PropTypes.string,
  // Whether or not to hide the item
  show: PropTypes.bool,
  // Whether or not the item is clickable
  enabled: PropTypes.bool,
  // Custom paginator label
  paginatorLabel: PropTypes.string,
  // Add custom columns to columnDef
  columns: PropTypes.arrayOf(
    PropTypes.string,
  ),
  // Custom filters for the fetch method
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  // Custom fetch method
  fetch: PropTypes.func,
});

export const submenuItemDefault = {
  name: null,
  label: null,
  show: false,
  enabled: false,
  paginatorLabel: null,
  filters: {},
  fetch: () => {},
};

export default {
  submenuItemType,
  submenuItemDefault,
};
