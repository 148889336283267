// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WeekNavigation {
  display: flex;
  align-items: center;
}
.WeekNavigation .week-dates {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Controls/WeekNavigation.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AACJ","sourcesContent":[".WeekNavigation {\n  display: flex;\n  align-items: center;\n\n  .week-dates {\n    margin-left: 8px;\n    font-size: 16px;\n    font-weight: bold;\n    user-select: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
