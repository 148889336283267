import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viChevronRight from '../../../icons/viChevronRight';
import './PanelItemLink.scss';

function PanelItemLink({
  className,
  text,
  active,
  icon,
  color,
  onClick,
}) {
  return (
    <div
      className={classNames('PanelItemLink', className)}
      style={{
        backgroundColor: active
          ? `${color}29`
          : 'transparent',
      }}
      onClick={onClick}
    >
      {icon ? (
        <VibeIcon
          icon={icon}
          color={color}
          size={16}
        />
      ) : null}

      <div
        className="text"
        style={{
          color,
        }}
      >
        {text}
      </div>

      {active ? (
        <VibeIcon
          icon={viChevronRight}
          color={color}
          size={24}
        />
      ) : null}
    </div>
  );
}

PanelItemLink.propTypes = {
  /** Class */
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  color: PropTypes.string,
  onClick: PropTypes.func,
};

PanelItemLink.defaultProps = {
  className: '',
  active: false,
  icon: null,
  color: '#6d6db5',
  onClick: () => {},
};

export default PanelItemLink;
