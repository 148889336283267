import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  NavigationHelper,
  ToastActions,
  Field2 as Field,
  VibeModal,
  VibeTooltip,
  VibeIcon,
  viArrowRight,
  viLock,
  viNowPlaying,
  viCalendar,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import './AdProgramListItem.scss';

function AdProgramListItem({
  className,
  adProgramId,
  locationId,
  startDate,
  endDate,
  selected,
  current,
  future,
  queueToast,
}) {
  const [showEffectiveDates, setShowEffectiveDates] = useState(false);
  const [effectiveStartDate, setEffectiveStartDate] = useState(null);
  const [effectiveEndDate, setEffectiveEndDate] = useState(null);

  const startDateStr = moment(startDate).format('ddd, MMM DD, YYYY');
  const endDateStr = moment(endDate).format('ddd, MMM DD, YYYY');

  /**
   * Change effective start date
   */
  const onChangeEffectiveStartDate = (date) => {
    setEffectiveStartDate(date);
  };

  /**
   * Change effective end date
   */
  const onChangeEffectiveEndDate = (date) => {
    setEffectiveEndDate(date);
  };

  /**
   * Clicking the effective dates icon
   */
  const onClickEffectiveDates = (e) => {
    e.preventDefault();
    setShowEffectiveDates(true);
  };

  /**
   * Cancel the effective dates
   */
  const onCancelEffectiveDates = () => {
    setShowEffectiveDates(false);
  };

  /**
   * Save the effective dates
   */
  const onSaveEffectiveDates = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = await API.Location.AdPrograms.updateEffectiveDates({
        locationId,
        adProgramId,
        _id: adProgramId,
        effectiveStartDate,
        effectiveEndDate,
      });

      const success = get(response, '[0].type') === 'LOCATIONADPROGRAM.EFFECTIVE_DATES_UPDATED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Saved Effective Dates!',
          allowClose: true,
        });
      } else {
        console.error('Error saving effective dates to API', response);

        queueToast({
          type: 'error',
          title: 'Error Saving Dates',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving dates', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Dates',
        timeout: 10,
        allowClose: true,
      });
    }

    setShowEffectiveDates(false);
  };

  useEffect(async () => {
    if (showEffectiveDates) {
      // fetch the ad program to get the effective start/end dates
      const adProgram = await API.Location.AdPrograms.get({
        locationId,
        adProgramId,
      });

      setEffectiveStartDate(adProgram.effectiveStartDate);
      setEffectiveEndDate(adProgram.effectiveEndDate);
    }
  }, [showEffectiveDates]);

  return (
    <div>
      <VibeTooltip
        title={`${startDateStr} to ${endDateStr}`}
        placement="right"
      >
        <Link
          className={classNames('AdProgramListItem', className, { selected })}
          to={NavigationHelper.updateParams({
            adProgramId,
            day: null,
            spotPoolStart: null,
          })}
        >
          {startDate}

          <VibeIcon
            icon={viArrowRight}
            color={color.manatee}
            size={16}
            style={{
              margin: '0 4px',
            }}
          />

          <div className="end-date">
            {endDate}
          </div>

          {current && (
            <VibeIcon
              icon={viNowPlaying}
              color={color.manatee}
              size={16}
              style={{
                margin: '0 4px',
              }}
              tooltip="Active Ad Program"
            />
          )}

          <VibeIcon
            icon={viCalendar}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={16}
            style={{
              margin: '0 4px',
            }}
            tooltip="Effective Dates"
            onClick={onClickEffectiveDates}
          />

          {!future && (
            <VibeIcon
              icon={viLock}
              color={color.manatee}
              size={16}
              style={{
                margin: '0 4px',
              }}
              tooltip="Only Future Ad Programs can be Modified"
            />
          )}
        </Link>
      </VibeTooltip>

      <VibeModal
        type="confirm"
        show={showEffectiveDates}
        confirmProps={{
          text: 'Save Effective Dates',
          color: color.violetVibe,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        // title="Ad Program Effective Dates"
        onConfirm={onSaveEffectiveDates}
        onClose={onCancelEffectiveDates}
      >
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <div
              style={{
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              Ad Program Dates
            </div>
          </Grid>

          <Grid xs={6} item>
            <Field
              type="custom"
              label="Start Date"
            >
              {moment(startDate).format('YYYY-MM-DD')}
            </Field>
          </Grid>

          <Grid xs={6} item>
            <Field
              type="custom"
              label="End Date"
            >
              {moment(endDate).format('YYYY-MM-DD')}
            </Field>
          </Grid>

          <Grid xs={12} item>
            <div
              style={{
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              Reporting Effective Dates
            </div>
          </Grid>

          <Grid xs={6} item>
            <Field
              type="datepicker"
              label="Start Date"
              placeholder="YYYY-MM-DD"
              value={effectiveStartDate}
              dateProps={{
                format: 'YYYY-MM-DD',
                minDate: moment(startDate).toDate(),
                maxDate: moment(endDate).toDate(),
              }}
              onChange={onChangeEffectiveStartDate}
              required
            />
          </Grid>

          <Grid xs={6} item>
            <Field
              type="datepicker"
              label="End Date"
              placeholder="YYYY-MM-DD"
              value={effectiveEndDate}
              dateProps={{
                format: 'YYYY-MM-DD',
                minDate: moment(startDate).toDate(),
                maxDate: moment(endDate).toDate(),
              }}
              onChange={onChangeEffectiveEndDate}
              required
            />
          </Grid>
        </Grid>
      </VibeModal>
    </div>
  );
}

AdProgramListItem.propTypes = {
  className: PropTypes.string,
  adProgramId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  current: PropTypes.bool,
  future: PropTypes.bool,
};

AdProgramListItem.defaultProps = {
  className: '',
  selected: false,
  current: false,
  future: false,
};

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(null, mapDispatchToProps)(AdProgramListItem);
