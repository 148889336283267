import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useSearchParams,
} from 'react-router-dom';
import {
  API,
  ExpandedLocationsHelper,
  SegmentModel,
  FlightModel,
  FlightSummaryModel,
  OrderModel,
  OrderSummaryModel,
  LoadingContent,
} from 'vibeguide';
import SegmentDetails from './SegmentDetails';
import './SegmentSidebar.scss';

function SegmentSidebar({
  className,
  type,
  segmentId,
  routeId,
  isNew,
  socket,
}) {
  const [searchParams] = useSearchParams();
  // if ?flightId= is in the URL, default the segment to that flight (new segment button on flight details page)
  const [segment, setSegment] = useState(new SegmentModel({
    flightId: searchParams.get('flightId'),
  }));
  /**
   * Determine whether to use Flight or Order models for this sidebar
   */
  const [flight, setFlight] = useState({
    data: type === 'flight'
      ? new FlightModel()
      : new OrderModel(),
    summary: type === 'flight'
      ? new FlightSummaryModel()
      : new OrderSummaryModel(),
  });

  /**
   * Get Flight Details
   */
  const getFlight = async (flightId) => {
    // flight details
    const flightResponse = await API.Flight.getById(flightId);
    const flightItem = new FlightModel(flightResponse);

    // flight summary (impressions, spots, etc)
    const flightSummaryResponse = await API.Flight.summary(flightId);
    const flightSummaryItem = new FlightSummaryModel(flightSummaryResponse);

    setFlight({
      ...flight,
      data: flightItem,
      summary: routeId === segment.flightId
        ? flightSummaryItem
        : new FlightSummaryModel(),
    });
  };

  /**
   * Get Order Details
   */
  const getOrder = async (orderId) => {
    // order details
    const orderResponse = await API.Order.get(orderId);
    const orderItem = new OrderModel(orderResponse);

    // order summary (impressions, spots, etc)
    const orderSummaryResponse = await API.Order.summary(orderId);
    const orderSummaryItem = new OrderSummaryModel(orderSummaryResponse);

    setFlight({
      ...flight,
      data: orderItem,
      summary: orderSummaryItem,
    });
  };

  const getSegment = async () => {
    const segmentResponse = type === 'flight'
      ? await API.Flight.Segment.get({
        flightId: routeId,
        segmentId,
      })
      : await API.Order.Segment.get({
        _id: routeId,
        segmentId,
      });

    const expandedLocations = type === 'flight'
      ? await API.Flight.Segment.getExpandedLocations({
        flightId: routeId,
        segmentId,
      })
      : await API.Order.Segment.getExpandedLocations({
        _id: routeId,
        segmentId,
      });

    // Set the location data
    segmentResponse.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    if (type === 'order') {
      // show targetImpressions as impressions for the segment calculator
      segmentResponse.impressions = segmentResponse.targetImpressions;
    }

    const segmentItem = new SegmentModel(segmentResponse);
    setSegment(segmentItem);
  };

  /**
   * Update the segment
   */
  const onUpdate = (data) => {
    if (data.frequencyCapped === false) {
      data.frequencyCap = 0;
    }

    if (data.spotSelectionStrategy
      && data.spotSelectionStrategy === 'spots-per-hour') {
      data.frequencyCapped = false;
      data.frequencyCap = 0;
    }

    setSegment({
      ...segment,
      ...data,
    });
  };

  /**
   * Listen for socket events
   */
  const onApiEvent = (e) => {
    switch (e.type) {
      // When a flight is changed or a calculation has completed
      case 'FLIGHT.COMPUTING': {
        if (segment.flightId) {
          setFlight({
            ...flight,
            summary: new FlightSummaryModel(),
          });
        }
        break;
      }

      case 'FLIGHT.COMPUTED':
      case 'FLIGHT.RECOVERED': {
        if (segment.flightId) {
          getFlight(segment.flightId);
        }
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
   * Get the segment if the ID changes
   */
  useEffect(() => {
    if (segmentId) {
      getSegment();
    }
  }, [segmentId]);

  /**
   * Get the flight if the ID changes
   */
  useEffect(() => {
    const doGetFlight = segment.flightId && type !== 'order';

    if (doGetFlight) {
      getFlight(segment.flightId);

      socket.emit('subscribe', {
        topic: 'flight',
        id: segment.flightId,
      });
    }

    // when component unmounts
    return () => {
      if (doGetFlight) {
        socket.emit('unsubscribe', {
          topic: 'flight',
          id: segment.flightId,
        });
      }
    };
  }, [segment.flightId]);

  /**
   * Get the order if there is an order
   */
  useEffect(() => {
    const doGetOrder = routeId && type === 'order';

    if (doGetOrder) {
      getOrder(routeId);
    }
  }, [routeId]);

  /**
   * When connected to the socket, listen for flight events
   * Need to resubscribe to the event listener whenever the segment or flight is fetched
   */
  useEffect(() => {
    if (socket.connected) {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.on('VAPI_EVENT', onApiEvent);
    }

    // when component unmounts
    return () => {
      socket.off('VAPI_EVENT', onApiEvent);
    };
  }, [socket.connected, segment._id, flight.data._id]);

  if (segmentId && !segment._id) {
    // segment hasn't been fetched from the API yet
    return null;
  }

  return (
    <div className={classNames('SegmentSidebar', className)}>
      <SegmentDetails
        segment={segment}
        flight={flight.data}
        flightSummary={flight.summary}
        routeId={routeId}
        isNew={isNew}
        onUpdate={onUpdate}
        // onRefresh={getSegment}
      />

      {segmentId && !segment._id && (
        <LoadingContent />
      )}
    </div>
  );
}

SegmentSidebar.propTypes = {
  className: PropTypes.string,
  /** type of segment being requested */
  type: PropTypes.oneOf([
    'flight',
    'order',
  ]).isRequired,
  segmentId: PropTypes.string,
  routeId: PropTypes.string,
  isNew: PropTypes.bool,
};

SegmentSidebar.defaultProps = {
  className: '',
  segmentId: '',
  routeId: '',
  isNew: false,
};

function mapStateToProps(state) {
  return {
    socket: state.socket.connection,
  };
}

export default connect(mapStateToProps)(SegmentSidebar);
