import React from 'react';

const viAlarm = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M14.667 3.907L11.6 1.333l-.86 1.02 3.067 2.574.86-1.02zM5.253 2.353L4.4 1.333 1.333 3.9l.86 1.02 3.06-2.567zm3.08 3.074h-1v4l3.167 1.9.5-.82-2.667-1.58v-3.5zM8 2.76a6 6 0 1 0 .001 12.001A6 6 0 0 0 8 2.76zm0 10.667A4.663 4.663 0 0 1 3.333 8.76 4.663 4.663 0 0 1 8 4.093a4.663 4.663 0 0 1 4.667 4.667A4.663 4.663 0 0 1 8 13.427z" />
    </g>
  </svg>
);

export default viAlarm;
