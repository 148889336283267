import { createAction } from 'redux-actions';

export const setSelectedLocationId = createAction('SET_SELECTED_LOCATION_ID');
export const setCurrentLocation = createAction('SET_CURRENT_LOCATION');
export const setLocationData = createAction('SET_LOCATION_DATA');
export const setLocationId = createAction('SET_LOCATION_ID');
export const setLocationName = createAction('SET_LOCATION_NAME');
export const setCompany = createAction('SET_LOCATION_COMPANY');
export const setAddress1 = createAction('SET_ADDRESS1');
export const setAddress2 = createAction('SET_ADDRESS2');
export const setCity = createAction('SET_CITY');
export const setLocationState = createAction('SET_LOCATION_STATE');
export const setZip = createAction('SET_ZIP');
export const setRating = createAction('SET_RATING');
export const setLatitude = createAction('SET_LATITUDE');
export const setLongitude = createAction('SET_LONGITUDE');
export const setCoordsLockedUnlocked = createAction('SET_COORDS_LOCKED_UNLOCKED');
export const setLocationImage = createAction('SET_LOCATION_IMAGE');
export const setLocationBannerName = createAction('SET_LOCATION_BANNER_NAME');
export const setSalesforceId = createAction('SET_LOCATION_SALESFORCE_ID');
export const setEnableAdPrograms = createAction('SET_LOCATION_ENABLE_AD_PROGRAMS');
export const addTag = createAction('ADD_LOCATION_TAG');
export const removeTag = createAction('REMOVE_LOCATION_TAG');
export const updateCurrentLocation = createAction('UPDATE_CURRENT_LOCATION');
export const resetNewLocation = createAction('RESET_NEW_LOCATION');
export const resetCurrentLocation = createAction('RESET_CURRENT_LOCATION');

export default {
  setSelectedLocationId,
  setCurrentLocation,
  setLocationData,
  setLocationId,
  setLocationName,
  setCompany,
  setAddress1,
  setAddress2,
  setCity,
  setLocationState,
  setZip,
  setRating,
  setLatitude,
  setLongitude,
  setCoordsLockedUnlocked,
  setLocationImage,
  setLocationBannerName,
  setSalesforceId,
  setEnableAdPrograms,
  addTag,
  removeTag,
  updateCurrentLocation,
  resetNewLocation,
  resetCurrentLocation,
};
