import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DaySelector,
  VibeIcon,
  viCalendar,
  viTime,
  color,
} from 'vibeguide';
import './SegmentSchedule.scss';

function SegmentSchedule({
  startDate,
  endDate,
  startTime,
  endTime,
  days,
}) {
  return (
    <div className="SegmentSchedule">
      <div className="date-time-wrapper">
        <VibeIcon
          icon={viCalendar}
          color={color.manatee}
          size={20}
        />

        <div className="date">
          <div>{moment(startDate).format('ll')} - {moment(endDate).format('ll')}</div>
        </div>

        <VibeIcon
          icon={viTime}
          color={color.manatee}
          size={20}
        />

        <div className="time">
          <div>{startTime} - {endTime}</div>
        </div>
      </div>

      <div
        className="schedule"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 8,
        }}
      >
        <DaySelector
          style={{
            width: 250,
          }}
          dayFormat="dd"
          selectedDays={days}
          disabled
        />
      </div>
    </div>
  );
}

SegmentSchedule.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  days: PropTypes.arrayOf(PropTypes.string),
};

SegmentSchedule.defaultProps = {
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  days: [],
};

export default SegmentSchedule;
