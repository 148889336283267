import React from 'react';
import PropTypes from 'prop-types';
import ObjectSelection from '../ObjectSelection/ObjectSelection';
import PlaylistsUnassigned from './PlaylistsUnassigned';
import PlaylistsAssigned from './PlaylistsAssigned';

function MixPlaylistSelection({
  fetch,
  filters,
  selected,
  allowChanges,
  allowPaginator,
  customToolbarAssigned,
  onSelect,
  onDeselect,
  onUpdate,
}) {
  return (
    <ObjectSelection
      className="PlaylistSelection"
      leftColumn={(
        <PlaylistsUnassigned
          fetch={fetch}
          filters={filters}
          selected={selected}
          allowChanges={allowChanges}
          allowPaginator={allowPaginator}
          onSelect={onSelect}
        />
      )}
      rightColumn={(
        <PlaylistsAssigned
          selected={selected}
          onUpdate={onUpdate}
          customToolbar={customToolbarAssigned}
          allowChanges={allowChanges}
          onDeselect={onDeselect}
        />
      )}
    />
  );
}

MixPlaylistSelection.propTypes = {
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected playlists */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** Allow the Paginator to be displayed */
  allowPaginator: PropTypes.bool,
  /** Custom toolbar for assigned locations */
  customToolbarAssigned: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onUpdate: PropTypes.func,
  /** Fetch playlists endpoint */
  fetch: PropTypes.func,
  /** When a playlist is selected */
  onSelect: PropTypes.func,
  /** When a location is deselected */
  onDeselect: PropTypes.func,
};

MixPlaylistSelection.defaultProps = {
  filters: null,
  selected: [],
  allowChanges: false,
  allowPaginator: false,
  customToolbarAssigned: null,
  fetch: () => {},
  onUpdate: () => {},
  onSelect: () => {},
  onDeselect: () => {},
};

export default MixPlaylistSelection;
