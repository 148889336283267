import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeIcon,
  viSubtract,
  color,
} from 'vibeguide';
import './GripHandle.scss';

function GripHandle({
  className,
  position,
  onMouseDown,
}) {
  const onMouseDownGrip = (e) => {
    onMouseDown(e, {
      type: position === 'top'
        ? 'startTime'
        : 'stopTime',
    });
  };

  return (
    <div
      className={classNames('GripHandle', position, className)}
      onMouseDown={onMouseDownGrip}
    >
      <VibeIcon
        icon={viSubtract}
        color={color.manatee}
        size={16}
      />
    </div>
  );
}

GripHandle.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf([
    'top',
    'bottom',
  ]).isRequired,
  onMouseDown: PropTypes.func,
};

GripHandle.defaultProps = {
  className: '',
  onMouseDown: () => {},
};

export default GripHandle;
