import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isFinite,
  toString,
  toNumber,
} from 'lodash';
import VibeIcon from '../VibeIcon/VibeIcon';
import viDollar from '../../icons/viDollar';
import color from '../../sass/color.scss';
import './DollarField.scss';

function toCurrency(value = '') {
  const numValue = toNumber(toString(value).replace(/,|\$/g, ''));
  const useValue = isFinite(numValue)
    ? numValue
    : 0;

  return useValue
    .toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    });
}

function fromCurrency(value = '') {
  const numValue = toNumber(value.replace(/,|\$/g, ''));
  return isFinite(numValue)
    ? numValue
    : 0;
}

function DollarField({
  className,
  name,
  placeholder,
  value,
  disabled,
  tabIndex,
  autoFocus,
  autoComplete,
  onChange,
  onBlur,
  onFocus,
}) {
  const [friendlyValue, setFriendlyValue] = useState(toCurrency(value));

  // value has changed, update to show as currency
  useEffect(() => {
    setFriendlyValue(toCurrency(value));
  }, [value]);

  const onChangeField = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setFriendlyValue(value);
  };

  const onBlurField = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    // convert the value back to a number
    const numValue = fromCurrency(value);

    // format the value for currency
    setFriendlyValue(toCurrency(value));

    // only tell the parent component of the change after blur (to format properly)
    onChange({
      name,
      value: numValue,
    });

    onBlur(e);
  };

  return (
    <div className={classNames('DollarField', className)}>
      <div className="input-group-left">
        <VibeIcon
          icon={viDollar}
          color={color.manatee}
          size={16}
        />
      </div>

      <input
        name={name}
        className={classNames('field-input', 'has-input-group-left')}
        type="text"
        tabIndex={tabIndex}
        placeholder={placeholder || '0.00'}
        value={friendlyValue !== '0.00'
          ? friendlyValue
          : ''}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        onBlur={onBlurField}
        onChange={onChangeField}
        onFocus={onFocus}
      />
    </div>
  );
}

DollarField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Auto focus on the input */
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

DollarField.defaultProps = {
  className: '',
  name: '',
  placeholder: '',
  value: 0,
  disabled: false,
  tabIndex: 0,
  autoFocus: false,
  autoComplete: 'on',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

export default DollarField;
