// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdvertiserDetails .row {
  margin-bottom: 8px;
}
.AdvertiserDetails .row.extra-space {
  margin-top: 8px;
}
.AdvertiserDetails .details-header {
  display: flex;
  align-items: center;
}
.AdvertiserDetails .details-header .ad-image {
  margin-right: 16px;
}
.AdvertiserDetails .details-header .ad-image .upload-image {
  width: 40px;
  height: 40px;
}
.AdvertiserDetails .details-title {
  font-size: 14px;
  font-weight: 900;
}
.AdvertiserDetails .panel-footer .btn-save,
.AdvertiserDetails .panel-footer .btn-edit {
  margin-right: 12px;
}
.AdvertiserDetails .panel-footer .toolbar-buttons {
  display: flex;
  align-items: center;
}
.AdvertiserDetails .panel-footer .toolbar-buttons .toolbar-button {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Advertisers/Sidebar/AdvertiserDetails.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAKE;EACE,aAAA;EACA,mBAAA;AAHJ;AAKI;EACE,kBAAA;AAHN;AAKM;EACE,WAAA;EACA,YAAA;AAHR;AAQE;EACE,eAAA;EACA,gBAAA;AANJ;AAWI;;EAEE,kBAAA;AATN;AAYI;EACE,aAAA;EACA,mBAAA;AAVN;AAYM;EACE,kBAAA;AAVR","sourcesContent":[".AdvertiserDetails {\r\n\r\n  .row {\r\n    margin-bottom: 8px;\r\n\r\n    &.extra-space {\r\n      margin-top: 8px;\r\n    }\r\n  }\r\n\r\n  .details-header {\r\n    display: flex;\r\n    align-items: center;\r\n\r\n    .ad-image {\r\n      margin-right: 16px;\r\n\r\n      .upload-image {\r\n        width: 40px;\r\n        height: 40px;\r\n      }\r\n    }\r\n  }\r\n\r\n  .details-title {\r\n    font-size: 14px;\r\n    font-weight: 900;\r\n  }\r\n\r\n  .panel-footer {\r\n\r\n    .btn-save,\r\n    .btn-edit {\r\n      margin-right: 12px;\r\n    }\r\n\r\n    .toolbar-buttons {\r\n      display: flex;\r\n      align-items: center;\r\n\r\n      .toolbar-button {\r\n        margin-right: 12px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
