import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isArray,
} from 'lodash';
import TableUsers from '../VibeTable/Tables/TableUsers';
import defaultCompanyImage from '../../assets/default_company.png';
import './UsersAssigned.scss';

class UsersAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = props;

    this.state = {
      filteredUsers: selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected: users,
    } = this.props;

    const {
      selected: prevUsers,
    } = prevProps;

    if (JSON.stringify(users) !== JSON.stringify(prevUsers)) {
      this.setState({
        filteredUsers: users,
      });
    }
  }

  onAdd = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const users = [
        ...state.filteredUsers,
        item,
      ];

      onChange(users);

      return {
        filteredUsers: users,
      };
    });
  };

  onRemove = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const itemIds = items.map(item => item._id);

    this.setState((state) => {
      const users = state.filteredUsers.filter(user => !itemIds.includes(user._id));
      onChange(users);

      return {
        filteredUsers: users,
      };
    });
  };

  render() {
    const {
      allowCustomOrder,
      allowChanges,
      customToolbar,
      onChange,
    } = this.props;

    const {
      filteredUsers,
    } = this.state;

    const columns = [
      'First Name',
      'Last Name',
      'Title',
      'Email',
      'Phone #',
      'Company',
      'Tags',
      '...',
    ];

    if (allowChanges) {
      columns.unshift('Add/Remove');
    }

    return (
      <div className="UsersAssigned">
        <div className="title-container">
          <div className="title">
            Assigned Users
          </div>

          {customToolbar}
        </div>

        <div className="users-container">
          <TableUsers
            columns={columns}
            collection={filteredUsers}
            defaultSortBy={{
              label: 'First Name',
              attr: 'fname',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Users',
              urlPaging: false,
              urlFilters: false,
            }}
            emptyProps={{
              image: defaultCompanyImage,
              title: 'Add Users to this Permission Set',
              description: 'Choose users to add to this permission set. Any existing permissions will be overwritten.',
            }}
            menuItems={[
              { name: 'Archive', userCan: 'user.delete' },
            ]}
            draggable={allowCustomOrder}
            droppable={allowCustomOrder}
            assigned
            highlight={filteredUsers}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

UsersAssigned.propTypes = {
  /** Allow custom order of objects in the table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  selected: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    messageType: PropTypes.string,
    url: PropTypes.string,
    active: PropTypes.bool,
  })),
  onChange: PropTypes.func,
};

UsersAssigned.defaultProps = {
  allowCustomOrder: false,
  allowChanges: false,
  customToolbar: null,
  selected: [],
  onChange: () => {},
};

export default UsersAssigned;
