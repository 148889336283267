import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  TargetTagsList,
  DeviceAttributes,
  SidePanelTabs,
  withRouter,
} from 'vibeguide';
import './TargetTagsContainer.scss';

class TargetTagsContainer extends PureComponent {
  constructor(props) {
    super(props);

    const {
      params: {
        type,
      },
      user,
    } = props;

    this.state = {
      tabs: [{
        name: 'Triton Target Tags',
        link: '/ad-ops/targeting/triton',
        disabled: !user.can('targeting_tag.view'),
        matchType: 'triton',
        selected: type === 'triton' || !type,
      },
      {
        name: 'Vistar Device Attributes',
        link: '/ad-ops/targeting/vistar',
        disabled: !user.can('device_attribute.view'),
        matchType: 'vistar',
        selected: type === 'vistar',
      }],
    };
  }

  componentDidUpdate(prevProps) {
    const {
      params: {
        type,
      },
    } = this.props;

    const {
      params: {
        type: prevType,
      },
    } = prevProps;

    if (type !== prevType) {
      this.setState((state) => {
        return {
          tabs: state.tabs.map((tab) => {
            if (tab.matchType === type) {
              return {
                ...tab,
                selected: true,
              };
            }

            return {
              ...tab,
              selected: false,
            };
          }),
        };
      });
    }
  }

  render() {
    const {
      params: {
        type,
      },
      user,
    } = this.props;

    const {
      tabs,
    } = this.state;

    return (
      <div className="TargetTagsContainer">
        <div className="page-title">
          Ad Targeting
        </div>

        <SidePanelTabs
          className="panel-tabs"
          tabs={tabs}
        />

        {type === 'triton' || !type ? (
          <TargetTagsList
            className="target-tags-admin"
            panelList
            allowCreate
            allowCopy
          />
        ) : null}

        {type === 'vistar' ? (
          <DeviceAttributes
            className="target-tags-admin"
            type="admin"
            allowCreate={user.can('device_attribute.create')}
            allowModify={user.can('device_attribute.modify')}
            allowCopy={user.can('device_attribute.modify')}
            allowSearch
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default withRouter(connect(mapStateToProps)(TargetTagsContainer));
