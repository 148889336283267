import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ResponsiveContainer,
  AreaChart as ReAreaChart,
  Area,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

function AreaChart({
  className,
  style,
  width,
  height,
  data,
  areas,
  xDataKey,
  xLabel,
  yLabel,
}) {
  return (
    <div
      className={classNames('AreaChart', className)}
      style={style}
    >
      <ResponsiveContainer
        width={width}
        height={height}
      >
        <ReAreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            {areas.map((area) => (
              <linearGradient
                key={`color-${area.key}`}
                id={`color-${area.key}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="5%" stopColor={area.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={area.color} stopOpacity={0} />
              </linearGradient>
            ))}
          </defs>

          <XAxis
            dataKey={xDataKey}
            style={{
              fontSize: 12,
              fontFamily: 'Lato, sans-serif',
            }}
          >
            {xLabel && (
              <Label
                value={xLabel}
                offset={0}
                position="insideBottom"
                style={{
                  fontSize: 12,
                }}
              />
            )}
          </XAxis>

          <YAxis
            style={{
              fontSize: 12,
              fontFamily: 'Lato, sans-serif',
            }}
          >
            {yLabel && (
              <Label
                value={yLabel}
                angle={-90}
                position="insideLeft"
                style={{
                  textAnchor: 'middle',
                  fontSize: 12,
                }}
              />
            )}
          </YAxis>

          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
          />

          <Tooltip />
          <Legend
            verticalAlign="top"
            align="right"
            height={36}
          />

          {areas.map((area) => (
            <Area
              key={`area-${area.key}`}
              type="monotone"
              dataKey={area.key}
              name={area.name}
              stroke={area.color}
              fillOpacity={1}
              fill={`url(#color-${area.key})`}
            />
          ))}
        </ReAreaChart>
      </ResponsiveContainer>
    </div>
  );
}

AreaChart.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** data used for plotting */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** areas to plot from the data array */
  areas: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  /** x-axis data key from the data array */
  xDataKey: PropTypes.string,
  /** label for the x-axis */
  xLabel: PropTypes.string,
  /** label for the y-axis */
  yLabel: PropTypes.string,
};

AreaChart.defaultProps = {
  className: '',
  style: {},
  width: '100%',
  height: 240,
  xDataKey: '',
  xLabel: '',
  yLabel: '',
};

export default AreaChart;
