import React from 'react';

const viGear = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M10.627 7.68c0-.144.048-.288.048-.48s0-.336-.048-.48l1.008-.816c.096-.096.096-.192.048-.288l-.96-1.68c-.048-.048-.144-.096-.288-.048l-1.2.48c-.24-.192-.528-.336-.816-.48L8.227 2.64c.048-.144-.096-.24-.192-.24h-1.92c-.096 0-.24.096-.24.192l-.192 1.296c-.288.096-.528.288-.816.48l-1.152-.48c-.144-.048-.24 0-.336.096l-.96 1.68c-.048.048 0 .192.096.288l1.008.768c0 .144-.048.288-.048.48s0 .336.048.48l-1.008.816c-.096.096-.096.192-.048.288l.96 1.68c.048.048.144.096.288.048l1.2-.48c.24.192.528.336.816.48l.192 1.248c0 .096.096.192.24.192h1.92c.096 0 .24-.096.24-.192l.192-1.248c.288-.144.576-.288.816-.48l1.2.48c.096.048.24 0 .288-.096l.96-1.68c.048-.096.048-.24-.048-.288l-1.104-.768zm-3.552 1.2c-.912 0-1.68-.768-1.68-1.68 0-.912.768-1.68 1.68-1.68.912 0 1.68.768 1.68 1.68 0 .912-.768 1.68-1.68 1.68z" transform="translate(-428 -212) translate(120 192) translate(196) translate(108 16) translate(4.8 4.8)" />
    </g>
  </svg>
);

export default viGear;
