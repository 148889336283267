import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  TimeUtil,
  VibeTooltip,
  VibeIcon,
  viMusic,
  viMicrophone,
  viAlarm,
  color,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './DayColumnHeader.scss';

/**
 * Get the tooltip text for the header
 */
function getTooltipText({
  scheduled,
  archived,
}) {
  const archivedCount = archived.length;
  const items = [];

  if (scheduled._id) {
    items.push(
      <div
        key="scheduled-baseline"
        style={{
          marginBottom:
            // when there is a scheduled baseline as well as archived baselines
            archivedCount > 0
              ? 4
              : 0,
        }}
      >
        <div
          style={{
            marginBottom: 4,
            fontWeight: 900,
          }}
        >
          A baseline is scheduled to begin at midnight
        </div>

        <div>
          {scheduled.name}
        </div>
      </div>,
    );
  }

  if (archivedCount > 0) {
    items.push(
      <div key="archived-baselines">
        <div
          style={{
            marginBottom: 4,
            fontWeight: 900,
          }}
        >
          {archivedCount === 1
            ? '1 Archived Baseline'
            : `${archivedCount} Archived Baselines`}
        </div>

        {archived.map((baseline) => {
          return (
            <div key={`baseline-${baseline.name}`}>
              {baseline.name}
            </div>
          );
        })}
      </div>,
    );
  }

  if (items.length > 0) {
    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {items}
      </div>
    );
  }

  return '';
}

function DayColumnHeader({
  day,
  startOfWeek,
  scheduled,
  archived,
  musicOverride,
  dayOfWeekOnly,
  hideIcons,
}) {
  const hasArchivedBaselines = archived.length > 0;
  const dayIndex = TimeUtil.getDayOfWeekIndex(day);
  const dayNum = !dayOfWeekOnly
    ? startOfWeek
      .add(dayIndex, 'days')
      .format('MMM DD')
    : null;

  const dayText = !dayOfWeekOnly && !musicOverride
    ? day
    : TimeUtil.getDayFromIndex(dayIndex, 'dddd');

  const iconColor = scheduled._id || hasArchivedBaselines
    ? color.white
    : color.manatee50;

  const tooltip = getTooltipText({
    scheduled,
    archived,
  });

  return (
    <VibeTooltip title={tooltip}>
      <div className={classNames('DayColumnHeader', {
        scheduled: scheduled._id,
        archived: hasArchivedBaselines,
      })}
      >
        <div className="col-content">
          <div className="col-title">
            {dayText}

            {!dayOfWeekOnly && !musicOverride && (
              <span className="weekday">
                {dayNum}
              </span>
            )}
          </div>

          {!hideIcons && (
            <Grid container className="col-icons">
              <Grid item xs={4}>
                <VibeIcon
                  icon={viMusic}
                  color={iconColor}
                  size={16}
                />
              </Grid>

              {!musicOverride && (
                <Grid item xs={4}>
                  <VibeIcon
                    icon={viMicrophone}
                    color={iconColor}
                    size={16}
                  />
                </Grid>
              )}

              {!musicOverride && (
                <Grid item xs={4}>
                  <VibeIcon
                    icon={viAlarm}
                    color={iconColor}
                    size={16}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </div>
    </VibeTooltip>
  );
}

DayColumnHeader.propTypes = {
  day: PropTypes.string.isRequired,
  startOfWeek: PropTypes.instanceOf(moment).isRequired,
  scheduled: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  archived: PropTypes.arrayOf(PropTypes.object),
  musicOverride: PropTypes.bool,
  /** Only show the day of the week at the top of the column */
  dayOfWeekOnly: PropTypes.bool,
  /** Hide the icons for each column in the schedule (i.e. for Spot Values) */
  hideIcons: PropTypes.bool,
};

DayColumnHeader.defaultProps = {
  scheduled: {},
  archived: [],
  musicOverride: false,
  dayOfWeekOnly: false,
  hideIcons: false,
};

export default DayColumnHeader;
