import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import {
  FieldGroupContainer,
  Field2 as Field,
} from 'vibeguide';
import ReportRow from './ReportRow';

function Reporting({
  className,
  style,
  securityGroup,
  reports,
  disableInput,
  onUpdate,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When a report row is changed
   */
  const onUpdateReportRow = (data, reportId) => {
    onUpdate({
      reports: reports.map((report) => {
        if (reportId === report._id) {
          return {
            ...report,
            ...data,
          };
        }

        return report;
      }),
    });
  };

  /**
   * When a report row is added
   */
  const onAddReportRow = () => {
    const report = {
      _id: uniqueId('report-'),
      name: '',
      url: '',
    };

    onUpdate({
      reports: [
        ...reports,
        report,
      ],
    });
  };

  /**
   * When a report row is removed
   */
  const onRemoveReportRow = (reportId) => {
    onUpdate({
      reports: reports.length <= 1
        // at least one item must exist, clear instead of remove
        ? [{
          _id: reportId,
          name: '',
          url: '',
        }]
        : reports.filter(report => report._id !== reportId),
    });
  };

  return (
    <div
      className={classNames('Reporting', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        ...style,
      }}
    >
      <div>
        <Field
          type="text"
          label="Security Group"
          name="securityGroup"
          placeholder="Security Group"
          value={securityGroup}
          disabled={disableInput}
          onChange={onChange}
        />
      </div>

      <FieldGroupContainer
        addRowLabel="Add Report Configuration"
        allowAddRow
        onAddRow={onAddReportRow}
      >
        {reports.map((report, index) => {
          return (
            <ReportRow
              key={report._id}
              reportId={report._id}
              name={report.name}
              url={report.url}
              disableInput={disableInput}
              // only show field labels for the first row
              showLabels={index === 0}
              onUpdate={onUpdateReportRow}
              onRemove={onRemoveReportRow}
            />
          );
        })}
      </FieldGroupContainer>
    </div>
  );
}

Reporting.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  securityGroup: PropTypes.string,
  reports: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Reporting.defaultProps = {
  className: '',
  style: {},
  securityGroup: '',
  reports: [],
  disableInput: false,
  onUpdate: () => {},
};

export default Reporting;
