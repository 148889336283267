import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PlayControlPlayer,
  ProgressBar,
  VibeTooltip,
  VibeIcon,
  viDownload,
  color,
} from 'vibeguide';
import userBubbleImage from '../../../../../assets/user-bubble.png';
import './HistoryAudioItem.scss';

class HistoryAudioItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPercent: 0,
    };
  }

  /**
   * When the time is updated
   */
  onTimeUpdate = (data) => {
    const {
      currentPercent,
    } = data;

    this.setState({
      currentPercent,
    });
  };

  /**
   * When clicking the download icon
   */
  onClickDownload = () => {
    const {
      url,
    } = this.props;

    window.open(url);
  };

  render() {
    const {
      type,
      date,
      url,
      username,
      imageUrl,
      last,
    } = this.props;

    const {
      currentPercent,
    } = this.state;

    const time = new moment(date).format('h:mm A');
    const image = imageUrl || userBubbleImage;

    let typeFormatted = type;

    if (type === 'rough') {
      typeFormatted = 'Rough Cut';
    } else if (type === 'final') {
      typeFormatted = 'Final Cut';
    }

    return (
      <div className="HistoryAudioItem">
        <div className="item-container">
          <div className="item">
            <PlayControlPlayer
              className="left-icon"
              url={url}
              color={color.aquaForest}
              size={24}
              onTimeUpdate={this.onTimeUpdate}
            />

            <VibeIcon
              className="download-icon"
              icon={viDownload}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              tooltip="Download"
              onClick={this.onClickDownload}
            />

            <div className="type-container">
              <div className="type">
                {typeFormatted}
              </div>
            </div>

            <div className="time">
              {time}
            </div>

            <div className="avatar-container">
              <VibeTooltip
                title={username}
                placement="left"
              >
                <img
                  className="avatar"
                  src={image}
                  alt="avatar"
                />
              </VibeTooltip>
            </div>
          </div>

          <ProgressBar
            className="play-progress"
            progress={currentPercent}
            color={color.aquaForest}
            height={4}
          />
        </div>

        {!last ? (
          <div className="item-connector" />
        ) : null}
      </div>
    );
  }
}

HistoryAudioItem.propTypes = {
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  username: PropTypes.string,
  imageUrl: PropTypes.string,
  /** Last item in the history */
  last: PropTypes.bool,
};

HistoryAudioItem.defaultProps = {
  username: null,
  imageUrl: null,
  last: false,
};

export default HistoryAudioItem;
