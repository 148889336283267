import { createAction } from 'redux-actions';

export const setMixData = createAction('SET_MIX_DATA');
export const addLocation = createAction('ADD_MIX_LOCATION');
export const addLocations = createAction('ADD_MIX_LOCATIONS');
export const removeLocation = createAction('REMOVE_MIX_LOCATION');
export const removeAllLocations = createAction('REMOVE_MIX_ALL_LOCATIONS');
export const addMixPlaylist = createAction('ADD_MIX_PLAYLIST');
export const addMixPlaylists = createAction('ADD_MIX_PLAYLISTS');
export const removeMixPlaylist = createAction('REMOVE_MIX_PLAYLIST');
export const updateMixPlaylist = createAction('UPDATE_MIX_PLAYLIST');
export const setAllLocations = createAction('SET_MIX_ALL_LOCATIONS');
export const setMixImage = createAction('SET_MIX_IMAGE');
export const setMixTitle = createAction('SET_MIX_TITLE');
export const setMixDescription = createAction('SET_MIX_DESCRIPTION');
export const setMixCategory = createAction('SET_MIX_CATEGORY');
export const setMixCompany = createAction('SET_MIX_COMPANY');
export const setTags = createAction('SET_MIX_TAGS');
export const addTag = createAction('ADD_MIX_TAG');
export const removeTag = createAction('REMOVE_MIX_TAG');
export const resetNewMix = createAction('RESET_NEW_MIX');

export default {
  setMixData,
  addLocation,
  addLocations,
  removeLocation,
  removeAllLocations,
  addMixPlaylist,
  addMixPlaylists,
  removeMixPlaylist,
  updateMixPlaylist,
  setAllLocations,
  setMixImage,
  setMixTitle,
  setMixDescription,
  setMixCategory,
  setMixCompany,
  setTags,
  addTag,
  removeTag,
  resetNewMix,
};
