import React from 'react';

const viDollar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12.843 17.164c1.236-.193 1.854-.84 1.854-1.941 0-.415-.137-.741-.41-.982-.274-.239-.635-.442-1.085-.606l-.36 3.529zM11.798 6.718c-.345.05-.635.125-.871.226a1.94 1.94 0 0 0-.573.354 1.263 1.263 0 0 0-.32.455 1.41 1.41 0 0 0-.101.531c0 .422.142.764.427 1.024.284.26.651.482 1.1.66l.338-3.25zm1.719 3.893c.36.1.719.21 1.079.327.36.118.705.254 1.039.408.334.153.644.33.932.53.29.201.54.437.753.708.214.272.38.583.5.933.12.351.18.754.18 1.212 0 .679-.12 1.317-.36 1.914a4.664 4.664 0 0 1-1.056 1.588 5.432 5.432 0 0 1-1.708 1.126c-.674.29-1.446.466-2.314.53l-.135 1.33a.823.823 0 0 1-.275.542.898.898 0 0 1-.646.241h-1.281l.224-2.21a10.153 10.153 0 0 1-2.466-.712c-.782-.34-1.444-.75-1.983-1.228l1.079-1.49a1.08 1.08 0 0 1 .365-.306c.154-.083.317-.124.489-.124.18 0 .363.045.55.134.188.09.391.197.613.322.22.126.464.254.73.386.266.132.567.241.904.327l.416-4.043a19.378 19.378 0 0 1-1.612-.504 5.269 5.269 0 0 1-1.444-.783 3.762 3.762 0 0 1-1.04-1.254c-.266-.507-.398-1.144-.398-1.91 0-.564.12-1.114.36-1.65.239-.537.59-1.02 1.05-1.448.46-.43 1.028-.781 1.702-1.057.674-.275 1.445-.434 2.315-.477l.123-1.19a.82.82 0 0 1 .276-.542.898.898 0 0 1 .646-.241h1.28l-.213 2.102c.809.143 1.503.375 2.084.697.581.322 1.073.665 1.478 1.03l-.854 1.19a1.44 1.44 0 0 1-.388.391.867.867 0 0 1-.466.124c-.112 0-.246-.027-.399-.08a8.932 8.932 0 0 1-.5-.194c-.18-.075-.373-.153-.579-.236a6.198 6.198 0 0 0-.657-.22l-.393 3.807z" />
    </g>
  </svg>
);

export default viDollar;
