import React from 'react';

const viFile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M4.929 19.571c-2.121-2.121-2.121-5.657 0-7.778l7.425-7.425c1.555-1.555 4.1-1.555 5.656 0 1.556 1.556 1.556 4.101 0 5.657L12 16.035c-.99.99-2.546.99-3.536 0-.99-.99-.99-2.545 0-3.535l5.304-5.303 1.06 1.06-5.303 5.304c-.424.424-.424.99 0 1.414.424.424.99.424 1.414 0l6.01-6.01c.99-.99.99-2.546 0-3.536-.99-.99-2.545-.99-3.535 0L5.99 12.854c-1.556 1.555-1.556 4.1 0 5.656 1.555 1.556 4.1 1.556 5.656 0l6.718-6.717 1.06 1.06-6.717 6.718c-2.121 2.121-5.657 2.121-7.778 0z" />
    </g>
  </svg>
);

export default viFile;
