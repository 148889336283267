import { handleActions } from 'redux-actions';

/*
 * Reducer handles the state for the library page in the application
 */

export default handleActions(
  {
    CREATE_PLAYLIST: state => {
      return {
        ...state,
        createPlaylist: !state.createPlaylist,
      };
    },
    SEARCH_SONGS: (state, action) => {
      const searchedSongs = action.payload;
      return {
        ...state,
        songResults: searchedSongs,
      };
    },
    GET_SUGGESTED_SONGS: (state, action) => {
      const songResults = action.payload;
      return {
        ...state,
        songResults,
      };
    },
    ADD_SONG_TO_PLAYLIST: (state, action) => {
      const addedSong = action.payload;
      return {
        ...state,
        createdPlaylist: [...state.createdPlaylist, addedSong],
      };
    },
    SHOW_PLAYLIST_DETAILS: state => {
      return {
        ...state,
        playlistDetails: true,
      };
    },
    CLOSE_EDITING_SCREEN: state => {
      return {
        ...state,
        playlistDetails: false,
      };
    },
  },
  // Initial State
  {
    playlistDetails: false,
    songResults: [],
    createdPlaylist: [],
  },
);
