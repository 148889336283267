import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isArray,
} from 'lodash';
import {
  setPanel,
} from '../../actions/Global/GlobalActions';
import {
  group,
} from '../../helpers/Location';
import API from '../../api';
import TableMessageBlocks from '../VibeTable/Tables/TableMessageBlocks';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './MessageBlocksUnassigned.scss';

function MessageBlocksUnassigned({
  className,
  user,
  companyId,
  locations,
  messageBlocks: propMessageBlocks,
  setPanel,
  onUpdate,
}) {
  const [messageBlocks, setMessageBlocks] = useState(propMessageBlocks);
  const [highlightIds, setHighlightIds] = useState(messageBlocks.map(messageBlock => messageBlock._id));

  const isACAF = user.isAllLocations({
    locations,
    companyId,
  });

  // Build location query for search filtering
  const locationData = user.getLocations({
    allLocations: isACAF,
    companyId,
    locations,
  });

  // Group the location spec
  const locationGroup = group(locationData);

  const filters = {
    active: true,
    locations: {
      matchType: 'intersect',
      locations: locationGroup,
    },
  };

  const highlight = highlightIds.map((_id) => {
    return {
      _id,
    };
  });

  /**
   * Close the third panel on the sidebar
   */
  const onClose = () => {
    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * When a row is removed from the assigned table
   */
  const onRemoveItems = (rows) => {
    if (!isArray(rows)) {
      rows = [rows];
    }

    const rowIds = rows.map(row => row._id);
    const newMessageBlocks = messageBlocks.filter(messageBlock => !rowIds.includes(messageBlock._id));

    onUpdate({
      messageBlocks: newMessageBlocks,
      messageLists: newMessageBlocks,
    });

    setHighlightIds(highlightIds.filter(highlightId => !rowIds.includes(highlightId)));
    setMessageBlocks(newMessageBlocks);
  };

  const onAddItems = (rows) => {
    if (!isArray(rows)) {
      rows = [rows];
    }

    const rowIds = rows.map(row => row._id);
    const newMessageBlocks = [...messageBlocks, ...rows];

    onUpdate({
      messageBlocks: newMessageBlocks,
      messageLists: newMessageBlocks,
    });

    setHighlightIds([...highlightIds, ...rowIds]);
    setMessageBlocks(newMessageBlocks);

    document.dispatchEvent(new Event('onUpdateTableAssignedMessageBlocks'));
  };

  return (
    <div className={classNames('MessageBlocksUnassigned', className)}>
      <div className="title-container">
        <div className="title">
          Unassigned Message Blocks
        </div>

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.white}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <div className="message-blocks-container">
        <TableMessageBlocks
          columns={[
            'Add/Remove',
            'Name',
            'Company',
            'Integration',
            'Frequency',
            'Consecutive',
            'Start Type',
            'Sequence',
            'Tags',
            '...',
          ]}
          fetch={API.MessageBlock.list}
          filters={filters}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Message Blocks',
            urlPaging: false,
            urlFilters: false,
          }}
          disableCardView
          available
          highlight={highlight}
          onAdd={onAddItems}
          onRemove={onRemoveItems}
        />
      </div>
    </div>
  );
}

MessageBlocksUnassigned.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
};

MessageBlocksUnassigned.defaultProps = {
  className: '',
  companyId: '',
  locations: [],
  messageBlocks: [],
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlocksUnassigned);
