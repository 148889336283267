import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  color,
} from 'vibeguide';

function ProfileFooter({
  className,
  style,
  children,
}) {
  return (
    <div
      className={classNames('ProfileFooter', className)}
      style={{
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'flex-end',
        bottom: 0,
        height: 32,
        padding: '8px 24px',
        borderTop: `1px solid ${color.whiteSmoke}`,
        backgroundColor: color.white,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

ProfileFooter.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ProfileFooter.defaultProps = {
  className: '',
  style: {},
};

export default ProfileFooter;
