import React from 'react';
import PropTypes from 'prop-types';
import {
  TimeUtil,
  VibeTooltip,
} from 'vibeguide';
import './BaselineSeparatorItem.scss';

function BaselineSeparatorItem({
  baselineSeparator,
}) {
  const itemStart = TimeUtil.convertToTwelveHour({
    time: baselineSeparator.startTime,
    short: true,
  });

  return (
    <VibeTooltip
      placement="right"
      enterDelay={500}
      enterNextDelay={300}
      leaveDelay={500}
      arrow
      title={(
        <div className="BaselineSeparatorItemTooltip">
          <div className="data-row">
            {itemStart}
          </div>

          <div className="data-row title">
            Baseline {baselineSeparator.oldBaselineName} was replaced by {baselineSeparator.newBaselineName}
          </div>
        </div>
      )}
    >
      <div
        className="BaselineSeparatorItem"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: -20,
            left: 0,
            width: 122,
            margin: '0 4px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pointerEvents: 'none',
          }}
        >
          {baselineSeparator.oldBaselineName}
        </div>

        <div
          style={{
            position: 'absolute',
            top: 12,
            left: 0,
            width: 122,
            margin: '0 4px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pointerEvents: 'none',
          }}
        >
          {baselineSeparator.newBaselineName}
        </div>
      </div>
    </VibeTooltip>
  );
}

BaselineSeparatorItem.propTypes = {
  baselineSeparator: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

BaselineSeparatorItem.defaultProps = {
  baselineSeparator: {},
};

export default BaselineSeparatorItem;
