/**
 * Convert Hex color to RGB color
 *
 * @param {string} hex
 * @returns object
 */
export function hexToRGB(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

/**
 * Add transparency to a color
 *
 * @param {string} color
 * @param {integer} percent
 * @returns string
 */
export function transparent(color, percent) {
  switch (percent) {
    case 0:
      return `${color}00`;
    case 1:
      return `${color}03`;
    case 2:
      return `${color}05`;
    case 3:
      return `${color}08`;
    case 4:
      return `${color}0A`;
    case 5:
      return `${color}0D`;
    case 6:
      return `${color}0F`;
    case 7:
      return `${color}12`;
    case 8:
      return `${color}14`;
    case 9:
      return `${color}17`;
    case 10:
      return `${color}1A`;
    case 11:
      return `${color}1C`;
    case 12:
      return `${color}1F`;
    case 13:
      return `${color}21`;
    case 14:
      return `${color}24`;
    case 15:
      return `${color}26`;
    case 16:
      return `${color}29`;
    case 17:
      return `${color}2B`;
    case 18:
      return `${color}2E`;
    case 19:
      return `${color}30`;
    case 20:
      return `${color}33`;
    case 21:
      return `${color}36`;
    case 22:
      return `${color}38`;
    case 23:
      return `${color}3B`;
    case 24:
      return `${color}3D`;
    case 25:
      return `${color}40`;
    case 26:
      return `${color}42`;
    case 27:
      return `${color}45`;
    case 28:
      return `${color}47`;
    case 29:
      return `${color}4A`;
    case 30:
      return `${color}4D`;
    case 31:
      return `${color}4F`;
    case 32:
      return `${color}52`;
    case 33:
      return `${color}54`;
    case 34:
      return `${color}57`;
    case 35:
      return `${color}59`;
    case 36:
      return `${color}5C`;
    case 37:
      return `${color}5E`;
    case 38:
      return `${color}61`;
    case 39:
      return `${color}63`;
    case 40:
      return `${color}66`;
    case 41:
      return `${color}69`;
    case 42:
      return `${color}6B`;
    case 43:
      return `${color}6E`;
    case 44:
      return `${color}70`;
    case 45:
      return `${color}73`;
    case 46:
      return `${color}75`;
    case 47:
      return `${color}78`;
    case 48:
      return `${color}7A`;
    case 49:
      return `${color}7D`;
    case 50:
      return `${color}80`;
    case 51:
      return `${color}82`;
    case 52:
      return `${color}85`;
    case 53:
      return `${color}87`;
    case 54:
      return `${color}8A`;
    case 55:
      return `${color}8C`;
    case 56:
      return `${color}8F`;
    case 57:
      return `${color}91`;
    case 58:
      return `${color}94`;
    case 59:
      return `${color}96`;
    case 60:
      return `${color}99`;
    case 61:
      return `${color}9C`;
    case 62:
      return `${color}9E`;
    case 63:
      return `${color}A1`;
    case 64:
      return `${color}A3`;
    case 65:
      return `${color}A6`;
    case 66:
      return `${color}A8`;
    case 67:
      return `${color}AB`;
    case 68:
      return `${color}AD`;
    case 69:
      return `${color}B0`;
    case 70:
      return `${color}B3`;
    case 71:
      return `${color}B5`;
    case 72:
      return `${color}B8`;
    case 73:
      return `${color}BA`;
    case 74:
      return `${color}BD`;
    case 75:
      return `${color}BF`;
    case 76:
      return `${color}C2`;
    case 77:
      return `${color}C4`;
    case 78:
      return `${color}C7`;
    case 79:
      return `${color}C9`;
    case 80:
      return `${color}CC`;
    case 81:
      return `${color}CF`;
    case 82:
      return `${color}D1`;
    case 83:
      return `${color}D4`;
    case 84:
      return `${color}D6`;
    case 85:
      return `${color}D9`;
    case 86:
      return `${color}DB`;
    case 87:
      return `${color}DE`;
    case 88:
      return `${color}E0`;
    case 89:
      return `${color}E3`;
    case 90:
      return `${color}E6`;
    case 91:
      return `${color}E8`;
    case 92:
      return `${color}EB`;
    case 93:
      return `${color}ED`;
    case 94:
      return `${color}F0`;
    case 95:
      return `${color}F2`;
    case 96:
      return `${color}F5`;
    case 97:
      return `${color}F7`;
    case 98:
      return `${color}FA`;
    case 99:
      return `${color}FC`;
    case 100:
      return `${color}FF`;
    default:
      return color;
  }
}

export default {
  hexToRGB,
  transparent,
};
