import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  sortBy,
  isUndefined,
} from 'lodash';
import {
  API,
  GlobalActions,
  LocationHelper,
  TimeUtil,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  DaySelector,
  Drawer,
  VibeButtonNew,
  VibeIcon,
  viClose,
  viCopy,
  color,
} from 'vibeguide';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DisplayOptions from '../../../Locations/IndividualLocation/Schedule/Controls/DisplayOptions';
import ScheduleHeader from '../../../Locations/IndividualLocation/Schedule/ScheduleHeader';
import ScheduleData from '../../../Locations/IndividualLocation/Schedule/ScheduleData';
import './BaselineScheduleDay.scss';

function BaselineScheduleDay({
  className,
  user,
  day,
  activeDisplay: propsActiveDisplay,
  locations,
  playlists: propPlaylists,
  mixes: propMixes,
  messageBlocks: propMessageBlocks,
  interruptions: propInterruptions,
  onChangeDisplay: propsOnChangeDisplay,
  onUpdateItems: propsOnUpdateItems,
  onCopyDay,
  setPanel,
}) {
  const [activeDisplay, setActiveDisplay] = useState(propsActiveDisplay);
  const [showCopyDay, setShowCopyDay] = useState(false);
  const [copyToDays, setCopyToDays] = useState([]);
  const [playlists, setPlaylists] = useState(sortBy(propPlaylists, 'startTime'));
  const [mixes, setMixes] = useState(sortBy(propMixes, 'startTime'));
  const [messageBlocks, setMessageBlocks] = useState(sortBy(propMessageBlocks, 'startTime'));
  const [interruptions, setInterruptions] = useState(sortBy(propInterruptions, 'startTime'));
  const copyDayRef = useRef(null);

  const drawerItems = [];
  // Group the location spec
  const group = LocationHelper.group(locations);

  if (user.can('playlist.view')) {
    drawerItems.push({
      label: 'Playlists',
      fetch: API.Playlist.list,
      filters: {
        locations: {
          matchType: 'contains',
          locations: group,
        },
      },
    });
  }

  if (user.can('mix.view')) {
    drawerItems.push({
      label: 'Stations',
      fetch: API.Mix.list,
      filters: {
        locations: {
          matchType: 'contains',
          locations: group,
        },
      },
    });
  }

  if (user.can('messagelist.view')) {
    drawerItems.push({
      label: 'Message Blocks',
      fetch: API.MessageBlock.list,
      filters: {
        locations: {
          matchType: 'contains',
          locations: group,
        },
      },
    });
  }

  if (user.can('message.view')) {
    drawerItems.push({
      label: 'Interrupts',
      fetch: API.Message.list,
      filters: {
        locations: {
          matchType: 'intersect',
          locations: group,
        },
      },
    });
  }

  if (user.can('message.view') && user.can('advertisement.view')) {
    drawerItems.push({
      label: 'Ads',
      fetch: API.Message.list,
      filters: {
        messageType: 'ad',
        locations: {
          matchType: 'intersect',
          locations: group,
        },
      },
    });
  }

  const onChangeDisplay = (display) => {
    setActiveDisplay(display);

    // tell parent component the display changed
    propsOnChangeDisplay(display);
  };

  const onClose = () => {
    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * When items are updated in the schedule
   */
  const onUpdateItems = (newItems, type) => {
    // format the day to the short abbrev.
    const dayDDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'ddd');
    const newPlaylists = newItems.filter(item => !isUndefined(item.playlist));
    const newMixes = newItems.filter(item => !isUndefined(item.mix));
    const newMessageBlocks = newItems.filter(item => !isUndefined(item.messageBlock));
    const newInterruptions = newItems.filter(item => !isUndefined(item.message));

    if (type === 'Music') {
      // update playlists/stations in the schedule for the modified day
      const mergePlaylists = sortBy([
        ...playlists.filter(playlist => playlist.day !== dayDDD),
        ...newPlaylists.map((playlist) => {
          return {
            ...playlist,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      const mergeMixes = sortBy([
        ...mixes.filter(mix => mix.day !== dayDDD),
        ...newMixes.map((mix) => {
          return {
            ...mix,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      setPlaylists(mergePlaylists);
      setMixes(mergeMixes);
      propsOnUpdateItems([
        ...mergePlaylists,
        ...mergeMixes,
      ], type, day);
    } else if (type === 'Message Blocks') {
      // update message blocks in the schedule for the modified day
      const mergeMessageBlocks = sortBy([
        ...messageBlocks.filter(messageBlock => messageBlock.day !== dayDDD),
        ...newMessageBlocks.map((messageBlock) => {
          return {
            ...messageBlock,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      setMessageBlocks(mergeMessageBlocks);
      propsOnUpdateItems(mergeMessageBlocks, type, day);
    } else if (type === 'Interrupts') {
      // update interrupts in the schedule for the modified day
      const mergeInterrupts = sortBy([
        ...interruptions.filter(interrupt => interrupt.day !== dayDDD),
        ...newInterruptions.map((interrupt) => {
          return {
            ...interrupt,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      setInterruptions(mergeInterrupts);
      propsOnUpdateItems(mergeInterrupts, type, day);
    }
  };

  /**
   * When the copy day button is clicked
   */
  const onToggleCopyDay = () => {
    setShowCopyDay(!showCopyDay);
  };

  /**
   * When the selected days are changed
   */
  const onChangeCopyDays = (copyDays) => {
    setCopyToDays(copyDays);
  };

  /**
   * Copy the day items to the selected days
   */
  const onClickSaveCopyDays = () => {
    onCopyDay({
      from: day,
      to: copyToDays,
      playlists,
      mixes,
      messageBlocks,
      interruptions,
    });

    setCopyToDays([]);
    onToggleCopyDay();
    onClose();
  };

  return (
    <SidePanelContainer className={classNames('BaselineScheduleDay', className)}>
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div
          className="details-header"
          style={{
            width: '100%',
          }}
        >
          <div className="flex-horizontal">
            <div
              className="title"
              style={{
                flexGrow: 1,
              }}
            >
              {day}
            </div>

            <div
              ref={copyDayRef}
              style={{
                marginRight: 16,
              }}
            >
              <VibeButtonNew
                text="Copy Day"
                color={color.violetVibe}
                icon={viCopy}
                iconProps={{
                  style: {
                    padding: 0,
                  },
                }}
                disabled={!user.can('baseline.modify')}
                onClick={onToggleCopyDay}
              />
            </div>

            <Popper
              className="BaselineScheduleDayCopyPopper"
              anchorEl={copyDayRef.current}
              open={showCopyDay}
              placement="bottom"
              style={{
                zIndex: 1302,
              }}
            >
              <ClickAwayListener onClickAway={onToggleCopyDay}>
                <div className="container">
                  <div className="content">
                    <DaySelector
                      toggleAll
                      onChange={onChangeCopyDays}
                    />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 8,
                      }}
                    >
                      <VibeButtonNew
                        text="Save"
                        color={color.violetVibe}
                        selectedDays={copyToDays}
                        disabled={!user.can('baseline.modify') || copyToDays.length <= 0}
                        onClick={onClickSaveCopyDays}
                      />
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </Popper>

            <DisplayOptions
              activeDisplay={activeDisplay}
              onChangeDisplay={onChangeDisplay}
            />
          </div>
        </div>
      </SidePanelHeader>

      <SidePanelContent className="sidepanel-content">
        <ScheduleHeader
          type="baseline"
        />

        <ScheduleData
          type="schedule-day"
          style={{
            height:
              activeDisplay === '24h'
                ? 'calc(100% - 65px)'
                : 'auto',
          }}
          activeDisplay={activeDisplay}
          startOfWeek={moment().startOf('week')}
          playlists={playlists}
          mixes={mixes}
          messageBlocks={messageBlocks}
          interrupts={interruptions}
          onUpdateItems={onUpdateItems}
        />

        <Drawer
          items={drawerItems}
        />
      </SidePanelContent>

      {/* Need the footer to offset the drawer height */}
      <SidePanelFooter className="sidepanel-footer" />
    </SidePanelContainer>
  );
}

BaselineScheduleDay.propTypes = {
  className: PropTypes.string,
  /** Day of the week currently being shown */
  day: PropTypes.string.isRequired,
  activeDisplay: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interruptions: PropTypes.arrayOf(PropTypes.object),
  onChangeDisplay: PropTypes.func,
  onUpdateItems: PropTypes.func,
  onCopyDay: PropTypes.func,
};

BaselineScheduleDay.defaultProps = {
  className: '',
  activeDisplay: '24h',
  locations: [],
  playlists: [],
  mixes: [],
  messageBlocks: [],
  interruptions: [],
  onChangeDisplay: () => {},
  onUpdateItems: () => {},
  onCopyDay: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaselineScheduleDay);
