import React from 'react';

const viEqualizer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M9 2v2H2v2h7v1.999h2V2H9zm3 4h10V4H12v2zm1 3v2H2v2h11v2h1.999V9H13zm3 4h5.999v-2H16v2zm-9 2.999V18H2v2h5v2h2v-6.001H7zM10 20h12v-2H10v2z" />
    </g>
  </svg>
);

export default viEqualizer;
