import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import rootReducer from '../../reducers';

function buildMiddleware() {
  const middleware = [];
  const nodeEnv = process.env.NODE_ENV;
  const production = nodeEnv === 'production';

  middleware.push(applyMiddleware(thunkMiddleware));
  middleware.push(applyMiddleware(promiseMiddleware));

  if (!production) {
    middleware.push(applyMiddleware(logger));
  }

  if (!production && typeof window !== 'undefined') {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      // Add dev tools, if present
      middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  return middleware;
}

export const store = createStore(
  rootReducer,
  compose(...buildMiddleware()),
);

function Wrapper({
  children,
}) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}

export default Wrapper;
