import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  find,
  orderBy,
} from 'lodash';
import {
  API,
  TimeUtil,
  GlobalActions,
  LocationHelper,
  ExpandedLocationsHelper,
  LoadingContent,
} from 'vibeguide';
import BaselineDetails from './BaselineDetails';
import './BaselineSidebar.scss';

class BaselineSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      baseline: {},
    };
  }

  componentDidMount() {
    const {
      baselineId,
    } = this.props;

    if (baselineId && baselineId !== 'new') {
      this.getBaseline();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      baselineId,
      isNew,
    } = this.props;

    const {
      baselineId: prevBaselineId,
    } = prevProps;

    if (baselineId && !isNew && baselineId !== prevBaselineId) {
      this.getBaseline();
    }
  }

  /**
   * Update the baseline
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        baseline: {
          ...state.baseline,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtraPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  getBaseline = async () => {
    const {
      baselineId,
    } = this.props;

    const baseline = await API.Baseline.getById(baselineId);
    const expandedLocations = await API.Baseline.getExpandedLocations(baselineId);

    if (baseline.baseline) {
      // Set the location data
      baseline.baseline.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);
      // move the refs to inside the baseline object
      baseline.baseline.refs = baseline.refs;
    }

    const {
      playlists,
      mixes,
      messageBlocks,
      interruptions,
    } = this.getBaselineData(baseline.baseline);

    this.setState({
      baseline: {
        ...baseline.baseline,
        data: {
          playlists,
          mixes,
          messageBlocks,
          interruptions,
        },
      },
    });
  };

  /**
   * Get Baseline Data in their own object arrays
   */
  getBaselineData = (baseline) => {
    const {
      playlists: playlistRefs,
      mixes: mixRefs,
      messageBlocks: msgBlockRefs,
      interrupts: interruptRefs,
    } = baseline.refs;

    const interruptions = [];
    const messageBlocks = [];
    const playlists = [];
    const mixes = [];

    if (baseline.days.length > 0) {
      baseline.days.forEach((items, dayIndex) => {
        const {
          interrupts = [],
          messageParts = [],
          musicParts = [],
        } = items;

        const day = TimeUtil.getDayFromIndex(dayIndex, 'ddd');

        if (!day) {
          return;
        }

        interrupts.forEach((interrupt) => {
          const interruptDetail = find(interruptRefs, ref => interrupt.messageId === ref._id);
          const compareResult = LocationHelper.compare(
            get(baseline, 'locations', []),
            get(interruptDetail, 'locations', []),
          );
          // Determine if the interrupt has access to all the locations for this object
          const accessAllLocations = compareResult.parent.missing.length <= 0;

          interruptions.push({
            startTime: interrupt.startTime,
            message: interruptDetail,
            accessAllLocations,
            day,
          });
        });

        messageParts.forEach((messageBlock) => {
          const mbDetail = find(msgBlockRefs, ref => messageBlock.messageListId === ref._id);

          if (get(mbDetail, 'messages.length', 0) > 0) {
            mbDetail.messages = orderBy(mbDetail.messages, 'sequence');
          }

          messageBlocks.push({
            messageBlock: mbDetail,
            startTime: messageBlock.startTime,
            stopTime: messageBlock.endTime,
            day,
          });
        });

        musicParts.forEach((music) => {
          if (music.type === 'playlist') {
            const playlistDetail = find(playlistRefs, ref => music.musicId === ref._id);
            playlists.push({
              playlist: playlistDetail,
              startTime: music.startTime,
              stopTime: music.endTime,
              day,
            });
          } else if (music.type === 'mix') {
            const mixDetail = find(mixRefs, ref => music.musicId === ref._id);
            mixes.push({
              mix: mixDetail,
              startTime: music.startTime,
              stopTime: music.endTime,
              day,
            });
          }
        });
      });
    }

    return {
      playlists,
      mixes,
      messageBlocks,
      interruptions,
    };
  };

  render() {
    const {
      baselineId,
      isNew,
    } = this.props;

    const {
      baseline,
    } = this.state;

    return (
      <div className="BaselineSidebar">
        <BaselineDetails
          baseline={baseline}
          isNew={isNew}
          onClose={this.onClose}
          onCloseExtraPanel={this.onCloseExtraPanel}
          onUpdate={this.onUpdate}
          onRefresh={this.getBaseline}
        />

        {baselineId && !baseline._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

BaselineSidebar.propTypes = {
  baselineId: PropTypes.string,
  isNew: PropTypes.bool,
};

BaselineSidebar.defaultProps = {
  baselineId: null,
  isNew: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(BaselineSidebar);
