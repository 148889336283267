import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlayControl from '../../PlayControl/PlayControl';
import CellTooltip from './CellTooltip';
import VibeIcon from '../../VibeIcon/VibeIcon';
import rootColor from '../../../sass/color.scss';
import './AudioCell.scss';

function AudioCell({
  className,
  id,
  name,
  src,
  durationSeconds,
  color,
  playText,
  pauseText,
  icon,
  disabled,
}) {
  return (
    <div className={classNames('AudioCell', className)}>
      <PlayControl
        id={id}
        name={name}
        src={src}
        color={color}
        durationSeconds={durationSeconds}
        playText={playText}
        pauseText={pauseText}
        disabled={disabled}
      />

      {icon ? (
        <VibeIcon
          className="secondary-icon"
          icon={icon}
          color={rootColor.aquaForest}
          size={12}
        />
      ) : null}

      <CellTooltip title={name}>
        <div className="audio-name">
          {name}
        </div>
      </CellTooltip>
    </div>
  );
}

AudioCell.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  src: PropTypes.string,
  color: PropTypes.string,
  durationSeconds: PropTypes.number,
  playText: PropTypes.string,
  pauseText: PropTypes.string,
  icon: PropTypes.func,
  disabled: PropTypes.bool,
};

AudioCell.defaultProps = {
  className: '',
  name: '',
  src: '',
  color: '#6d6db5',
  durationSeconds: 0,
  playText: 'Play',
  pauseText: 'Pause',
  icon: null,
  disabled: false,
};

export default AudioCell;
