import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import Tag from './Tag';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viTag from '../../icons/viTag';
import color from '../../sass/color.scss';
import './TagList.scss';

class TagList extends PureComponent {
  render() {
    const {
      style,
      tags,
      textColor,
      tagColor,
      hideIcon,
    } = this.props;

    const tagList = (tags || []).map((tag, index) => {
      // When the tag is null
      if (!tag) {
        return null;
      }

      // Hide removed tags from the list under the input
      if (get(tag, 'status') === 'remove') {
        return null;
      }

      return (
        <Tag
          key={`tag-${index}`}
          tag={tag}
          textColor={textColor}
          style={{
            background: tagColor,
            color: textColor,
          }}
        />
      );
    });

    const noTagsHtml = !hideIcon ? (
      <div className="no-tags">
        No Tags
      </div>
    ) : null;

    const tagsHtml = (
      <div className="tags-list">
        {!hideIcon ? (
          <div className="tags-icon-container">
            <VibeIcon
              type="button"
              icon={viTag}
              color={textColor}
              size={16}
              buttonProps={{
                size: 24,
                color: color.white,
                borderColor: color.whiteSmoke,
              }}
            />
          </div>
        ) : null}

        {tags.length > 0
          ? tagList
          : noTagsHtml}
      </div>
    );

    // Whether or not to display a tooltip
    const tagsContainerHtml = tags.length > 0 ? (
      <VibeTooltip
        title={(
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 8,
              fontSize: 10,
            }}
          >
            {tagList}
          </div>
        )}
        enterDelay={500}
        placement="top"
      >
        {tagsHtml}
      </VibeTooltip>
    ) : tagsHtml;

    return (
      <div
        className="TagList"
        style={style}
      >
        {tagsContainerHtml}
      </div>
    );
  }
}

TagList.propTypes = {
  /** TagList data */
  tags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  textColor: PropTypes.string,
  tagColor: PropTypes.string,
  /** Hide the tag list icon */
  hideIcon: PropTypes.bool,
};

TagList.defaultProps = {
  tags: [],
  style: {},
  textColor: '#959aad',
  tagColor: 'rgba(149, 154, 173, 0.16)',
  hideIcon: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(TagList);
