import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import { Close, Check, Help } from '@mui/icons-material';
import {
  locations as searchByLocations,
} from '../../helpers/SearchBy';
import {
  locations as sortByLocations,
} from '../../helpers/SortBy';
import Cards from '../Cards/Cards';
import CompanyCard from '../CompanyCard/CompanyCard';
import LocationCard from '../LocationCard/LocationCard';
import Paginator from '../Paginator/Paginator';
import PageLayout from '../PageLayout/PageLayout';
import Field from '../Field/Field';
import VibeButton from '../VibeButton/VibeButton';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCheckCircleOutline from '../../icons/viCheckCircleOutline';
import color from '../../sass/color.scss';
import './Locations.scss';

class Locations extends Component {
  constructor(props) {
    super(props);

    const {
      selectAll,
    } = props;

    this.state = {
      locations: [],
      companies: [],
      totalItems: 0,
      isCompanies: false,
      selectAll,
    };

    this._scrollRef = null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectAll,
    } = this.props;

    const {
      selectAll: prevSelectAll,
    } = prevProps;

    if (selectAll !== prevSelectAll) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectAll,
      });
    }
  }

  onChangePaginatorLabel = (labelName) => {
    const isCompanies = labelName === 'Companies';

    this.setState({
      isCompanies,
    });
  };

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    this.setState((state) => {
      const {
        isCompanies,
      } = state;

      return {
        locations: isCompanies ? [] : collection,
        companies: isCompanies ? collection : [],
        totalItems,
      };
    });
  };

  toggleSelected = (locationId, selected) => {
    const {
      onSelect,
      onDeselect,
    } = this.props;

    const {
      locations,
      companies,
      isCompanies,
    } = this.state;

    if (selected && !isCompanies) {
      // Selected a Location
      const location = find(locations, { _id: locationId });

      if (location) {
        onSelect({
          companyId: location.companyId,
          locationId,
        });
      } else {
        console.warn('Location not found...', locationId);
      }
    } else if (!selected && !isCompanies) {
      // Deselected a Location
      const location = find(locations, { _id: locationId });

      if (location) {
        onDeselect({
          companyId: location.companyId,
          locationId,
        });
      } else {
        console.warn('Location not found', locationId);
      }
    } else if (selected && isCompanies) {
      // Selected a Company
      const company = find(companies, { _id: locationId });

      if (company) {
        onSelect({
          companyId: company._id,
          locationId: '*',
        });
      } else {
        console.warn('Company not found...', locationId);
      }
    } else if (!selected && isCompanies) {
      // Deselected a Company
      const company = find(companies, { _id: locationId });

      if (company) {
        onDeselect({
          companyId: company._id,
          locationId: '*',
        });
      } else {
        console.warn('Company not found', locationId);
      }
    }
  };

  toggleAllCurrentAndFuture = () => {
    const {
      onSelectAll,
    } = this.props;

    this.setState((state) => {
      const selectAll = !state.selectAll;

      onSelectAll(selectAll);

      return {
        selectAll,
      };
    });
  };

  toggleSelectAll = () => {
    const {
      selectedLocations,
      onSelectMultiple,
      onDeselectMultiple,
    } = this.props;

    const {
      locations,
      companies,
      isCompanies,
    } = this.state;

    if (!isCompanies) {
      // Select All Locations
      const selected = locations.filter(location => {
        return find(selectedLocations, { locationId: location._id });
      });

      const isAllSelected = locations.length === selected.length;
      const locationData = locations.map(location => {
        return {
          companyId: location.companyId,
          locationId: location._id,
        };
      });

      if (isAllSelected) {
        // All are selected, deselect
        onDeselectMultiple(locationData);
      } else {
        // All are not selected, select
        onSelectMultiple(locationData);
      }
    } else {
      // Select All Companies
      const selected = companies.filter(company => {
        return find(selectedLocations, { companyId: company._id, locationId: '*' });
      });

      const isAllSelected = companies.length === selected.length;
      const locationData = companies.map(company => {
        return {
          companyId: company._id,
          locationId: '*',
        };
      });

      if (isAllSelected) {
        // All are selected, deselect
        onDeselectMultiple(locationData);
      } else {
        // All are not selected, select
        onSelectMultiple(locationData);
      }
    }

    console.warn('Select All Toggled');
  };

  render() {
    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const {
      className,
      user,
      selectedCompanyId,
      selectedLocations,
      availableLocations,
      filterLocations,
      filterCompanies,
      allowClick,
      allowCompanies,
      showAllToggle,
      showSelectAll,
      showPaginator,
      required,
    } = this.props;

    const {
      locations,
      companies,
      totalItems,
      selectAll,
      isCompanies,
    } = this.state;

    const paginatorLabel = user.sysAdmin && allowCompanies && selectedCompanyId === adminCompanyId
      ? ['Individual Locations', 'Companies']
      : 'Locations';

    const locationCards = [];
    const companyCards = [];
    let canSelectAllCurrentAndFuture = false;
    let assignedLocations = locations;

    if (get(user, 'locationsAllowed.length', 0) === 1) {
      // Check if the user can set all current and future locations
      const firstCompanyId = get(user, 'locationsAllowed[0].companyId', null);
      const firstLocationId = get(user, 'locationsAllowed[0].locationId', null);

      if (firstCompanyId === '*' || firstLocationId === '*') {
        // User can toggle all
        canSelectAllCurrentAndFuture = true;
      }
    }

    if (availableLocations.length > 0) {
      // Display only the locations a user has access to and the baseline is assigned to.
      assignedLocations = availableLocations.some(loc => loc.companyId === '*')
        ? locations
        : locations.filter(
          availableLocations.map(l => {
            return l.locationId === '*'
              ? loc => loc.companyId === l.companyId
              : loc => loc.companyId === l.companyId && loc._id === l.locationId;
          }).reduce((agg, f) => loc => agg(loc) || f(loc)));
    }

    assignedLocations.forEach((location, index) => {
      const isSelected = selectAll
        || find(selectedLocations, { locationId: location._id }) !== undefined;

      const locationData = {
        id: location._id,
        imageUrl: location.imageUrl,
        companyId: location.companyId,
        companyName: location.companyName,
        name: location.name,
        location: location.city ? `${location.city}, ${location.state}` : location.state,
      };

      locationCards.push(
        <LocationCard
          key={index}
          toggleSelected={this.toggleSelected}
          disabled={selectAll}
          selected={isSelected}
          allowClick={allowClick}
          style={{
            marginBottom: 20,
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...locationData}
        />,
      );
    });

    companies.forEach((company, index) => {
      const isSelected = selectAll
        || find(selectedLocations, { companyId: company._id, locationId: '*' }) !== undefined;

      companyCards.push(
        <CompanyCard
          key={index}
          company={company}
          toggleSelected={this.toggleSelected}
          disabled={selectAll}
          selected={isSelected}
          allowClick={allowClick}
        />,
      );
    });

    // Check All Selected
    let isAllSelected;

    if (!isCompanies) {
      // Check all selected on locations
      const selected = locations.filter(location => {
        return find(selectedLocations, { locationId: location._id });
      });

      isAllSelected = locations.length === selected.length;
    } else {
      // Check all selected on companies
      const selected = companies.filter(company => {
        return find(selectedLocations, { companyId: company._id, locationId: '*' });
      });

      isAllSelected = companies.length === selected.length;
    }

    const cards = isCompanies ? companyCards : locationCards;

    return (
      <div className={classNames('Locations', className)}>
        <Grid container>
          <Grid item xs={12}>
            <PageLayout
              sortOptions={sortByLocations}
              searchOptions={searchByLocations}
              scrollRef={this._scrollRef}
              toggleLabel="Show Archived Locations"
              filter={!isCompanies ? filterLocations : filterCompanies}
              filterProps={{
                fetchFilters: {
                  _id: user._id,
                },
              }}
              selectedCompanyId={!isCompanies ? selectedCompanyId : null}
              onFilter={this.onFilter}
              showToggle
              disableView
            />
          </Grid>
        </Grid>

        <div className="location-content">
          <PerfectScrollbar containerRef={(ref) => { this._scrollRef = ref; }}>
            <Grid
              className="location-container"
              container
            >
              <Grid
                xs={12}
                item
              >
                <div className="location-toolbar">
                  {showPaginator ? (
                    <Paginator
                      className="location-paginator"
                      totalLoaded={!isCompanies ? assignedLocations.length : companies.length}
                      totalItems={totalItems}
                      label={paginatorLabel}
                      onLabelChange={this.onChangePaginatorLabel}
                    />
                  ) : null}

                  <div className="toolbar-right">
                    <div className="toolbar-right-items">
                      {required ? (
                        <Field
                          type="hidden"
                          label="Locations"
                          value={selectAll || selectedLocations.length > 0 ? 'has locations' : ''}
                          requiredLabel="At least 1 location is required"
                          requiredLabelSize={14}
                          required
                        />
                      ) : null}

                      {selectAll || selectedLocations.length > 0 ? (
                        <div className="selected-count-container">
                          <div>
                            {selectAll ? 'All' : selectedLocations.length} Selected
                          </div>

                          <VibeIcon
                            icon={viCheckCircleOutline}
                            color={color.aquaForest}
                            size={24}
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        </div>
                      ) : null}

                      {showSelectAll ? (
                        <div className="select-container">
                          <VibeButton
                            text={isAllSelected ? 'Deselect All' : 'Select All'}
                            btnColor="green"
                            textColor="white"
                            icon={isAllSelected ? (
                              <Close className="select-icon" />
                            ) : (
                              <Check className="select-icon" />
                            )}
                            iconPlacement="left"
                            disabled={selectAll}
                            onClick={this.toggleSelectAll}
                          />
                        </div>
                      ) : null}

                      {showAllToggle && canSelectAllCurrentAndFuture ? (
                        <div className="all-current-future-container">
                          <Help className="help-icon" />

                          <p className="current-future-title">
                            Set All Current and Future
                          </p>

                          <div className="toggle-container">
                            <label htmlFor="all-locations" className="switch">
                              <input
                                id="all-locations"
                                className="switch__input"
                                type="checkbox"
                                onChange={this.toggleAllCurrentAndFuture}
                                checked={selectAll}
                              />
                              <div className="switch__checkbox" />
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <Cards>
                  {cards}
                </Cards>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

Locations.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Selected company ID for the object being interracted with */
  selectedCompanyId: PropTypes.string,
  /** Selected locations */
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Available locations (used for things like baseline activation) */
  availableLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Are all locations selected */
  selectAll: PropTypes.bool,
  allowClick: PropTypes.bool,
  /** Allow companies to be selected */
  allowCompanies: PropTypes.bool,
  showAllToggle: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  showPaginator: PropTypes.bool,
  /** At least 1 location is required */
  required: PropTypes.bool,
  /** Filter locations function */
  filterLocations: PropTypes.func,
  /** Filter companies function */
  filterCompanies: PropTypes.func,
  /** When the select all locations is toggled */
  onSelectAll: PropTypes.func,
  /** When a location is selected */
  onSelect: PropTypes.func,
  /** When multiple locations are selected */
  onSelectMultiple: PropTypes.func,
  /** When multiple locations are deselected */
  onDeselectMultiple: PropTypes.func,
  /** When a location is deselected */
  onDeselect: PropTypes.func,
};

Locations.defaultProps = {
  className: null,
  selectedCompanyId: null,
  selectedLocations: [],
  availableLocations: [],
  selectAll: false,
  allowClick: false,
  allowCompanies: false,
  showAllToggle: false,
  showSelectAll: false,
  showPaginator: false,
  required: false,
  filterLocations: () => {},
  filterCompanies: () => {},
  onSelectAll: () => {},
  onSelect: () => {},
  onSelectMultiple: () => {},
  onDeselect: () => {},
  onDeselectMultiple: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Locations);
