import React from 'react';

const viAdd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
    </g>
  </svg>
);

export default viAdd;
