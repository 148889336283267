import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  API,
  TablePlaylists,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import './SongPlaylists.scss';

class SongPlaylists extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      playlists: [],
    };
  }

  componentDidMount() {
    this.getPlaylists();
  }

  getPlaylists = async () => {
    const {
      songId,
    } = this.props;

    const playlists = await API.Music.getPlaylists({
      _id: songId,
    });

    this.setState({
      playlists,
    });
  };

  render() {
    const {
      onClose,
    } = this.props;

    const {
      playlists,
    } = this.state;

    return (
      <div className="SongPlaylists">
        <div className="title-container">
          <div className="title">
            Assigned Playlists
          </div>

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        </div>

        <TablePlaylists
          columns={[
            'Name',
            'Description',
            'Company',
            'Songs',
            'Duration',
            'Avg. Song',
            'Genre',
            'Tags',
            '...',
          ]}
          collection={playlists}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Playlists',
            urlPaging: false,
            urlFilters: false,
          }}
          menuItems={[
            { name: 'Archive', userCan: 'playlist.delete' },
          ]}
        />
      </div>
    );
  }
}

SongPlaylists.propTypes = {
  songId: PropTypes.string,
  onClose: PropTypes.func,
};

SongPlaylists.defaultProps = {
  songId: null,
  onClose: () => {},
};

export default SongPlaylists;
