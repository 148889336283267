/**
 * List of supported External ID sources
 */
export const list = [
  {
    label: 'Harmony',
    value: 'harmony',
  },
  {
    label: 'Stingray',
    value: 'stingray',
  },
];

export default {
  list,
};
