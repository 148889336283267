import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  find,
  head,
  kebabCase,
} from 'lodash';
import withRouter from '../../wrappers/withRouter';
import {
  submenuItemType,
} from '../../types/submenuItemType';
import './Submenu.scss';

function Submenu({
  rootUrl,
  style,
  items: allItems,
  matchParameter,
  params,
  location: {
    pathname: matchUrl,
  },
  onClickItem,
  onChangeActiveSubmenuItem,
}) {
  const getActiveItem = () => {
    const param = params[matchParameter] || params.type;

    // Match the item with the URL paramater of :type
    return allItems.filter(item => param === kebabCase(item.name) && item.enabled && item.show)[0]
      || find(allItems, { enabled: true, show: true })
      || head(allItems);
  };

  const onClickMenuItem = (e) => {
    const {
      target: {
        dataset,
      },
    } = e;

    onClickItem(dataset);
  };

  useEffect(() => {
    onChangeActiveSubmenuItem(getActiveItem());
  }, [matchParameter, params]);

  // Get the items this user can use
  const items = allItems.filter(item => item.show === true);
  const activeItem = getActiveItem();

  // Which URL to use (default to rootUrl)
  const url = rootUrl || matchUrl;

  return (
    <div
      className="Submenu"
      style={style}
    >
      {items.map((item) => {
        const itemLink = `${url}/${kebabCase(item.name)}`;

        return item.enabled ? (
          <Link
            key={item.name}
            className={classNames('item', { active: item.name === activeItem.name })}
            to={itemLink}
            data-name={item.name}
            onClick={onClickMenuItem}
          >
            {item.label || item.name}
          </Link>
        ) : (
          <div
            key={item.name}
            className="item disabled"
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
}

Submenu.propTypes = {
  /** Root URL to link to */
  rootUrl: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Match this parameter (usually "type") */
  matchParameter: PropTypes.string,
  items: PropTypes.arrayOf(submenuItemType).isRequired,
  onClickItem: PropTypes.func,
  onChangeActiveSubmenuItem: PropTypes.func,
};

Submenu.defaultProps = {
  rootUrl: null,
  style: {},
  matchParameter: '',
  onClickItem: () => {},
  onChangeActiveSubmenuItem: () => {},
};

export default withRouter(Submenu);
