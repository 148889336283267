import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  VibeIcon,
  viChevronLeft,
  viChevronRight,
  color,
} from 'vibeguide';
import './WeekNavigation.scss';

function WeekNavigation({
  className,
  disabled,
  startOfWeek,
  endOfWeek,
  onChangeWeek,
}) {
  const weekDatesStart = startOfWeek.format('MMMM DD');
  const weekDatesEnd = endOfWeek.format('MMMM DD, YYYY');
  const weekDates = `${weekDatesStart} - ${weekDatesEnd}`;

  return (
    <div className={classNames('WeekNavigation', className)}>
      <VibeIcon
        icon={viChevronLeft}
        color={color.manatee}
        hoverColor={color.obsidian}
        tooltip="Previous Week"
        tooltipProps={{
          placement: 'top',
        }}
        disabled={disabled}
        size={24}
        onClick={() => onChangeWeek('prev')}
      />

      <VibeIcon
        icon={viChevronRight}
        color={color.manatee}
        hoverColor={color.obsidian}
        tooltip="Next Week"
        tooltipProps={{
          placement: 'top',
        }}
        disabled={disabled}
        size={24}
        onClick={() => onChangeWeek('next')}
      />

      <div className="week-dates">
        {weekDates}
      </div>
    </div>
  );
}

WeekNavigation.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  startOfWeek: PropTypes.instanceOf(moment).isRequired,
  endOfWeek: PropTypes.instanceOf(moment).isRequired,
  onChangeWeek: PropTypes.func,
};

WeekNavigation.defaultProps = {
  className: '',
  disabled: false,
  onChangeWeek: () => {},
};

export default WeekNavigation;
