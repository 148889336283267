import {
  find,
  without,
  clone,
} from 'lodash';
import Company from '../models/ExpandedLocations/Company';
import Location from '../models/ExpandedLocations/Location';

/**
 * Get the locations data
 */
export function getLocationsData(companiesList) {
  const companies = [];
  const locations = [];

  companiesList.forEach((companyData) => {
    const company = new Company(companyData);
    const companyLocations = [];

    company.locations.forEach((locationData) => {
      const location = new Location(locationData, company);
      companyLocations.push(location);
      locations.push(location);
    });

    company.locations = companyLocations;
    companies.push(company);
  });

  return {
    companies,
    locations,
  };
}

/**
 * Handle Company
 */
export function handleCompany({
  type,
  companies,
  company,
  allLocations,
}) {
  if (!company) {
    console.error('No Company Sent');
    return false;
  }

  const companyExists = find(companies, { _id: company._id });
  const companyItem = companyExists || company;

  companyItem.allLocations = allLocations;

  if (type === 'add' && !companyExists) {
    // Add the company
    companies.push(new Company(companyItem));
  } else if (type === 'remove' && companyExists) {
    // Remove the company
    companies = without(companies, companyItem);
  }

  return companies;
}

/**
 * Handle Location
 */
export function handleLocation({
  type,
  locations,
  location,
}) {
  if (!location) {
    console.error('No Location Sent');
    return false;
  }

  const locationExists = find(locations, { _id: location._id });

  if (type === 'add' && !locationExists) {
    // Add the location
    locations.push(new Location(location));
  } else if (type === 'remove' && locationExists) {
    // Remove the location
    locations = without(locations, location);
  }

  return locations;
}

/**
 * Handle Locations Data (both companies and locations)
 */
export function handleLocationsData({
  type,
  data,
  companies,
  locations,
}) {
  const {
    company,
    companyId,
    location,
    locationId,
  } = data;

  const allLocations = locationId === '*';

  companies = clone(companies);
  locations = clone(locations);

  if (allLocations) {
    // Clear any locations with this company since all are selected
    locations = locations.filter(location => location.companyId !== company._id);
  } else {
    // Add/Remove the location to the locations data array
    locations = location
      ? handleLocation({
        type,
        locations,
        location,
        company,
      })
      : locations;
  }

  const companyLocations = locations.filter(location => location.companyId === companyId);

  if (
    type === 'add'
    || (type === 'remove' && !location)
    || (type === 'remove' && companyLocations.length <= 0)
  ) {
    companies = company
      ? handleCompany({
        type,
        companies,
        company,
        allLocations,
      })
      : companies;
  }

  return {
    companies,
    locations,
  };
}

export default {
  getLocationsData,
  handleCompany,
  handleLocation,
  handleLocationsData,
};
