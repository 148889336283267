import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  Slide,
} from '@mui/material';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import viVibeCube from '../../icons/viVibeCube';
import color from '../../sass/color.scss';
import './VibeDialog.scss';

const styles = {
  toolbarGutters: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0 0 15px',
  },
  title: {
    color: '#383838',
    fontSize: 24,
    fontWeight: 'normal',
    lineHeight: 1,
    letterSpacing: '1.6px',
  },
};

const TransitionUp = React.forwardRef((props, ref) => {
  return (
    <Slide
      ref={ref}
      direction="up"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});

const TransitionLeft = React.forwardRef((props, ref) => {
  return (
    <Slide
      ref={ref}
      direction="left"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});

class VibeDialog extends Component {
  /**
   * Render the Transition Component
   */
  renderTransitionComponent = () => {
    const {
      transitionDirection,
    } = this.props;

    if (transitionDirection === 'left') {
      return TransitionLeft;
    }

    return TransitionUp;
  };

  render() {
    const {
      classes,
      open,
      fullScreen,
      title,
      closeDialogLink,
      children,
      onClose,
    } = this.props;

    const {
      title: titleClass,
      toolbarGutters,
    } = classes;

    const closeDialogIcon = (
      <VibeIcon
        className="close-icon"
        icon={viClose}
        color={color.manatee}
        hoverColor={color.manatee16}
        size={24}
        onClick={onClose}
      />
    );

    const closeDialogHtml = closeDialogLink ? (
      <Link to={closeDialogLink}>
        {closeDialogIcon}
      </Link>
    ) : closeDialogIcon;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={this.renderTransitionComponent()}
        className="VibeDialog"
        disableEnforceFocus
      >
        {title ? (
          <AppBar className="app-bar" position="sticky">
            <Toolbar
              classes={{
                gutters: `${toolbarGutters} gutters`,
              }}
            >
              <VibeIcon
                icon={viVibeCube}
                color={color.manatee}
                size={24}
              />

              <h1 className={`${titleClass} wizard-title`}>
                {title}
              </h1>

              <div className="right-bar-items">
                {closeDialogHtml}
              </div>
            </Toolbar>
          </AppBar>
        ) : null}

        {children}
      </Dialog>
    );
  }
}

VibeDialog.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.shape({
    appBar: PropTypes.string,
    title: PropTypes.string,
    toolbarGutters: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** Open the dialog */
  open: PropTypes.bool,
  /** Dialog is full screen */
  fullScreen: PropTypes.bool,
  /** Transition direction */
  transitionDirection: PropTypes.oneOf([
    'up',
    'left',
  ]),
  /** Link when dialog is closed */
  closeDialogLink: PropTypes.string,
  /** When the dialog is closed */
  onClose: PropTypes.func,
};

VibeDialog.defaultProps = {
  title: null,
  open: false,
  fullScreen: false,
  transitionDirection: 'up',
  closeDialogLink: null,
  onClose: () => {},
};

export default withStyles(styles)(VibeDialog);
