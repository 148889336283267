// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 200px);
  font-size: 12px;
}
.ScheduleContainer > .schedule-content {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.ScheduleContainer > .drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1299;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/ScheduleContainer.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,0BAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,cAAA;AACJ;AAEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAAJ","sourcesContent":[".ScheduleContainer {\n  position: relative;\n  width: 100%;\n  height: calc(100% - 200px);\n  font-size: 12px;\n\n  > .schedule-content {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n  > .drag-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1299;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
