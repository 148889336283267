import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import classNames from 'classnames';
import MonthNavigate from './MonthNavigate';
import MonthDay from './MonthDay';
import './Month.scss';

class Month extends PureComponent {
  constructor(props) {
    super(props);

    const {
      date,
      timezone,
    } = props;

    this.currDate = new moment().tz(timezone);

    const monthDate = new moment(date)
      .startOf('month');

    this.state = {
      monthDate,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      date,
    } = this.props;

    const {
      date: prevDate,
    } = prevProps;

    if (date !== prevDate) {
      this.setState({
        monthDate: new moment(date)
          .startOf('month'),
      });
    }
  }

  /**
   * When the previous month icon is clicked
   */
  onClickPrevMonth = () => {
    const {
      monthDate: currMonthDate,
    } = this.state;

    // Get the previous month
    const monthDate = currMonthDate
      .clone()
      .subtract(1, 'month');

    this.setState({
      monthDate,
    });
  };

  /**
   * When the previous month icon is clicked
   */
  onClickNextMonth = () => {
    const {
      monthDate: currMonthDate,
    } = this.state;

    // Get the previous month
    const monthDate = currMonthDate
      .clone()
      .add(1, 'month');

    this.setState({
      monthDate,
    });
  };

  /**
   * When a day is selected
   */
  onSelectDay = (day) => {
    const {
      onSelectDay,
    } = this.props;

    onSelectDay(day);
  };

  /**
   * Get the number of weeks and the days in the week
   */
  getWeeks = () => {
    const {
      monthDate,
    } = this.state;

    const currMonthDate = monthDate
      .clone()
      .startOf('week');

    let weekIndex = 0;
    // Get the end of the week for the last week
    let lastWeekEndOfWeek = null;
    // Store the days of the week in week arrays
    const weeks = [[]];

    for (let i = 1; i <= 43; i++) {
      if (i !== 1) {
        currMonthDate.add(1, 'day');
      }

      if (i > 28) {
        // Past the limit for the minimum month length, ensure the day is still in the month
        if (currMonthDate.format('MMMM') !== monthDate.format('MMMM')) {
          if (currMonthDate.format('D') === currMonthDate.clone().startOf('week').format('D')) {
            // The new month starts at the beginning of the week
            if (weeks[weekIndex].length <= 0) {
              // Remove the empty week
              weeks.splice(weekIndex, 1);
            }
            break;
          }

          // The day is not part of the current month
          if (!lastWeekEndOfWeek) {
            lastWeekEndOfWeek = currMonthDate
              .clone()
              .endOf('week');
          }

          if (currMonthDate.isAfter(lastWeekEndOfWeek)) {
            // The day is not part of the last week even though it's not the same month
            if (weeks[weekIndex].length <= 0) {
              // Remove the empty week
              weeks.splice(weekIndex, 1);
            }

            break;
          }
        }
      }

      // Add the day to the week
      weeks[weekIndex].push(currMonthDate.clone());

      if (i % 7 === 0) {
        weekIndex++;

        // Start the days array for this week
        weeks[weekIndex] = [];
        // console.log('Week Break ----------');
      }
    }

    return weeks;
  };

  render() {
    const {
      navigate,
      showToday,
      minDate,
      maxDate,
      selectedDays,
      eventDays,
      dragEvents,
      selectDisabled,
      onDrop,
      timezone,
    } = this.props;

    const {
      monthDate,
    } = this.state;

    const {
      currDate,
    } = this;

    const weeks = this.getWeeks();
    const monthHeaderText = monthDate.format('MMMM YYYY');
    const monthName = monthDate.format('MMMM');
    const monthYear = monthDate.format('YYYY');
    // Current MM/DD/YYYY
    const currMMDDYYYY = currDate.format('MM/DD/YYYY');
    // Current Month (January/February/etc)
    const currMonthName = currDate.format('MMMM');
    // Current Day of week (Mon/Tue/etc)
    const currDay = currDate.format('ddd');
    // Minimum date start of day
    const minDateStartOfDay = minDate
      ? new moment(minDate).startOf('day')
      : null;
    // Maximum date start of day
    const maxDateStartOfDay = maxDate
      ? new moment(maxDate).startOf('day')
      : null;
    // Array of MM/DD/YYYY for each event day
    const eventDaysMMDDYYYY = eventDays.map(eventDate => new moment(eventDate).tz(timezone).format('MM/DD/YYYY'));

    // Highlight the day of the week
    const highlightDay = monthName === currMonthName
      && showToday;

    return (
      <div className="Month">
        {navigate ? (
          <MonthNavigate
            text={monthHeaderText}
            onPrev={this.onClickPrevMonth}
            onNext={this.onClickNextMonth}
          />
        ) : (
          <div className="header">
            <div className="month-text month-name">
              {monthName}
            </div>

            <div className="month-text month-year">
              {monthYear}
            </div>
          </div>
        )}

        <div className="week header">
          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Sun',
          })}
          >
            Sun
          </div>

          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Mon',
          })}
          >
            Mon
          </div>

          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Tue',
          })}
          >
            Tue
          </div>

          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Wed',
          })}
          >
            Wed
          </div>

          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Thu',
          })}
          >
            Thu
          </div>

          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Fri',
          })}
          >
            Fri
          </div>

          <div className={classNames('day-header', 'day', {
            today: highlightDay
              && currDay === 'Sat',
          })}
          >
            Sat
          </div>
        </div>

        {weeks.map((week, index) => {
          return (
            <div
              key={`week-${index}`}
              className="week"
            >
              {week.map((day) => {
                const showDay = day.format('MMMM') === monthName;
                const dayMMDDYYYY = day.format('MM/DD/YYYY');
                // Disable selecting the date
                const disabled = (minDate && day.isBefore(minDateStartOfDay))
                  || (maxDate && day.isAfter(maxDateStartOfDay));

                return (
                  <MonthDay
                    key={`${monthName}-${day.format('D')}`}
                    className="day-number day"
                    date={day}
                    showDay={showDay}
                    highlight={showToday && currMMDDYYYY === dayMMDDYYYY}
                    selected={selectedDays.includes(dayMMDDYYYY)}
                    hasEvents={eventDaysMMDDYYYY.includes(dayMMDDYYYY)}
                    dragEvents={dragEvents}
                    disabled={disabled}
                    selectDisabled={selectDisabled}
                    onSelect={this.onSelectDay}
                    onDrop={onDrop}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

Month.propTypes = {
  /** Date to use */
  date: PropTypes.instanceOf(Date),
  /** Allow Navigation between months */
  navigate: PropTypes.bool,
  /** Highlight the current day */
  showToday: PropTypes.bool,
  /** Minimum date to allow selection */
  minDate: PropTypes.instanceOf(Date),
  /** Maximum date to allow selection */
  maxDate: PropTypes.instanceOf(Date),
  /** Days events are occuring */
  eventDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** Can events be dragged */
  dragEvents: PropTypes.bool,
  /** Allow the disabled dates to be selected (while still looking disabled) */
  selectDisabled: PropTypes.bool,
  /** Days that are currently selected */
  selectedDays: PropTypes.arrayOf(PropTypes.string),
  /** When the day is selected */
  onSelectDay: PropTypes.func,
  /** When an item is dropped on a day */
  onDrop: PropTypes.func,
};

Month.defaultProps = {
  date: new moment().toDate(),
  navigate: false,
  showToday: false,
  minDate: null,
  maxDate: null,
  eventDays: [],
  dragEvents: false,
  selectDisabled: false,
  selectedDays: [],
  onSelectDay: () => {},
  onDrop: () => {},
};

export default Month;
