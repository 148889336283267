import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  find,
  head,
  last,
  inRange,
  sortBy,
} from 'lodash';
import {
  TimeUtil,
  NavigationHelper,
  ScheduleHelper,
  viAdd,
  viClose,
  color,
} from 'vibeguide';
import GridItem from './GridItem';
import CurrentTime from './Items/CurrentTime';
import MusicItem from './Items/MusicItem';
import MessageBlockItem from './Items/MessageBlockItem';
import InterruptItem from './Items/InterruptItem';
import BaselineSeparatorItem from './Items/BaselineSeparatorItem';
import EventItem from './Items/EventItem';
import SpotPoolGhostItem from './Items/SpotPoolGhostItem';
import SpotPoolScheduleItem from './Items/SpotPoolScheduleItem';
import SpotPoolItem from './Items/SpotPoolItem';
import SpotValueItem from './Items/SpotValueItem';
import DragItem from './Items/DragItem';
import Playlist from '../../../../Programs/Baselines/Calendar/Items/Playlist';
import Mix from '../../../../Programs/Baselines/Calendar/Items/Mix';
import MessageBlock from '../../../../Programs/Baselines/Calendar/Items/MessageBlock';
import './GridDay.scss';

// save the current Y position to prevent multiple renders
let currentY = 0;

/**
 * Get the slot when dragging something into the day
 */
function getSlot({
  activeDisplay,
  slotHeight,
  y,
}) {
  // get the full slot position (index is the full number, remainder is the position within the slot)
  const slotPos = y / slotHeight;
  const slotIndex = Math.floor(slotPos) >= 0
    ? Math.floor(slotPos)
    : 0;
  // position within the slot
  const slotPercent = (slotPos % 1) * 100;

  switch (activeDisplay) {
    case '24h':
    case '1h': {
      const hour = slotIndex < 10
        ? `0${slotIndex}`
        : slotIndex;

      if (slotPercent < 25) {
        return `${hour}:00`;
      }

      if (slotPercent < 50) {
        return `${hour}:15`;
      }

      if (slotPercent < 75) {
        return `${hour}:30`;
      }

      // between 75-100%
      return `${hour}:45`;
    }

    case '15m': {
      const slotTimeInSeconds = slotIndex * 15;

      if (slotPercent < 33) {
        return TimeUtil.convertDuration(slotTimeInSeconds);
      }
      if (slotPercent < 66) {
        return TimeUtil.convertDuration(slotTimeInSeconds + 5);
      }

      return TimeUtil.convertDuration(slotTimeInSeconds + 10);
    }

    case '1m':
      return TimeUtil.convertDuration(slotIndex);

    default:
      return 'Unknown';
  }
}

/**
 * Get the items available start/stop time based on the current mouse position when dragging
 */
function getAvailableItemSpan({
  slot,
  weekDay,
  timezone,
  dragType,
  events,
  spotPools,
}) {
  let checkItems = [];

  switch (dragType) {
    case 'Event':
      checkItems = events;
      break;
    case 'Spot Pool':
      checkItems = spotPools;
      break;
    default:
      break;
  }

  const currTime = ScheduleHelper.getCurrentDate(timezone);
  const currMinutes = TimeUtil.getMinutes(currTime.format('HH:mm'));
  const slotMinutes = TimeUtil.getMinutes(slot);

  // is an item currently in this range?
  const conflictItem = find(checkItems, (item) => {
    const startMinutes = TimeUtil.getMinutes(item.startTime);
    const stopMinutes = TimeUtil.getMinutes(item.stopTime);

    return inRange(slotMinutes, startMinutes, stopMinutes);
  });

  if (conflictItem) {
    // an item is already occuring at this time
    return false;
  }

  // get the last item before this time slot
  const prevItem = last(checkItems.filter(item => TimeUtil.getMinutes(item.stopTime) <= slotMinutes));
  // get the first item after this time slot
  const nextItem = head(checkItems.filter(item => TimeUtil.getMinutes(item.startTime) >= slotMinutes));

  // set the start/stop time for the item to be dropped based on the range between items
  let startTime = prevItem
    ? prevItem.stopTime
    : '00:00';

  let stopTime = nextItem
    ? nextItem.startTime
    : '24:00';

  // is the event timespan valid?
  let valid;

  // get the start/stop minutes
  const startMinutes = TimeUtil.getMinutes(startTime);
  // get the available event start time as a date
  const startTimeDate = weekDay.clone().add(startMinutes, 'minutes');
  const isToday = currTime.format && startTimeDate.format('YYYY-MM-DD') === currTime.format('YYYY-MM-DD');

  /**
   * Whenever dragging an event on the current locations day
   * Check for blocking events
   * Prevent an event from being dropped in the past
   */
  if (dragType === 'Event') {
    if (isToday && prevItem && TimeUtil.getMinutes(prevItem.stopTime) > currMinutes) {
      // dragging an event for today, but there is a previous event that stops after the current locations time
      valid = true;
    } else if (isToday && nextItem && TimeUtil.getMinutes(nextItem.startTime) < currMinutes) {
      // dragging an event for today, but there is a future event that starts before the current locations time
      valid = false;
    } else if (isToday && slotMinutes < currMinutes) {
      // dragging before the current time at this location (cannot occur in the past)
      valid = false;
      stopTime = currTime.format('HH:mm');
    } else if (isToday && slotMinutes >= currMinutes) {
      // dragging after the current time at this location
      valid = true;
      startTime = currTime.format('HH:mm');
    } else if (!isToday && startTimeDate.isBefore(currTime)) {
      // an event cannot occur in the past
      valid = false;
    } else {
      // assume true on any other variation
      valid = true;
    }
  } else if (dragType === 'Spot Pool') {
    // allow spot pools to be created
    valid = true;
  }

  return {
    startTime,
    stopTime,
    valid,
  };
}

function GridDay({
  day,
  weekDay,
  activeDisplay,
  slotHeight,
  currTime,
  timezone,
  musicOverride,
  allowClick,
  playlists,
  mixes,
  messageBlocks,
  interrupts,
  baselineSeparators,
  events,
  spotPools,
  spotValues,
  adProgramProps,
  adProgram,
  companyId,
  locationId,
  dragging,
  dragType,
  onClick,
}) {
  const dayRef = useRef(null);
  const [dragItemStart, setDragItemStart] = useState(null);
  const [dragItemStop, setDragItemStop] = useState(null);
  const [dragItemValid, setDragItemValid] = useState(false);
  // show potential time span for a spot pool item when hovering over an empty area
  const [createSpotPoolItem, setCreateSpotPoolItem] = useState(null);

  const musicItems = sortBy([
    ...playlists,
    ...mixes,
  ], 'startTime');

  const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');

  const onDragOver = (e) => {
    e.preventDefault();

    const {
      pageY,
    } = e;

    if (pageY === currentY) {
      // do not re-render until the mouse moves
      return;
    }

    if (dragType !== 'Event') {
      // only allow certain objects to be dragged into the schedule
      return;
    }

    const {
      top,
    } = dayRef.current.getBoundingClientRect();

    // get the position of the mouse relative to the grid element
    const y = pageY - top;
    // get the current slot being hovered over
    const slot = getSlot({
      activeDisplay,
      slotHeight,
      y,
    });

    // get the start/stop time of the item if dropped at this spot
    const itemSpan = getAvailableItemSpan({
      slot,
      weekDay,
      timezone,
      dragType,
      events,
    });

    if (itemSpan) {
      // item can be dropped in this range
      setDragItemStart(itemSpan.startTime);
      setDragItemStop(itemSpan.stopTime);
      setDragItemValid(itemSpan.valid);
    } else {
      // item cannot be dropped in this time slot
      setDragItemStart(null);
      setDragItemStop(null);
      setDragItemValid(false);
    }

    // set the current mouse position to prevent re-renders until the mouse moves
    currentY = pageY;
  };

  // const onDragEnter = () => {
  //   console.log('onDragEnter');
  // }

  const onDragLeave = () => {
    setDragItemStart(null);
    setDragItemStop(null);
    setDragItemValid(false);
  };

  const onDrop = () => {
    // only allow drop when a viable timeslot is found
    if (dragItemStart && dragItemStop && dragItemValid) {
      // tell the schedule to add the dropped item
      document.dispatchEvent(new CustomEvent('onDropItemOnSchedule', {
        detail: {
          day,
          type: dragType,
          itemStart: dragItemStart,
          itemStop: dragItemStop,
        },
      }));
    } else {
      console.warn(`onDrop GridDay: ${dragType} cannot be dropped here`);
    }

    setDragItemStart(null);
    setDragItemStop(null);
    setDragItemValid(false);
  };

  /**
   * When the mouse enters an empty area that can create a spot pool
   */
  const onMouseEnterEmptySpotPool = (e) => {
    const {
      pageY,
    } = e;

    const {
      top,
    } = dayRef.current.getBoundingClientRect();

    // get the position of the mouse relative to the grid element
    const y = pageY - top;
    // get the current slot being hovered over
    const slot = getSlot({
      activeDisplay,
      slotHeight,
      y,
    });

    // get the start/stop time of the item if dropped at this spot
    const itemSpan = getAvailableItemSpan({
      slot,
      weekDay,
      timezone,
      dragType: 'Spot Pool',
      spotPools,
    });

    if (itemSpan) {
      // item can be dropped in this range
      setCreateSpotPoolItem({
        day,
        startTime: itemSpan.startTime,
        stopTime: itemSpan.stopTime,
      });
    }
  };

  /**
   * When the spot pool ghost item is clicked, add a spot pool
   */
  const onClickSpotPoolGhostItem = ({
    startTime,
    stopTime,
  }) => {
    if (adProgramProps && adProgramProps.onAddSpotPool) {
      adProgramProps.onAddSpotPool({
        day,
        startTime,
        stopTime,
      });

      // reset the create spot pool area so another can be added without saving the ad program first
      setCreateSpotPoolItem(null);
    }
  };

  return (
    <div
      ref={dayRef}
      className="GridDay"
    >
      <div
        className={classNames('day-content', { clickable: allowClick })}
        onClick={allowClick
          ? () => onClick(day)
          : null}
      >
        {/* Link listener for Events due to the main event having no pointer events */}
        {events.map((event) => {
          return (
            <Link
              key={`event-click-listener-${day}-${event.startTime}`}
              to={NavigationHelper.updateParams({
                eventId: event._id,
              })}
            >
              <GridItem
                type="Other"
                startTime={event.startTime}
                stopTime={event.stopTime}
                width={136}
                left={-20}
                layer={1}
              />
            </Link>
          );
        })}

        {musicItems.map((musicItem) => {
          if (musicItem.playlist) {
            return (
              <GridItem
                key={`playlist-${day}-${musicItem.startTime}`}
                type="Playlist"
                startTime={musicItem.startTime}
                stopTime={musicItem.stopTime}
                // extra pixel of width to merge adjoining borders
                width={33}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={2}
                tooltip={(
                  <Playlist
                    playlist={musicItem.playlist}
                    startTime={musicItem.startTime}
                    stopTime={musicItem.stopTime}
                    archived={!get(musicItem, 'playlist.active')}
                    isHover
                  />
                )}
              >
                <MusicItem
                  type="playlist"
                  playlist={musicItem}
                  color={!musicOverride
                    ? color.violetVibe
                    : color.flamingo}
                />
              </GridItem>
            );
          }

          if (musicItem.mix) {
            return (
              <GridItem
                key={`mix-${day}-${musicItem.startTime}`}
                type="Mix"
                startTime={musicItem.startTime}
                stopTime={musicItem.stopTime}
                // extra pixel of width to merge adjoining borders
                width={33}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={2}
                tooltip={(
                  <Mix
                    mix={musicItem.mix}
                    startTime={musicItem.startTime}
                    stopTime={musicItem.stopTime}
                    archived={!get(musicItem, 'mix.active')}
                    isHover
                  />
                )}
              >
                <MusicItem
                  type="station"
                  station={musicItem}
                  color={!musicOverride
                    ? color.violetVibe
                    : color.flamingo}
                />
              </GridItem>
            );
          }

          // unknown music item type
          return null;
        })}

        {messageBlocks.map((messageBlock) => (
          <GridItem
            key={`block-${day}-${messageBlock.startTime}`}
            type="Message Block"
            startTime={messageBlock.startTime}
            stopTime={messageBlock.stopTime}
            width={32}
            left={32}
            style={{
              backgroundColor: color.white,
            }}
            layer={2}
            tooltip={(
              <MessageBlock
                messageBlock={messageBlock.messageBlock}
                companyId={companyId}
                locationId={locationId}
                startTime={messageBlock.startTime}
                stopTime={messageBlock.stopTime}
                archived={!get(messageBlock, 'messageBlock.active')}
                day={day}
                isHover
              />
            )}
          >
            <MessageBlockItem
              messageBlock={messageBlock}
              color={color.violetVibe}
            />
          </GridItem>
        ))}

        {interrupts.map((interrupt) => (
          <GridItem
            key={`interrupt-${day}-${interrupt.startTime}`}
            type="Message"
            startTime={interrupt.startTime}
            stopTime={interrupt.startTime}
            width={24}
            height={6}
            left={72}
            style={{
              backgroundColor: color.white,
            }}
            layer={2}
            tooltip={(
              <InterruptItem
                interrupt={interrupt}
                color={color.violetVibe}
                day={day}
                tooltipView
              />
            )}
            tooltipProps={{
              custom: true,
            }}
          >
            <InterruptItem
              interrupt={interrupt}
              color={color.violetVibe}
              day={day}
            />
          </GridItem>
        ))}

        {baselineSeparators.map((sep) => (
          <GridItem
            key={`separator-${day}-${sep.startTime}`}
            type="Other"
            startTime={sep.startTime}
            stopTime={sep.startTime}
            width={136}
            height={4}
            left={-20}
            style={{
              backgroundColor: color.violetVibe,
              overflow: 'visible',
            }}
            layer={3}
          >
            <BaselineSeparatorItem
              baselineSeparator={sep}
            />
          </GridItem>
        ))}

        {events.map((event) => (
          <div key={`event-${day}-${event.startTime}`}>
            {/* hide everything behind the event */}
            {!event.playBaselineMusic ? (
              <Link
                to={NavigationHelper.updateParams({
                  eventId: event._id,
                })}
              >
                <GridItem
                  type="Other"
                  startTime={event.startTime}
                  stopTime={event.stopTime}
                  width={52}
                  left={-20}
                  style={{
                    backgroundColor: color.white,
                    borderRadius: 0,
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                  }}
                  layer={4}
                />
              </Link>
            ) : null}

            {/* hide everything behind the event */}
            {!event.playBaselineMessageBlocks ? (
              <Link
                to={NavigationHelper.updateParams({
                  eventId: event._id,
                })}
              >
                <GridItem
                  type="Other"
                  startTime={event.startTime}
                  stopTime={event.stopTime}
                  width={32}
                  left={32}
                  style={{
                    backgroundColor: color.white,
                    borderRadius: 0,
                  }}
                  layer={4}
                />
              </Link>
            ) : null}

            {/* hide everything behind the event */}
            {!event.playBaselineInterrupts ? (
              <Link
                to={NavigationHelper.updateParams({
                  eventId: event._id,
                })}
              >
                <GridItem
                  type="Other"
                  startTime={event.startTime}
                  stopTime={event.stopTime}
                  width={52}
                  left={64}
                  style={{
                    backgroundColor: color.white,
                    borderRadius: 0,
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                  layer={4}
                />
              </Link>
            ) : null}

            {event.playlists.map((playlist) => (
              <GridItem
                key={`event-playlist-${day}-${playlist.startTime}`}
                type="Playlist"
                startTime={playlist.startTime}
                stopTime={playlist.stopTime}
                // extra pixel of width to merge adjoining borders
                width={33}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={4}
                tooltip={(
                  <Playlist
                    playlist={playlist.playlist}
                    startTime={playlist.startTime}
                    stopTime={playlist.stopTime}
                    archived={!get(playlist, 'playlist.active')}
                    isHover
                  />
                )}
              >
                <Link
                  to={NavigationHelper.updateParams({
                    eventId: event._id,
                  })}
                >
                  <MusicItem
                    type="playlist"
                    playlist={playlist}
                    color={event.color}
                  />
                </Link>
              </GridItem>
            ))}

            {event.mixes.map((mix) => (
              <GridItem
                key={`event-mix-${day}-${mix.startTime}`}
                type="Mix"
                startTime={mix.startTime}
                stopTime={mix.stopTime}
                // extra pixel of width to merge adjoining borders
                width={33}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={4}
                tooltip={(
                  <Mix
                    mix={mix.mix}
                    startTime={mix.startTime}
                    stopTime={mix.stopTime}
                    archived={!get(mix, 'mix.active')}
                    isHover
                  />
                )}
              >
                <Link
                  to={NavigationHelper.updateParams({
                    eventId: event._id,
                  })}
                >
                  <MusicItem
                    type="station"
                    station={mix}
                    color={event.color}
                  />
                </Link>
              </GridItem>
            ))}

            {event.messageBlocks.map((messageBlock) => (
              <GridItem
                key={`event-block-${day}-${messageBlock.startTime}`}
                type="Message Block"
                startTime={messageBlock.startTime}
                stopTime={messageBlock.stopTime}
                width={32}
                left={32}
                style={{
                  backgroundColor: color.white,
                }}
                layer={4}
                tooltip={(
                  <MessageBlock
                    messageBlock={messageBlock.messageBlock}
                    companyId={companyId}
                    locationId={locationId}
                    startTime={messageBlock.startTime}
                    stopTime={messageBlock.stopTime}
                    archived={!get(messageBlock, 'messageBlock.active')}
                    day={day}
                    isHover
                  />
                )}
              >
                <Link
                  to={NavigationHelper.updateParams({
                    eventId: event._id,
                  })}
                >
                  <MessageBlockItem
                    messageBlock={messageBlock}
                    color={event.color}
                  />
                </Link>
              </GridItem>
            ))}

            {event.interruptions.map((interrupt) => (
              <GridItem
                key={`event-interrupt-${day}-${interrupt.startTime}`}
                type="Message"
                startTime={interrupt.startTime}
                stopTime={interrupt.startTime}
                width={24}
                height={6}
                left={72}
                style={{
                  backgroundColor: color.white,
                }}
                layer={4}
                tooltip={(
                  <InterruptItem
                    interrupt={interrupt}
                    color={event.color}
                    day={day}
                    tooltipView
                    isEvent
                  />
                )}
                tooltipProps={{
                  custom: true,
                }}
              >
                <Link
                  to={NavigationHelper.updateParams({
                    eventId: event._id,
                  })}
                >
                  <InterruptItem
                    interrupt={interrupt}
                    color={event.color}
                    day={day}
                    isEvent
                  />
                </Link>
              </GridItem>
            ))}

            <GridItem
              type="Other"
              startTime={event.startTime}
              stopTime={event.stopTime}
              width={136}
              left={-20}
              style={{
                pointerEvents: 'none',
              }}
              layer={4}
            >
              <EventItem
                event={event}
              />
            </GridItem>
          </div>
        ))}

        {/* Show the create spot pool if hovering over an empty space in the schedule */}
        {adProgramProps.canAddSpotPool && (
          <GridItem
            type="Other"
            startTime="00:00"
            stopTime="24:00"
            width={33}
            left={0}
            layer={4}
            onMouseEnter={onMouseEnterEmptySpotPool}
          />
        )}

        {/* Show the create spot pool item in the shedule */}
        {createSpotPoolItem && (
          <GridItem
            type="Other"
            startTime={createSpotPoolItem.startTime}
            stopTime={createSpotPoolItem.stopTime}
            width={33}
            left={0}
            layer={4}
          >
            <SpotPoolGhostItem
              startTime={createSpotPoolItem.startTime}
              stopTime={createSpotPoolItem.stopTime}
              onClick={onClickSpotPoolGhostItem}
            />
          </GridItem>
        )}

        {spotPools.map((item) => (
          <GridItem
            key={`spot-pool-${day}-${item.startTime}`}
            type="Other"
            startTime={item.startTime}
            stopTime={item.stopTime}
            width={33}
            left={0}
            style={{
              backgroundColor: color.white,
            }}
            layer={5}
          >
            <SpotPoolItem
              day={day}
              spotPool={item.spotPool}
              canView={item.canView}
              canEdit={item.canEdit}
              onCopy={item.onCopy}
              onChange={item.onChange}
              onRemove={item.onRemove}
            />
          </GridItem>
        ))}

        {spotValues.map((item) => (
          <GridItem
            key={`spot-value-${day}-${item.startTime}`}
            type="Other"
            startTime={item.startTime}
            stopTime={item.stopTime}
            width={56}
            left={40}
            style={{
              backgroundColor: color.white,
            }}
            layer={5}
          >
            <SpotValueItem
              day={day}
              startTime={item.startTime}
              stopTime={item.stopTime}
              value={item.value}
              canView={item.canView}
              canEdit={item.canEdit}
              onChange={item.onChange}
            />
          </GridItem>
        ))}

        {get(adProgram, `spotPools[${dayDD}]`, []).map((spotPool) => (
          <GridItem
            key={`spot-pool-${day}-${spotPool.startTime}`}
            type="Other"
            startTime={spotPool.startTime}
            stopTime={spotPool.endTime}
            width={48}
            left={24}
            style={{
              backgroundColor: color.white,
            }}
            layer={5}
          >
            <SpotPoolScheduleItem
              day={dayDD}
              adProgramId={adProgram._id}
              locationId={locationId}
              startTime={spotPool.startTime}
              stopTime={spotPool.endTime}
            />
          </GridItem>
        ))}

        {dragItemStart ? (
          <GridItem
            type="Other"
            startTime={dragItemStart}
            stopTime={dragItemStop}
            width={136}
            left={-20}
            style={{
              backgroundColor: dragItemValid
                ? color.violetVibe50
                : color.fireBrick50,
            }}
            layer={6}
          >
            <DragItem
              icon={dragItemValid
                ? viAdd
                : viClose}
            />
          </GridItem>
        ) : null}

        {currTime ? (
          <GridItem
            type="Other"
            startTime={currTime.format('H:mm')}
            stopTime={currTime.format('H:mm')}
            width={157}
            height={2}
            left={-40}
            style={{
              backgroundColor: color.violetVibe,
              overflow: 'visible',
            }}
            layer={10}
          >
            <CurrentTime
              time={currTime.format('h:mm A')}
            />
          </GridItem>
        ) : null}
      </div>

      {dragging ? (
        <div
          className="drag-overlay"
          onDragOver={onDragOver}
          // onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        />
      ) : null}
    </div>
  );
}

GridDay.propTypes = {
  day: PropTypes.string.isRequired,
  weekDay: PropTypes.instanceOf(moment).isRequired,
  activeDisplay: PropTypes.string.isRequired,
  slotHeight: PropTypes.number.isRequired,
  currTime: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  timezone: PropTypes.string,
  musicOverride: PropTypes.bool,
  /** Allow the day to be clicked (change the style on hover) */
  allowClick: PropTypes.bool,
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interrupts: PropTypes.arrayOf(PropTypes.object),
  baselineSeparators: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  spotPools: PropTypes.arrayOf(PropTypes.object),
  spotValues: PropTypes.arrayOf(PropTypes.object),
  /** Config for the ad program */
  adProgramProps: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  adProgram: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** When the day is clicked on the schedule day */
  onClick: PropTypes.func,
};

GridDay.defaultProps = {
  currTime: null,
  timezone: null,
  musicOverride: false,
  allowClick: false,
  playlists: [],
  mixes: [],
  messageBlocks: [],
  interrupts: [],
  baselineSeparators: [],
  events: [],
  spotPools: [],
  spotValues: [],
  adProgramProps: {},
  adProgram: {},
  onClick: () => {},
};

function mapStateToProps(state) {
  return {
    companyId: get(state, 'locations.currentLocation.companyId', null),
    locationId: get(state, 'locations.currentLocation._id', null),
    dragging: state.global.drag.dragging,
    dragType: state.global.drag.dragType,
  };
}

export default connect(mapStateToProps)(GridDay);
