import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { get } from 'lodash';
import {
  MimeTypeHelper,
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import {
  AddCircle,
  Add,
  Edit,
} from '@mui/icons-material';
import defaultMixImage from '../../../../assets/default_mix.png';
import './Information.scss';

class Information extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  /**
   * When the company field is changed
   */
  onSetCompany = ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      companyId: currCompanyId,
      tags,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  onDrop = (acceptedFiles) => {
    const file = get(acceptedFiles, '[0]', false);

    if (file) {
      const {
        onUpdate,
        onUploadImage,
      } = this.props;

      const reader = new FileReader();
      reader.onload = () => {
        const fileAsDataURL = reader.result;
        // Save image data to state to show as a preview
        onUpdate({ imageUrl: fileAsDataURL });
      };

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');

      reader.readAsDataURL(file);

      onUploadImage(file);
    }
  };

  render() {
    const {
      name,
      description,
      image: mixImage,
      companyName,
      companyId,
      tags,
      isNew,
      isDuplicate,
      disableInput,
      user,
    } = this.props;

    const canSetCompany = user.sysAdmin;

    return (
      <div className="Information info-station">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={3}
            item
          >
            <div className="mix-image">
              <Dropzone
                accept={MimeTypeHelper.images}
                multiple={false}
                onDrop={this.onDrop}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getRootProps()}
                      className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                    >
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input {...getInputProps()} />

                      {mixImage ? (
                        <img src={mixImage} alt="Mix Preview" />
                      ) : (
                        <img src={defaultMixImage} alt="Mix" />
                      )}

                      {isDragActive ? (
                        <div className="icon-drop">
                          <AddCircle className="icon" />
                        </div>
                      ) : null}

                      <div className="icon-container">
                        {isDragActive ? (
                          <Add className="icon" />
                        ) : (
                          <Edit className="icon" />
                        )}
                      </div>
                    </div>
                  );
                }}
              </Dropzone>
            </div>
          </Grid>

          <Grid
            xs={9}
            item
          >
            <Field
              type="text"
              label="Name"
              name="name"
              placeholder="Name"
              value={name}
              tabIndex={1}
              marginBottom={16}
              onChange={this.onChange}
              disabled={disableInput}
              required
              autoFocus
            />

            {canSetCompany ? (
              <div className="mix-company">
                <div className="form-container">
                  <Field
                    type="company"
                    label="Company"
                    name="companyName"
                    value={companyName}
                    tabIndex={2}
                    companyProps={{
                      companyId,
                      companyName,
                    }}
                    disabled={disableInput || (!isNew && !isDuplicate)}
                    onChange={this.onSetCompany}
                    required
                  />
                </div>
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              className="station-description"
              type="textarea"
              label="Description"
              name="description"
              placeholder="Description"
              value={description}
              tabIndex={4}
              onChange={this.onChange}
              disabled={disableInput}
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                companyId,
                tags,
                allowTypes: ['client', 'admin'],
                showAs: 'list',
                assign: user.can('mix.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={5}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  description: '',
  image: '',
  companyName: '',
  companyId: '',
  tags: [],
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
