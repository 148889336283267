import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  API,
  TableIntegrationFile,
} from 'vibeguide';
import './IntegrationLocationFile.scss';
import SubNavigation from '../../Header/SubNavigation';

function IntegrationLocationFile({
  onExport,
  user,
}) {
  onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `integration-locations-${timestamp}.csv`);
  };

  return (
    <div className="IntegrationLocationFile">

      <SubNavigation
        title="Integration Location Files"
      />

      <TableIntegrationFile
        columns={[
          '.',
          'File Name',
          'Upload Date',
          'Integration',
          '...',
        ]}
        fetch={API.LocationControlFile.list}
        defaultSortBy={{
          label: 'File Name',
          attr: 'fileName',
        }}
        paginator
        paginatorProps={{
          label: 'Files',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Files',
          tooltip: true,
        }}
        csv={user.sysAdmin}
        csvProps={{
          onExport,
        }}
        menuItems={[
          { name: 'Archive', userCan: 'location_control_file.delete' },

        ]}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default (connect(mapStateToProps)(IntegrationLocationFile));
