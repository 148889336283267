import React from 'react';

const viMicrophone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M8 9.667c1.133 0 2-.867 2-2v-4c0-1.134-.867-2-2-2s-2 .866-2 2v4c0 1.133.867 2 2 2zm3.533-2c0 2-1.666 3.4-3.533 3.4s-3.533-1.4-3.533-3.4H3.333c0 2.266 1.8 4.133 4 4.466v2.2h1.334v-2.2c2.2-.333 4-2.2 4-4.466h-1.134z" />
    </g>
  </svg>
);

export default viMicrophone;
