import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Navigate,
} from 'react-router-dom';
import {
  LoginActions,
  Authentication,
  withRouter,
} from 'vibeguide';
import { Card } from '@mui/material';
import { passwordValidation } from '../../utils/validation';
import { markPageLoading } from '../../actions/loading-actions';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import FirstTimeLoginForm from './FirstTimeLoginForm';
import LoadingSpinner from '../Shared/LoadingSpinner';
import logoImage from '../../assets/login_logo.png';
import desktopImage from '../../assets/desktop_bg.png';
// Required for dev environment to export the favicon
// eslint-disable-next-line no-unused-vars
import faviconImage from '../../assets/favicon.ico';
import './LoginContainer.scss';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginStatus: 'login',
      username: '',
      password: '',
      newPassword: '',
      confirmationCode: '',
      loginErrorMessage: '',
      confirmationCodeErrorMessage: '',
      forgotPasswordErrorMessage: '',
      loginError: false,
      confirmationCodeError: false,
      forgotPasswordError: false,
      passwordErrors: {
        number: true,
        uppercase: true,
        lowercase: true,
        special: true,
        minLength: true,
        errors: true,
      },
      challengeData: null,
    };
  }

  componentDidUpdate() {
    const {
      authenticated,
      history,
    } = this.props;

    if (authenticated) {
      history('/');
    }
  }

  usernameChange = e => {
    const username = e.target.value.trim();
    this.setState({ username });
  };

  passwordChange = e => {
    const password = e.target.value.trim();
    this.setState({ password });
  };

  confirmationCodeChange = e => {
    const confirmationCode = e.target.value.trim();
    this.setState({ confirmationCode });
  };

  forgotPassword = () => {
    this.setState({ loginStatus: 'forgotPassword' });
  };

  cancelForgotPassword = () => {
    this.setState({
      loginStatus: 'login',
      forgotPasswordError: false,
    });
  };

  firstTimeNewPassword = () => {
    const {
      password,
    } = this.state;

    const passwordErrors = passwordValidation(password, password, 8);

    this.setState({
      passwordErrors,
      newPassword: password,
    });
  };

  passwordValidateChange = e => {
    const password = e.target.value;
    const passwordErrors = passwordValidation(password, password, 8);
    this.setState({ passwordErrors, password });
  };

  passwordValidateChangeFirstTime = e => {
    const newPassword = e.target.value;
    const passwordErrors = passwordValidation(newPassword, newPassword, 8);
    this.setState({ passwordErrors, newPassword });
  };

  resendActivationCode = () => {
    const {
      username,
    } = this.state;

    Authentication.forgotPassword(username);
  };

  checkCredentials = async e => {
    e.preventDefault();
    const { username, password } = this.state;
    const { dispatch } = this.props;
    const Username = username;
    const Password = password;
    const authenticationData = {
      Username,
      Password,
    };
    try {
      dispatch(markPageLoading(true));
      const authenticated = await Authentication.authenticateUser(authenticationData);
      if (authenticated.type === 'AUTHENTICATION.CHALLENGED'
        && authenticated.data.challengeType === 'newPasswordRequired') {
        this.setState({
          loginStatus: 'firstTimeLogin',
          challengeData: authenticated,
        });
        dispatch(markPageLoading(false));
      } else {
        dispatch(markPageLoading(false));
      }
    } catch (err) {
      const errorMessage = err.message;

      this.setState({
        loginError: true,
        loginErrorMessage: errorMessage,
      });

      dispatch(markPageLoading(false));
    }
  };

  firstTimeChangePassword = e => {
    const {
      newPassword,
      challengeData,
    } = this.state;
    const { dispatch } = this.props;

    dispatch(markPageLoading(true));

    Authentication.acceptChallenge(challengeData, newPassword)
      .then(() => {
        dispatch(LoginActions.doResetPasswordSuccess(true));
        dispatch(markPageLoading(false));
      })
      .catch(error => {
        dispatch(markPageLoading(false));
        throw new Error(error);
      });

    e.preventDefault();
  };

  sendActivationCode = () => {
    const { username } = this.state;
    const { dispatch } = this.props;
    dispatch(markPageLoading(true));
    Authentication.forgotPassword(username)
      .then(() => {
        this.setState({
          loginStatus: 'resetPassword',
          password: '',
        });
        dispatch(markPageLoading(false));
      })
      .catch(() => {
        const forgotPasswordErrorMessage = 'Username Not Found';
        this.setState({
          forgotPasswordError: true,
          forgotPasswordErrorMessage,
        });
        dispatch(markPageLoading(false));
      });
  };

  resetForgottenPassword = e => {
    const { username, confirmationCode, password } = this.state;
    const { dispatch } = this.props;
    const Username = username;
    const Password = password;
    const authenticationDetails = {
      Username,
      Password,
    };
    dispatch(markPageLoading(true));
    Authentication.confirmResetPassword(username, confirmationCode, password)
      .then(() => {
        Authentication.authenticateUser(authenticationDetails);
      })
      .then(() => {
        dispatch(LoginActions.doResetPasswordSuccess(true));
        dispatch(markPageLoading(false));
      })
      .catch(() => {
        const confirmationCodeErrorMessage = 'Incorrect Activation Code';
        this.setState({
          confirmationCodeError: true,
          confirmationCodeErrorMessage,
        });
        dispatch(markPageLoading(false));
      });
    e.preventDefault();
  };

  render() {
    const {
      loading,
      resetSuccess,
      path,
    } = this.props;

    const {
      username,
      password,
      loginStatus,
      loginError,
      loginErrorMessage,
      forgotPasswordError,
      forgotPasswordErrorMessage,
      confirmationCodeError,
      confirmationCodeErrorMessage,
      passwordErrors,
      confirmationCode,
    } = this.state;

    const isResetPage = path === '/reset';
    const isFirstTimeLogin = loginStatus === 'firstTimeLogin';

    if (resetSuccess && isResetPage) {
      // user successfully reset their password
      return (
        <Navigate
          to={{
            pathname: '/',
          }}
        />
      );
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `url(${desktopImage}) no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <Card className={`LoginContainer ${isResetPage ? 'reset-page' : ''}`}>
          {loading && <LoadingSpinner />}

          <div className="login-content">
            <div className="logo-container">
              <img
                className="logo"
                src={logoImage}
                alt="Vibenomics"
              />
            </div>

            {path === '/login' ? (
              <div>
                {!isFirstTimeLogin ? (
                  <LoginForm
                    onUsernameChange={this.usernameChange}
                    onPasswordChange={this.passwordChange}
                    onForgotPassword={this.forgotPassword}
                    cancelForgotPassword={this.cancelForgotPassword}
                    checkCredentials={this.checkCredentials}
                    username={username}
                    password={password}
                    loginError={loginError}
                    loginErrorMessage={loginErrorMessage}
                  />
                ) : (
                  <FirstTimeLoginForm
                    passwordValidateChange={this.passwordValidateChangeFirstTime}
                    onFirstTimeNewPassword={this.firstTimeNewPassword}
                    onFirstTimeChangePassword={this.firstTimeChangePassword}
                    errors={passwordErrors}
                  />
                )}
              </div>
            ) : null}

            {path === '/forgot' ? (
              <ForgotPasswordForm
                cancelForgotPassword={this.cancelForgotPassword}
                sendActivationCode={this.sendActivationCode}
                onUsernameChange={this.usernameChange}
                username={username}
                forgotPasswordError={forgotPasswordError}
                forgotPasswordErrorMessage={forgotPasswordErrorMessage}
              />
            ) : null}

            {path === '/reset' ? (
              <ResetPasswordForm
                passwordValidateChange={this.passwordValidateChange}
                resetPassword={this.resetForgottenPassword}
                resendActivationCode={this.resendActivationCode}
                onConfirmationCodeChange={this.confirmationCodeChange}
                errors={passwordErrors}
                confirmationCode={confirmationCode}
                confirmationCodeError={confirmationCodeError}
                confirmationCodeErrorMessage={confirmationCodeErrorMessage}
              />
            ) : null}
          </div>
        </Card>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  /**
   * Boolean value determining whether or not the page is loading or fetching data
   */
  loading: PropTypes.bool.isRequired,
  /**
   * Function that comes from redux to dispatch actions
   */
  dispatch: PropTypes.func.isRequired,
  /**
   * Boolean determining whether the resetting of the password was successful or not
   */
  resetSuccess: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticated: state.login.authenticated,
    currentLocation: state.locations.currentLocation,
    loading: state.loading.pageLoading,
    resetSuccess: state.login.resetSuccess,
  };
}

export default withRouter(connect(mapStateToProps)(LoginContainer));
