import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  ToastActions,
  LocationActions,
  Field2 as Field,
  VibeButtonNew,
  VibeModal,
  VibeIcon,
  viLock,
  viUnlock,
  viRefresh,
  color,
} from 'vibeguide';
import ProfileContentFooter from '../Profile/ProfileContentFooter';
import './Provisioning.scss';

function Provisioning({
  className,
  locationId,
  onboardStatus,
  logLevel,
  serviceAccountUserName,
  serviceAccountPassword,
  activationCode,
  canManageDeviceConfig,
  isAdmin,
  isActive,
  onUpdate,
  queueToast,
  updateCurrentLocation,
}) {
  // automatically lock the config each time the page is loaded
  const [lockConfig, setLockConfig] = useState(true);
  const [showConfirmUnlock, setShowConfirmUnlock] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const disabled = !canManageDeviceConfig
    || lockConfig;

  /**
   * When the onboard status is changed
   */
  const onChangeOnboardStatus = (e) => {
    const {
      target: {
        dataset: {
          name,
        },
      },
    } = e;

    if (canManageDeviceConfig && !lockConfig) {
      onUpdate({
        onboardStatus: name,
      });
    }

    setHasChanges(true);
  };

  /**
   * When the user clicks the lock icon
   */
  const onToggleLock = () => {
    if (lockConfig) {
      // require confirmation to unlock the config
      setShowConfirmUnlock(true);
    } else {
      // lock the config
      setLockConfig(true);
    }
  };

  /**
   * Confirm unlocking the config
   */
  const onConfirmUnlock = () => {
    setLockConfig(false);
    setShowConfirmUnlock(false);
  };

  /**
   * Cancel unlocking the config
   */
  const onCancelUnlock = () => {
    setShowConfirmUnlock(false);
  };

  /**
   * Update the config fields
   */
  const onUpdateConfig = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });

    setHasChanges(true);
  };

  /**
   * When the reset config button is clicked
   */
  const onClickResetConfig = () => {
    setShowConfirmReset(true);
  };

  /**
   * Confirm resetting the config
   */
  const onConfirmReset = async () => {
    const resetResponse = await API.Location.DeviceConfig.reset(locationId);

    if (get(resetResponse, '[0].type') === 'LOCATION.DEVICE_CONFIG_UPDATED') {
      updateCurrentLocation({
        onboardStatus: get(resetResponse, '[0].data.onboardStatus', 'setup'),
      });

      onUpdate({
        onboardStatus: get(resetResponse, '[0].data.onboardStatus', 'setup'),
        logLevel: get(resetResponse, '[0].data.deviceConfig.logLevel', 'info'),
        activationCode: get(resetResponse, '[0].data.deviceConfig.activationCode', 'XXXX'),
      });
    }

    setShowConfirmReset(false);
  };

  /**
   * Cancel resetting the config
   */
  const onCancelReset = () => {
    setShowConfirmReset(false);
  };

  /**
   * Save the provisioning config
   */
  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving Device Config...',
      allowClose: true,
    });

    try {
      const updateResponse = await API.Location.DeviceConfig.update({
        _id: locationId,
        onboardStatus,
        logLevel,
        serviceAccountUserName,
        serviceAccountPassword,
      });

      if (get(updateResponse, '[0].type') !== 'LOCATION.DEVICE_CONFIG_UPDATED') {
        queueToast({
          type: 'error',
          title: 'Error Updating Config',
          timeout: 10,
          allowClose: true,
        });
      } else {
        queueToast({
          type: 'success',
          title: 'Saved Device Config!',
          allowClose: true,
          delay: 500,
        });

        setHasChanges(false);
      }
    } catch (err) {
      console.error('Error Updating Config', err);

      queueToast({
        type: 'error',
        title: 'Error Updating Config',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  return (
    <div className={classNames('Provisioning', className)}>
      {canManageDeviceConfig && isActive && (
        <div className="header">
          <VibeIcon
            className="lock-button"
            type="button"
            icon={lockConfig
              ? viLock
              : viUnlock}
            color={lockConfig
              ? color.fireBrick
              : color.manatee}
            size={16}
            buttonProps={{
              size: 32,
              borderColor: lockConfig
                ? color.fireBrick
                : color.whiteSmoke,
            }}
            tooltip={lockConfig
              ? 'Unlock Config'
              : 'Lock Config'}
            tooltipProps={{
              placement: 'left',
            }}
            disabled={!canManageDeviceConfig}
            onClick={onToggleLock}
          />
        </div>
      )}

      <div className="content">
        <ul className="status-breadcrumb-container">
          <li
            className={classNames('status', { manage: !disabled })}
            data-name="setup"
            onClick={onChangeOnboardStatus}
          >
            <span className={classNames('status-title', 'setup', {
              active: onboardStatus === 'setup'
                || onboardStatus === 'shipping'
                || onboardStatus === 'complete',
            })}
            >
              Set Up
            </span>
          </li>

          <li
            className={classNames('status', { manage: !disabled })}
            data-name="shipping"
            onClick={onChangeOnboardStatus}
          >
            <span className={classNames('status-title', 'shipping', {
              active: onboardStatus === 'shipping'
                || onboardStatus === 'complete',
            })}
            >
              <span className="status-text">
                Shipping
              </span>
            </span>
          </li>

          <li
            className={classNames('status', { manage: !disabled })}
            data-name="complete"
            onClick={onChangeOnboardStatus}
          >
            <span
              className={classNames('status-title', 'complete', { active: onboardStatus === 'complete' })}
            >
              <span className="status-text">
                Fully Activated
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div className="content">
        <Field
          type="text"
          label="Service Account Email"
          name="serviceAccountUserName"
          placeholder="Service Account Email"
          value={serviceAccountUserName || ''}
          tabIndex={22}
          marginBottom={16}
          disabled={disabled}
          onChange={onUpdateConfig}
        />

        <Field
          type="password"
          label="Service Account Password"
          name="serviceAccountPassword"
          placeholder="Service Account Password"
          value={serviceAccountPassword || ''}
          tabIndex={23}
          marginBottom={16}
          disabled={disabled}
          onChange={onUpdateConfig}
        />

        <Field
          type="select"
          label="Log Level"
          name="logLevel"
          value={logLevel || ''}
          tabIndex={24}
          marginBottom={16}
          options={[
            {
              label: 'Fatal',
              value: 'fatal',
            },
            {
              label: 'Error',
              value: 'error',
            },
            {
              label: 'Warn',
              value: 'warn',
            },
            {
              label: 'Info',
              value: 'info',
            },
            {
              label: 'Debug',
              value: 'debug',
            },
          ]}
          disabled={disabled}
          onChange={onUpdateConfig}
        />

        <Field
          type="custom"
          label="Activation Code"
          name="activationCode"
        >
          <div>
            {activationCode || 'XXXX'}
          </div>
        </Field>
      </div>

      {isAdmin && isActive && canManageDeviceConfig && (
        <ProfileContentFooter>
          <VibeButtonNew
            variant="text"
            text="Reset Config"
            color={color.obsidian}
            icon={viRefresh}
            disabled={disabled}
            onClick={onClickResetConfig}
          />

          <VibeButtonNew
            style={{
              marginLeft: 8,
            }}
            text="Save Changes"
            color={color.violetVibe}
            disabled={disabled || !hasChanges}
            onClick={onSave}
          />
        </ProfileContentFooter>
      )}

      <VibeModal
        show={showConfirmUnlock}
        type="confirm"
        title="Change Configuration"
        text={(
          <div>
            Changing the provisioning configuration can result in the player losing access to the system.
            <br />
            <br />
            Change this information only if you are certain that the new settings are valid for the location
            and that the associated user has the requisite permissions.
          </div>
        )}
        confirmProps={{
          text: 'Unlock',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmUnlock}
        onClose={onCancelUnlock}
      />

      <VibeModal
        show={showConfirmReset}
        type="confirm"
        title="Reset Configuration"
        text={(
          <div>
            Are you sure you want to reset this location&apos;s device configuration?
            <br /><br />
            This action cannot be undone.
          </div>
        )}
        confirmProps={{
          text: 'Reset Config',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmReset}
        onClose={onCancelReset}
      />
    </div>
  );
}

Provisioning.propTypes = {
  className: PropTypes.string,
  locationId: PropTypes.string.isRequired,
  onboardStatus: PropTypes.string,
  logLevel: PropTypes.string,
  serviceAccountUserName: PropTypes.string,
  serviceAccountPassword: PropTypes.string,
  activationCode: PropTypes.string,
  canManageDeviceConfig: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isActive: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Provisioning.defaultProps = {
  className: '',
  onboardStatus: '',
  logLevel: '',
  serviceAccountUserName: '',
  serviceAccountPassword: '',
  activationCode: '',
  canManageDeviceConfig: false,
  isAdmin: false,
  isActive: false,
  onUpdate: () => {},
};

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
  updateCurrentLocation: LocationActions.updateCurrentLocation,
};

export default connect(null, mapDispatchToProps)(Provisioning);
