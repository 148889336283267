import { handleActions } from 'redux-actions';
import { find } from 'lodash';

/*
 * Reducer handles the state for the companies view
 */

export default handleActions(
  {
    ADD_CURRENT_COMPANY_LOCATION: (state, action) => {
      const location = action.payload;
      const hasLocation = find(state.currentCompany.locations, location) !== undefined;

      if (hasLocation) {
        // Do not add the location twice
        return state;
      }

      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          locations: [...state.currentCompany.locations, location],
        },
      };
    },

    REMOVE_CURRENT_COMPANY_LOCATION: (state, action) => {
      const locationData = action.payload;
      const locationItem = find(state.currentCompany.locations, locationData);

      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          locations: state.currentCompany.locations.filter(location => location !== locationItem),
        },
      };
    },

    SET_CURRENT_COMPANY_ALL_LOCATIONS: (state, action) => {
      const {
        selectAll,
        allLocationsObj,
      } = action.payload;

      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          locations: allLocationsObj,
          allLocations: selectAll,
        },
      };
    },

    SET_CURRENT_COMPANY: (state, action) => {
      const currentCompany = action.payload;

      return {
        ...state,
        currentCompany,
      };
    },

    SET_SELECTED_COMPANY: (state, action) => {
      const selected = action.payload;

      return {
        ...state,
        selectedCompany: {
          companyName: selected.name,
          companyImage: selected.image,
        },
      };
    },

    RESET_COMPANY: state => {
      return {
        ...state,
        currentCompany: {},
      };
    },

    FETCH_COMPANY: (state, action) => {
      const {
        selectedCompany: company,
      } = action.payload;

      return {
        ...state,
        currentCompany: {
          _id: company._id,
          active: company.active,
          address1: company.address1,
          address2: company.address2,
          category: company.category,
          city: company.city,
          createdBy: company.createdBy,
          createdDate: company.createdDate,
          imageUrl: company.imageUrl,
          locations: company.locations.map((location) => {
            return {
              companyId: location.companyId,
              locationId: location._id,
            };
          }),
          modifiedBy: company.modifiedBy,
          modifiedDate: company.modifiedDate,
          name: company.name,
          postalCode: company.postalCode,
          salesforceId: company.salesforceId,
          adNetworkEnabled: company.adNetworkEnabled,
          adProgramEnabled: company.adProgramEnabled,
          adProvider: company.adProvider,
          adProviderConfig: company.adProviderConfig,
          requireMessageApproval: company.requireMessageApproval,
          state: company.state,
          tags: company.tags,
          users: company.users,
        },
      };
    },
  },
  // Initial State
  {
    currentCompany: {},
  },
);
