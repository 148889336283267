import React from 'react';

const viArrowUpDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M8 3.887L10.113 6l.94-.94L8 2 4.94 5.06l.947.94L8 3.887zm0 8.226L5.887 10l-.94.94L8 14l3.06-3.06-.947-.94L8 12.113z" />
    </g>
  </svg>
);

export default viArrowUpDown;
