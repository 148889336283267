import React from 'react';
import PropTypes from 'prop-types';
import {
  RelativeTime,
  VibeTooltip,
  VibeIcon,
  viFlag,
  viFlagSlash,
  color,
} from 'vibeguide';
import defaultUserImage from '../../../assets/user-bubble.png';
import './FlagActivityItem.scss';

function FlagActivityItem({
  type,
  date,
  name,
  imageUrl,
  note,
  flagEnabled,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
}) {
  return (
    <div
      className="FlagActivityItem"
      style={{
        paddingLeft: type === 'add-note'
          ? 0
          : 16,
        paddingRight: 16,
      }}
    >
      <div className="activity-content">
        <div className="flag-left">
          {type === 'toggle-flag' ? (
            <div className="toggle-flag">
              <VibeIcon
                className="flag-icon"
                icon={flagEnabled
                  ? viFlag
                  : viFlagSlash}
                color={flagEnabled
                  ? color.fireBrick
                  : color.manatee}
                size={24}
              />

              <div
                className="flag-note"
                style={{
                  color: flagEnabled
                    ? color.fireBrick
                    : color.obsidian,
                }}
              >
                {flagEnabled ? 'Flagged' : 'Unflagged'}
              </div>
            </div>
          ) : null}

          {type === 'comment' ? (
            <div className="flag-note">
              {note}
            </div>
          ) : null}

          {type === 'add-note' ? (
            <input
              className="flag-input"
              type="text"
              value={note}
              placeholder="Add a note..."
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          ) : null}
        </div>

        <div className="flag-right">
          {date ? (
            <div className="flag-date">
              <RelativeTime
                time={date}
              />
            </div>
          ) : null}

          {type !== 'add-note' ? (
            <VibeTooltip
              title={name}
              placement="left"
            >
              <div className="avatar-container">
                <img
                  className="avatar"
                  src={imageUrl || defaultUserImage}
                  alt={name}
                />
              </div>
            </VibeTooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}

FlagActivityItem.propTypes = {
  type: PropTypes.string.isRequired,
  date: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  note: PropTypes.string,
  flagEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

FlagActivityItem.defaultProps = {
  name: 'Unknown',
  date: null,
  imageUrl: null,
  note: '',
  flagEnabled: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
};

export default FlagActivityItem;
