import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  LoadingContent,
} from 'vibeguide';
import CompanyDetails from './CompanyDetails';
import './CompanySidebar.scss';

class CompanySidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      company: {},
    };
  }

  componentDidMount() {
    const {
      companyId,
    } = this.props;

    if (companyId && companyId !== 'new') {
      this.getCompany();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      companyId,
      isNew,
    } = this.props;

    const {
      companyId: prevCompanyId,
    } = prevProps;

    if (companyId && !isNew && companyId !== prevCompanyId) {
      this.getCompany();
    }
  }

  /**
   * Update the company
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        company: {
          ...state.company,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtraPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Close the third panel on the sidebar
   */
  onCloseThirdPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  getCompany = async () => {
    const {
      companyId,
      user,
    } = this.props;

    const options = {
      _id: companyId,
      filters: {
        active: true,
      },
    };

    const company = await API.Company.getById(companyId);
    const users = await API.Company.getUsers(options);
    const locations = await API.Company.getLocations(options);
    const banners = user.can('company_banner.view')
      // get banners assigned to the company
      ? await API.Company.Banner.list({
        companyId,
      })
      // user cannot view banners
      : [];
    const layoutTemplates = user.can('visual_layout_template.view')
      // get templates assigned to the company
      ? await API.Company.LayoutTemplate.list({
        companyId,
      })
      // user cannot view templates
      : [];
    const loopTemplates = user.can('visual_loop_template.view')
      // get templates assigned to the company
      ? await API.Company.LoopTemplate.list({
        companyId,
      })
      // user cannot view templates
      : [];

    company.userCount = users.totalItems;
    company.locationCount = locations.totalItems;
    company.bannerCount = banners.length || 0;
    company.layoutTemplateCount = layoutTemplates.totalItems;
    company.loopTemplateCount = loopTemplates.totalItems;

    this.setState({
      company,
    });
  };

  render() {
    const {
      companyId,
      isNew,
      isDuplicate,
    } = this.props;

    const {
      company,
    } = this.state;

    return (
      <div className="CompanySidebar">
        <CompanyDetails
          company={company}
          isNew={isNew}
          isDuplicate={isDuplicate}
          onClose={this.onClose}
          onCloseExtraPanel={this.onCloseExtraPanel}
          onCloseThirdPanel={this.onCloseThirdPanel}
          onUpdate={this.onUpdate}
          onRefresh={this.getCompany}
        />

        {companyId && !company._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

CompanySidebar.propTypes = {
  companyId: PropTypes.string,
  /** New Company */
  isNew: PropTypes.bool,
  /** Duplicate Company */
  isDuplicate: PropTypes.bool,
};

CompanySidebar.defaultProps = {
  companyId: null,
  isNew: false,
  isDuplicate: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySidebar);
