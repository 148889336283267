import React from 'react';

const viUserCog = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M17.7114 14.8C17.7114 14.56 17.7914 14.32 17.7914 14C17.7914 13.68 17.7914 13.44 17.7114 13.2L19.3914 11.84C19.5514 11.68 19.5514 11.52 19.4714 11.36L17.8714 8.56C17.7914 8.48 17.6314 8.4 17.3914 8.48L15.3914 9.28C14.9914 8.96 14.5114 8.72 14.0314 8.48L13.7114 6.4C13.7914 6.16 13.5514 6 13.3914 6H10.1914C10.0314 6 9.79138 6.16 9.79138 6.32L9.47138 8.48C8.99138 8.64 8.59138 8.96 8.11138 9.28L6.19138 8.48C5.95138 8.4 5.79138 8.48 5.63138 8.64L4.03138 11.44C3.95138 11.52 4.03138 11.76 4.19138 11.92L5.87138 13.2C5.87138 13.44 5.79138 13.68 5.79138 14C5.79138 14.32 5.79138 14.56 5.87138 14.8L4.19138 16.16C4.03138 16.32 4.03138 16.48 4.11138 16.64L5.71138 19.44C5.79138 19.52 5.95138 19.6 6.19138 19.52L8.19138 18.72C8.59138 19.04 9.07138 19.28 9.55138 19.52L9.87138 21.6C9.87138 21.76 10.0314 21.92 10.2714 21.92H13.4714C13.6314 21.92 13.8714 21.76 13.8714 21.6L14.1914 19.52C14.6714 19.28 15.1514 19.04 15.5514 18.72L17.5514 19.52C17.7114 19.6 17.9514 19.52 18.0314 19.36L19.6314 16.56C19.7114 16.4 19.7114 16.16 19.5514 16.08L17.7114 14.8ZM11.7914 16.8C10.2714 16.8 8.99138 15.52 8.99138 14C8.99138 12.48 10.2714 11.2 11.7914 11.2C13.3114 11.2 14.5914 12.48 14.5914 14C14.5914 15.52 13.3114 16.8 11.7914 16.8Z" />
    </g>
  </svg>
);

export default viUserCog;
