import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  Cards,
  PageLayout,
  Paginator,
  EventCard,
  API,
  GlobalActions,
  NavigationHelper,
  SearchByHelper,
  SortByHelper,
  withRouter,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import EventSidebar from '../../../../Programs/Events/Sidebar/EventSidebar';
import './Events.scss';

class Events extends Component {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;

    this.state = {
      events: [],
      totalItems: 0,
      refresh: false,
    };
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams();
    const isNew = qs.type === 'new';
    const isView = qs.eventId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.eventId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.eventId || qs.type === 'new') {
        this.sidebar(qs.type, qs.eventId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      eventId: null,
      type: null,
    }, {
      keepPage: true,
    });

    this.refresh();
    history(url);
  };

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    this.setState({
      events: collection,
      totalItems,
    });
  };

  refresh = () => {
    // Refresh Page Layout
    this.setState({
      refresh: true,
    }, () => {
      this.setState({
        refresh: false,
      });
    });
  };

  /**
   * Determine whether or not to show or hide the sidebar
   */
  sidebar = (type, eventId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 475,
      children: (
        <EventSidebar
          eventId={eventId}
          isNew={type === 'new'}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
      currentLocation,
      scrollRef,
    } = this.props;

    const {
      events,
      totalItems,
      refresh,
    } = this.state;

    const locationId = get(currentLocation, '_id', null);
    const canViewEvent = user.can('event.view');

    return (
      <div className="Events">
        <PageLayout
          searchOptions={SearchByHelper.events}
          sortOptions={SortByHelper.events}
          disableSort={SortByHelper.events.length <= 0}
          scrollRef={scrollRef}
          refresh={refresh}
          filterParamFirst={locationId}
          filter={API.Location.listEvents}
          onFilter={this.onFilter}
          disableView
        />

        <Grid className="container" container>
          <Grid xs={12} item>
            <Paginator
              className="content-paginator"
              totalLoaded={events.length}
              totalItems={totalItems}
              label="Events"
            />
          </Grid>

          <Grid xs={12} item>
            <Cards>
              {events.map((event) => {
                return (
                  <EventCard
                    key={event._id}
                    event={event}
                    link={canViewEvent
                      ? NavigationHelper.updateParams({
                        eventId: event._id,
                        type: null,
                      })
                      : null}
                    allowClick={canViewEvent}
                  />
                );
              })}
            </Cards>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
