import React, { PureComponent } from 'react';
import classNames from 'classnames';
import {
  random,
} from 'lodash';
import image1 from '../assets/kraken.svg';
import './KonamiCode.scss';

const KEY_UP = 'ArrowUp';
const KEY_DOWN = 'ArrowDown';
const KEY_LEFT = 'ArrowLeft';
const KEY_RIGHT = 'ArrowRight';
const KEY_B = 'b';
const KEY_A = 'a';

// Sequence for the Konami Code
const code = [
  KEY_UP,
  KEY_UP,
  KEY_DOWN,
  KEY_DOWN,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_B,
  KEY_A,
];

class KonamiCode extends PureComponent {
  constructor(props) {
    super(props);

    // Timeout to wait for the next code key
    this.nextKeyTimeout = null;
    // Delay before resetting the listener (milliseconds)
    this.nextKeyDelay = 500;
    // Wait for the initial key of the Konami Code
    this.nextKeyIndex = 0;

    // Interval to show the animation images
    this.animationInterval = null;

    this.state = {
      show: false,
      animateFirstImage: false,
      firstImageX: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    // Clear the timeout
    clearTimeout(this.nextKeyTimeout);

    // Remove listener for Konami Code
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (e) => {
    const {
      key,
    } = e;

    if (key === code[this.nextKeyIndex]) {
      console.warn('Konami Code:', key);

      // Next key
      this.nextKeyIndex += 1;

      clearTimeout(this.nextKeyTimeout);
      this.nextKeyTimeout = setTimeout(this.reset, this.nextKeyDelay);

      if (this.nextKeyIndex === 10) {
        // Reached the end of the code!
        this.onComplete();
      }
    } else if (this.nextKeyIndex !== 0) {
      // Invalid key resets the code
      this.reset();
    }
  };

  /**
   * When the code is completed
   */
  onComplete = () => {
    console.warn('Konami Code: Completed!');

    this.setState({
      show: true,
    });

    clearTimeout(this.nextKeyTimeout);
    this.reset();

    // Start the animation cycle
    this.startAnimation(2, 5);

    this.animationInterval = setInterval(() => {
      this.startAnimation(2, 5);
    }, 10000);
  };

  /**
   * Reset the listener
   */
  reset = () => {
    this.nextKeyIndex = 0;
  };

  /**
   * Close the info
   */
  close = () => {
    clearInterval(this.animationInterval);

    this.setState({
      show: false,
    });
  };

  /**
   * Start the animation for the version mascot
   */
  startAnimation = (startDelay, stopDelay) => {
    // get random number to show the animation horizontally
    const x = random(0, (window.innerWidth - 250));

    // Start animating the first image
    setTimeout(() => {
      this.setState({
        animateFirstImage: true,
        firstImageX: x,
      });
    }, startDelay * 1000);

    // Finish animating the first image
    setTimeout(() => {
      this.setState({
        animateFirstImage: false,
      });
    }, stopDelay * 1000);
  };

  render() {
    const {
      show,
      animateFirstImage,
      firstImageX,
    } = this.state;

    return (
      <div
        className={classNames('KonamiCode', { show })}
        onClick={this.close}
      >
        <div className="info-container">
          <div className="version-name">
            KRAKEN
          </div>

          <div className="version-number">
            v{process.env.VERSION}
          </div>
        </div>

        <div
          className={classNames('animation-image', 'image-1', { 'animate-1': animateFirstImage })}
          style={{
            left: firstImageX,
          }}
        >
          <img
            src={image1}
            alt="animation 1"
            style={{
              height: 256,
            }}
          />
        </div>
      </div>
    );
  }
}

export default KonamiCode;
