/**
 * Search by options for Baselines
 */
export const baselines = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Locations
 */
export const locations = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'City',
    attr: 'address.city',
  },
  {
    label: 'State',
    attr: 'address.state',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Locations from local data
 */
export const locationsLocal = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'City',
    attr: 'city',
  },
  {
    label: 'State',
    attr: 'state',
  },
];

/**
 * Search by options for Events
 */
export const events = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'Description',
    attr: 'description',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Message Blocks
 */
export const messageBlocks = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Messages',
    attr: 'messages',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Message Requests
 */
export const messageRequests = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'Requester',
    attr: 'createdByUserName',
  },
  {
    label: 'Script',
    attr: 'script',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Playlists
 */
export const playlists = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Genre',
    attr: 'category',
  },
  {
    label: 'Description',
    attr: 'description',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Mixes
 */
export const mixes = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'Description',
    attr: 'name',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
];

/**
 * Search by options for Advertisers
 */
export const advertisers = [
  {
    label: 'Name',
    attr: 'name',
  },
];

/**
 * Search by options for Users
 */
export const users = [
  {
    label: 'First Name',
    attr: 'fname',
  },
  {
    label: 'Last Name',
    attr: 'lname',
  },
  {
    label: 'Email',
    attr: 'email',
  },
];

/**
 * Search by options for Companies
 */
export const companies = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Category',
    attr: 'category',
  },
  {
    label: 'Salesforce ID',
    attr: 'salesforceId',
  },
  {
    label: 'Tags',
    attr: 'tags.name',
  },
  {
    label: 'Address 1',
    attr: 'address.line1',
  },
  {
    label: 'Address 2',
    attr: 'address.line2',
  },
  {
    label: 'City',
    attr: 'address.city',
  },
  {
    label: 'State',
    attr: 'address.state',
  },
  {
    label: 'Zip',
    attr: 'address.postalCode',
  },
];

/**
 * Search by options for Companies from local data
 */
export const companiesLocal = [
  {
    label: 'Name',
    attr: 'name',
  },
];

/**
 * Search by options for Tags
 */
export const tags = [
  {
    label: 'Name',
    attr: 'name',
  },
];

export default {
  baselines,
  locations,
  events,
  messageBlocks,
  messageRequests,
  playlists,
  mixes,
  advertisers,
  users,
  companies,
  tags,
};
