// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationDetails .header-content > .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.LocationDetails .details-title {
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 900;
}
.LocationDetails .details-title.first {
  margin-top: 0;
}
.LocationDetails .data-row {
  display: flex;
  margin-bottom: 16px;
}
.LocationDetails .data-row:last-child {
  margin-bottom: 0;
}
.LocationDetails .data-row > .data-fields {
  flex-grow: 1;
}
.LocationDetails .data-row > .field-container {
  margin: 0 8px;
}
.LocationDetails .data-row > .field-container:first-child {
  margin-left: 0;
}
.LocationDetails .data-row > .field-container:last-child {
  margin-right: 0;
}
.LocationDetails .panel-footer .btn-save {
  margin-right: 12px;
}
.LocationDetails .panel-footer .toolbar-buttons {
  display: flex;
  align-items: center;
}
.LocationDetails .panel-footer .toolbar-buttons .toolbar-button {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/Sidebar/LocationDetails.scss"],"names":[],"mappings":"AAII;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHN;AAOE;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AALJ;AAOI;EACE,aAAA;AALN;AASE;EACE,aAAA;EACA,mBAAA;AAPJ;AASI;EACE,gBAAA;AAPN;AAUI;EACE,YAAA;AARN;AAWI;EACE,aAAA;AATN;AAWM;EACE,cAAA;AATR;AAYM;EACE,eAAA;AAVR;AAiBI;EACE,kBAAA;AAfN;AAkBI;EACE,aAAA;EACA,mBAAA;AAhBN;AAkBM;EACE,kBAAA;AAhBR","sourcesContent":[".LocationDetails {\n\n  .header-content {\n\n    > .title {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n\n  .details-title {\n    margin-top: 24px;\n    margin-bottom: 16px;\n    font-size: 14px;\n    font-weight: 900;\n\n    &.first {\n      margin-top: 0;\n    }\n  }\n\n  .data-row {\n    display: flex;\n    margin-bottom: 16px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n\n    > .data-fields {\n      flex-grow: 1;\n    }\n\n    > .field-container {\n      margin: 0 8px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n\n      &:last-child {\n        margin-right: 0;\n      }\n    }\n  }\n\n  .panel-footer {\n\n    .btn-save {\n      margin-right: 12px;\n    }\n\n    .toolbar-buttons {\n      display: flex;\n      align-items: center;\n\n      .toolbar-button {\n        margin-right: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
