// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonthNavigate {
  display: flex;
  align-items: center;
  user-select: none;
}
.MonthNavigate .month-nav-icon {
  cursor: pointer;
}
.MonthNavigate .month-text {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
}
.MonthNavigate .month-text.left {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Calendar/Month/MonthNavigate.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN","sourcesContent":[".MonthNavigate {\n  display: flex;\n  align-items: center;\n  user-select: none;\n\n  .month-nav-icon {\n    cursor: pointer;\n  }\n\n  .month-text {\n    flex-grow: 1;\n    font-size: 16px;\n    font-weight: 900;\n    text-align: center;\n\n    &.left {\n      text-align: left;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
