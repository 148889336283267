import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';

function RequireAuth({
  authenticated,
  user,
  can,
  anyOf,
  children,
}) {
  const location = useLocation();

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
      />
    );
  }

  // User must have ALL these permissions
  const cannotCount = can.filter(permission => user.can(permission) === false).length;
  if (cannotCount > 0) {
    // user does not have permission to view this page
    const rootUrl = user.getRootUrl();

    return (
      <Navigate
        to={rootUrl}
      />
    );
  }

  // User must have ONE or MORE of these permissions
  if (anyOf.length > 0) {
    const anyOfCount = anyOf.filter(permission => user.can(permission) === true).length;
    if (anyOfCount <= 0) {
      // user does not have permission to view this page
      const rootUrl = user.getRootUrl();

      return (
        <Navigate
          to={rootUrl}
        />
      );
    }
  }

  return children;
}

RequireAuth.propTypes = {
  can: PropTypes.arrayOf(PropTypes.string),
  anyOf: PropTypes.arrayOf(PropTypes.string),
};

RequireAuth.defaultProps = {
  can: [],
  anyOf: [],
};

function mapStateToProps(state) {
  return {
    authenticated: state.login.authenticated,
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(RequireAuth);
