import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
  sortBy,
  forEach,
} from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PageLayout from '../PageLayout/PageLayout';
import VibeSplash from '../VibeSplash/VibeSplash';
import CompanyItem from './CompanyItem';
import SwapButton from '../SwapButton/SwapButton';
import Field from '../Field/Field';
import {
  companiesLocal as searchByCompaniesLocal,
} from '../../helpers/SearchBy';
import {
  companiesLocal as sortByCompaniesLocal,
} from '../../helpers/SortBy';
import defaultLocationImage from '../../assets/default_location.png';
import color from '../../sass/color.scss';
import './LocationsAssigned.scss';

class LocationsAssigned extends Component {
  constructor(props) {
    super(props);

    const {
      locationsData: {
        companies,
      },
    } = this.props;

    this._scrollRef = null;
    this.companyRefs = {};

    this.state = {
      companies,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      locationsData: {
        companies,
      },
    } = this.props;

    const {
      locationsData: {
        companies: prevCompanies,
      },
    } = prevProps;

    // Update selected locations by grouping them by company
    if (JSON.stringify(companies) !== JSON.stringify(prevCompanies)) {
      this.setState({
        companies: sortBy(companies, 'name'),
      });
    }
  }

  onFilter = (data) => {
    const {
      collection,
    } = data;

    this.setState({
      companies: collection,
    });
  };

  onScroll = () => {
    const {
      stickyScroll,
    } = this.props;

    const {
      companies,
    } = this.state;

    if (!stickyScroll) {
      return;
    }

    forEach(this.companyRefs, (ref, companyId) => {
      // Top of scrollable content area (height of all the headers)
      const topOfContent = 323;

      if (ref === null) {
        return;
      }

      const rect = ref.getBoundingClientRect();
      const company = find(companies, { _id: companyId });

      if (rect.top <= topOfContent && rect.top > (topOfContent - rect.height + 72)) {
        // Company item is in the "sticky" zone
        if (!company.sticky) {
          this.setState((state) => {
            return {
              companies: state.companies.map((c) => {
                if (c._id === company._id) {
                  return {
                    ...c,
                    fixToBottom: false,
                    sticky: true,
                  };
                }

                return c;
              }),
            };
          });
        }

        // Do not run further
        return;
      }

      if (company.sticky && !company.fixToBottom && rect.bottom <= 395 && rect.bottom > 323) {
        this.setState((state) => {
          return {
            companies: state.companies.map((c) => {
              if (c._id === company._id) {
                return {
                  ...c,
                  fixToBottom: true,
                };
              }

              return c;
            }),
          };
        });
      }

      // Company is not inside the top zone
      if (company.sticky) {
        this.setState((state) => {
          return {
            companies: state.companies.map((c) => {
              if (c._id === company._id) {
                return {
                  ...c,
                  // fixToBottom: false,
                  sticky: false,
                };
              }

              return c;
            }),
          };
        });
      }
    });
  };

  render() {
    const {
      selectAll,
      selectedLocations,
      onDeselectAll,
      onDeselectLocation,
      locationsData: {
        companies: originalCompanies,
        locations,
      },
      customToolbar,
      allowChanges,
      allowAssignCompany,
      required,
    } = this.props;

    const {
      companies,
    } = this.state;

    const hasNoLocations = companies.length <= 0;

    return (
      <div className="LocationsAssigned two-panel">
        <div className="title-container">
          <div className="title">
            Assigned Locations
          </div>

          {required ? (
            <Field
              type="hidden"
              label="Locations"
              value={selectAll || selectedLocations.length > 0 ? 'has locations' : ''}
              requiredLabel="At least 1 location is required"
              requiredLabelSize={14}
              required
            />
          ) : null}

          {customToolbar}
        </div>

        <PageLayout
          className="assigned-layout"
          sortOptions={sortByCompaniesLocal}
          searchOptions={searchByCompaniesLocal}
          scrollRef={this._scrollRef}
          placeholder="Search Companies..."
          collection={originalCompanies}
          searchStyle={{
            maxWidth: 'auto',
          }}
          onFilter={this.onFilter}
          disableView
        />

        <div className="locations-content-root">
          {hasNoLocations || selectAll ? (
            <VibeSplash
              image={defaultLocationImage}
              title={selectAll
                ? 'All locations have been assigned'
                : 'No Locations Assigned'}
              titleStyle={{
                color: selectAll
                  ? color.aquaForest
                  : color.violetVibe,
                textTransform: 'uppercase',
              }}
              subtitle={selectAll
                ? 'This is assigned to all current and future locations.'
                : 'Assign locations from the left panel to continue.'}
            />
          ) : (
            <PerfectScrollbar
              containerRef={(ref) => { this._scrollRef = ref; }}
              onScrollY={this.onScroll}
            >
              <div className="locations-content">
                <div className="assigned-toolbar">
                  {allowChanges ? (
                    <SwapButton
                      className="unassign-btn"
                      text="Unassign All"
                      color={color.aquaForest}
                      arrowPlacement="left"
                      arrowPosition="left"
                      onClick={onDeselectAll}
                    />
                  ) : null}
                </div>

                <div className="locations-list">

                  {companies.map((company) => {
                    const companyLocations = locations.filter(location => location.companyId === company._id);

                    return (
                      <div
                        key={company._id}
                        ref={(ref) => { this.companyRefs[company._id] = ref; }}
                      >
                        <CompanyItem
                          company={company}
                          selectedLocations={selectedLocations}
                          companyLocations={companyLocations}
                          allowChanges={allowChanges}
                          allowExpand={companyLocations.length > 0}
                          allowAssignCompany={allowAssignCompany}
                          onDeselectLocation={onDeselectLocation}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </PerfectScrollbar>
          )}
        </div>
      </div>
    );
  }
}

LocationsAssigned.propTypes = {
  /** Select All locations */
  selectAll: PropTypes.bool,
  /** Selected locations */
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Selected Locations Data */
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      allLocations: PropTypes.bool,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
    })),
    locations: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      city: PropTypes.string,
      companyId: PropTypes.string,
      companyName: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    })),
  }),
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** Allow companies to be assigned */
  allowAssignCompany: PropTypes.bool,
  /** At least one location is required */
  required: PropTypes.bool,
  /** Sticky Scroll company header */
  stickyScroll: PropTypes.bool,
  // When all locations are deselected
  onDeselectAll: PropTypes.func,
  /** When a location is deselected */
  onDeselectLocation: PropTypes.func,
};

LocationsAssigned.defaultProps = {
  selectAll: false,
  selectedLocations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  customToolbar: null,
  allowChanges: false,
  allowAssignCompany: false,
  required: false,
  stickyScroll: false,
  onDeselectAll: () => {},
  onDeselectLocation: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(LocationsAssigned);
