import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  Field2 as Field,
  VibeIcon,
  viChevronDown,
  viCheck,
  color,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './Information.scss';

class Information extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    this.state = {
      canSetCompany: user.sysAdmin,
      showColorMenu: false,
      colors: [
        { name: 'red', color: color.boxRed },
        { name: 'pink', color: color.boxPink },
        { name: 'orange', color: color.boxOrange },
        { name: 'yellow', color: color.boxYellow },
        { name: 'green', color: color.boxGreen },
        { name: 'darkgreen', color: color.boxDarkGreen },
        { name: 'blue', color: color.boxBlue },
        { name: 'darkblue', color: color.boxDarkBlue },
        { name: 'purple', color: color.boxPurple },
        { name: 'darkpurple', color: color.boxDarkPurple },
      ],
    };
  }

  componentDidMount() {
    const {
      user,
    } = this.props;

    const {
      canSetCompany,
    } = this.state;

    if (!canSetCompany) {
      this.onSetCompany({
        id: user.companyId,
        value: user.companyName,
      });
    }
  }

  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the company field is changed
   */
  onSetCompany = ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      companyId: currCompanyId,
      tags,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  onChangeColor = (e) => {
    const {
      onUpdate,
    } = this.props;

    const color = get(e, 'target.dataset.color', null);

    onUpdate({
      color,
    });
  };

  onBlurColor = () => {
    this.setState({
      showColorMenu: false,
    });
  };

  toggleColorMenu = () => {
    const {
      disableInput,
    } = this.props;

    if (!disableInput) {
      this.setState((state) => {
        return {
          showColorMenu: !state.showColorMenu,
        };
      });
    }
  };

  render() {
    const {
      isNew,
      user,
      eventColor,
      title,
      description,
      companyName,
      companyId,
      tags,
      disableInput,
      disableCompany,
    } = this.props;

    const {
      colors,
      canSetCompany,
      showColorMenu,
    } = this.state;

    return (
      <div className="Information info-event">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <div className="data-row">
          <div className="data-item item-color">
            <Field
              type="custom"
              label="Color"
              value={eventColor}
              marginBottom={10}
            >
              <div
                className="color-container"
                tabIndex={-1}
                onBlur={this.onBlurColor}
                onClick={this.toggleColorMenu}
              >
                <div
                  className="box"
                  style={{
                    background: eventColor,
                  }}
                />

                <div className="color-toggle">
                  <VibeIcon
                    icon={viChevronDown}
                    color={color.obsidian}
                    size={24}
                  />
                </div>

                {showColorMenu ? (
                  <div className="color-menu">
                    <Grid container>
                      {colors.map((c, index) => {
                        return (
                          <Grid
                            key={index}
                            className="color-menu-item"
                            xs={6}
                            item
                          >
                            <div
                              className="box"
                              style={{
                                background: c.color,
                              }}
                              data-color={c.color}
                              onClick={this.onChangeColor}
                            >
                              {eventColor === c.color ? (
                                <div className="color-selected">
                                  <VibeIcon
                                    icon={viCheck}
                                    color={color.white}
                                    size={16}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                ) : null}
              </div>
            </Field>
          </div>

          <div className="data-item item-title">
            <Field
              type="text"
              label="Name"
              name="title"
              placeholder="Event Name"
              value={title}
              tabIndex={1}
              disabled={disableInput}
              validation={{
                check: true,
                checkEmpty: !isNew,
                events: [
                  'init',
                  'onChange',
                ],
              }}
              onChange={this.onChange}
              required
              autoFocus
            />
          </div>
        </div>

        {canSetCompany ? (
          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={12} item>
              <Field
                type="company"
                label="Company"
                name="companyName"
                value={companyName}
                tabIndex={2}
                companyProps={{
                  companyId,
                  companyName,
                }}
                disabled={disableCompany || disableInput}
                validation={{
                  check: true,
                  checkEmpty: !isNew,
                  events: [
                    'init',
                    'onChange',
                  ],
                }}
                onChange={this.onSetCompany}
                required
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <Field
              className="description-textarea"
              type="textarea"
              label="Description"
              name="description"
              placeholder="Describe this event..."
              value={description}
              tabIndex={3}
              disabled={disableInput}
              validation={{
                check: true,
                checkEmpty: !isNew,
                events: [
                  'init',
                  'onChange',
                ],
              }}
              onChange={this.onChange}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                companyId,
                tags,
                allowTypes: ['client', 'admin'],
                showAs: 'list',
                assign: user.can('event.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={4}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  isNew: PropTypes.bool,
  eventColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  disableCompany: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  isNew: false,
  eventColor: '',
  title: '',
  description: '',
  companyId: '',
  companyName: '',
  tags: [],
  disableInput: false,
  disableCompany: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
