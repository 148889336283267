import React from 'react';

const viEvent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M11.333 8.667H8V12h3.333V8.667zm-.666-7.334v1.334H5.333V1.333H4v1.334h-.667C2.6 2.667 2 3.267 2 4v9.333c0 .734.6 1.334 1.333 1.334h9.334c.733 0 1.333-.6 1.333-1.334V4c0-.733-.6-1.333-1.333-1.333H12V1.333h-1.333zm2 12H3.333V6h9.334v7.333z" />
    </g>
  </svg>
);

export default viEvent;
