import viSpeaker from '../icons/viSpeaker';
import viTv from '../icons/viTv';
import viHelp from '../icons/viHelp';

/**
 * List of supported Media Types
 */
export const list = [
  {
    label: 'Audio',
    value: 'audio',
  },
  {
    label: 'Visual',
    value: 'visual',
  },
];

/**
 * Get icon for the media format
 */
export function getIcon(mediaFormat) {
  switch (mediaFormat) {
    case 'visual':
      return viTv;

    case 'audio':
      return viSpeaker;

    default:
      return viHelp;
  }
}

/**
 * Get tooltip for the media format
 */
export function getTooltip(mediaFormat) {
  switch (mediaFormat) {
    case 'visual':
      return 'Visual';

    case 'audio':
      return 'Audio';

    default:
      return 'Unknown';
  }
}

export default {
  list,
  getIcon,
  getTooltip,
};
