import { createAction } from 'redux-actions';
import {
  find,
} from 'lodash';
import API from '../../api';

export const setTargetTagActive = createAction('SET_TARGET_TAG_ACTIVE');
export const addTargetTag = createAction('ADD_TARGET_TAG');
export const addTargetTags = createAction('ADD_TARGET_TAGS');
export const removeTargetTag = createAction('REMOVE_TARGET_TAG');
export const updateTargetTag = createAction('UPDATE_TARGET_TAG');
export const setLocationActiveMenuItem = createAction('SET_LOCATION_ACTIVE_MENU_ITEM');
export const resetTargetTags = createAction('RESET_TARGET_TAGS');

/**
 * Get Children
 */
async function getChildren(tagId, dispatch) {
  const children = await API.TargetTag.getChildren(tagId, { filters: { active: true } });

  // Add the child tags
  dispatch(addTargetTags(children));

  // Update the main tag that the children are loaded
  dispatch(updateTargetTag({
    _id: tagId,
    childrenLoaded: true,
  }));

  return children;
}

/**
 * Get all Children
 */
const getAllChildren = (children, dispatch, getState) => {
  const newDataArray = children.reduce(async (acc, data) => {
    // const accumulator = await acc.resolve();
    const accumulator = await acc;
    const {
      targetTag: {
        tags,
      },
    } = getState();

    // Get children
    const newChildren = data.hasChildren && !data.childrenLoaded
      ? await getChildren(data._id, dispatch)
      : tags.filter(tag => tag.parentId === data._id);

    const moreChildren = newChildren.length > 0
      ? await getAllChildren(newChildren, dispatch, getState)
      : [];

    return Promise.resolve([...accumulator, ...moreChildren]);
  }, Promise.resolve(children));

  return newDataArray;
};

/**
 * Get all Parents
 */
const getParents = (parents, dispatch, getState) => {
  const newDataArray = parents.reduce(async (acc, data) => {
    const accumulator = await acc;
    const {
      targetTag: {
        tags,
      },
    } = getState();

    // Get parent
    const parent = find(tags, { _id: data.parentId });

    const moreParents = parent
      ? await getParents([parent], dispatch, getState)
      : [];

    return Promise.resolve([...accumulator, ...moreParents]);
  }, Promise.resolve(parents));

  return newDataArray;
};

/**
 * Select the target tag
 */
export function selectTargetTag(tagId, selectAll = false) {
  return async (dispatch, getState) => {
    let {
      targetTag: {
        tags,
      },
    } = getState();

    const tag = find(tags, { _id: tagId });

    if (!tag) {
      console.error('Tag', tagId, 'not found');
      return;
    }

    // Select the tag
    dispatch(updateTargetTag({
      _id: tagId,
      selected: true,
      allChildrenSelected: (!tag.allChildrenSelected && selectAll) || tag.allChildrenSelected,
    }));

    tags = getState().targetTag.tags;

    if (tag.hasChildren) {
      const children = !tag.childrenLoaded
        ? await getChildren(tagId, dispatch)
        : tags.filter(tag => tag.parentId === tagId);

      const allChildren = await getAllChildren(children, dispatch, getState);
      // console.warn('allChildren', allChildren);

      if (selectAll) {
        // Get all unselected children
        const unselected = allChildren.filter(child => !child.selected);

        await unselected.forEach(async (child) => {
          // Select the child
          await dispatch(updateTargetTag({
            _id: child._id,
            selected: true,
            allChildrenSelected: true,
          }));
        });
      }
    }

    tags = getState().targetTag.tags;

    const parent = find(tags, { _id: tag.parentId });

    if (parent) {
      const parents = await getParents([parent], dispatch, getState);

      await parents.forEach(async (parent) => {
        const children = !tag.childrenLoaded
          ? await getChildren(parent._id, dispatch)
          : tags.filter(tag => tag.parentId === parent._id);

        const parentAllChildren = await getAllChildren(children, dispatch, getState);
        // console.warn('Parent Children', parent.name, parentAllChildren);

        // Get all unselected children
        const unselected = parentAllChildren.filter(child => !child.selected);

        if (unselected.length <= 0) {
          // All children of this parent is selected
          // console.warn('ALL CHILDREN SELECTED', parent);

          dispatch(updateTargetTag({
            _id: parent._id,
            allChildrenSelected: true,
          }));
        }

        if (!parent.selected) {
          // Update parent to show some children are selected
          dispatch(updateTargetTag({
            _id: parent._id,
            someChildrenSelected: true,
          }));
        }

        // console.warn('Parent', parent);
      });
    }
  };
}

/**
 * Select the target tag and all children
 */
export function selectTargetTagAll(tagId) {
  return async (dispatch, getState) => {
    const {
      targetTag: {
        tags,
      },
    } = getState();

    const tag = find(tags, { _id: tagId });

    if (!tag) {
      console.error('Tag', tagId, 'not found');
      return;
    }

    // Select the tag
    dispatch(updateTargetTag({
      _id: tagId,
      selected: true,
      allChildrenSelected: true,
    }));

    if (tag.hasChildren) {
      const children = !tag.childrenLoaded
        ? await getChildren(tagId, dispatch)
        : tags.filter(tag => tag.parentId === tagId);

      const allChildren = await getAllChildren(children, dispatch, getState);
      // console.warn('allChildren selectTargetTagAll', allChildren);

      // Get all unselected children
      const unselected = allChildren.filter(child => !child.selected);

      unselected.forEach((child) => {
        // Select the child
        dispatch(updateTargetTag({
          _id: child._id,
          selected: true,
          allChildrenSelected: true,
        }));
      });
    }
  };
}

/**
 * Deselect the target tag
 */
export function deselectTargetTag(tagId) {
  return async (dispatch, getState) => {
    let {
      targetTag: {
        tags,
      },
    } = getState();

    const tag = find(tags, { _id: tagId });

    if (!tag) {
      console.error('Tag', tagId, 'not found');
      return;
    }

    // Deselect the tag
    dispatch(updateTargetTag({
      _id: tagId,
      selected: false,
      allChildrenSelected: false,
      someChildrenSelected: false,
    }));

    tags = getState().targetTag.tags;

    if (tag.hasChildren && tag.allChildrenSelected) {
      const children = !tag.childrenLoaded
        ? await getChildren(tagId, dispatch)
        : tags.filter(tag => tag.parentId === tagId);

      const allChildren = await getAllChildren(children, dispatch, getState);
      // console.warn('allChildren deselectTargetTag', allChildren);

      // Get all selected children
      const selected = allChildren.filter(child => child.selected);

      await selected.forEach(async (child) => {
        // Deselect the child
        await dispatch(updateTargetTag({
          _id: child._id,
          selected: false,
          allChildrenSelected: false,
          someChildrenSelected: false,
        }));
      });
    }

    tags = getState().targetTag.tags;

    const parent = find(tags, { _id: tag.parentId });

    if (parent) {
      const parents = await getParents([parent], dispatch, getState);

      await parents.forEach(async (parent) => {
        const children = !parent.childrenLoaded
          ? await getChildren(parent._id, dispatch)
          : tags.filter(tag => tag.parentId === parent._id);

        const allChildren = await getAllChildren(children, dispatch, getState);
        // Get the selected children
        const selected = allChildren.filter(child => child.selected);

        // Update parent to show not all children are selected
        dispatch(updateTargetTag({
          _id: parent._id,
          allChildrenSelected: false,
          someChildrenSelected: selected.length > 0,
        }));

        // console.warn('Parent', parent);
      });
    }
  };
}

export default {
  setTargetTagActive,
  addTargetTag,
  addTargetTags,
  removeTargetTag,
  updateTargetTag,
  resetTargetTags,
  selectTargetTag,
  selectTargetTagAll,
  deselectTargetTag,
};
