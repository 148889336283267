import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  API,
  NavigationHelper,
  GlobalActions,
  NewMixActions,
  TableStations,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import MixSidebar from './Sidebar/MixSidebar';
import './MixesContainer.scss';

class MixesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.stationId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.stationId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.stationId || qs.type === 'new') {
        this.sidebar(qs.type, qs.stationId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
      resetMix,
    } = this.props;

    resetMix();

    const url = NavigationHelper.updateParams({
      stationId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  sidebar = (type, stationId = null) => {
    const {
      setPanel,
      setMixId,
    } = this.props;

    if (stationId) {
      setMixId({
        _id: stationId,
      });
    }

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <MixSidebar
          stationId={stationId}
          isNew={type === 'new'}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    return (
      <div className="MixesContainer">
        <SubNavigation
          title="Stations"
          buttons={[
            {
              text: 'New Station',
              icon: viAdd,
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userCan: [
                'mix.create',
              ],
            },
          ]}
        />

        <TableStations
          columns={[
            '.',
            'Name',
            'Description',
            'Company',
            'Duration',
            'Playlists',
            'Modified',
            'Tags',
            '...',
          ]}
          fetch={API.Mix.list}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Stations',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Stations',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          rowLink={{
            stationId: '_id',
          }}
          menuItems={[
            { name: 'Archive', userCan: 'mix.delete' },
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  setMixId: NewMixActions.setMixData,
  resetMix: NewMixActions.resetNewMix,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MixesContainer));
