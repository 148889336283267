// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Information .messageBlock-description {
  height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Messages/MessageBlocks/Sidebar/Information.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;AADJ","sourcesContent":[".Information {\n\n  .messageBlock-description {\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
