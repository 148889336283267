import React, { Component } from 'react';
import {
  find,
  isUndefined,
  isFunction,
} from 'lodash';
import {
  API,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import UploadFile from './UploadFile';

class UploadStatus extends Component {
  constructor(props) {
    super(props);

    const {
      files,
    } = props;

    this.state = {
      files,
    };
  }

  componentDidMount() {
    const {
      files,
    } = this.state;

    if (files.length > 0) {
      // Upload a file
      this.uploadMusic();
    }
  }

  /**
   * Upload Music to the API
   */
  uploadMusic = async () => {
    const {
      files,
    } = this.state;

    // Get the next item to upload in the queue
    const upload = find(files, { isComplete: false, error: null });

    if (!upload) {
      // Nothing left to upload
      this.uploadComplete();
      return;
    }

    const music = await API.Music.uploadMusic(upload.file, (currentProgress) => {
      if (!isUndefined(currentProgress)) {
        // Update the progress on the file
        upload.progress = Math.ceil(currentProgress);

        this.setState({
          files,
        });
      }
    });

    // Set this upload object as completed
    upload.isComplete = true;

    if (music.error) {
      // API threw an error while uploading, display it
      upload.error = music.error;
    }

    this.setState({
      files,
    }, () => {
      // Completed uploading of this file, run to see if there are more to upload
      this.uploadMusic();
    });
  };

  /**
   * Uploading Complete
   */
  uploadComplete = () => {
    const {
      onComplete,
    } = this.props;

    if (isFunction(onComplete)) {
      onComplete();
    }
  };

  render() {
    const {
      files,
    } = this.state;

    const uploadingHtml = [];

    files.forEach((upload, index) => {
      uploadingHtml.push(
        <Grid key={index} item xs={12} sm={6} md={4}>
          <UploadFile upload={upload} />
        </Grid>,
      );
    });

    return (
      <Grid container className="UploadStatus" spacing={3}>
        {uploadingHtml}
      </Grid>
    );
  }
}

export default UploadStatus;
