import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import {
  API,
  withRouter,
  viCheckCircleOutline,
} from 'vibeguide';
import { Grid } from '@mui/material';
import OrderStatusCard from '../OrderStatusCard';

function CompletedOrderTables() {
  const [summaries, setSummaries] = useState({});

  /**
   * Get the Order Status Summary
   */
  const getSummaries = async () => {
    const past = await API.Order.Reporting.Summaries.Completed.past();

    setSummaries({
      past,
    });
  };

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <Grid container spacing={2} className="CompletedOrderTables">
      <Grid item xs={12}>
        <OrderStatusCard
          title="Completed in the last 30 Days"
          linkUrl="../../completed/past"
          icon={viCheckCircleOutline}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'deliveredImpressions', 'startDate', 'endDate'],
            data: get(summaries, 'past', []),
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(CompletedOrderTables);
