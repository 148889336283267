import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  find,
  sortBy,
} from 'lodash';
import DeviceAttributesList from './DeviceAttributes/DeviceAttributesList';
import DeviceAttributeAddItem from './DeviceAttributes/DeviceAttributeAddItem';
import DeviceAttributeCopyToLocations from './DeviceAttributes/DeviceAttributeCopyToLocations';
import DeviceAttributeDelete from './DeviceAttributes/DeviceAttributeDelete';
import DeviceAttributeLocations from './DeviceAttributes/DeviceAttributeLocations';
import DeviceAttributeAddToLocation from './DeviceAttributes/DeviceAttributeAddToLocation';
import './DeviceAttributes.scss';

class DeviceAttributes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAssignAttribute: false,
      showNewAttribute: false,
      showCopyToLocations: false,
      showLocations: false,
      showDeleteAttributes: false,
      deviceAttributes: [],
      deviceAttributesAssign: [],
      activeAttribute: {},
      selectedAttributes: [],
      resetList: false,
      mergeMode: 'replace',
    };
  }

  /**
   * When Device Attributes are fetched
   */
  onChangeDeviceAttributes = (deviceAttributes) => {
    this.setState({
      deviceAttributes,
    });
  };

  /**
   * When Device Attributes are fetched in the assignment panel
   */
  onChangeAssignDeviceAttributes = (deviceAttributes) => {
    this.setState({
      deviceAttributesAssign: deviceAttributes,
    });
  };

  /**
   * When the new attribute panel is toggled
   */
  onShowAssignAttribute = () => {
    this.setState((state) => {
      return {
        activeAttribute: {},
        deviceAttributesAssign: [],
        showAssignAttribute: !state.showAssignAttribute,
        showNewAttribute: false,
        showCopyToLocations: false,
        showLocations: false,
        showDeleteAttributes: false,
      };
    });
  };

  /**
   * When the new attribute panel is toggled
   */
  onShowNewAttribute = () => {
    this.setState((state) => {
      return {
        activeAttribute: {},
        selectedAttributes: [],
        showNewAttribute: !state.showNewAttribute,
        showCopyToLocations: false,
        showLocations: false,
        showDeleteAttributes: false,
      };
    });
  };

  /**
   * When the new attribute is saved
   */
  onSaveNewAttribute = (saveAttribute) => {
    const {
      type,
    } = this.props;

    const updateKey = type === 'admin'
      ? 'deviceAttributes'
      : 'deviceAttributesAssign';

    this.setState((state) => {
      const exists = find(state[updateKey], { _id: saveAttribute._id }) !== undefined;

      const attributes = exists
        ? state[[updateKey]].map((attribute) => {
          if (attribute._id === saveAttribute._id) {
            return {
              ...attribute,
              ...saveAttribute,
            };
          }

          return attribute;
        })
        : [...state[updateKey], saveAttribute];

      return {
        [updateKey]: sortBy(attributes, ['name', 'value']),
        activeAttribute: {},
        showNewAttribute: false,
        showCopyToLocations: false,
        showLocations: false,
        showDeleteAttributes: false,
      };
    });
  };

  /**
   * When the new attribute is archived
   */
  onArchiveAttribute = (attributeId) => {
    this.setState((state) => {
      return {
        deviceAttributes: state.deviceAttributes.filter(attribute => attribute._id !== attributeId),
        activeAttribute: {},
        showNewAttribute: false,
        showCopyToLocations: false,
        showLocations: false,
        showDeleteAttributes: false,
      };
    });
  };

  /**
   * When the new attribute panel is closed
   */
  onCloseNewAttribute = () => {
    this.setState({
      activeAttribute: {},
      showNewAttribute: false,
    });
  };

  /**
   * Closing the add to location panel
   */
  onCloseAddToLocation = (options = {}) => {
    this.setState((state) => {
      return {
        selectedAttributes: [],
        deviceAttributes: options.reset
          ? []
          : state.deviceAttributes,
        resetList: options.reset,
      };
    }, () => {
      if (options.reset) {
        this.setState({
          resetList: false,
        });
      }
    });
  };

  /**
   * When the copy to locations is toggled
   */
  onCopyToLocations = () => {
    this.setState((state) => {
      return {
        showCopyToLocations: !state.showCopyToLocations,
        showLocations: false,
        showAssignAttribute: false,
        showNewAttribute: false,
        showDeleteAttributes: false,
        activeAttribute: {},
        selectedAttributes: [],
      };
    });
  };

  /**
   * When the locations panel is toggled
   */
  onShowLocations = ({ mergeMode }) => {
    this.setState({
      showLocations: true,
      showNewAttribute: false,
      showDeleteAttributes: false,
      mergeMode,
    });
  };

  /**
   * When the locations panel is closed
   */
  onCloseLocations = () => {
    this.setState({
      showLocations: false,
      showCopyToLocations: false,
      showNewAttribute: false,
      showDeleteAttributes: false,
      selectedAttributes: [],
    });
  };

  /**
   * When the delete attributes is toggled
   */
  onDeleteAttributes = (options = {}) => {
    this.setState((state) => {
      return {
        showDeleteAttributes: !state.showDeleteAttributes,
        showCopyToLocations: false,
        showLocations: false,
        showAssignAttribute: false,
        showNewAttribute: false,
        activeAttribute: {},
        selectedAttributes: [],
        deviceAttributes: options.reset
          ? []
          : state.deviceAttributes,
        resetList: options.reset,
      };
    }, () => {
      if (options.reset) {
        this.setState({
          resetList: false,
        });
      }
    });
  };

  /**
   * When the attribute is clicked
   */
  onClickAttribute = (attribute) => {
    const {
      type,
    } = this.props;

    if (type === 'location') {
      // Do not allow selecting of a single item if in location view
      return;
    }

    this.setState({
      activeAttribute: attribute,
      showNewAttribute: true,
    });
  };

  /**
   * When the attribute is selected
   */
  onSelectAttribute = (attribute) => {
    this.setState((state) => {
      const exists = find(state.selectedAttributes, { _id: attribute._id }) !== undefined;

      return {
        showNewAttribute: false,
        selectedAttributes: exists
          ? state.selectedAttributes.filter(attr => attr._id !== attribute._id)
          : [...state.selectedAttributes, attribute],
      };
    });
  };

  render() {
    const {
      className,
      type,
      locationId,
      allowSearch,
      allowAssign,
      allowCreate,
      allowModify,
      allowCopy,
      allowRemove,
    } = this.props;

    const {
      showAssignAttribute,
      showNewAttribute,
      showCopyToLocations,
      showLocations,
      showDeleteAttributes,
      deviceAttributes,
      deviceAttributesAssign,
      activeAttribute,
      selectedAttributes,
      resetList,
      mergeMode,
    } = this.state;

    const selectedIds = selectedAttributes.map(attribute => attribute._id);
    // Allow selecting of a single item
    const allowSelectItem = type === 'admin' || showCopyToLocations || showDeleteAttributes;

    return (
      <div className={classNames('DeviceAttributes', className)}>
        <div className="panels">
          <DeviceAttributesList
            type={type}
            locationId={locationId}
            deviceAttributes={deviceAttributes}
            activeAttribute={activeAttribute}
            selectedIds={selectedIds}
            resetList={resetList}
            showAssignAttribute={showAssignAttribute}
            showNewAttribute={showNewAttribute}
            showCopyToLocations={showCopyToLocations}
            showDeleteAttributes={showDeleteAttributes}
            allowSearch={allowSearch}
            allowAssign={allowAssign}
            allowCreate={allowCreate}
            allowCopy={allowCopy}
            allowRemove={allowRemove}
            allowSelectItem={allowSelectItem}
            allowSelect={showCopyToLocations || showDeleteAttributes}
            onSelect={this.onSelectAttribute}
            onClick={this.onClickAttribute}
            onShowAssignAttribute={this.onShowAssignAttribute}
            onShowNewAttribute={this.onShowNewAttribute}
            onCopyToLocations={this.onCopyToLocations}
            onDeleteAttributes={this.onDeleteAttributes}
            onChangeDeviceAttributes={this.onChangeDeviceAttributes}
          />

          {showAssignAttribute ? (
            <DeviceAttributesList
              type="admin"
              locationId={locationId}
              deviceAttributes={deviceAttributesAssign}
              activeAttribute={activeAttribute}
              selectedIds={selectedIds}
              showNewAttribute={showNewAttribute}
              allowSearch
              allowCreate={allowCreate}
              allowSelectItem
              allowSelect
              onSelect={this.onSelectAttribute}
              onClick={this.onClickAttribute}
              onShowNewAttribute={this.onShowNewAttribute}
              onChangeDeviceAttributes={this.onChangeAssignDeviceAttributes}
            />
          ) : null}

          {showAssignAttribute && selectedAttributes.length > 0 ? (
            <DeviceAttributeAddToLocation
              locationId={locationId}
              selectedAttributes={selectedAttributes}
              onClose={this.onCloseAddToLocation}
            />
          ) : null}

          {showNewAttribute ? (
            <DeviceAttributeAddItem
              id={activeAttribute._id}
              name={activeAttribute.name}
              value={activeAttribute.value}
              allowModify={allowModify}
              onSave={this.onSaveNewAttribute}
              onArchive={this.onArchiveAttribute}
              onClose={this.onCloseNewAttribute}
            />
          ) : null}

          {showCopyToLocations ? (
            <DeviceAttributeCopyToLocations
              deviceAttributes={deviceAttributes}
              selectedIds={selectedIds}
              mergeMode={mergeMode}
              showLocations={showLocations}
              onShowLocations={this.onShowLocations}
              onClose={this.onCopyToLocations}
            />
          ) : null}

          {showDeleteAttributes ? (
            <DeviceAttributeDelete
              locationId={locationId}
              selectedAttributes={selectedAttributes}
              selectedIds={selectedIds}
              onClose={this.onDeleteAttributes}
            />
          ) : null}

          {showLocations ? (
            <DeviceAttributeLocations
              selectedIds={selectedIds}
              mergeMode={mergeMode}
              onClose={this.onCloseLocations}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

DeviceAttributes.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Show the attribute list panel for admin or location view */
  type: PropTypes.oneOf([
    'admin',
    'location',
  ]).isRequired,
  /** Location ID for the device attributes if location view (not admin) */
  locationId: PropTypes.string,
  /** Allow Searching of items */
  allowSearch: PropTypes.bool,
  /** Allow attribute assignment to a location */
  allowAssign: PropTypes.bool,
  /** Allow attribute creation */
  allowCreate: PropTypes.bool,
  /** Allow attribute modification */
  allowModify: PropTypes.bool,
  /** Allow attribute copy to locations */
  allowCopy: PropTypes.bool,
  /** Allow attributes to be removed from location */
  allowRemove: PropTypes.bool,
};

DeviceAttributes.defaultProps = {
  className: '',
  locationId: null,
  allowSearch: false,
  allowAssign: false,
  allowCreate: false,
  allowModify: false,
  allowCopy: false,
  allowRemove: false,
};

export default DeviceAttributes;
