import React from 'react';

const viStarOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M22 9.744l-7.191-.617L12 2.5 9.191 9.127 2 9.744l5.455 4.727L5.82 21.5 12 17.772l6.18 3.728-1.635-7.029L22 9.744zm-10 6.152l-3.763 2.27.996-4.281-3.323-2.878 4.38-.376L12 6.595l1.71 4.036 4.38.376-3.322 2.878.996 4.281L12 15.896z" />
    </g>
  </svg>
);

export default viStarOutline;
