import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import classNames from 'classnames';
import API from '../../api';
import PanelList from './Panels/PanelList';
import PanelLocationList from './Panels/PanelLocationList';
import PanelDetails from './Panels/PanelDetails';
import PanelCopyToLocations from './Panels/PanelCopyToLocations';
import {
  setTargetTagActive,
  addTargetTags,
  resetTargetTags,
} from '../../actions/TargetTag/TargetTagActions';
import './TargetTagsList.scss';

class TargetTagsList extends PureComponent {
  constructor(props) {
    super(props);

    const {
      locationTags,
      addTargetTags,
    } = props;

    if (locationTags.length > 0) {
      addTargetTags(locationTags);
    }

    this.state = {
      locationTags,
    };
  }

  componentWillUnmount() {
    const {
      resetTargetTags,
    } = this.props;

    resetTargetTags();
  }

  /**
   * When New Target Tag is toggled
   */
  onShowNewTargetTag = () => {
    const {
      setTargetTagActive,
    } = this.props;

    // Add a new target tag as the active tag
    const tag = {
      _id: 'new',
      name: 'Parent Name',
      qualifiedName: 'Parent-Name',
    };

    // Set target tag as active
    setTargetTagActive(tag);
  };

  /**
   * When Copy to Locations is selected
   */
  onCopyToLocations = () => {
    const {
      showCopyToLocations,
      setTargetTagActive,
    } = this.props;

    if (!showCopyToLocations) {
      // Show the copy to locationns panel
      setTargetTagActive({
        _id: 'copy',
      });
    } else {
      // No longer show copy to locations
      setTargetTagActive({
        _id: null,
      });
    }
  };

  /**
   * When Remove Tag from Location is selected
   */
  onRemoveFromLocation = async (tag) => {
    const {
      location: {
        _id,
      },
      setTargetTagActive,
    } = this.props;

    const updateTagsResponse = await API.Location.updateTargetTags({
      _id,
      targetingTags: [
        { _id: tag._id, action: 'remove' },
      ],
    });

    const isSuccess = get(updateTagsResponse, '[0].documentId', false);

    if (isSuccess) {
      setTargetTagActive({
        _id: null,
      });

      this.setState((state) => {
        return {
          locationTags: state.locationTags.filter(t => t._id !== tag._id),
        };
      });
    }
  };

  /**
   * When location tags are updated
   */
  onUpdateLocationTags = async () => {
    const {
      location,
    } = this.props;

    const targetTags = await API.Location.getTargetTags(location._id);

    this.setState({
      locationTags: targetTags.customTags,
    });
  };

  render() {
    const {
      className,
      location,
      panelList,
      panelLocationList,
      activeLocationMenuItem,
      allowCreate,
      allowCopy,
      allowRemove,
      showAddTargetTag,
      showNewTargetTag,
      showPanelDetails,
      showCopyToLocations,
    } = this.props;

    const {
      locationTags,
    } = this.state;

    return (
      <div className={classNames('TargetTagsList', className)}>
        <div className="panels">
          {panelList ? (
            <PanelList
              allowCreate={allowCreate}
              allowCopy={allowCopy}
              allowSelect={showCopyToLocations}
              onShowNewTargetTag={this.onShowNewTargetTag}
              onCopyToLocations={this.onCopyToLocations}
            />
          ) : null}

          {panelLocationList ? (
            <PanelLocationList
              locationTags={locationTags}
              allowCreate={allowCreate}
              allowCopy={allowCopy}
              allowRemove={allowRemove}
              allowSelect={showCopyToLocations}
              onCopyToLocations={this.onCopyToLocations}
            />
          ) : null}

          {panelLocationList && showAddTargetTag ? (
            <PanelList
              allowCreate={allowCreate}
              allowSelect
              onShowNewTargetTag={this.onShowNewTargetTag}
            />
          ) : null}

          {showPanelDetails || showNewTargetTag ? (
            <PanelDetails
              isEdit={showNewTargetTag}
              isLocation={location._id !== undefined}
              onRemoveFromLocation={this.onRemoveFromLocation}
            />
          ) : null}

          {showCopyToLocations ? (
            <PanelCopyToLocations
              location={location}
              isLocation={location._id !== undefined}
              showAddToThisLocation={
                location._id !== undefined
                && activeLocationMenuItem !== 'copy'
                && activeLocationMenuItem !== 'remove'
              }
              showRemoveFromThisLocation={
                location._id !== undefined
                && activeLocationMenuItem === 'remove'
              }
              onUpdateLocationTags={this.onUpdateLocationTags}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

TargetTagsList.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Location */
  location: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Location Tags */
  locationTags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    qualifiedName: PropTypes.string,
  })),
  /** Show the target tag list panel */
  panelList: PropTypes.bool,
  /** Show the target tag location list panel */
  panelLocationList: PropTypes.bool,
  /** Allow target tag creation */
  allowCreate: PropTypes.bool,
  /** Allow target tag copy to locations */
  allowCopy: PropTypes.bool,
  /** Allow target tags to be removed from location */
  allowRemove: PropTypes.bool,
};

TargetTagsList.defaultProps = {
  className: '',
  location: {},
  locationTags: [],
  panelList: false,
  panelLocationList: false,
  allowCreate: false,
  allowCopy: false,
  allowRemove: false,
};

function mapStateToProps(state, props) {
  const {
    location = {},
  } = props;

  const activeId = state.targetTag.activeTag._id;
  const showCopyToLocations = (!location._id && state.targetTag.activeTag._id === 'copy')
    || (location._id && state.targetTag.activeLocationMenuItem === 'copy' && state.targetTag.activeTag._id !== 'new')
    || (location._id && state.targetTag.activeLocationMenuItem === 'add' && state.targetTag.activeTag._id !== 'new')
    || (location._id && state.targetTag.activeLocationMenuItem === 'remove' && state.targetTag.activeTag._id !== 'new');

  return {
    activeId,
    activeLocationMenuItem: state.targetTag.activeLocationMenuItem,
    showCopyToLocations,
    showAddTargetTag: state.targetTag.activeLocationMenuItem === 'add',
    showNewTargetTag: state.targetTag.activeTag._id === 'new',
    showPanelDetails: activeId
      && state.targetTag.activeLocationMenuItem !== 'add'
      && state.targetTag.activeLocationMenuItem !== 'remove'
      && !showCopyToLocations,
  };
}

const mapDispatchToProps = {
  setTargetTagActive,
  addTargetTags,
  resetTargetTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetTagsList);
