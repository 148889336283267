import React from 'react';
import PropTypes from 'prop-types';
import FullObjectSelection from '../FullObjectSelection/FullObjectSelection';
import SongsUnassigned from './SongsUnassigned';
import SongsAssigned from './SongsAssigned';

function SongSelection({
  filters,
  selected,
  allowCustomOrder,
  allowChanges,
  customToolbar,
  playlistId,
  averageDurationSeconds,
  assigning,
  onChange,
}) {
  if (assigning) {
    return (
      <FullObjectSelection className="SongSelection">
        <SongsUnassigned
          selected={selected}
          customToolbar={customToolbar}
          filters={filters}
          onChange={onChange}
        />
      </FullObjectSelection>
    );
  }

  return (
    <FullObjectSelection className="SongSelection">
      <SongsAssigned
        selected={selected}
        allowCustomOrder={allowCustomOrder}
        allowChanges={allowChanges}
        customToolbar={customToolbar}
        playlistId={playlistId}
        averageDurationSeconds={averageDurationSeconds}
        onChange={onChange}
      />
    </FullObjectSelection>
  );
}

SongSelection.propTypes = {
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected messages */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Allow custom order of messages in the assigned table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the message selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar for assigned locations */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Playlist ID */
  playlistId: PropTypes.string,
  averageDurationSeconds: PropTypes.number,
  /** Selector is assigning messages */
  assigning: PropTypes.bool,
  /** When the messages are changed */
  onChange: PropTypes.func,
};

SongSelection.defaultProps = {
  filters: null,
  selected: [],
  allowCustomOrder: false,
  allowChanges: false,
  assigning: false,
  customToolbar: null,
  playlistId: '',
  averageDurationSeconds: 0,
  onChange: () => {},
};

export default SongSelection;
