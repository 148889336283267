import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import {
  Card,
  CardContent,
} from '@mui/material';
import {
  VibeIcon,
  color,
  VibeMiniTable,
  viLaunch,
} from 'vibeguide';
import './OrderStatusCard.scss';
import { Link } from 'react-router-dom';

function OrderStatusCard({
  className,
  style,
  title,
  linkUrl,
  linkFilter,
  tableData,
  tableStyle,
  loading,
  icon,
  iconStyles,
}) {
  const {
    hideColumn,
    columns,
    data,
  } = tableData;

  iconStyles = iconStyles
    ? {
      ...iconStyles,
      fontSize: '20px',
      marginRight: '8px',
    }
    : {
      fontSize: '20px',
      marginRight: '8px',
    };

  return (
    <Card
      className={classNames('OrderStatusCard', className)}
      sx={style}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className="card-title">
            <VibeIcon
              style={iconStyles}
              icon={icon}
              color={color.violetVibe}
              size={20}
            />

            <div>
              {title}
            </div>
          </div>

          <Link
            to={`${linkUrl}?${new URLSearchParams(linkFilter).toString()}`}
            className="link"
          >
            <VibeIcon
              icon={viLaunch}
              color={color.violetVibe}
              size={20}
            />
          </Link>
        </div>
      </CardContent>

      <VibeMiniTable
        style={tableStyle}
        loading={loading}
        hideColumnHeader={hideColumn}
        columnData={columns}
        tableData={data}
        renderLink={(row) => {
          return `/ad-ops/order/${row._id}/details`;
        }}
      />
    </Card>
  );
}

OrderStatusCard.propTypesropTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  tableData: PropTypes.shape({
    hideColumn: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  tableStyle: PropTypes.object,
  loading: PropTypes.bool,
  icon: PropTypes.func,
};

OrderStatusCard.defaultProps = {
  className: '',
  style: {},
  title: '',
  linkText: '',
  linkUrl: '',
  tableData: {
    hideColumn: false,
    columns: [],
    data: [],
  },
  tableStyle: {},
  loading: false,
  icon: () => {},
};

export default OrderStatusCard;
