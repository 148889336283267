import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';
import {
  TimeUtil,
  NavigationHelper,
  VibeTooltip,
} from 'vibeguide';
import './SpotPoolScheduleItem.scss';

function SpotPoolScheduleItem({
  user,
  day,
  adProgramId,
  locationId,
  startTime,
  stopTime,
}) {
  const itemStart = TimeUtil.convertToTwelveHour({
    time: startTime,
  });

  const itemStop = TimeUtil.convertToTwelveHour({
    time: stopTime,
  });

  const canViewAdProgram = user.can('location-ad-program.view');

  return (
    <Link
      className="SpotPoolScheduleItem"
      to={canViewAdProgram
        ? NavigationHelper.updateParams({
          adProgramId,
          day,
          spotPoolStart: startTime,
        }, {
          pathname: `/location/${locationId}/programs`,
        })
        : ''}
    >
      <VibeTooltip
        title={(
          <div>
            <div
              style={{
                marginBottom: 8,
              }}
            >
              {itemStart} - {itemStop}
            </div>

            <div
              style={{
                marginBottom: 8,
                opacity: 0.8,
              }}
            >
              Ad Program Spot Pool
            </div>

            {canViewAdProgram && (
              <div
                style={{
                  opacity: 0.8,
                }}
              >
                Click to View/Edit...
              </div>
            )}
          </div>
        )}
        placement="right"
      >
        <div className="content" />
      </VibeTooltip>
    </Link>
  );
}

SpotPoolScheduleItem.propTypes = {
  day: PropTypes.string.isRequired,
  adProgramId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  stopTime: PropTypes.string,
};

SpotPoolScheduleItem.defaultProps = {
  startTime: '',
  stopTime: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(SpotPoolScheduleItem);
