import React from 'react';

const viCloseCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M6 1C3.235 1 1 3.235 1 6s2.235 5 5 5 5-2.235 5-5-2.235-5-5-5zm2.5 6.795l-.705.705L6 6.705 4.205 8.5 3.5 7.795 5.295 6 3.5 4.205l.705-.705L6 5.295 7.795 3.5l.705.705L6.705 6 8.5 7.795z" />
    </g>
  </svg>
);

export default viCloseCircle;
