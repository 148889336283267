import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  TableMessageBlocks,
  API,
  GlobalActions,
  NavigationHelper,
  VibeButton,
  VibeIcon,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import MessageBlockSidebar from '../../../../Messages/MessageBlocks/Sidebar/MessageBlockSidebar';
import './MessageBlocks.scss';

class MessageBlocks extends Component {
  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.messageBlockId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        this.sidebar(qs.type, qs.messageBlockId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.messageBlockId || qs.type === 'new') {
        this.sidebar(qs.type, qs.messageBlockId);
      }
    }
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      messageBlockId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  sidebar = (type, messageBlockId = null) => {
    const {
      setPanel,
      currentLocation,
    } = this.props;

    let locationToAdd = {};
    let companyToAdd = {};

    if (type === 'new') {
      locationToAdd = {
        companyId: currentLocation.companyId,
        locationId: currentLocation._id,
      };

      companyToAdd = {
        companyId: currentLocation.companyId,
        companyName: currentLocation.companyName,
      };
    }

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <MessageBlockSidebar
          messageBlockId={messageBlockId}
          isNew={type === 'new'}
          locationToAdd={locationToAdd}
          companyToAdd={companyToAdd}
          redirectTo={`/location/${currentLocation._id}/assignments/blocks`}
          refresh={this.refresh}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      currentLocation,
      user,
    } = this.props;

    const locationId = get(currentLocation, '_id', null);

    return (
      <div className="MessageBlocks">
        <TableMessageBlocks
          columns={[
            '.',
            'Name',
            'Company',
            'Integration',
            'Frequency',
            'Consecutive',
            'Start Type',
            'Sequence',
            'Locations',
            'Tags',
            '...',
          ]}
          fetch={API.Location.listMessageLists}
          fetchProps={{
            filters: {
              _id: locationId,
            },
          }}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Message Blocks',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Message Blocks',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          toolbarProps={{
            customButtons: user.can('messagelist.create')
              ? [
                (
                  <VibeButton
                    key="button-new-message-block"
                    text="New Message Block"
                    btnColor="green"
                    btnLink={NavigationHelper.updateParams({
                      type: 'new',
                    })}
                    textColor="white"
                    icon={(
                      <VibeIcon
                        icon={viAdd}
                        color={color.white}
                        size={18}
                      />
                    )}
                  />
                ),
              ]
              : [],
          }}
          cardProps={{
            requireFilter: false,
          }}
          rowLink={{
            messageBlockId: '_id',
          }}
          menuItems={[
            { name: 'Archive', userCan: 'messagelist.delete' },
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageBlocks));
