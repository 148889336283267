import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/mixes`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: id => Http.get(`${baseUrl}/${id}`),
  getMusic: (id, options) => Http.get(`${baseUrl}/${id}/music`, options),
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  create: data => Http.post(baseUrl, data),
  modify: (id, data) => Http.put(`${baseUrl}/${id}`, data),
  uploadImage: (id, file, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/image`, file, 'image', onProgress,
  ),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (mixes) => Http.delete(baseUrl, {
    mixes,
  }),
};
