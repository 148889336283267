import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../../VibeIcon/VibeIcon';
import './LabelCell.scss';

function LabelCell({
  className,
  style,
  background,
  color,
  value,
  icon,
  inline,
}) {
  return (
    <div
      className={classNames('LabelCell', className)}
      style={{
        backgroundColor: background,
        color,
        display: inline
          ? 'inline-flex'
          : 'flex',
        ...style,
      }}
    >
      {icon && (
        <VibeIcon
          className="icon"
          icon={icon}
          color={color}
          size={16}
        />
      )}

      <div className="value">
        {value}
      </div>
    </div>
  );
}

LabelCell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.func,
  inline: PropTypes.bool,
};

LabelCell.defaultProps = {
  className: '',
  style: {},
  value: '',
  background: 'rgba(149, 154, 173, 0.16)',
  color: '#959aad',
  icon: null,
  inline: false,
};

export default LabelCell;
