import React from 'react';

const viCircleOne = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm1.296 4H11.89L9.4 10.09l.538.714a.521.521 0 0 0 .307.178c.07.013.144.013.222 0a.472.472 0 0 0 .159-.058l.08-.054.91-.758a9.352 9.352 0 0 0-.016.316l-.004.156-.001.154v4.033h-1.537V16h4.555v-1.23h-1.317V8z" />
    </g>
  </svg>
);

export default viCircleOne;
