// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadImage {
  cursor: pointer;
  width: 128px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/UploadImage/UploadImage.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;AACF","sourcesContent":[".UploadImage {\n  cursor: pointer;\n  width: 128px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
