import React from 'react';
import PropTypes from 'prop-types';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viChevronLeft from '../../../icons/viChevronLeft';
import viChevronRight from '../../../icons/viChevronRight';
import './MonthNavigate.scss';

function MonthNavigate({
  text,
  iconColor,
  iconSize,
  iconStyle,
  onPrev,
  onNext,
}) {
  return (
    <div className="MonthNavigate">
      <VibeIcon
        className="month-nav-icon"
        style={iconStyle}
        icon={viChevronLeft}
        color={iconColor}
        size={iconSize}
        onClick={onPrev}
      />

      <div className="month-text">
        {text}
      </div>

      <VibeIcon
        className="month-nav-icon"
        style={iconStyle}
        icon={viChevronRight}
        color={iconColor}
        size={iconSize}
        onClick={onNext}
      />
    </div>
  );
}

MonthNavigate.propTypes = {
  /** Text to show between navigation icons */
  text: PropTypes.string.isRequired,
  /** Icon color */
  iconColor: PropTypes.string,
  /** Icon size */
  iconSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Icon style */
  iconStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** When previous month is clicked */
  onPrev: PropTypes.func,
  /** When next month is clicked */
  onNext: PropTypes.func,
};

MonthNavigate.defaultProps = {
  iconColor: '#959aad',
  iconSize: 24,
  iconStyle: {},
  onPrev: () => {},
  onNext: () => {},
};

export default MonthNavigate;
