/* eslint-disable import/no-import-module-exports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import {
  Authentication,
  StorageUtil,
} from 'vibeguide';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import pkg from '../../../../package.json';
import App from './App';
import ErrorView from './ErrorView';

const local = process.env.APP_ENV === 'local';

/**
 * Custom Theme to Override Material UI Styles
 */
const vibeTheme = createTheme({
  typography: {
    fontFamily: '"Lato", sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        },
      },
    },
  },
});

/**
 * Start Bugsnag Listener
 */
Bugsnag.start({
  apiKey: 'b8b4843a00e850e924160decdb804786',
  appVersion: pkg.version,
  releaseStage: process.env.APP_ENV,
  enabledReleaseStages: [
    // 'local',
    'development',
    'staging',
    'production',
  ],
  plugins: [new BugsnagPluginReact()],
  onError(event) {
    try {
      const user = StorageUtil.getLocal('app:user', {});

      event.setUser(
        user._id,
        user.email,
        `${user.fname}${user.lname ? ` ${user.lname}` : ''}`,
      );

      // Send user company with the error
      event.addMetadata('company', {
        name: user.companyName,
        id: user.companyId,
      });

      // Send user permissions with the error
      event.addMetadata('permissions', {
        // sort permissions alphabetically
        permissions: user.permissions.map(permission => permission.name).sort(),
      });
    } catch (err) {
      event.setUser(
        '',
        '',
        'Unknown',
      );
    }
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const renderApp = (Component) => {
  // Wrap react-hot-loader component if local
  const wrapper = local ? (
    <AppContainer>
      <Component />
    </AppContainer>
  ) : (
    <Component />
  );

  ReactDOM.render(
    <ThemeProvider theme={vibeTheme}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        {wrapper}
      </ErrorBoundary>
    </ThemeProvider>,
    document.getElementById('app'),
  );
};

const displayApplication = async () => {
  await Authentication.getUserInfo();

  if (local && module.hot) {
    module.hot.accept();
  }

  renderApp(App);
};

displayApplication();
