// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExternalInfo {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
.ExternalInfo > .header {
  position: absolute;
  top: 8px;
  right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/ThirdPartyData/ExternalInfo.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;AACJ","sourcesContent":[".ExternalInfo {\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n\n  > .header {\n    position: absolute;\n    top: 8px;\n    right: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
