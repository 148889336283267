import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FullObjectSelection.scss';

function FullObjectSelection({
  className,
  children,
}) {
  return (
    <div className={classNames('FullObjectSelection', className)}>
      <div className="children">
        {children}
      </div>
    </div>
  );
}

FullObjectSelection.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Left content */
  /** Right content */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FullObjectSelection.defaultProps = {
  className: '',
};

export default FullObjectSelection;
