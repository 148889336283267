import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viViewDayTrack,
  viEvent,
} from 'vibeguide';

function ProgramsContainer({
  user,
}) {
  const viewBaselines = user.can('baseline.view');
  const viewEvents = user.can('event.view');

  return (
    <div
      className="ProgramsContainer"
      style={{
        height: '100%',
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Baselines"
          url="/programs/baselines"
          icon={viViewDayTrack}
          show={viewBaselines}
        />

        <NavigationSideItem
          text="Events"
          url="/programs/events"
          icon={viEvent}
          show={viewEvents}
        />
      </NavigationSide>

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(ProgramsContainer);
