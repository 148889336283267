import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  EventModel,
} from 'vibeguide';
import WeekNavigation from './Controls/WeekNavigation';
import AdControls from './Controls/AdControls';
import DisplayOptions from './Controls/DisplayOptions';
import WeekDates from './Controls/WeekDates';
import ContainerColumns from './Controls/ContainerColumns';
import ScheduleToolbar from './ScheduleToolbar';
import './ScheduleHeader.scss';

function ScheduleHeader({
  className,
  type,
  schedule,
  musicOverride,
  musicOverrideSchedule,
  timezone,
  viewAds,
  startOfWeek,
  endOfWeek,
  activeDisplay,
  activeEvent,
  archivedBaselines,
  midnightBaselines,
  readOnly,
  onChangeWeek,
  onChangeDisplay,
  onToggleAdLayer,
  onUpdateEvent,
  onGetSchedule,
}) {
  return (
    <div className={classNames('ScheduleHeader', className)}>
      {type === 'schedule' && (
        <div className="header-row toolbar">
          <WeekNavigation
            className="week-nav"
            startOfWeek={startOfWeek}
            endOfWeek={endOfWeek}
            onChangeWeek={onChangeWeek}
          />

          <AdControls
            className="ad-controls"
            onToggleAdLayer={onToggleAdLayer}
          />

          <DisplayOptions
            className="display-options"
            activeDisplay={activeDisplay}
            onChangeDisplay={onChangeDisplay}
          />
        </div>
      )}

      {type === 'schedule' && (
        <ScheduleToolbar
          schedule={schedule}
          musicOverride={musicOverride}
          musicOverrideSchedule={musicOverrideSchedule}
          timezone={timezone}
          viewAds={viewAds}
          onGetSchedule={onGetSchedule}
        />
      )}

      {type === 'schedule' && (
        <WeekDates
          startOfWeek={startOfWeek}
          archivedBaselines={archivedBaselines}
          midnightBaselines={midnightBaselines}
        />
      )}

      {(type === 'baseline' || type === 'event') && (
        <ContainerColumns
          type={type}
          activeEvent={activeEvent}
          readOnly={readOnly}
          onUpdateEvent={onUpdateEvent}
        />
      )}
    </div>
  );
}

ScheduleHeader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'schedule',
    'baseline',
    'event',
  ]).isRequired,
  schedule: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  musicOverride: PropTypes.bool,
  musicOverrideSchedule: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  timezone: PropTypes.string,
  viewAds: PropTypes.bool,
  startOfWeek: PropTypes.instanceOf(moment),
  endOfWeek: PropTypes.instanceOf(moment),
  activeDisplay: PropTypes.string,
  /** Active Event */
  activeEvent: PropTypes.instanceOf(EventModel),
  archivedBaselines: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  midnightBaselines: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
  onChangeWeek: PropTypes.func,
  onChangeDisplay: PropTypes.func,
  onToggleAdLayer: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onGetSchedule: PropTypes.func,
};

ScheduleHeader.defaultProps = {
  className: '',
  schedule: {},
  musicOverride: false,
  musicOverrideSchedule: {},
  timezone: null,
  viewAds: false,
  startOfWeek: moment().startOf('week'),
  endOfWeek: moment().endOf('week'),
  activeDisplay: '',
  activeEvent: null,
  archivedBaselines: {},
  midnightBaselines: {},
  readOnly: false,
  onChangeWeek: () => {},
  onChangeDisplay: () => {},
  onToggleAdLayer: () => {},
  onUpdateEvent: () => {},
  onGetSchedule: () => {},
};

export default ScheduleHeader;
