import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import './AtAGlanceItem.scss';

function AtAGlanceItem({
  className,
  name,
  loading,
  value,
}) {
  return (
    <div className={classNames('AtAGlanceItem', className)}>
      <div className="name">
        {name}
      </div>

      <div className={classNames('value', { loading })}>
        {loading ? (
          <CircularProgress
            color="inherit"
            size={10}
          />
        ) : (value || 0).toLocaleString()}
      </div>
    </div>
  );
}

AtAGlanceItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  loading: PropTypes.bool,
};

AtAGlanceItem.defaultProps = {
  className: '',
  loading: false,
  value: 0,
};

export default AtAGlanceItem;
