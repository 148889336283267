import { handleActions } from 'redux-actions';
import { find } from 'lodash';
import {
  handleLocationsData,
  getLocationsData,
} from '../../helpers/ExpandedLocations';

const initialState = {
  locationsData: {
    companies: [],
    locations: [],
  },
  locations: [],
  type: 'std',
  publishAutomatically: false,
  messageName: '',
  advertiserId: '',
  advertiserName: '',
  companyId: '',
  companyName: '',
  script: '',
  specialInstructions: '',
  file: null,
  fileData: null,
  messageBlocks: [],
  helpScript: true,
  originalRequestId: null,
  allLocations: false,
  startDate: '',
  endDate: '',
  tags: [],
};

export default handleActions(
  {
    SET_MESSAGE_REQ_DATA: (state, action) => {
      const data = action.payload;

      return {
        ...state,
        ...data,
      };
    },

    ADD_MESSAGE_REQ_LOCATION: (state, action) => {
      const location = action.payload;
      const hasLocation = find(state.locations, location) !== undefined;

      if (hasLocation) {
        // Do not add the location twice
        return state;
      }

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        locations = locations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...locations, location],
      };
    },

    ADD_MESSAGE_REQ_LOCATIONS: (state, action) => {
      const {
        locations,
        expandedLocations,
      } = action.payload;

      return {
        ...state,
        locationsData: getLocationsData(expandedLocations),
        locations: state.locations.concat(locations),
      };
    },

    REMOVE_MESSAGE_REQ_LOCATION: (state, action) => {
      const locationData = action.payload;
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    },

    REMOVE_MESSAGE_REQ_ALL_LOCATIONS: (state) => {
      return {
        ...state,
        locationsData: initialState.locationsData,
        locations: initialState.locations,
      };
    },

    SET_MESSAGE_REQ_ALL_LOCATIONS: (state, action) => {
      const allLocations = action.payload;
      const locations = allLocations ? [] : state.locations;

      return {
        ...state,
        allLocations,
        locations,
      };
    },

    SET_MESSAGE_REQ_NAME: (state, action) => {
      const messageName = action.payload;

      return {
        ...state,
        messageName,
      };
    },

    SET_MESSAGE_REQ_SCRIPT: (state, action) => {
      const script = action.payload;

      return {
        ...state,
        script,
      };
    },

    SET_MESSAGE_REQ_COMPANY: (state, action) => {
      const data = action.payload;

      const companyId = data.companyId !== undefined
        ? data.companyId
        : state.companyId;

      const companyName = data.companyName !== undefined
        ? data.companyName
        : state.companyName;

      return {
        ...state,
        companyId,
        companyName,
        // Only keep admin tags since client tags are linked to the company
        tags: state.companyId && state.companyId !== companyId
          ? state.tags.filter(tag => tag.type === 'admin')
          : state.tags,
      };
    },

    SET_MESSAGE_REQ_ADVERTISER: (state, action) => {
      const data = action.payload;

      const advertiserId = data.advertiserId !== undefined
        ? data.advertiserId
        : state.advertiserId;

      const advertiserName = data.advertiserName !== undefined
        ? data.advertiserName
        : state.advertiserName;

      return {
        ...state,
        advertiserId,
        advertiserName,
      };
    },

    SET_MESSAGE_REQ_INSTRUCTIONS: (state, action) => {
      const specialInstructions = action.payload;

      return {
        ...state,
        specialInstructions,
      };
    },

    SET_MESSAGE_REQ_START_DATE: (state, action) => {
      const startDate = action.payload;

      return {
        ...state,
        startDate,
      };
    },

    SET_MESSAGE_REQ_END_DATE: (state, action) => {
      const endDate = action.payload;

      return {
        ...state,
        endDate,
      };
    },

    SET_MESSAGE_REQ_HELP: (state, action) => {
      const helpScript = action.payload;

      return {
        ...state,
        helpScript,
      };
    },

    SET_MESSAGE_REQ_PUBLISH: (state, action) => {
      const publishAutomatically = action.payload;

      return {
        ...state,
        publishAutomatically,
      };
    },

    SET_MESSAGE_REQ_FILE: (state, action) => {
      const messageFile = action.payload;

      return {
        ...state,
        file: messageFile,
      };
    },

    SET_MESSAGE_REQ_TAGS: (state, action) => {
      const tags = action.payload;

      tags.forEach((tag) => {
        tag.initialTag = true;
      });

      return {
        ...state,
        tags: [...state.tags, ...tags],
      };
    },

    ADD_MESSAGE_REQ_TAG: (state, action) => {
      const tag = action.payload;
      const existingTag = find(state.tags, { _id: tag._id });

      // Tag is already added (and not queued for removal)
      if (existingTag && existingTag.status !== 'remove') {
        return state;
      }

      // Tag is already added (and queued for removal)
      // Update the tag to include its new status
      if (existingTag && existingTag.status === 'remove') {
        return {
          ...state,
          tags: state.tags.map((t) => {
            if (t._id === existingTag._id) {
              return {
                ...t,
                ...tag,
                status: existingTag.initialTag
                  ? 'unedited'
                  : tag.status,
              };
            }

            return t;
          }),
        };
      }

      // Add the new tag
      return {
        ...state,
        tags: [...state.tags, tag],
      };
    },

    REMOVE_MESSAGE_REQ_TAG: (state, action) => {
      const tag = action.payload;
      // Find the tag if it already exists
      const existingTag = find(state.tags, { _id: tag._id });

      // Tag exists, add a remove status
      if (existingTag && existingTag.initialTag) {
        return {
          ...state,
          tags: state.tags.map((t) => {
            if (t._id === existingTag._id) {
              return {
                ...t,
                ...tag,
              };
            }

            return t;
          }),
        };
      }

      // Remove the tag
      return {
        ...state,
        tags: state.tags.filter(t => t._id !== tag._id),
      };
    },

    NEW_MESSAGE_ADD_MESSAGE_BLOCK: (state, action) => {
      const messageBlock = action.payload;

      return {
        ...state,
        messageBlocks: [...state.messageBlocks, messageBlock],
      };
    },

    NEW_MESSAGE_REMOVE_MESSAGE_BLOCK: (state, action) => {
      const id = action.payload;

      return {
        ...state,
        messageBlocks: state.messageBlocks.filter(messageBlock => messageBlock._id !== id),
      };
    },

    RESET_NEW_MESSAGE_REQ: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
