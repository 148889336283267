import React from 'react';

const viExact = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0 4.5a9.5 9.5 0 1 1 0-19 9.5 9.5 0 0 1 0 19zm0-1.5a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-1.5a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zM12 0c.276 0 .5.252.5.563v7.875c0 .31-.224.562-.5.562s-.5-.252-.5-.563V.564c0-.311.224-.563.5-.563zm3.563 11.5h7.874c.311 0 .563.224.563.5s-.252.5-.563.5h-7.875c-.31 0-.562-.224-.562-.5s.252-.5.563-.5zM12 15c.276 0 .5.252.5.563v7.874c0 .311-.224.563-.5.563s-.5-.252-.5-.563v-7.875c0-.31.224-.562.5-.562zM.562 11.5h7.875c.311 0 .563.224.563.5s-.252.5-.563.5H.564C.252 12.5 0 12.276 0 12s.252-.5.563-.5zM12 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </g>
  </svg>
);

export default viExact;
