// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventsContainer {
  margin-left: 80px;
}
.EventsContainer .cards-container {
  padding: 0 30px;
  padding-right: 10px;
}
.EventsContainer .event-paginator {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Programs/Events/EventsContainer.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".EventsContainer {\n  margin-left: 80px;\n\n  .cards-container {\n    padding: 0 30px;\n    padding-right: 10px;\n  }\n\n  .event-paginator {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
