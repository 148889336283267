import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import {
  removeToast,
} from '../../actions/Toast/ToastActions';
import color from '../../sass/color.scss';
import './Toast.scss';

class Toast extends PureComponent {
  constructor(props) {
    super(props);

    const {
      toast: {
        type,
        title,
        timeout,
        show,
        allowClose,
      },
    } = props;

    this.showTimeout = null;
    this.hideTimeout = null;
    this.removeTimeout = null;

    this.state = {
      type,
      title,
      timeout,
      show,
      allowClose,
    };
  }

  componentDidMount() {
    const {
      timeout,
    } = this.state;

    this.showTimeout = setTimeout(this.showToast, 100);
    this.hideTimeout = setTimeout(this.hideToast, ((timeout - 0.5) * 1000));
    this.removeTimeout = setTimeout(this.removeToast, (timeout * 1000));
  }

  componentWillUnmount() {
    clearTimeout(this.showTimeout);
    clearTimeout(this.hideTimeout);
    clearTimeout(this.removeTimeout);
  }

  showToast = () => {
    this.setState({
      show: true,
    });
  };

  hideToast = () => {
    this.setState({
      show: false,
    });
  };

  removeToast = () => {
    const {
      toast: {
        id,
      },
      removeToast,
    } = this.props;

    clearTimeout(this.hideTimeout);
    clearTimeout(this.removeTimeout);

    // Hide the toast first
    this.hideToast();

    // Remove this toast from the UI
    this.removeTimeout = setTimeout(() => {
      removeToast(id);
    }, 500);
  };

  render() {
    const {
      type,
      title,
      show,
      allowClose,
    } = this.state;

    return (
      <div className={classNames('Toast', type, { show })}>
        <div className="content">
          <div className="title">
            {title}
          </div>

          {allowClose ? (
            <VibeIcon
              className="close-icon"
              icon={viClose}
              color={color.white}
              size={16}
              onClick={this.removeToast}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

Toast.propTypes = {
  toast: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'info',
      'success',
      'error',
      'warning',
    ]),
    title: PropTypes.string,
    timeout: PropTypes.number,
    show: PropTypes.bool,
    allowClose: PropTypes.bool,
  }),
};

Toast.defaultProps = {
  toast: {
    type: 'info',
    title: '',
    timeout: 3,
    show: false,
    allowClose: false,
  },
};

const mapDispatchToProps = {
  removeToast,
};

export default connect(null, mapDispatchToProps)(Toast);
