import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  sortBy,
} from 'lodash';
import {
  TimeUtil,
  EventModel,
} from 'vibeguide';
import Gridline from './Gridline';
import GridDay from './GridDay';
import GridItemColumn from './Columns/GridItemColumn';
import GridItem from './GridItem';
import './ScheduleGrid.scss';

const days = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

function ScheduleGrid({
  className,
  type,
  activeDisplay,
  musicOverride,
  startOfWeek,
  currTime,
  timezone,
  gridHeight,
  playlists,
  mixes,
  messageBlocks,
  interrupts,
  baselineSeparators,
  events,
  spotPools,
  spotValues,
  adProgramProps,
  adPrograms,
  activeEvent,
  allowClickDay,
  readOnly,
  onClickDay,
  // onUpdateEvent,
  onUpdateItems,
}) {
  const gridRef = useRef(null);

  /**
   * Get gridlines for a display
   */
  const getGridlines = () => {
    switch (activeDisplay) {
      case '24h':
      case '1h': {
        const gridlines = [];

        // each slot represents 1 hour
        for (let h = 0; h < 25; h++) {
          let label = `${h}:00`;
          let style = {};

          if (label === '0:00' || label === '24:00') {
            label = '12AM';
            style = {
              fontWeight: 900,
            };
          } else if (label === '12:00') {
            label = '12PM';
            style = {
              fontWeight: 900,
            };
          } else if (h > 12) {
            // PM shift
            label = `${h - 12}:00`;
          }

          gridlines.push({
            label,
            style,
            time: `${h}:00`,
          });
        }

        return gridlines;
      }

      case '15m': {
        const gridlines = [];

        // each slot represents 15 minutes
        for (let h = 0; h < 25; h++) {
          for (let m = 0; m < 60; m += 15) {
            const minute = m < 10
              ? `0${m}`
              : m;

            let hour = h;

            if (h === 0 && m > 0) {
              // Skip 0:00 to display 12AM for midnight
              hour = '12';
            }

            let label = `${hour}:${minute}`;
            let style = {};

            if (label === '0:00' || label === '24:00') {
              label = '12AM';
              style = {
                fontWeight: 900,
              };
            } else if (label === '12:00') {
              label = '12PM';
              style = {
                fontWeight: 900,
              };
            } else if (h > 12) {
              // PM shift
              label = `${h - 12}:${minute}`;
            }

            // only display up to midnight
            if (h < 24 || (h === 24 && m === 0)) {
              gridlines.push({
                label,
                style,
                time: `${h}:${minute}`,
              });
            }
          }
        }

        return gridlines;
      }

      case '1m': {
        const gridlines = [];

        for (let h = 0; h < 25; h++) {
          for (let m = 0; m < 60; m++) {
            const minute = m < 10
              ? `0${m}`
              : m;

            let hour = h;

            if (h === 0 && m > 0) {
              // Skip 0:00 to display 12AM for midnight
              hour = '12';
            }

            let label = `${hour}:${minute}`;
            let style = {};

            if (label === '0:00' || label === '24:00') {
              label = '12AM';
              style = {
                fontWeight: 900,
              };
            } else if (label === '12:00') {
              label = '12PM';
              style = {
                fontWeight: 900,
              };
            } else if (h > 12) {
              // PM shift
              label = `${h - 12}:${minute}`;
            }

            // only display up to midnight
            if (h < 24 || (h === 24 && m === 0)) {
              gridlines.push({
                label,
                style,
                time: `${h}:${minute}`,
              });
            }
          }
        }

        return gridlines;
      }

      default:
        return [];
    }
  };

  const renderGridlines = (gridlines, slotHeight) => {
    return (
      <div className="gridlines">
        {gridlines.map((gridline) => {
          return (
            <Gridline
              key={`gridline-${gridline.time}`}
              height={slotHeight}
              label={gridline.label}
              style={gridline.style}
            />
          );
        })}
      </div>
    );
  };

  const gridlines = getGridlines();
  const slotHeight = activeDisplay === '24h'
    // height of each gridline to not scroll
    ? gridHeight / gridlines.length
    // fixed height of each gridline that is scrollable
    : 48;

  const style = {
    top: slotHeight / 2,
    height: `calc(100% - ${slotHeight}px)`,
  };

  return (
    <div
      ref={gridRef}
      className={classNames('ScheduleGrid', className)}
    >
      {renderGridlines(gridlines, slotHeight)}

      {(type === 'schedule' || type === 'schedule-override') && (
        <div
          className="grid-content"
          style={style}
        >
          {days.map((day, dayIndex) => {
            const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');
            const weekDay = startOfWeek.clone().add(dayIndex, 'days');
            const isToday = currTime.format && weekDay.format('YYYY-MM-DD') === currTime.format('YYYY-MM-DD');

            return (
              <GridDay
                key={`grid-day-${day}`}
                day={day}
                weekDay={weekDay}
                activeDisplay={activeDisplay}
                musicOverride={musicOverride}
                slotHeight={slotHeight}
                playlists={playlists.filter(playlist => playlist.day === day)}
                mixes={mixes.filter(mix => mix.day === day)}
                messageBlocks={messageBlocks.filter(messageBlock => messageBlock.day === day)}
                interrupts={interrupts.filter(interrupt => interrupt.day === day)}
                baselineSeparators={baselineSeparators.filter(sep => sep.day === day)}
                events={events.filter(event => event.day === day)}
                spotPools={spotPools.filter(item => item.day === day)}
                spotValues={spotValues.filter(item => item.day === day)}
                adProgramProps={adProgramProps}
                adProgram={adPrograms[dayDD]}
                currTime={isToday
                  ? currTime
                  : null}
                timezone={timezone}
                readOnly={readOnly}
                allowClick={allowClickDay}
                onClick={onClickDay}
              />
            );
          })}
        </div>
      )}

      {(type === 'baseline'
        || type === 'event'
        || type === 'schedule-day'
        || type === 'schedule-override-day'
      ) && (
        <div
          className="grid-content"
          style={style}
        >
          <GridItemColumn
            type="Music"
            activeDisplay={activeDisplay}
            slotHeight={slotHeight}
            items={sortBy([
              ...playlists,
              ...mixes,
            ], 'startTime')}
            activeEvent={activeEvent}
            readOnly={readOnly}
            // onUpdateEvent={onUpdateEvent}
            onUpdateItems={onUpdateItems}
          />

          {type !== 'schedule-override-day' && (
            <GridItemColumn
              type="Message Blocks"
              activeDisplay={activeDisplay}
              slotHeight={slotHeight}
              items={messageBlocks}
              activeEvent={activeEvent}
              readOnly={readOnly}
              // onUpdateEvent={onUpdateEvent}
              onUpdateItems={onUpdateItems}
            />
          )}

          {type !== 'schedule-override-day' && (
            <GridItemColumn
              type="Interrupts"
              activeDisplay={activeDisplay}
              slotHeight={slotHeight}
              items={interrupts}
              activeEvent={activeEvent}
              readOnly={readOnly}
              // onUpdateEvent={onUpdateEvent}
              onUpdateItems={onUpdateItems}
            />
          )}

          {type === 'event' && (
            <GridItem
              key={`event-start-${activeEvent.startTime}`}
              type="Other"
              startTime={activeEvent.startTime}
              stopTime={activeEvent.startTime}
              width="100%"
              height={4}
              left={0}
              style={{
                backgroundColor: activeEvent.color,
              }}
              layer={1}
            />
          )}

          {type === 'event' && (
            <GridItem
              key={`event-stop-${activeEvent.stopTime}`}
              type="Other"
              startTime={activeEvent.stopTime}
              stopTime={activeEvent.stopTime}
              width="100%"
              height={4}
              left={0}
              style={{
                backgroundColor: activeEvent.color,
              }}
              layer={1}
            />
          )}
        </div>
      )}
    </div>
  );
}

ScheduleGrid.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'schedule',
    'schedule-day',
    'schedule-override',
    'schedule-override-day',
    'baseline',
    'event',
  ]).isRequired,
  activeDisplay: PropTypes.string,
  // gridWidth: PropTypes.number,
  gridHeight: PropTypes.number,
  musicOverride: PropTypes.bool,
  startOfWeek: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  currTime: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  timezone: PropTypes.string,
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  interrupts: PropTypes.arrayOf(PropTypes.object),
  baselineSeparators: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  spotPools: PropTypes.arrayOf(PropTypes.object),
  spotValues: PropTypes.arrayOf(PropTypes.object),
  /** Config for the ad program */
  adProgramProps: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  adPrograms: PropTypes.shape({
    SU: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    MO: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    TU: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    WE: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    TH: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    FR: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    SA: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Active Event */
  activeEvent: PropTypes.instanceOf(EventModel),
  /** Allow the day to be clicked (change the style on hover) */
  allowClickDay: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
  /** When the day is clicked on the schedule day */
  onClickDay: PropTypes.func,
  // onUpdateEvent: PropTypes.func,
  onUpdateItems: PropTypes.func,
};

ScheduleGrid.defaultProps = {
  className: '',
  activeDisplay: '',
  // gridWidth: 0,
  gridHeight: 0,
  musicOverride: false,
  startOfWeek: {},
  currTime: {},
  timezone: null,
  playlists: [],
  mixes: [],
  messageBlocks: [],
  interrupts: [],
  baselineSeparators: [],
  events: [],
  spotPools: [],
  spotValues: [],
  adProgramProps: {},
  adPrograms: {},
  activeEvent: null,
  allowClickDay: false,
  readOnly: false,
  onClickDay: () => {},
  // onUpdateEvent: () => {},
  onUpdateItems: () => {},
};

export default ScheduleGrid;
