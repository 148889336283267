import {
  get,
  sortBy,
  isUndefined,
} from 'lodash';
import moment from 'moment';
import { RRule } from 'rrule';
import API from '../../api';
import {
  getMinutes,
  getDuration,
  getOffset,
  convertMinutes,
} from '../../utils/TimeUtil';
import {
  rrule,
} from '../../helpers/DateTime';
import EventModel from '../../models/Event';

function getStartStopTime(eventStartTime, duration, offset) {
  const startHour = parseInt(eventStartTime.split(':')[0], 10);
  const startMinute = parseInt(eventStartTime.split(':')[1], 10);

  const durationHour = parseInt(duration.split(':')[0], 10);
  const durationMinute = parseInt(duration.split(':')[1], 10);

  const offsetHour = parseInt(offset.split(':')[0], 10);
  const offsetMinute = parseInt(offset.split(':')[1], 10);

  const startTotalMinutes = (startHour * 60) + startMinute;
  const durationTotalMinutes = (durationHour * 60) + durationMinute;
  const offsetTotalMinutes = (offsetHour * 60) + offsetMinute;

  // The start time is the event minutes plus the offset minutes
  const startTimeMinutes = startTotalMinutes + offsetTotalMinutes;
  const startTime = convertMinutes(startTimeMinutes);

  // The stop time is the start time minutes plus the duration minutes
  const stopTimeMinutes = startTimeMinutes + durationTotalMinutes;
  const stopTime = convertMinutes(stopTimeMinutes);

  return {
    startTime,
    stopTime,
  };
}

function updateRepeatRule(event) {
  let dtstart = get(event, 'repeatRule.options.dtstart', new Date());
  let interval = get(event, 'repeatRule.options.interval', 1);
  let freq = get(event, 'repeatRule.options.freq', 0);
  let byweekday = get(event, 'repeatRule.options.byweekday', []);
  let until = get(event, 'repeatRule.options.until', null);
  const isRepeat = event.repeat;

  if (event.startDate) {
    const startDateUTC = new moment(event.startDate).format();
    dtstart = new Date(startDateUTC);
  }

  if (event.repeatInterval) {
    try {
      interval = parseInt(event.repeatInterval, 10);
    } catch (e) {
      console.error(e);
    }
  }

  if (event.repeatFrequency) {
    if (event.repeatFrequency === 'week') {
      freq = RRule.WEEKLY;
    } else if (event.repeatFrequency === 'month') {
      freq = RRule.MONTHLY;
    } else if (event.repeatFrequency === 'year') {
      freq = RRule.YEARLY;
    }
  }

  if (event.repeatDays) {
    // array to store the unsorted array of days
    const unsortedDays = [];

    event.repeatDays.forEach((day) => {
      if (day === 'Sunday') {
        unsortedDays.push({
          dayIndex: 0,
          weekday: RRule.SU,
        });
      } else if (day === 'Monday') {
        unsortedDays.push({
          dayIndex: 1,
          weekday: RRule.MO,
        });
      } else if (day === 'Tuesday') {
        unsortedDays.push({
          dayIndex: 2,
          weekday: RRule.TU,
        });
      } else if (day === 'Wednesday') {
        unsortedDays.push({
          dayIndex: 3,
          weekday: RRule.WE,
        });
      } else if (day === 'Thursday') {
        unsortedDays.push({
          dayIndex: 4,
          weekday: RRule.TH,
        });
      } else if (day === 'Friday') {
        unsortedDays.push({
          dayIndex: 5,
          weekday: RRule.FR,
        });
      } else if (day === 'Saturday') {
        unsortedDays.push({
          dayIndex: 6,
          weekday: RRule.SA,
        });
      }
    });

    // sort the days of the week for the RRule string ;BYDAY= list of days
    // needed for the Android client to read the RRule properly
    const sortedDays = sortBy(unsortedDays, 'dayIndex');

    byweekday = sortedDays.map(day => day.weekday);
  }

  if (!isUndefined(event.stopDate)) {
    until = event.stopDate;
  }

  const repeatRule = isRepeat
    ? rrule({
      dtstart,
      interval,
      freq,
      byweekday,
      until,
      // enforce Sunday as the first day of the week
      wkst: RRule.SU,
    })
    : rrule({
      dtstart,
    });

  return repeatRule;
}

/**
 * Save an Event
 */
export function saveEvent(event) {
  return async (dispatch, getState) => {
    const {
      login: {
        user,
      },
    } = getState();

    const locationData = user.getLocations({
      locations: event.locations,
      allLocations: event.allLocations,
      companyId: event.companyId,
    });

    const playlistItems = event.playlists.map((item) => {
      return {
        musicId: item.playlist._id,
        type: 'playlist',
        offset: item.offset,
        duration: item.duration,
      };
    });

    const mixItems = event.mixes.map((item) => {
      return {
        musicId: item.mix._id,
        type: 'mix',
        offset: item.offset,
        duration: item.duration,
      };
    });

    const musicParts = playlistItems.concat(mixItems);

    // Get tags to add/remove
    const modifyTags = event.tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

    // Save data for the API
    const data = {
      name: event.title,
      companyId: event.companyId,
      description: event.description,
      locations: locationData,
      duration: event.duration,
      schedule: event.schedule,
      scheduleOriginTimeZone: event.scheduleOriginTimeZone,
      color: event.color,
      playBaselineMusic: event.playBaselineMusic,
      playBaselineMessageList: event.playBaselineMessageBlocks,
      playBaselineInterrupts: event.playBaselineInterrupts,
      volume: event.volume,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
      musicParts,
      messageParts: event.messageBlocks.map((item) => {
        return {
          messageListId: item.messageBlock._id,
          offset: item.offset,
          duration: item.duration,
        };
      }),
      interrupts: event.interruptions.map((item) => {
        return {
          messageId: item.message._id,
          offset: item.offset,
        };
      }),
    };

    if (event._id && event._id.indexOf('new-event') <= -1) {
      data._id = event._id;
    }

    try {
      const eventResponse = event._id.indexOf('new-event') >= 0
        ? await API.Event.create(data)
        : await API.Event.update(data);

      return eventResponse;
    } catch (err) {
      return [{
        type: 'EVENT.ERROR',
        error: err,
      }];
    }
  };
}

/**
 * Update an Event
 */
export function updateEvent(event) {
  const {
    updateRule,
  } = event;

  // if (updateRule) {
  //   // Do not set this parameter on the event
  //   delete event.updateRule;
  // }

  // if (event.date) {
  //   // Do not update the date of the event
  //   delete event.date;
  // }

  const {
    playlists,
    mixes,
    messageBlocks,
    interruptions,
  } = event;

  // get the event start time (depending on if a time was passed in the data)
  const eventStartTime = event.startTime;
  // get the event stop time (depending on if a time was passed in the data)
  const eventStopTime = event.stopTime;
  // get the event stop time minutes
  const eventStopMinutes = getMinutes(eventStopTime);

  // Start/stop time for the event was changed. Get new duration.
  event.duration = getDuration(eventStartTime, eventStopTime);

  // are the items in the event within the bounds of the event
  let itemsInBounds = true;
  let errorMessage = null;

  event.playlists = playlists.map((playlist) => {
    playlist.offset = getOffset(eventStartTime, playlist.startTime);
    playlist.duration = getDuration(playlist.startTime, playlist.stopTime);
    playlist.minutes = getMinutes(playlist.duration);

    const {
      startTime: playlistStartTime,
      stopTime: playlistStopTime,
    } = getStartStopTime(eventStartTime, playlist.duration, playlist.offset);

    const playlistStopMinutes = getMinutes(playlistStopTime);

    if (playlistStopMinutes > eventStopMinutes) {
      itemsInBounds = false;
    }

    return {
      ...playlist,
      startTime: playlistStartTime,
      stopTime: playlistStopTime,
    };
  });

  event.mixes = mixes.map((mix) => {
    mix.offset = getOffset(eventStartTime, mix.startTime);
    mix.duration = getDuration(mix.startTime, mix.stopTime);
    mix.minutes = getMinutes(mix.duration);

    const {
      startTime: mixStartTime,
      stopTime: mixStopTime,
    } = getStartStopTime(eventStartTime, mix.duration, mix.offset);

    const mixStopMinutes = getMinutes(mixStopTime);

    if (mixStopMinutes > eventStopMinutes) {
      itemsInBounds = false;
    }

    return {
      ...mix,
      startTime: mixStartTime,
      stopTime: mixStopTime,
    };
  });

  event.messageBlocks = messageBlocks.map((messageBlock) => {
    messageBlock.offset = getOffset(eventStartTime, messageBlock.startTime);
    messageBlock.duration = getDuration(messageBlock.startTime, messageBlock.stopTime);
    messageBlock.minutes = getMinutes(messageBlock.duration);

    const {
      startTime: blockStartTime,
      stopTime: blockStopTime,
    } = getStartStopTime(eventStartTime, messageBlock.duration, messageBlock.offset);

    const blockStopMinutes = getMinutes(blockStopTime);

    if (blockStopMinutes > eventStopMinutes) {
      itemsInBounds = false;
    }

    return {
      ...messageBlock,
      startTime: blockStartTime,
      stopTime: blockStopTime,
    };
  });

  event.interruptions = interruptions.map((interrupt) => {
    interrupt.offset = getOffset(eventStartTime, interrupt.startTime);

    const {
      startTime: interruptStartTime,
    } = getStartStopTime(eventStartTime, '00:00', interrupt.offset);

    const interruptStartMinutes = getMinutes(interruptStartTime);

    if (interruptStartMinutes > eventStopMinutes) {
      itemsInBounds = false;
    }

    return {
      ...interrupt,
      startTime: interruptStartTime,
    };
  });

  // The items in the event are no longer within the event bounds
  if (!itemsInBounds) {
    errorMessage = 'All items in the event must be within the event bounds';
    console.error(errorMessage);
  }

  event.repeatRule = updateRule
    ? updateRepeatRule(event)
    : event.repeatRule;

  event.schedule = event.repeatRule.toString();

  const updatedEvent = new EventModel(event);
  // console.log('event1', event);
  // console.log('event2', updatedEvent);

  return updatedEvent;
}

export default {
  saveEvent,
  updateEvent,
};
