import Location from './Location';

class Company {
  constructor(data = {}) {
    this._id = data._id || data.companyId;
    this.active = data.active || true;
    this.name = data.name || data.companyName;
    this.imageUrl = data.imageUrl || null;
    this.locations = [];
    this.locationCount = data.locationCount || 0;
    this.address = data.address || {
      line1: data.address1 || '',
      line2: data.address2 || '',
      city: data.city || '',
      country: data.country || '',
      state: data.state || '',
      postalCode: data.postalCode || '',
    };
    this.category = data.category || null;
    this.salesforceId = data.salesforceId || null;
    this.tags = data.tags || [];

    // Set address items
    this.address1 = data.address1 || this.address.line1;
    this.address2 = data.address2 || this.address.line2;
    this.city = data.city || this.address.city;
    this.country = data.country || this.address.country;
    this.state = data.state || this.address.state;
    this.postalCode = data.postalCode || this.address.postalCode;

    if (data.locations && data.locations.length > 0) {
      data.locations.forEach((location) => {
        this.locations.push(new Location(location));
      });
    }
  }
}

export default Company;
