import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  API,
  NavigationHelper,
  GlobalActions,
  TableMessageBlocks,
  viAdd,
  withRouter,
} from 'vibeguide';
import MessageBlockSidebar from './Sidebar/MessageBlockSidebar';
import SubNavigation from '../../Header/SubNavigation';
import './MessageBlocksContainer.scss';

class MessageBlocksContainer extends PureComponent {
  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.messageBlockId && qs.type !== 'new';
    const isDuplicate = qs.messageBlockId && qs.type === 'new';

    if (isNew || isView || isDuplicate) {
      setTimeout(() => {
        this.sidebar(qs.type, qs.messageBlockId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.messageBlockId || qs.type === 'new') {
        this.sidebar(qs.type, qs.messageBlockId);
      }
    }
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      messageBlockId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  sidebar = (type, messageBlockId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <MessageBlockSidebar
          messageBlockId={messageBlockId}
          isNew={type === 'new'}
          isDuplicate={messageBlockId !== null && type === 'new'}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    return (
      <div className="MessageBlocksContainer">
        <SubNavigation
          title="Message Blocks"
          buttons={[
            {
              text: 'New Message Block',
              icon: viAdd,
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userCan: [
                'messagelist.create',
              ],
            },
          ]}
        />

        <TableMessageBlocks
          columns={[
            '.',
            'Name',
            'Company',
            'Integration',
            'Frequency',
            'Consecutive',
            'Start Type',
            'Sequence',
            'Locations',
            'Tags',
            '...',
          ]}
          fetch={API.MessageBlock.list}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Message Blocks',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Message Blocks',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          cardProps={{
            requireFilter: true,
          }}
          rowLink={{
            messageBlockId: '_id',
          }}
          menuItems={[
            { name: 'Archive', userCan: 'messagelist.delete' },
          ]}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(MessageBlocksContainer));
