import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/notifications`;

export default {
  list: options => Http.get(baseUrl, options),
  markRead: (id, options) => Http.post(`${baseUrl}/${id}/read`, options),
  markAllRead: (data, options = {}) => {
    options = addVersion('3.0.6', options);
    return Http.put(`${baseUrl}/read`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
};
