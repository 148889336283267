import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
  size,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  list as mediaFormatList,
  getIcon as getMediaFormatIcon,
  getTooltip as getMediaFormatTooltip,
} from '../../../helpers/MediaFormat';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import VibeButtonNew from '../../VibeButton/VibeButtonNew';
import Avatar from '../../Avatar/Avatar';
import ContentTags from '../CellTypes/ContentTags';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import IconCell from '../CellTypes/IconCell';
import VibeButton from '../../VibeButton/VibeButton';
import VibeAlert from '../../VibeAlert/VibeAlert';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAddCircle from '../../../icons/viAddCircle';
import viCloseCircle from '../../../icons/viCloseCircle';
import viDownload from '../../../icons/viDownload';
import viSpeaker from '../../../icons/viSpeaker';
import defaultCompanyImage from '../../../assets/default_company.png';
import countries from '../../../utils/Countries';
import color from '../../../sass/color.scss';

const tableId = 'table:locations';
const permissionPrefix = 'location';

class TableLocations extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Add/Remove',
      defaultWidth: 77,
      icon: viAddCircle,
      locked: true,
    },
    {
      name: 'Select',
      defaultWidth: 125,
      locked: true,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      valueAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Name (Text Only)',
      label: 'Name',
      searchAttr: 'name',
      valueAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyId',
      sortAttr: 'companyName',
      valueAttr: 'companyName',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'company',
      sortable: true,
    },
    {
      name: 'Category',
      searchAttr: 'category',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'company-category',
      sortable: true,
    },
    {
      name: 'Status',
      searchAttr: 'playerOnline',
      defaultWidth: 125,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Online',
          value: true,
        },
        {
          label: 'Offline',
          value: false,
        },
      ],
    },
    {
      name: 'Media Format',
      searchAttr: 'mediaFormat',
      icon: viSpeaker,
      defaultWidth: 95,
      resizable: false,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...mediaFormatList,
      ],
    },
    {
      name: 'Address 1',
      searchAttr: 'address.line1',
      sortAttr: 'address1',
      valueAttr: 'address1',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Address 2',
      searchAttr: 'address.line2',
      sortAttr: 'address2',
      valueAttr: 'address2',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Country',
      searchAttr: 'address.country',
      sortAttr: 'country',
      valueAttr: 'country',
      defaultWidth: 200,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'City',
      searchAttr: 'address.city',
      sortAttr: 'city',
      valueAttr: 'city',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'State/Province',
      searchAttr: 'address.state',
      sortAttr: 'state',
      valueAttr: 'state',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Postal Code',
      searchAttr: 'address.postalCode',
      sortAttr: 'postalCode',
      valueAttr: 'postalCode',
      defaultWidth: 135,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Latitude',
      searchAttr: 'lat',
      defaultWidth: 190,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Longitude',
      searchAttr: 'long',
      defaultWidth: 190,
      resizable: true,
      sortable: true,
    },
    {
      name: 'DMA',
      searchAttr: 'dmaCode',
      // sortAttr: 'dmaName',
      valueAttr: 'dmaName',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'dma',
    },
    {
      name: 'Ad Network',
      searchAttr: 'adNetworkEnabled',
      defaultWidth: 170,
      sortable: true,
      resizable: true,

    },
    {
      name: 'Ad Programs',
      searchAttr: 'adProgramEnabled',
      defaultWidth: 170,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Content Network Owner',
      searchAttr: 'contentNetworkOwner',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Content Delivery System',
      searchAttr: 'contentDeliverySystem',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Ad Delivery System',
      searchAttr: 'adDeliverySystem',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Ad Network Owner',
      searchAttr: 'adNetworkOwner',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'SAN',
      searchAttr: 'san',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Tags',
      searchAttr: 'tags.name',
      valueAttr: 'tags',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      editable: true,
    },
    // Rikki (Viper) fields for segment allocations
    {
      name: 'Salesforce ID',
      searchAttr: 'salesforceId',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Target Impressions',
      searchAttr: 'targetImpressions',
      defaultWidth: 190,
      resizable: true,
    },
    {
      name: 'Total Impressions',
      searchAttr: 'totalImpressions',
      defaultWidth: 190,
      resizable: true,
    },
    {
      name: 'Total Spots',
      searchAttr: 'totalSpots',
      defaultWidth: 190,
      resizable: true,
    },
    {
      name: 'Frequency',
      searchAttr: 'frequency',
      defaultWidth: 190,
      resizable: true,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveLocation', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveLocation', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = await API.Location.deactivate(_id);

        const successText = 'LOCATION.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.Location.reactivate({
          _id,
        });

        const successText = 'LOCATION.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * Bulk Edit Selected Rows
   */
  onBulkEditApply = async ({
    selected,
    data,
  }) => {
    // bulk add tags to a message
    const response = await API.Location.bulkAddTags({
      mergeMode: 'merge',
      locations: selected.map(item => item._id),
      tags: data.tags.map(tag => tag._id),
    });

    const success = response.filter(result => result.type.indexOf('LOCATION.BULK_APPLY_TAGS') < 0).length <= 0;

    if (success) {
      // refresh table data
      this.onUpdate({
        refresh: true,
      });
    }
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Remove a Location from the table
   */
  onRemoveLocation = (e, _rowId) => {
    const {
      onRemove,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onRemove(row);
  };

  /**
   * Add a Location to the table
   */
  onAddLocation = (e, _rowId) => {
    const {
      onAdd,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onAdd(row);
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (get(props, 'fetchProps.requireFilter') && size(get(state, 'filters', {})) <= 0) {
      // require a filter before fetching any data from the API
      const rows = [];
      const totalItems = 0;

      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        totalItems,
        filters: {},
        requireFilter: get(props, 'fetchProps.requireFilter', false),
      });

      console.log('a search filter must be provided before API results are returned');
      return;
    }

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
        requireFilter: get(props, 'fetchProps.requireFilter', false),
      });
    }
  };

  /**
   * Fetch data for the table
   * Only request and return the response from the API or collection
   */
  fetchData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    const {
      rows,
      // totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    return rows;
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      highlight,
    } = this.props;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Add/Remove': {
        const assigned = find(highlight, { _id: row._id }) !== undefined;

        return (
          <VibeIcon
            name={row._rowId}
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.fireBrick
              : color.aquaForest}
            hoverColor={assigned
              ? color.fireBrick75
              : color.aquaForest75}
            size={24}
            tooltip={assigned
              ? 'Remove Location'
              : 'Add Location'}
            tooltipProps={{
              placement: 'right',
            }}
            onClick={assigned
              ? this.onRemoveLocation
              : this.onAddLocation}
          />
        );
      }

      case 'Select': {
        const {
          highlight,
          onSelectLocation,
        } = this.props;

        const rowId = get(row, '_id');
        const selectedId = get(highlight, '[0]._id');

        return (
          <div
            className="cell-content"
            style={{
              textAlign: 'center',
            }}
          >
            <VibeButtonNew
              text="Select"
              color={color.violetVibe}
              disabled={rowId === selectedId}
              onClick={() => {
                onSelectLocation(row);
              }}
            />
          </div>
        );
      }

      case 'Name':
        return (
          <Avatar
            imageUrl={row.imageUrl}
            imageDefault={defaultCompanyImage}
            text={value}
            tooltipProps={{
              placement: 'top',
              enterDelay: 500,
            }}
            displayText
          />
        );

      // Currently only used for the Rikki Flight Segment details
      case 'Name (Text Only)':
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {get(row, 'warnings.length', 0) > 0 && (
              <VibeAlert
                style={{
                  marginRight: 8,
                  fontSize: 12,
                }}
                severity="warning"
                alerts={row.warnings || []}
                size={24}
                iconOnly
              />
            )}

            <CellTooltip title={value}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </div>
            </CellTooltip>
          </div>
        );

      case 'Country': {
        const srcSetRoot = 'https://flagcdn.com/w40';
        const srcRoot = 'https://flagcdn.com/w20';

        return (
          <div>
            {row.country && (
              <div>
                <img
                  loading="lazy"
                  width="20"
                  style={{
                    marginRight: 10,
                  }}
                  srcSet={`${srcSetRoot}/${row.country?.toLowerCase()}.png 2x`}
                  src={`${srcRoot}/${row.country?.toLowerCase()}.png`}
                  alt=""
                />
                {countries.find(country => country.code === row.country)?.label}
              </div>
            )}
          </div>
        );
      }

      case 'Status':
        return (
          <LabelCell
            value={row.playerOnline
              ? 'Online'
              : 'Offline'}
            background={row.playerOnline
              ? color.aquaForest16
              : color.fireBrick16}
            color={row.playerOnline
              ? color.aquaForest
              : color.fireBrick}
          />
        );

      case 'Media Format':
        return (
          <IconCell
            rowId={row._rowId}
            icon={getMediaFormatIcon(value)}
            color={color.manatee}
            tooltip={getMediaFormatTooltip(value)}
            tooltipProps={{
              placement: 'right',
            }}
          />
        );

      case 'Ad Network':
        return (
          <LabelCell
            value={row.adNetworkEnabled
              ? 'Enabled'
              : 'Disabled'}
            background={row.adNetworkEnabled
              ? color.aquaForest16
              : color.fireBrick16}
            color={row.adNetworkEnabled
              ? color.aquaForest
              : color.fireBrick}
          />
        );

      case 'Ad Programs':
        return (
          <LabelCell
            value={row.adProgramEnabled
              ? 'Enabled'
              : 'Disabled'}
            background={row.adProgramEnabled
              ? color.aquaForest16
              : color.fireBrick16}
            color={row.adProgramEnabled
              ? color.aquaForest
              : color.fireBrick}
          />
        );

      case 'Tags':
        return (
          <ContentTags
            className="cell-content"
            tags={row.tags}
            hideIcon
          />
        );

      case 'Target Impressions':
      case 'Total Impressions':
      case 'Total Spots':
      case 'Frequency': {
        // convert the number to a comma separated string
        const numValue = value.toLocaleString();

        return (
          <CellTooltip title={numValue}>
            <div className="cell-content">
              {numValue}
            </div>
          </CellTooltip>
        );
      }

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      rootStyle,
      toolbarProps,
      csv,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
    } = this.state;

    const totalItemsStr = totalItems.toLocaleString();
    const customButtons = [];

    if (csv) {
      customButtons.push(
        <VibeButton
          key="btn-export"
          variant="outlined"
          text="Export"
          style={{
            marginRight: 8,
          }}
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viDownload}
              color={color.violetVibe}
              size={16}
            />
          )}
          tooltip={`Export ${totalItemsStr} Locations to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />,
      );
    }

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...customButtons,
        ...get(toolbarProps, 'customButtons', []),
      ],
    };

    return (
      <div
        className={classNames('Table', 'TableLocations', className)}
        style={rootStyle}
      >
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          bulkArchive={API.Location.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkEditApply={this.onBulkEditApply}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onFetch={this.fetchData}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableLocations.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
  onSelectLocation: PropTypes.func,
};

TableLocations.defaultProps = {
  ...tablePropsDefault,
  onSelectLocation: () => {},
};

export default withRouter(TableLocations);
