import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ObjectSelection.scss';

function ObjectSelection({
  className,
  leftColumn,
  rightColumn,
}) {
  return (
    <div className={classNames('ObjectSelection', className)}>
      <div className="location-col unassigned">
        {leftColumn}
      </div>

      <div className="location-col assigned">
        {rightColumn}
      </div>
    </div>
  );
}

ObjectSelection.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Left content */
  leftColumn: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** Right content */
  rightColumn: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ObjectSelection.defaultProps = {
  className: '',
};

export default ObjectSelection;
