import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import VibeIcon from '../VibeIcon/VibeIcon';
import color from '../../sass/color.scss';
import './PercentMeter.scss';

class PercentMeter extends PureComponent {
  constructor(props) {
    super(props);

    const {
      percentage,
    } = props;

    this.state = {
      percentage,
      range: this.getRange(percentage),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      percentage,
    } = this.props;

    const {
      percentage: prevPercentage,
    } = prevProps;

    if (percentage !== prevPercentage) {
      this.setState({
        percentage,
        range: this.getRange(percentage),
      });
    }
  }

  /**
   * Get the range for this percentage
   */
  getRange = (percentage) => {
    const {
      ranges,
    } = this.props;

    let useRange = ranges[0];

    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      const rangeSplt = range.range.split('-');
      const rangeMin = parseInt(rangeSplt[0], 10);
      const rangeMax = parseInt(rangeSplt[1], 10);

      if (percentage >= rangeMin && percentage <= rangeMax) {
        useRange = range;
        break;
      }
    }

    return useRange;
  };

  render() {
    const {
      width,
      textPosition,
      disabled,
    } = this.props;

    const {
      percentage,
      range,
    } = this.state;

    return (
      <div className="PercentMeter">
        <div className="percentage">
          <div
            style={{
              color: disabled
                ? color.manatee
                : range.color,
              display: textPosition === 'right'
                ? 'flex'
                : 'block',
              alignItems: textPosition === 'right'
                ? 'center'
                : 'stretch',
            }}
          >
            {textPosition === 'top' ? (
              <div className="percentage-text">
                {range.icon ? (
                  <VibeIcon
                    className="percentage-icon"
                    icon={range.icon}
                    color={range.color}
                    size={12}
                  />
                ) : null}

                <span>
                  {disabled
                    ? 'N/A'
                    : `${percentage}%`}
                </span>
              </div>
            ) : null}

            <div
              className="percent-container"
              style={{
                width,
                marginTop: textPosition === 'top'
                  ? 3
                  : 0,
                border: range.border
                  ? `1px solid ${range.color}`
                  : `1px solid ${color.whiteSmoke}`,
              }}
            >
              <div
                className="value"
                style={{
                  width: disabled
                    ? 0
                    : `${percentage}%`,
                  backgroundColor: range.color,
                }}
              />
            </div>

            {textPosition === 'right' ? (
              <div
                className="percentage-text"
                style={{
                  marginLeft: 8,
                }}
              >
                {range.icon ? (
                  <VibeIcon
                    className="percentage-icon"
                    icon={range.icon}
                    color={disabled
                      ? color.manatee
                      : range.color}
                    size={12}
                  />
                ) : null}

                <span>
                  {disabled
                    ? 'N/A'
                    : `${percentage}%`}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

PercentMeter.propTypes = {
  /** Width of meter */
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /** Position of the text */
  textPosition: PropTypes.oneOf([
    'top',
    'right',
  ]),
  /** Percentage */
  percentage: PropTypes.number,
  /** Ranges of percents with custom options */
  ranges: PropTypes.arrayOf(PropTypes.shape({
    range: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
  })),
  /** Is the meter disabled */
  disabled: PropTypes.bool,
};

PercentMeter.defaultProps = {
  width: '100%',
  textPosition: 'top',
  percentage: 0,
  ranges: [{
    range: '0-100',
    color: '#6d6db5',
    border: false,
    icon: null,
  }],
  disabled: false,
};

export default PercentMeter;
