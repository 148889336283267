import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Toast from './Toast';
import './Toasts.scss';

function Toasts({
  toasts,
}) {
  return (
    <div className="Toasts">
      {toasts.map((toast) => {
        return (
          <Toast
            key={toast.id}
            toast={toast}
          />
        );
      })}
    </div>
  );
}

Toasts.propTypes = {
  // Toasts are an array of object (speicifc object props in Toast component)
  toasts: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
};

Toasts.defaultProps = {
  toasts: [],
};

function mapStateToProps(state) {
  return {
    toasts: state.toast.toasts,
  };
}

export default connect(mapStateToProps)(Toasts);
