import { Component } from 'react';
import {
  withRouter,
} from 'vibeguide';

class ScrollRestoration extends Component {
  componentDidUpdate(prevProps) {
    const {
      location,
    } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
    } = this.props;

    return children;
  }
}

export default withRouter(ScrollRestoration);
