import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import VibeCard from '../VibeCard/VibeCard';
import TagList from '../Tags/TagList';
import defaultLocationImage from '../../assets/default_location.png';
import './LocationCard.scss';

class LocationCard extends Component {
  toggleCard = () => {
    const {
      id,
      toggleSelected,
      selected,
      disabled,
      allowClick,
      onClick,
    } = this.props;

    onClick(id);

    if (!disabled && allowClick) {
      // Send selected state back to the parent component
      toggleSelected(id, !selected);
    }
  };

  render() {
    const {
      id,
      className,
      width,
      style,
      imageUrl,
      name,
      tags,
      playerOnline,
      onboardStatus,
      location,
      disabled,
      selected,
      allowClick,
      viewLocationDetails,
      showStatus,
      hideSelectedIcon,
    } = this.props;

    const isOnline = playerOnline;
    const isInTransit = onboardStatus !== 'complete';

    let status = isOnline
      ? 'online'
      : 'offline';

    if (isInTransit) {
      status = 'transit';
    }

    return (
      <div className="LocationCard">
        <VibeCard
          className={`card-container ${className}`}
          width={width}
          height={216}
          style={{
            padding: 0,
            marginBottom: 0,
            ...style,
          }}
          selected={selected}
          disabled={disabled}
          onClick={this.toggleCard}
          hideSelectedIcon={hideSelectedIcon}
          allowClick={allowClick}
        >
          <div className="location-data">
            <div className="name-container">
              {showStatus ? (
                <div className={`location-status location-status-${status}`} />
              ) : null}

              <div className="location-name">
                {name}
              </div>
            </div>

            <div className="company-container">
              <div className="location">
                {location}
              </div>
            </div>

            <div className="location-image-container">
              {imageUrl ? (
                <img className="location-image" src={imageUrl} alt={name} />
              ) : (
                <img className="location-image" src={defaultLocationImage} alt={name} />
              )}

              {viewLocationDetails ? (
                <div className="location-details">
                  <Link
                    id={`location-link-${id}`}
                    className="details-link"
                    to={{
                      pathname: `/location/${id}/schedule`,
                    }}
                  >
                    <Button variant="outlined" className="details-button">
                      View Details
                    </Button>
                  </Link>
                </div>
              ) : null}
            </div>

            <TagList
              tags={tags}
              style={{
                height: '48px',
                margin: '0 10px',
              }}
            />

          </div>
        </VibeCard>
      </div>
    );
  }
}

LocationCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  tags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  playerOnline: PropTypes.bool,
  onboardStatus: PropTypes.string,
  location: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  allowClick: PropTypes.bool,
  viewLocationDetails: PropTypes.bool,
  toggleSelected: PropTypes.func,
  showStatus: PropTypes.bool,
  /** Hide the selected icon */
  hideSelectedIcon: PropTypes.bool,
  onClick: PropTypes.func,
};

LocationCard.defaultProps = {
  className: '',
  width: 350,
  style: {},
  tags: [],
  imageUrl: null,
  playerOnline: false,
  onboardStatus: 'setup',
  location: null,
  allowClick: false,
  viewLocationDetails: false,
  disabled: false,
  selected: false,
  showStatus: false,
  hideSelectedIcon: false,
  toggleSelected: () => {},
  onClick: () => {},
};

export default LocationCard;
