// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaselineSeparatorItem {
  width: 100%;
  height: 100%;
}

.BaselineSeparatorItemTooltip .data-row {
  display: flex;
  align-items: center;
  margin: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Grid/Items/BaselineSeparatorItem.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAIE;EACE,aAAA;EACA,mBAAA;EACA,aAAA;AADJ","sourcesContent":[".BaselineSeparatorItem {\n  width: 100%;\n  height: 100%;\n}\n\n.BaselineSeparatorItemTooltip {\n\n  .data-row {\n    display: flex;\n    align-items: center;\n    margin: 8px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
