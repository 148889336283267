/**
 * Utils file/Helpers file
 * This file is used for functions or utilities that we might have to use all around the application
 */
import { values } from 'lodash';

// Regex for verifying the email address is correct
// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Regex that test to make sure there is a number
export const numberRegex = /\d/;
// Regex to verify that there is an uppercase letter
export const uppercaseRegex = /[A-Z]/;
// Regex to verify there is a lowercase letter
export const lowercaseRegex = /[a-z]/;
// Regex to verify that there is a special character present
export const specialCharacterRegex = /[-/\\^$*+?.()|[\]{}!@#%&]/;

// Function that will validate that a valid email address has been entered
export const validateEmail = email => {
  return emailRegex.test(email);
};

// Function to test and verify there is a number in the string
export const verifyNumber = str => {
  return numberRegex.test(str);
};

// Function to test and verify that there is an uppercase letter in the string
export const verifyUppercase = str => {
  return uppercaseRegex.test(str);
};

// Function to test and verify that there is a lowercase letter in the string
export const verifyLowercase = str => {
  return lowercaseRegex.test(str);
};

// Function to test and verify that there are special characters in the string
export const verifySpecialCharacter = str => {
  return specialCharacterRegex.test(str);
};

// Function to test and make sure that the string is the right length
export const verifyLength = (str, len) => {
  if (str.length < len) {
    return false;
  }
  return true;
};

// Function to test the validation for the password requirements
export const passwordValidation = (password, passwordConfirm, passwordLength = 8) => {
  let errors = [];

  const errorObject = {
    number: true,
    uppercase: true,
    lowercase: true,
    special: true,
    minLength: true,
    match: true,
  };

  if (verifyNumber(password)) {
    errorObject.number = false;
  }

  if (verifyUppercase(password)) {
    errorObject.uppercase = false;
  }

  if (verifyLowercase(password)) {
    errorObject.lowercase = false;
  }

  if (verifySpecialCharacter(password)) {
    errorObject.special = false;
  }

  if (verifyLength(password, passwordLength)) {
    errorObject.minLength = false;
  }

  if (password && passwordConfirm && password === passwordConfirm) {
    errorObject.match = false;
  }

  errors = values(errorObject);

  if (errors.indexOf(true) === -1) {
    return {
      ...errorObject,
      errors: false,
    };
  }

  return {
    ...errorObject,
    errors: true,
  };
};
