import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  uniq,
} from 'lodash';
import VibeIcon from '../VibeIcon/VibeIcon';
import viAdd from '../../icons/viAdd';
import Chip from './Chip';
import color from '../../sass/color.scss';
import './Chips.scss';

class Chips extends PureComponent {
  constructor(props) {
    super(props);

    const {
      chips = [],
    } = props;

    // Input container ref
    this.inputContainerRef = React.createRef();
    // Input ref
    this.inputRef = React.createRef();

    this.state = {
      // When the input has focus
      inputFocus: false,
      // List of chips currently being added
      addedChips: chips,
    };
  }

  componentDidMount() {
    const {
      showChipsInline,
    } = this.props;

    const {
      inputContainerRef: {
        current: inputContainerRef,
      },
    } = this;

    if (showChipsInline && inputContainerRef) {
      // Scroll to the right
      inputContainerRef.scrollLeft = inputContainerRef.scrollWidth;
    }
  }

  /**
   * When the input is changed
   */
  onChange = (e) => {
    const {
      onChange,
    } = this.props;

    onChange(e);
  };

  /**
   * When the input has focus
   */
  onFocus = (e) => {
    const {
      onFocus,
    } = this.props;

    this.setState({
      inputFocus: true,
    });

    onFocus(e);
  };

  /**
   * When the input is blurred
   */
  onBlur = (e) => {
    const {
      onBlur,
    } = this.props;

    this.setState({
      inputFocus: false,
    });

    onBlur(e);
  };

  /**
   * When the input key is pressed
   */
  onKeyDown = (e) => {
    const {
      onKeyDown,
    } = this.props;

    const {
      key,
    } = e;

    switch (key) {
      // Add the chip
      case 'Enter': {
        this.addSelectedChip();
        break;
      }

      default:
        break;
    }

    onKeyDown(e);
  };

  /**
   * When an added chip is removed
   */
  onRemoveAddedChip = (chip) => {
    const {
      onSet,
    } = this.props;

    const {
      inputRef: {
        current: inputRef,
      },
    } = this;

    if (inputRef) {
      inputRef.focus();
    }

    this.setState((state) => {
      const addedChips = state.addedChips.filter(chipItem => chipItem !== chip);

      onSet(addedChips);

      return {
        addedChips,
      };
    });
  };

  /**
   * Add the selected chip to the object
   */
  addSelectedChip = () => {
    const {
      value,
      showChipsInline,
      onSet,
    } = this.props;

    const {
      addedChips,
    } = this.state;

    const {
      inputContainerRef: {
        current: inputContainerRef,
      },
    } = this;

    if (value) {
      const chipsList = value.split(',');
      const newChips = [];

      chipsList.forEach((chip) => {
        // remove spacing
        const newChip = chip.trim();

        if (newChip) {
          newChips.push(newChip);
        }
      });

      // remove duplicates
      const allChips = uniq([
        ...addedChips,
        ...newChips,
      ]);

      this.setState({
        addedChips: allChips,
      }, () => {
        if (showChipsInline && inputContainerRef) {
          // Scroll to the right
          inputContainerRef.scrollLeft = inputContainerRef.scrollWidth;
        }
      });

      onSet(allChips);

      // Clear the input
      this.clearSearch();
    }
  };

  /**
   * Clear search input
   */
  clearSearch = () => {
    const {
      onChange,
    } = this.props;

    const {
      inputRef: {
        current: inputRef,
      },
    } = this;

    // Clear the input
    onChange({
      target: {
        value: '',
      },
    });

    if (inputRef) {
      inputRef.focus();
    }
  };

  render() {
    const {
      id,
      className,
      assign,
      showChipsInline,
      showChipsList,
      chips,
      style,
      tabIndex,
      label,
      name,
      placeholder,
      value,
      autoFocus,
    } = this.props;

    const {
      inputFocus,
      addedChips,
    } = this.state;

    return (
      <div className="Chips">
        <div
          ref={this.inputContainerRef}
          className={classNames('chips-input-container', {
            focus: inputFocus,
          })}
        >

          {showChipsInline && addedChips.length > 0 ? (
            <div className="added-chips">
              {addedChips.map((chip, index) => {
                return (
                  <Chip
                    key={`chip-inline-${index}`}
                    chip={chip}
                    allowRemove={assign}
                    onRemove={this.onRemoveAddedChip}
                  />
                );
              })}
            </div>
          ) : null}

          <input
            ref={this.inputRef}
            type="text"
            id={`chip-${id}`}
            className={classNames('chips-field', className, {
              focus: inputFocus,
            })}
            label={label}
            name={name}
            tabIndex={tabIndex}
            placeholder={placeholder}
            value={value}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />

          {value ? (
            <div className="add-chip">
              <VibeIcon
                className="add-chip-icon"
                icon={viAdd}
                color={color.manatee}
                size={20}
                onClick={this.addSelectedChip}
              />
            </div>
          ) : null}
        </div>

        {showChipsList && chips.length > 0 ? (
          <div className="chips-list">
            {chips.map((chip, index) => {
              return (
                <Chip
                  key={`chip-list-${index}`}
                  chip={chip}
                  style={{
                    marginTop: 10,
                    marginRight: 8,
                    ...style,
                  }}
                  allowRemove={assign}
                  onRemove={this.onRemoveAddedChip}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

Chips.propTypes = {
  /** ID of item with chips */
  id: PropTypes.string,
  /** Custom class */
  className: PropTypes.string,
  /** Label for field */
  label: PropTypes.string,
  /** Name of the field */
  name: PropTypes.string,
  /** Show chips inline with the input box */
  showChipsInline: PropTypes.bool,
  /** Show chips list below the field */
  showChipsList: PropTypes.bool,
  /** Show the assign chips input */
  assign: PropTypes.bool,
  /** Current chips on the object */
  chips: PropTypes.arrayOf(PropTypes.string),
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Tab index */
  tabIndex: PropTypes.number,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Value of field */
  value: PropTypes.string,
  /** Auto focus the input field */
  autoFocus: PropTypes.bool,
  // /** Field is required */
  // required: PropTypes.bool,
  // /** Disable the input field */
  // disabled: PropTypes.bool,
  /** When the field is changed */
  onChange: PropTypes.func,
  /** When the field has a key pressed */
  onKeyDown: PropTypes.func,
  /** When the field is focused */
  onFocus: PropTypes.func,
  /** When the field is blurred */
  onBlur: PropTypes.func,
  /** When the chips are changed */
  onSet: PropTypes.func,
};

Chips.defaultProps = {
  id: 'new-chip',
  className: '',
  label: 'Chips',
  name: '',
  // hideLabel: false,
  showChipsInline: false,
  showChipsList: true,
  assign: false,
  chips: [],
  style: {},
  tabIndex: -1,
  placeholder: '',
  value: '',
  autoFocus: false,
  // required: false,
  // disabled: false,
  onChange: () => {},
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onSet: () => {},
};

export default Chips;
