// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleGrid {
  position: relative;
  height: 100%;
}
.ScheduleGrid > .grid-content {
  position: absolute;
  left: 55px;
  width: calc(100% - 55px);
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Grid/ScheduleGrid.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;EACA,wBAAA;EACA,aAAA;AACJ","sourcesContent":[".ScheduleGrid {\n  position: relative;\n  height: 100%;\n\n  > .grid-content {\n    position: absolute;\n    left: 55px;\n    width: calc(100% - 55px);\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
