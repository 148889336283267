// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageBlockMessages {
  height: 100%;
}
.MessageBlockMessages .assigned-toolbar {
  display: flex;
  align-items: center;
}
.MessageBlockMessages .assigned-toolbar .btn-toolbar {
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Messages/MessageBlocks/Sidebar/MessageBlockMessages.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AACI;EACE,kBAAA;AACN","sourcesContent":[".MessageBlockMessages {\n  height: 100%;\n\n  .assigned-toolbar {\n    display: flex;\n    align-items: center;\n\n    .btn-toolbar {\n      margin-right: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
