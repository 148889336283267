import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  LocationHelper,
  ExpandedLocationsHelper,
} from 'vibeguide';
import TargetGroupDetails from './TargetGroupDetails';

function TargetGroupSidebar({
  className,
  targetGroupId,
}) {
  const [targetGroup, setTargetGroup] = useState({
    // set default to prevent undefined error
    locationSpec: [],
  });

  /**
   * Get Target Groups
   */
  const getTargetGroup = async () => {
    const targetGroupResponse = await API.TargetGroup.get(targetGroupId);
    const expandedLocations = await API.TargetGroup.getExpandedLocations({
      _id: targetGroupId,
    });

    // Set the location data
    targetGroupResponse.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);
    targetGroupResponse.locationSpec = LocationHelper.toSpec(targetGroupResponse.locations);
    setTargetGroup(targetGroupResponse);
  };

  /**
   * Update the target group value
   */
  const onUpdate = (data) => {
    setTargetGroup({
      ...targetGroup,
      ...data,
    });
  };

  /**
   * Get the target group if the ID changes
   */
  useEffect(() => {
    if (targetGroupId) {
      getTargetGroup();
    }
  }, [targetGroupId]);

  return (
    <div
      className={classNames('TargetGroupSidebar', className)}
      style={{
        height: '100%',
      }}
    >
      <TargetGroupDetails
        targetGroup={targetGroup}
        onUpdate={onUpdate}
      />
    </div>
  );
}

TargetGroupSidebar.propTypes = {
  className: PropTypes.string,
  targetGroupId: PropTypes.string,
};

TargetGroupSidebar.defaultProps = {
  className: '',
  targetGroupId: '',
};

export default TargetGroupSidebar;
