// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PieChart .title {
  font-size: 14px;
  font-weight: 900;
  text-align: center;
}
.PieChart .chart-container {
  display: flex;
  align-items: center;
}
.PieChart .legend-container .legend-title {
  height: 24px;
  font-size: 14px;
  font-weight: 900;
}
.PieChart .legend-container .legend-items {
  max-height: 130px;
  padding-right: 16px;
  overflow: auto;
}
.PieChart .legend-container .legend-items .legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.PieChart .legend-container .legend-items .legend-item:hover {
  opacity: 0.6;
}
.PieChart .legend-container .legend-items .legend-item .item-name {
  margin-left: 4px;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Charts/PieChart.scss"],"names":[],"mappings":"AAEE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;AAFJ;AAOI;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AALN;AAQI;EACE,iBAAA;EACA,mBAAA;EACA,cAAA;AANN;AAQM;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AANR;AAQQ;EACE,YAAA;AANV;AASQ;EACE,gBAAA;EACA,eAAA;AAPV","sourcesContent":[".PieChart {\n\n  .title {\n    font-size: 14px;\n    font-weight: 900;\n    text-align: center;\n  }\n\n  .chart-container {\n    display: flex;\n    align-items: center;\n  }\n\n  .legend-container {\n\n    .legend-title {\n      height: 24px;\n      font-size: 14px;\n      font-weight: 900;\n    }\n\n    .legend-items {\n      max-height: 130px;\n      padding-right: 16px;\n      overflow: auto;\n\n      .legend-item {\n        display: flex;\n        align-items: center;\n        margin-bottom: 8px;\n\n        &:hover {\n          opacity: 0.6;\n        }\n\n        .item-name {\n          margin-left: 4px;\n          font-size: 10px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
