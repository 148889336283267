import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CellTooltip from './CellTooltip';

function NumberCell({
  className,
  style,
  value,
}) {
  const formatValue = value.toLocaleString();

  return (
    <CellTooltip title={formatValue}>
      <div
        className={classNames('NumberCell', className)}
        style={style}
      >
        {formatValue}
      </div>
    </CellTooltip>
  );
}

NumberCell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.number,
};

NumberCell.defaultProps = {
  className: '',
  style: {},
  value: '',
};

export default NumberCell;
