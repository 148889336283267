import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import defaultUserImage from '../../assets/user-bubble.png';
import './UserAvatar.scss';

function UserAvatar({
  className,
  avatar,
  size,
  name,
}) {
  return (
    <div className={classNames('UserAvatar', className)}>
      <div
        className="avatar"
        style={{
          width: size,
          height: size,
        }}
      >
        <img
          className="avatar-image"
          style={{
            width: size,
            height: size,
          }}
          src={avatar || defaultUserImage}
          alt={name || 'User'}
        />
      </div>

      {name ? (
        <div className="name">
          {name}
        </div>
      ) : null}
    </div>
  );
}

UserAvatar.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string,
};

UserAvatar.defaultProps = {
  className: '',
  avatar: '',
  size: 24,
  name: '',
};

export default UserAvatar;
