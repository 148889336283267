import { handleActions } from 'redux-actions';

const initialState = {
  _id: null,
  companyId: '',
  companyName: '',
  userAccountPermissionSet: {
    _id: '',
    name: '',
  },
  tags: [],
  bulkImage: null,
  bulkInformationConfirmed: false,
  data: {
    status: null,
    rowCount: 0,
    sets: {},
    dataUrl: null,
    validationResult: {},
  },
};

export default handleActions(
  {
    SET_IMPORT_LOCATIONS_ID: (state, action) => {
      const _id = action.payload;

      return {
        ...state,
        _id,
      };
    },

    SET_IMPORT_LOCATIONS_BULK_IMAGE: (state, action) => {
      const bulkImage = action.payload;

      return {
        ...state,
        bulkImage,
      };
    },

    SET_IMPORT_LOCATIONS_DATA: (state, action) => {
      const data = action.payload;

      return {
        ...state,
        data,
      };
    },

    SET_IMPORT_LOCATIONS_COMPANY: (state, action) => {
      const data = action.payload;

      const companyId = data.companyId !== undefined
        ? data.companyId
        : state.companyId;

      const companyName = data.companyName !== undefined
        ? data.companyName
        : state.companyName;

      return {
        ...state,
        companyId,
        companyName,
        // Only keep admin tags since client tags are linked to the company
        tags: state.companyId && state.companyId !== companyId
          ? state.tags.filter(tag => tag.type === 'admin')
          : state.tags,
      };
    },

    SET_IMPORT_LOCATIONS_USER_ACCOUNT_PERMISSION_SET: (state, action) => {
      const userAccountPermissionSet = action.payload;

      return {
        ...state,
        userAccountPermissionSet,
      };
    },

    SET_IMPORT_LOCATIONS_TAGS: (state, action) => {
      const tags = action.payload;

      // Update the tags
      return {
        ...state,
        tags,
      };
    },

    SET_IMPORT_LOCATIONS_BULK_INFORMATION_CONFIRMED: (state, action) => {
      const bulkInformationConfirmed = action.payload;

      return {
        ...state,
        bulkInformationConfirmed,
      };
    },

    RESET_IMPORT_LOCATIONS: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
