// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar {
  display: flex;
  align-items: center;
}
.Avatar > .avatar-container {
  overflow: hidden;
  border-radius: 100%;
  user-select: none;
}
.Avatar > .avatar-container.has-text {
  margin-right: 12px;
}
.Avatar > .text {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Avatar/Avatar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AACI;EACE,kBAAA;AACN;AAGE;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AADJ","sourcesContent":[".Avatar {\n  display: flex;\n  align-items: center;\n\n  > .avatar-container {\n    overflow: hidden;\n    border-radius: 100%;\n    user-select: none;\n\n    &.has-text {\n      margin-right: 12px;\n    }\n  }\n\n  > .text {\n    flex-grow: 1;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
