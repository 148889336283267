import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  EventModel,
  VibeIcon,
  viMusic,
  viMicrophone,
  viAlarm,
  color,
} from 'vibeguide';
import PassThrough from './PassThrough';
import './ContainerColumns.scss';

function ContainerColumns({
  className,
  type,
  activeEvent,
  readOnly,
  onUpdateEvent,
}) {
  const onTogglePassThrough = (title) => {
    onUpdateEvent({
      playBaselineMusic: title === 'Music'
        ? !activeEvent.playBaselineMusic
        : activeEvent.playBaselineMusic,
      playBaselineMessageBlocks: title === 'Message Block'
        ? !activeEvent.playBaselineMessageBlocks
        : activeEvent.playBaselineMessageBlocks,
      playBaselineInterrupts: title === 'Interrupt'
        ? !activeEvent.playBaselineInterrupts
        : activeEvent.playBaselineInterrupts,
    });
  };

  return (
    <div className={classNames('ContainerColumns', className)}>
      <div className="column">
        <div className="content">
          <VibeIcon
            icon={viMusic}
            color={color.manatee}
            size={16}
          />

          <div className="title">
            Music
          </div>

          {type === 'event' && (
            <PassThrough
              title="Music"
              enabled={activeEvent.playBaselineMusic}
              eventColor={activeEvent.color}
              onChange={!readOnly
                ? onTogglePassThrough
                : null}
            />
          )}
        </div>
      </div>

      <div className="column">
        <div className="content">
          <VibeIcon
            icon={viMicrophone}
            color={color.manatee}
            size={16}
          />

          <div className="title">
            Message Blocks
          </div>

          {type === 'event' && (
            <PassThrough
              title="Message Block"
              enabled={activeEvent.playBaselineMessageBlocks}
              eventColor={activeEvent.color}
              onChange={!readOnly
                ? onTogglePassThrough
                : null}
            />
          )}
        </div>
      </div>

      <div className="column">
        <div className="content">
          <VibeIcon
            icon={viAlarm}
            color={color.manatee}
            size={16}
          />

          <div className="title">
            Interruptions
          </div>

          {type === 'event' && (
            <PassThrough
              title="Interrupt"
              enabled={activeEvent.playBaselineInterrupts}
              eventColor={activeEvent.color}
              onChange={!readOnly
                ? onTogglePassThrough
                : null}
            />
          )}
        </div>
      </div>
    </div>
  );
}

ContainerColumns.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'baseline',
    'event',
  ]).isRequired,
  /** Active Event */
  activeEvent: PropTypes.instanceOf(EventModel),
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
  onUpdateEvent: PropTypes.func,
};

ContainerColumns.defaultProps = {
  className: '',
  activeEvent: null,
  readOnly: false,
  onUpdateEvent: () => {},
};

export default ContainerColumns;
