// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Telemetry {
  height: calc(100% - 150px);
}
.Telemetry .content-header .content-title {
  display: flex;
  align-items: center;
  padding: 24px;
  padding-bottom: 8px;
  font-size: 24px;
  font-weight: 900;
}
.Telemetry .telemetry-button-group {
  display: inline-flex;
  padding-right: 24px;
  padding-bottom: 16px;
}
.Telemetry .submenu-wrapper {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Telemetry > .content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 118px);
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/EditLocation/Telemetry.scss"],"names":[],"mappings":"AACA;EACE,0BAAA;AAAF;AAII;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAFN;AAME;EACE,oBAAA;EACA,mBAAA;EACA,oBAAA;AAJJ;AAOE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AALJ;AAQE;EACE,aAAA;EACA,sBAAA;EACA,0BAAA;AANJ","sourcesContent":["// @import '~vibeguide/sass/color.scss';\n.Telemetry {\n  height: calc(100% - 150px);\n\n  .content-header {\n\n    .content-title {\n      display: flex;\n      align-items: center;\n      padding: 24px;\n      padding-bottom: 8px;\n      font-size: 24px;\n      font-weight: 900;\n    }\n  }\n\n  .telemetry-button-group {\n    display: inline-flex;\n    padding-right: 24px;\n    padding-bottom: 16px;\n  }\n\n  .submenu-wrapper {\n    height: 44px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  > .content {\n    display: flex;\n    flex-direction: column;\n    height: calc(100% - 118px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
