import { createAction } from 'redux-actions';

export const addLocation = createAction('ADD_AD_PROGRAM_LOCATION');
export const removeLocation = createAction('REMOVE_AD_PROGRAM_LOCATION');
export const removeAllLocations = createAction('REMOVE_AD_PROGRAM_ALL_LOCATIONS');
export const setAllLocations = createAction('SET_AD_PROGRAM_ALL_LOCATIONS');
export const resetNewAdProgram = createAction('RESET_NEW_AD_PROGRAM');

export default {
  addLocation,
  removeLocation,
  removeAllLocations,
  setAllLocations,
  resetNewAdProgram,
};
