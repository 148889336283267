import React from 'react';

const viMicrophoneList = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M17 14.105c1.457 0 2.571-1.094 2.571-2.526V6.526C19.571 5.095 18.457 4 17 4s-2.571 1.095-2.571 2.526v5.053c0 1.432 1.114 2.526 2.571 2.526zm0 1.769c-2.4 0-4.543-1.769-4.543-4.295H11c0 2.863 2.314 5.221 5.143 5.642V20h1.714v-2.779C20.686 16.8 23 14.442 23 11.58h-1.457c0 2.526-2.143 4.295-4.543 4.295zm-7.211-1c0 .09-.058.162-.13.162H4.586c-.072 0-.13-.073-.13-.162v-1.4c0-.09.058-.162.13-.162h5.073c.072 0 .13.073.13.162v1.4zm2.514 2.703v1.4c0 .089-.086.161-.191.161H4.65c-.105 0-.19-.072-.19-.161v-1.4c0-.09.085-.162.19-.162h7.462c.105 0 .191.072.191.162M8.988 9.412v1.318c0 .112-.04.203-.09.203H4.546c-.05 0-.09-.09-.09-.203V9.412c0-.112.04-.202.09-.202h4.354c.049 0 .089.09.089.202M12.99 5.31v1.318c0 .112-.075.202-.167.202H4.627c-.093 0-.168-.09-.168-.202V5.31c0-.112.075-.203.168-.203h8.197c.092 0 .167.09.167.203m-10.208.102V6.73c0 .112-.015.202-.035.202H1.035c-.02 0-.035-.09-.035-.202V5.412c0-.112.016-.203.035-.203h1.713c.02 0 .035.09.035.203m0 3.996v1.318c0 .112-.015.203-.035.203H1.035c-.02 0-.035-.091-.035-.203V9.408c0-.112.016-.203.035-.203h1.713c.02 0 .035.09.035.203m0 4.106v1.318c0 .112-.015.203-.035.203H1.035c-.02 0-.035-.09-.035-.203v-1.318c0-.112.016-.202.035-.202h1.713c.02 0 .035.09.035.202m0 4.103v1.319c0 .111-.015.202-.035.202H1.035c-.02 0-.035-.09-.035-.202v-1.319c0-.112.016-.202.035-.202h1.713c.02 0 .035.09.035.202" />
    </g>
  </svg>
);

export default viMicrophoneList;
