import React from 'react';

const viRefresh = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M11.733 4.267A5.08 5.08 0 0 0 8 2.667c-2.933 0-5.333 2.4-5.333 5.333 0 2.933 2.4 5.333 5.333 5.333 2.467 0 4.533-1.733 5.133-4h-1.4C11.2 10.867 9.733 12 8 12c-2.2 0-4-1.8-4-4s1.8-4 4-4c1.133 0 2.067.467 2.8 1.2L8.667 7.333h4.666V2.667l-1.6 1.6z" />
    </g>
  </svg>
);

export default viRefresh;
