import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const apiUrl = process.env.VAPI_BASE_URL || 'https://api-dev.vibenomics.com/api';
const baseUrl = `${apiUrl}/advertisers`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: id => Http.get(`${baseUrl}/${id}`),
  create: data => Http.post(baseUrl, data),
  modify: (id, data) => Http.put(`${baseUrl}/${id}`, data),
  uploadImage: (id, image, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/image`, image, 'image', onProgress,
  ),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
};
