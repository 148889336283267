/**
 * List of supported Spot Types
 */
export const list = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Endemic',
    value: 'endemic',
  },
  {
    label: 'Non Endemic',
    value: 'non-endemic',
  },
  {
    label: 'Adult Beverage',
    value: 'adult-beverage',
  },
];

export default {
  list,
};
