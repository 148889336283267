import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  NavigationHelper,
  GlobalActions,
  API,
  TableAdvertisers,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import AdvertiserSidebar from './Sidebar/AdvertiserSidebar';
import './AdvertiserList.scss';

function AdvertiserList({
  location,
  history,
  setPanel,
}) {
  const prevSearchRef = useRef(location.search);

  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      advertiserId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, advertiserId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      children: (
        <AdvertiserSidebar
          advertiserId={advertiserId}
          isNew={type === 'new'}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.advertiserId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        sidebar(qs.type, qs.advertiserId);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    const {
      search,
    } = location;

    if (search !== prevSearchRef) {
      const qs = NavigationHelper.getParams();

      if (qs.advertiserId || qs.type === 'new') {
        sidebar(qs.type, qs.advertiserId);
      }
    }
  }, [location.search]);

  return (
    <div className="AdvertiserList">
      <SubNavigation
        title="Advertisers"
        buttons={[
          {
            text: 'New Advertiser',
            icon: viAdd,
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
            userCan: [
              'advertiser.create',
            ],
          },
        ]}
      />

      <TableAdvertisers
        columns={[
          'Name',
          '...',
        ]}
        fetch={API.Advertiser.list}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Advertisers',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Advertisers',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: false,
          add: false,
          block: false,
          remove: false,
          archive: false,
        }}
        rowLink={{
          advertiserId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'advertiser.delete' },
        ]}
      />
    </div>
  );
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(AdvertiserList));
