import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  map,
} from 'lodash';
import {
  PieChart,
  NavigationHelper,
  withRouter,
} from 'vibeguide';

class AppVersion extends PureComponent {
  /**
   * When the pie chart is clicked
   */
  onClickPieChart = (data) => {
    const {
      includeFlaggedLocations,
      history,
    } = this.props;

    // Get the label of the area clicked
    const label = get(data, 'name', null);

    if (!label) {
      console.error('No label found for the chart');
      return;
    }

    console.log('label', label);

    const urlData = {
      playerOnline: true,
    };

    if (!includeFlaggedLocations) {
      urlData.flagEnabled = false;
    }

    // Add a parameter for the table to automatically apply
    const url = NavigationHelper.updateParams(urlData, {
      pathname: '/dashboard/support',
    });

    history(url);
  };

  render() {
    const {
      data,
      includeFlaggedLocations,
    } = this.props;

    const donutData = map(data, (count, version) => {
      const versionData = {
        vibenomicsPlayerVersion: version,
      };

      if (!includeFlaggedLocations) {
        versionData.flagEnabled = false;
      }

      const versionUrl = NavigationHelper.updateParams(versionData, {
        pathname: '/dashboard/support',
      });

      return {
        name: version,
        value: count,
        url: versionUrl,
      };
    });

    return (
      <div className="AppVersion">
        <PieChart
          title="App Version"
          width={315}
          legend="right"
          data={donutData}
          onClick={this.onClickPieChart}
        />
      </div>
    );
  }
}

AppVersion.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  includeFlaggedLocations: PropTypes.bool,
};

AppVersion.defaultProps = {
  includeFlaggedLocations: false,
};

export default withRouter(AppVersion);
