import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/playlists`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  create: data => Http.post(baseUrl, data),
  uploadImage: (id, file, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/image`, file, 'image', onProgress,
  ),
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  update: (id, data) => Http.put(`${baseUrl}/${id}`, data),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (playlists) => Http.delete(baseUrl, {
    playlists,
  }),
};
