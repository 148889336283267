import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  isEqual,
  capitalize,
  find,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  convertDuration,
} from '../../../utils/TimeUtil';
import { list as adProviderList } from '../../../helpers/AdProvider';
import { list as spotTypeList } from '../../../helpers/SpotType';
import VibeTable from '../VibeTable';
import VibeButton from '../../VibeButton/VibeButton';
import VibeIcon from '../../VibeIcon/VibeIcon';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import TimestampCell from '../CellTypes/TimestampCell';
import viTime from '../../../icons/viTime';
import viDownload from '../../../icons/viDownload';
import color from '../../../sass/color.scss';

const tableId = 'table:telemetry:ad:reporting';

class TableAdReporting extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Created Date',
      searchAttr: 'createdDate',
      defaultWidth: 235,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Ad Status',
      searchAttr: 'status',
      defaultWidth: 230,
      resizable: true,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Reason',
      searchAttr: 'reason',
      defaultWidth: 230,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Spot Type',
      searchAttr: 'spotType',
      defaultWidth: 140,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...spotTypeList,
      ],
    },
    {
      name: 'Expected Delivery Time',
      searchAttr: 'expectedDelivery',
      defaultWidth: 235,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Play Time',
      searchAttr: 'playTime',
      defaultWidth: 235,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Report Time',
      searchAttr: 'reportTime',
      defaultWidth: 235,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Media Name',
      searchAttr: 'name',
      defaultWidth: 160,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Size',
      searchAttr: 'size',
      defaultWidth: 150,
      resizable: true,
    },
    {
      name: 'Duration',
      searchAttr: 'durationSeconds',
      defaultWidth: 100,
      icon: viTime,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Advertiser',
      searchAttr: 'advertiser',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Ad Provider',
      searchAttr: 'adProvider',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...adProviderList.map(item => ({
          label: item.name,
          value: item.value,
        })),
      ],
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
    };

    // listen for when data changes
    document.addEventListener('onUpdateTableTelemetryAdReporting', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onUpdateTableTelemetryAdReporting', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    const {
      paginatorProps: {
        urlPaging,
      },
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        pageNumber: urlPaging
          ? parseInt(getParam('page') || 1, 10)
          : 1,
      },
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Get label style for the message type cell
   */
  getLabelStyle = (status) => {
    switch (status) {
      case 'confirmed':
        return {
          text: 'Confirmed',
          textStyle: {
            background: color.aquaForest16,
            color: color.aquaForest,
          },
        };

      case 'read':
        return {
          text: 'Ready',
          textStyle: {
            background: color.yellow75,
            color: color.yellow,
          },
        };

      case 'started':
        return {
          text: 'Started',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'terminated':
        return {
          text: 'Terminated',
          textStyle: {
            background: color.fireBrick16,
            color: color.fireBrick,
          },
        };

      case 'confirmation-failed':
        return {
          text: 'Failed',
          textStyle: {
            background: color.fireBrick16,
            color: color.fireBrick,
          },
        };

      case 'timed-out':
        return {
          text: 'Timed Out',
          textStyle: {
            background: color.yellow75,
            color: color.yellow,
          },
        };

      default:
        return {
          text: capitalize(status),
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Created Date':
      case 'Expected Delivery Time':
      case 'Play Time':
      case 'Report Time':
        return (
          <TimestampCell
            time={value}
            format="ddd, MMM DD, YYYY, h:mm:ssa"
          />
        );

      case 'Size':
        return (
          <div className="cell-content">
            {value && value.length
              ? `${value[0]} x ${value[1]}`
              : `${value}`}
          </div>
        );

      case 'Spot Type':
        return (
          <div className="cell-content">
            {find(spotTypeList, { value })?.label}
          </div>
        );

      case 'Ad Provider':
        return (
          <div className="cell-content">
            {find(adProviderList, { value })?.name}
          </div>
        );

      case 'Ad Status': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Duration': {
        const duration = convertDuration(value, 'm:ss');

        return (
          <CellTooltip title={duration}>
            <div className="cell-content">
              {duration}
            </div>
          </CellTooltip>
        );
      }

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      toolbarProps,
      csv,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
    } = this.state;

    const customButtons = csv
      ? [(
        <VibeButton
          key="btn-export"
          variant="outlined"
          text="Export"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viDownload}
              color={color.violetVibe}
              size={16}
            />
          )}
          tooltip={`Export ${totalItems} Ad Report to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />
      )]
      : [];

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TableTelemetryAdReporting', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />
      </div>
    );
  }
}

TableAdReporting.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableAdReporting.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableAdReporting);
