import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, sortBy } from 'lodash';
import API from '../../../api';
import TargetTag from '../TargetTag';
import TargetTagListItem from '../TargetTagListItem';
import SearchInput from '../../SearchInput/SearchInput';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAdd from '../../../icons/viAdd';
import viStore from '../../../icons/viStore';
import {
  addTargetTags,
} from '../../../actions/TargetTag/TargetTagActions';
import color from '../../../sass/color.scss';
import './PanelList.scss';

class PanelList extends PureComponent {
  constructor(props) {
    super(props);

    const {
      allowCreate,
      allowCopy,
      user,
    } = props;

    this.isAtBottom = false;

    // Timeout to run search
    this.searchTimeout = null;

    this.state = {
      showCreate: user.can('targeting_tag.create') && allowCreate,
      showCopy: user.can('targeting_tag.modify') && allowCopy,
      searchValue: '',
      searchResults: [],
      pageNumber: 1,
      hasMoreItems: false,
    };
  }

  componentDidMount() {
    this.getTargetTags();
  }

  componentWillUnmount() {
    clearTimeout(this.searchTimeout);
  }

  onScroll = (e) => {
    const {
      target: {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
    } = e;

    const {
      hasMoreItems,
    } = this.state;

    // Pad the bottom to trigger the next page before hitting the bottom
    const padBottom = 100;
    const scrollBottom = scrollTop + clientHeight;

    const atBottom = scrollBottom >= scrollHeight - padBottom;

    if (atBottom && !this.isAtBottom) {
      // At the bottom
      this.isAtBottom = true;

      if (hasMoreItems) {
        this.getTargetTags();
      }
    } else if (!atBottom && this.isAtBottom) {
      // Not at the bottom
      this.isAtBottom = false;
    }
  };

  /**
   * When search is changed
   */
  onSearchChanged = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState((state) => {
      return {
        searchValue: value,
        searchResults: value
          ? state.searchResults
          : [],
      };
    });

    clearTimeout(this.searchTimeout);

    if (value) {
      this.searchTimeout = setTimeout(this.search, 500);
    }
  };

  /**
   * Get Target Tags
   */
  getTargetTags = async () => {
    const {
      user,
      addTargetTags,
    } = this.props;

    const {
      pageNumber,
    } = this.state;

    if (!user.can('targeting_tag.view')) {
      console.error('Cannot list target tags');
      return false;
    }

    const targetTagsResponse = await API.TargetTag.list({
      pageNumber,
      active: true,
    });

    const targetTags = get(targetTagsResponse, 'data', []);
    addTargetTags(targetTags);

    this.setState((state) => {
      return {
        pageNumber: state.pageNumber + 1,
        hasMoreItems: targetTagsResponse.pageNumber !== targetTagsResponse.totalPages,
      };
    });

    return targetTags;
  };

  /**
   * Search Target Tags
   */
  search = async () => {
    const {
      searchValue,
    } = this.state;

    const results = await API.TargetTag.list({
      filters: {
        qualifiedName: searchValue,
        active: true,
      },
    });

    this.setState({
      searchResults: results.data,
    });
  };

  render() {
    const {
      className,
      targetTags,
      allowSelect,
      showNewTargetTag,
      showCopyToLocations,
      onShowNewTargetTag,
      onCopyToLocations,
    } = this.props;

    const {
      showCreate,
      showCopy,
      searchValue,
      searchResults,
    } = this.state;

    return (
      <div
        className={classNames('PanelList', className)}
        onScroll={this.onScroll}
      >
        {showCreate ? (
          <div
            className={classNames('target-tag-action', 'action-new', { active: showNewTargetTag })}
            onClick={onShowNewTargetTag}
          >
            <VibeIcon
              icon={viAdd}
              color={color.aquaForest}
              size={16}
            />

            <div className="text">
              New Target Tag
            </div>
          </div>
        ) : null}

        {showCopy ? (
          <div
            className={classNames('target-tag-action', 'action-copy', { active: showCopyToLocations })}
            onClick={onCopyToLocations}
          >
            <VibeIcon
              icon={viStore}
              color={color.violetVibe}
              size={16}
            />

            <div className="text">
              Copy to Locations
            </div>
          </div>
        ) : null}

        <div className="search-tags">
          <SearchInput
            value={searchValue}
            onChange={this.onSearchChanged}
          />
        </div>

        {searchResults.length > 0 ? (
          <div className="search-results">
            {searchResults.map((tag) => {
              return (
                <TargetTag
                  key={tag._id}
                  className="tag-search"
                  qualifiedName={tag.qualifiedName}
                  allowCopy
                />
              );
            })}
          </div>
        ) : null}

        <div className="target-tags">
          {targetTags.map((tag) => {
            return (
              <TargetTagListItem
                key={tag._id}
                level={1}
                tag={tag}
                allowSelect={allowSelect}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

PanelList.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Allow target tag creation */
  allowCreate: PropTypes.bool,
  /** Allow target tag copy to locations */
  allowCopy: PropTypes.bool,
  /** Allow selection of target tags */
  allowSelect: PropTypes.bool,
  /** When New Target Tag is toggled */
  onShowNewTargetTag: PropTypes.func,
  /** When Copy to Locations is selected */
  onCopyToLocations: PropTypes.func,
};

PanelList.defaultProps = {
  className: '',
  allowCreate: false,
  allowCopy: false,
  allowSelect: false,
  onShowNewTargetTag: () => {},
  onCopyToLocations: () => {},
};

function mapStateToProps(state) {
  const targetTags = state.targetTag.tags.filter(tag => tag.parentId === undefined);

  return {
    user: state.login.user,
    targetTags: sortBy(targetTags, 'name'),
    showNewTargetTag: state.targetTag.activeTag._id === 'new',
    showCopyToLocations: state.targetTag.activeTag._id === 'copy',
  };
}

const mapDispatchToProps = {
  addTargetTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelList);
