import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  queueToast,
} from '../../actions/Toast/ToastActions';
import Field from '../Field/Field2';
import VibeIcon from '../VibeIcon/VibeIcon';
import viSend from '../../icons/viSend';
import NoteMessage from './NoteMessage';
import color from '../../sass/color.scss';

function Notes({
  className,
  style,
  notes,
  allowCreate,
  numVisibleMessages,
  queueToast,
  onCreate,
}) {
  const [noteMessage, setNoteMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const notesRef = useRef(null);

  // only send a note if there is one to send and it's not loading
  const allowSend = noteMessage && !loading;

  /**
   * Note was successfully added
   */
  const onCreateNoteSuccess = () => {
    setLoading(false);
    setNoteMessage('');
  };

  /**
   * Note was not added due to an error
   */
  const onCreateNoteError = (message) => {
    setLoading(false);

    queueToast({
      type: 'error',
      title: message,
      timeout: 10,
      allowClose: true,
    });
  };

  /**
   * When the user adds a note
   */
  const onSendNote = () => {
    setLoading(true);
    onCreate(noteMessage, onCreateNoteSuccess, onCreateNoteError);
  };

  /**
   * When the user is typing a new note
   */
  const onKeyDownNote = (e) => {
    if (e.key === 'Enter' && allowSend) {
      // Save the note
      onSendNote();
    }
  };

  /**
   * When the note changes
   */
  const onChangeNote = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setNoteMessage(value);
  };

  /**
   * Scroll to the bottom of the notes
   */
  useEffect(() => {
    notesRef.current.scrollTop = notesRef.current.scrollHeight;
  }, [notes]);

  return (
    <div
      className={classNames('Notes', className)}
      style={style}
    >
      <div
        style={{
          textTransform: 'uppercase',
          color: color.violetVibe,
          fontWeight: 'bold',
        }}
      >
        Notes
      </div>

      <div
        ref={notesRef}
        className="note-message-container"
        style={{
          // make the height exactly the size of the number of visible messages (assuming 1 line messages)
          height: (numVisibleMessages * 86) - 16,
          margin: '12px 0',
          padding: 24,
          border: `1px solid ${color.whiteSmoke}`,
          borderRadius: 4,
          overflowY: 'auto',
        }}
      >
        {notes.map((note) => (
          <NoteMessage
            key={note._id}
            _id={note._id}
            userId={note.userId}
            username={note.username}
            date={note.date}
            avatarUrl={note.avatarUrl}
            type={note.type}
            note={note.note}
          />
        ))}

        {notes.length <= 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 14,
              color: color.manatee,
            }}
          >
            Looks pretty empty in here...add a note!
          </div>
        )}
      </div>

      {allowCreate && (
        <div
          className="note-input-container"
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Field
              type="text"
              name="note"
              value={noteMessage}
              placeholder="Enter new note..."
              onKeyDown={onKeyDownNote}
              onChange={onChangeNote}
            />
          </div>

          <VibeIcon
            icon={viSend}
            type="button"
            buttonProps={{
              size: 32,
              color: color.violetVibe,
              borderColor: color.violetVibe,
              style: {
                marginLeft: 8,
              },
            }}
            tooltip="Send"
            tooltipProps={{
              placement: 'right',
            }}
            color={color.white}
            size={20}
            disabled={!allowSend}
            onClick={onSendNote}
          />
        </div>
      )}
    </div>
  );
}

Notes.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  notes: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    userId: PropTypes.string,
    username: PropTypes.string,
    date: PropTypes.string,
    avatarUrl: PropTypes.string,
    type: PropTypes.string,
    note: PropTypes.string,
  })),
  /** Allow the user to create a new note */
  allowCreate: PropTypes.bool,
  /** Height of the container determined from the number of notes to show */
  numVisibleMessages: PropTypes.number,
  /** Method to add a note to the API */
  onCreate: PropTypes.func,
};

Notes.defaultProps = {
  className: '',
  style: {},
  notes: [],
  allowCreate: false,
  numVisibleMessages: 5,
  onCreate: () => {},
};

const mapDispatchToProps = {
  queueToast,
};

export default connect(null, mapDispatchToProps)(Notes);
