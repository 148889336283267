import React from 'react';

const viAddCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12.714 3.286c-2.607-2.607-6.821-2.607-9.428 0-2.607 2.607-2.607 6.821 0 9.428 2.607 2.607 6.821 2.607 9.428 0 2.56-2.56 2.606-6.668.14-9.284l-.14-.144zm-4.05 8.763H7.336V8.665H3.951v-1.33h3.384V3.951h1.33v3.384h3.384v1.33H8.665v3.384z" transform="translate(-24 -265) translate(20 145) translate(0 60) translate(4 60)" />
    </g>
  </svg>
);

export default viAddCircle;
