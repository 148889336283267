import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  toInteger,
} from 'lodash';
import {
  Field2 as Field,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function Information({
  className,
  name,
  size,
  disableInput,
  onUpdate,
}) {
  const sizeWidth = size[0] || '';
  const sizeHeight = size[1] || '';

  /**
   * When a field is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When either of the size fields are changed
   */
  const onChangeSize = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    // convert the value to a number
    const newSize = toInteger(value);

    if (name === 'sizeWidth') {
      // update the width
      onUpdate({
        size: [
          newSize,
          sizeHeight,
        ],
      });
    } else if (name === 'sizeHeight') {
      // update the height
      onUpdate({
        size: [
          sizeWidth,
          newSize,
        ],
      });
    }
  };

  return (
    <div className={classNames('Information', 'resolution', className)}>
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Information
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          xs={12}
          item
        >
          <Field
            type="text"
            label="Name"
            name="name"
            placeholder="Name"
            value={name}
            disabled={disableInput}
            tabIndex={1}
            onChange={onChange}
            autoFocus
            required
          />
        </Grid>

        <Grid
          xs={6}
          item
        >
          <Field
            type="number"
            label="Width (in pixels)"
            name="sizeWidth"
            value={sizeWidth}
            numberProps={{
              min: 0,
            }}
            tabIndex={2}
            disabled={disableInput}
            onChange={onChangeSize}
            required
          />
        </Grid>

        <Grid
          xs={6}
          item
        >
          <Field
            type="number"
            label="Height (in pixels)"
            name="sizeHeight"
            value={sizeHeight}
            numberProps={{
              min: 0,
            }}
            tabIndex={2}
            disabled={disableInput}
            onChange={onChangeSize}
            required
          />
        </Grid>
      </Grid>
    </div>
  );
}

Information.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.arrayOf(PropTypes.number),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  className: '',
  name: '',
  size: [0, 0],
  disableInput: false,
  onUpdate: () => {},
};

export default Information;
