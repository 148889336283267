import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeTooltip,
} from 'vibeguide';
import './DisplayOptions.scss';

function DisplayOptions({
  className,
  activeDisplay,
  onChangeDisplay,
}) {
  return (
    <div className={classNames('DisplayOptions', className)}>
      <VibeTooltip title="View the entire schedule">
        <div
          className={classNames('option', { active: activeDisplay === '24h' })}
          onClick={() => onChangeDisplay('24h')}
        >
          24h
        </div>
      </VibeTooltip>

      <VibeTooltip title="Zoom in to see each hour">
        <div
          className={classNames('option', { active: activeDisplay === '1h' })}
          onClick={() => onChangeDisplay('1h')}
        >
          1h
        </div>
      </VibeTooltip>

      <VibeTooltip title="Zoom in to see 15 minute intervals">
        <div
          className={classNames('option', { active: activeDisplay === '15m' })}
          onClick={() => onChangeDisplay('15m')}
        >
          15m
        </div>
      </VibeTooltip>

      <VibeTooltip title="Zoom in to see each minute">
        <div
          className={classNames('option', { active: activeDisplay === '1m' })}
          onClick={() => onChangeDisplay('1m')}
        >
          1m
        </div>
      </VibeTooltip>
    </div>
  );
}

DisplayOptions.propTypes = {
  className: PropTypes.string,
  activeDisplay: PropTypes.string,
  onChangeDisplay: PropTypes.func,
};

DisplayOptions.defaultProps = {
  className: '',
  activeDisplay: '',
  onChangeDisplay: () => {},
};

export default DisplayOptions;
