import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  size,
  isEqual,
  isEmpty,
  isArray,
  toUpper,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import { list as adProviderList } from '../../../helpers/AdProvider';
import { statusList as orderStatusList } from '../../../helpers/Order';
import MissingCreatives from '../../MissingCreatives/MissingCreatives';
import {
  list as mediaFormatList,
  getIcon as getMediaFormatIcon,
  getTooltip as getMediaFormatTooltip,
} from '../../../helpers/MediaFormat';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import Field from '../../Field/Field2';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import IconCell from '../CellTypes/IconCell';
import TimestampCell from '../CellTypes/TimestampCell';
import viSpeaker from '../../../icons/viSpeaker';
import color from '../../../sass/color.scss';

const tableId = 'table:orders';
const permissionPrefix = 'order';

class TableOrders extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Creative',
      searchAttr: 'missingCreatives',
      defaultWidth: 170,
      searchable: true,
      sortable: true,
      resizable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Missing',
          value: true,
        },
        {
          label: 'Attached',
          value: false,
        },
      ],
    },
    {
      name: 'Status',
      searchAttr: 'status',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...orderStatusList.map(item => ({
          label: item.name,
          value: item.value,
        })),
      ],
    },
    {
      name: 'Opportunity Name',
      searchAttr: 'opportunityName',
      defaultWidth: 230,
      resizable: true,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Opportunity ID',
      searchAttr: 'opportunityId',
      defaultWidth: 225,
      resizable: true,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Deal ID',
      searchAttr: 'dealId',
      defaultWidth: 225,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyId',
      sortAttr: 'companyName',
      valueAttr: 'companies',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'company',
    },
    {
      name: 'Order Reference ID',
      searchAttr: 'extReferenceId',
      defaultWidth: 225,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Delivery System',
      searchAttr: 'deliverySystem',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchSelector: 'delivery-system',
      sortable: true,
    },
    {
      name: 'Advertiser',
      searchAttr: 'advertiserName',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Ad Provider',
      searchAttr: 'adProvider',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...adProviderList.map(item => ({
          label: item.name,
          value: item.value,
        })),
      ],
    },
    {
      name: 'Media Format',
      searchAttr: 'mediaFormats',
      icon: viSpeaker,
      defaultWidth: 95,
      resizable: false,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...mediaFormatList,
      ],
    },
    {
      name: 'Start Date',
      searchAttr: 'startDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'End Date',
      searchAttr: 'endDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Modified Date',
      searchAttr: 'modifiedDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Created Date',
      searchAttr: 'createdDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Created By',
      searchAttr: 'createdByUserName',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
      confirmReason: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveOrder', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveOrder', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * When the confirm reason input is changed
   */
  onChangeConfirmReason = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      confirmReason: value,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      // Copy the Order
      case 'copy': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to copy ${row.name}?`,
          confirmApproveText: 'Yes, Copy',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      // Pause the Order
      case 'pause': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: '',
          confirmApproveText: 'Yes, Pause',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      // Resume a Paused Order
      case 'resume': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to resume ${row.name}?`,
          confirmApproveText: 'Yes, Resume',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      // Cancel the Order
      case 'cancel': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: '',
          confirmApproveText: 'Yes, Cancel',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
      confirmReason,
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      // Copy an Order
      case 'copy': {
        const response = await API.Order.copy({
          _id,
        });

        const successText = 'FLIGHT.CREATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      // Pause the Order
      case 'pause': {
        const response = await API.Order.pause({
          _id,
          statusReason: confirmReason,
        });

        const successText = 'ORDER.PAUSED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      // Resume a Paused Order
      case 'resume': {
        const response = await API.Order.resume({
          _id,
        });

        const successText = 'ORDER.RESUMED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      // Cancel the Order
      case 'cancel': {
        const response = await API.Order.cancel({
          _id,
          statusReason: confirmReason,
        });

        const successText = 'ORDER.CANCELLED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'archive': {
        const response = await API.Order.deactivate(_id);

        const successText = 'ORDER.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.Order.reactivate({
          _id,
        });

        const successText = 'ORDER.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (get(props, 'fetchProps.requireFilter') && size(get(state, 'filters', {})) <= 0) {
      // require a filter before fetching any data from the API
      const rows = [];
      const totalItems = 0;

      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        totalItems,
        filters: {},
        requireFilter: get(props, 'fetchProps.requireFilter', false),
      });

      console.log('a search filter must be provided before API results are returned');
      return;
    }

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
        requireFilter: get(props, 'fetchProps.requireFilter', false),
      });
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
      confirmReason: '',
    });
  };

  /**
   * Get the text for the confirmation modal
   */
  getConfirmText = () => {
    const {
      confirmRow,
      confirmAction,
      confirmText,
      confirmReason,
    } = this.state;

    // pause requires a reason input, which needs to be rendered when the input is changed
    if (confirmAction === 'pause') {
      return (
        <div>
          Are you sure you want to pause {confirmRow.name}?

          <div
            style={{
              marginTop: 16,
            }}
          >
            <Field
              type="textarea"
              style={{
                height: 150,
              }}
              label="Reason"
              placeholder="Reason for Pausing..."
              value={confirmReason}
              onChange={this.onChangeConfirmReason}
              autoFocus
              required
            />
          </div>
        </div>
      );
    }

    // cancel requires a reason input, which needs to be rendered when the input is changed
    if (confirmAction === 'cancel') {
      return (
        <div>
          Are you sure you want to cancel {confirmRow.name}?

          <div
            style={{
              marginTop: 16,
            }}
          >
            <Field
              type="textarea"
              style={{
                height: 150,
              }}
              label="Reason"
              placeholder="Reason for Canceling..."
              value={confirmReason}
              onChange={this.onChangeConfirmReason}
              autoFocus
              required
            />
          </div>
        </div>
      );
    }

    // render the confirm text from the state variable (no reason input needed)
    return confirmText;
  };

  /**
   * Get label style for the cell
   */
  getLabelStyle = (status) => {
    const item = find(orderStatusList, { value: status });

    return get(item, 'tagStyle', {
      background: color.manatee16,
      color: color.manatee,
    });
  };

  // parse content for company multiselect
  getCompaniesString = (value) => {
    let tooltipTitle = '';
    value.forEach((option) => {
      tooltipTitle += `${option.companyName}, `;
    });
    return tooltipTitle.slice(0, -2);
  };

  // get string value for Missing Creative
  getCreativeString = (value, row) => {
    const adProvider = get(row, 'adProvider', '');

    if (adProvider !== 'vibenomics') {
      return 'N/A';
    }
    switch (value) {
      case true:
        return 'Missing';

      case false:
        return 'Attached';

      default:
        return 'Missing';
    }
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Company':
        return (
          <CellTooltip title={this.getCompaniesString(value)}>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.getCompaniesString(value)}
            </div>
          </CellTooltip>
        );
      case 'Creative':
        return (
          <CellTooltip title={this.getCreativeString(value, row)}>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
              }}
            >
              <MissingCreatives
                status={this.getCreativeString(value, row)}
              />
            </div>
          </CellTooltip>
        );
      case 'Status': {
        const tagStyle = this.getLabelStyle(value);

        return (
          <LabelCell
            value={toUpper(value)}
            background={tagStyle.background}
            color={tagStyle.color}
            inline
          />
        );
      }

      case 'Media Format': {
        if (isArray(value)) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {value.map((item) => (
                <IconCell
                  key={`media-format-${item}`}
                  rowId={row._rowId}
                  icon={getMediaFormatIcon(item)}
                  color={color.manatee}
                  tooltip={getMediaFormatTooltip(item)}
                  tooltipProps={{
                    placement: 'right',
                  }}
                />
              ))}
            </div>
          );
        }

        // unknown media format (must be an array)
        return (
          <div className="cell-content">
            {value}
          </div>
        );
      }

      case 'Ad Provider':
        return (
          <div className="cell-content">
            {find(adProviderList, { value }).name}
          </div>
        );

      case 'Start Date':
      case 'End Date':
      case 'Modified Date':
      case 'Created Date':
        return (
          <TimestampCell
            time={value}
            format="ddd, MMM DD, YYYY"
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmApproveText,
      confirmApproveColor,
      confirmAction,
      confirmReason,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableOrders', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          // bulkArchive={API.Order.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
            // disable the confirm button if no reason is supplied when pausing
            disabled: (confirmAction === 'pause' || confirmAction === 'cancel')
              && isEmpty(confirmReason),
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={this.getConfirmText()}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableOrders.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableOrders.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableOrders);
