import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  LoadingContent,
} from 'vibeguide';
import ScreenResolutionDetails from './ScreenResolutionDetails';

function ScreenResolutionSidebar({
  className,
  resolutionId,
}) {
  const [resolution, setResolution] = useState({});

  /**
   * Get Resolution Details
   */
  const getResolution = async () => {
    const resolutionResponse = await API.ScreenResolution.getById(resolutionId);
    setResolution(resolutionResponse);
  };

  /**
   * Update the resolution
   */
  const onUpdate = (data) => {
    setResolution({
      ...resolution,
      ...data,
    });
  };

  /**
   * Get the segment if the ID changes
   */
  useEffect(() => {
    if (resolutionId) {
      getResolution();
    }
  }, [resolutionId]);

  if (resolutionId && !resolution._id) {
    // resolution hasn't been fetched from the API yet
    return null;
  }

  return (
    <div
      className={classNames('ScreenResolutionSidebar', className)}
      style={{
        height: '100%',
      }}
    >
      <ScreenResolutionDetails
        resolution={resolution}
        onUpdate={onUpdate}
      />

      {resolutionId && !resolution._id && (
        <LoadingContent />
      )}
    </div>
  );
}

ScreenResolutionSidebar.propTypes = {
  className: PropTypes.string,
  resolutionId: PropTypes.string,
};

ScreenResolutionSidebar.defaultProps = {
  className: '',
  resolutionId: '',
};

export default ScreenResolutionSidebar;
