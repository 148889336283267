import React from 'react';

const viCategory = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="m12 2-5.5 9h11z" />
      <circle fill="currentColor" cx="17.5" cy="17.5" r="4.5" />
      <path fill="currentColor" d="M3 13.5h8v8H3z" />
    </g>
  </svg>
);

export default viCategory;
