import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isUndefined,
} from 'lodash';
import {
  GlobalActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  TableLocations,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import './AllocationLocations.scss';

function AllocationLocations({
  className,
  companyName,
  locations,
  setPanel,
}) {
  // set the active attribute on each location if not present
  // used for a fix for tables with a local collection of rows (active must be present)
  locations.forEach((location) => {
    if (isUndefined(location.active)) {
      location.active = true;
    }
  });

  const onClose = () => {
    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  return (
    <SidePanelContainer className={classNames('AllocationLocations', className)}>
      <SidePanelHeader
        className="sidepanel-header"
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="title">
          Allocations - {companyName}
        </div>
      </SidePanelHeader>

      <SidePanelContent className="sidepanel-content">
        <TableLocations
          columns={[
            'Name (Text Only)',
            'Target Impressions',
            'Total Impressions',
            'Total Spots',
            'Frequency',
            'Salesforce ID',
            '...',
          ]}
          collection={locations}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Locations',
            urlPaging: false,
            urlFilters: false,
          }}
        />
      </SidePanelContent>
    </SidePanelContainer>
  );
}

AllocationLocations.propTypes = {
  className: PropTypes.string,
  companyName: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
};

AllocationLocations.defaultProps = {
  className: '',
  companyName: '',
  locations: [],
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(AllocationLocations);
