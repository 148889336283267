import { handleActions } from 'redux-actions';
import { find } from 'lodash';
import Field from '../../models/Field';

const initialState = {
  fields: [],
  validate: false,
};

export default handleActions(
  {
    ADD_FIELD: (state, action) => {
      const field = action.payload;

      return {
        ...state,
        fields: [...state.fields, new Field(field)],
      };
    },

    REMOVE_FIELD: (state, action) => {
      const id = action.payload;
      const fieldItem = find(state.fields, { id });

      return {
        ...state,
        fields: state.fields.filter(field => field !== fieldItem),
      };
    },

    UPDATE_FIELD: (state, action) => {
      const fieldItem = action.payload;

      return {
        ...state,
        fields: state.fields.map((field) => {
          if (field.id === fieldItem.id) {
            return new Field({
              ...field,
              ...fieldItem,
            });
          }

          return field;
        }),
      };
    },

    VALIDATE_FIELDS: (state, action) => {
      const validate = action.payload;

      return {
        ...state,
        validate,
      };
    },
  },
  // Initial State
  initialState,
);
