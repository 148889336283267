// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 150px);
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/EditLocation/Content.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,0BAAA;AACF","sourcesContent":[".Content {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 150px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
