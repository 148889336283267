import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ContentSteps.scss';

class ContentSteps extends PureComponent {
  getStep = (status) => {
    switch (status) {
      case 'submitted':
        return {
          label: 'Unassigned',
          step: 'step0',
        };

      case 'assigned':
        return {
          label: 'Needs Rough Cut',
          step: 'step1',
        };

      case 'rough-cut-uploaded':
        return {
          label: 'Needs Final Cut',
          step: 'step2',
        };

      case 'final-cut-uploaded':
        return {
          label: 'Awaiting Approval',
          step: 'step3',
        };

      case 'denied':
        return {
          label: 'Denied',
          step: 'step4',
        };

      default:
        return {
          label: 'Unknown',
          step: 'step0',
        };
    }
  };

  render() {
    const {
      textStyle,
      changeRequested,
      status,
      align,
    } = this.props;

    const {
      label,
      step,
    } = this.getStep(status);

    const denied = status === 'denied';

    return (
      <div
        className="ContentSteps"
        style={{
          justifyContent: align,
        }}
      >
        <div
          className={classNames('step-label', {
            'change-requested': changeRequested && !denied,
          })}
          style={textStyle}
        >
          {label}
        </div>

        <div className={classNames('steps', step)}>
          <span
            className={classNames('step', 'unassigned', {
              'change-requested': changeRequested,
              denied,
            })}
          />
          <span
            className={classNames('step', 'needs-rough', {
              'change-requested': changeRequested,
              denied,
            })}
          />
          <span
            className={classNames('step', 'needs-final', {
              'change-requested': changeRequested,
              denied,
            })}
          />
          <span
            className={classNames('step', 'needs-approval', {
              'change-requested': changeRequested,
              denied,
            })}
          />
        </div>
      </div>
    );
  }
}

ContentSteps.propTypes = {
  /** Message Status */
  status: PropTypes.string,
  /** Message Change Requested */
  changeRequested: PropTypes.bool,
  /** Text Style */
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

ContentSteps.defaultProps = {
  status: '',
  changeRequested: false,
  textStyle: {},
};

export default ContentSteps;
