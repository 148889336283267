import { handleActions } from 'redux-actions';

const initialState = {
  eventId: null,
};

export default handleActions(
  {
    SET_EVENT_ID: (state, action) => {
      const eventId = action.payload;

      return {
        ...state,
        eventId,
      };
    },

    RESET_NEW_EVENT: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
