import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  find,
  isEmpty,
  capitalize,
} from 'lodash';
import {
  Outlet,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  API,
  OrderHelper,
  NavigationHelper,
  OrderModel,
  SegmentModel,
  Field2 as Field,
  Tag,
  TableSegments,
  VibeModal,
  VibeIcon,
  VibeTooltip,
  viCopy,
  viPlayCircleOutline,
  viPauseCircleOutline,
  viTrash,
  viTrendingUp,
  viClose,
  color,
} from 'vibeguide';
import SubNavigation from '../../../Header/SubNavigation';

function OrderDetails({
  className,
  style,
  user,
}) {
  const [order, setOrder] = useState({
    data: new OrderModel(),
    segments: [],
  });

  /**
   * Modals need their own state for the types that require a "statusReason"
   * Otherwise the state is lost if using the "confirmModal" object method
   */
  const [confirmCopy, setConfirmCopy] = useState(false);
  const [confirmPause, setConfirmPause] = useState(false);
  const [confirmResume, setConfirmResume] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [statusReason, setStatusReason] = useState('');

  const location = useLocation();
  const isGraph = location.pathname.indexOf('/graph') >= 0;

  const {
    id: orderId,
  } = useParams();

  const history = useNavigate();

  let breadcrumbs = [
    {
      title: 'Dashboard',
      linkUrl: '../../orders/dashboard',
    },
  ];

  /**
   * Get Order Segments
   */
  const getSegments = async () => {
    // if user cannot view segments skip
    if (!user.can('order_segment.view')) {
      return [];
    }

    const params = NavigationHelper.getParams();
    const active = get(params, 'active') !== 'false';

    // get all segments so we can calculate delivered data
    const response = await API.Order.Segment.list(
      {
        _id: orderId,
        filters: {
          active,
        },
        pageSize: -1,
      },
    );

    const segmentItems = get(response, 'data', []).map((segment) => {
      return new SegmentModel(segment);
    });

    return segmentItems;
  };

  /**
   * Get Order Data
   */
  const getOrderData = async () => {
    const response = await API.Order.get(orderId);
    return new OrderModel(response);
  };

  /**
   * Get the Order Details
   */
  const getOrder = async () => {
    if (!orderId) {
      console.error('Order ID not Specified');
      return;
    }

    const orderData = await getOrderData();
    const orderSegments = await getSegments();

    setOrder({
      data: orderData,
      segments: orderSegments,
    });
  };

  /**
   * When the user changes the reason textarea in the confirm modal
   */
  const onChangeStatusReason = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setStatusReason(value);
  };

  /**
   * Copy Order
   */
  const onConfirmCopy = async () => {
    const response = await API.Order.copy({
      _id: orderId,
    });

    const success = get(response, '[0].type') === 'FLIGHT.CREATED';

    // redirect to the copied order (which is now a flight)
    if (success) {
      const copyId = get(response, '[0].documentId');
      history(`/ad-ops/flight/${copyId}`);
    }
  };

  /**
   * Cancel Copy
   */
  const onCancelCopy = () => {
    setConfirmCopy(false);
  };

  /**
   * Pause Order
   */
  const onConfirmPause = async () => {
    const response = await API.Order.pause({
      _id: orderId,
      statusReason,
    });

    const success = get(response, '[0].type') === 'ORDER.PAUSED';

    if (success) {
      setConfirmPause(false);
      setStatusReason('');
      getOrder();
    }
  };

  /**
   * Cancel Pause
   */
  const onCancelPause = () => {
    setConfirmPause(false);
    setStatusReason('');
  };

  /**
   * Resume Order
   */
  const onConfirmResume = async () => {
    const response = await API.Order.resume({
      _id: orderId,
    });

    const success = get(response, '[0].type') === 'ORDER.RESUMED';

    if (success) {
      setConfirmResume(false);
      getOrder();
    }
  };

  /**
   * Cancel Resume
   */
  const onCancelResume = () => {
    setConfirmResume(false);
  };

  /**
   * Cancel Order
   */
  const onConfirmCancel = async () => {
    const response = await API.Order.cancel({
      _id: orderId,
      statusReason,
    });

    const success = get(response, '[0].type') === 'ORDER.CANCELLED';

    if (success) {
      setConfirmCancel(false);
      setStatusReason('');
      getOrder();
    }
  };

  /**
   * Cancel "Canceling" an Order
   */
  const onCancelCancel = () => {
    setConfirmCancel(false);
    setStatusReason('');
  };

  /**
   * Get the Buttons to use for the Dropdown
   */
  const getMenuItems = () => {
    const orderStatus = get(order, 'data.status');

    const items = [{
      text: 'Copy',
      userCan: [
        'flight.create',
      ],
      icon: viCopy,
      color: color.violetVibe,
      onClick: () => setConfirmCopy(true),
    }];

    if (orderStatus === 'paused') {
      // Can only resume orders that have been paused
      items.push({
        text: 'Resume',
        userCan: [
          'order.pause',
        ],
        icon: viPlayCircleOutline,
        color: color.violetVibe,
        onClick: () => setConfirmResume(true),
      });
    } else if (orderStatus === 'live') {
      // Can only pause live orders
      items.push({
        text: 'Pause',
        userCan: [
          'order.pause',
        ],
        icon: viPauseCircleOutline,
        color: color.violetVibe,
        onClick: () => setConfirmPause(true),
      });
    }

    if (orderStatus !== 'cancelled' && orderStatus !== 'completed') {
      // Can only cancel orders that haven't been completed or canceled already
      items.push({
        text: 'Cancel',
        userCan: [
          'order.cancel',
        ],
        icon: viTrash,
        color: color.fireBrick,
        onClick: () => setConfirmCancel(true),
      });
    }

    return items;
  };

  /**
   * Get total number of delivered impression and spots
   */
  const getOrderSegmentData = () => {
    const segments = get(order, 'segments', []);

    return {
      deliveredImpressions: segments.reduce((sum, { deliveredImpressions }) => sum + deliveredImpressions, 0),
      deliveredSpots: segments.reduce((sum, { deliveredSpots }) => sum + deliveredSpots, 0),
      segmentType: find(segments, { segmentType: 'added-value' }) !== undefined
        ? 'added-value'
        : 'standard',
    };
  };

  /**
   * When the Order ID Changes
   */
  useEffect(() => {
    getOrder();
  }, [orderId]);

  const updateTitle = (status) => {
    return `${capitalize(status)} Orders`;
  };

  // merge some segment data into the order (delivered impressions, etc)
  const orderData = {
    ...order.data,
    ...getOrderSegmentData(),
  };

  const orderSegments = order.segments;

  const formatStr = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^./, (match) => match.toUpperCase());
  };

  const buildBreadcrumbs = (name, status, tags, data) => {
    const {
      opportunityName,
      opportunityId,
      dealId,
      extReferenceId,
    } = data;

    // build extra links for the order
    const links = [];

    if (opportunityId) {
      links.push({
        label: opportunityName,
        value: opportunityId,
        url: `../../orders/all?opportunityId=${opportunityId}`,
      });
    }

    if (dealId) {
      links.push({
        label: 'Deal ID',
        value: dealId,
        url: `../../orders/all?dealId=${dealId}`,
      });
    }

    if (extReferenceId) {
      links.push({
        label: 'Order Reference ID',
        value: extReferenceId,
        url: `../../orders/all?extReferenceId=${extReferenceId}`,
      });
    }

    breadcrumbs = [
      ...breadcrumbs,
      {
        title: updateTitle(status),
        linkUrl: `../../orders/dashboard/${status}`,
      },
      {
        title: name,
        links,
        tags: (
          Object.keys(tags).map((key) => {
            if (!tags[key]) return null;

            return (
              <VibeTooltip
                key={`tag-${key}`}
                title={formatStr(key)}
              >
                <div>
                  <Tag
                    key={key}
                    tag={{
                      name: formatStr(tags[key]),
                    }}
                    style={{
                      background: color.lightGray,
                      color: color.manatee,
                      fontSize: 12,
                    }}
                  />
                </div>
              </VibeTooltip>
            );
          })
        ),
        linkUrl: './',
      },
    ];
  };

  buildBreadcrumbs(
    orderData.name,
    orderData.status,
    {
      advertiserName: orderData.advertiserName,
      adProvider: orderData.adProvider,
      deliverySystem: orderData.deliverySystem,
    },
    {
      opportunityId: orderData.opportunityId,
      opportunityName: orderData.opportunityName,
      dealId: orderData.dealId,
      extReferenceId: orderData.extReferenceId,
    },
  );

  // do not render until an order has been fetched
  if (!orderData._id) {
    return null;
  }

  return (
    <div
      className={classNames('OrderDetails', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...style,
      }}
    >
      <SubNavigation
        breadcrumbs={breadcrumbs}
        statusReason={orderData.statusReason}
        statusTag={{
          label: orderData.status,
          style: find(OrderHelper.statusList, { value: orderData.status }).tagStyle,
        }}
        buttons={getMenuItems()}
        isDropMenu
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: 16,
          margin: '0 2rem',
          marginBottom: orderData.reportingAuthority !== 'vibenomics'
            // add spacing when the info cards aren't shown between the order dates and the table
            ? '2rem'
            // spacing is already added with the info cards
            : 0,
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            color: color.manatee,
          }}
        >
          {moment(orderData.startDate).format('ll')} - {moment(orderData.endDate).format('ll')}
        </div>

        {orderData.reportingAuthority === 'vibenomics' && (
          <Link
            to={isGraph
              ? '.'
              : './graph'}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: color.violetVibe,
            }}
          >
            {isGraph
              ? 'Close Graph'
              : 'View Graph'}

            <VibeIcon
              style={{
                marginLeft: 4,
              }}
              icon={isGraph
                ? viClose
                : viTrendingUp}
              color={color.violetVibe}
              hoverColor={color.obsidian}
              size={24}
            />
          </Link>
        )}
      </div>

      {orderData.reportingAuthority === 'vibenomics' && (
        <Outlet
          context={[orderData]}
        />
      )}

      {user.can('order_segment.view') && (
        <TableSegments
          columns={[
            'Name',
            'Media Format',
            'Segment Type',
            'Creative',
            'Start Date',
            'End Date',
            'Start Time',
            'End Time',
            'Budget',
            'CPM',
            'Target Impressions',
            'Total Impressions',
            'Spots',
            'Frequency',
            'Spot Type',
            'Spot Selection Strategy',
            'Allow Duplicate Ads Back to Back',
            'Days of Week',
            'Created By',
            'Created Date',
            '...',
          ]}
          collection={orderSegments}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Segments',
            urlPaging: true,
            urlFilters: true,
          }}
          rowLink="../segment/{_id}"
          // include segment summary data to show warnings and rollup information
          segmentSummaries={orderSegments}
          adProvider={orderData.adProvider}
        />
      )}

      {/* Copy an Order Modal */}
      <VibeModal
        type="confirm"
        show={confirmCopy}
        confirmProps={{
          text: 'Copy Order',
          color: color.violetVibe,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        title="Copy Order"
        text={`Are you sure you want to copy ${orderData.name}?`}
        onConfirm={onConfirmCopy}
        onClose={onCancelCopy}
      />

      {/* Pause an Order Modal */}
      <VibeModal
        type="confirm"
        show={confirmPause}
        confirmProps={{
          text: 'Pause Order',
          color: color.violetVibe,
          disabled: isEmpty(statusReason),
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        title="Pause Order"
        text={(
          <div>
            <div
              style={{
                marginBottom: 16,
              }}
            >
              Are you sure you want to pause {orderData.name}?
            </div>

            <Field
              type="textarea"
              style={{
                height: 150,
              }}
              label="Reason"
              placeholder="Reason for Pausing..."
              value={statusReason}
              onChange={onChangeStatusReason}
              autoFocus
              required
            />
          </div>
        )}
        onConfirm={onConfirmPause}
        onClose={onCancelPause}
      />

      {/* Resume an Order Modal */}
      <VibeModal
        type="confirm"
        show={confirmResume}
        confirmProps={{
          text: 'Resume Order',
          color: color.violetVibe,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        title="Resume Order"
        text={`Are you sure you want to resume ${orderData.name}?`}
        onConfirm={onConfirmResume}
        onClose={onCancelResume}
      />

      {/* Cancel an Order Modal */}
      <VibeModal
        type="confirm"
        show={confirmCancel}
        confirmProps={{
          text: 'Cancel Order',
          color: color.violetVibe,
          disabled: isEmpty(statusReason),
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        title="Cancel Order"
        text={(
          <div>
            <div
              style={{
                marginBottom: 16,
              }}
            >
              Are you sure you want to cancel {orderData.name}?
            </div>

            <Field
              type="textarea"
              style={{
                height: 150,
              }}
              label="Reason"
              placeholder="Reason for Canceling..."
              value={statusReason}
              onChange={onChangeStatusReason}
              autoFocus
              required
            />
          </div>
        )}
        onConfirm={onConfirmCancel}
        onClose={onCancelCancel}
      />
    </div>
  );
}

OrderDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

OrderDetails.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(OrderDetails);
