import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeIcon,
  viCompany,
  color,
} from 'vibeguide';
import TargetLocation from './TargetLocation';
import './TargetCompany.scss';

function TargetCompany({
  className,
  companyId,
  name,
  locations,
  onRemoveLocation,
  onUpdate,
}) {
  return (
    <div className={classNames('TargetCompany', className)}>
      <div className="title">
        <div className="text">
          {name || 'Unknown'}
        </div>

        <VibeIcon
          icon={viCompany}
          color={color.obsidian}
          size={24}
        />
      </div>

      {locations.map((location) => (
        <TargetLocation
          key={`target-${location._id}`}
          companyId={companyId}
          locationId={location._id}
          name={location.name}
          earliestEffectiveDate={location.earliestEffectiveDate}
          // affectedAdPrograms={location.affectedAdPrograms}
          warnings={location.warnings || []}
          onRemove={onRemoveLocation}
          onUpdate={onUpdate}
        />
      ))}
    </div>
  );
}

TargetCompany.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  name: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    affectedAdPrograms: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })),
    earliestEffectiveDate: PropTypes.string,
    name: PropTypes.string,
    warnings: PropTypes.arrayOf(PropTypes.string),
  })),
  onRemoveLocation: PropTypes.func,
  onUpdate: PropTypes.func,
};

TargetCompany.defaultProps = {
  className: '',
  name: '',
  locations: [],
  onRemoveLocation: () => {},
  onUpdate: () => {},
};

export default TargetCompany;
