/**
 * Common MIME Types
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */

/**
 * Accepted mimetypes for music files
 */
export const music = {
  'audio/aac': ['.aac'],
  'audio/midi': ['.mid', '.midi'],
  'audio/x-m4a': ['.m4a'],
  'audio/x-midi': ['.mid', '.midi'],
  'audio/mpeg': ['.mp3'],
  'audio/ogg': ['.oga'],
  'audio/opus': ['.opus'],
  'audio/wav': ['.wav'],
  'audio/webm': ['.weba'],
  'audio/3gpp2': ['.3g2'],
  'video/3gpp2': ['.3g2'],
  'video/mp4': ['.mp4'],
};

/**
 * Accepted mimetypes for images
 */
export const images = {
  'image/bmp': ['.bmp'],
  'image/gif': ['.gif'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
  'image/tiff': ['.tif', '.tiff'],
  'image/webp': ['.webp'],
};

/**
 * Accepted mimetypes for attachments
 */
export const attachments = {
  'audio/mpeg': ['.mp3'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'text/plain': ['.txt'],
  'application/pdf': ['.pdf'],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
};

export default {
  music,
  images,
  attachments,
};
