import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  NavigationHelper,
  GlobalActions,
  ToastActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeButtonNew,
  VibeTooltip,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  color,
} from 'vibeguide';
import Information from './Information';
import './ScreenResolutionDetails.scss';

function ScreenResolutionDetails({
  className,
  style,
  resolution,
  user,
  setPanel,
  queueToast,
  onUpdate,
}) {
  const [confirmArchive, setConfirmArchive] = useState(false);
  const history = useNavigate();

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  /**
   * Archive the item
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * When the archive modal is closed
   */
  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * Archive the item
   */
  const onClickUnarchive = async () => {
    await API.ScreenResolution.reactivate({
      _id: resolution._id,
    });

    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveScreenResolution'));
  };

  /**
   * When the archive modal is confirmed
   */
  const onConfirmArchive = async () => {
    await API.ScreenResolution.deactivate(resolution._id);

    onCloseArchive();
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveScreenResolution'));
  };

  const onSave = async () => {
    const data = {
      name: resolution.name,
      size: resolution.size,
    };

    if (resolution._id) {
      // editing a resolution
      data._id = resolution._id;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = resolution._id
        ? await API.ScreenResolution.update(data)
        : await API.ScreenResolution.create(data);

      const resolutionId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = data._id
        ? responseType === 'SCREENRESOLUTION.UPDATED'
        : responseType === 'SCREENRESOLUTION.CREATED';

      if (success) {
        // Successfully saved the resolution
        const saveMessage = data._id
          ? 'Screen Resolution Updated!'
          : 'Screen Resolution Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSaveScreenResolution'));

        // creating a new object, redirect to the proper URL
        if (!resolution._id) {
          const redirectUrl = NavigationHelper.updateParams({
            resolutionId,
            type: null,
          });

          history(redirectUrl);
        }
      } else {
        console.error('Error saving screen resolution to API', response);

        queueToast({
          type: 'error',
          title: 'Error Saving Screen Resolution',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving screen resolution', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Screen Resolution',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  const disableSave = !resolution.name
    // ensure the size array is valid
    || get(resolution, 'size.length', 0) !== 2
    // make sure width/height are > 0
    || get(resolution, 'size[0]', 0) <= 0
    || get(resolution, 'size[1]', 0) <= 0;

  const disableInput = (resolution._id && !user.can('screen_resolution.modify'))
    || (!resolution._id && !user.can('screen_resolution.create'));

  const showArchive = user.can('screen_resolution.delete');
  const isArchived = !resolution.active;

  return (
    <SidePanelContainer
      className={classNames('ScreenResolutionDetails', className)}
      style={style}
    >
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="title">
          <VibeTooltip title={resolution.name || 'New Screen Resolution'}>
            <span>
              {resolution._id
                ? resolution.name
                : 'New Screen Resolution'}
            </span>
          </VibeTooltip>
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <Information
          name={resolution.name}
          size={resolution.size}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButtonNew
          text="Save Screen Resolution"
          style={{
            marginRight: 12,
          }}
          color={color.violetVibe}
          // loadingEvent="onSaveScreenResolution"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {resolution._id && (
          <div className="toolbar-buttons">
            {showArchive && !isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.fireBrick,
                  }}
                  tooltip="Archive"
                  color={color.fireBrick}
                  size={20}
                  onClick={onClickArchive}
                />
              </div>
            ) : null}

            {showArchive && isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.aquaForest,
                  }}
                  tooltip="Unarchive"
                  color={color.aquaForest}
                  size={20}
                  onClick={onClickUnarchive}
                />
              </div>
            ) : null}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text="Would you like to archive this screen resolution?"
        confirmProps={{
          text: 'Archive',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

ScreenResolutionDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  resolution: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onUpdate: PropTypes.func,
};

ScreenResolutionDetails.defaultProps = {
  className: '',
  style: {},
  resolution: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenResolutionDetails);
