import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from '../Tags/Tag';
import color from '../../sass/color.scss';

/**
 * Get the style for the flight status tag
 */
function getStatusStyle(status) {
  switch (status) {
    case 'confirmed':
      return {
        backgroundColor: color.aquaForest16,
        color: color.aquaForest,
      };

    case 'unconfirmed':
      return {
        backgroundColor: color.fireBrick16,
        color: color.fireBrick,
      };

    case 'computing':
    default:
      return {
        backgroundColor: color.flamingo16,
        color: color.flamingo,
      };
  }
}

function FlightStatus({
  className,
  style,
  status,
}) {
  const statusStyle = getStatusStyle(status);

  return (
    <Tag
      className={classNames('FlightStatus', className)}
      tag={{
        name: status,
      }}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        fontSize: 10,
        textTransform: 'uppercase',
        backgroundColor: statusStyle.backgroundColor,
        color: statusStyle.color,
        ...style,
      }}
    />
  );
}

FlightStatus.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  status: PropTypes.string.isRequired,
};

FlightStatus.defaultProps = {
  className: '',
  style: {},
};

export default FlightStatus;
