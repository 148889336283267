import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SidePanelContainer.scss';

function SidePanelContainer({
  className,
  style,
  children,
}) {
  return (
    <div
      className={classNames('SidePanelContainer', className)}
      style={style}
    >
      {children}
    </div>
  );
}

SidePanelContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SidePanelContainer.defaultProps = {
  className: null,
  style: {},
};

export default SidePanelContainer;
