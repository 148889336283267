import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  TableAdReporting,
  API,
} from 'vibeguide';
import moment from 'moment';

function AdReporting({
  user,
  socket,
  device,
  currentLocation: {
    _id,
  },
}) {
  const [socketEventsRegistered, setSocketEventsRegistered] = useState(false);
  const prevDeviceRef = useRef();

  const onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `ad-reports-${timestamp}.csv`);
  };

  const onApiEvent = async (e) => {
    switch (e.type) {
      // When a media item is being played
      case 'LOCATION.CAPTURED_MEDIA_PLAYED': {
        // tell listening components to update the table
        document.dispatchEvent(new Event('onUpdateTableTelemetryAdReporting'));
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  useEffect(() => {
    if (!socketEventsRegistered && socket.connected) {
      socket.on('VAPI_EVENT', onApiEvent);
      setSocketEventsRegistered(true);
    }

    return () => {
      // Cleanup: Unsubscribe from socket event when component unmounts
      socket.off('VAPI_EVENT', onApiEvent);
    };
  }, [socket]);

  useEffect(() => {
    if (prevDeviceRef.current && prevDeviceRef.current._id && device._id !== prevDeviceRef.current._id) {
      document.dispatchEvent(new Event('onUpdateTableTelemetryAdReporting'));
    }

    prevDeviceRef.current = device;
  }, [device]);

  return (
    <TableAdReporting
      columns={[
        'Created Date',
        'Ad Status',
        'Reason',
        'Spot Type',
        'Expected Delivery Time',
        'Play Time',
        'Report Time',
        'Media Name',
        'Size',
        'Duration',
        'Advertiser',
        'Ad Provider',
        '...',
      ]}
      fetch={API.Location.Telemetry.Device.getImpressionsTracking}
      fetchProps={{
        filters: {
          _id,
          deviceId: device._id,
        },
      }}
      defaultSortBy={{
        label: 'Created Date',
        attr: 'createdDate',
        direction: 'desc',
      }}
      paginator
      paginatorProps={{
        label: 'Requests',
        urlPaging: true,
        urlFilters: true,
      }}
      csv={user.sysAdmin}
      csvProps={{
        onExport,
      }}
    />
  );
}

AdReporting.propTypes = {
  device: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(AdReporting);
