import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/campaigns`;

export default {
  calculateTotalAdInventory: (data, options = {}) => {
    options = addVersion('5.0.0', options);
    return Http.post(`${baseUrl}/inventory/total`, data, options);
  },
};
