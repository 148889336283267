import { handleActions } from 'redux-actions';
import {
  find,
  cloneDeep,
} from 'lodash';
import {
  handleLocationsData,
  getLocationsData,
} from '../../helpers/ExpandedLocations';

/**
 * Recursively add percentage to playlists
 *
 * @param {array} playlists
 * @param {integer} amountRemaining
 * @param {function} cb - Callback once recursion is complete
 */
function addPercentage(playlists, amountRemaining, cb) {
  // Get playlists with at least 1 percentage point to add
  playlists = playlists.filter(playlist => playlist.mixPercent < 100);

  playlists.forEach((playlist) => {
    if (amountRemaining > 0) {
      // Add 1 percent to the playlist
      playlist.mixPercent += 1;
      amountRemaining -= 1;
    }
  });

  if (amountRemaining > 0 && playlists.length > 0) {
    // Add more percentage points
    addPercentage(playlists, amountRemaining, cb);
  } else if (amountRemaining > 0 && playlists.length <= 0) {
    // Still have percentage points to add
    console.error('STILL HAVE', amountRemaining, 'to add but no playlists');
  } else if (amountRemaining <= 0) {
    cb();
  }
}

/**
 * Recursively remove percentage from playlists
 *
 * @param {array} playlists
 * @param {integer} amountRemaining
 * @param {function} cb - Callback once recursion is complete
 */
function removePercentage(playlists, amountRemaining, cb) {
  // Get playlists with at least 1 percentage point to remove
  playlists = playlists.filter(playlist => playlist.mixPercent > 1);

  playlists.forEach((playlist) => {
    if (amountRemaining > 0) {
      // Remove 1 percent from the playlist
      playlist.mixPercent -= 1;
      amountRemaining -= 1;
    }
  });

  if (amountRemaining > 0 && playlists.length > 0) {
    // Remove more percentage points
    removePercentage(playlists, amountRemaining, cb);
  } else if (amountRemaining > 0 && playlists.length <= 0) {
    // Still have percentage points to remove
    console.error('STILL HAVE', amountRemaining, 'to remove but no playlists');
  } else if (amountRemaining <= 0) {
    cb();
  }
}

/**
 * Get the total percentage of all playlists
 *
 * @param {array} playlists - Playlists to calculate total mix percentage
 */
function getTotalPercentage(playlists) {
  let total = 0;

  playlists.forEach((playlist) => {
    total += playlist.mixPercent;
  });

  return total;
}

/**
 * Get the mix playlist data for all playlists
 * Including the min/max mixPercent, etc.
 *
 * @param {array} playlists - Playlists to calculate total mix percentage
 */
function getPlaylistMaxPercent(playlists) {
  playlists = cloneDeep(playlists);

  const totalPercentage = getTotalPercentage(playlists);

  if (totalPercentage !== 100) {
    console.warn('Mix Percentage is not 100%');
    return playlists;
  }

  playlists.forEach((playlist) => {
    // Get playlists to add their mixPercent to the playlist max
    const playlistsToAddMax = playlists.filter(p => !p.locked && p._id !== playlist._id);

    // Add it's own mix percentage to its max
    playlist.mixPercentMax = playlist.mixPercent;

    // Add other playlist mix percentage to its max
    playlistsToAddMax.forEach((p) => {
      playlist.mixPercentMax += p.mixPercent - 1;
    });
  });

  return playlists;
}

/**
 * Update a playlist mix percentage as well as updating all other playlists
 * to ensure the total mix percentage equals 100.
 *
 * @param {Object} data
 * @param {array} data.playlists - Array of playlists
 * @param {string} data.playlistId - The playlist ID to update the mix percentage
 * @param {integer} data.mixPercent - The new mix percentage used for the playlist
 * @return {Object} playlists
 */
function updateMixPercent({
  playlists,
  playlistId,
  mixPercent,
}) {
  playlists = cloneDeep(playlists);

  // First update the mix percent of the playlist
  const updatePlaylist = find(playlists, { _id: playlistId });

  if (updatePlaylist) {
    updatePlaylist.mixPercent = mixPercent;
  }

  const totalPercentage = getTotalPercentage(playlists);
  console.warn('First Total', totalPercentage);

  // Get total percentage
  if (totalPercentage === 100) {
    // Mix percentage is 100%
    console.warn('Congrats! Mix Percentage is 100!');
    // return;
    return playlists;
  }

  // Percentage is less than 100. Scale up other playlists
  if (totalPercentage < 100) {
    // Amount we need to scale up
    const scaleUpAmount = 100 - totalPercentage;
    console.warn('scaleUpAmount', scaleUpAmount);

    // Get playlists that can be scaled up
    // Must not be locked
    // Must not be the playlist we're editing
    const scalePlaylists = playlists.filter(playlist => !playlist.locked && playlist._id !== playlistId);

    if (scalePlaylists.length <= 0) {
      // No playlists available to scale.
      console.warn('No playlists available to scale up');
      // return;
      return playlists;
    }

    addPercentage(scalePlaylists, scaleUpAmount, () => {
      // Update the max percentage
      playlists = getPlaylistMaxPercent(playlists);
    });

    console.warn('Second Total', getTotalPercentage(playlists));
  }

  // Percentage is greater than 100. Scale down other playlists
  if (totalPercentage > 100) {
    // Amount we need to scale down
    const scaleDownAmount = totalPercentage - 100;
    console.warn('scaleDownAmount', scaleDownAmount);

    // Get playlists that can be scaled down
    // Must not be locked
    // Must not be the playlist we're editing
    const scalePlaylists = playlists.filter(playlist => !playlist.locked && playlist._id !== playlistId);

    if (scalePlaylists.length <= 0) {
      // No playlists available to scale.
      console.warn('No playlists available to scale down');
    }

    removePercentage(scalePlaylists, scaleDownAmount, () => {
      // Update the max percentage
      playlists = getPlaylistMaxPercent(playlists);
    });

    console.warn('Second Total', getTotalPercentage(playlists));
  }

  return playlists;
}

const initialState = {
  _id: null,
  locationsData: {
    companies: [],
    locations: [],
  },
  locations: [],
  playlists: [],
  allLocations: false,
  image: null,
  title: '',
  description: '',
  category: '',
  companyId: '',
  companyName: '',
  tags: [],
};

export default handleActions(
  {
    SET_MIX_DATA: (state, action) => {
      const data = action.payload;

      return {
        ...state,
        ...data,
      };
    },

    ADD_MIX_LOCATION: (state, action) => {
      const location = action.payload;
      const hasLocation = find(state.locations, location) !== undefined;

      if (hasLocation) {
        // Do not add the location twice
        return state;
      }

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        locations = locations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...locations, location],
      };
    },

    ADD_MIX_LOCATIONS: (state, action) => {
      const {
        locations,
        expandedLocations,
      } = action.payload;

      return {
        ...state,
        locationsData: getLocationsData(expandedLocations),
        locations: state.locations.concat(locations),
      };
    },

    REMOVE_MIX_LOCATION: (state, action) => {
      const locationData = action.payload;
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    },

    REMOVE_MIX_ALL_LOCATIONS: (state) => {
      return {
        ...state,
        locationsData: initialState.locationsData,
        locations: initialState.locations,
      };
    },

    ADD_MIX_PLAYLIST: (state, action) => {
      const playlist = action.payload;

      // Default to unlocked
      playlist.locked = false;

      // Get playlists that are unlocked
      const unlocked = state.playlists.filter(playlist => !playlist.locked);

      if (unlocked.length > 0) {
        // Get mean of all the unlocked playlists
        let totalPercentage = 0;

        unlocked.forEach((playlist) => {
          totalPercentage += playlist.mixPercent;
        });

        const avg = Math.floor(totalPercentage / unlocked.length);
        playlist.mixPercent = avg;
      } else {
        // No unlocked playlists
        playlist.mixPercent = 1;
      }

      // Add the playlist
      const newPlaylists = [...state.playlists, playlist];
      // Calculate new mix percentage
      const playlists = updateMixPercent({
        playlists: newPlaylists,
      });

      return {
        ...state,
        playlists,
      };
    },

    ADD_MIX_PLAYLISTS: (state, action) => {
      const playlists = action.payload;
      const newPlaylists = state.playlists.concat(playlists);
      // Get mix playlists data (max percentage, etc)
      const mixPlaylists = getPlaylistMaxPercent(newPlaylists);

      return {
        ...state,
        playlists: mixPlaylists,
      };
    },

    REMOVE_MIX_PLAYLIST: (state, action) => {
      const playlistId = action.payload;
      // Remove the playlist
      const newPlaylists = state.playlists.filter(playlist => playlist._id !== playlistId);
      // Re-calculate the max percetnage for the new playlists
      const playlists = updateMixPercent({
        playlists: newPlaylists,
      });

      return {
        ...state,
        playlists,
      };
    },

    UPDATE_MIX_PLAYLIST: (state, action) => {
      const data = action.payload;

      console.log('data', data);

      let updatedPlaylists = data.mixPercent
        ? updateMixPercent({
          playlists: state.playlists,
          playlistId: data.playlistId,
          mixPercent: data.mixPercent,
        })
        : state.playlists;

      if (data.locked !== undefined) {
        const playlist = find(updatedPlaylists, { _id: data.playlistId });
        playlist.locked = data.locked;

        updatedPlaylists = getPlaylistMaxPercent(updatedPlaylists);
      }

      return {
        ...state,
        playlists: state.playlists.map((playlist) => {
          const updatedPlaylist = find(updatedPlaylists, { _id: playlist._id });

          if (data.playlistId === playlist._id) {
            delete data.mixPercent;

            return {
              ...playlist,
              ...updatedPlaylist,
              ...data,
            };
          }

          return {
            ...playlist,
            // Add attributes from the percentage data calculated
            ...updatedPlaylist,
          };

          // return playlist;
        }),
      };
    },

    SET_MIX_ALL_LOCATIONS: (state, action) => {
      const allLocations = action.payload;

      return {
        ...state,
        allLocations,
      };
    },

    SET_MIX_IMAGE: (state, action) => {
      const image = action.payload;

      return {
        ...state,
        image,
      };
    },

    SET_MIX_TITLE: (state, action) => {
      const title = action.payload;

      return {
        ...state,
        title,
      };
    },

    SET_MIX_DESCRIPTION: (state, action) => {
      const description = action.payload;

      return {
        ...state,
        description,
      };
    },

    SET_MIX_CATEGORY: (state, action) => {
      const category = action.payload;

      return {
        ...state,
        category,
      };
    },

    SET_MIX_COMPANY: (state, action) => {
      const data = action.payload;

      const companyId = data.companyId !== undefined
        ? data.companyId
        : state.companyId;

      const companyName = data.companyName !== undefined
        ? data.companyName
        : state.companyName;

      return {
        ...state,
        companyId,
        companyName,
        // Only keep locations with the same company ID when the company changes
        // locations: state.locations.filter(location => location.companyId === companyId),
        // Only keep admin tags since client tags are linked to the company
        tags: state.companyId && state.companyId !== companyId
          ? state.tags.filter(tag => tag.type === 'admin')
          : state.tags,
      };
    },

    SET_MIX_TAGS: (state, action) => {
      const tags = action.payload;

      tags.forEach((tag) => {
        tag.initialTag = true;
      });

      return {
        ...state,
        tags: [...state.tags, ...tags],
      };
    },

    ADD_MIX_TAG: (state, action) => {
      const tag = action.payload;
      const existingTag = find(state.tags, { _id: tag._id });

      // Tag is already added (and not queued for removal)
      if (existingTag && existingTag.status !== 'remove') {
        return state;
      }

      // Tag is already added (and queued for removal)
      // Update the tag to include its new status
      if (existingTag && existingTag.status === 'remove') {
        return {
          ...state,
          tags: state.tags.map((t) => {
            if (t._id === existingTag._id) {
              return {
                ...t,
                ...tag,
                status: existingTag.initialTag
                  ? 'unedited'
                  : tag.status,
              };
            }

            return t;
          }),
        };
      }

      // Add the new tag
      return {
        ...state,
        tags: [...state.tags, tag],
      };
    },

    REMOVE_MIX_TAG: (state, action) => {
      const tag = action.payload;
      // Find the tag if it already exists
      const existingTag = find(state.tags, { _id: tag._id });

      // Tag exists, add a remove status
      if (existingTag && existingTag.initialTag) {
        return {
          ...state,
          tags: state.tags.map((t) => {
            if (t._id === existingTag._id) {
              return {
                ...t,
                ...tag,
              };
            }

            return t;
          }),
        };
      }

      // Remove the tag
      return {
        ...state,
        tags: state.tags.filter(t => t._id !== tag._id),
      };
    },

    RESET_NEW_MIX: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
