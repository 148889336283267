import {
  // each,
  // isUndefined,
  size,
} from 'lodash';

class Field {
  constructor(data = {}) {
    this.id = data.id || null;
    this.type = data.type || null;
    this.label = data.label || null;
    this.placeholder = data.placeholder || '';
    this.value = data.value || '';
    this.tabIndex = data.tabIndex || -1;
    this.autoFocus = data.autoFocus || false;
    this.disabled = data.disabled || false;
    this.marginTop = data.marginTop || 0;
    this.marginBottom = data.marginBottom || 0;
    this.minLength = data.minLength || 0;
    this.required = data.required || false;
    this.hasError = data.hasError || false;
    this.errorMinLength = data.errorMinLength || false;
    this.errorRequired = data.errorRequired || false;
    this.errorEmail = data.errorEmail || false;
    this.errorPhone = data.errorPhone || false;
    this.errorExceedsMin = data.errorExceedsMin || false;
    this.errorExceedsMax = data.errorExceedsMax || false;

    // each(data, (val, key) => {
    //   // Convert the key to camelcase
    //   // const camelKey = camelCase(key);

    //   if (isUndefined(this[key])) {
    //     // Add the property to the model
    //     this[key] = val;

    //     console.warn(`${key} is not added to Field Model`);
    //   }
    // });
  }

  validate = (options = {}) => {
    const {
      type,
      value,
      minLength,
      required,
      // updateField,
    } = this;

    const {
      ignoreEmpty,
    } = options;

    const valueLength = size(value);
    let isErrorMinLength = false;
    let isErrorRequired = false;
    let isErrorEmail = false;
    let isErrorPhone = false;

    if (!ignoreEmpty && valueLength < minLength) {
      // Does not meet minimum length requirement (field can not also be empty)
      isErrorMinLength = true;
    }

    if (ignoreEmpty && valueLength > 0 && valueLength < minLength) {
      // Does not meet minimum length requirement (field can be empty)
      isErrorMinLength = true;
    }

    if (!ignoreEmpty && valueLength <= 0 && required) {
      // Does not meet required requirement (field can not be empty)
      isErrorRequired = true;
    }

    if (!ignoreEmpty && valueLength > 0 && type === 'email') {
      // Validate email address
      isErrorEmail = this.checkEmail(value) === false;
    }

    if (valueLength > 0 && type === 'tel') {
      // Validate phone number
      isErrorPhone = this.checkPhone(value) === false;
    }

    const hasError = isErrorRequired
      || isErrorMinLength
      || isErrorEmail
      || isErrorPhone
      || this.errorExceedsMin
      || this.errorExceedsMax;

    this.errorMinLength = isErrorMinLength;
    this.errorRequired = isErrorRequired;
    this.errorEmail = isErrorEmail;
    this.errorPhone = isErrorPhone;
    this.hasError = hasError;

    return {
      errorMinLength: isErrorMinLength,
      errorRequired: isErrorRequired,
      errorEmail: isErrorEmail,
      errorPhone: isErrorPhone,
      errorExceedsMin: this.errorExceedsMin,
      errorExceedsMax: this.errorExceedsMax,
      hasError,
    };
  };

  checkEmail(email) {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkPhone(phone) {
    const match = phone.match(/[0-9]+/g);
    const phoneNumber = size(match) > 0
      ? match.join('')
      : '';

    const phoneLength = size(phoneNumber);

    // Verify phone is 10 digits long
    return phoneLength === 10;
  }

  formatPhone(phone) {
    const match = phone.match(/[0-9]+/g);
    const phoneNumber = size(match) > 0
      ? match.join('')
      : '';

    const phoneLength = size(phoneNumber);
    let formatPhone = phoneNumber;

    if (phoneLength === 10) {
      // Format 10 digit phone
      formatPhone = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    } else if (phoneLength === 7) {
      // Format 7 digit phone
      formatPhone = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3)}`;
    }

    return formatPhone;
  }
}

export default Field;
