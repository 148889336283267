import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  MediaFormatHelper,
  Field2 as Field,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function ProfileSettings({
  className,
  companyId,
  contentRating,
  mediaFormat,
  contentNetworkOwner,
  contentDeliverySystem,
  adNetworkOwner,
  adDeliverySystem,
  adProgramEnabled,
  adNetworkEnabled,
  isAdmin,
  canEdit,
  canViewCompany,
  canEnableAdProgram,
  canModifyLocationAdProgram,
  onUpdate,
}) {
  const [companyAdProgramEnabled, setCompanyAdProgramEnabled] = useState(false);

  const [companyAdNetworkEnabled, setCompanyAdNetworkEnabled] = useState(false);

  const canAdminEdit = isAdmin && canEdit;

  /**
   * When a field value is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onDropdownChange = (e) => {
    const {
      name,
      value,
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the ad program is toggled
   */
  const onToggleAdProgram = () => {
    onUpdate({
      adProgramEnabled: !adProgramEnabled,
    });
  };

  const onToggleAdNetwork = () => {
    onUpdate({
      adNetworkEnabled: !adNetworkEnabled,
    });
  };

  const titleCase = (string) => {
    return string?.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  /**
   * Fetch the company information to determine if the ad program is enabled at the company level
   */
  useEffect(async () => {
    if (!canViewCompany || !canEnableAdProgram) {
      // do not fetch if the user cannot view the company or enable the ad program
      return;
    }

    const company = await API.Company.getById(companyId);

    if (company && company.adProgramEnabled) {
      setCompanyAdProgramEnabled(true);
    }

    if (company && company.adNetworkEnabled) {
      setCompanyAdNetworkEnabled(true);
    }
  }, []);

  return (
    <div className={classNames('ProfileSettings', className)}>
      <Grid spacing={2} container>
        {canEnableAdProgram && (
        <Grid xs={12} lg={6} item>
          <Field
            type="toggle"
            label="Ad Network"
            toggleProps={{
              label: 'Enable Ad Network',
              helpText: !companyAdNetworkEnabled
                ? 'Company ad network is disabled'
                : null,
            }}
            value={adNetworkEnabled
              ? 'on'
              : 'off'}
            tabIndex={8}
            disabled={!canAdminEdit || !companyAdNetworkEnabled || adProgramEnabled || !canModifyLocationAdProgram}
            onChange={onToggleAdNetwork}
          />
        </Grid>
        )}

        {canEnableAdProgram && (
          <Grid xs={12} lg={6} item>
            <Field
              type="toggle"
              label="Ad Programs"
              toggleProps={{
                label: 'Enable Ad Programs',
                helpText: !companyAdProgramEnabled
                  ? 'Company ad program is disabled'
                  : null,
              }}
              value={adProgramEnabled
                ? 'on'
                : 'off'}
              tabIndex={9}
              disabled={!canAdminEdit || !companyAdProgramEnabled || !adNetworkEnabled || !canModifyLocationAdProgram}
              onChange={onToggleAdProgram}
            />
          </Grid>
        )}

        <Grid xs={12} lg={6} item>
          <Field
            type="dropdown"
            label="Content Network Owner"
            name="contentNetworkOwner"
            value={titleCase(contentNetworkOwner)}
            tabIndex={10}
            dropdownProps={{
              type: 'lookup',
              attr: 'value',
              fetch: API.LookupList.contentNetworkOwners.search,
            }}
            disabled={!canAdminEdit}
            onChange={onDropdownChange}
            required
          />
        </Grid>

        <Grid xs={12} lg={6} item>
          <Field
            type="dropdown"
            label="Content Delivery System"
            name="contentDeliverySystem"
            value={titleCase(contentDeliverySystem)}
            tabIndex={11}
            dropdownProps={{
              type: 'lookup',
              attr: 'value',
              fetch: API.LookupList.contentDeliverySystems.search,
            }}
            disabled={!canAdminEdit}
            onChange={onDropdownChange}
            required
          />
        </Grid>

        <>
          <Grid xs={12} lg={6} item>
            <Field
              type="dropdown"
              label="Ad Delivery System"
              name="adDeliverySystem"
              value={titleCase(adDeliverySystem)}
              tabIndex={12}
              dropdownProps={{
                type: 'lookup',
                attr: 'value',
                fetch: API.LookupList.adDeliverySystems.search,
              }}
              disabled={!canAdminEdit}
              onChange={onDropdownChange}
              required={adNetworkEnabled}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Field
              type="dropdown"
              label="Ad Network Owner"
              name="adNetworkOwner"
              value={titleCase(adNetworkOwner)}
              tabIndex={13}
              dropdownProps={{
                type: 'lookup',
                attr: 'value',
                fetch: API.LookupList.adNetworkOwners.search,
              }}
              disabled={!canAdminEdit}
              onChange={onDropdownChange}
              required={adNetworkEnabled}
            />
          </Grid>
        </>

        <Grid xs={12} lg={6} item>
          <Field
            type="select"
            label="Maximum Content Rating"
            name="contentRating"
            value={contentRating}
            tabIndex={14}
            options={[
              {
                label: 'Radio Edit',
                value: 'radio-edit',
              },
              {
                label: 'G',
                value: 'g',
              },
              {
                label: 'Explicit',
                value: 'explicit',
              },
            ]}
            disabled={!canEdit}
            onChange={onChange}
          />
        </Grid>

        <Grid xs={12} lg={6} item>
          {/* Media Format can only be set on location create/import */}
          <Field
            type="select"
            label="Media Format"
            name="mediaFormat"
            value={mediaFormat}
            options={[
              {
                label: '',
                value: '',
              },
              ...MediaFormatHelper.list,
            ]}
            disabled
            tabIndex={15}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}

ProfileSettings.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string,
  contentRating: PropTypes.string,
  mediaFormat: PropTypes.string,
  contentNetworkOwner: PropTypes.string,
  contentDeliverySystem: PropTypes.string,
  adNetworkOwner: PropTypes.string,
  adDeliverySystem: PropTypes.string,
  adProgramEnabled: PropTypes.bool,
  adNetworkEnabled: PropTypes.bool,
  isAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  canViewCompany: PropTypes.bool,
  canEnableAdProgram: PropTypes.bool,
  canModifyLocationAdProgram: PropTypes.bool,
  onUpdate: PropTypes.func,
};

ProfileSettings.defaultProps = {
  className: '',
  companyId: '',
  contentRating: '',
  mediaFormat: '',
  contentNetworkOwner: '',
  contentDeliverySystem: '',
  adNetworkOwner: '',
  adDeliverySystem: '',
  adProgramEnabled: false,
  isAdmin: false,
  canEdit: false,
  canViewCompany: false,
  canEnableAdProgram: false,
  canModifyLocationAdProgram: false,
  adNetworkEnabled: false,
  onUpdate: () => {},
};

export default ProfileSettings;
