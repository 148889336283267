import React from 'react';

const viCircleTwo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm.131 4c-.362 0-.7.048-1.016.144a2.49 2.49 0 0 0-.839.437c-.244.196-.449.44-.613.733-.132.235-.23.502-.293.802l-.041.23.89.158c.228.036.404.021.527-.044s.223-.197.299-.396a1.008 1.008 0 0 1 .988-.69c.308 0 .537.084.687.253.15.168.226.41.226.725 0 .192-.024.374-.07.545a2.388 2.388 0 0 1-.213.516 3.468 3.468 0 0 1-.358.525c-.097.118-.205.24-.324.366l-.187.193-2.28 2.297a.812.812 0 0 0-.229.326 1.09 1.09 0 0 0-.058.23l-.007.107V16h5.561v-.978a.517.517 0 0 0-.157-.393.553.553 0 0 0-.325-.144l-.104-.006h-1.477c-.167 0-.348.017-.543.05-.13.02-.262.05-.394.084l-.198.056 1.547-1.585c.21-.214.409-.425.595-.633.187-.208.35-.424.492-.647.14-.222.252-.457.334-.703.081-.246.122-.514.122-.804 0-.336-.059-.645-.177-.926a2.046 2.046 0 0 0-.508-.725 2.365 2.365 0 0 0-.8-.475A3.071 3.071 0 0 0 12.13 8z" />
    </g>
  </svg>
);

export default viCircleTwo;
