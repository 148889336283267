import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Field2 as Field,
  API,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function ProfileInfo({
  className,
  name,
  companyId,
  companyName,
  bannerName,
  category,
  tags,
  isAdmin,
  canEdit,
  salesforceId,
  canAssignTag,
  canCreateCompanyBanner,
  user,
  onUpdate,
}) {
  const canAdminEdit = isAdmin && canEdit;

  /**
   * When a field value is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the tag field is changed
   */
  const onChangeTag = (data) => {
    onUpdate(data);
  };

  /**
   * When a dropdown is changed
   */
  const onChangeDropdown = ({
    name,
    value,
  }) => {
    onUpdate({
      [name]: value,
    });
  };

  return (
    <div className={classNames('ProfileInfo', className)}>
      <Grid spacing={2} container>
        <Grid
          xs={6}
          lg={isAdmin
            ? 6
            : 12}
          item
        >
          <Field
            type="text"
            label="Location Name"
            placeholder="Location Name"
            name="name"
            value={name}
            tabIndex={1}
            onChange={onChange}
            disabled={!canAdminEdit}
            required
          />
        </Grid>

        {/* <Grid
          xs={6}
          lg={isAdmin
            ? 6
            : 12}
          item
        >
          <Field
            type="text"
            label="Industry"
            value={companyName}
            tabIndex={2}
            companyProps={{
              companyId,
              companyName,
            }}
            required
          />
        </Grid> */}

        {isAdmin && (
          <Grid
            xs={6}
            lg={isAdmin
              ? 6
              : 12}
            item
          >
            <Field
              type="company"
              label="Company"
              value={companyName}
              tabIndex={3}
              companyProps={{
                companyId,
                companyName,
              }}
              disabled
              required
            />
          </Grid>
        )}

        <Grid
          xs={6}
          lg={isAdmin
            ? 6
            : 12}
          item
        >
          <Field
            type="dropdown"
            label="Category"
            name="category"
            value={category}
            tabIndex={4}
            dropdownProps={{
              type: 'category',
              attr: 'value',
              fetch: API.Company.Category.list,
              create: API.Company.Category.create,
              canCreate: user.can('company_category.create'),
              successMessage: 'COMPANYCATEGORY.CREATED',
            }}
            disabled={!canAdminEdit}
            onChange={onChangeDropdown}
            required
          />
        </Grid>

        <Grid
          xs={6}
          lg={isAdmin
            ? 6
            : 12}
          item
        >
          <Field
            type="dropdown"
            label="Location Banner"
            name="bannerName"
            value={bannerName}
            tabIndex={5}
            dropdownProps={{
              type: 'banner',
              attr: 'value',
              companyId,
              fetch: API.Company.Banner.list,
              create: API.Company.Banner.create,
              canCreate: canCreateCompanyBanner,
              successMessage: 'COMPANYBANNER.CREATED',
            }}
            disabled={!canAdminEdit}
            onChange={onChangeDropdown}
            required
          />
        </Grid>

        {canEdit && (
          <Grid xs={12} lg={6} item>
            <Field
              type="text"
              label="Salesforce ID"
              placeholder="XXXXXXXXXXXXXXXXXX"
              name="salesforceId"
              value={salesforceId}
              tabIndex={6}
              disabled={!canAdminEdit}
              onChange={onChange}
            />
          </Grid>
        )}

        {/* <Grid
          xs={6}
          lg={isAdmin
            ? 6
            : 12}
          item
        >
          <Field
            type="select"
            label="Business Status"
            name="companyName"
            value={companyName}
            options={[
              {
                label: '',
                value: '',
              },
              {
                label: 'Audio',
                value: 'audio',
              },
              {
                label: 'Visual',
                value: 'visual',
              },
            ]}
            tabIndex={6}
            onChange={onChange}
            required
          />
        </Grid> */}

        <Grid xs={12} item>
          <Field
            type="tags"
            label="Tags"
            name="tags"
            tagProps={{
              companyId,
              tags,
              allowTypes: ['client', 'admin'],
              showAs: 'list',
              assign: canAssignTag,
            }}
            disabled={!canEdit}
            onChange={onChangeTag}
            tabIndex={7}
          />
        </Grid>
      </Grid>
    </div>
  );
}

ProfileInfo.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  category: PropTypes.string,
  salesforceId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  isAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  canAssignTag: PropTypes.bool,
  onUpdate: PropTypes.func,
};

ProfileInfo.defaultProps = {
  className: '',
  name: '',
  companyId: '',
  companyName: '',
  category: '',
  salesforceId: '',
  tags: [],
  isAdmin: false,
  canEdit: false,
  canAssignTag: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(ProfileInfo);
