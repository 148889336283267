// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddRemoveField {
  display: flex;
  align-items: center;
}
.AddRemoveField > .field-container {
  flex-grow: 1;
}
.AddRemoveField > .actions {
  width: 60px;
  padding-left: 8px;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Field/AddRemoveField.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,WAAA;EACA,iBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".AddRemoveField {\n  display: flex;\n  align-items: center;\n\n  > .field-container {\n    flex-grow: 1;\n  }\n\n  > .actions {\n    width: 60px;\n    padding-left: 8px;\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
