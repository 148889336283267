import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeToggle,
  VibeIcon,
  viChevronRight,
  viChevronDown,
  color,
  VibeButtonGroup,
  VibeButton,
} from 'vibeguide';
import PermissionItem from './PermissionItem';
import './PermissionGroup.scss';

class PermissionGroup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  /**
   * When a group toggle is changed
   */
  onToggleGroup = () => {
    const {
      title,
      onToggleGroup,
    } = this.props;

    onToggleGroup(title);
  };

  /**
   * When a group is set to on
   */
  onBulkGroupOn = () => {
    const {
      title,
      onBulkGroupOn,
    } = this.props;

    onBulkGroupOn(title);
  };

  /**
   * When a group is set to off
   */
  onBulkGroupOff = () => {
    const {
      title,
      onBulkGroupOff,
    } = this.props;

    onBulkGroupOff(title);
  };

  /**
   * When an individual permission is toggled
   */
  onTogglePermission = (permissionId) => {
    const {
      title,
      onTogglePermission,
    } = this.props;

    onTogglePermission(title, permissionId);
  };

  /**
   * When an individual permission is toggled
   */
  onBulkPermissionOn = (permissionId) => {
    const {
      title,
      onBulkPermissionOn,
    } = this.props;

    onBulkPermissionOn(title, permissionId);
  };

  /**
   * When an individual permission is toggled
   */
  onBulkPermissionOff = (permissionId) => {
    const {
      title,
      onBulkPermissionOff,
    } = this.props;

    onBulkPermissionOff(title, permissionId);
  };

  /**
   * When a group toggle expand is changed
   */
  onToggleExpand = () => {
    this.setState((state) => {
      return {
        expanded: !state.expanded,
      };
    });
  };

  render() {
    const {
      title,
      permissions,
      selectedIds,
      allToggled,
      someToggled,
      disabled,
      bulk,
      bulkGroupOn,
      bulkGroupOff,
    } = this.props;

    const {
      expanded,
    } = this.state;

    return (
      <div className={classNames('PermissionGroup', { disabled })}>
        <div className={classNames('group-info', { disabled })}>
          <div className="group-title">
            {title}

            <div
              className={classNames('group-show-all', { disabled })}
              onClick={this.onToggleExpand}
            >
              {expanded
                ? 'Hide All'
                : 'Show All'}

              <VibeIcon
                icon={expanded
                  ? viChevronDown
                  : viChevronRight}
                color={color.violetVibe}
                size={16}
              />
            </div>
          </div>

          <div className="group-toggle">
            {!bulk ? (
              <VibeToggle
                height={16}
                checked={allToggled}
                partial={someToggled}
                disabled={disabled}
                onChange={this.onToggleGroup}
              />
            ) : (
              <VibeButtonGroup>
                <VibeButton
                  className="on-off-btn"
                  text="OFF"
                  btnColor={bulkGroupOff ? 'manatee' : 'white'}
                  textColor={bulkGroupOff ? 'white' : 'manatee'}
                  onClick={this.onBulkGroupOff}
                />

                <VibeButton
                  className="on-off-btn"
                  text="ON"
                  btnColor={bulkGroupOn ? 'green' : 'white'}
                  textColor={bulkGroupOn ? 'white' : 'manatee'}
                  onClick={this.onBulkGroupOn}
                />
              </VibeButtonGroup>
            )}
          </div>
        </div>

        <div
          className={classNames('group-permissions', { expanded })}
          style={{
            height: expanded
              ? (permissions.length * 48)
              : 0,
          }}
        >
          {permissions.map((permission) => {
            return (
              <PermissionItem
                key={`permission-${permission._id}`}
                permissionId={permission._id}
                name={permission.friendlyName}
                description={permission.desc}
                checked={selectedIds.includes(permission._id)}
                disabled={disabled || bulkGroupOn || bulkGroupOff}
                bulk={bulk}
                bulkPermissionOn={permission.on}
                bulkPermissionOff={permission.off}
                onToggle={this.onTogglePermission}
                onBulkPermissionOn={this.onBulkPermissionOn}
                onBulkPermissionOff={this.onBulkPermissionOff}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

PermissionGroup.propTypes = {
  title: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object),
  /** selected permission IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** all permissions are toggled */
  allToggled: PropTypes.bool,
  /** some permissions are toggled */
  someToggled: PropTypes.bool,
  disabled: PropTypes.bool,
  bulk: PropTypes.bool,
  bulkGroupOn: PropTypes.bool,
  bulkGroupOff: PropTypes.bool,
  onToggleGroup: PropTypes.func,
  onTogglePermission: PropTypes.func,
  onBulkGroupOn: PropTypes.func,
  onBulkGroupOff: PropTypes.func,
  onBulkPermissionOn: PropTypes.func,
  onBulkPermissionOff: PropTypes.func,
};

PermissionGroup.defaultProps = {
  permissions: [],
  selectedIds: [],
  allToggled: false,
  someToggled: false,
  disabled: false,
  bulk: false,
  bulkGroupOn: false,
  bulkGroupOff: false,
  onToggleGroup: () => {},
  onTogglePermission: () => {},
  onBulkGroupOn: () => {},
  onBulkGroupOff: () => {},
  onBulkPermissionOn: () => {},
  onBulkPermissionOff: () => {},
};

export default PermissionGroup;
