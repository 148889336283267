import { handleActions } from 'redux-actions';
import {
  get,
} from 'lodash';

// track events to emit once the socket connects
const emitQueue = [];
// track number of times topics have been subscribed to
// this prevents unsubscribing if another component is also subscribed to the topic
const topicSubscriptions = {};

const initialState = {
  connected: false,
  connection: {
    on: () => {},
    off: () => {},
    emit: (command, data) => {
      emitQueue.push({
        command,
        data,
      });
    },
  },
};

export default handleActions(
  {
    SOCKET_CONNECTED: (state, action) => {
      const socket = action.payload;

      // overwrite the emit command to catch emitted events
      socket.emit = ((_super) => {
        // eslint-disable-next-line func-names
        return function (...args) {
          const socketEvent = args[0];

          switch (socketEvent) {
            // Subscribing to a topic
            case 'subscribe': {
              const topic = get(args, '[1].topic');
              const id = get(args, '[1].id');

              if (!topic || !id) {
                console.warn('topic or id is empty', topic, id);
              }

              // merge topic & id to create a unique subscription key
              const subscribeKey = `${topic}-${id}`;

              if (!topicSubscriptions[subscribeKey]) {
                // no other component is subscribed, create the key
                topicSubscriptions[subscribeKey] = 1;
              } else {
                // increment the subscription count
                topicSubscriptions[subscribeKey] += 1;
              }

              // show number of subscriptions to this topic/id
              // console.log('S: topicSubscriptions', topicSubscriptions);

              // allow multiple subscriptions to the same topic/id combo
              return _super.apply(this, args);
            }

            // Unsubscribing from a topic
            case 'unsubscribe': {
              const topic = get(args, '[1].topic');
              const id = get(args, '[1].id');

              if (!topic || !id) {
                console.warn('topic or id is empty', topic, id);
              }

              // merge topic & id to create a unique subscription key
              const subscribeKey = `${topic}-${id}`;

              // decrement the subscription count
              topicSubscriptions[subscribeKey] -= 1;

              // show number of subscriptions to this topic/id
              // console.log('U: topicSubscriptions', topicSubscriptions);

              if (topicSubscriptions[subscribeKey] <= 0) {
                // no other components are listening to this topic/id
                // remove topic subscription key
                delete topicSubscriptions[subscribeKey];

                // allow unsubscribe from topic/id
                return _super.apply(this, args);
              }

              // another component is subscribed to this topic/id
              // do not allow unsubscription from topic/id
              console.warn('another component is subscribed to:', subscribeKey);
              return null;
            }

            // All other events handle normally
            default:
              return _super.apply(this, args);
          }
        };
      })(socket.emit);

      // Dequeue any pending socket events
      emitQueue.forEach((item, index, object) => {
        socket.emit(item.command, item.data);

        // Remove item from the queue
        object.splice(index, 1);
      });

      return {
        ...state,
        connected: true,
        connection: socket,
      };
    },

    SOCKET_DISCONNECTED: (state) => {
      return {
        ...state,
        connected: false,
        connection: initialState.connection,
      };
    },
  },
  // Initial State
  initialState,
);
