import React from 'react';

const viCalendarUnschedule = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M16 11.3l-.8-.8-3.2 3.2-3.2-3.2-.8.8 3.2 3.2L8 17.7l.8.8 3.2-3.2 3.2 3.2.8-.8-3.2-3.2 3.2-3.2zM16 2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2zm3 18H5V9h14v11z" />
    </g>
  </svg>
);

export default viCalendarUnschedule;
