import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './NavigationSide.scss';

function NavigationSide({
  className,
  children,
}) {
  return (
    <div className={classNames('NavigationSide', className)}>
      {children}
    </div>
  );
}

NavigationSide.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

NavigationSide.defaultProps = {
  className: '',
};

export default NavigationSide;
