import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  isEqual,
  capitalize,
} from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import API from '../../api';
import PercentMeter from '../PercentMeter/PercentMeter';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import viError from '../../icons/viError';
import color from '../../sass/color.scss';
import './TelemetryStatus.scss';

/**
 * Format bytes to readable format
 */
function formatBytes(bytes, decimals) {
  if (bytes === 0) return '0';
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

class TelemetryStatus extends PureComponent {
  constructor(props) {
    super(props);

    const {
      telemetry,
    } = props;

    this.socketEventsRegistered = false;

    this.state = {
      telemetry,
    };
  }

  componentDidMount() {
    const {
      socket,
    } = this.props;

    const {
      telemetry,
    } = this.state;

    if (socket.connected) {
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketEventsRegistered = true;
    }

    if (!telemetry) {
      // If no data provided, fetch telemetry data
      this.getTelemetry();
    }
  }

  componentDidUpdate() {
    const {
      socket,
      telemetry,
    } = this.props;

    const {
      telemetry: stateTelemetry,
    } = this.state;

    if (!this.socketEventsRegistered && socket.connected) {
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketEventsRegistered = true;
    }

    // If the parent component updates the telemetry
    if (telemetry && !isEqual(telemetry, stateTelemetry)) {
      this.setState({
        telemetry,
      });
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    socket.off('VAPI_EVENT', this.onApiEvent);
  }

  onApiEvent = async (e) => {
    switch (e.type) {
      // When a media item is being played
      case 'LOCATION.CAPTURED_MEDIA_PLAYED': {
        this.getTelemetry();
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
   * Get Location Telemetry
   */
  getTelemetry = async () => {
    const {
      currentLocation: {
        _id,
      },
    } = this.props;

    if (!_id) {
      return;
    }

    const telemetry = await API.Location.Telemetry.current({ _id });

    this.setState({
      telemetry,
    });
  };

  /**
   * Render a panel item
   */
  renderPanelItem(title, value, options = {}) {
    const {
      disabled,
    } = options;

    const tooltip = typeof value === 'string';
    const displayValue = disabled
      ? 'N/A'
      : value;

    return (
      <div className={classNames('panel-item', { disabled })}>
        <div className="title">
          {title}
        </div>
        <div className="value">
          {tooltip ? (
            <VibeTooltip
              title={displayValue}
              placement="top"
              enterDelay={1000}
            >
              <span>
                {displayValue}
              </span>
            </VibeTooltip>
          ) : value}
        </div>
      </div>
    );
  }

  render() {
    const {
      style,
      bamboo,
    } = this.props;

    const {
      telemetry,
    } = this.state;

    if (!telemetry) {
      return null;
    }

    const startTime = get(telemetry, 'info.startTime', 'Unknown');
    const startTimeFormat = startTime !== 'Unknown'
      ? new moment(startTime).format('ddd, MMM DD, YYYY, h:mma')
      : startTime;

    const checkInTime = get(telemetry, 'modifiedDate', 'Unknown');
    const checkInTimeFormat = checkInTime !== 'Unknown'
      ? new moment(checkInTime).format('ddd, MMM DD, YYYY, h:mma')
      : checkInTime;

    const memoryAvailable = Math.round(get(telemetry, 'health.memoryPercentage', 0) * 100);
    const chargingState = get(telemetry, 'health.chargingState', 'Unknown');
    const audioConnected = get(telemetry, 'health.audioConnected', false) === true
      ? 'True'
      : 'False';
    const temperature = `${get(telemetry, 'health.temperature', 0)}°C`;
    const dataState = get(telemetry, 'network.dataState', 'Unknown');
    const dataEnabled = get(telemetry, 'network.dataEnabled', false) === true
      ? 'True'
      : 'False';
    const roaming = get(telemetry, 'network.roaming', false) === true
      ? 'True'
      : 'False';
    const cellSignalStrength = `${get(telemetry, 'network.cellDbm', 0)}db`;

    return (
      <div
        className="TelemetryStatus"
        style={style}
      >
        <div className="panel">
          <div className="title">
            Device Info
          </div>

          <div className="panel-content">
            <div className="panel-row">
              {this.renderPanelItem('IMEI', get(telemetry, 'info.imei', 'Unknown'))}
              {this.renderPanelItem('Device ID', get(telemetry, 'playerId', 'Unknown'))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Model', get(telemetry, 'info.model', 'Unknown'))}
              {this.renderPanelItem('Total Memory', formatBytes(get(telemetry, 'info.deviceMemory', 0)))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('OS Version', get(telemetry, 'info.osVersion', 'Unknown'))}
              {this.renderPanelItem('Total Storage', formatBytes(get(telemetry, 'info.deviceStorage', 0)))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('App Package', get(telemetry, 'info.packageName', 'Unknown'))}
              {this.renderPanelItem('Phone Number', (
                <PhoneNumber
                  value={get(telemetry, 'info.phoneNumber', 'Unknown')}
                />
              ))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Start Time', startTimeFormat)}
              {this.renderPanelItem('Check In Time', checkInTimeFormat)}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Time Zone', get(telemetry, 'info.timeZone', 'Unknown'))}
              {this.renderPanelItem('Version', get(telemetry, 'info.vibenomicsVersion', 'Unknown'))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Media Format', capitalize(get(telemetry, 'locationMediaFormat', 'Unknown')))}
              {this.renderPanelItem('', '')}
            </div>
          </div>
        </div>

        <div className="panel">
          <div className="title">
            Device Health
          </div>

          <div className="panel-content">
            <div className="panel-row">
              {this.renderPanelItem('Master Volume', (
                <PercentMeter
                  percentage={get(telemetry, 'volume.master', 0)}
                  width={84}
                  textPosition="right"
                  ranges={[
                    {
                      range: '0',
                      color: color.fireBrick,
                      border: true,
                      icon: viError,
                    },
                    {
                      range: '1-25',
                      color: color.fireBrick,
                    },
                    {
                      range: '26-60',
                      color: color.violetVibe,
                    },
                    {
                      range: '61-100',
                      color: color.aquaForest,
                    },
                  ]}
                />
              ))}
              {this.renderPanelItem('Message Volume', (
                <PercentMeter
                  percentage={get(telemetry, 'volume.message', 0)}
                  width={84}
                  textPosition="right"
                  ranges={[
                    {
                      range: '0',
                      color: color.fireBrick,
                      border: true,
                      icon: viError,
                    },
                    {
                      range: '1-25',
                      color: color.fireBrick,
                    },
                    {
                      range: '26-60',
                      color: color.violetVibe,
                    },
                    {
                      range: '61-100',
                      color: color.aquaForest,
                    },
                  ]}
                />
              ))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Music Volume', (
                <PercentMeter
                  percentage={get(telemetry, 'volume.music', 0)}
                  width={84}
                  textPosition="right"
                  ranges={[
                    {
                      range: '0',
                      color: color.fireBrick,
                      border: true,
                      icon: viError,
                    },
                    {
                      range: '1-25',
                      color: color.fireBrick,
                    },
                    {
                      range: '26-60',
                      color: color.violetVibe,
                    },
                    {
                      range: '61-100',
                      color: color.aquaForest,
                    },
                  ]}
                />
              ))}
              {this.renderPanelItem('Battery', (
                <PercentMeter
                  percentage={get(telemetry, 'health.batteryLevel', 0)}
                  width={84}
                  textPosition="right"
                  ranges={[
                    {
                      range: '0',
                      color: color.fireBrick,
                      border: true,
                      icon: viError,
                    },
                    {
                      range: '1-25',
                      color: color.fireBrick,
                    },
                    {
                      range: '26-60',
                      color: color.violetVibe,
                    },
                    {
                      range: '61-100',
                      color: color.aquaForest,
                    },
                  ]}
                  disabled={bamboo}
                />
              ), {
                disabled: bamboo,
              })}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Memory % Available', (
                <PercentMeter
                  percentage={memoryAvailable}
                  width={84}
                  textPosition="right"
                  ranges={[
                    {
                      range: '0',
                      color: color.fireBrick,
                      border: true,
                      icon: viError,
                    },
                    {
                      range: '1-25',
                      color: color.fireBrick,
                    },
                    {
                      range: '26-60',
                      color: color.violetVibe,
                    },
                    {
                      range: '61-100',
                      color: color.aquaForest,
                    },
                  ]}
                />
              ))}
              {this.renderPanelItem('Charging State', chargingState, { disabled: bamboo })}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Memory Available', formatBytes(get(telemetry, 'health.memoryAvailable', 0)))}
              {this.renderPanelItem('Storage Available', formatBytes(get(telemetry, 'health.storageFree', 0)))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Memory Used', formatBytes(get(telemetry, 'health.memoryUsed', 0)))}
              {this.renderPanelItem('Storage Used', formatBytes(get(telemetry, 'health.storageUsed', 0)))}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Audio Connected', audioConnected)}
              {this.renderPanelItem('Temperature', temperature, { disabled: bamboo })}
            </div>
          </div>
        </div>

        <div className="panel">
          <div className="title">
            Network
          </div>

          <div className="panel-content">
            <div className="panel-row">
              {bamboo
                ? this.renderPanelItem('Network Type', 'Ethernet')
                : this.renderPanelItem('Cellular Connection', dataState)}

              {this.renderPanelItem('Cellular Data', dataEnabled, { disabled: bamboo })}
            </div>

            <div className="panel-row">
              {this.renderPanelItem(
                'Cellular Network',
                get(telemetry, 'network.networkName', 'Unknown'),
                { disabled: bamboo },
              )}
              {this.renderPanelItem(
                'Cellular Network Type',
                get(telemetry, 'network.networkType', 'Unknown'),
                { disabled: bamboo },
              )}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('Cellular Signal Strength', cellSignalStrength, { disabled: bamboo })}
              {this.renderPanelItem('Roaming', roaming, { disabled: bamboo })}
            </div>

            <div className="panel-row">
              {this.renderPanelItem(
                'Wifi Signal Strength',
                get(telemetry, 'network.wifiQuality', 0).toString(),
                { disabled: bamboo },
              )}
              {this.renderPanelItem('Wifi SSID', get(telemetry, 'network.wifiSSID', 'Unknown'), { disabled: bamboo })}
            </div>

            <div className="panel-row">
              {this.renderPanelItem('IP Address', get(telemetry, 'ipAddress', 'Unknown'))}
              {this.renderPanelItem('MAC Address', get(telemetry, 'macAddress', 'Unknown'))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TelemetryStatus.propTypes = {
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Telemetry data to use for the status */
  telemetry: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Is the device a Bamboo device? */
  bamboo: PropTypes.bool.isRequired,
};

TelemetryStatus.defaultProps = {
  style: {},
  telemetry: null,
};

function mapStateToProps(state) {
  return {
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
  };
}

export default connect(mapStateToProps)(TelemetryStatus);
