import queryString from 'query-string';
import {
  get,
  omit,
  forEach,
  isUndefined,
} from 'lodash';

/**
 * Get all the parameters in the URL
 */
export function getParams(searchStr) {
  const search = searchStr || get(window, 'location.search', '');
  return queryString.parse(search);
}

/**
 * Get a specific parameter in the URL
 */
export function getParam(key, searchStr) {
  const search = searchStr || get(window, 'location.search', '');
  const qs = queryString.parse(search);

  return get(qs, key);
}

/**
 * Update the parameter in the URL
 */
export function updateParams(params, options = {}) {
  const pathname = options.pathname || get(window, 'location.pathname', '/');
  const search = get(window, 'location.search', '');
  const qs = queryString.parse(search);
  // list of parameters to remove
  const removeParams = [];

  if (isUndefined(params.page) && !options.keepPage) {
    // Only remove/reset the page number if we aren't adding/updating the page number
    // or if the option is set to keep the page parameter
    removeParams.push('page');
  }

  forEach(params, (value, key) => {
    // do not remove the attribute when the value is false
    if (value || value === false) {
      // add or update the parameter
      qs[key] = value;
    } else {
      // no value, remove from the querystring
      removeParams.push(key);
    }
  });

  // remove the empty parameters
  const paramList = omit(qs, removeParams);
  const newParams = queryString.stringify(paramList, {
    encode: options.encode !== false,
  });

  return `${pathname}?${newParams}`;
}

/**
 * Remove all parameters in the URL
 */
export function clearParams() {
  const pathname = get(window, 'location.pathname', '/');
  return pathname;
}

export default {
  getParam,
  getParams,
  updateParams,
  clearParams,
};
