// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VibeButtonGroup {
  display: flex;
  align-items: center;
}
.VibeButtonGroup .VibeButton {
  margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeButtonGroup/VibeButtonGroup.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".VibeButtonGroup {\n  display: flex;\n  align-items: center;\n\n  .VibeButton {\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
