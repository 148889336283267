import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  PieChart,
  NavigationHelper,
  withRouter,
  color,
} from 'vibeguide';

class Connection extends PureComponent {
  /**
   * When the pie chart is clicked
   */
  onClickPieChart = (data) => {
    const {
      includeFlaggedLocations,
      history,
    } = this.props;

    // Get the label of the area clicked
    const label = get(data, 'name', '');

    if (!label) {
      console.error('No label found for the chart');
      return;
    }

    const urlData = {
      playerOnline: true,
      onboardStatus: 'complete',
      connectionType: label.toLowerCase(),
    };

    if (!includeFlaggedLocations) {
      urlData.flagEnabled = false;
    }

    // Add a parameter for the table to automatically apply
    const url = NavigationHelper.updateParams(urlData, {
      pathname: '/dashboard/support',
    });

    history(url);
  };

  render() {
    const {
      data,
      includeFlaggedLocations,
    } = this.props;

    const urlData = {
      playerOnline: true,
      onboardStatus: 'complete',
    };

    const wifiData = {
      ...urlData,
      connectionType: 'wifi',
    };

    const cellularData = {
      ...urlData,
      connectionType: 'cellular',
    };

    const ethernetData = {
      ...urlData,
      connectionType: 'ethernet',
    };

    if (!includeFlaggedLocations) {
      wifiData.flagEnabled = false;
      cellularData.flagEnabled = false;
      ethernetData.flagEnabled = false;
    }

    const wifiUrl = NavigationHelper.updateParams(wifiData, {
      pathname: '/dashboard/support',
    });

    const cellularUrl = NavigationHelper.updateParams(cellularData, {
      pathname: '/dashboard/support',
    });

    const ethernetUrl = NavigationHelper.updateParams(ethernetData, {
      pathname: '/dashboard/support',
    });

    const donutData = [
      {
        name: 'WIFI',
        value: data.WIFI,
        color: color.violetVibe,
        url: wifiUrl,
      },
      {
        name: 'ETHERNET',
        value: data.ETHERNET,
        color: color.cosmicVibe,
        url: ethernetUrl,
      },
      {
        name: 'CELLULAR',
        value: data.CELLULAR,
        color: color.manatee,
        url: cellularUrl,
      },
    ];

    return (
      <div className="Connection">
        <PieChart
          title="Connection"
          width={275}
          legend="right"
          data={donutData}
          onClick={this.onClickPieChart}
        />
      </div>
    );
  }
}

Connection.propTypes = {
  data: PropTypes.shape({
    WIFI: PropTypes.number,
    CELLULAR: PropTypes.number,
    ETHERNET: PropTypes.number,
  }).isRequired,
  includeFlaggedLocations: PropTypes.bool,
};

Connection.defaultProps = {
  includeFlaggedLocations: false,
};

export default withRouter(Connection);
