import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  reduce,
} from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  API,
  GlobalActions,
  ToastActions,
  StorageUtil,
  NavigationHelper,
} from 'vibeguide';
import DashboardHeader from './DashboardHeader';
import DashboardCard from './DashboardCard';
import AlertCount from './Alerts/AlertCount';
import AlertVolumeConfig from './Alerts/AlertVolumeConfig';
import AlertAudioConfig from './Alerts/AlertAudioConfig';
import AlertBatteryConfig from './Alerts/AlertBatteryConfig';
import AlertMemoryConfig from './Alerts/AlertMemoryConfig';
import AlertNetworkConfig from './Alerts/AlertNetworkConfig';
import AlertOfflineConfig from './Alerts/AlertOfflineConfig';
import DeviceStates from './Charts/DeviceStates';
import AppVersion from './Charts/AppVersion';
import Connection from './Charts/Connection';
import OfflineDevices from './Charts/OfflineDevices';
import './DashboardAlerts.scss';

class DashboardAlerts extends PureComponent {
  constructor(props) {
    super(props);

    const currIncludeFlaggedLocations = StorageUtil.getLocal('alerts:includeFlaggedLocations', false);

    this.state = {
      alerts: {},
      deviceStates: {},
      offlineDevices: {},
      appVersions: {},
      connection: {},
      summaryFetched: false,
      pauseRefresh: false,
      includeFlaggedLocations: currIncludeFlaggedLocations,
    };
  }

  componentDidMount() {
    this.getDashboardData();
  }

  /**
   * When clicking the settings button on the card
   */
  onClickSettings = (title) => {
    const {
      setPanel,
    } = this.props;

    const data = this.getSettingsData(title);

    setPanel({
      title: data.title,
      show: true,
      backdrop: true,
      children: data.content,
    });
  };

  /**
   * When clicking the export button on the card
   */
  onClickExport = async (category) => {
    const {
      includeFlaggedLocations,
    } = this.state;

    const plainCategory = category.toLowerCase().trim();

    const params = {
      filters: {},
      pageNumber: 1,
      pageSize: -1,
    };

    if (plainCategory !== 'all open alerts') {
      params.filters.category = plainCategory;
    }

    if (!includeFlaggedLocations) {
      params.filters.flagEnabled = false;
    }

    const options = {
      headers: {
        Accept: 'text/csv',
      },
    };

    const exportCSV = await API.Location.Alerts.list(params, options);

    if (exportCSV && (exportCSV.length > 0)) {
      const blob = new Blob([exportCSV], { type: 'text/csv;charset=utf-8' });
      const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
      saveAs(blob, `alerts-${plainCategory}-${timestamp}.csv`);
    } else {
      console.warn('No Data To Download');
    }
  };

  onChangeIncludeFlaggedLocations = (includeFlaggedLocations) => {
    StorageUtil.setLocal('alerts:includeFlaggedLocations', includeFlaggedLocations);

    this.setState({
      includeFlaggedLocations,
    }, () => {
      this.getDashboardData();
    });
  };

  /**
   * When to queue a toast to show
   */
  onToast = (data) => {
    const {
      queueToast,
    } = this.props;

    queueToast(data);
  };

  /**
   * When the data is being refreshed
   */
  onRefresh = () => {
    this.getDashboardData();
  };

  /**
   * Get Dashboard Data
   */
  getDashboardData = () => {
    const {
      includeFlaggedLocations,
    } = this.state;

    const filters = {
      includeFlaggedLocations,
    };

    Promise.all([
      API.Location.Alerts.summary(filters),
      API.Location.Telemetry.getStatusSummary(filters),
      API.Location.Telemetry.getOfflineSummary(filters),
      API.Location.Telemetry.getVersionSummary(filters),
      API.Location.Telemetry.getConnectionSummary(filters),
    ]).then((values) => {
      const [
        alerts,
        deviceStates,
        offlineDevices,
        appVersions,
        connection,
      ] = values;

      // Get total number of alerts
      const total = reduce(alerts, (sum, n) => {
        return sum + n;
      }, 0);

      alerts.total = total;

      this.setState({
        alerts,
        deviceStates,
        offlineDevices,
        appVersions,
        connection,
        summaryFetched: true,
        pauseRefresh: false,
      });
    }).catch((err) => {
      console.error('Error fetching dashboard data', err);

      this.setState({
        pauseRefresh: true,
      });
    });
  };

  /**
   * Get the settings for the specific alert config clicked
   */
  getSettingsData(title) {
    switch (title.toLowerCase()) {
      case 'volume':
        return {
          title: 'Volume Alert Settings',
          content: (
            <AlertVolumeConfig
              onToast={this.onToast}
            />
          ),
        };

      case 'audio':
        return {
          title: 'Audio Alert Settings',
          content: (
            <AlertAudioConfig
              onToast={this.onToast}
            />
          ),
        };

      case 'battery':
        return {
          title: 'Battery Alert Settings',
          content: (
            <AlertBatteryConfig
              onToast={this.onToast}
            />
          ),
        };

      case 'memory':
        return {
          title: 'Memory Alert Settings',
          content: (
            <AlertMemoryConfig
              onToast={this.onToast}
            />
          ),
        };

      case 'network':
        return {
          title: 'Network Alert Settings',
          content: (
            <AlertNetworkConfig
              onToast={this.onToast}
            />
          ),
        };

      case 'offline':
        return {
          title: 'Offline Alert Settings',
          content: (
            <AlertOfflineConfig
              onToast={this.onToast}
            />
          ),
        };

      default:
        return {
          title: 'Unknown',
          content: null,
        };
    }
  }

  render() {
    const {
      user,
    } = this.props;

    const {
      alerts,
      deviceStates,
      offlineDevices,
      appVersions,
      connection,
      summaryFetched,
      pauseRefresh,
      includeFlaggedLocations,
    } = this.state;

    if (!summaryFetched) {
      return null;
    }

    const canViewAlerts = user.can('alert.view');
    const canManageSettings = user.can('alert.manage_settings');

    return (
      <div className="DashboardAlerts">
        <DashboardHeader
          title="Alerts Dashboard"
          refreshEvery={60}
          pauseRefresh={pauseRefresh}
          includeFlaggedLocations={includeFlaggedLocations}
          showIncludeFlaggedLocationsToggle={canViewAlerts}
          onRefresh={this.onRefresh}
          onChangeIncludeFlaggedLocations={this.onChangeIncludeFlaggedLocations}
        />

        <div className="alerts-content">
          <div className="alerts-row-container">
            <div className="alerts-row">
              <DashboardCard
                className="alert-card card-all-alerts"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : '/alerts/open'}
              >
                <AlertCount
                  title="All Open Alerts"
                  count={alerts.total >= 0
                    ? alerts.total
                    : -1}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>

              <DashboardCard
                className="alert-card"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    category: 'volume',
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : NavigationHelper.updateParams({
                    category: 'volume',
                  }, { pathname: '/alerts/open' })}
              >
                <AlertCount
                  title="Volume"
                  count={alerts.volume >= 0
                    ? alerts.volume
                    : -1}
                  showSettings={canManageSettings}
                  onClickSettings={this.onClickSettings}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>

              <DashboardCard
                className="alert-card"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    category: 'audio',
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : NavigationHelper.updateParams({
                    category: 'audio',
                  }, { pathname: '/alerts/open' })}
              >
                <AlertCount
                  title="Audio"
                  count={alerts.audio >= 0
                    ? alerts.audio
                    : -1}
                  showSettings={canManageSettings}
                  onClickSettings={this.onClickSettings}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>

              <DashboardCard
                className="alert-card"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    category: 'battery',
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : NavigationHelper.updateParams({
                    category: 'battery',
                  }, { pathname: '/alerts/open' })}
              >
                <AlertCount
                  title="Battery"
                  count={alerts.battery >= 0
                    ? alerts.battery
                    : -1}
                  showSettings={canManageSettings}
                  onClickSettings={this.onClickSettings}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>

              <DashboardCard
                className="alert-card"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    category: 'memory',
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : NavigationHelper.updateParams({
                    category: 'memory',
                  }, { pathname: '/alerts/open' })}
              >
                <AlertCount
                  title="Memory"
                  count={alerts.memory >= 0
                    ? alerts.memory
                    : -1}
                  showSettings={canManageSettings}
                  onClickSettings={this.onClickSettings}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>

              <DashboardCard
                className="alert-card"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    category: 'network',
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : NavigationHelper.updateParams({
                    category: 'network',
                  }, { pathname: '/alerts/open' })}
              >
                <AlertCount
                  title="Network"
                  count={alerts.network >= 0
                    ? alerts.network
                    : -1}
                  showSettings={canManageSettings}
                  onClickSettings={this.onClickSettings}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>

              <DashboardCard
                className="alert-card"
                link={!includeFlaggedLocations
                  ? NavigationHelper.updateParams({
                    category: 'offline',
                    flagEnabled: false,
                  }, { pathname: '/alerts/open' })
                  : NavigationHelper.updateParams({
                    category: 'offline',
                  }, { pathname: '/alerts/open' })}
              >
                <AlertCount
                  title="Offline"
                  count={alerts.offline >= 0
                    ? alerts.offline
                    : -1}
                  showSettings={canManageSettings}
                  onClickSettings={this.onClickSettings}
                  onClickExport={this.onClickExport}
                />
              </DashboardCard>
            </div>
          </div>

          <div className="alerts-row-container">
            <div className="alerts-row">
              <DashboardCard className="alert-card">
                <DeviceStates
                  data={deviceStates}
                  includeFlaggedLocations={includeFlaggedLocations}
                />
              </DashboardCard>

              <DashboardCard className="alert-card">
                <AppVersion
                  data={appVersions}
                  includeFlaggedLocations={includeFlaggedLocations}
                />
              </DashboardCard>

              <DashboardCard className="alert-card">
                <Connection
                  data={connection}
                  includeFlaggedLocations={includeFlaggedLocations}
                />
              </DashboardCard>
            </div>
          </div>

          <div className="alerts-row-container">
            <div className="alerts-row">
              <DashboardCard>
                <OfflineDevices
                  data={offlineDevices}
                  includeFlaggedLocations={includeFlaggedLocations}
                />
              </DashboardCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAlerts);
