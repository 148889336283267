import React from 'react';

const viQuestionBubble = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M5 2h14c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-4l-3 4-3-4H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm4.62 4.638l.438.684a.37.37 0 0 0 .342.198c.068 0 .14-.024.216-.072.076-.048.168-.101.276-.159a1.842 1.842 0 0 1 .942-.231c.3 0 .541.078.723.234a.787.787 0 0 1 .273.63c0 .216-.047.403-.141.561-.094.158-.21.3-.348.426a4.307 4.307 0 0 1-.444.354c-.158.11-.301.224-.429.342a1.48 1.48 0 0 0-.306.39.795.795 0 0 0-.072.501l.114.972h1.134l.15-.858a.65.65 0 0 1 .189-.339c.102-.102.223-.205.363-.309.14-.104.288-.216.444-.336.156-.12.299-.262.429-.426a2.21 2.21 0 0 0 .324-.579c.086-.222.129-.485.129-.789 0-.316-.057-.602-.171-.858a1.876 1.876 0 0 0-.483-.657 2.174 2.174 0 0 0-.75-.42 3.062 3.062 0 0 0-.978-.147c-.26 0-.503.023-.729.069a3.28 3.28 0 0 0-1.17.471 4.33 4.33 0 0 0-.465.348zm1.122 7.044a.945.945 0 0 0 .282.681.948.948 0 0 0 .309.201c.118.048.247.072.387.072.136 0 .264-.024.384-.072a.94.94 0 0 0 .519-.504.945.945 0 0 0 .075-.378.951.951 0 0 0-.594-.885.988.988 0 0 0-.384-.075.982.982 0 0 0-.696.279.951.951 0 0 0-.282.681z" />
    </g>
  </svg>
);

export default viQuestionBubble;
