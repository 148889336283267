import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  ToastActions,
  LocationActions,
  VibeButtonNew,
  VibeIcon,
  viVolumeUp,
  viVolumeDown,
  viVolumeOff,
  viEqualizer,
  color,
} from 'vibeguide';
import Slider from '@mui/material/Slider';
import ProfileContentFooter from '../Profile/ProfileContentFooter';
import './VolumeControl.scss';

function VolumeControl({
  className,
  locationId,
  master,
  message,
  music,
  isActive,
  canManageVolume,
  onChange,
  queueToast,
  updateCurrentLocation,
  socket,
}) {
  const [hasChanges, setHasChanges] = useState(false);

  /**
   * When the volume is changed
   */
  const onChangeVolume = (e, value) => {
    if (!canManageVolume || !isActive) {
      return;
    }

    const {
      target: {
        name,
      },
    } = e;

    onChange({
      master,
      message,
      music,
      [name]: value,
    });

    setHasChanges(true);
  };

  /**
   * When the volume is turned up
   */
  const onClickVolumeUp = (e, name) => {
    if (!canManageVolume || !isActive) {
      return;
    }

    onChange({
      master: name === 'master' && master < 100
        ? master + 1
        : master,
      message: name === 'message' && message < 100
        ? message + 1
        : message,
      music: name === 'music' && music < 100
        ? music + 1
        : music,
    });

    setHasChanges(true);
  };

  /**
   * When the volume is turned down
   */
  const onClickVolumeDown = (e, name) => {
    if (!canManageVolume || !isActive) {
      return;
    }

    onChange({
      master: name === 'master' && master > 0
        ? master - 1
        : master,
      message: name === 'message' && message > 0
        ? message - 1
        : message,
      music: name === 'music' && music > 0
        ? music - 1
        : music,
    });

    setHasChanges(true);
  };

  /**
   * Listen for socket events
   */
  const onApiEvent = async (e) => {
    switch (e.type) {
      /**
       * When the volume is changed
       */
      case 'LOCATION.VOLUME_UPDATED': {
        if (e.documentId === locationId) {
          onChange({
            master: get(e, 'data.master', master),
            message: get(e, 'data.message', message),
            music: get(e, 'data.music', music),
          });
        }
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
   * Save the volume
   */
  const onSave = async () => {
    if (!canManageVolume || !isActive) {
      return;
    }

    queueToast({
      type: 'info',
      title: 'Saving Volume...',
      allowClose: true,
    });

    try {
      const volumeResponse = await API.Location.updateVolume({
        _id: locationId,
        master,
        music,
        message,
      });

      const success = get(volumeResponse, '[0].type') === 'LOCATION.VOLUME_UPDATED';

      if (success) {
        updateCurrentLocation({
          volume: {
            master,
            message,
            music,
          },
        });

        queueToast({
          type: 'success',
          title: 'Saved Volume!',
          allowClose: true,
          delay: 500,
        });

        setHasChanges(false);
      } else {
        queueToast({
          type: 'error',
          title: 'Error Saving Volume',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error Saving Volume', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Volume',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  /**
   * Restore the volume to the default settings
   */
  const restoreDefaultVolumes = () => {
    if (!canManageVolume || !isActive) {
      return;
    }

    onChange({
      master: 60,
      message: 100,
      music: 80,
    });

    setHasChanges(true);
  };

  /**
   * When connected to the socket, listen for events
   */
  useEffect(() => {
    if (socket.connected) {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.on('VAPI_EVENT', onApiEvent);
    }

    // when component unmounts
    return () => {
      socket.off('VAPI_EVENT', onApiEvent);
    };
  }, [socket.connected]);

  return (
    <div className={classNames('VolumeControl', className)}>
      <div className="content">
        <div className={classNames('volume-option', { muted: master === 0 })}>
          <div className="volume-header">
            <div className="title">
              Master
            </div>

            <div className="volume-current">
              {master}%
            </div>
          </div>

          <div className="volume-slider">
            <VibeIcon
              className="sound-down"
              icon={master === 0
                ? viVolumeOff
                : viVolumeDown}
              color={master === 0
                ? color.manatee16
                : color.obsidian}
              hoverColor={color.manatee}
              size={24}
              name="master"
              onClick={onClickVolumeDown}
            />

            <Slider
              name="master"
              classes={{
                root: 'volume-control',
              }}
              value={master}
              disabled={!canManageVolume || !isActive}
              onChange={onChangeVolume}
            />

            <VibeIcon
              className="sound-up"
              icon={viVolumeUp}
              color={master === 0
                ? color.fireBrick
                : color.obsidian}
              hoverColor={color.manatee}
              size={24}
              name="master"
              onClick={onClickVolumeUp}
            />
          </div>
        </div>

        <div className={classNames('volume-option', { muted: message === 0 })}>
          <div className="volume-header">
            <div className="title">
              Message
            </div>

            <div className="volume-current">
              {message}%
            </div>
          </div>

          <div className="volume-slider">
            <VibeIcon
              className="sound-down"
              icon={message === 0
                ? viVolumeOff
                : viVolumeDown}
              color={message === 0
                ? color.manatee16
                : color.obsidian}
              hoverColor={color.manatee}
              size={24}
              name="message"
              onClick={onClickVolumeDown}
            />

            <Slider
              name="message"
              classes={{
                root: 'volume-control',
              }}
              value={message}
              disabled={!canManageVolume || !isActive}
              onChange={onChangeVolume}
            />

            <VibeIcon
              className="sound-up"
              icon={viVolumeUp}
              color={message === 0
                ? color.fireBrick
                : color.obsidian}
              hoverColor={color.manatee}
              size={24}
              name="message"
              onClick={onClickVolumeUp}
            />
          </div>
        </div>

        <div className={classNames('volume-option', { muted: music === 0 })}>
          <div className="volume-header">
            <div className="title">
              Music
            </div>

            <div className="volume-current">
              {music}%
            </div>
          </div>

          <div className="volume-slider">
            <VibeIcon
              className="sound-down"
              icon={music === 0
                ? viVolumeOff
                : viVolumeDown}
              color={music === 0
                ? color.manatee16
                : color.obsidian}
              hoverColor={color.manatee}
              size={24}
              name="music"
              onClick={onClickVolumeDown}
            />

            <Slider
              name="music"
              classes={{
                root: 'volume-control',
              }}
              value={music}
              disabled={!canManageVolume || !isActive}
              onChange={onChangeVolume}
            />

            <VibeIcon
              className="sound-up"
              icon={viVolumeUp}
              color={music === 0
                ? color.fireBrick
                : color.obsidian}
              hoverColor={color.manatee}
              size={24}
              name="music"
              onClick={onClickVolumeUp}
            />
          </div>
        </div>
      </div>

      {canManageVolume && isActive && (
        <ProfileContentFooter>
          <VibeButtonNew
            variant="text"
            text="RESTORE DEFAULTS"
            color={color.obsidian}
            icon={viEqualizer}
            onClick={restoreDefaultVolumes}
          />

          <VibeButtonNew
            style={{
              marginLeft: 8,
            }}
            text="Save Changes"
            color={color.violetVibe}
            disabled={!isActive || !hasChanges}
            onClick={onSave}
          />
        </ProfileContentFooter>
      )}
    </div>
  );
}

VolumeControl.propTypes = {
  className: PropTypes.string,
  locationId: PropTypes.string.isRequired,
  master: PropTypes.number,
  message: PropTypes.number,
  music: PropTypes.number,
  /** Is the location active to allow volume changes */
  isActive: PropTypes.bool,
  canManageVolume: PropTypes.bool,
  onChange: PropTypes.func,
};

VolumeControl.defaultProps = {
  className: '',
  master: 0,
  message: 0,
  music: 0,
  isActive: false,
  canManageVolume: false,
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    socket: state.socket.connection,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
  updateCurrentLocation: LocationActions.updateCurrentLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumeControl);
