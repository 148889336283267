import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import PermissionRole from './Content/PermissionRole/PermissionRole';
import PermissionList from './Content/PermissionList/PermissionList';

class UserPermissions extends PureComponent {
  constructor(props) {
    super(props);

    const {
      permissionIds,
    } = props;

    this.state = {
      permissionSetId: null,
      permissionIds,
    };
  }

  /**
   * Intercept update requests to ensure selected permission IDs stay in sync
   */
  onUpdate = (data) => {
    const {
      onUpdate,
    } = this.props;

    // use state to set permission IDs to automatically update child components
    if (data.permissionIds) {
      this.setState({
        permissionIds: data.permissionIds,
      });
    }

    // pass the normal update request to the parent component
    onUpdate(data);
  };

  /**
   * When the permission set is changed
   */
  onChangePermissionSet = (permissionSetId) => {
    this.setState({
      permissionSetId,
    });
  };

  render() {
    const {
      user,
      disabled,
      onClose,
    } = this.props;

    const {
      permissionSetId,
      permissionIds,
    } = this.state;

    return (
      <SidePanelContainer className="UserPermissions">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          <div className="title">
            Permissions
          </div>
        </SidePanelHeader>

        <SidePanelContent>
          {user.can('permissionset.view') ? (
            <PermissionRole
              permissionIds={permissionIds}
              disabled={disabled}
              allowActions
              onChange={this.onChangePermissionSet}
            />
          ) : null}

          <PermissionList
            permissionSetId={permissionSetId}
            permissionIds={permissionIds}
            disabled={disabled}
            onUpdate={this.onUpdate}
          />
        </SidePanelContent>
      </SidePanelContainer>
    );
  }
}

UserPermissions.propTypes = {
  permissionIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

UserPermissions.defaultProps = {
  permissionIds: [],
  disabled: false,
  onClose: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(UserPermissions);
