import React, { Component } from 'react';
import {
  ProgressBar,
  viCheckCircle,
  VibeIcon,
  viAlert,
  color,
} from 'vibeguide';
import { Paper } from '@mui/material';
import './UploadFile.scss';

class UploadFile extends Component {
  /**
   * Format bytes to readable format
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) return '0';
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
  }

  render() {
    const {
      upload: {
        isComplete,
        error,
        progress,
        file,
      },
    } = this.props;

    let statusItem = `${progress}%`;
    let statusClass = 'pending';

    // Get the current uploaded amount
    const totalUploadedBytes = file.size * (progress / 100);
    // Convert total uploaded to a readable format
    const totalUploaded = this.formatBytes(totalUploadedBytes, 1);
    // Convert the total size to a readable format
    const totalSize = this.formatBytes(file.size, 1);

    let statusMessage = `${totalUploaded} / ${totalSize}`;

    if (error) {
      statusClass = 'error';
      statusMessage = error;
      statusItem = (
        <VibeIcon
          icon={viAlert}
          color={color.fireBrick}
          size={32}
        />
      );
    } else if (!isComplete && progress >= 100) {
      // Show progress as 99 even if it's at 100 while we wait for the upload request to finish
      statusItem = '99%';
    } else if (isComplete) {
      statusClass = 'success';
      statusMessage = 'Success!';
      statusItem = (
        <VibeIcon
          className="icon"
          icon={viCheckCircle}
          color={color.aquaForest}
          size={24}
        />
      );
    }

    return (
      <Paper className="UploadFile">
        {!isComplete ? (
          <div className="progress-bar">
            <ProgressBar
              progress={progress}
              color="#00a474"
              height={4}
            />
          </div>
        ) : null}

        <div className="upload-content">
          <div className={`status-box ${statusClass}`}>
            {statusItem}
          </div>
          <div>
            <div className="filename">
              {file.name}
            </div>
            <div className={`status-message ${statusClass}`}>
              {statusMessage}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default UploadFile;
