import React from 'react';
import PropTypes from 'prop-types';
import LabelCell from '../VibeTable/CellTypes/LabelCell';
import color from '../../sass/color.scss';

/**
 * Get the style for the missing creatives tag
 */
function getStatusStyle(status) {
  switch (status) {
    case 'Missing':
      return {
        backgroundColor: color.fireBrick16,
        color: color.fireBrick,
      };

    case 'Attached':
      return {
        backgroundColor: color.aquaForest16,
        color: color.aquaForest,
      };

    case 'N/A':
      return {
        backgroundColor: color.flamingo16,
        color: color.flamingo,
      };

    default:
      return {
        backgroundColor: color.flamingo16,
        color: color.flamingo,
      };
  }
}

function MissingCreatives({
  status,
}) {
  const statusStyle = getStatusStyle(status);

  return (
    <LabelCell
      value={status}
      background={statusStyle.backgroundColor}
      color={statusStyle.color}
      style={{
        textTransform: 'uppercase',
      }}
      inline
    />
  );
}

MissingCreatives.propTypes = {
  status: PropTypes.string.isRequired,
};

export default MissingCreatives;
