import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import API from '../../api';
import VibeCard from '../VibeCard/VibeCard';
import VibeModal from '../VibeModal/VibeModal';
import VibeIcon from '../VibeIcon/VibeIcon';
import viEmail from '../../icons/viEmail';
import defaultUserImage from '../../assets/users.png';
import color from '../../sass/color.scss';
import './UserCard.scss';

class UserCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmArchive: false,
    };
  }

  /**
   * When a Menu Item is Selected
   */
  onSelectMenuItem = async (name) => {
    const {
      user: {
        _id,
      },
      onArchive,
    } = this.props;

    if (name === 'archive') {
      this.setState({
        showConfirmArchive: true,
      });
    } else if (name === 'unarchive') {
      const response = await API.User.reactivate({
        _id,
      });
      const isSuccess = get(response, '[0].documentId', null);

      if (isSuccess) {
        onArchive(_id);
      }
    }
  };

  /**
   * Confirm Archive Message
   */
  onConfirmArchive = async (e) => {
    e.stopPropagation();

    const {
      user: {
        _id,
      },
      onArchive,
    } = this.props;

    const response = await API.User.deactivate(_id);
    const isSuccess = get(response, '[0].documentId', null);

    if (isSuccess) {
      onArchive(_id);
    }

    this.setState({
      showConfirmArchive: false,
    });
  };

  /**
   * Cancel Archive Message
   */
  onCancelArchive = () => {
    this.setState({
      showConfirmArchive: false,
    });
  };

  toggleCard = () => {
    const {
      id,
      user,
      toggleSelected,
      showUserDetails,
      disabled,
    } = this.props;

    if (!disabled) {
      toggleSelected(id, true);
      showUserDetails(user);
    } else {
      console.warn('Cannot select a disabled card');
    }
  };

  render() {
    const {
      user,
      className = '',
      style,
      selected: isUserSelected,
      slim,
      allowClick,
      allowActions,
    } = this.props;

    const {
      showConfirmArchive,
    } = this.state;

    const userFullname = `${user.fname} ${user.lname}`;
    const userFullTitle = user.title ? user.title : `Employee at ${user.companyName}`;

    const contentHtml = slim ? (
      <div className="card-content-container">
        <div className="content">
          <div className="picture-container">
            {user.imageUrl ? (
              <img src={user.imageUrl} className="profile-picture" alt="profile" />
            ) : (
              <img className="profile-picture" src={defaultUserImage} alt="profile" />
            )}
          </div>
          <div className="user-info">
            <div className="user-name">{userFullname}</div>
            <div className="user-email">
              <VibeIcon
                icon={viEmail}
                color={color.manatee}
                size={16}
              />
              <div className="user-email-text">
                {user.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="card-content-container">
        <div className="content">
          <div className="picture-container">
            {user.imageUrl ? (
              <img src={user.imageUrl} className="profile-picture" alt="profile" />
            ) : (
              <img className="profile-picture" src={defaultUserImage} alt="profile" />
            )}
          </div>
          <div className="user-info">
            <div className="user-name">{userFullname}</div>
            <div className="user-title">{userFullTitle}</div>
          </div>
        </div>

        <div className={`status ${user.active ? 'active' : 'inactive'}`}>
          {user.active ? '• ACTIVE' : '• INACTIVE'}
        </div>
      </div>
    );

    // const isSelected = isUserSelected || selected;
    const isSelected = isUserSelected;
    const menuItems = allowActions
      ? [{ name: user.active ? 'Archive' : 'Unarchive', userCan: 'user.delete' }]
      : [];

    return (
      <div className={`UserCard ${slim ? 'slim' : ''}`}>
        <VibeCard
          className={`card-container ${className}`}
          style={{
            margin: slim
              ? '0 auto'
              : 0,
            ...style,
          }}
          width={350}
          height={slim ? 88 : 110}
          menuItems={menuItems}
          selected={isSelected}
          onClick={this.toggleCard}
          onSelectMenuItem={this.onSelectMenuItem}
          allowClick={allowClick}
        >
          {contentHtml}
        </VibeCard>

        <VibeModal
          show={showConfirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${userFullname}?`}
          confirmProps={{
            text: 'Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );
  }
}

UserCard.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  showUserDetails: PropTypes.func,
  user: PropTypes.shape({
    _id: PropTypes.string,
    /** First Name */
    fname: PropTypes.string,
    /** Last Name */
    lname: PropTypes.string,
    companyName: PropTypes.string,
    active: PropTypes.bool,
    imageUrl: PropTypes.string,
  }).isRequired,
  /** Slim user card */
  slim: PropTypes.bool,
  selected: PropTypes.bool,
  toggleSelected: PropTypes.func,
  allowClick: PropTypes.bool,
  /** Allow actions to the card */
  allowActions: PropTypes.bool,
  /** When a card is archived */
  onArchive: PropTypes.func,
};

UserCard.defaultProps = {
  className: '',
  style: {},
  slim: false,
  selected: false,
  allowClick: false,
  allowActions: false,
  showUserDetails: () => {},
  toggleSelected: () => {},
  onArchive: () => {},
};

export default UserCard;
