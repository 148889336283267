import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  sortBy,
  last,
  uniqueId,
  toInteger,
} from 'lodash';
import {
  API,
  ToastActions,
  Field2 as Field,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeButtonNew,
  VibeIcon,
  viArchive,
  viUnarchive,
  viAdd,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import LoopTemplateItem from './LoopTemplateItem';

function CompanyLoopTemplateEdit({
  className,
  style,
  templateId,
  companyId,
  user,
  queueToast,
}) {
  const [template, setTemplate] = useState({});
  const [confirmArchive, setConfirmArchive] = useState(false);

  const items = sortBy(get(template, 'items', []), 'seq');

  const showArchive = template._id && user.can('visual_loop_template.delete');
  const isArchived = template._id && !template.active;

  const disableSave = !template.name;
  const disableInput = (template._id && !user.can('visual_loop_template.modify'))
    || (!template._id && !user.can('visual_loop_template.create'));

  /**
   * Get the template
   */
  const getTemplate = async () => {
    const response = await API.Company.LoopTemplate.getById({
      _id: templateId,
      companyId,
    });

    // add a unique ID for each item
    response.items.forEach((item, index) => {
      // ensure the sequence for each item isn't missing any in-between (0-n)
      item.seq = index;

      if (!item._id) {
        item._id = uniqueId('item-');
      }
    });

    setTemplate(response);
  };

  /**
   * Get the total number of ads in the loop
   */
  const getAdCount = () => {
    return items.filter(item => item.itemType === 'ad').length;
  };

  /**
   * Get the actual duration (in seconds)
   */
  const getActualDuration = () => {
    return items.reduce((sum, { durationSeconds }) => sum + durationSeconds, 0);
  };

  /**
   * Get the max hourly ad spots for the loop
   */
  const getMaxHourlyAdSpots = () => {
    // how long the entire loop is
    const durationSeconds = getActualDuration();
    // how many times the loop can run in an hour
    const runsPerHour = durationSeconds > 0
      // only calculate if there is a duration
      ? Math.floor(3600 / durationSeconds)
      // results in "Infinity" as a result
      : 0;
    // how many ads in the loop
    const adCount = getAdCount();

    // number of ads * the number of times the loop can run in an hour
    return adCount * runsPerHour;
  };

  /**
   * Update the template item
   */
  const onUpdateItem = (item) => {
    setTemplate({
      ...template,
      items: items.map((i) => {
        if (i._id === item._id) {
          return {
            ...i,
            ...item,
          };
        }

        return i;
      }),
    });
  };

  /**
   * Remove the template item
   */
  const onRemoveItem = (itemId) => {
    setTemplate({
      ...template,
      items: items.filter(i => i._id !== itemId),
    });
  };

  /**
   * Change the item order
   */
  const onChangeItemOrder = (data) => {
    const newItems = [];

    items.forEach((item) => {
      if (item._id !== data.dropItem._id) {
        // ignore the item being dropped (add it before/after the item it was dropped on)
        if (item._id !== data.onItem._id) {
          // not the item dropped on, add the item to the array
          newItems.push(item);
        } else if (item._id === data.onItem._id && data.at === 'above') {
          // dropped on this item (insert before)
          newItems.push(data.dropItem);
          newItems.push(item);
        } else if (item._id === data.onItem._id && data.at === 'below') {
          // dropped on this item (insert after)
          newItems.push(item);
          newItems.push(data.dropItem);
        }
      }
    });

    // add the new sequence numbers to the items
    newItems.forEach((item, index) => {
      item.seq = index;
    });

    setTemplate({
      ...template,
      items: newItems,
    });
  };

  /**
   * User clicks Archive
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * User clicks Unarchive
   */
  const onClickUnarchive = async () => {
    await API.Company.LoopTemplate.reactivate({
      _id: template._id,
      companyId,
    });

    setTemplate({
      ...template,
      active: true,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveVisualLoopTemplate'));
  };

  /**
   * User confirms archiving the object
   */
  const onConfirmArchive = async () => {
    setConfirmArchive(false);
    await API.Company.LoopTemplate.deactivate({
      _id: template._id,
      companyId,
    });

    setTemplate({
      ...template,
      active: false,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveVisualLoopTemplate'));
  };

  /**
   * User cancels archiving the banner
   */
  const onCancelArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * User changes the input box
   */
  const onChange = ({
    target: {
      name,
      value,
    },
  }) => {
    setTemplate({
      ...template,
      [name]: value,
    });
  };

  /**
   * User changes the number input box
   */
  const onChangeNumber = ({
    target: {
      name,
      value,
    },
  }) => {
    setTemplate({
      ...template,
      [name]: toInteger(value),
    });
  };

  /**
   * Create a new item
   */
  const onClickNewItem = () => {
    setTemplate({
      ...template,
      items: [
        ...items,
        {
          _id: uniqueId('item-'),
          name: '',
          itemType: '',
          seq: items.length > 0
            ? last(items).seq + 1
            : 0,
          durationSeconds: 0,
        },
      ],
    });
  };

  /**
   * Save the template
   */
  const onSave = async () => {
    document.dispatchEvent(new Event('onSaveVisualLoopTemplateStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const data = {
      companyId,
      name: template.name,
      plannedDurationSeconds: template.plannedDurationSeconds,
      items: items.map((item) => {
        return {
          name: item.name,
          itemType: item.itemType,
          seq: item.seq,
          durationSeconds: item.durationSeconds,
        };
      }),
    };

    if (template._id) {
      data._id = template._id;
    }

    try {
      const response = template._id
        ? await API.Company.LoopTemplate.update(data)
        : await API.Company.LoopTemplate.create(data);

      const responseType = get(response, '[0].type');

      const success = template._id
        ? responseType === 'VISUALLOOPTEMPLATE.UPDATED'
        : responseType === 'VISUALLOOPTEMPLATE.CREATED';

      if (success) {
        // Successfully saved the object
        const saveMessage = template._id
          ? 'Template Updated!'
          : 'Template Created!';

        setTemplate({
          ...template,
          _id: get(response, '[0].documentId'),
        });

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      document.dispatchEvent(new Event('onSaveVisualLoopTemplate'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveVisualLoopTemplateError'));
    }
  };

  useEffect(() => {
    if (templateId) {
      getTemplate();
    }
  }, [templateId]);

  return (
    <SidePanelContainer
      className={classNames('CompanyLoopTemplateEdit', className)}
      style={style}
    >
      <SidePanelContent>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <Field
              type="text"
              label="Name"
              placeholder="Name"
              name="name"
              value={template.name}
              tabIndex={20}
              disabled={disableInput}
              onChange={onChange}
              required
              autoFocus
            />
          </Grid>

          <Grid xs={12} item>
            <div
              style={{
                fontSize: 14,
                fontWeight: 900,
              }}
            >
              Totals
            </div>
          </Grid>

          <Grid xs={3} item>
            <Field
              type="number"
              label="Planned Duration"
              name="plannedDurationSeconds"
              value={template.plannedDurationSeconds}
              tabIndex={21}
              numberProps={{
                min: 0,
              }}
              disabled={disableInput}
              onChange={onChangeNumber}
            />
          </Grid>

          <Grid xs={3} item>
            <Field
              type="number"
              label="Actual Duration"
              name="actualDurationSeconds"
              // Get total duration from the items
              value={getActualDuration()}
              numberProps={{
                min: 0,
              }}
              disabled
            />
          </Grid>

          <Grid xs={3} item>
            <Field
              type="number"
              label="Number of Ads"
              value={getAdCount()}
              numberProps={{
                min: 0,
              }}
              disabled
            />
          </Grid>

          <Grid xs={3} item>
            <Field
              type="number"
              label="Max Hourly Ad Spots"
              name="maxHourlyAdSpots"
              value={getMaxHourlyAdSpots()}
              numberProps={{
                min: 0,
              }}
              disabled
            />
          </Grid>

          <Grid xs={12} item>
            <div
              style={{
                marginBottom: 4,
                fontSize: 14,
                fontWeight: 900,
              }}
            >
              Loop (drag to re-order)
            </div>
          </Grid>

          <Grid xs={12} item>
            {items.map((item) => (
              <LoopTemplateItem
                key={`item-${item._id}`}
                item={item}
                disableInput={disableInput}
                onUpdate={onUpdateItem}
                onRemove={onRemoveItem}
                onChangeOrder={onChangeItemOrder}
              />
            ))}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 8,
              }}
            >
              <VibeButtonNew
                text="New Item"
                icon={viAdd}
                color={color.violetVibe}
                disabled={disableInput}
                onClick={onClickNewItem}
              />
            </div>
          </Grid>
        </Grid>
      </SidePanelContent>

      <SidePanelFooter>
        <VibeButtonNew
          text="Save Changes"
          color={color.violetVibe}
          loadingEvent="onSaveVisualLoopTemplate"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {template._id && (
          <div>
            {showArchive && !isArchived && (
              <VibeIcon
                icon={viArchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.fireBrick,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Archive"
                color={color.fireBrick}
                size={20}
                onClick={onClickArchive}
              />
            )}

            {showArchive && isArchived && (
              <VibeIcon
                icon={viUnarchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.aquaForest,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Unarchive"
                color={color.aquaForest}
                size={20}
                onClick={onClickUnarchive}
              />
            )}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${template.name}?`}
        confirmProps={{
          text: 'Archive',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCancelArchive}
      />
    </SidePanelContainer>
  );
}

CompanyLoopTemplateEdit.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  templateId: PropTypes.string,
  companyId: PropTypes.string.isRequired,
};

CompanyLoopTemplateEdit.defaultProps = {
  className: '',
  style: {},
  templateId: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLoopTemplateEdit);
