import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import Tooltip from '@mui/material/Tooltip';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import './VibeTooltip.scss';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  // TODO: Style Overrides aren't working
  // components: {
  //   MuiTooltip: {
  //     styleOverrides: {
  //       popper: {
  //         zIndex: 9000,
  //       },
  //     },
  //   },
  // },
});

function VibeTooltip({
  title,
  placement,
  className,
  children,
  enterDelay,
  enterNextDelay,
  leaveDelay,
  arrow,
  followCursor,
}) {
  let useTitle = title;

  if (isArray(title) && title.length > 0) {
    // an array is sent, split each item into a separate line item
    useTitle = title.map(tooltip => (
      <div
        key={`tooltip-${tooltip}`}
        className="tooltip-item"
      >
        {tooltip}
      </div>
    ));
  } else if (isArray(title) && title.length <= 0) {
    // an array is sent but no array items are defined, use an empty string
    useTitle = '';
  }

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        classes={{
          popper: 'VibeTooltipPopper',
          tooltip: classNames('VibeTooltip', className),
        }}
        title={useTitle}
        placement={placement}
        enterDelay={enterDelay}
        enterNextDelay={enterNextDelay >= 0
          ? enterNextDelay
          : enterDelay}
        leaveDelay={leaveDelay}
        arrow={arrow}
        followCursor={followCursor}
      >
        {children}
      </Tooltip>
    </ThemeProvider>
  );
}

VibeTooltip.propTypes = {
  className: PropTypes.string,
  /** Tooltip Text */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  placement: PropTypes.string,
  /** Delay when entering the tooltip */
  enterDelay: PropTypes.number,
  enterNextDelay: PropTypes.number,
  /** Delay when leaving the tooltip */
  leaveDelay: PropTypes.number,
  arrow: PropTypes.bool,
  followCursor: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

VibeTooltip.defaultProps = {
  className: '',
  placement: 'bottom',
  enterDelay: 0,
  enterNextDelay: -1,
  leaveDelay: 0,
  arrow: true,
  followCursor: false,
};

export default VibeTooltip;
