import React from 'react';
import PropTypes from 'prop-types';
import './Gridline.scss';

function Gridline({
  height,
  label,
  style,
}) {
  return (
    <div
      className="Gridline"
      style={{
        height,
        minHeight: height,
        maxHeight: height,
      }}
    >
      <div
        className="label"
        style={style}
      >
        {label}
      </div>

      <div className="line" />
    </div>
  );
}

Gridline.propTypes = {
  height: PropTypes.number.isRequired,
  label: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

Gridline.defaultProps = {
  label: '',
  style: {},
};

export default Gridline;
