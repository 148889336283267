import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
} from 'vibeguide';
import LocationFlag from './LocationFlag';
import ManageAlerts from './ManageAlerts';
import './SupportSidebar.scss';

class SupportSidebar extends PureComponent {
  constructor(props) {
    super(props);

    const {
      type,
    } = this.props;

    this.state = {
      activeSection: type,
      flagEnabled: false,
      activeAlerts: {},
    };
  }

  componentDidUpdate(prevProps) {
    const {
      locationId,
    } = this.props;

    const {
      locationId: prevLocationId,
    } = prevProps;

    if (locationId && locationId !== prevLocationId) {
      this.setState({
        flagEnabled: false,
        activeAlerts: {},
      });
    }
  }

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * When the Flag icon is clicked
   */
  onClickFlag = (value) => {
    this.setState({
      flagEnabled: value,
    });
  };

  /**
   * Set Alert Data
   */
  onSetAlertData = (data) => {
    this.setState({
      activeAlerts: data,
    });
  };

  onSaveAlertConfig = async () => {
    const {
      queueToast,
    } = this.props;
    const {
      locationId,
      activeAlerts,
    } = this.state;

    const updatedData = {
      _id: locationId,
      ...activeAlerts,
    };

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const updateAlertConfig = await API.Location.Alerts.Config.update(updatedData);

    const updateResponse = get(updateAlertConfig, '[0].documentId', null);

    if (!updateResponse) {
      // No new messageBlock was created
      queueToast({
        type: 'error',
        title: 'Error Saving Alert Config',
        timeout: 10,
        allowClose: true,
      });

      console.error('Error Saving Alert Config', updateAlertConfig);
      return;
    }

    queueToast({
      type: 'success',
      title: 'Saved!',
      allowClose: true,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveLocationExcluded'));
  };

  /**
   * Toggle the flag section
   */
  onToggleFlag = () => {
    this.setState({ activeSection: 'flags' });
  };

  /**
   * Toggle the alerts section
   */
  onToggleAlerts = () => {
    this.setState({ activeSection: 'alerts' });
  };

  render() {
    const {
      type,
      locationId,
      canManageSettings,
    } = this.props;

    const {
      flagEnabled,
      activeSection,
      activeAlerts,
    } = this.state;

    return (
      <div className="SupportSidebar">
        {activeSection === 'flags' ? (
          <LocationFlag
            type={type}
            locationId={locationId}
            flagEnabled={flagEnabled}
            canManageAlerts={canManageSettings}
            onClose={this.onClose}
            onToggleAlerts={this.onToggleAlerts}
            onClickFlag={this.onClickFlag}
          />
        ) : (
          <ManageAlerts
            locationId={locationId}
            activeAlerts={activeAlerts}
            onSetAlertData={this.onSetAlertData}
            onClose={this.onClose}
            onToggleFlag={this.onToggleFlag}
            onSaveAlertConfig={this.onSaveAlertConfig}
          />
        )}
      </div>
    );
  }
}

SupportSidebar.propTypes = {
  type: PropTypes.oneOf([
    'alerts',
    'flags',
  ]),
  locationId: PropTypes.string,
  canManageSettings: PropTypes.bool,
};

SupportSidebar.defaultProps = {
  locationId: '',
  type: 'flags',
  canManageSettings: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(null, mapDispatchToProps)(SupportSidebar);
