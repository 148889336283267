// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TokenTable {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
.TokenTable > .header {
  position: absolute;
  top: 8px;
  right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Users/Sidebar/Content/TokenTable/TokenTable.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;AACJ","sourcesContent":[".TokenTable {\r\n  display: flex;\r\n  flex-direction: column;\r\n  font-size: 12px;\r\n\r\n  > .header {\r\n    position: absolute;\r\n    top: 8px;\r\n    right: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
