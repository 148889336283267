import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import classNames from 'classnames';
import TargetTag from '../TargetTag';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAdd from '../../../icons/viAdd';
import viStore from '../../../icons/viStore';
import viClose from '../../../icons/viClose';
import {
  setTargetTagActive,
  updateTargetTag,
  addTargetTags,
  setLocationActiveMenuItem,
} from '../../../actions/TargetTag/TargetTagActions';
import color from '../../../sass/color.scss';
import './PanelLocationList.scss';

class PanelLocationList extends PureComponent {
  constructor(props) {
    super(props);

    const {
      allowCreate,
      allowCopy,
      allowRemove,
      user,
    } = props;

    this.state = {
      showCreate: user.can('targeting_tag.create') && allowCreate,
      showCopy: user.can('targeting_tag.modify') && allowCopy,
      showRemove: user.can('targeting_tag.delete') && allowRemove,
    };
  }

  /**
   * When a target tag is clicked
   */
  onClickTargetTag = (tag) => {
    const {
      setTargetTagActive,
      addTargetTags,
      setLocationActiveMenuItem,
    } = this.props;

    addTargetTags([tag]);
    setTargetTagActive(tag);
    setLocationActiveMenuItem(null);
  };

  /** When a target tag checkbox is changed */
  onChangeSelect = (tagId) => {
    const {
      targetTags,
      updateTargetTag,
    } = this.props;

    const targetTag = find(targetTags, { _id: tagId });

    updateTargetTag({
      _id: tagId,
      selected: !targetTag.selected,
    });
  };

  onShowAddTargetTag = () => {
    const {
      setLocationActiveMenuItem,
    } = this.props;

    setLocationActiveMenuItem('add');
  };

  onCopyToLocations = () => {
    const {
      setLocationActiveMenuItem,
      onCopyToLocations,
    } = this.props;

    setLocationActiveMenuItem('copy');
    onCopyToLocations();
  };

  onShowRemoveTargetTag = () => {
    const {
      setLocationActiveMenuItem,
    } = this.props;

    setLocationActiveMenuItem('remove');
  };

  render() {
    const {
      className,
      locationTags,
      targetTags,
      activeTag,
      allowSelect,
      showAddTags,
      showCopyToLocations,
      showRemoveTags,
    } = this.props;

    const {
      showCreate,
      showCopy,
      showRemove,
    } = this.state;

    return (
      <div
        className={classNames('PanelLocationList', className)}
        onScroll={this.onScroll}
      >
        {showCreate ? (
          <div
            className={classNames('target-tag-action', 'action-new', { active: showAddTags })}
            onClick={this.onShowAddTargetTag}
          >
            <VibeIcon
              icon={viAdd}
              color={color.aquaForest}
              size={16}
            />

            <div className="text">
              Add Tags
            </div>
          </div>
        ) : null}

        {showCopy ? (
          <div
            className={classNames('target-tag-action', 'action-copy', { active: showCopyToLocations })}
            onClick={this.onCopyToLocations}
          >
            <VibeIcon
              icon={viStore}
              color={color.violetVibe}
              size={16}
            />

            <div className="text">
              Copy to Locations
            </div>
          </div>
        ) : null}

        {showRemove ? (
          <div
            className={classNames('target-tag-action', 'action-remove', { active: showRemoveTags })}
            onClick={this.onShowRemoveTargetTag}
          >
            <VibeIcon
              icon={viClose}
              color={color.fireBrick}
              size={16}
            />

            <div className="text">
              Remove Tags
            </div>
          </div>
        ) : null}

        <div className="target-tags">
          {locationTags.map((tag) => {
            const targetTag = find(targetTags, { _id: tag._id });

            return (
              <TargetTag
                key={tag._id}
                className="tag-item"
                tagId={tag._id}
                qualifiedName={tag.qualifiedName}
                active={activeTag._id === tag._id}
                selected={targetTag.selected}
                allowSelect={allowSelect}
                onClick={this.onClickTargetTag}
                onChangeSelect={this.onChangeSelect}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

PanelLocationList.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Location Tags */
  locationTags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    qualifiedName: PropTypes.string,
  })),
  /** Allow target tag creation */
  allowCreate: PropTypes.bool,
  /** Allow target tag copy to locations */
  allowCopy: PropTypes.bool,
  /** Allow target tag remove from location */
  allowRemove: PropTypes.bool,
  /** When Copy to Locations is selected */
  onCopyToLocations: PropTypes.func,
};

PanelLocationList.defaultProps = {
  className: '',
  locationTags: [],
  allowCreate: false,
  allowCopy: false,
  allowRemove: false,
  onCopyToLocations: () => {},
};

function mapStateToProps(state) {
  const {
    targetTag: {
      activeTag,
      activeLocationMenuItem,
    },
  } = state;

  const allowSelect = activeLocationMenuItem === 'copy'
    || activeLocationMenuItem === 'remove';

  return {
    user: state.login.user,
    showAddTags: activeLocationMenuItem === 'add',
    showCopyToLocations: activeLocationMenuItem === 'copy',
    showRemoveTags: activeLocationMenuItem === 'remove',
    targetTags: state.targetTag.tags,
    activeTag,
    allowSelect,
  };
}

const mapDispatchToProps = {
  setTargetTagActive,
  updateTargetTag,
  addTargetTags,
  setLocationActiveMenuItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelLocationList);
