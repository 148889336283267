// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPasswordForm .cancel-btn {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Login/ForgotPasswordForm.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;AADJ","sourcesContent":[".ForgotPasswordForm {\n\n  .cancel-btn {\n    margin-top: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
