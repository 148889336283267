import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeCheckbox from '../VibeCheckbox/VibeCheckbox';
import color from '../../sass/color.scss';
import './VibeCardNew.scss';

function VibeCardNew({
  className,
  style,
  name,
  selectable,
  selected,
  children,
  onSelect,
}) {
  return (
    <div
      className={classNames('VibeCardNew', className, {
        selectable,
        selected,
      })}
      style={style}
      onClick={selectable
        ? onSelect
        : null}
    >
      <div className="content-container">
        {children}
      </div>

      <div className="actions">
        {selectable && (
          <VibeCheckbox
            size={14}
            color={color.violetVibe}
            name={name}
            checked={selected}
            // onChange={onSelect}
          />
        )}
      </div>
    </div>
  );
}

VibeCardNew.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  /** Unique identifier when  */
  name: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onSelect: PropTypes.func,
};

VibeCardNew.defaultProps = {
  className: '',
  style: {},
  name: '',
  selectable: false,
  selected: false,
  onSelect: () => {},
};

export default VibeCardNew;
