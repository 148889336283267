import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeIcon,
  viGear,
  viDownload,
  color,
} from 'vibeguide';
import './AlertCount.scss';

class AlertCount extends PureComponent {
  onClickExport = (e) => {
    e.preventDefault();

    const {
      title,
      onClickExport,
    } = this.props;

    onClickExport(title);
  };

  onClickSettings = (e) => {
    e.preventDefault();

    const {
      title,
      onClickSettings,
    } = this.props;

    onClickSettings(title);
  };

  render() {
    const {
      className,
      title,
      count,
      showSettings,
    } = this.props;

    return (
      <div className={classNames('AlertCount', className)}>
        <div className="header">
          <div className="title">
            {title}
          </div>

          {showSettings ? (
            <div className="icon">
              <VibeIcon
                type="button"
                icon={viGear}
                color={color.manatee}
                size={16}
                buttonProps={{
                  size: 24,
                  borderColor: color.whiteSmoke,
                }}
                onClick={this.onClickSettings}
              />
            </div>
          ) : null}

          <div className="icon">
            <VibeIcon
              type="button"
              icon={viDownload}
              color={color.manatee}
              size={16}
              buttonProps={{
                size: 24,
                borderColor: color.whiteSmoke,
              }}
              onClick={this.onClickExport}
            />
          </div>
        </div>

        <div
          className="content"
          style={{
            color: count > 0
              ? color.fireBrick
              : color.whiteSmoke,
          }}
        >
          {count >= 0
            ? count
            : '?'}
        </div>
      </div>
    );
  }
}

AlertCount.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  showSettings: PropTypes.bool,
  onClickSettings: PropTypes.func,
  onClickExport: PropTypes.func,
};

AlertCount.defaultProps = {
  className: '',
  showSettings: false,
  onClickSettings: () => {},
  onClickExport: () => {},
};

export default AlertCount;
