import React from 'react';

const viChangeRequest = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M20 2c1.1 0 2 .9 2 2v18l-4-4H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2zm-7.111 5.378L8 12.31V14h1.689l4.889-4.933-1.69-1.69zm1.333-1.245l-.8.8 1.69 1.69.755-.845c.177-.178.177-.445 0-.622l-1.023-1.023c-.177-.177-.444-.177-.622 0z" />
    </g>
  </svg>
);

export default viChangeRequest;
