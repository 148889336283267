import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeCheckbox,
  VibeSplash,
  VibeButton,
  Field2 as Field,
  color,
} from 'vibeguide';
import AlertEmailSettings from './AlertEmailSettings';

class AlertBatteryConfig extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      configFetched: false,
      enableBatteryBelow: false,
      batteryPercentage: 20,
      emailFlaggedLocations: false,
      recipients: [],
      suppressNotificationMinutes: 0,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  /**
   * When the email settings are changed
   */
  onUpdateEmailSettings = (data) => {
    this.setState(data);
  };

  onChangeSelect = ({
    name,
    checked,
  }) => {
    this.setState({
      [name]: checked,
    });
  };

  onChangePercentage = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState({
      [name]: parseInt(value, 10),
    });
  };

  getConfig = async () => {
    const config = await API.Location.Alerts.Battery.get();

    this.setState({
      configFetched: true,
      enableBatteryBelow: get(config, 'low_charge.active', false),
      batteryPercentage: get(config, 'low_charge.threshold', '?'),
      emailFlaggedLocations: get(config, 'emailNotification.emailFlaggedLocations', false),
      recipients: get(config, 'emailNotification.recipients', []),
      suppressNotificationMinutes: get(config, 'suppressNotificationMinutes', 0),
    });
  };

  save = async () => {
    const {
      onToast,
    } = this.props;

    const {
      enableBatteryBelow,
      batteryPercentage,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
    } = this.state;

    const data = {
      low_charge: {
        active: enableBatteryBelow,
        threshold: batteryPercentage,
      },
      emailNotification: {
        emailFlaggedLocations,
        recipients,
      },
      suppressNotificationMinutes,
    };

    onToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const saveResponse = await API.Location.Alerts.Battery.update(data);

    if (get(saveResponse, '[0].type') !== 'SYSTEMSETTING.UPDATED') {
      onToast({
        type: 'error',
        title: 'Error updating setting',
        timeout: 10,
        allowClose: true,
      });

      return;
    }

    onToast({
      type: 'success',
      title: 'Saved!',
      allowClose: true,
      delay: 500,
    });
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      configFetched,
      enableBatteryBelow,
      batteryPercentage,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
    } = this.state;

    if (!configFetched) {
      return (
        <VibeSplash
          type="loading"
          size={64}
          style={{
            color: color.violetVibe,
          }}
        />
      );
    }

    return (
      <SidePanelContainer className={classNames('AlertSidebarConfig', 'AlertBatteryConfig', className)}>
        <SidePanelContent>
          <div className="section">
            <div className="section-title">
              Send alert when:
            </div>

            <div className="section-content">
              <div className="setting">
                <VibeCheckbox
                  className="setting-check"
                  name="enableBatteryBelow"
                  size={16}
                  color={color.aquaForest}
                  checked={enableBatteryBelow}
                  onChange={this.onChangeSelect}
                />

                <div className="text">
                  Battery is below:
                </div>

                <Field
                  className="setting-input"
                  type="number"
                  name="batteryPercentage"
                  value={batteryPercentage}
                  numberProps={{
                    min: 0,
                    max: 100,
                  }}
                  onChange={this.onChangePercentage}
                  disabled={!enableBatteryBelow}
                />
              </div>
            </div>
          </div>

          <AlertEmailSettings
            emailFlaggedLocations={emailFlaggedLocations}
            recipients={recipients}
            suppressNotificationMinutes={suppressNotificationMinutes}
            onUpdate={this.onUpdateEmailSettings}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            btnColor="green"
            textColor="white"
            onClick={this.save}
          />
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

AlertBatteryConfig.propTypes = {
  className: PropTypes.string,
  onToast: PropTypes.func,
};

AlertBatteryConfig.defaultProps = {
  className: '',
  onToast: () => {},
};

export default AlertBatteryConfig;
