import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setDrag,
  resetDrag,
} from '../../actions/Global/GlobalActions';
import {
  drawerClose,
} from '../../actions/ContentDrawer/ContentDrawerActions';

class Draggable extends PureComponent {
  constructor(props) {
    super(props);

    this.dragRef = React.createRef();
  }

  onDragStart = (e) => {
    const {
      dataTransfer,
    } = e;

    const {
      setDrag,
      dragType,
      data,
      ghostOptions,
    } = this.props;

    const {
      dragRef: {
        current: dragRef,
      },
    } = this;

    // Safari 3.0+ "[object HTMLElementConstructor]"
    // eslint-disable-next-line wrap-iife, func-names, dot-notation, no-undef, max-len
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    // Set drag data
    dataTransfer.setData('text/plain', JSON.stringify(data));

    const dragRefRect = dragRef.getBoundingClientRect();
    const crt = dragRef.cloneNode(true);

    const dragWidth = ghostOptions.width || dragRefRect.width;

    crt.style.position = 'absolute';
    crt.style.top = `${dragRefRect.top}px`;
    crt.style.left = `${(dragRefRect.width * -1) - 20}px`;
    crt.style.bottom = 'auto';
    crt.style.height = ghostOptions.height ? `${ghostOptions.height}px` : `${dragRefRect.height}px`;
    crt.style.width = `${dragWidth}px`;
    crt.style.zIndex = '2000';
    crt.classList.add('drag-ghost');
    // Remove interruption class to prevent overlap check on these
    crt.classList.remove('item-interruption');

    document.body.appendChild(crt);
    // Set the drag image to follow the drop line
    const dragImage = isSafari ? dragRef : crt;

    dataTransfer.setDragImage(dragImage, dragWidth / 2, 10);

    setDrag({
      dragging: true,
      contentDrawer: true,
      dragIds: [data._id],
      dragType,
    });
  };

  onDragEnd = () => {
    const {
      resetDrag,
      closeOnDrag,
      drawerClose,
    } = this.props;

    if (closeOnDrag) {
      // Drawer should be closed when dropping onto a content area instead of re-opening
      drawerClose();
    }

    resetDrag();
  };

  render() {
    const {
      children,
      allowDrag,
    } = this.props;

    return (
      <div
        className="Draggable"
        ref={this.dragRef}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        draggable={allowDrag}
      >
        {children}
      </div>
    );
  }
}

Draggable.propTypes = {
  dragType: PropTypes.oneOf([
    'Message',
    'Message Block',
    'Playlist',
    'Mix',
    'Baseline',
    'Event',
    'Ad Block',
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** Drag ghost options */
  ghostOptions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  allowDrag: PropTypes.bool,
  /** Close the drawer when dragging from the content drawer */
  closeOnDrag: PropTypes.bool,
};

Draggable.defaultProps = {
  data: '',
  ghostOptions: {},
  allowDrag: false,
  closeOnDrag: false,
};

const mapDispatchToProps = {
  setDrag,
  resetDrag,
  drawerClose,
};

export default connect(null, mapDispatchToProps)(Draggable);
