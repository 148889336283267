import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './LinkCell.scss';

function LinkCell({
  className,
  background,
  color,
  value,
  link,
  onClick,
}) {
  return (
    <div
      className={classNames('LinkCell', className)}
      style={{
        backgroundColor: background,
        color,
      }}
      onClick={onClick}
    >
      <a href={link} download>
        {value}
      </a>
    </div>
  );
}

LinkCell.defaultProps = {
  className: '',
  value: '',
  background: 'rgba(255, 255, 255, 0.16)',
  color: '#959aad',
  onClick: () => {},
  link: null,
};

LinkCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default LinkCell;
