import moment from 'moment';
import Http from '../Http';

const baseUrl = '/static';

export default {
  getVersion: () => {
    const timestamp = moment().format('x');
    return Http.get(`${baseUrl}/version.json`, {
      timestamp,
      silent: true,
    });
  },
};
