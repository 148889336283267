import React from 'react';
import PropTypes from 'prop-types';
import {
  TimeUtil,
} from 'vibeguide';
import './EventItem.scss';

function EventItem({
  event,
}) {
  const {
    color,
    title,
    startTime,
    stopTime,
  } = event;

  const itemStart = TimeUtil.convertToTwelveHour({
    time: startTime,
    short: true,
  });

  const itemStop = TimeUtil.convertToTwelveHour({
    time: stopTime,
    short: true,
  });

  return (
    <div
      className="EventItem"
      style={{
        border: `1px solid ${color}`,
        borderRadius: 4,
        boxSizing: 'border-box',
      }}
    >
      <div className="time">
        {itemStart} - {itemStop}
      </div>

      <div className="title">
        {title}
      </div>
    </div>
  );
}

EventItem.propTypes = {
  event: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

EventItem.defaultProps = {
  event: {},
};

export default EventItem;
