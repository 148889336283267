import React from 'react';

const viCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M8.6 16.1l-4.2-4.2L3 13.3l5.6 5.6 12-12-1.4-1.4z" />
    </g>
  </svg>
);

export default viCheck;
