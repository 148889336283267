import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  isFunction,
} from 'lodash';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import './VibeButton.scss';

class VibeButton extends PureComponent {
  constructor(props) {
    super(props);

    const {
      loadingEvent,
    } = props;

    this.loadingTimeout = null;

    this.state = {
      loading: false,
    };

    if (loadingEvent) {
      document.addEventListener(`${loadingEvent}Start`, this.startLoading);
      document.addEventListener(`${loadingEvent}Error`, this.stopLoading);
      document.addEventListener(loadingEvent, this.stopLoading);
    }
  }

  componentWillUnmount() {
    const {
      loadingEvent,
    } = this.props;

    if (loadingEvent) {
      clearTimeout(this.loadingTimeout);
      document.removeEventListener(`${loadingEvent}Start`, this.startLoading);
      document.removeEventListener(`${loadingEvent}Error`, this.stopLoading);
      document.removeEventListener(loadingEvent, this.stopLoading);
    }
  }

  handleClick = (e) => {
    const {
      name,
      disabled,
      loading,
      onClick,
    } = this.props;

    if (disabled || loading) {
      return;
    }

    if (isFunction(onClick)) {
      onClick(e, name);
    }
  };

  startLoading = () => {
    this.setState({
      loading: true,
    });
  };

  stopLoading = () => {
    this.loadingTimeout = setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  render() {
    const {
      text,
      variant,
      size,
      id,
      className,
      style,
      btnColor,
      btnLink,
      textColor,
      textStyle,
      icon,
      iconPlacement,
      tooltip,
      tooltipProps,
      disabled,
    } = this.props;

    const {
      loading,
    } = this.state;

    const isDisabled = disabled || loading;

    const buttonHtml = (
      <Button
        id={!btnLink
          ? id
          : null}
        variant={variant}
        className={classNames(
          'VibeButton',
          `btn-${size}`,
          `btn-${variant === 'outlined'
            ? `outline-${btnColor}`
            : btnColor}`,
          `text-${textColor}`,
          className,
          {
            disabled: isDisabled,
          })}
        style={style}
        onClick={this.handleClick}
        disabled={isDisabled}
      >
        <div className="btn-label">
          {icon && iconPlacement === 'left' ? (
            <span className={classNames('icon', 'left', { 'icon-sm': (size === 'sm' || size === 'xs') })}>
              {!loading
                ? icon
                : (
                  <CircularProgress
                    color="inherit"
                    size={16}
                  />
                )}
            </span>
          ) : null}

          {!icon && loading ? (
            <span className={classNames('icon', 'left', { 'icon-sm': (size === 'sm' || size === 'xs') })}>
              <CircularProgress
                color="inherit"
                size={16}
              />
            </span>
          ) : null}

          <span
            className={classNames('text', { 'text-sm': (size === 'sm' || size === 'xs') })}
            style={textStyle}
          >
            {text}
          </span>

          {icon && iconPlacement === 'right' ? (
            <span className={classNames('icon', 'right', { 'icon-sm': (size === 'sm' || size === 'xs') })}>
              {!loading
                ? icon
                : (
                  <CircularProgress
                    color="inherit"
                    size={size}
                  />
                )}
            </span>
          ) : null}
        </div>
      </Button>
    );

    const buttonLinkHtml = btnLink ? (
      <Link
        id={id}
        to={btnLink}
      >
        {buttonHtml}
      </Link>
    ) : buttonHtml;

    const buttonLinkTooltipHtml = tooltip ? (
      <VibeTooltip
        title={tooltip}
        placement={tooltipProps.placement}
        className={tooltipProps.className}
      >
        <div>
          {buttonLinkHtml}
        </div>
      </VibeTooltip>
    ) : buttonLinkHtml;

    return buttonLinkTooltipHtml;
  }
}

VibeButton.propTypes = {
  /* Custom Classes */
  className: PropTypes.string,
  /** Element ID */
  id: PropTypes.string,
  /** Button Name for onClick */
  name: PropTypes.string,
  /* Button Text */
  text: PropTypes.string.isRequired,
  /** Button variant */
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
  ]),
  /* Button size */
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'lg',
    'block',
  ]),
  /** Change the icon to a loading spinner when actions are performed */
  loadingEvent: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /* Disable the button from being clicked */
  disabled: PropTypes.bool,
  /** Link for button */
  btnLink: PropTypes.string,
  /* Color of the button background */
  btnColor: PropTypes.oneOf([
    'green',
    'purple',
    'red',
    'cosmic',
    'white',
    'flamingo',
    'manatee',
    'transparent',
    'boxred',
    'boxpink',
    'boxorange',
    'boxyellow',
    'boxgreen',
    'boxdarkgreen',
    'boxblue',
    'boxdarkblue',
    'boxpurple',
    'boxdarkpurple',
  ]),
  /* Color of the button text */
  textColor: PropTypes.string,
  /** Custom style for text */
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /* Icon Element */
  icon: PropTypes.element,
  /* Icon location */
  iconPlacement: PropTypes.oneOf([
    'left',
    'right',
  ]),
  /** Tooltip text */
  tooltip: PropTypes.string,
  /** Tooltip props */
  tooltipProps: PropTypes.shape({
    className: PropTypes.string,
    placement: PropTypes.string,
  }),
  /* When button is clicked */
  onClick: PropTypes.func,
};

VibeButton.defaultProps = {
  className: '',
  id: '',
  name: null,
  variant: 'contained',
  size: 'lg',
  loadingEvent: null,
  style: {},
  disabled: false,
  btnLink: null,
  btnColor: 'green',
  textColor: 'white',
  textStyle: {},
  icon: null,
  iconPlacement: 'left',
  tooltip: null,
  tooltipProps: {
    className: '',
    placement: 'bottom',
  },
  onClick: null,
};

export default VibeButton;
