import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  API,
  ToastActions,
  Field2 as Field,
  LocationHelper,
  VibeModal,
  color,
} from 'vibeguide';
import CircularProgress from '@mui/material/CircularProgress';
import TargetCompany from './TargetCompany';

function AdProgramCopyPreview({
  className,
  show,
  currTime,
  locationId,
  adProgramId,
  locations,
  onClose,
  onSuccess,
  queueToast,
}) {
  const [effectiveDate, setEffectiveDate] = useState('');
  const [targets, setTargets] = useState([]);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const onChangeEffectiveDate = (date) => {
    setEffectiveDate(date);
  };

  /**
   * When the targets are updated
   */
  const onUpdateTarget = (data) => {
    setTargets(targets.map((target) => {
      if (target._id === data.companyId) {
        return {
          ...target,
          locations: target.locations.map((location) => {
            if (location._id === data.locationId) {
              return {
                ...location,
                effectiveDate: data.effectiveDate,
              };
            }

            return location;
          }),
        };
      }

      return target;
    }));
  };

  /**
   * When a target is removed
   */
  const onRemoveTarget = ({
    companyId,
    locationId,
  }) => {
    const newTargets = targets.filter((target) => {
      if (target._id === companyId) {
        // remove the location from this target (company)
        target.locations = target.locations.filter((location) => {
          if (location._id === locationId) {
            return false;
          }

          return true;
        });

        if (target.locations.length <= 0) {
          // no more locations for this company, remove entire target (company)
          return false;
        }
      }

      return true;
    });

    setTargets(newTargets);
  };

  /**
   * When the copy button is pressed
   */
  const onConfirmCopy = async () => {
    // build the object to send to copy the ad program
    const copyTargets = {};

    targets.forEach((target) => {
      copyTargets[target._id] = {};

      target.locations.forEach((location) => {
        // only copy locations with an effective date set
        if (location.effectiveDate || location.earliestEffectiveDate) {
          copyTargets[target._id][location._id] = {
            effective: location.effectiveDate || location.earliestEffectiveDate,
          };
        }
      });
    });

    setEffectiveDate('');
    onClose();

    queueToast({
      type: 'info',
      title: 'Copying...',
      allowClose: true,
    });

    try {
      const response = await API.Location.AdPrograms.copyApply({
        locationId,
        adProgramId,
        targets: copyTargets,
      });

      const success = get(response, '[0].type') === 'LOCATIONADPROGRAM.UPDATED' || 'LOCATIONADPROGRAM.CREATED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Copied!',
          allowClose: true,
        });

        // close the copy ad program dialog
        onSuccess();
      } else {
        console.error('Error copying ad program to API', response);

        queueToast({
          type: 'error',
          title: 'Error Copying Ad Program',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error copying ad program', err);

      queueToast({
        type: 'error',
        title: 'Error Copying Ad Program',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  /**
   * When the copy is cancelled
   */
  const onCancelCopy = () => {
    setEffectiveDate('');
    onClose();
  };

  /**
   * Get the preview of the copy
   */
  const getCopyPreview = async () => {
    const targets = LocationHelper.group(locations);
    setLoadingPreview(true);

    try {
      const response = await API.Location.AdPrograms.copyPreview({
        locationId,
        adProgramId,
        effective: effectiveDate,
        targets,
      });

      setTargets(response.targets);
      setLoadingPreview(false);
    } catch (err) {
      // error previewing copy action
      console.error('Preview Copy Error', err);
      setEffectiveDate('');
      setLoadingPreview(false);
      onClose();
    }
  };

  /**
   * Whenever the effective date is changed, get a preview of the copy
   */
  useEffect(() => {
    // reset the targets
    setTargets([]);

    // No date is selected, or invalid date, skipping
    if (!effectiveDate || (effectiveDate && effectiveDate.length !== 10)) {
      return;
    }

    getCopyPreview();
  }, [effectiveDate]);

  return (
    <VibeModal
      className={classNames('AdProgramCopyPreview', className)}
      type="confirm"
      maxWidth="md"
      fullWidth
      show={show}
      confirmProps={{
        text: loadingPreview
          ? (
            <CircularProgress
              color="inherit"
              size={20}
            />
          )
          : 'Copy Ad Program',
        color: color.aquaForest,
        disabled: targets.length <= 0,
      }}
      cancelProps={{
        text: 'Cancel',
        color: color.manatee,
      }}
      title="Copy Ad Program Preview"
      onConfirm={onConfirmCopy}
      onClose={onCancelCopy}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Field
          type="datepicker"
          label="Effective Date"
          placeholder="YYYY-MM-DD"
          value={effectiveDate}
          dateProps={{
            format: 'YYYY-MM-DD',
            minDate: currTime.clone().add(1, 'day').toDate(),
          }}
          rootStyle={{
            width: 250,
          }}
          onChange={onChangeEffectiveDate}
          required
        />
      </div>

      {targets.length > 0 && (
        <div
          className="preview-locations"
          style={{
            marginTop: 16,
          }}
        >
          {targets.map((target) => (
            <TargetCompany
              key={`target-${target._id}`}
              companyId={target._id}
              name={target.name}
              locations={target.locations}
              onRemoveLocation={onRemoveTarget}
              onUpdate={onUpdateTarget}
            />
          ))}
        </div>
      )}
    </VibeModal>
  );
}

AdProgramCopyPreview.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  currTime: PropTypes.instanceOf(moment).isRequired,
  locationId: PropTypes.string.isRequired,
  adProgramId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  /** Successfully copied the ad program */
  onSuccess: PropTypes.func,
};

AdProgramCopyPreview.defaultProps = {
  className: '',
  locations: [],
  onClose: () => {},
  onSuccess: () => {},
};

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(null, mapDispatchToProps)(AdProgramCopyPreview);
