import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Slider,
} from '@mui/material';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import './ProgressBar.scss';

class ProgressBar extends PureComponent {
  renderTooltip = (props) => {
    const {
      // open,
      // value,
      children,
    } = props;

    const {
      currentDuration,
    } = this.props;

    return (
      <VibeTooltip
        placement="top"
        title={currentDuration}
      >
        {children}
      </VibeTooltip>
    );
  };

  render() {
    const {
      progress,
      // color,
      className,
      min,
      max,
      onChange,
    } = this.props;

    return (
      <Slider
        className={classNames('ProgressBar', className)}
        classes={{
          rail: 'slider-rail',
          track: 'slider-track',
          thumb: 'slider-thumb',
        }}
        components={{
          ValueLabel: this.renderTooltip,
        }}
        value={progress}
        min={min}
        max={max}
        onChange={onChange}
        aria-label="custom thumb label"
      />
    );
  }
}

ProgressBar.propTypes = {
  /* Slider Progress */
  progress: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  currentDuration: PropTypes.string,
  /* Slider Color */
  // color: PropTypes.string,
  /* Slider Height */
  // height: PropTypes.number,
};

ProgressBar.defaultProps = {
  progress: 0,
  min: 0,
  max: 100,
  currentDuration: '',
  // color: vibeColor.aquaForest,
  // height: 8,
};

export default ProgressBar;
