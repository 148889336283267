import {
  uniqueId,
  forEach,
} from 'lodash';
import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/companies`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: async (id, options) => {
    options = addVersion('7.14.0', options);

    const company = await Http.get(`${baseUrl}/${id}`, options);

    /**
     * Add a Unique ID to each Ad Provider Source
     * Used when the source is modified
     */
    if (company.adProviderConfig) {
      forEach(company.adProviderConfig, (config) => {
        forEach(config, (source) => {
          if (!source._id) {
            source._id = uniqueId('source-');
          }
        });
      });
    }

    return company;
  },
  getLocations: (data, options) => Http.post(`${baseUrl}/${data._id}/locations`, data, options),
  getUsers: (data) => Http.post(`${baseUrl}/${data._id}/users`, data),
  getMessageConfig: (data, options) => Http.get(`${baseUrl}/${data._id}/messagingconfig`, options),
  create: data => Http.post(baseUrl, data),
  update: (id, data, options) => {
    options = addVersion('7.4.0', options);
    return Http.put(`${baseUrl}/${id}`, data, options);
  },
  uploadImage: (id, image, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/image`, image, 'image', onProgress,
  ),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (companies) => Http.delete(baseUrl, {
    companies,
  }),

  /**
   * Banners
   */
  Banner: {
    list: (data, options) => Http.post(`${baseUrl}/${data.companyId}/banners/search`, data, options),
    update: (data) => Http.put(`${baseUrl}/${data.companyId}/banners/${data._id}`, data),
    create: (data) => Http.post(`${baseUrl}/${data.companyId}/banners`, data),
    reactivate: (data, options) => Http.post(`${baseUrl}/${data.companyId}/banners/${data._id}`, data, options),
    deactivate: (data) => Http.delete(`${baseUrl}/${data.companyId}/banners/${data._id}`),
  },

  /**
   * Categories
   */
  Category: {
    list: (data, options) => Http.post(`${baseUrl}/categories/search`, data, options),
    update: (data) => Http.put(`${baseUrl}/categories/${data._id}`, data),
    create: (data) => Http.post(`${baseUrl}/categories`, data),
    reactivate: (data, options) => Http.post(`${baseUrl}/categories/${data._id}`, data, options),
    deactivate: (id) => Http.delete(`${baseUrl}/categories/${id}`),
  },

  /**
   * Layout Templates
   */
  LayoutTemplate: {
    list: (data, options) => Http.post(`${baseUrl}/${data.companyId}/visuallayouttemplates/search`, data, options),
    getById: (data, options) => {
      return Http.get(`${baseUrl}/${data.companyId}/visuallayouttemplates/${data._id}`, options);
    },
    create: (data, options) => Http.post(`${baseUrl}/${data.companyId}/visuallayouttemplates`, data, options),
    update: (data, options) => {
      return Http.put(`${baseUrl}/${data.companyId}/visuallayouttemplates/${data._id}`, data, options);
    },
    reactivate: (data, options) => {
      return Http.post(`${baseUrl}/${data.companyId}/visuallayouttemplates/${data._id}`, data, options);
    },
    deactivate: (data) => Http.delete(`${baseUrl}/${data.companyId}/visuallayouttemplates/${data._id}`),
  },

  /**
   * Loop Templates
   */
  LoopTemplate: {
    list: (data, options) => Http.post(`${baseUrl}/${data.companyId}/visuallooptemplates/search`, data, options),
    getById: (data, options) => {
      return Http.get(`${baseUrl}/${data.companyId}/visuallooptemplates/${data._id}`, options);
    },
    create: (data, options) => Http.post(`${baseUrl}/${data.companyId}/visuallooptemplates`, data, options),
    update: (data, options) => {
      return Http.put(`${baseUrl}/${data.companyId}/visuallooptemplates/${data._id}`, data, options);
    },
    reactivate: (data, options) => {
      return Http.post(`${baseUrl}/${data.companyId}/visuallooptemplates/${data._id}`, data, options);
    },
    deactivate: (data) => Http.delete(`${baseUrl}/${data.companyId}/visuallooptemplates/${data._id}`),
  },
};
