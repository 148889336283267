import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  viClose,
  color,
  VibeIcon,
  VibeButton,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Field2 as Field,
} from 'vibeguide';
import { Grid } from '@mui/material';
import './AccountType.scss';

function AccountType({
  accountType,
  user,
  onUpdate,
  onClose,
}) {
  const [type, setType] = useState('');

  const onChangeAccountType = (accountType) => {
    setType(accountType);
  };

  const onNext = () => {
    onUpdate(type);
  };

  const disableNext = !type;

  return (
    <SidePanelContainer className="AccountType">
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.dimGray}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="details-header">
          <div className="title">
            New Account
          </div>
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <div className="content-header">
          <h1 className="title">
            Account Type
          </h1>
          <p>
            Select Account Type
          </p>
        </div>
        <Grid container>
          {user.can('user.create') && (
            <Grid xs={12} item>
              <div className="type-row">
                <Field
                  className="custom-radio"
                  type="radio"
                  label={accountType.userAccount.label}
                  value={accountType.userAccount.value}
                  radioProps={{
                    description: accountType.userAccount.description,
                    icon: accountType.userAccount.icon,
                    selected: type === 'userAccount',
                  }}
                  onClick={onChangeAccountType}
                />
              </div>
            </Grid>
          )}

          {user.can('partner_user.create') && (
            <Grid xs={12} item>
              <div className="type-row">
                <Field
                  className="custom-radio"
                  type="radio"
                  label={accountType.partnerAccount.label}
                  value={accountType.partnerAccount.value}
                  radioProps={{
                    description: accountType.partnerAccount.description,
                    icon: accountType.partnerAccount.icon,
                    selected: type === 'partnerAccount',
                  }}
                  onClick={onChangeAccountType}
                />
              </div>
            </Grid>
          )}

          {user.can('integration_user.create') && (
            <Grid xs={12} item>
              <div className="type-row">
                <Field
                  className="custom-radio"
                  type="radio"
                  label={accountType.systemAccount.label}
                  value={accountType.systemAccount.value}
                  radioProps={{
                    description: accountType.systemAccount.description,
                    icon: accountType.systemAccount.icon,
                    selected: type === 'systemAccount',
                  }}
                  onClick={onChangeAccountType}
                />
              </div>
            </Grid>
          )}

        </Grid>
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButton
          className="btn-save"
          text="Next"
          btnColor="purple"
          textColor="white"
          loadingEvent="onSaveCompany"
          disabled={disableNext}
          onClick={onNext}
        />
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

AccountType.propTypes = {
  accountType: PropTypes.shape({
    userAccount: PropTypes.object,
    systemAccount: PropTypes.object,
    playerAccount: PropTypes.object,
  }),
  user: PropTypes.object,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

AccountType.defaultProps = {
  accountType: {},
  user: {},
  onUpdate: () => {},
  onClose: () => {},
};

export default AccountType;
