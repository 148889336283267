import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  find,
} from 'lodash';
import {
  ImportLocationsActions,
  Field2 as Field,
  UploadImage,
  VibeButton,
  VibeIcon,
  API,
  viCircleOne,
  color,
} from 'vibeguide';
import './ImportBulkInformation.scss';

class ImportBulkInformation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      permissionSets: [],
    };
  }

  componentDidMount() {
    this.getPermissionSets();
  }

  onDrop = (file) => {
    const {
      setBulkImage,
    } = this.props;

    setBulkImage(file);
    this.resetConfirmation();
  };

  /**
   * When the user account permission set is changed
   */
  onChangeUserAccountPermissionSet = (e) => {
    const {
      setUserAccountPermissionSet,
    } = this.props;

    const permissionSet = this.getPermissionSet(e);
    setUserAccountPermissionSet({
      _id: permissionSet.value,
      name: permissionSet.label,
    });

    this.resetConfirmation();
  };

  onChangeTag = (data) => {
    const {
      setTags,
    } = this.props;

    setTags(data.tags);
    this.resetConfirmation();
  };

  getPermissionSets = async () => {
    const permissionSetList = await API.PermissionSet.list();
    const permissionSets = [{
      value: '',
      label: '',
    }];

    permissionSetList.forEach((permissionSet) => {
      if (permissionSet.active) {
        // Only add active permission sets to the select view
        permissionSets.push({
          value: permissionSet._id,
          label: permissionSet.name,
        });
      }
    });

    this.setState({
      permissionSets,
    });
  };

  /**
   * Get the permission set from the saved permission sets when selected from the dropdown
   */
  getPermissionSet = (e) => {
    const {
      permissionSets,
    } = this.state;

    const {
      target: {
        value,
      },
    } = e;

    const permissionSet = find(permissionSets, { value });

    if (!permissionSet) {
      console.error('No permission set found', value);
    }

    return permissionSet;
  };

  setCompany = ({
    id,
    value,
  }) => {
    const {
      companyId,
      setCompany,
    } = this.props;

    setCompany({
      companyId: id,
      companyName: value,
    });

    if (id !== undefined && id !== companyId) {
      // New Company was set
      this.resetConfirmation();
    }
  };

  /**
   * Reset Bulk Information confirmation when changes are made
   */
  resetConfirmation = () => {
    const {
      setBulkInformationConfirmed,
    } = this.props;

    setBulkInformationConfirmed(false);
  };

  /**
   * When the bulk information is confirmed
   */
  confirm = () => {
    const {
      createImportJob,
    } = this.props;

    createImportJob();
  };

  render() {
    const {
      user,
      companyId,
      companyName,
      userAccountPermissionSet,
      tags,
      confirmed,
      validationResult,
    } = this.props;

    const {
      permissionSets,
    } = this.state;

    const optUserAccountPermissionSetId = find(validationResult.options, { name: 'userAccountPermissionSetId' }) || {
      valid: true,
    };

    return (
      <div className="ImportBulkInformation">
        <div className="bulk-box">
          <div className="title">
            Bulk Information
          </div>

          <div className="description">
            Apply the following to all imported locations.
          </div>

          <UploadImage
            style={{
              margin: '15px 0',
            }}
            allowUpload={user.can('location.create')}
            onDrop={this.onDrop}
          />

          <Field
            type="company"
            label="Company"
            value={companyName}
            tabIndex={1}
            marginBottom={25}
            companyProps={{
              companyId,
              companyName,
            }}
            required
            onChange={this.setCompany}
          />

          <Field
            type="select"
            label="User Account Permission Set"
            value={userAccountPermissionSet._id}
            tabIndex={3}
            marginBottom={25}
            options={permissionSets}
            onChange={this.onChangeUserAccountPermissionSet}
            required={!userAccountPermissionSet._id && !optUserAccountPermissionSetId.valid}
            requiredLabel="Field required when importing user columns."
            requiredLabelSize={12}
          />

          <Field
            type="tags"
            label="Tags"
            name="tags"
            tagProps={{
              companyId,
              tags,
              allowTypes: ['client', 'admin'],
              showAs: 'list',
              assign: user.can('location.assign_tag'),
            }}
            onChange={this.onChangeTag}
            tabIndex={4}
          />
        </div>

        <div className="btn-container">
          <VibeButton
            className="btn-confirm"
            text="Confirm Bulk Information"
            icon={(
              <VibeIcon
                icon={viCircleOne}
                color={color.white}
                size={24}
              />
            )}
            btnColor="purple"
            textColor="white"
            disabled={confirmed}
            onClick={this.confirm}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    companyId: state.import.locations.companyId,
    companyName: state.import.locations.companyName,
    userAccountPermissionSet: state.import.locations.userAccountPermissionSet,
    tags: state.import.locations.tags,
    confirmed: state.import.locations.bulkInformationConfirmed,
    validationResult: state.import.locations.data.validationResult || {},
  };
}

const mapDispatchToProps = {
  createImportJob: ImportLocationsActions.createImportJob,
  setBulkImage: ImportLocationsActions.setBulkImage,
  setCompany: ImportLocationsActions.setCompany,
  setUserAccountPermissionSet: ImportLocationsActions.setUserAccountPermissionSet,
  setBulkInformationConfirmed: ImportLocationsActions.setBulkInformationConfirmed,
  setTags: ImportLocationsActions.setTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportBulkInformation);
