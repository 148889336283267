import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeIcon,
  viSend,
  viFlag,
  viFlagSlash,
  viAlert,
  viClose,
  color,
} from 'vibeguide';
import FlagActivityItem from './FlagActivityItem';
import './LocationFlag.scss';

class LocationFlag extends PureComponent {
  constructor(props) {
    super(props);

    this.flagActivityRef = React.createRef();

    this.state = {
      flagDataFetched: false,
      flagActivity: [],
      flagNote: '',
    };
  }

  componentDidMount() {
    this.onOpen();
  }

  componentDidUpdate(prevProps) {
    const {
      locationId,
    } = this.props;

    const {
      locationId: prevLocationId,
    } = prevProps;

    if (locationId && locationId !== prevLocationId) {
      this.onOpen();
    }
  }

  /**
   * When the flag modal is opened
   */
  onOpen = async () => {
    const {
      locationId,
      onClickFlag,
    } = this.props;

    const flagData = locationId ? await API.Location.getFlagActivity(locationId) : null;

    if (!flagData) {
      console.error('No flag data found');

      this.setState({
        flagDataFetched: true,
      });

      return;
    }

    const {
      flagActivity,
      flagEnabled,
    } = flagData;

    onClickFlag(flagEnabled);

    this.setState({
      flagDataFetched: true,
      flagActivity,
    });

    // Scroll to bottom of the flag activity
    this.flagActivityRef.scrollTop = this.flagActivityRef.scrollHeight;
  };

  /**
   * When the user focuses on the input to add a flag note
   */
  onFocusFlagModalAddNote = () => {
    // Scroll to bottom of the flag activity
    this.flagActivityRef.scrollTop = this.flagActivityRef.scrollHeight;
  };

  /**
   * When the user changes the input to add a flag note
   */
  onChangeFlagModalAddNote = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      flagNote: value,
    });
  };

  /**
   * When the user hits a key in the input to add a flag note
   */
  onKeyDownFlagModalAddNote = (e) => {
    if (e.key === 'Enter') {
      // Save the note
      this.onClickFlagModalSaveNote();
    }
  };

  /**
   * When the save note button is clicked
   */
  onClickFlagModalSaveNote = async () => {
    const {
      user,
      locationId,
    } = this.props;

    const {
      flagNote,
    } = this.state;

    const note = flagNote.trim();

    if (!note) {
      console.error('Note cannot be empty');
      return;
    }

    const saveNoteResponse = await API.Location.addFlagComment({
      _id: locationId,
      comment: note,
    });

    const success = get(saveNoteResponse, '[0].type') === 'LOCATION.FLAG_COMMENT_ADDED';

    if (!success) {
      console.error('Error saving note', saveNoteResponse);
      return;
    }

    this.setState((state) => {
      return {
        flagNote: '',
        showFlagNoteButtons: false,
        flagActivity: [...state.flagActivity, {
          activityType: 'comment',
          date: new moment().format(),
          detail: {
            comment: flagNote,
          },
          userFName: user.fname,
          userLName: user.lname,
          userImageUrl: user.imageUrl,
        }],
      };
    }, () => {
      // Scroll to bottom of the flag activity
      this.flagActivityRef.scrollTop = this.flagActivityRef.scrollHeight;
    });
  };

  onRef = (ref) => {
    this.flagActivityRef = ref;
  };

  /**
   * Toggle the alerts section
   */
  onToggleAlerts = () => {
    const {
      onToggleAlerts,
    } = this.props;

    onToggleAlerts();
  };

  /**
   * Toggle the flag for the item
   */
  toggleFlag = async () => {
    const {
      user,
      locationId,
      onClickFlag,
      flagEnabled,
    } = this.props;

    const toggleResponse = flagEnabled
      ? await API.Location.disableFlag(locationId)
      : await API.Location.enableFlag(locationId);

    const type = get(toggleResponse, '[0].type', null);
    const success = flagEnabled
      ? type === 'LOCATION.FLAG_DISABLED'
      : type === 'LOCATION.FLAG_ENABLED';

    if (success) {
      onClickFlag(!flagEnabled);

      this.setState((state) => {
        return {
          flagActivity: [...state.flagActivity, {
            activityType: 'toggle-flag',
            date: moment().format(),
            detail: {
              enabled: !flagEnabled,
            },
            userFName: user.fname,
            userLName: user.lname,
            userImageUrl: user.imageUrl,
          }],
        };
      }, () => {
        // Scroll to bottom of the flag activity
        this.flagActivityRef.scrollTop = this.flagActivityRef.scrollHeight;
      });
    }
  };

  render() {
    const {
      flagEnabled,
      canManageAlerts,
      onClose,
    } = this.props;

    const {
      flagDataFetched,
      flagActivity,
      flagNote,
    } = this.state;

    return (
      <SidePanelContainer className="LocationFlag">
        <SidePanelHeader
          icons={(
            <div>
              <VibeIcon
                id="flag"
                icon={viFlag}
                color={color.violetVibe}
                hoverColor={color.obsidian}
                tooltip="Flag"
                size={24}
                onClick={() => {}}
              />
              {canManageAlerts ? (
                <VibeIcon
                  id="alert"
                  icon={viAlert}
                  color={color.manatee}
                  hoverColor={color.obsidian}
                  tooltip="Manage Alerts"
                  size={24}
                  onClick={this.onToggleAlerts}
                />
              ) : null}

              <VibeIcon
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
            </div>
          )}
        >
          <div className="flex-horizontal">
            <div className="title">
              Flag Notes
            </div>
          </div>
        </SidePanelHeader>

        <SidePanelContent
          onRef={this.onRef}
        >
          <div className="modal-content">
            {(flagActivity && flagActivity.length > 0) || !flagDataFetched ? (
              <div className="flag-activity">
                {flagActivity.map((activity, index) => {
                  const {
                    activityType,
                    date,
                    userFName,
                    userLName,
                    userImageUrl,
                    detail,
                  } = activity;

                  return (
                    <FlagActivityItem
                      key={`flag-activity-${index}`}
                      type={activityType}
                      date={date}
                      name={`${userFName} ${userLName}`}
                      imageUrl={userImageUrl}
                      note={detail.comment}
                      flagEnabled={detail.enabled}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flag-activity empty">
                No Flag Activity
              </div>
            )}
          </div>
        </SidePanelContent>

        <SidePanelFooter>
          <div className="input-container modal-footer">
            <VibeIcon
              className="toolbar-btn toggle-flag-btn"
              type="button"
              icon={flagEnabled
                ? viFlag
                : viFlagSlash}
              color={flagEnabled
                ? color.white
                : color.manatee}
              size={20}
              tooltip={flagEnabled
                ? 'Unflag Player'
                : 'Flag Player'}
              buttonProps={{
                size: 38,
                color: flagEnabled
                  ? color.fireBrick
                  : color.white,
                borderColor: color.whiteSmoke,
              }}
              onClick={this.toggleFlag}
            />

            <div
              style={{
                flexGrow: 1,
              }}
            >
              <FlagActivityItem
                type="add-note"
                note={flagNote}
                onChange={this.onChangeFlagModalAddNote}
                onFocus={this.onFocusFlagModalAddNote}
                onKeyDown={this.onKeyDownFlagModalAddNote}
              />
            </div>

            <div className="toolbar-btns">
              <VibeIcon
                className="toolbar-btn save-btn"
                type="button"
                icon={viSend}
                color={flagNote
                  ? color.aquaForest
                  : color.manatee}
                size={20}
                tooltip="Save Note"
                buttonProps={{
                  size: 38,
                }}
                onClick={this.onClickFlagModalSaveNote}
              />
            </div>
          </div>
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

LocationFlag.propTypes = {
  locationId: PropTypes.string,
  canManageAlerts: PropTypes.bool,
  onClose: PropTypes.func,
  onToggleAlerts: PropTypes.func,
};

LocationFlag.defaultProps = {
  locationId: '',
  canManageAlerts: false,
  onClose: () => {},
  onToggleAlerts: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(LocationFlag);
