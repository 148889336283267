// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GripHandle {
  position: absolute;
  left: 0;
  width: 100%;
  height: 16px;
  text-align: center;
  z-index: 2;
}
.GripHandle.top {
  cursor: ns-resize;
  top: 0;
}
.GripHandle.bottom {
  cursor: ns-resize;
  top: calc(100% - 16px);
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Grid/Items/GripHandle.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;AACF;AACE;EACE,iBAAA;EACA,MAAA;AACJ;AAEE;EACE,iBAAA;EACA,sBAAA;AAAJ","sourcesContent":[".GripHandle {\n  position: absolute;\n  left: 0;\n  width: 100%;\n  height: 16px;\n  text-align: center;\n  z-index: 2;\n\n  &.top {\n    cursor: ns-resize;\n    top: 0;\n  }\n\n  &.bottom {\n    cursor: ns-resize;\n    top: calc(100% - 16px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
