import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import {
  FieldGroup,
  FieldGroupItem,
  Field2 as Field,
} from 'vibeguide';

function ProfileExternalIDRow({
  className,
  style,
  id,
  source,
  extId,
  disableInput,
  showLabels,
  allowRemove,
  alignColumns,
  onRemove,
  isNew,
  options,
  onChangeSource,
  onChangeId,
}) {
  return (
    <FieldGroup
      className={classNames('ExternalIDRow', className)}
      style={style}
      id={extId}
      allowRemove={allowRemove}
      alignColumns={alignColumns}
      removeTooltip="Remove External ID"
      onRemove={(id) => onRemove(id, source, isNew)}
    >
      <FieldGroupItem>
        {isNew ? (
          <Field
            rootClassName="location-input location-provider"
            type="select"
            label={showLabels
              ? 'Source'
              : null}
            name="name"
            options={options}
            value={source}
            dataId={id}
            onChange={onChangeSource}
          />
        ) : (
          <Field
            type="text"
            label={showLabels
              ? 'Source'
              : null}
            name="source"
            placeholder="Source"
            value={capitalize(source)}
            disabled={disableInput}
          />
        )}
      </FieldGroupItem>

      <FieldGroupItem>
        <Field
          type="text"
          label={showLabels
            ? 'External ID'
            : null}
          name="extId"
          placeholder="External ID"
          value={extId}
          disabled={!isNew}
          dataId={id}
          onChange={onChangeId}
        />
      </FieldGroupItem>
    </FieldGroup>
  );
}

ProfileExternalIDRow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
  source: PropTypes.string,
  extId: PropTypes.string,
  disableInput: PropTypes.bool,
  showLabels: PropTypes.bool,
  allowRemove: PropTypes.bool,
  alignColumns: PropTypes.bool,
  onRemove: PropTypes.func,
  isNew: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onChangeSource: PropTypes.func,
  onChangeId: PropTypes.func,
};

ProfileExternalIDRow.defaultProps = {
  className: '',
  style: {},
  source: '',
  extId: '',
  disableInput: false,
  showLabels: false,
  allowRemove: false,
  alignColumns: false,
  onRemove: () => {},
  isNew: false,
  options: [],
  onChangeSource: () => {},
  onChangeId: () => {},
};

export default ProfileExternalIDRow;
