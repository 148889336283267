import React from 'react';

const viBlock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zM3.2 8c0-2.64 2.16-4.8 4.8-4.8 1.08 0 2.1.36 2.94 1.02l-6.72 6.72A4.729 4.729 0 0 1 3.2 8zM8 12.8c-1.08 0-2.1-.36-2.94-1.02l6.72-6.72c.66.84 1.02 1.86 1.02 2.94 0 2.64-2.16 4.8-4.8 4.8z" />
    </g>
  </svg>
);

export default viBlock;
