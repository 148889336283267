import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  GlobalActions,
  VibeAlert,
  VibeIcon,
  viArrowRight,
  color,
} from 'vibeguide';
import AtAGlanceItem from './AtAGlanceItem';
import AllocationCompanies from './Allocations/AllocationCompanies';
import './AtAGlance.scss';

function AtAGlance({
  className,
  segmentSummary,
  setPanel,
}) {
  const onClickViewDetails = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <AllocationCompanies
            companies={get(segmentSummary, 'locations', [])}
          />
        ),
      },
    });
  };

  const loading = !segmentSummary._id;

  return (
    <div className={classNames('AtAGlance', className)}>
      <div className="details-title">
        At a Glance
      </div>

      <div className="glance-container">
        <AtAGlanceItem
          name="Companies"
          loading={loading}
          value={get(segmentSummary, 'locations.length', 0) || 0}
        />

        <AtAGlanceItem
          name="Target Impressions"
          loading={loading}
          value={get(segmentSummary, 'targetImpressions', 0) || 0}
        />

        <AtAGlanceItem
          name="Total Impressions"
          loading={loading}
          value={get(segmentSummary, 'totalImpressions', 0) || 0}
        />

        <AtAGlanceItem
          name="Total Spots"
          loading={loading}
          value={get(segmentSummary, 'totalSpots', 0) || 0}
        />

        <AtAGlanceItem
          name="Frequency"
          loading={loading}
          value={get(segmentSummary, 'frequency', 0) || 0}
        />

        <div
          className="view-details"
          onClick={onClickViewDetails}
        >
          <div className="name">
            View Details
          </div>

          {get(segmentSummary, 'warnings.length', 0) > 0 && (
            <VibeAlert
              style={{
                marginRight: 8,
                fontSize: 12,
              }}
              severity="warning"
              alerts={segmentSummary.warnings || []}
              size={16}
              iconOnly
            />
          )}

          <VibeIcon
            icon={viArrowRight}
            color={color.obsidian}
            size={16}
          />
        </div>
      </div>
    </div>
  );
}

AtAGlance.propTypes = {
  className: PropTypes.string,
  segmentSummary: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

AtAGlance.defaultProps = {
  className: '',
  segmentSummary: {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(AtAGlance);
