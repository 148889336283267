import React from 'react';
import PropTypes from 'prop-types';
import ObjectSelection from '../ObjectSelection/ObjectSelection';
import PlaylistsUnassigned from './PlaylistsUnassigned';
import PlaylistsAssigned from './PlaylistsAssigned';

function PlaylistSelection({
  fetch,
  filters,
  selected,
  allowChanges,
  allowPaginator,
  customToolbarAssigned,
  onSelect,
  onDeselect,
  songId,
}) {
  return (
    <ObjectSelection
      className="PlaylistSelection"
      leftColumn={(
        <PlaylistsUnassigned
          songId={songId}
          fetch={fetch}
          filters={filters}
          selected={selected}
          allowChanges={allowChanges}
          allowPaginator={allowPaginator}
          onSelect={onSelect}
        />
      )}
      rightColumn={(
        <PlaylistsAssigned
          selected={selected}
          customToolbar={customToolbarAssigned}
          allowChanges={allowChanges}
          onDeselect={onDeselect}
        />
      )}
    />
  );
}

PlaylistSelection.propTypes = {
  /** Song Id */
  songId: PropTypes.string,
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected playlists */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** Allow the Paginator to be displayed */
  allowPaginator: PropTypes.bool,
  /** Custom toolbar for assigned locations */
  customToolbarAssigned: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Fetch playlists endpoint */
  fetch: PropTypes.func,
  /** When a playlist is selected */
  onSelect: PropTypes.func,
  /** When a location is deselected */
  onDeselect: PropTypes.func,
};

PlaylistSelection.defaultProps = {
  songId: '',
  filters: null,
  selected: [],
  allowChanges: false,
  allowPaginator: false,
  customToolbarAssigned: null,
  fetch: () => {},
  onSelect: () => {},
  onDeselect: () => {},
};

export default PlaylistSelection;
