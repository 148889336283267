import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  sortBy,
} from 'lodash';
import {
  API,
  LocationHelper,
  VibeModal,
  Drawer,
} from 'vibeguide';
import MusicOverrideToolbar from './MusicOverrideToolbar';
import ScheduleData from '../ScheduleData';

function MusicOverrideEditor({
  className,
  user,
  currentLocation,
  day,
  playlists,
  mixes,
  onCopyToDays,
  onClearDay,
  onClose,
  onSave,
}) {
  const [items, setItems] = useState(sortBy([
    ...playlists,
    ...mixes,
  ], 'startTime'));

  const drawerItems = [];
  // Group the location spec
  const group = LocationHelper.group([{
    companyId: currentLocation.companyId,
    locationId: currentLocation._id,
  }]);

  if (user.can('playlist.view')) {
    drawerItems.push({
      label: 'Playlists',
      fetch: API.Playlist.list,
      filters: {
        locations: {
          matchType: 'contains',
          locations: group,
        },
      },
    });
  }

  if (user.can('mix.view')) {
    drawerItems.push({
      label: 'Stations',
      fetch: API.Mix.list,
      filters: {
        locations: {
          matchType: 'contains',
          locations: group,
        },
      },
    });
  }

  const onUpdateItems = (newItems) => {
    setItems(newItems);
  };

  const onCopyItemsToDays = (copyToDays) => {
    onCopyToDays({
      copyToDays,
      items,
    });
  };

  const onClearDayItems = () => {
    setItems([]);
    onClearDay();
  };

  const onSaveDay = () => {
    onSave(items);
  };

  return (
    <VibeModal
      className={classNames('MusicOverrideEditor', className)}
      type="custom"
      width={650}
      height={600}
      maxWidth="lg"
      show
    >
      <MusicOverrideToolbar
        day={day}
        onCopyToDays={onCopyItemsToDays}
        onClearDay={onClearDayItems}
        onClose={onClose}
        onSave={onSaveDay}
      />

      <ScheduleData
        type="schedule-override-day"
        style={{
          height: 'calc(100% - 65px)',
        }}
        activeDisplay="24h"
        startOfWeek={moment().startOf('week')}
        playlists={playlists}
        mixes={mixes}
        musicOverride
        onUpdateItems={onUpdateItems}
      />

      <Drawer
        items={drawerItems}
      />
    </VibeModal>
  );
}

MusicOverrideEditor.propTypes = {
  className: PropTypes.string,
  day: PropTypes.string.isRequired,
  playlists: PropTypes.arrayOf(PropTypes.object),
  mixes: PropTypes.arrayOf(PropTypes.object),
  onCopyToDays: PropTypes.func,
  onClearDay: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

MusicOverrideEditor.defaultProps = {
  className: '',
  playlists: [],
  mixes: [],
  onCopyToDays: () => {},
  onClearDay: () => {},
  onClose: () => {},
  onSave: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(MusicOverrideEditor);
