import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import classNames from 'classnames';
import './PhoneField.scss';

function PhoneField({
  name,
  placeholder,
  value,
  readOnly,
  onChange,
}) {
  const handleInputChange = (e) => {
    onChange({
      target: {
        name,
        value: e,
      },
    });
  };

  return (
    <MuiTelInput
      className={classNames('PhoneField', readOnly && 'readonly')}
      name={name}
      placeholder={placeholder || '+1 234 567 8910'}
      value={value}
      flagSize="small"
      disabled={readOnly}
      onChange={handleInputChange}
    />
  );
}

export default PhoneField;
