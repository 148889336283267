import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import {
  API,
  withRouter,
  viShortcut,
} from 'vibeguide';
import { Grid } from '@mui/material';
import OrderStatusCard from '../OrderStatusCard';

function PendingOrderTables() {
  const [summaries, setSummaries] = useState({});

  /**
   * Get the Order Status Summary
   */
  const getSummaries = async () => {
    const upcoming = await API.Order.Reporting.Summaries.Pending.upcoming();

    setSummaries({
      upcoming,
    });
  };

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <Grid container spacing={2} className="PendingOrderTables">
      <Grid item xs={12}>
        <OrderStatusCard
          title="Pending in the next 30 Days"
          linkUrl="../../pending/upcoming"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1) rotate(90deg)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'startDate', 'endDate'],
            data: get(summaries, 'upcoming', []),
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(PendingOrderTables);
