import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import API from '../../../api';
import VibeButton from '../../VibeButton/VibeButton';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import Tag from '../../Tags/Tag';
import color from '../../../sass/color.scss';
import './DeviceAttributeAddToLocation.scss';

class DeviceAttributeAddToLocation extends PureComponent {
  /**
   * Save the Device Attributes to the location
   */
  onAddToLocation = async () => {
    const {
      locationId,
      selectedAttributes,
      onClose,
    } = this.props;

    const addToLocationResponse = await API.Location.DeviceAttributes.update({
      _id: locationId,
      deviceAttributes: selectedAttributes.map((attribute) => {
        return {
          _id: attribute._id,
          action: 'add',
        };
      }),
    });

    if (get(addToLocationResponse, '[0].type') === 'LOCATION.DEVICE_ATTRIBUTES_UPDATED') {
      onClose({
        reset: true,
      });
    } else {
      console.error('Error saving attributes to location', addToLocationResponse);
    }
  };

  render() {
    const {
      className,
      selectedAttributes,
      onClose,
    } = this.props;

    return (
      <div className={classNames('DeviceAttributeAddToLocation', className)}>
        <div className="attribute-section section-selected">
          <VibeCheckbox
            color={color.aquaForest}
            size={16}
            checked
          />

          <div className="text">
            {selectedAttributes.length} selected
          </div>
        </div>

        <div className="attribute-section section-content">
          <div className="attribute-item-container">
            {selectedAttributes.map((attribute) => {
              return (
                <Tag
                  key={`attribute-${attribute._id}`}
                  style={{
                    marginBottom: 8,
                  }}
                  tag={{
                    name: `${attribute.name} : ${attribute.value}`,
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className="attribute-section section-footer">
          <VibeButton
            className="btn-save"
            text="Add to this location"
            btnColor="green"
            textColor="white"
            onClick={this.onAddToLocation}
          />

          <VibeButton
            text="Cancel"
            btnColor="transparent"
            textColor="manatee"
            onClick={onClose}
          />
        </div>
      </div>
    );
  }
}

DeviceAttributeAddToLocation.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Location ID for the device attributes if location view (not admin) */
  locationId: PropTypes.string,
  /** Selected Attributes */
  selectedAttributes: PropTypes.arrayOf(PropTypes.object),
  /** When the new attribute is closed */
  onClose: PropTypes.func,
};

DeviceAttributeAddToLocation.defaultProps = {
  className: '',
  locationId: null,
  selectedAttributes: [],
  onClose: () => {},
};

export default DeviceAttributeAddToLocation;
