// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoteMessage {
  margin-bottom: 16px;
}
.NoteMessage:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Notes/NoteMessage.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".NoteMessage {\n  margin-bottom: 16px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
