import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import './DashboardCard.scss';

function DashboardCard({
  className,
  children,
  link,
}) {
  if (link) {
    return (
      <Link
        className={classNames('DashboardCard', className)}
        to={link}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className={classNames('DashboardCard', className)}>
      {children}
    </div>
  );
}

DashboardCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  link: PropTypes.string,
};

DashboardCard.defaultProps = {
  className: '',
  link: null,
};

export default DashboardCard;
