import React from 'react';

const viMoreHorizontal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        {/* eslint-disable-next-line max-len */}
        <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
      </mask>

      <g fill="#383838" mask="url(#b)">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default viMoreHorizontal;
