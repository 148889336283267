import React from 'react';

const viMicrophoneBubble = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M22 4v18l-4-4H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2zm-10 7.316c.85 0 1.5-.684 1.5-1.58V6.58C13.5 5.684 12.85 5 12 5s-1.5.684-1.5 1.579v3.158c0 .895.65 1.579 1.5 1.579zm2.65-1.58A2.646 2.646 0 0 1 12 12.422c-1.4 0-2.65-1.105-2.65-2.684H8.5c0 1.79 1.35 3.263 3 3.526V15h1v-1.737c1.65-.263 3-1.737 3-3.526h-.85z" />
    </g>
  </svg>
);

export default viMicrophoneBubble;
