import React from 'react';

const viPlaylist = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12.807 3.264c-.268-.03-.562-.08-.796-.222-.177-.107-.322-.375-.541-.375-.334 0-.334.561-.334.849v6.508c-.542-.383-1.341-.504-2.13-.256-.563.177-1.041.514-1.345.949-.322.46-.415.975-.26 1.448.154.474.533.84 1.067 1.032.255.09.53.136.814.136.278 0 .562-.043.841-.131.932-.293 1.573-1.013 1.664-1.766a.326.326 0 0 0 .017-.098V4.44c.233.072.543.13.96.156.466.016.902.206 1.239.541a.4.4 0 0 0 .558-.018.38.38 0 0 0 .068-.43c-.233-.482-.78-1.31-1.822-1.425zM7.193 9.712c0 .06-.04.11-.087.11H3.724c-.048 0-.087-.05-.087-.11v-.95c0-.06.04-.11.087-.11h3.382c.048 0 .087.05.087.11v.95zm-.991 1.834v.95c0 .06-.028.11-.062.11H3.702c-.034 0-.062-.05-.062-.11v-.95c0-.06.028-.11.062-.11H6.14c.034 0 .062.05.062.11m3.79-5.54V6.9c0 .076-.056.138-.125.138H3.762c-.069 0-.125-.062-.125-.138v-.894c0-.076.056-.137.125-.137h6.105c.069 0 .125.061.125.137m.002-2.784v.895c0 .075-.056.137-.125.137H3.765c-.07 0-.125-.062-.125-.137v-.895c0-.076.056-.137.125-.137h6.104c.07 0 .125.061.125.137m-7.472.07v.894c0 .076-.01.137-.023.137H1.357c-.013 0-.024-.061-.024-.137V3.29c0-.076.01-.137.024-.137h1.142c.013 0 .023.061.023.137m0 2.712v.895c0 .075-.01.137-.023.137H1.357c-.013 0-.024-.062-.024-.137v-.895c0-.076.01-.137.024-.137h1.142c.013 0 .023.061.023.137m0 2.786v.895c0 .076-.01.137-.023.137H1.357c-.013 0-.024-.061-.024-.137v-.895c0-.076.01-.137.024-.137h1.142c.013 0 .023.061.023.137m0 2.785v.894c0 .076-.01.138-.023.138H1.357c-.013 0-.024-.062-.024-.138v-.894c0-.076.01-.138.024-.138h1.142c.013 0 .023.062.023.138" />
    </g>
  </svg>
);

export default viPlaylist;
