import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viUser,
  viUserGroup,
  viLock,
} from 'vibeguide';

function UsersContainer({
  user,
}) {
  return (
    <div
      className="users-container"
      style={{
        height: '100%',
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Accounts"
          url="/users/accounts"
          icon={viUser}
          show={user.hasAnyOf(['user.view', 'integration_user.view', 'partner_user.view'])}
        />

        <NavigationSideItem
          text="Users with Permissions"
          url="/users/view-users-with-permissions"
          icon={viUserGroup}
          show={user.sysAdmin
            && user.hasAnyOf(['user.view', 'integration_user.view', 'partner_user.view'])}
        />

        <NavigationSideItem
          text="Permission Sets"
          url="/users/permissions"
          icon={viLock}
          show={user.can('permissionset.view')}
        />
      </NavigationSide>

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    isAuthenticated: state.login.authenticated,
  };
}

export default connect(mapStateToProps)(UsersContainer);
