import React from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';

/**
 * withRouter is deprecated in react-router-dom
 * Recreate using the hook
 */
function withRouter(Component) {
  function Wrapper(props) {
    const history = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        history={history}
        location={location}
        params={params}
        {...props}
      />
    );
  }

  return Wrapper;
}

export default withRouter;
