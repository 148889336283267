import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeIcon,
  viViewDayTrack,
  color,
} from 'vibeguide';
import './PassThrough.scss';

function PassThrough({
  className,
  title,
  enabled,
  eventColor,
  onChange,
}) {
  return (
    <VibeIcon
      className={classNames('PassThrough', className)}
      type="button"
      buttonProps={{
        size: 32,
        color: enabled
          ? eventColor
          : color.white,
        borderColor: enabled
          ? eventColor
          : color.white,
      }}
      icon={viViewDayTrack}
      color={enabled
        ? color.white
        : color.obsidian}
      hoverColor={enabled
        ? color.white
        : color.violetVibe}
      size={16}
      tooltip={(
        <div className="PassThroughTooltip">
          <div className="title">
            {title} Pass-Through

            <div className={classNames('status', { enabled })}>
              {enabled
                ? 'ON'
                : 'OFF'}
            </div>
          </div>

          <div className="desc">
            Events are scheduled on top of baseline programs.
            By turning the {title} Pass-Through feature ON,
            the baseline&lsquo;s music will play during empty spaces in this event.
            By turning this feature OFF, any empty spaces will be scheduled as dead air.
          </div>
        </div>
      )}
      onClick={() => onChange(title)}
    />
  );
}

PassThrough.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
};

PassThrough.defaultProps = {
  className: '',
  enabled: false,
  onChange: () => {},
};

export default PassThrough;
