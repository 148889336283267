import { handleActions } from 'redux-actions';
import { find } from 'lodash';

const initialState = {
  messages: {
    requests: {
      unassigned: [],
      pending_rough_cut: [],
      pending_final_cut: [],
      pending_approval: [],
      processing: [],
    },
    totals: {
      unassigned: 0,
      pending_rough_cut: 0,
      pending_final_cut: 0,
      pending_approval: 0,
      processing: 0,
    },
  },
  activeMessage: null,
  locations: [],
  allLocations: false,
  messageBlocks: [],
};

export default handleActions(
  {
    UPDATE_MESSAGE_LIST: (state, action) => {
      const messages = action.payload;

      return {
        ...state,
        messages,
      };
    },

    ADD_MESSAGE_LOCATION: (state, action) => {
      const location = action.payload;
      const hasLocation = find(state.locations, location) !== undefined;

      if (hasLocation) {
        // Do not add the location twice
        return state;
      }

      return {
        ...state,
        locations: [...state.locations, location],
      };
    },

    REMOVE_MESSAGE_LOCATION: (state, action) => {
      const locationData = action.payload;
      const locationItem = find(state.locations, locationData);

      return {
        ...state,
        locations: state.locations.filter(location => location !== locationItem),
      };
    },

    SET_ACTIVE_MESSAGE: (state, action) => {
      const message = action.payload;

      return {
        ...state,
        activeMessage: message,
      };
    },

    UPDATE_ACTIVE_MESSAGE: (state, action) => {
      const message = action.payload;

      return {
        ...state,
        activeMessage: {
          ...state.activeMessage,
          ...message,
        },
      };
    },

    SET_MESSAGE_ALL_LOCATIONS: (state, action) => {
      const allLocations = action.payload;
      const locations = allLocations ? [] : state.locations;

      return {
        ...state,
        allLocations,
        locations,
      };
    },

    CLEAR_ACTIVE_MESSAGE: (state) => {
      return {
        ...state,
        activeMessage: null,
      };
    },

    RESET_NEW_MESSAGE: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
