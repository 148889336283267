import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DrawerItem from './DrawerItem';
import VibeIcon from '../VibeIcon/VibeIcon';
import viChevronRight from '../../icons/viChevronRight';
import viChevronDown from '../../icons/viChevronDown';
import viArrowLeft from '../../icons/viArrowLeft';
import color from '../../sass/color.scss';
import './Drawer.scss';

function Drawer({
  className,
  items,
}) {
  const [showContent, setShowContent] = useState(false);
  const [headerContent, setHeaderContent] = useState(null);
  const [content, setContent] = useState(null);
  const [overrideContent, setOverrideContent] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const onDragStart = () => {
    setShowContent(false);
  };

  const onClearHeaderContent = () => {
    setHeaderContent(null);
    setOverrideContent(null);
  };

  const onClearContent = () => {
    setShowContent(false);
    setActiveItem(null);

    // clear the content once the animation has completed
    setTimeout(() => {
      setContent(null);
    }, 500);
  };

  const onSetHeaderContent = (headerHtml, contentHtml) => {
    setHeaderContent(headerHtml);
    setOverrideContent(contentHtml);
  };

  const onSetContent = (label, data) => {
    if (data !== null) {
      setShowContent(true);
      setActiveItem(label);
      setContent(data);
    } else {
      // content was cleared
      onClearContent();
    }
  };

  useEffect(() => {
    document.addEventListener('onDragStartVibeTable', onDragStart);
    document.addEventListener('onDragEndVibeTable', onClearContent);

    return () => {
      document.removeEventListener('onDragStartVibeTable', onDragStart);
      document.removeEventListener('onDragEndVibeTable', onClearContent);
    };
  }, []);

  return (
    <div className={classNames('Drawer', className, {
      content: showContent,
    })}
    >
      <div className="header">
        {headerContent === null ? (
          <VibeIcon
            icon={showContent
              ? viChevronDown
              : viChevronRight}
            color={color.violetVibe}
            hoverColor={color.white}
            size={24}
            onClick={onClearContent}
          />
        ) : (
          <VibeIcon
            icon={viArrowLeft}
            color={color.violetVibe}
            hoverColor={color.white}
            size={24}
            onClick={onClearHeaderContent}
          />
        )}

        {headerContent === null && items.map((item) => (
          <DrawerItem
            key={`dawer-item-${item.label}`}
            label={item.label}
            icon={item.icon}
            tooltip={item.tooltip}
            draggable={item.draggable}
            active={activeItem === item.label}
            disabled={item.disabled}
            fetch={item.fetch}
            filters={item.filters}
            onSetContent={onSetContent}
            onSetHeaderContent={onSetHeaderContent}
          />
        ))}

        {headerContent && (
          <div className="override-header">
            {headerContent}
          </div>
        )}
      </div>

      <div className="content">
        {overrideContent || content}
      </div>
    </div>
  );
}

Drawer.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.func,
    tooltip: PropTypes.string,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
  })),
};

Drawer.defaultProps = {
  className: '',
  items: [],
};

export default Drawer;
