import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';
import {
  API,
  MimeTypeHelper,
  Field2 as Field,
  VibeIcon,
  viEdit,
  viAdd,
  viAddCircle,
  color,
} from 'vibeguide';
import defaultLocationImage from '../../../assets/default_location.png';
import './Information.scss';

class Information extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When a dropdown is changed
   */
  onChangeDropdown = ({
    name,
    value,
  }) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  /**
   * When the company field is changed
   */
  onSetCompany = async ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      companyId: currCompanyId,
      category,
      tags,
      user,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });

    // populate the category if not already set
    if (!category && user.can('company.view')) {
      const company = await API.Company.getById(companyId);

      onUpdate({
        category: company.category || '',
      });
    }
  };

  onDrop = (acceptedFiles) => {
    const file = get(acceptedFiles, '[0]', false);

    if (file) {
      const {
        onUpdate,
        onUploadImage,
      } = this.props;

      const reader = new FileReader();
      reader.onload = () => {
        const fileAsDataURL = reader.result;
        // Save image data to state to show as a preview
        onUpdate({
          imageUrl: fileAsDataURL,
        });
      };

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');

      reader.readAsDataURL(file);

      onUploadImage(file);
    }
  };

  render() {
    const {
      name,
      image: locationImage,
      companyName,
      companyId,
      bannerName,
      category,
      salesforceId,
      tags,
      disableInput,
      user,
      isNew,
      disableBannerField,
    } = this.props;

    const canAdminEdit = user.sysAdmin && !disableInput;
    const canSetCompany = user.sysAdmin;

    return (
      <div className="Information location">
        <div className="details-title first">
          Information
        </div>

        <div className="data-row">
          <div className="location-image">
            <Dropzone
              accept={MimeTypeHelper.images}
              multiple={false}
              onDrop={this.onDrop}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getRootProps()}
                    className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                  >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <input {...getInputProps()} />

                    {locationImage ? (
                      <img src={locationImage} alt="Location Preview" />
                    ) : (
                      <img src={defaultLocationImage} alt="Location" />
                    )}

                    {isDragActive ? (
                      <div className="icon-drop">
                        <VibeIcon
                          className="icon"
                          icon={viAddCircle}
                          color={color.white}
                          size={40}
                        />
                      </div>
                    ) : null}

                    <div className="icon-container">
                      {isDragActive ? (
                        <VibeIcon
                          type="button"
                          icon={viAdd}
                          color={color.white}
                          size={16}
                          buttonProps={{
                            size: 24,
                            color: color.violetVibe,
                          }}
                        />
                      ) : (
                        <VibeIcon
                          type="button"
                          icon={viEdit}
                          color={color.white}
                          size={16}
                          buttonProps={{
                            size: 24,
                            color: color.violetVibe,
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          </div>

          <div>
            <Grid spacing={2} container>
              <Grid xs={12} lg={6} item>
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  placeholder="Name"
                  value={name}
                  tabIndex={1}
                  onChange={this.onChange}
                  disabled={disableInput}
                  required
                  autoFocus
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <Field
                  type="company"
                  label="Company"
                  name="companyName"
                  value={companyName}
                  tabIndex={2}
                  companyProps={{
                    companyId,
                    companyName,
                  }}
                  disabled={disableInput || !canSetCompany || !isNew}
                  onChange={this.onSetCompany}
                  required
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <Field
                  type="dropdown"
                  label="Category"
                  name="category"
                  value={category}
                  tabIndex={3}
                  dropdownProps={{
                    type: 'category',
                    attr: 'value',
                    fetch: API.Company.Category.list,
                    create: API.Company.Category.create,
                    canCreate: user.can('company_category.create'),
                    successMessage: 'COMPANYCATEGORY.CREATED',
                  }}
                  disabled={disableInput}
                  onChange={this.onChangeDropdown}
                  required
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <Field
                  type="dropdown"
                  label="Location Banner"
                  name="bannerName"
                  value={bannerName}
                  tabIndex={4}
                  dropdownProps={{
                    type: 'banner',
                    attr: 'value',
                    companyId,
                    fetch: API.Company.Banner.list,
                    create: API.Company.Banner.create,
                    canCreate: user.can('company_banner.create'),
                    successMessage: 'COMPANYBANNER.CREATED',
                  }}
                  disabled={!canAdminEdit || disableBannerField || !companyId}
                  onChange={this.onChangeDropdown}
                  required
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <Field
                  type="text"
                  label="Salesforce ID"
                  name="salesforceId"
                  placeholder="XXXXXXXXXXXXXXXXXX"
                  value={salesforceId}
                  tabIndex={5}
                  disabled={!canAdminEdit}
                  onChange={this.onChange}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="data-row">
          <div className="data-fields">
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                companyId,
                tags,
                allowTypes: ['client', 'admin'],
                showAs: 'list',
                assign: user.can('location.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={6}
            />
          </div>
        </div>
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  bannerName: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  disableBannerField: PropTypes.bool,
  isNew: PropTypes.bool,
  onUploadImage: PropTypes.func,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  companyName: '',
  companyId: '',
  bannerName: '',
  category: '',
  image: null,
  tags: [],
  disableInput: false,
  disableBannerField: false,
  isNew: false,
  onUploadImage: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
