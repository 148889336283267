import request from 'superagent';
import {
  isFunction,
  isString,
  get,
} from 'lodash';
import {
  getCookie,
} from '../utils/CookieUtil';
import {
  checkSession,
} from '../utils/AuthUtil';
import { store } from '../lib/styleguide/Wrapper';
import {
  setModal,
} from '../actions/Global/GlobalActions';
import pkg from '../../../../package.json';

// let tokenExpired = false;

// setTimeout(() => {
//   tokenExpired = true;
//   console.warn('Force Token Expiration');
// }, 10000);

const appEnv = process.env.APP_ENV;
const local = appEnv === 'local';
const development = appEnv === 'development';
const clientHeader = local || development
  ? `web/Dev V${pkg.version}`
  : `web/${pkg.version}`;

function handleError(error) {
  let message = 'Something went wrong';

  if (isString(error)) {
    message = error;
  } else if (error.message) {
    message = error.message;
  }

  store.dispatch(setModal({
    message,
    type: 'error',
  }));

  if (local || development) {
    console.error(error);
  }
}

async function makeRequest(url, method, headers, body, useToken) {
  // check if the user session is valid. If not, refresh the user tokens
  await checkSession();

  const token = useToken || getCookie('app:user:idToken');

  const options = {
    method,
    headers: {
      'VAPI-Client': clientHeader,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    body,
  };

  const response = await fetch(url, options);

  if (headers.Accept === 'text/csv') {
    const responseText = await response.text();
    return responseText;
  }

  const responseJson = await response.json();
  const errorMessage = responseJson['@@errorMessage'];

  if (errorMessage) {
    throw errorMessage;
  }

  return responseJson;
}

function buildQueryString(options) {
  if (!options) return '';

  const qs = Reflect.ownKeys(options)
    .map(key => {
      if (
        key === 'token'
        || key === 'headers'
      ) {
        // Do not pass these keys in the querystring
        return null;
      }

      const value = Reflect.get(options, key);

      return `${key}=${value}`;
    })
    .join('&');

  return qs ? `?${qs}` : '';
}

class Http {
  async get(getURL, options = {}) {
    try {
      const qs = buildQueryString(options);
      const useToken = get(options, 'token', null);

      return await makeRequest(
        `${getURL}${qs}`,
        'GET',
        {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        null,
        useToken,
      );
    } catch (error) {
      if (!options.silent) {
        // show error modal
        handleError(error);
        throw error;
      } else {
        // silent response (do not show error)
        return {};
      }
    }
  }

  async post(postURL, postBody, options = {}) {
    try {
      return await makeRequest(
        postURL,
        'POST',
        {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        JSON.stringify(postBody),
      );
    } catch (error) {
      if (!options.silent) {
        // show error modal
        handleError(error);
        throw error;
      } else {
        // silent response (do not show error)
        return {};
      }
    }
  }

  async postFile(postURL, postBody, options = {}) {
    const data = new FormData();

    postBody.forEach((item, key) => {
      data.append(key, item);
    });

    return makeRequest(
      postURL,
      'POST',
      options.headers,
      data,
    );
  }

  async postFileUpload(postURL, file, key, onProgress) {
    try {
      const token = getCookie('app:user:idToken');
      const response = await request
        .post(postURL)
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${token}`)
        .set('VAPI-Client', clientHeader)
        .attach(key, file)
        .on('progress', e => {
          if (isFunction(onProgress)) {
            // Send progress back as the file is uploading
            onProgress(e.percent);
          }
        });

      const responseJson = response.body;

      return responseJson;
    } catch (error) {
      const errorObj = get(error, 'response.body', {});
      const errorMessage = errorObj['@@errorMessage'];

      if (errorMessage) {
        store.dispatch(setModal({
          message: errorMessage,
          type: 'error',
        }));

        // Return error message to the component to display
        return {
          error: errorMessage,
        };
      }

      store.dispatch(setModal({
        message: error,
        type: 'error',
      }));

      handleError(error);
      // Unknown error occured
      throw error;
    }
  }

  async delete(deleteURL, deleteBody, options = {}) {
    try {
      return await makeRequest(
        deleteURL,
        'DELETE',
        {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        JSON.stringify(deleteBody),
      );
    } catch (error) {
      handleError(error);
      throw error;
    }
  }

  async put(putURL, putBody, options = {}) {
    try {
      return await makeRequest(
        putURL,
        'PUT',
        {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        JSON.stringify(putBody),
      );
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new Http();
