// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DaySelector {
  display: flex;
  justify-content: space-between;
}
.DaySelector > .toggle-all {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/DaySelector/DaySelector.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".DaySelector {\n  display: flex;\n  justify-content: space-between;\n\n  > .toggle-all {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
