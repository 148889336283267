import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

class NotificationSettings extends PureComponent {
  onChangeEmail = (toggled) => {
    const {
      sms,
      onUpdate,
    } = this.props;

    onUpdate({
      notifications: {
        email: toggled,
        sms,
      },
    });
  };

  onChangeSMS = (toggled) => {
    const {
      email,
      onUpdate,
    } = this.props;

    onUpdate({
      notifications: {
        email,
        sms: toggled,
      },
    });
  };

  render() {
    const {
      className,
      email,
      sms,
      disableInput,
    } = this.props;

    return (
      <div className={classNames('NotificationSettings', className)}>
        <Grid
          className="row extra-space"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={12}
            item
          >
            Notification Settings
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={6} item>
            <Field
              type="toggle"
              label="Email Notifications"
              toggleProps={{
                label: 'Enable Email Notifications',
              }}
              value={email
                ? 'on'
                : 'off'}
              disabled={disableInput}
              onChange={this.onChangeEmail}
            />
          </Grid>

          <Grid xs={6} item>
            <Field
              type="toggle"
              label="SMS Notifications"
              toggleProps={{
                label: 'Enable SMS Notifications',
              }}
              value={sms
                ? 'on'
                : 'off'}
              disabled={disableInput}
              onChange={this.onChangeSMS}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  className: PropTypes.string,
  email: PropTypes.bool,
  sms: PropTypes.bool,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

NotificationSettings.defaultProps = {
  className: '',
  email: false,
  sms: false,
  disableInput: false,
  onUpdate: () => {},
};

export default NotificationSettings;
