import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClipboardJS from 'clipboard';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCopy from '../../icons/viCopy';
import color from '../../sass/color.scss';
import './Copyable.scss';

class Copyable extends PureComponent {
  constructor(props) {
    super(props);

    const {
      copyText,
    } = props;

    this.clipboardRef = React.createRef();
    // Timeout to reset the clipboard tooltip
    this.clipboardResetTimeout = null;

    this.state = {
      copyText,
    };
  }

  componentDidMount() {
    const {
      clipboardRef: {
        current: clipboardRef,
      },
    } = this;

    if (clipboardRef) {
      const clipboard = new ClipboardJS(clipboardRef);

      clipboard.on('success', (e) => {
        e.clearSelection();

        this.setState({
          copyText: 'Copied!',
        });

        this.clipboardResetTimeout = setTimeout(() => {
          const {
            copyText,
          } = this.props;

          this.setState({
            copyText,
          });
        }, 2000);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      copyText,
    } = this.props;

    const {
      copyText: prevCopyText,
    } = prevProps;

    if (copyText !== prevCopyText) {
      this.setState({
        copyText,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.clipboardResetTimeout);
  }

  render() {
    const {
      className,
      displayText,
    } = this.props;

    const {
      copyText,
    } = this.state;

    return (
      <VibeTooltip
        title={copyText}
      >
        <div
          ref={this.clipboardRef}
          className={classNames('Copyable', className)}
          data-clipboard-text={copyText}
        >
          <VibeIcon
            className="copy-icon"
            icon={viCopy}
            color={color.manatee}
            size={16}
          />

          <div className="text">
            {displayText || copyText}
          </div>
        </div>
      </VibeTooltip>
    );
  }
}

Copyable.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Text to display */
  displayText: PropTypes.string,
  /** Text to copy */
  copyText: PropTypes.string.isRequired,
};

Copyable.defaultProps = {
  className: '',
  displayText: '',
};

export default Copyable;
