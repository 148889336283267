import PropTypes from 'prop-types';
import {
  submenuItemType,
} from './submenuItemType';

export const submenuPropsType = PropTypes.shape({
  rootUrl: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  items: PropTypes.arrayOf(submenuItemType),
});

export const submenuPropsDefault = {
  rootUrl: null,
  style: {},
  items: [],
};

export default {
  submenuPropsType,
  submenuPropsDefault,
};
