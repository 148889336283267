import React from 'react';

const viStoreCopy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M20.222 10.25L21 14v1.5h-.778V20h-1.555v-4.5h-3.111V20H7.778v-4.5H7V14l.778-3.75h12.444zM14 15.5H9.333v3H14v-3zM16.222 4v2.25l.156.75h-9.6l-.001 3.147-.756 3.65L6 14v2H3.778v-4.5H3V10l.778-3.75V4h12.444zm4 4v1.5H7.778V8h12.444z" />
    </g>
  </svg>
);

export default viStoreCopy;
