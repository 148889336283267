import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import {
  API,
  withRouter,
  viPlayDown,
  viShortcut,
} from 'vibeguide';
import { Grid } from '@mui/material';
import OrderStatusCard from '../OrderStatusCard';

function DefaultOrderTables() {
  const [summaries, setSummaries] = useState({});

  /**
   * Get the Order Status Summary
   */
  const getSummaries = async () => {
    const underPacing = await API.Order.Reporting.Summaries.Overview.underPacing();
    const ending = await API.Order.Reporting.Summaries.Overview.ending();
    const missingCreatives = await API.Order.Reporting.Summaries.Overview.missingCreative();
    const upcoming = await API.Order.Reporting.Summaries.Pending.upcoming();

    setSummaries({
      underPacing,
      ending,
      missingCreatives,
      upcoming,
    });
  };

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <OrderStatusCard
          title="Under Pacing Orders"
          linkUrl="../overview/underpacing"
          icon={viPlayDown}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'deliveredImpressions', 'endDate'],
            data: get(summaries, 'underPacing', []),
          }}
          tableStyle={{
            height: 317,
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>

      <Grid item xs={6}>
        <OrderStatusCard
          title="Awaiting Creative"
          linkUrl="../overview/awaitingcreative"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'startDate', 'endDate'],
            data: get(summaries, 'missingCreatives', []),
          }}
          tableStyle={{
            height: 317,
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>

      <Grid item xs={6}>
        <OrderStatusCard
          title="Ends in 30 Days or Less"
          linkUrl="../overview/ending"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'deliveredImpressions', 'endDate'],
            data: get(summaries, 'ending', []),
          }}
          tableStyle={{
            height: 317,
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>

      <Grid item xs={6}>
        <OrderStatusCard
          title="Upcoming in the next 30 Days"
          linkUrl="../overview/upcoming"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'startDate', 'endDate'],
            data: get(summaries, 'upcoming', []),
          }}
          tableStyle={{
            height: 317,
          }}
          loading={size(summaries) <= 0}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(DefaultOrderTables);
