import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  clone,
  pullAt,
  findLastIndex,
  isArray,
} from 'lodash';
import API from '../../api';
import TableUsers from '../VibeTable/Tables/TableUsers';
import './UsersUnassigned.scss';

class UsersUnassigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = props;

    this.state = {
      selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    const {
      selected: prevSelected,
    } = prevProps;

    if (JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
      this.setState({
        selected,
      });
    }
  }

  onAdd = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const newItems = items.map((item) => {
      const newItem = clone(item);

      // remove the row ID to give it a unique identifier
      delete newItem._rowId;
      return newItem;
    });

    this.setState((state) => {
      const users = [
        ...state.selected,
        ...newItems,
      ];

      onChange(users);

      return {
        selected: users,
      };
    });
  };

  onRemove = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const {
        selected,
      } = state;

      const lastIndex = findLastIndex(selected, { _id: item._id });

      // only remove one item in case there are duplicates
      pullAt(selected, lastIndex);
      onChange(selected);

      return {
        selected,
      };
    });

    // needed because the third panel table isn't re-rendered on a prop/state change
    document.dispatchEvent(new Event('onRenderTable'));
    document.dispatchEvent(new Event('onUpdateTableAssignedUsers'));
  };

  render() {
    const {
      customToolbar,
      filters,
      onChange,
    } = this.props;

    const {
      selected,
    } = this.state;

    return (
      <div className="UsersUnassigned">
        <div className="title-container">
          <div className="title">
            Available Users
          </div>

          {customToolbar}
        </div>

        <div className="users-container">
          <TableUsers
            columns={[
              'Add/Remove',
              'First Name',
              'Last Name',
              'Title',
              'Email',
              'Phone #',
              'Company',
              'Tags',
              '...',
            ]}
            fetch={API.User.list}
            filters={filters}
            defaultSortBy={{
              label: 'First Name',
              attr: 'fname',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Users',
              urlPaging: false,
              urlFilters: false,
            }}
            menuItems={[
              { name: 'Archive', userCan: 'user.delete' },
            ]}
            available
            highlight={selected}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

UsersUnassigned.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    messageType: PropTypes.string,
    url: PropTypes.string,
    active: PropTypes.bool,
  })),
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** When the messages are changed */
  onChange: PropTypes.func,
};

UsersUnassigned.defaultProps = {
  filters: null,
  selected: [],
  customToolbar: null,
  onChange: () => {},
};

export default UsersUnassigned;
