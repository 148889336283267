import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import slice from 'lodash/slice';
import { useOutletContext } from 'react-router-dom';
import {
  API,
  AreaChart,
  color,
} from 'vibeguide';

function InfoGraph({
  className,
  style,
}) {
  const [orderData] = useOutletContext();
  const [dailyStatistics, setDailyStatistics] = useState({});

  /**
   * Get Daily Statistics
   */
  const getDailyStatistics = async () => {
    if (!orderData._id) {
      return;
    }

    const response = await API.Order.dailyStatistics({
      _id: orderData._id,
    }, { silent: true });

    if (response) {
      setDailyStatistics(response);
    }
  };

  /**
   * Get the days to show in the graph
   * @param type audio | visual
   */
  const getDays = (type) => {
    // number of days to return from the daily statistics
    const daysToShow = 15;

    // all items in the daily statistics
    const items = get(dailyStatistics, type, {});

    // items to use in the graph
    let days = [];

    if (size(items) <= daysToShow) {
      // there are less items than days to show in the graph, show everything
      days = map(items, (item, day) => {
        return {
          day,
          dayLabel: moment(day).format('MMM D'),
          ...item,
        };
      });
    } else {
      // there are more items than days to show, determine which items to graph
      // separate the items into dates from the past, or future dates
      const today = new moment();
      const pastItems = [];
      const futureItems = [];

      forEach(items, (item, day) => {
        const itemDate = new moment(day);
        const data = {
          day,
          dayLabel: itemDate.format('MMM D'),
          ...item,
        };

        if (itemDate <= today) {
          pastItems.push(data);
        } else {
          futureItems.push(data);
        }
      });

      if (pastItems.length > daysToShow) {
        // too many items in the past to show, only show the last items
        const startIndex = pastItems.length - daysToShow;
        const stopIndex = pastItems.length - 1;

        for (let i = startIndex; i <= stopIndex; i++) {
          days.push(pastItems[i]);
        }
      } else {
        // not enough days to show, pad with future days
        const stopIndex = daysToShow - pastItems.length;
        const useFutureItems = slice(futureItems, 0, stopIndex);
        days = [
          ...pastItems,
          ...useFutureItems,
        ];
      }
    }

    // sort the array by days to show
    return sortBy(days, ['day']);
  };

  /**
   * Merge the days to show multiple lines in the graph
   * @param {Array} audioDays
   * @param {Array} visualDays
   */
  const mergeDays = (audioDays, visualDays) => {
    // use audio days as the starting point, then merge visual into it
    const merged = audioDays.map((audioDay) => {
      return {
        day: audioDay.day,
        dayLabel: audioDay.dayLabel,
        audioTotalImpressionsAllocated: audioDay.totalImpressionsAllocated,
        audioTotalImpressionsDelivered: audioDay.totalImpressionsDelivered,
        audioTotalSpotsAllocated: audioDay.totalSpotsAllocated,
        audioTotalSpotsDelivered: audioDay.totalSpotsDelivered,
      };
    });

    // merge visual days
    visualDays.forEach((visualDay) => {
      const day = find(merged, { day: visualDay.day });

      if (day) {
        // day already exists in the merged day array, add visual data
        day.visualTotalImpressionsAllocated = visualDay.totalImpressionsAllocated;
        day.visualTotalImpressionsDelivered = visualDay.totalImpressionsDelivered;
        day.visualTotalSpotsAllocated = visualDay.totalSpotsAllocated;
        day.visualTotalSpotsDelivered = visualDay.totalSpotsDelivered;
      } else {
        // day does not exist in the merged day array
        merged.push({
          day: visualDay.day,
          dayLabel: visualDay.dayLabel,
          visualTotalImpressionsAllocated: visualDay.totalImpressionsAllocated,
          visualTotalImpressionsDelivered: visualDay.totalImpressionsDelivered,
          visualTotalSpotsAllocated: visualDay.totalSpotsAllocated,
          visualTotalSpotsDelivered: visualDay.totalSpotsDelivered,
        });
      }
    });

    // sort the array by days to show
    return sortBy(merged, ['day']);
  };

  useEffect(() => {
    getDailyStatistics();
  }, [orderData._id]);

  const audioDays = dailyStatistics.audio
    ? getDays('audio')
    : [];
  const visualDays = dailyStatistics.visual
    ? getDays('visual')
    : [];

  // merge audio and visual days to show both data sets
  const days = mergeDays(audioDays, visualDays);

  return (
    <div
      className={classNames('InfoGraph', className)}
      style={{
        margin: '2rem',
        ...style,
      }}
    >
      <AreaChart
        width="100%"
        height={240}
        data={days}
        areas={[
          {
            key: 'audioTotalImpressionsDelivered',
            name: 'Audio',
            color: color.lightOrchid,
          },
          {
            key: 'visualTotalImpressionsDelivered',
            name: 'Visual',
            color: color.graphBlue,
          },
        ]}
        xDataKey="dayLabel"
        yLabel="Impressions"
      />
    </div>
  );
}

InfoGraph.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

InfoGraph.defaultProps = {
  className: '',
  style: {},
};

export default InfoGraph;
