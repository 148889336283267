import { handleActions } from 'redux-actions';

const initialState = {
  id: null,
  name: null,
  src: null,
  paused: false,
  playText: 'Play',
  pauseText: 'Pause',
};

export default handleActions(
  {
    SET_AUDIO: (state, action) => {
      const data = action.payload;

      return {
        ...state,
        ...data,
        paused: data.paused || false,
      };
    },

    RESET_AUDIO: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
