// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Information.info-station .station-description {
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Music/Mixes/Sidebar/Information.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;AADJ","sourcesContent":[".Information.info-station {\n\n  .station-description {\n    height: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
