// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Events {
  margin-bottom: 30px;
}
.Events .content-paginator {
  margin-bottom: 20px;
}
.Events .page-container {
  margin-bottom: 30px;
}
.Events .container {
  padding: 0 40px;
  padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/EditLocation/Content/Events.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,mBAAA;AADJ","sourcesContent":[".Events {\n  margin-bottom: 30px;\n\n  .content-paginator {\n    margin-bottom: 20px;\n  }\n\n  .page-container {\n    margin-bottom: 30px;\n  }\n\n  .container {\n    padding: 0 40px;\n    padding-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
