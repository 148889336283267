import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  toInteger,
} from 'lodash';
import {
  Field2 as Field,
} from 'vibeguide';
import './AlertEmailSettings.scss';

class AlertEmailSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      recipientsValue: '',
      selectedUnit: 'minutes',
    };
  }

  onChangeEmailFlagged = () => {
    const {
      emailFlaggedLocations,
      onUpdate,
    } = this.props;

    onUpdate({
      emailFlaggedLocations: !emailFlaggedLocations,
    });
  };

  onChangePropsInput = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    // convert the number string to an integer
    const convertVal = name === 'suppressNotificationMinutes'
      ? toInteger(value)
      : value;

    onUpdate({
      [name]: convertVal,
    });
  };

  onChangeStateInput = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  onSetEmails = (recipients) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      recipients,
    });

    this.setState({
      recipientsValue: '',
    });
  };

  render() {
    const {
      className,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
      hideSuppressNotificationMinutes,
    } = this.props;

    const {
      recipientsValue,
      selectedUnit,
    } = this.state;

    return (
      <div className={classNames('AlertEmailSettings', className)}>
        <div className="section">
          <div className="section-title">
            Alert Email Settings
          </div>

          <div className="section-content">
            <Field
              type="toggle"
              className="email-flagged-toggle"
              toggleProps={{
                label: 'Email Flagged Locations',
              }}
              value={emailFlaggedLocations ? 'on' : 'off'}
              onChange={this.onChangeEmailFlagged}
            />

            {!hideSuppressNotificationMinutes && (
              <div className="setting-container">
                <Field
                  type="label"
                  label="Do Not Send More Than Once Every"
                />

                <div className="setting">
                  <Field
                    className="setting-input"
                    type="number"
                    name="suppressNotificationMinutes"
                    value={suppressNotificationMinutes}
                    numberProps={{
                      min: 0,
                      max: 100,
                    }}
                    onChange={this.onChangePropsInput}
                  />

                  <Field
                    className="setting-unit"
                    type="select"
                    value={selectedUnit}
                    name="selectedUnit"
                    options={[
                      {
                        label: 'minutes',
                        value: 'minutes',
                      },
                    ]}
                    disabled
                    onChange={this.onChangeStateInput}
                  />
                </div>
              </div>
            )}

            <div className="setting-container">
              <Field
                type="inputChips"
                label="Only Send To"
                name="recipientsValue"
                chipProps={{
                  chips: recipients,
                  assign: true,
                  onSet: this.onSetEmails,
                }}
                value={recipientsValue}
                placeholder="(Separate by comma to add multiple emails at once)"
                onChange={this.onChangeStateInput}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AlertEmailSettings.propTypes = {
  className: PropTypes.string,
  emailFlaggedLocations: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.string),
  suppressNotificationMinutes: PropTypes.number,
  hideSuppressNotificationMinutes: PropTypes.bool,
  onUpdate: PropTypes.func,
};

AlertEmailSettings.defaultProps = {
  className: '',
  emailFlaggedLocations: false,
  recipients: [],
  suppressNotificationMinutes: 0,
  hideSuppressNotificationMinutes: false,
  onUpdate: () => {},
};

export default AlertEmailSettings;
