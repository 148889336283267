import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  convertDuration,
} from '../../utils/TimeUtil';
import VibeIcon from '../VibeIcon/VibeIcon';
import viPlayCircleOutline from '../../icons/viPlayCircleOutline';
import viPauseCircleOutline from '../../icons/viPauseCircleOutline';
import rootColor from '../../sass/color.scss';
import './PlayControlPlayer.scss';

class PlayControlPlayer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAudioPlaying: false,
      isAudioLoaded: false,
      totalSeconds: 0,
      totalAudioDuration: '...',
      currentAudioTime: '',
    };

    this.audioRef = React.createRef();
    this.playRef = React.createRef();

    document.addEventListener('onPlayAudio', this.onPlayAudio);
    document.addEventListener('onPauseAudio', this.onPauseAudio);
  }

  componentDidMount() {
    const {
      autoPlay,
    } = this.props;

    const {
      audioRef: {
        current: audioRef,
      },
    } = this;

    if (autoPlay && audioRef) {
      // start playing the audio
      this.onPlay();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      url,
      src,
      scrubToPercent,
      autoPlay,
    } = this.props;

    const {
      url: prevUrl,
      src: prevSrc,
      scrubToPercent: prevScrubToPercent,
    } = prevProps;

    const {
      totalSeconds,
    } = this.state;

    const {
      audioRef: {
        current: audioRef,
      },
    } = this;

    if (url !== prevUrl || src !== prevSrc) {
      this.setState({
        isAudioPlaying: false,
        isAudioLoaded: false,
        totalSeconds: 0,
        totalAudioDuration: '...',
        currentAudioTime: '',
      }, () => {
        if (audioRef) {
          // update the audio ref source
          audioRef.src = url || src;

          if (autoPlay) {
            // start playing th new audio
            this.onPlay();
          }
        }
      });
    } else if (audioRef && scrubToPercent !== prevScrubToPercent) {
      // scrub to a new position
      // get the new time in seconds
      const newTimeSeconds = totalSeconds * (scrubToPercent / 100);
      const currentAudioTime = convertDuration(newTimeSeconds, 'm:ss');
      audioRef.currentTime = newTimeSeconds;

      this.setState({
        currentAudioTime,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onPlayAudio', this.onPlayAudio);
    document.removeEventListener('onPauseAudio', this.onPauseAudio);
  }

  /**
   * Audio message has loaded metadata information (such as duration)
   */
  onLoadedMetadata = () => {
    const {
      audioRef: {
        current: audioRef,
      },
    } = this;
    const totalSeconds = get(audioRef, 'duration', 0);
    const totalAudioDuration = convertDuration(totalSeconds, 'm:ss');

    this.setState({
      totalSeconds,
      totalAudioDuration,
    });
  };

  /**
   * Audio message is being played and the elapsed time changes
   */
  onTimeUpdate = () => {
    const {
      onTimeUpdate,
    } = this.props;

    const {
      totalSeconds,
      totalAudioDuration,
    } = this.state;

    const {
      audioRef: {
        current: audioRef,
      },
    } = this;

    const currentSeconds = get(audioRef, 'currentTime', 0);
    const currentAudioTime = convertDuration(currentSeconds, 'm:ss');
    const currentPercent = (currentSeconds / totalSeconds) * 100;
    const currentDuration = `${currentAudioTime}/${totalAudioDuration}`;

    this.setState({
      currentAudioTime,
    });

    // Send the current time back to the parent component
    onTimeUpdate({
      currentPercent,
      currentSeconds,
      currentDuration,
    });

    // tell listening components the audio has played
    document.dispatchEvent(new CustomEvent('onPlayControlTimeUpdate', {
      detail: currentDuration,
    }));
  };

  /**
   * Audio message has finished playing
   */
  onEnded = () => {
    this.setState({
      isAudioPlaying: false,
      isAudioLoaded: false,
      currentAudioTime: '',
    });
  };

  /**
   * Another component asks to play the play control (Audio Player)
   */
  onPlayAudio = () => {
    const {
      audioPlayer,
    } = this.props;

    if (audioPlayer) {
      this.onPlay();
    }
  };

  /**
   * Another component asks to pause the play control (Audio Player)
   */
  onPauseAudio = () => {
    const {
      audioPlayer,
    } = this.props;

    if (audioPlayer) {
      this.onPause();
    }
  };

  /**
   * Audio message is being played
   */
  onPlay = () => {
    const {
      onPlay,
    } = this.props;

    const {
      audioRef: {
        current: audioRef,
      },
    } = this;

    this.playRef.current = audioRef.play();

    this.setState({
      isAudioPlaying: true,
    });

    onPlay();

    document.dispatchEvent(new Event('onPlayControlPlay'));
  };

  /**
   * Audio message has been paused
   */
  onPause = () => {
    const {
      onPause,
    } = this.props;

    const {
      audioRef: {
        current: audioRef,
      },
      playRef: {
        current: playRef,
      },
    } = this;

    if (playRef !== undefined) {
      playRef.then(() => {
        audioRef.pause();
      })
        .catch(error => {
          console.error('Play button error: ', error);
        });
    }

    this.setState({
      isAudioPlaying: false,
    });

    onPause();

    document.dispatchEvent(new Event('onPlayControlPause'));
  };

  /**
   * Load the audio message to get metadata
   */
  loadAudio = () => {
    this.setState({
      isAudioLoaded: true,
    });
  };

  /**
   * Play/Pause the audio message
   */
  togglePlayAudio = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      disabled,
    } = this.props;

    const {
      isAudioPlaying,
    } = this.state;

    const {
      audioRef: {
        current: audioRef,
      },
    } = this;

    if (disabled) {
      // do not attempt to play/pause a disabled control
      return;
    }

    if (audioRef) {
      // Ensure the audio element has a reference
      if (isAudioPlaying) {
        // Pause the audio element
        audioRef.pause();
      } else if (!isAudioPlaying) {
        audioRef.play();
      }
    }
  };

  render() {
    const {
      url,
      src,
      className,
      color,
      disabled,
      style,
      size,
      playIcon,
      playIconProps,
      pauseIcon,
      pauseIconProps,
      tooltipProps,
      playText,
      pauseText,
    } = this.props;

    const {
      totalAudioDuration,
      currentAudioTime,
      isAudioPlaying,
      isAudioLoaded,
    } = this.state;

    const playAudioIcon = isAudioPlaying ? (
      <VibeIcon
        className={classNames('icon', className, pauseIconProps.className, {
          disabled,
        })}
        icon={pauseIcon || viPauseCircleOutline}
        color={pauseIconProps.color || color || rootColor.violetVibe}
        tooltip={`${pauseText} (${currentAudioTime}/${totalAudioDuration})`}
        tooltipProps={tooltipProps}
        size={pauseIconProps.size || size}
      />
    ) : (
      <VibeIcon
        className={classNames('icon', className, pauseIconProps.className, {
          disabled,
        })}
        icon={playIcon || viPlayCircleOutline}
        color={playIconProps.color || color || rootColor.violetVibe}
        tooltip={!disabled
          ? `${playText} ${currentAudioTime
            ? `(${currentAudioTime}/${totalAudioDuration})`
            : `(${totalAudioDuration})`}`
          : null}
        tooltipProps={tooltipProps}
        size={playIconProps.size || size}
        onMouseEnter={this.loadAudio}
      />
    );

    const audioControlHtml = isAudioLoaded || url || src ? (
      <audio
        ref={this.audioRef}
        onPlay={this.onPlay}
        onPause={this.onPause}
        onEnded={this.onEnded}
        onLoadedMetadata={this.onLoadedMetadata}
        onTimeUpdate={this.onTimeUpdate}
      >
        <source
          src={url || src}
          type="audio/mpeg"
        />
        Your browser does not support the audio element.
      </audio>
    ) : null;

    return (
      <span
        className={classNames('PlayControlPlayer', { disabled })}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        style={{
          height: size,
          ...style,
        }}
        onClick={this.togglePlayAudio}
      >
        {playAudioIcon}
        {audioControlHtml}
      </span>
    );
  }
}

PlayControlPlayer.propTypes = {
  /** Icon class name */
  className: PropTypes.string,
  /** Audio URL */
  url: PropTypes.string,
  /** Audio source if no URL provided */
  src: PropTypes.string,
  /** Automatically start playing */
  autoPlay: PropTypes.bool,
  /** Color of the icons */
  color: PropTypes.string,
  /** Disable the control icon */
  disabled: PropTypes.bool,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Size of the play control icons */
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /** Play icon */
  playIcon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  /** Play icon props */
  playIconProps: PropTypes.shape({
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  /** Pause icon */
  pauseIcon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  /** Pause icon props */
  pauseIconProps: PropTypes.shape({
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  /** Tooltip props */
  tooltipProps: PropTypes.shape({
    placement: PropTypes.string,
  }),
  /** Custom play text when hovering over the control */
  playText: PropTypes.string,
  /** Custom pause text when hovering over the control */
  pauseText: PropTypes.string,
  /** Percent of audio time when scrubbing */
  scrubToPercent: PropTypes.number,
  /** Is the play control tied to the Audio Player component */
  audioPlayer: PropTypes.bool,
  /** When the audio is played */
  onPlay: PropTypes.func,
  /** When the audio is paused */
  onPause: PropTypes.func,
  /** When the audio progresses */
  onTimeUpdate: PropTypes.func,
};

PlayControlPlayer.defaultProps = {
  className: '',
  url: '',
  src: '',
  autoPlay: false,
  color: null,
  disabled: false,
  style: {},
  size: 16,
  playIcon: null,
  playIconProps: {
    className: '',
    color: null,
    size: null,
  },
  pauseIcon: null,
  pauseIconProps: {
    className: '',
    color: null,
    size: null,
  },
  tooltipProps: {
    placement: 'bottom',
  },
  playText: 'Play Message',
  pauseText: 'Pause Message',
  scrubToPercent: 0,
  audioPlayer: false,
  onPlay: () => {},
  onPause: () => {},
  onTimeUpdate: () => {},
};

export default PlayControlPlayer;
