import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import API from '../../api';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './BaselineSelector.scss';

/* Baseline Selection Field for Forms */
class BaselineSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    // Only allow baseline to fetch once when component updates
    this.hasUpdated = false;
    this.onBlurTimeout = null;

    this.state = {
      baselines: [],
      isBaselinesOpen: false,
      selectedBaselineIndex: 0,
    };
  }

  componentDidMount() {
    const {
      baselineId,
      baselineName,
    } = this.props;

    if (baselineId || baselineName) {
      this.getBaseline();
    }
  }

  componentDidUpdate() {
    const {
      baselineId,
      baselineName,
    } = this.props;

    if (!this.hasUpdated && baselineId) {
      // Fetch baseline details
      this.hasUpdated = true;
      this.getBaseline();
    } else if (this.hasUpdated && baselineId && !baselineName) {
      // Need to fetch baseline to get the name
      this.getBaseline();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.onBlurTimeout);
  }

  onFocus = () => {
    const {
      onFocus,
    } = this.props;

    onFocus();
  };

  onChangeBaseline = (e) => {
    const {
      locationId,
      onChange,
    } = this.props;

    const {
      target: {
        value = '',
      },
    } = e;

    // Baseline must be selected from the dropdown list
    onChange({
      baselineId: '',
      baselineName: value,
    });

    if (value === '') {
      this.setState({
        baselines: [],
        isBaselinesOpen: false,
        selectedBaselineIndex: 0,
      });

      return;
    }

    const searchName = value.trim();

    API.Location.listBaselines({
      _id: locationId,
      filters: {
        name: searchName,
        active: true,
      },
    }).then((result) => {
      const baselines = result.data;

      this.setState({
        baselines,
        isBaselinesOpen: true,
      });
    });
  };

  onBlurBaseline = (e) => {
    const {
      onBlur,
    } = this.props;

    // Ensure blur fires after selecting a baseline
    this.onBlurTimeout = setTimeout(() => {
      this.setState({
        baselines: [],
        isBaselinesOpen: false,
        selectedBaselineIndex: 0,
      });
    }, 500);

    onBlur(e);
  };

  onKeyDownBaseline = (e) => {
    const {
      onChange,
    } = this.props;

    if (e.key === 'ArrowDown') {
      this.setState((state) => {
        const nextIndex = state.selectedBaselineIndex + 1;

        return {
          selectedBaselineIndex: nextIndex < state.baselines.length ? nextIndex : state.baselines.length - 1,
        };
      });
    } else if (e.key === 'ArrowUp') {
      this.setState((state) => {
        const prevIndex = state.selectedBaselineIndex - 1;

        return {
          selectedBaselineIndex: prevIndex >= 0 ? prevIndex : 0,
        };
      });
    } else if (e.key === 'Enter') {
      this.setState((state) => {
        const baseline = get(state, `baselines[${state.selectedBaselineIndex}]`, null);

        if (!baseline) {
          // No baseline found to select
          return state;
        }

        onChange({
          baselineId: baseline._id,
          baselineName: baseline.name,
        });

        return {
          baselines: [],
          isBaselinesOpen: false,
          selectedBaselineIndex: 0,
        };
      });
    }
  };

  getBaseline = () => {
    const {
      baselineId,
      onChange,
    } = this.props;

    const searchId = encodeURIComponent(baselineId);

    API.Baseline.getById(searchId).then((result) => {
      const { baseline } = result;

      onChange({
        baselineId: baseline._id,
        baselineName: baseline.name,
      });
    });
  };

  selectBaseline = (baseline) => {
    const {
      onChange,
    } = this.props;

    onChange({
      baselineId: baseline._id,
      baselineName: baseline.name,
    });

    this.setState({
      baselines: [],
      isBaselinesOpen: false,
      selectedBaselineIndex: 0,
    });
  };

  clearBaseline = () => {
    const {
      onChange,
    } = this.props;

    const {
      inputRef: {
        current: inputRef,
      },
    } = this;

    onChange({
      baselineId: '',
      baselineName: '',
    });

    if (inputRef) {
      inputRef.focus();
    }
  };

  render() {
    const {
      fieldId,
      baselineName,
      disabled,
      tabIndex,
      autoFocus,
      dropdownPosition,
      onClick,
    } = this.props;

    const {
      baselines,
      isBaselinesOpen,
      selectedBaselineIndex,
    } = this.state;

    let topPosition = 40;

    if (dropdownPosition === 'top' && baselines.length <= 5) {
      // Set top position based on the number of results
      topPosition = (baselines.length * 40) * -1;
    } else if (dropdownPosition === 'top' && baselines.length > 5) {
      // Set top position based on maximum height
      topPosition = -200;
    }

    return (
      <div
        className="BaselineSelector"
        onClick={onClick}
      >
        <div className="baseline-input-container">
          <input
            ref={this.inputRef}
            tabIndex={tabIndex}
            className={classNames('field-input', 'baseline-input', { 'dropdown-active': isBaselinesOpen })}
            id={fieldId}
            name="baseline"
            type="text"
            placeholder="Search for a baseline..."
            value={baselineName}
            onFocus={this.onFocus}
            onChange={this.onChangeBaseline}
            onBlur={this.onBlurBaseline}
            onKeyDown={this.onKeyDownBaseline}
            autoComplete="off"
            autoFocus={autoFocus}
            disabled={disabled}
          />

          {!disabled ? (
            <div className="baseline-clear" onClick={this.clearBaseline}>
              <VibeIcon
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={16}
              />
            </div>
          ) : null}
        </div>

        {isBaselinesOpen ? (
          <div
            className="baseline-select"
            style={{
              top: topPosition,
            }}
          >
            {baselines.map((baseline, index) => {
              return (
                <div
                  key={baseline._id}
                  className={classNames('baseline-item', { selected: selectedBaselineIndex === index })}
                  data-id={baseline._id}
                  data-name={baseline.name}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={this.selectBaseline.bind(this, baseline)}
                >
                  {baseline.name}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

BaselineSelector.propTypes = {
  fieldId: PropTypes.string,
  baselineName: PropTypes.string,
  baselineId: PropTypes.string,
  locationId: PropTypes.string,
  disabled: PropTypes.bool,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Auto focus on the input */
  autoFocus: PropTypes.bool,
  /** Dropdown position */
  dropdownPosition: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

BaselineSelector.defaultProps = {
  fieldId: 'baseline-selector',
  baselineName: '',
  baselineId: '',
  locationId: '',
  disabled: false,
  tabIndex: 0,
  autoFocus: false,
  dropdownPosition: 'bottom',
  onClick: () => {},
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

export default BaselineSelector;
