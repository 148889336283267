import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  toInteger,
} from 'lodash';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import withRouter from '../../wrappers/withRouter';
import {
  updateParams,
} from '../../helpers/Navigation';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viChevronLeft from '../../icons/viChevronLeft';
import viChevronRight from '../../icons/viChevronRight';
import viArrowDown from '../../icons/viArrowDown';
import viCheck from '../../icons/viCheck';
import color from '../../sass/color.scss';
import './TablePaginator.scss';

function paginate({
  totalItems,
  currentPage,
  pageSize,
  maxPages,
}) {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage = 0;
  let endPage = 0;

  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };
}

class TablePaginator extends PureComponent {
  constructor(props) {
    super(props);

    const {
      page,
      totalItems,
      pageSize,
    } = props;

    const pageData = paginate({
      totalItems,
      currentPage: page,
      pageSize,
      maxPages: 5,
    });

    const {
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    } = pageData;

    this.state = {
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
      showItemsPerPageDropdown: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      totalItems,
      page,
      pageSize,
    } = this.props;

    const {
      totalItems: prevTotalItems,
      page: prevPage,
      pageSize: prevPageSize,
    } = prevProps;

    if (page !== prevPage || totalItems !== prevTotalItems || pageSize !== prevPageSize) {
      // Page changed or total items updated
      const pageData = paginate({
        totalItems,
        currentPage: page,
        pageSize,
        maxPages: 5,
      });

      const {
        totalPages,
        startPage,
        endPage,
        startIndex,
        endIndex,
        pages,
      } = pageData;

      this.setState({
        totalPages,
        startPage,
        endPage,
        startIndex,
        endIndex,
        pages,
      });
    }
  }

  /**
   * When the page is changed when not using URL to page data
   */
  onChangePage = (e) => {
    const {
      target: {
        dataset: {
          page,
        },
      },
    } = e;

    const {
      urlPaging,
      onChangePage,
    } = this.props;

    if (!urlPaging) {
      e.preventDefault();
    }

    onChangePage(parseInt(page, 10));
  };

  /**
   * When they select a custom page from the dropdown
   */
  onSelectPage = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      history,
      urlPaging,
      onChangePage,
    } = this.props;

    if (value === '...') {
      // The page did not change
      return;
    }

    onChangePage(parseInt(value, 10));

    if (urlPaging) {
      const pagePath = updateParams({
        page: value,
      });

      history(pagePath);
    }
  };

  selectItemsPerPage = (e) => {
    const {
      target: {
        dataset: {
          size,
        },
      },
    } = e;

    const {
      onChangePageSize,
    } = this.props;

    const newPageSize = toInteger(size) > 0
      ? toInteger(size)
      : 50;

    onChangePageSize(newPageSize);
    this.closeItemsPerPageDropdown();
  };

  openItemsPerPageDropdown = () => {
    this.setState({
      showItemsPerPageDropdown: true,
    });
  };

  closeItemsPerPageDropdown = () => {
    this.setState({
      showItemsPerPageDropdown: false,
    });
  };

  /**
   * Render the page list for the select input
   */
  renderPageList = () => {
    const {
      totalPages,
    } = this.state;

    const list = [];

    for (let i = 1; i <= totalPages; i++) {
      list.push(
        <option key={`page-${i}`}>{i}</option>,
      );
    }

    return (
      <select
        className="page-select"
        value="..."
        onChange={this.onSelectPage}
      >
        <option value="...">. . .</option>
        {list}
      </select>
    );
  };

  render() {
    const {
      className,
      label,
      page,
      totalItems,
      pageSize,
      pageSizes,
    } = this.props;

    const {
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
      showItemsPerPageDropdown,
    } = this.state;

    const nextPage = page + 1 <= totalPages
      ? page + 1
      : totalPages;
    const prevPage = page - 1 > 0
      ? page - 1
      : 1;

    const showPrevDots = startPage !== 1;
    const showNextDots = endPage !== totalPages;
    const disablePrev = page <= 1;
    const disableNext = page >= totalPages;
    const disablePages = pages.length <= 1;

    const firstItemNum = (startIndex + 1).toLocaleString();
    const lastItemNum = (endIndex + 1).toLocaleString();
    const totalItemsNum = totalItems.toLocaleString();

    const pageList = showPrevDots || showNextDots
      ? this.renderPageList()
      : null;

    const prevPagePath = updateParams({
      page: prevPage === 1
        ? null
        : prevPage,
    });

    const nextPagePath = updateParams({
      page: nextPage === 1
        ? null
        : nextPage,
    });

    const itemsPerPageMenuItems = [(
      <MenuItem
        key="header"
        className="menu-item disabled"
        disableRipple
      >
        Show up to
      </MenuItem>
    )];

    pageSizes.forEach((option, index) => {
      itemsPerPageMenuItems.push(
        <MenuItem
          key={index}
          className="menu-item"
          data-size={option}
          onClick={this.selectItemsPerPage}
        >
          <div className="text">
            {option}
          </div>

          {pageSize === option ? (
            <VibeIcon
              icon={viCheck}
              color={color.white}
            />
          ) : null}
        </MenuItem>,
      );
    });

    return (
      <div className={classNames('TablePaginator', className)}>
        <Link
          className={classNames('page', 'page-prev', { disabled: disablePrev })}
          to={prevPagePath}
          data-page={prevPage}
          onClick={this.onChangePage}
        >
          <VibeIcon
            icon={viChevronLeft}
            color={color.manatee}
            size={24}
            style={{
              pointerEvents: 'none',
            }}
          />
        </Link>

        {showPrevDots ? (
          <VibeTooltip
            title="Click to jump to a page"
            placement="top"
          >
            <div className="page">
              {pageList}
            </div>
          </VibeTooltip>
        ) : null}

        {pages.map((pageNum) => {
          const pagePath = updateParams({
            // remove ?page=1 from the URL
            page: pageNum === 1
              ? null
              : pageNum,
          });

          return (
            <Link
              key={pageNum}
              className={classNames('page', { active: page === pageNum, disabled: disablePages })}
              to={pagePath}
              data-page={pageNum}
              onClick={this.onChangePage}
            >
              {pageNum}
            </Link>
          );
        })}

        {showNextDots ? (
          <VibeTooltip
            title="Click to jump to a page"
            placement="top"
          >
            <div className="page">
              {pageList}
            </div>
          </VibeTooltip>
        ) : null}

        <Link
          className={classNames('page', 'page-next', { disabled: disableNext })}
          to={nextPagePath}
          data-page={nextPage}
          onClick={this.onChangePage}
        >
          <VibeIcon
            icon={viChevronRight}
            color={color.manatee}
            size={24}
            style={{
              pointerEvents: 'none',
            }}
          />
        </Link>

        <div className="count-container">
          {startIndex > -1
            ? `${firstItemNum}-${lastItemNum} `
            : '0 '}
          of {totalItemsNum}
        </div>

        <div
          className={classNames('label-container', { 'label-container-dropdown': pageSizes.length > 1 })}
          ref={(ref) => { this.itemsPerPageDropdownRef = ref; }}
          onClick={pageSizes.length > 1
            ? this.openItemsPerPageDropdown
            : () => {}}
        >
          {pageSizes.length > 1 ? (
            <VibeTooltip
              title="Change Page Size"
              placement="top"
            >
              <div>
                {label}
              </div>
            </VibeTooltip>
          ) : label}

          {pageSizes.length > 1
            ? (
              <VibeIcon
                color={color.manatee}
                icon={viArrowDown}
                size={12}
              />
            )
            : null}
        </div>

        {pageSizes.length > 1
          ? (
            <Menu
              id="label-menu"
              classes={{
                paper: 'PageLayoutPaper',
              }}
              className="table-paginator-menu"
              anchorEl={this.itemsPerPageDropdownRef}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={showItemsPerPageDropdown}
              onClose={this.closeItemsPerPageDropdown}
            >
              {itemsPerPageMenuItems}
            </Menu>
          )
          : null }
      </div>
    );
  }
}

TablePaginator.propTypes = {
  /** Custom Class */
  className: PropTypes.string,
  /** Label for end of paginator */
  label: PropTypes.string,
  /** Current Page */
  page: PropTypes.number,
  /** Total Items */
  totalItems: PropTypes.number,
  /** Use the URL for page links */
  urlPaging: PropTypes.bool,
  /** Items per page */
  pageSize: PropTypes.number,
  /** Items per page options */
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  /** When items per page is changed */
  onChangePageSize: PropTypes.func,
  /** When the page is changed not using URL paging */
  onChangePage: PropTypes.func,
};

TablePaginator.defaultProps = {
  className: '',
  label: '',
  page: 1,
  totalItems: 0,
  pageSize: 50,
  pageSizes: [50],
  urlPaging: false,
  onChangePageSize: () => {},
  onChangePage: () => {},
};

export default withRouter(TablePaginator);
