/* eslint-disable no-use-before-define */
import React from 'react';
import jwt from 'jsonwebtoken';
import {
  store,
} from '../lib/styleguide/Wrapper';
import {
  doAuthenticateUser,
} from '../actions/Login/LoginActions';
import {
  setModal,
} from '../actions/Global/GlobalActions';
import {
  socketConnect,
} from '../actions/Socket/SocketActions';
import {
  getCookie,
} from './CookieUtil';
import {
  getLocal,
  setLocal,
  removeLocal,
} from './StorageUtil';
import {
  saveTokens,
  refreshHostedContent,
  getSession,
  authenticate,
  logout as authLogout,
} from './AuthUtil';
import API from '../api';
import color from '../sass/color.scss';
import pkg from '../../../../package.json';

// Interval to check if there is a new version (seconds)
const versionTimerInterval = 60;
// Timer to see if cookies need to be refreshed
let versionTimer = null;
// Only connect to the socket once (let socket reconnect attempts re-establish a connection)
let ranSocketConnect = false;

/**
 * Reload the app
 */
function reloadApp() {
  window.location.reload();
}

/**
 * Prevent the update app modal from showing until the user manually refreshes
 */
function preventUpdate() {
  setLocal('app:ignoreUpdate', true);
}

async function getProfile(userId, token) {
  const profile = await API.User.getById(userId, { token });

  return profile;
}

async function checkVersion() {
  try {
    const ignoreUpdate = getLocal('app:ignoreUpdate', false);
    const versionResponse = await API.Local.getVersion();

    // Do not prompt to update if they chose to wait until next refresh
    if (!ignoreUpdate
      && versionResponse.version !== undefined
      && versionResponse.version !== pkg.version
    ) {
      // Version mismatch, prompt to reload
      store.dispatch(setModal({
        type: 'confirm',
        confirmProps: {
          text: 'Update to New Version',
          style: {
            color: color.aquaForest,
          },
        },
        cancelProps: {
          text: 'Not Yet',
          style: {
            color: color.manatee,
          },
        },
        title: 'Update Available',
        message: (
          <div>
            An update is available for the web application. Would you like to update?
            <div
              style={{
                marginTop: 8,
              }}
            >
              Any unsaved changes will be lost.
            </div>
          </div>
        ),
        onConfirm: reloadApp,
        onCancel: preventUpdate,
      }));
    }
  } catch (err) {
    console.error('checkVersion(): Could not get version', err);
  }
}

/**
 * This function carries out the tasks that are run every time a user is successfully authenticated
 * @param {* This is an object that contains the response from the api from a successful Authentication} authDetails
 */
async function authenticationTasks(authDetails) {
  const tokens = authDetails.data || {};
  const {
    idToken,
  } = tokens;

  if (!idToken) {
    return null;
  }

  saveTokens(tokens);
  await refreshHostedContent({
    idToken,
  });

  const tokenPayload = jwt.decode(idToken);
  const userId = tokenPayload['custom:vibenomics_id'];
  // Fetch user profile
  const profile = await getProfile(userId, idToken);
  // Auth user object
  const authUser = tokenPayload;

  // Overwrite permissions with fetched profile permissions
  authUser.permissions = profile.permissions;

  // Merge profile with user object
  const userObj = {
    ...profile,
    ...authUser,
  };

  // console.log('User has logged in');
  store.dispatch(doAuthenticateUser(true, userObj));

  if (!ranSocketConnect) {
    // Connect to the socket
    const url = process.env.VAPI_SOCKET_HOST_URL ?? process.env.VAPI_BASE_URL.slice(0, -3);

    store.dispatch(socketConnect({
      url,
    }));

    // do not connect to the socket more than once
    ranSocketConnect = true;
  }

  if (!versionTimer) {
    // only set the version check timer once
    versionTimer = setInterval(checkVersion, versionTimerInterval * 1000);
  }

  return userObj;
}

/**
 * This function will authenticate the user on login
 * @param {* This is an object with the values equaling the Username and Password as keys} authenticationData
 */
export const authenticateUser = async (authenticationData) => {
  // Getting the authentication details for the new authenticate req
  const authData = {
    authType: 'credentials',
    authParams: {
      username: authenticationData.Username,
      password: authenticationData.Password,
    },
  };
  const authenticationDetails = await authenticate(authData);

  if (authenticationDetails[0].type === 'AUTHENTICATION.AUTHENTICATED') {
    await authenticationTasks(authenticationDetails[0]);
  } else if (authenticationDetails[0].type === 'AUTHENTICATION.CHALLENGED') {
    // Return Auth Response details so LoginContainer knows to show First Time Login Screen
    return authenticationDetails[0];
  }

  return authenticationDetails;
};

/**
 * This function will authenticate the user on login
 * @param {* This is an object with the values equaling the Username and Password as keys} authenticationData
 * @param {* This is a updated password from the user for first time login.
 * If it is preset we will update if, if not we will go with the first time
 * login verification} updatedPassword
 */
export const acceptChallenge = async (challengeDetails, updatedPassword) => {
  if (challengeDetails && updatedPassword) {
    // Getting the details for the new password challenge
    const challengeData = {
      challengeType: 'newPasswordRequired',
      challengeParams: {
        session: challengeDetails.data.session,
        username: challengeDetails.data.externalUserId,
        newPassword: updatedPassword,
      },
    };
    const acceptChallengeResponse = await API.Authentication.acceptChallenge(challengeData);

    if (acceptChallengeResponse[0].type === 'AUTHENTICATION.AUTHENTICATED') {
      await authenticationTasks(acceptChallengeResponse[0]);
    } else if (acceptChallengeResponse[0].type === 'AUTHENTICATION.CHALLENGED') {
      // Return Response details so LoginContainer knows to show First Time Login Screen
      return acceptChallengeResponse[0];
    }

    return acceptChallengeResponse;
  }

  return null;
};

/**
 * This is the function that will reset the password for FORGOTTEN passwords (Part 1)
 */
export const forgotPassword = username => {
  const resetData = {
    username,
  };

  const resetPassResponse = API.Authentication.resetPassword(resetData);
  return resetPassResponse;
};

/**
 * This function resets the password for the user (Part 2)
 * @param {* This is the verification code that was emailed to the user and provided in
 * the verification code box} verificationCode
 * @param {* This is the new password provided by the user} newPassword
 */
export const confirmResetPassword = (username, verificationCode, newPassword) => {
  const passwordData = {
    code: verificationCode,
    username,
    newPassword,
  };

  const resetPassResponse = API.Authentication.confirmPassword(passwordData);
  return resetPassResponse.type === 'AUTHENTICATION.RESET_PASSWORD_CONFIRMED'
    ? 'Password Successfully Reset'
    : resetPassResponse;
};

/**
 * Function that changes the current password for an authenticated user inside of the application
 * @param {oldPassword} is the old password that the user had
 * @param {newPassword} is the new password that the user wants to change it to
 */
export const changeUserPassword = (id, oldPassword, newPassword) => {
  const accessToken = getCookie('app:user:accessToken');

  const changePasswordData = {
    _id: id,
    oldPassword,
    newPassword,
    accessToken,
  };

  const changePassResponse = API.User.changePassword(changePasswordData);
  return changePassResponse;
};

export const getUserInfo = async () => {
  const tokens = await getSession();
  const {
    accessToken,
    idToken,
    refreshToken,
  } = tokens;

  const user = await authenticationTasks({
    data: {
      accessToken,
      idToken,
      refreshToken,
    },
  });

  return user;
};

/**
 * Log the user out of the application
 */
export const logout = () => {
  // Clear the cookies
  authLogout();
  removeLocal('app:ignoreUpdate');
  clearInterval(versionTimer);
};

export default {
  getUserInfo,
  authenticateUser,
  acceptChallenge,
  forgotPassword,
  changeUserPassword,
  confirmResetPassword,
  logout,
};
