import React from 'react';

const viMusicVoice = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M20.968 14.003c-.284-.485-.535-.988-.813-1.477-.131-.23-.267-.46-.42-.677-.08-.115-.189-.192-.189-.334 0-.708.018-1.416.016-2.124-.006-2.109-.753-4.194-2.4-5.596-1.304-1.11-2.996-1.673-4.698-1.795v5.99c0 .057.361.253.422.3.247.195.469.421.658.672 1.476 1.957.51 4.853-1.845 5.579-2.288.704-4.698-1.006-4.744-3.368-.012-.63.144-1.258.454-1.809.144-.257.32-.495.522-.71.101-.107.209-.208.322-.302.043-.035.357-.227.357-.263V2.384c-1.126.29-2.133.695-2.913 1.578-.669.756-1.092 1.699-1.351 2.662-.6 2.231-.376 4.528.334 6.704.448 1.375.997 2.726 1.2 4.163.212 1.494.096 3.004.005 4.502l5.054-.008 2.527-.004h.606c.142 0 .386.045.518-.004.084-.03.085-.268.095-.335.028-.174.062-.354.15-.51.132-.236.337-.268.59-.305.82-.12 1.632-.29 2.442-.469.553-.122 1.069-.226 1.232-.834.126-.474-.036-.852-.174-1.293-.092-.296.209-.49.4-.66.294-.264.29-.537.019-.828.824-.055.518-.887.53-1.424.001-.06.007-.122.024-.18.03-.104.073-.09.177-.105.194-.027.392-.068.566-.159.271-.141.366-.388.355-.658.01-.013.004-.073-.028-.214" />
    </g>
  </svg>
);

export default viMusicVoice;
