import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableDeliverySystems,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import DeliverySystemSidebar from './Sidebar/DeliverySystemSidebar';

function DeliverySystemContainer({
  className,
  setPanel,
  history,
}) {
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      deliverySystemId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, deliverySystemId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      children: (
        <DeliverySystemSidebar
          deliverySystemId={deliverySystemId}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.deliverySystemId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.deliverySystemId);
    }
  }, [qs.type, qs.deliverySystemId]);

  return (
    <div
      className={classNames('DeliverySystemContainer', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        title="Delivery Systems"
        buttons={[
          {
            text: 'New Delivery System',
            icon: viAdd,
            userCan: [
              'flight_delivery_system.create',
            ],
            color: color.violetVibe,
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
          },
        ]}
      />

      <TableDeliverySystems
        columns={[
          'Name',
          '...',
        ]}
        fetch={API.Flight.DeliverySystem.list}
        defaultSortBy={{
          label: 'Name',
          attr: 'value',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Delivery Systems',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Delivery Systems',
          tooltip: true,
        }}
        rowLink={{
          deliverySystemId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'flight_delivery_system.delete' },
        ]}
      />
    </div>
  );
}

DeliverySystemContainer.propTypes = {
  className: PropTypes.string,
};

DeliverySystemContainer.defaultProps = {
  className: '',
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(DeliverySystemContainer));
