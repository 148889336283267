import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import DayColumnHeader from '../../../../Programs/Baselines/Calendar/ScheduleWeekData/DayColumnHeader';
import './WeekDates.scss';

function WeekDates({
  className,
  style,
  startOfWeek,
  archivedBaselines,
  midnightBaselines,
  musicOverride,
  dayOfWeekOnly,
  hideIcons,
}) {
  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ];

  return (
    <div
      className={classNames('WeekDates', className)}
      style={style}
    >
      {days.map((day) => {
        return (
          <DayColumnHeader
            key={`day-${day}`}
            day={day}
            startOfWeek={startOfWeek.clone()}
            scheduled={midnightBaselines[day]}
            archived={archivedBaselines[day]}
            musicOverride={musicOverride}
            dayOfWeekOnly={dayOfWeekOnly}
            hideIcons={hideIcons}
          />
        );
      })}
    </div>
  );
}

WeekDates.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  startOfWeek: PropTypes.instanceOf(moment).isRequired,
  archivedBaselines: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  midnightBaselines: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  musicOverride: PropTypes.bool,
  /** Only show the day of the week at the top of the column */
  dayOfWeekOnly: PropTypes.bool,
  /** Hide the icons for each column in the schedule (i.e. for Spot Values) */
  hideIcons: PropTypes.bool,
};

WeekDates.defaultProps = {
  className: '',
  style: {},
  archivedBaselines: {},
  midnightBaselines: {},
  musicOverride: false,
  dayOfWeekOnly: false,
  hideIcons: false,
};

export default WeekDates;
