import { handleActions } from 'redux-actions';

const initialState = {
  modal: {
    title: null,
    message: null,
    type: null,
    btnText: 'Ok',
    confirmProps: {},
    cancelProps: {},
    onConfirm: () => {},
    onCancel: () => {},
  },
  drag: {
    tableId: null,
    dragging: false,
    dragIds: null,
    dragOverId: null,
    dragType: null,
    contentDrawer: false,
  },
  panel: {
    title: null,
    width: 600,
    show: false,
    backdrop: false,
    icons: null,
    children: null,
    onClose: () => {},
    onCloseSecond: () => {},
    onCloseThird: () => {},
    onCloseFourth: () => {},
    extraPanel: {
      title: null,
      width: window.innerWidth,
      show: false,
      backdrop: false,
      children: null,
      onClose: () => {},
    },
    thirdPanel: {
      title: null,
      width: window.innerWidth,
      show: false,
      backdrop: false,
      children: null,
      onClose: () => {},
    },
    fourthPanel: {
      title: null,
      width: window.innerWidth,
      show: false,
      backdrop: false,
      children: null,
      onClose: () => {},
    },
  },
  lastUrl: '',
};

export default handleActions(
  {
    SET_GLOBAL_MODAL: (state, action) => {
      const modal = action.payload;

      return {
        ...state,
        modal,
      };
    },

    CLEAR_GLOBAL_MODAL: (state) => {
      return {
        ...state,
        modal: initialState.modal,
      };
    },

    SET_GLOBAL_DRAG: (state, action) => {
      const dragOptions = action.payload;

      return {
        ...state,
        drag: {
          ...state.drag,
          ...dragOptions,
        },
      };
    },

    RESET_GLOBAL_DRAG: (state) => {
      return {
        ...state,
        drag: initialState.drag,
      };
    },

    SET_GLOBAL_PANEL: (state, action) => {
      const panel = action.payload;

      return {
        ...state,
        panel: {
          ...state.panel,
          ...panel,
          extraPanel: {
            ...state.panel.extraPanel,
            ...panel.extraPanel,
          },
          thirdPanel: {
            ...state.panel.thirdPanel,
            ...panel.thirdPanel,
          },
          fourthPanel: {
            ...state.panel.fourthPanel,
            ...panel.fourthPanel,
          },
        },
      };
    },

    RESET_GLOBAL_PANEL: (state) => {
      return {
        ...state,
        panel: initialState.panel,
      };
    },

    SET_LAST_URL: (state, action) => {
      const lastUrl = action.payload;

      return {
        ...state,
        lastUrl,
      };
    },

    CLEAR_LAST_URL: (state) => {
      const lastUrl = '';

      return {
        ...state,
        lastUrl,
      };
    },
  },
  // Initial State
  initialState,
);
