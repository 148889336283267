import color from '../sass/color.scss';

/**
 * List of supported Order Status'
 */
export const statusList = [
  {
    name: 'Live',
    value: 'live',
    tagStyle: {
      background: color.aquaForest16,
      color: color.aquaForest,
    },
  },
  {
    name: 'Completed',
    value: 'completed',
    tagStyle: {
      background: color.violetVibe16,
      color: color.violetVibe,
    },
  },
  {
    name: 'Pending',
    value: 'pending',
    tagStyle: {
      background: color.yellow16,
      color: color.yellow,
    },
  },
  {
    name: 'Paused',
    value: 'paused',
    tagStyle: {
      background: color.fireBrick16,
      color: color.fireBrick,
    },
  },
  {
    name: 'Cancelled',
    value: 'cancelled',
    tagStyle: {
      background: color.manatee16,
      color: color.manatee,
    },
  },
];

export default {
  statusList,
};
