import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';
import VibeIcon from '../VibeIcon/VibeIcon';
import viSearch from '../../icons/viSearch';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './SearchInput.scss';

class SearchInput extends PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  /**
   * Clear Search
   */
  clear = () => {
    const {
      value,
      onChange,
    } = this.props;

    const {
      inputRef: {
        current: inputRef,
      },
    } = this;

    if (value) {
      onChange({
        target: {
          value: '',
        },
      });
    }

    if (inputRef) {
      inputRef.focus();
    }
  };

  render() {
    const {
      className,
      style,
      theme,
      value,
      autoFocus,
      placeholder,
      loading,
      onChange,
      onFocus,
    } = this.props;

    return (
      <div
        className={classNames('SearchInput', { dark: theme === 'dark' }, className)}
        style={style}
      >
        <VibeIcon
          className="search-icon"
          icon={viSearch}
          color={theme === 'dark' ? color.cosmicVibe : color.violetVibe}
          size={24}
        />

        {loading ? (
          <div className="loading">
            <CircularProgress
              color="inherit"
              size={20}
            />
          </div>
        ) : null}

        <VibeIcon
          className={classNames('clear-icon', { show: value !== '' })}
          icon={viClose}
          color={color.manatee}
          size={20}
          onClick={this.clear}
        />

        <input
          ref={this.inputRef}
          type="text"
          placeholder={placeholder}
          className="input-search"
          value={value}
          autoFocus={autoFocus}
          onChange={onChange}
          onFocus={onFocus}
        />
      </div>
    );
  }
}

SearchInput.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Theme */
  theme: PropTypes.oneOf([
    'light',
    'dark',
  ]),
  /** Search value */
  value: PropTypes.string,
  /** Auto focus the search box */
  autoFocus: PropTypes.bool,
  /** Placeholder for the search input */
  placeholder: PropTypes.string,
  /** When fetching data */
  loading: PropTypes.bool,
  /** When search value is changed */
  onChange: PropTypes.func,
  /** When search input is focused */
  onFocus: PropTypes.func,
};

SearchInput.defaultProps = {
  className: '',
  style: {},
  theme: 'light',
  value: '',
  autoFocus: false,
  placeholder: 'Search...',
  loading: false,
  onChange: () => {},
  onFocus: () => {},
};

export default SearchInput;
