import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viMicrophoneBubble,
  viMicrophoneLibrary,
  viMicrophoneList,
} from 'vibeguide';
import './MessagesContainer.scss';

function MessagesContainer({
  user,
}) {
  return (
    <div className="MessagesContainer">
      {!user.voiceTalent ? (
        <NavigationSide>
          <NavigationSideItem
            text="Requests"
            url="/messages/requests"
            icon={viMicrophoneBubble}
            show={user.hasAnyOf(['message.view', 'advertisement.view'])}
          />

          <NavigationSideItem
            text="Library"
            url="/messages/library"
            icon={viMicrophoneLibrary}
            show={user.hasAnyOf(['message.view', 'advertisement.view'])}
          />

          <NavigationSideItem
            text="Blocks"
            url="/messages/blocks"
            icon={viMicrophoneList}
            show={user.can('messagelist.view')}
          />
        </NavigationSide>
      ) : null}

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(MessagesContainer);
