import React from 'react';

function LoadingSpinner() {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner" />
      <div className="loading-spinner-overlay" />
    </div>
  );
}

export default LoadingSpinner;
