import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../../Header/SubNavigation';
import Overview from './Overview';
import SnippetCardList from './SnippetCardList/SnippetCardList';

function OrderDashboard() {
  return (
    <div
      className="OrderDashboard"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        title="Order Dashboard"
      />

      <Overview />

      <SnippetCardList />

      <div
        style={{
          margin: '0 2rem',
          paddingBottom: '2rem',
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default withRouter(OrderDashboard);
