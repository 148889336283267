import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CircularProgress,
} from '@mui/material';
import './VibeSplash.scss';

function VibeSplash({
  className,
  type,
  size,
  style,
  image,
  imageStyle,
  title,
  titleStyle,
  subtitle,
  subtitleStyle,
}) {
  if (type === 'loading') {
    return (
      <div
        className={classNames('VibeSplash', className)}
        style={style}
      >
        <CircularProgress
          color="inherit"
          size={size}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames('VibeSplash', className)}
      style={style}
    >
      {image ? (
        <img
          className="splash-img"
          style={imageStyle}
          src={image}
          alt={title}
        />
      ) : null}

      {title ? (
        <div
          className="splash-title"
          style={titleStyle}
        >
          {title}
        </div>
      ) : null}

      {subtitle ? (
        <div
          className="splash-subtitle"
          style={subtitleStyle}
        >
          {subtitle}
        </div>
      ) : null}
    </div>
  );
}

VibeSplash.propTypes = {
  /** Custom Class */
  className: PropTypes.string,
  /** Type of splashscreen */
  type: PropTypes.oneOf([
    'loading',
    'custom',
  ]),
  /** Size of loading icon */
  size: PropTypes.number,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Image */
  image: PropTypes.string,
  /** Image style */
  imageStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Title */
  title: PropTypes.string,
  /** Title style */
  titleStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Subtitle */
  subtitle: PropTypes.string,
  /** Subtitle style */
  subtitleStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

VibeSplash.defaultProps = {
  className: null,
  type: 'custom',
  size: 40,
  style: {},
  image: null,
  imageStyle: {},
  title: null,
  titleStyle: {},
  subtitle: null,
  subtitleStyle: {},
};

export default VibeSplash;
