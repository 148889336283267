import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  API,
  VibeIcon,
  viClose,
  color,
  TableLocations,
} from 'vibeguide';
import './CompanyLocations.scss';

function onExport(data) {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
  saveAs(blob, `locations-${timestamp}.csv`);
}

function CompanyLocations({
  companyId,
  user,
  onClose,
}) {
  return (
    <div className="CompanyLocations">
      <div className="title-container">
        <div className="title">
          Assigned Locations
        </div>

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <TableLocations
        columns={[
          '.',
          'Name',
          'Status',
          'Address 1',
          'Address 2',
          'City',
          'State/Province',
          'Postal Code',
          'Ad Network',
          'Ad Programs',
          'Tags',
          '...',
        ]}
        fetch={API.User.getLocations}
        filters={{
          companyId,
        }}
        fetchProps={{
          filters: {
            _id: user._id,
          },
        }}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Locations',
          urlPaging: false,
          urlFilters: false,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Locations',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: true,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        menuItems={[
          { name: 'Archive', userCan: 'location.delete' },
        ]}
        csv
        csvProps={{
          onExport,
        }}
      />
    </div>
  );
}

CompanyLocations.propTypes = {
  companyId: PropTypes.string,
  onClose: PropTypes.func,
};

CompanyLocations.defaultProps = {
  companyId: '',
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(CompanyLocations);
