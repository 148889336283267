import React from 'react';
import { useOutletContext } from 'react-router-dom';
import InfoCards from './InfoCards';

function InfoCardsOutlet() {
  const [orderData] = useOutletContext();

  return (
    <InfoCards
      data={orderData}
    />
  );
}

export default InfoCardsOutlet;
