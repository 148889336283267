import React from 'react';

const viUserAdd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M10 8c1.467 0 2.667-1.2 2.667-2.667 0-1.466-1.2-2.666-2.667-2.666a2.675 2.675 0 0 0-2.667 2.666C7.333 6.8 8.533 8 10 8zM4 6.667v-2H2.667v2h-2V8h2v2H4V8h2V6.667H4zm6 2.666c-1.8 0-5.333.867-5.333 2.667v1.333h10.666V12c0-1.8-3.533-2.667-5.333-2.667z" />
    </g>
  </svg>
);

export default viUserAdd;
