import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import VibeButtonNew from '../VibeButton/VibeButtonNew';
import color from '../../sass/color.scss';
import './VibeModal.scss';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    fontFamily: 'Lato, sans-serif',
  },
}));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

function VibeModal({
  className,
  style,
  width,
  height,
  fullWidth,
  maxWidth,
  type,
  title,
  text,
  confirmProps,
  cancelProps,
  btnText,
  show,
  children,
  onConfirm,
  onOpen,
  onClose,
}) {
  const prevShow = usePrevious(show);
  useEffect(() => {
    if (show && !prevShow) {
      // modal has been opened
      onOpen();
    }
  }, [show]);

  return (
    <BootstrapDialog
      className={classNames('VibeModal', className)}
      style={style}
      TransitionComponent={Transition}
      open={show}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      keepMounted
      onClose={onClose}
    >
      {title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}

      <DialogContent
        style={{
          color: color.obsidian,
          width,
          height,
          fontSize: 16,
          lineHeight: 1.2,
        }}
      >
        {text || children}
      </DialogContent>

      {(type === 'error' || type === 'confirm' || type === 'success') && (
        <DialogActions>
          {type === 'error' && (
            <VibeButtonNew
              variant="text"
              text={btnText}
              color={color.fireBrick}
              onClick={onClose}
            />
          )}

          {type === 'confirm' && (
            <VibeButtonNew
              variant="text"
              text={cancelProps.text}
              style={cancelProps.style}
              color={get(cancelProps, 'color', color.manatee)}
              disabled={cancelProps.disabled}
              onClick={!cancelProps.disabled
                ? onClose
                : null}
            />
          )}

          {type === 'confirm' && (
            <VibeButtonNew
              variant="text"
              text={confirmProps.text}
              style={confirmProps.style}
              color={get(confirmProps, 'color', color.violetVibe)}
              disabled={confirmProps.disabled}
              onClick={!confirmProps.disabled
                ? onConfirm
                : null}
            />
          )}

          {type === 'success' && (
            <VibeButtonNew
              variant="text"
              text={btnText}
              color={color.aquaForest}
              onClick={onClose}
            />
          )}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}

VibeModal.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf([
    'error',
    'confirm',
    'success',
    'custom',
  ]),
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  confirmProps: PropTypes.shape({
    text: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    color: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  cancelProps: PropTypes.shape({
    text: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    color: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  btnText: PropTypes.string,
  show: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onConfirm: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

VibeModal.defaultProps = {
  className: '',
  style: {},
  fullWidth: false,
  maxWidth: 'sm',
  width: 'auto',
  height: 'auto',
  type: 'confirm',
  title: null,
  text: null,
  confirmProps: {
    text: 'Ok',
    style: {},
    color: color.violetVibe,
    disabled: false,
  },
  cancelProps: {
    text: 'Cancel',
    style: {},
    color: color.manatee,
    disabled: false,
  },
  btnText: 'Ok',
  show: false,
  children: null,
  onConfirm: () => {},
  onOpen: () => {},
  onClose: () => {},
};

export default VibeModal;
