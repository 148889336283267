import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import {
  VibeTooltip,
  VibeIcon,
  viChevronDown,
  viChevronRight,
  color,
} from 'vibeguide';
import userBubbleImage from '../../../../../assets/user-bubble.png';
import './HistoryScriptItem.scss';

class HistoryScriptItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  onToggleExpand = () => {
    this.setState((state) => {
      return {
        expanded: !state.expanded,
      };
    });
  };

  render() {
    const {
      script,
      date,
      username,
      imageUrl,
      last,
    } = this.props;

    const {
      expanded,
    } = this.state;

    const time = new moment(date).format('h:mm A');
    const image = imageUrl || userBubbleImage;

    return (
      <div className={classNames('HistoryScriptItem', { expanded })}>
        <div className="item-container">
          <div className="item">
            <VibeIcon
              className="expand-icon"
              icon={expanded
                ? viChevronDown
                : viChevronRight}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={this.onToggleExpand}
            />

            <div className="script-container">
              <div className="script">
                {!expanded
                  ? script
                  : null}
              </div>
            </div>

            <div className="time">
              {time}
            </div>

            <div className="avatar-container">
              <VibeTooltip
                title={username}
                placement="left"
              >
                <img
                  className="avatar"
                  src={image}
                  alt="avatar"
                />
              </VibeTooltip>
            </div>
          </div>

          {expanded ? (
            <div className="expand-container">
              {script}
            </div>
          ) : null}
        </div>

        {!last ? (
          <div className="item-connector" />
        ) : null}
      </div>
    );
  }
}

HistoryScriptItem.propTypes = {
  script: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  username: PropTypes.string,
  imageUrl: PropTypes.string,
  /** Last item in the history */
  last: PropTypes.bool,
};

HistoryScriptItem.defaultProps = {
  username: null,
  imageUrl: null,
  last: false,
};

export default HistoryScriptItem;
