import { createAction } from 'redux-actions';

export const addLocation = createAction('ADD_CURRENT_COMPANY_LOCATION');
export const removeLocation = createAction('REMOVE_CURRENT_COMPANY_LOCATION');
export const setAllLocations = createAction('SET_CURRENT_COMPANY_ALL_LOCATIONS');
export const setCurrentCompany = createAction('SET_CURRENT_COMPANY');
export const setSelectedCompany = createAction('SET_SELECTED_COMPANY');

export default {
  addLocation,
  removeLocation,
  setAllLocations,
  setCurrentCompany,
  setSelectedCompany,
};
