import { handleActions } from 'redux-actions';
import { find } from 'lodash';

export default handleActions(
  {
    SET_SEARCH_FILTERS: (state, action) => {
      const filters = action.payload;

      return {
        ...state,
        filters,
      };
    },

    ADD_SEARCH_FILTER: (state, action) => {
      const filter = action.payload;

      return {
        ...state,
        filters: [...state.filters, filter],
      };
    },

    REMOVE_SEARCH_FILTER: (state, action) => {
      const filter = find(state.filters, {
        category: action.payload.category,
        term: action.payload.term,
      });

      return {
        ...state,
        filters: state.filters.filter(item => item !== filter),
      };
    },
  },
  // Initial State
  {
    filters: [],
  },
);
