import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useOutletContext } from 'react-router-dom';
import {
  EmptyState,
} from 'vibeguide';
import CircularProgress from '@mui/material/CircularProgress';
import SubNavigation from '../../Header/SubNavigation';
import ReportCard from '../ReportCard/ReportCard';

function ReportingDashboard({
  className,
  style,
}) {
  const [
    reports,
    loading,
  ] = useOutletContext();

  return (
    <div
      className={classNames('ReportingDashboard', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...style,
      }}
    >
      <SubNavigation
        title="Reporting Dashboard"
      />

      <div
        style={{
          display: 'flex',
          gap: 32,
          padding: 32,
        }}
      >
        {loading && (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              color="inherit"
              size={64}
            />
          </div>
        )}

        {!loading && reports.length > 0 && reports.map((report) => (
          <ReportCard
            key={`report-${report.name}`}
            name={report.name}
          />
        ))}

        {!loading && reports.length <= 0 && (
          <EmptyState
            title="No Reports Found"
            description="We couldn't find any reports. Please try again later."
          />
        )}
      </div>
    </div>
  );
}

ReportingDashboard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

ReportingDashboard.defaultProps = {
  className: '',
  style: {},
};

export default ReportingDashboard;
