import React from 'react';

const viChevronDoubleLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M7.333 4.933L4.267 8l3.066 3.067L6.4 12l-4-4 4-4 .933.933zm6.667 0L10.933 8 14 11.067l-.933.933-4-4 4-4 .933.933z" />
    </g>
  </svg>
);

export default viChevronDoubleLeft;
