import { RRule } from 'rrule';

/**
 * Create an RRule object
 */
export function rrule(data) {
  return new RRule({
    ...data,
  });
}

export default {
  rrule,
};
