import React from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './Overview.scss';

function Overview() {
  return (
    <div className="Overview">
      <Grid container>
        <Grid item xs={6}>
          <div className="title">
            Overview
            <span className="date">
              Updated June 21, 2024 at 12:56:21 PM
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: 'right',
          }}
        >
          <Link
            className="link"
            to="../all"
          >
            View All Orders
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default Overview;
