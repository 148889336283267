import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  ExpandedLocationsHelper,
  LoadingContent,
} from 'vibeguide';
import PlaylistDetails from './PlaylistDetails';
import './PlaylistSidebar.scss';

class PlaylistSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      playlist: {
        musicItems: [],
      },
    };
  }

  componentDidMount() {
    const {
      user,
      playlistId,
    } = this.props;

    if (playlistId && playlistId !== 'new') {
      this.getPlaylist();
    }

    if (!playlistId && !user.sysAdmin) {
      this.setCompany();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      playlistId,
      isNew,
    } = this.props;

    const {
      playlistId: prevPlaylistId,
    } = prevProps;

    if (playlistId && !isNew && playlistId !== prevPlaylistId) {
      this.getPlaylist();
    }
  }

  /**
   * Update the playlist
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        playlist: {
          ...state.playlist,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtraPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Close the third panel on the sidebar
   */
  onCloseThirdPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  setCompany = () => {
    const {
      user,
    } = this.props;

    this.setState({
      playlist: {
        companyName: user.companyName,
        companyId: user.companyId,
      },
    });
  };

  getPlaylist = async () => {
    const {
      playlistId,
    } = this.props;

    const playlist = await API.Playlist.getById(playlistId);

    const expandedLocations = await API.Playlist.getExpandedLocations(playlistId);

    // Set the location data
    playlist.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    this.setState({
      playlist,
    });
  };

  render() {
    const {
      playlistId,
      isNew,
      isDuplicate,
    } = this.props;

    const {
      playlist,
    } = this.state;

    return (
      <div className="PlaylistSidebar">
        <PlaylistDetails
          playlist={playlist}
          isNew={isNew}
          isDuplicate={isDuplicate}
          onClose={this.onClose}
          onCloseExtraPanel={this.onCloseExtraPanel}
          onCloseThirdPanel={this.onCloseThirdPanel}
          onUpdate={this.onUpdate}
          onRefresh={this.getPlaylist}
        />

        {playlistId && !playlist._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

PlaylistSidebar.propTypes = {
  playlistId: PropTypes.string,
  /** New Playlist */
  isNew: PropTypes.bool,
  /** Duplicate Playlist */
  isDuplicate: PropTypes.bool,
};

PlaylistSidebar.defaultProps = {
  playlistId: null,
  isNew: false,
  isDuplicate: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistSidebar);
