// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OfflineDevices > .header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.OfflineDevices > .header .title {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Dashboard/Charts/OfflineDevices.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AAGI;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AADN","sourcesContent":[".OfflineDevices {\n\n  > .header {\n    display: flex;\n    align-items: center;\n    margin-bottom: 16px;\n\n    .title {\n      flex-grow: 1;\n      font-size: 14px;\n      font-weight: 900;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
