import React from 'react';

const viArchive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M13.693 3.487l-.926-1.12A.968.968 0 0 0 12 2H4a.99.99 0 0 0-.773.367l-.92 1.12A1.305 1.305 0 0 0 2 4.333v8.334C2 13.4 2.6 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V4.333c0-.32-.113-.62-.307-.846zM8 11.667L4.333 8h2.334V6.667h2.666V8h2.334L8 11.667zM3.413 3.333l.54-.666h8l.627.666H3.413z" />
    </g>
  </svg>
);

export default viArchive;
