import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TimeUtil,
  DaySelector,
  VibeButtonNew,
  viCopy,
  viTrash,
  color,
} from 'vibeguide';
import './MusicOverrideToolbar.scss';

function MusicOverrideToolbar({
  className,
  day,
  onCopyToDays,
  onClearDay,
  onClose,
  onSave,
}) {
  const [showCopyDay, setShowCopyDay] = useState(false);
  const [copyToDays, setCopyToDays] = useState([]);

  const dayIndex = TimeUtil.getDayOfWeekIndex(day);
  const dayText = TimeUtil.getDayFromIndex(dayIndex, 'dddd');

  const onToggleCopyDay = () => {
    setShowCopyDay(!showCopyDay);

    if (!showCopyDay) {
      setCopyToDays([]);
    }
  };

  const onClickSaveCopyDays = () => {
    onCopyToDays(copyToDays);
    onToggleCopyDay();
  };

  const onChangeCopyDays = (copyDays) => {
    setCopyToDays(copyDays);
  };

  return (
    <div className={classNames('MusicOverrideToolbar', className)}>
      <div className="day">
        {dayText}
      </div>

      <div className="copy-day">
        <VibeButtonNew
          className="btn-toolbar btn-copy-day"
          text="Copy Day"
          variant="outlined"
          color={color.violetVibe}
          icon={viCopy}
          onClick={onToggleCopyDay}
        />

        {showCopyDay ? (
          <div className="copy-day-details-container">
            <div className="copy-day-details">
              <div className="copy-to-text">
                Copy To...
              </div>

              <DaySelector
                toggleAll
                onChange={onChangeCopyDays}
              />

              <div className="actions-copy-days">
                <VibeButtonNew
                  className="btn-cancel"
                  text="Cancel"
                  variant="outlined"
                  color={color.manatee}
                  onClick={onToggleCopyDay}
                />

                <VibeButtonNew
                  text="Save"
                  color={color.violetVibe}
                  disabled={copyToDays.length <= 0}
                  onClick={onClickSaveCopyDays}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <VibeButtonNew
        className="btn-toolbar btn-clear-day"
        text="Clear Day"
        variant="outlined"
        color={color.violetVibe}
        icon={viTrash}
        onClick={onClearDay}
      />

      <div className="actions">
        <VibeButtonNew
          className="btn-toolbar btn-cancel"
          text="Cancel"
          variant="outlined"
          color={color.manatee}
          onClick={onClose}
        />

        <VibeButtonNew
          className="btn-toolbar btn-save"
          text="Save"
          color={color.violetVibe}
          onClick={onSave}
        />
      </div>
    </div>
  );
}

MusicOverrideToolbar.propTypes = {
  className: PropTypes.string,
  day: PropTypes.string.isRequired,
  onCopyToDays: PropTypes.func,
  onClearDay: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

MusicOverrideToolbar.defaultProps = {
  className: '',
  onCopyToDays: () => {},
  onClearDay: () => {},
  onClose: () => {},
  onSave: () => {},
};

export default MusicOverrideToolbar;
