import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import {
  API,
  SearchByHelper,
  LocationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  PageLayout,
  EmptyState,
  VibeButtonNew,
  VibeIcon,
  viChevronLeft,
  viUser,
  color,
} from 'vibeguide';
import CircularProgress from '@mui/material/CircularProgress';
import ApproverCard from '../Section/Approvers/ApproverCard';
import './ApproverList.scss';

function ApproverList({
  className,
  style,
  message,
  approverType,
  onUpdate,
  onClose,
}) {
  const approverSettings = get(message, `${approverType}Approval`, {}) || {};
  const approvers = approverSettings.approvers || [];

  const [loading, setLoading] = useState(true);
  const [approversList, setApproversList] = useState([]);
  const [filteredApprovers, setFilteredApprovers] = useState([]);
  const [selectedApprovers, setSelectedApprovers] = useState(sortBy(approvers, 'fname'));

  /**
   * User filters the approver list
   */
  const onFilter = (data) => {
    const {
      collection,
    } = data;

    setFilteredApprovers(collection);
  };

  /**
   * When a user is toggled from the list
   */
  const onSelectApprover = (approver) => {
    const existingApprover = find(approvers, { _id: approver._id }) || {};

    const allApprovers = approversList.map((item) => {
      if (item._id === approver._id) {
        // merge the selected approver with the existing approver data
        return {
          ...existingApprover,
          ...approver,
        };
      }

      // merge the selected approver with the existing approver data
      return {
        ...existingApprover,
        ...item,
      };
    });

    setApproversList(allApprovers);

    setFilteredApprovers(filteredApprovers.map((item) => {
      if (item._id === approver._id) {
        // merge the selected approver with the existing approver data
        return {
          ...existingApprover,
          ...approver,
        };
      }

      // merge the selected approver with the existing approver data
      return {
        ...existingApprover,
        ...item,
      };
    }));

    setSelectedApprovers(sortBy(allApprovers.filter(approver => approver.selected), 'fname').map(approver => {
      return {
        ...approver,
        selected: false,
      };
    }));
  };

  /**
   * User saves the approver selection
   */
  const onAssignApprovers = () => {
    const approverObject = approverType === 'client'
      ? 'clientApproval'
      : 'partnerApproval';

    onUpdate({
      [approverObject]: {
        ...message[approverObject],
        approvers: selectedApprovers,
        // update the required approver count if all approvers are required
        approvalsRequired: approverSettings.style === 'unanimous'
          ? selectedApprovers.length
          : message[approverObject].approvalsRequired,
      },
    });

    onClose();
  };

  /**
   * Get the footer style overrides
   * custom footer style needed when displaying selected approvers
   */
  const getFooterStyle = () => {
    const footerStyle = {};

    if (selectedApprovers.length > 0) {
      footerStyle.height = 'auto';
      footerStyle.minHeight = 'auto';
      footerStyle.paddingBottom = 16;
    }

    return footerStyle;
  };

  /**
   * Get the list of approvers
   */
  useEffect(async () => {
    const group = LocationHelper.group(message.locations);
    const response = await API.MessageRequest.Approval.getApprovers({ companyId: message.companyId, locations: group });

    setApproversList(response.map((approver) => {
      const selected = find(approvers, { _id: approver._id }) !== undefined;

      return {
        ...approver,
        selected,
      };
    }));

    setLoading(false);
  }, []);

  return (
    <SidePanelContainer
      className={classNames('ApproverList', className)}
      style={style}
    >
      <SidePanelHeader className="panel-header">
        <VibeIcon
          icon={viChevronLeft}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />

        <PageLayout
          className="page-layout"
          searchOptions={SearchByHelper.users}
          collection={approversList}
          onFilter={onFilter}
          disableView
          disableSort
        />
      </SidePanelHeader>

      <SidePanelContent className="panel-content">
        {loading && (
          <div
            style={{
              textAlign: 'center',
              color: color.violetVibe,
            }}
          >
            <CircularProgress
              color="inherit"
              size={48}
            />
          </div>
        )}

        {filteredApprovers.map((approver, index) => (
          <ApproverCard
            key={`approver-card-${index}`}
            style={{
              padding: 16,
            }}
            approver={approver}
            selectable
            onSelect={onSelectApprover}
          />
        ))}

        {!loading && filteredApprovers.length <= 0 && (
          <EmptyState
            title="No Available Approvers"
            description="No users found that can approve this message"
            style={{
              backgroundColor: color.white,
            }}
          />
        )}
      </SidePanelContent>

      <SidePanelFooter
        style={getFooterStyle()}
        preChildren={selectedApprovers.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              padding: '16px 0',
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: '20px',
            }}
          >
            {selectedApprovers.map((approver) => (
              <div
                key={`selected-approver-${approver._id}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 8,
                  backgroundColor: color.lightGray,
                  border: `1px solid ${color.whiteSmoke}`,
                  borderRadius: 4,
                }}
              >
                <VibeIcon
                  style={{
                    marginRight: 8,
                  }}
                  icon={viUser}
                  color={color.violetVibe}
                  size={16}
                />

                {`${approver.fname} ${approver.lname}`.trim()} Selected
              </div>
            ))}
          </div>
        )}
      >
        <VibeButtonNew
          text="Assign"
          style={{
            marginRight: 12,
          }}
          color={color.violetVibe}
          // disabled={disableSave || disableInput}
          onClick={onAssignApprovers}
        />

        <VibeButtonNew
          variant="text"
          text="Cancel"
          color={color.manatee}
          onClick={onClose}
        />
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

ApproverList.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  message: PropTypes.object,
  approverType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

ApproverList.defaultProps = {
  className: '',
  style: {},
  message: {},
  onUpdate: () => {},
  onClose: () => {},
};

export default ApproverList;
