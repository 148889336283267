import queryString from 'query-string';
import moment from 'moment';
import {
  get,
  forEach,
  find,
  size,
  orderBy,
  uniqueId,
  isArray,
  reduce,
  inRange,
  kebabCase,
  toString,
} from 'lodash';
import {
  getLocal,
  removeLocal,
  setLocal,
} from '../utils/StorageUtil';

/**
 * Get custom table settings
 */
export function getSettings({
  tableId,
  columnsDef,
  columnNames,
}) {
  const settings = getLocal(tableId, {});
  const settingsColumns = get(settings, 'columns', []);

  // order the table columns list from the parent
  // all columns available in this table
  const columns = columnNames.map((columnName) => {
    const column = find(columnsDef, { name: columnName }) || { defaultWidth: 0 };
    const settingColumn = find(settingsColumns, { name: columnName }) || {};

    // get column width based on the saved settings (if applicable)
    column.width = settingColumn.width || column.defaultWidth;
    // does the user hide this column
    column.hide = settingColumn.hide || false;

    return column;
  });

  settings.density = settings.density || 'compact';

  return {
    ...settings,
    allColumns: columns,
    // only render non-hidden columns
    columns: columns.filter(column => !column.hide),
  };
}

/**
 * Remove all custom table settings
 */
export function removeSettings(tableId) {
  removeLocal(tableId);
}

/**
 * Remove a specific setting from a table
 */
export function removeSetting({
  tableId,
  setting,
}) {
  const settings = getLocal(tableId, {});
  // remove the setting key
  delete settings[setting];

  if (size(settings) <= 0) {
    // no custom settings remain, remove the localStorage settings
    removeSettings(tableId);
  } else {
    // other settings remain (i.e. density, columns, etc). re-save the settings without the removed one
    setLocal(tableId, settings);
  }
}

export function saveColumn({
  tableId,
  name,
  width,
  hide,
}) {
  const settings = getLocal(tableId, {});
  const settingsColumns = get(settings, 'columns', []);
  const settingColumn = find(settingsColumns, { name });

  const data = {};

  if (width !== undefined) {
    data.width = width;
  }

  if (hide !== undefined) {
    data.hide = hide;
  }

  setLocal(tableId, {
    ...settings,
    columns: settingColumn
      // column already exists, update with the new settings
      ? settingsColumns.map((column) => {
        if (column.name === name) {
          return {
            ...column,
            ...data,
          };
        }

        return column;
      })
      // add the column
      : [...settingsColumns, {
        name,
        ...data,
      }],
  });
}

export function saveDensity({
  tableId,
  density,
}) {
  const settings = getLocal(tableId, {});

  setLocal(tableId, {
    ...settings,
    density,
  });
}

export function saveSortBy({
  tableId,
  sortBy,
}) {
  const settings = getLocal(tableId, {});

  setLocal(tableId, {
    ...settings,
    sortBy,
  });
}

export function savePageSize({
  tableId,
  pageSize,
}) {
  const settings = getLocal(tableId, {});

  setLocal(tableId, {
    ...settings,
    pageSize,
  });
}

export function saveViewCards({
  tableId,
  cards,
}) {
  const settings = getLocal(tableId, {});

  setLocal(tableId, {
    ...settings,
    cards,
  });
}

/**
 * Convert filters to the proper value if necessary
 * i.e. string 'true' and 'false' to boolean values
 */
export function convertFilters({
  filters,
  columns,
}) {
  forEach(filters, (value, key) => {
    const column = find(columns, { searchAttr: key }) || {};

    if (column.datepicker) {
      // convert dates to use the local timezone
      const dateStr = moment(value).format();
      filters[key] = dateStr;
    }

    if (value === 'true' || value === 'false') {
      filters[key] = value === 'true';
    }

    if (column.searchAdvanced && value.indexOf(',') >= 0) {
      // convert multiple search values to an array
      filters[key] = value.split(',');
    }
  });

  return filters;
}

export function getFiltersFromUrl({
  url,
  columns,
}) {
  const filters = {};
  const qs = queryString.parse(url || window.location.search);

  forEach(qs, (value, key) => {
    // Is there a column matching this param
    const column = find(columns, { searchAttr: key });

    if (!column) {
      // No column found to add a filter for
      return;
    }

    // Add filter to fetch call from querystring
    if (value === 'true' || value === 'false') {
      // convert string booleans to actual booleans
      filters[key] = value === 'true';
    } else {
      filters[key] = value;
    }
  });

  return filters;
}

/**
 * Ensure every row has a unique ID
 */
export function uniqueRows(rows) {
  return (rows || []).map((row) => {
    if (!row.rowId) {
      row._rowId = uniqueId(`${row._id}-`);
    }

    return row;
  });
}

/**
 * Search a local collection of data for matches
 */
export function searchCollection(collection, filters, columns) {
  let allResults = [];

  if (isArray(collection)
    && collection.length > 0
    && size(filters) > 0
  ) {
    // for the first scan use the entire collection. Anything after use the existing results
    let firstRun = true;

    forEach(filters, (value, by) => {
      let results = [];
      const useCollection = firstRun
        ? collection
        : allResults;

      const column = find(columns, { searchAttr: by }) || {};
      const valueLC = toString(value).toLowerCase();

      if (column.searchAdvanced && isArray(value)) {
        // search collection for multiple values in one column
        results = reduce(useCollection, (agg, v) => {
          const rowValue = get(v, column.searchAttr, '');
          // only allow a result to add once
          let added = false;

          value.forEach((valueItem) => {
            // if the row is already added skip it
            if (added) {
              return;
            }

            if (rowValue.toLowerCase().indexOf(valueItem.toLowerCase()) >= 0) {
              // row has at least one of the values being searched for
              agg.push(v);
              added = true;
            }
          });

          return agg;
        }, results);
      } else if (by === 'tags.name') {
        results = reduce(useCollection, (agg, v) => {
          const tags = get(v, 'tags', []);

          const matchedTags = tags.filter(tag => tag.name.toLowerCase().indexOf(valueLC) >= 0);
          if (matchedTags.length > 0) {
            agg.push(v);
          }

          return agg;
        }, results);
      } else if (by === 'proSelection') {
        // Search the PRO list to return rows with any of the selected PROs
        results = reduce(useCollection, (agg, v) => {
          const pros = get(v, 'proSelection', []);
          const enabledPros = [];

          forEach(pros, (value, key) => {
            if (value) {
              enabledPros.push(key);
            }
          });

          if (enabledPros.length > 0) {
            // only allow a result to add once
            let added = false;

            value.forEach((searchPro) => {
              // if the row is already added skip it
              if (added) {
                return;
              }

              if (enabledPros.includes(searchPro)) {
                agg.push(v);
                added = true;
              }
            });
          }

          return agg;
        }, results);
      } else if (isArray(get(useCollection[0], by))) {
        // Save item to results if at least one of the value's array items matches query
        results = reduce(useCollection, (agg, v) => {
          if (get(v, by).some(opt => opt.toLowerCase().includes(valueLC))) {
            agg.push(v);
          }

          return agg;
        }, results);
      } else if (column.datepicker) {
        // search for items occurring within the day
        const withinDateStart = moment(value).startOf('day').format();
        const withinDateEnd = moment(value).endOf('day').format();

        results = reduce(useCollection, (agg, v) => {
          const currValue = get(v, by, '');

          if (currValue) {
            const valueDate = moment(currValue);

            // use inclusivity parameters for isBetween - [] means include, () means exclude
            if (valueDate.isBetween(withinDateStart, withinDateEnd, undefined, '[]')) {
              // date is the same day
              agg.push(v);
            }
          }

          return agg;
        }, results);
      } else {
        results = reduce(useCollection, (agg, v) => {
          // convert the value to a string
          const currValue = toString(get(v, by, ''));

          if (currValue.toLowerCase().includes(valueLC)) {
            agg.push(v);
          }

          return agg;
        }, results);
      }

      // no longer the first run for filter searches
      firstRun = false;

      // combine all results from the filter search
      allResults = results;
    });
  } else {
    // collection is invalid or empty
    return collection;
  }

  return allResults;
}

/**
 * Get the results for a page from an array of data.
 * Used when the API does not page the data
 */
export function getPageResults({
  collection,
  pageNumber,
  pageSize,
}) {
  const startIndex = ((pageNumber - 1) * pageSize);
  const endIndex = pageSize === -1
    // get all results (all pages)
    ? collection.length
    // stop getting results at the page limit
    : startIndex + pageSize;
  const pageItems = [];

  collection.forEach((item, index) => {
    if (inRange(index, startIndex, endIndex)) {
      pageItems.push(item);
    }
  });

  return pageItems;
}

/**
 * Get the fetch method and props to use
 * Used for submenus with custom fetch properties
 */
function getFetchMethod({
  fetch,
  filters,
  columns,
  submenuItems,
  urlType,
}) {
  if (submenuItems.length > 0) {
    const currentType = urlType === undefined
      ? kebabCase(get(submenuItems, '[0].name', null))
      : urlType;

    const activeItem = submenuItems.filter(item => currentType === kebabCase(item.name)
      && item.enabled
      && item.show,
    )[0];

    if (activeItem) {
      return {
        fetch: activeItem.fetch || fetch,
        filters: {
          ...convertFilters({
            filters,
            columns,
          }),
          ...get(activeItem, 'filters', []),
        },
      };
    }
  }

  return {
    fetch,
    filters: convertFilters({
      filters,
      columns,
    }),
  };
}

/**
 * Get data for the table
 * Pass in the table props and state to get the correct data
 */
export async function getData({
  props,
  state,
  config = {},
  customData = {},
}) {
  const {
    // columns: propColumns,
    fetch: propFetch,
    fetchProps: {
      filters: fetchFilters,
    },
    filters: propFilters,
    collection,
    defaultSortBy,
    disableSort,
    submenuProps: {
      items: submenuItems,
    },
    params: {
      type: urlType,
    },
  } = props;

  const {
    filters: stateFilters,
    columnsDef: columns,
    columnNames,
    active,
    pageNumber,
    pageSize,
    sortBy,
  } = state;

  const {
    fetch,
    filters,
  } = getFetchMethod({
    fetch: propFetch,
    filters: {
      // always request an object by active status
      // if a table with a local collection of data is empty, chances are there is no "active" attribute
      active,
      ...propFilters,
      ...stateFilters,
    },
    columns,
    submenuItems,
    urlType,
  });

  // alter the search filter if the column data type is not a string
  forEach(filters, (value, key) => {
    const column = find(columns, { searchAttr: key }) || {};

    // if the column is an array search as an array
    if (column.dataType === 'array' || column.dataType === 'array-multiple') {
      filters[key] = value.split(',');
    }
  });

  // only use the sort field if the table instance includes that column
  const useSortBy = columnNames.includes(sortBy.label)
    ? sortBy
    : defaultSortBy;

  // get all pages if exporting or config variable set
  const usePageSize = config.pageSize || config.export
    ? get(config, 'pageSize', -1)
    : pageSize;

  let usePageNumber = config.pageNumber || config.export
    ? get(config, 'pageNumber', 1)
    : pageNumber;

  // force page 1 if requesting all data
  usePageNumber = usePageNumber !== 1 && usePageSize === -1
    ? 1
    : usePageNumber;

  if (!fetch) {
    // use a local collection for the table data
    const filteredCollection = searchCollection(collection, filters, columns);
    const sortedCollection = !disableSort
      // sort by items with warnings first, and then by the custom sort attribute
      // ensure items in the table with warnings are at the top of the table
      ? orderBy(filteredCollection, ['warnings', useSortBy.attr], ['desc', useSortBy.direction])
      : filteredCollection;

    const pagedCollection = getPageResults({
      collection: sortedCollection,
      pageNumber: usePageNumber,
      pageSize: usePageSize,
    });

    // export the data instead of updating the table
    if (config.export) {
      props.csvProps.onExport(sortedCollection);
      return {};
    }

    return {
      rows: uniqueRows(pagedCollection),
      totalItems: sortedCollection.length,
      filters,
    };
  }

  const options = config.export
    ? {
      headers: {
        Accept: 'text/csv',
      },
    }
    : {};

  const response = await fetch({
    filters,
    pageNumber: usePageNumber,
    pageSize: usePageSize,
    sort: {
      [useSortBy.attr]: useSortBy.direction,
    },
    ...fetchFilters,
    // add custom data for table specific parameters
    ...customData,
  }, options);

  // export the data instead of updating the table
  if (config.export) {
    props.csvProps.onExport(response);
    return {};
  }

  const pagedResponse = !isArray(response);
  const data = pagedResponse
    ? response.data
      || response.blockList
    : response;

  return {
    rows: pagedResponse
      ? uniqueRows(data)
      : uniqueRows(getPageResults({
        collection: response,
        pageNumber,
        pageSize,
      })),
    totalItems: pagedResponse
      ? response.totalItems || data.length
      : data.length,
    filters,
  };
}

export default {
  getSettings,
  removeSettings,
  removeSetting,
  saveColumn,
  saveDensity,
  saveSortBy,
  savePageSize,
  saveViewCards,
  convertFilters,
  getFiltersFromUrl,
  uniqueRows,
  searchCollection,
  getPageResults,
  getData,
};
