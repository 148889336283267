import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SidePanelFooter.scss';

function SidePanelFooter({
  className,
  style,
  preChildren,
  children,
}) {
  return (
    <div
      className={classNames('SidePanelFooter', className)}
      style={style}
    >
      {preChildren}

      <div className="footer-container">
        {children}
      </div>
    </div>
  );
}

SidePanelFooter.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  preChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SidePanelFooter.defaultProps = {
  className: null,
  style: {},
  preChildren: null,
  children: null,
};

export default SidePanelFooter;
