import React from 'react';

const viViewDayTrack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M2.002 7.968V2.465c0-.434.014-.462.252-.462h2.168c.227 0 .244.03.244.444V13.577c0 .365-.029.42-.225.42-.74.002-1.48-.005-2.22.006-.17.002-.222-.12-.22-.408.003-1.876.001-3.751.001-5.627zM14 12.996v.918c0 .08-.021.086-.287.086H11.57c-.202 0-.235-.009-.235-.068-.001-.623 0-1.245-.002-1.867 0-.046.06-.065.217-.065h2.23c.185 0 .22.012.22.073v.923zm0-3.333v.918c0 .08-.021.086-.287.086H11.57c-.202 0-.235-.009-.235-.069-.001-.622 0-1.244-.002-1.867 0-.046.06-.065.217-.064h2.23c.185 0 .22.011.22.073v.923zm0-3.333v.918c0 .079-.021.085-.287.085H11.57c-.202 0-.235-.008-.235-.068-.001-.622 0-1.245-.002-1.867 0-.046.06-.065.217-.065.743.002 1.486 0 2.23.001.185 0 .22.011.22.073v.923zm0-3.334v.918c0 .08-.021.086-.287.086H11.57c-.202 0-.235-.009-.235-.068-.001-.623 0-1.245-.002-1.867 0-.046.06-.065.217-.065h2.23c.185 0 .22.012.22.073v.923zM6.67 7.943c0-1.779.005-3.558-.003-5.336-.002-.44.054-.597.187-.598C7.623 2.005 8.39 2 9.158 2c.163 0 .177.337.176.715v10.569c-.001.606-.033.707-.226.708-.74.002-1.478-.008-2.217.008-.165.004-.224-.175-.222-.67.006-1.795.002-3.591.002-5.387z" />
    </g>
  </svg>
);

export default viViewDayTrack;
