import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { find, forEach } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SwapButton from '../SwapButton/SwapButton';
import Paginator from '../Paginator/Paginator';
import PageLayout from '../PageLayout/PageLayout';
import VibeSplash from '../VibeSplash/VibeSplash';
import VibeIcon from '../VibeIcon/VibeIcon';
import VibeToggle from '../VibeToggle/VibeToggle';
import viCompany from '../../icons/viCity';
import viStore from '../../icons/viStore';
import CompanyItem from './CompanyItem';
import LocationItem from './LocationItem';
import {
  companies as searchByCompanies,
  locations as searchByLocations,
} from '../../helpers/SearchBy';
import {
  companies as sortByCompanies,
  locations as sortByLocations,
} from '../../helpers/SortBy';
import defaultLocationImage from '../../assets/default_location.png';
import color from '../../sass/color.scss';
import './LocationsUnassigned.scss';

class LocationsUnassigned extends Component {
  constructor(props) {
    super(props);

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const {
      user,
      companyId,
      allowCompanies,
      locationsData: {
        companies,
        locations,
      },
    } = props;

    this._scrollRef = null;
    this.companyRefs = {};

    const allowCompanyView = allowCompanies
      && user.sysAdmin
      && companyId === adminCompanyId;

    // Do not automatically assign ACAF for admins if the object is assigned to Vibenomics
    this.skipAutoACAF = companyId === adminCompanyId && user.sysAdmin;

    this.state = {
      allowCompanyView,
      isCompanies: allowCompanyView,
      companies,
      locations,
      totalItems: 0,
    };
  }

  componentDidMount() {
    const {
      allowACAF,
      selectAllInitial,
      selectedLocations,
      onSelectAll,
    } = this.props;

    const {
      skipAutoACAF,
    } = this;

    if (
      !skipAutoACAF
      && allowACAF
      && selectAllInitial
      && selectedLocations.length <= 0
    ) {
      onSelectAll(true);
    }
  }

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    this.setState((state) => {
      const {
        isCompanies,
      } = state;

      return {
        locations: isCompanies ? [] : collection,
        companies: isCompanies ? collection : [],
        totalItems,
      };
    });
  };

  onScroll = () => {
    const {
      stickyScroll,
    } = this.props;

    const {
      companies,
    } = this.state;

    if (!stickyScroll) {
      return;
    }

    forEach(this.companyRefs, (ref, companyId) => {
      // Top of scrollable content area (height of all the headers)
      const topOfContent = 323;

      if (ref === null) {
        return;
      }

      const rect = ref.getBoundingClientRect();
      const company = find(companies, { _id: companyId });

      if (rect.top <= topOfContent && rect.top > (topOfContent - rect.height + 72)) {
        // Company item is in the "sticky" zone
        if (!company.sticky) {
          this.setState((state) => {
            return {
              companies: state.companies.map((c) => {
                if (c._id === company._id) {
                  return {
                    ...c,
                    fixToBottom: false,
                    sticky: true,
                  };
                }

                return c;
              }),
            };
          });
        }

        // Do not run further
        return;
      }

      if (company.sticky && !company.fixToBottom && rect.bottom <= 395 && rect.bottom > 323) {
        this.setState((state) => {
          return {
            companies: state.companies.map((c) => {
              if (c._id === company._id) {
                return {
                  ...c,
                  fixToBottom: true,
                };
              }

              return c;
            }),
          };
        });
      }

      // Company is not inside the top zone
      if (company.sticky) {
        this.setState((state) => {
          return {
            companies: state.companies.map((c) => {
              if (c._id === company._id) {
                return {
                  ...c,
                  // fixToBottom: false,
                  sticky: false,
                };
              }

              return c;
            }),
          };
        });
      }
    });
  };

  onChangeAssignAllCurrentAndFuture = () => {
    const {
      selectAll,
      onSelectAll,
    } = this.props;

    onSelectAll(!selectAll);
  };

  /**
   * Assign all loaded locations for this company
   */
  onClickAssignAll = () => {
    const {
      selectedLocations,
      onSelectLocation,
    } = this.props;

    const {
      locations,
    } = this.state;

    // Get the locations that are not selected/unselected
    const unselectedLocations = locations.filter((location) => {
      const selected = find(selectedLocations, {
        companyId: location.companyId,
        locationId: location._id,
      }) !== undefined;

      if (!selected) {
        return location;
      }

      return false;
    });

    const locationData = unselectedLocations.map((location) => {
      return {
        location,
        companyId: location.companyId,
        locationId: location._id,
      };
    });

    // Select the locations
    locationData.forEach((location) => {
      onSelectLocation({
        company: {
          _id: location.location.companyId,
          name: location.location.companyName,
        },
        ...location,
      });
    });
  };

  /**
   * Set Company View
   */
  setCompanyView = () => {
    const {
      allowCompanyView,
    } = this.state;

    if (!allowCompanyView) {
      return;
    }

    this.setState({
      isCompanies: true,
    });
  };

  /**
   * Set Location View
   */
  setLocationView = () => {
    this.setState({
      isCompanies: false,
    });
  };

  render() {
    const {
      companyId,
      selectAll,
      selectAllLabel,
      selectedLocations,
      locationsData,
      allowChanges,
      allowPaginator,
      allowACAF,
      allowAssignCompany,
      filterParamFirst,
      filterCompanies,
      filterLocations,
      user,
      onSelectLocation,
    } = this.props;

    const {
      allowCompanyView,
      isCompanies,
      companies,
      locations,
      totalItems,
    } = this.state;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    let filterParam = isCompanies
      ? user._id
      : companyId;

    if (
      !filterParamFirst
      && !isCompanies
      && user.sysAdmin
      && companyId === adminCompanyId
    ) {
      // The object is assigned to the admin company but the user is forcing location view
      filterParam = user._id;
    } else if (
      !filterParamFirst
      && !isCompanies
      && !user.sysAdmin
    ) {
      // The user is not an admin, fetch user locations
      filterParam = user._id;
    }

    // Only allow admins to choose which view they use
    const viewTypeComponent = user.sysAdmin ? (
      <div className="view-type">
        <VibeIcon
          className={classNames('view-type-icon', 'company-icon', { active: isCompanies })}
          icon={viCompany}
          color={isCompanies ? color.violetVibe : color.manatee}
          size={20}
          tooltip={`Company View ${!allowCompanyView ? '(disabled)' : ''}`}
          disabled={!allowCompanyView}
          onClick={this.setCompanyView}
        />

        <VibeIcon
          className={classNames('view-type-icon', 'location-icon', { active: !isCompanies })}
          icon={viStore}
          color={isCompanies ? color.manatee : color.violetVibe}
          size={20}
          tooltip="Location View"
          onClick={this.setLocationView}
        />
      </div>
    ) : null;

    return (
      <div className="LocationsUnassigned two-panel">
        <div className="title-container">
          <div className="title">
            Available Locations
          </div>

          {allowACAF && user.allowAllCurrentAndFuture ? (
            <div className="assign-all-container">
              <VibeToggle
                label={selectAllLabel}
                labelStyle={{
                  color: color.aquaForest,
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
                height={16}
                checked={selectAll}
                color={color.aquaForest}
                disabled={!allowChanges}
                onChange={this.onChangeAssignAllCurrentAndFuture}
              />
            </div>
          ) : null}
        </div>

        <div className="assign-object-container">
          <PageLayout
            sortOptions={!isCompanies
              ? sortByLocations
              : sortByCompanies}
            searchOptions={!isCompanies
              ? searchByLocations
              : searchByCompanies}
            scrollRef={this._scrollRef}
            placeholder={!isCompanies ? 'Search Locations...' : 'Search Companies...'}
            collection={isCompanies
              ? locationsData.companies
              : locationsData.locations}
            // filterParamFirst={filterParamFirst || filterParam}
            filter={isCompanies ? filterCompanies : filterLocations}
            filterProps={{
              fetchFilters: {
                _id: filterParam,
              },
            }}
            searchStyle={{
              maxWidth: 'auto',
            }}
            customComponent={viewTypeComponent}
            onFilter={this.onFilter}
            disableView
          />

          <div className="locations-content-root">
            <PerfectScrollbar
              containerRef={(ref) => { this._scrollRef = ref; }}
              onScrollY={this.onScroll}
            >
              {selectAll ? (
                <VibeSplash
                  image={defaultLocationImage}
                  title="All locations have been assigned"
                  titleStyle={{
                    color: color.aquaForest,
                    textTransform: 'uppercase',
                  }}
                  subtitle="This is assigned to all current and future locations."
                />
              ) : (
                <div className="locations-content">
                  {allowPaginator ? (
                    <div className="paginator-container">
                      <Paginator
                        className="location-paginator"
                        totalLoaded={!isCompanies ? locations.length : companies.length}
                        totalItems={totalItems}
                        label={!isCompanies ? 'Locations' : 'Companies'}
                      />

                      <div>
                        {allowChanges && !isCompanies && locations.length > 0 ? (
                          <SwapButton
                            className="assign-btn"
                            text={locations.length > 1
                              ? `Assign These ${locations.length} Locations`
                              : 'Assign This Location'}
                            arrowPlacement="right"
                            arrowPosition="right"
                            onClick={this.onClickAssignAll}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  <div className="locations-list">
                    {isCompanies && companies.map((company) => {
                      // Check if the company is selected
                      const selected = find(selectedLocations, {
                        companyId: company._id,
                        locationId: '*',
                      }) !== undefined;

                      return (
                        <div
                          key={company._id}
                          ref={(ref) => { this.companyRefs[company._id] = ref; }}
                        >
                          <CompanyItem
                            key={company._id}
                            company={company}
                            companyLocations={company.locations}
                            selectedLocations={selectedLocations}
                            selected={selected}
                            allowChanges={allowChanges}
                            allowExpand={!selected}
                            allowAssignCompany={allowChanges && allowAssignCompany}
                            onSelectLocation={onSelectLocation}
                            assign
                          />
                        </div>
                      );
                    })}

                    {!isCompanies ? (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                      >
                        {locations.map((location) => {
                          // Check if the company and/or location is selected
                          let selected = find(selectedLocations, {
                            locationId: location._id,
                          }) !== undefined;

                          if (!selected) {
                            selected = find(selectedLocations, {
                              companyId: location.companyId,
                              locationId: '*',
                            }) !== undefined;
                          }

                          return (
                            <div key={location._id}>
                              <LocationItem
                                location={location}
                                selected={selected}
                                allowChanges={allowChanges}
                                assign
                                showCompanyImage
                                onSelect={onSelectLocation}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    );
  }
}

LocationsUnassigned.propTypes = {
  /** Company ID */
  companyId: PropTypes.string.isRequired,
  /** Select All locations */
  selectAll: PropTypes.bool,
  /** Label for the select all toggle */
  selectAllLabel: PropTypes.string,
  /** Select All locations initially (for new objects) */
  selectAllInitial: PropTypes.bool,
  /** Selected locations */
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Available Locations Data */
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      allLocations: PropTypes.bool,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
    })),
    locations: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      city: PropTypes.string,
      companyId: PropTypes.string,
      companyName: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    })),
  }),
  /** Sticky Scroll company header */
  stickyScroll: PropTypes.bool,
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** Allow the Paginator to be displayed */
  allowPaginator: PropTypes.bool,
  /** Allow all current and future toggle */
  allowACAF: PropTypes.bool,
  /** Allow companies to be shown for admins */
  allowCompanies: PropTypes.bool,
  /** Allow companies to be assigned */
  allowAssignCompany: PropTypes.bool,
  /** When a location is selected */
  onSelectLocation: PropTypes.func,
  /** When all locations are selected with ACAF */
  onSelectAll: PropTypes.func,
};

LocationsUnassigned.defaultProps = {
  selectAll: false,
  selectAllLabel: 'Assign All Current and Future',
  selectAllInitial: false,
  selectedLocations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  stickyScroll: false,
  allowChanges: false,
  allowPaginator: false,
  allowACAF: false,
  allowCompanies: false,
  allowAssignCompany: false,
  onSelectLocation: () => {},
  onSelectAll: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(LocationsUnassigned);
