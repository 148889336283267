import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  camelCase,
  toInteger,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeSplash,
  VibeButton,
  VibeToggle,
  Field2 as Field,
  color,
} from 'vibeguide';
import AlertEmailSettings from './AlertEmailSettings';

class AlertOfflineConfig extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      configFetched: false,
      highPriority: {},
      mediumHighPriority: {},
      mediumPriority: {},
      lowPriority: {},
      suppressNotificationMinutes: 0,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  onUpdateHighPriorityEmailSettings = (data) => {
    this.setState((state) => {
      return {
        highPriority: {
          ...state.highPriority,
          emailNotification: {
            ...state.highPriority.emailNotification,
            ...data,
          },
        },
      };
    });
  };

  onUpdateMediumHighPriorityEmailSettings = (data) => {
    this.setState((state) => {
      return {
        mediumHighPriority: {
          ...state.mediumHighPriority,
          emailNotification: {
            ...state.mediumHighPriority.emailNotification,
            ...data,
          },
        },
      };
    });
  };

  onUpdateMediumPriorityEmailSettings = (data) => {
    this.setState((state) => {
      return {
        mediumPriority: {
          ...state.mediumPriority,
          emailNotification: {
            ...state.mediumPriority.emailNotification,
            ...data,
          },
        },
      };
    });
  };

  onUpdateLowPriorityEmailSettings = (data) => {
    this.setState((state) => {
      return {
        lowPriority: {
          ...state.lowPriority,
          emailNotification: {
            ...state.lowPriority.emailNotification,
            ...data,
          },
        },
      };
    });
  };

  /**
   * Changing the offline duration minutes for a priority
   */
  onChangeOfflineDurationMinutes = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState((state) => {
      return {
        [name]: {
          ...state[name],
          offlineDurationMinutes: toInteger(value),
        },
      };
    });
  };

  /**
   * Toggle a specific priority on/off
   */
  onTogglePriority = (label) => {
    const priorityKey = camelCase(label);

    this.setState((state) => {
      return {
        [priorityKey]: {
          ...state[priorityKey],
          active: !state[priorityKey].active,
        },
      };
    });
  };

  getConfig = async () => {
    const config = await API.Location.Alerts.Offline.get();

    this.setState({
      configFetched: true,
      highPriority: get(config, 'high_priority', {}),
      mediumHighPriority: get(config, 'medium_high_priority', {}),
      mediumPriority: get(config, 'medium_priority', {}),
      lowPriority: get(config, 'low_priority', {}),
      suppressNotificationMinutes: get(config, 'suppressNotificationMinutes', 60),
    });
  };

  save = async () => {
    const {
      onToast,
    } = this.props;

    const {
      highPriority,
      mediumHighPriority,
      mediumPriority,
      lowPriority,
      suppressNotificationMinutes,
    } = this.state;

    const data = {
      high_priority: {
        active: highPriority.active,
        emailNotification: highPriority.emailNotification,
        offlineDurationMinutes: highPriority.offlineDurationMinutes,
        nextAllocationDays: 28,
        dmaOfflinePercentage: 0.4,
      },
      medium_high_priority: {
        active: mediumHighPriority.active,
        emailNotification: mediumHighPriority.emailNotification,
        offlineDurationMinutes: mediumHighPriority.offlineDurationMinutes,
        nextAllocationDays: 28,
      },
      medium_priority: {
        active: mediumPriority.active,
        emailNotification: mediumPriority.emailNotification,
        offlineDurationMinutes: mediumPriority.offlineDurationMinutes,
        minDmaRank: 25,
      },
      low_priority: {
        active: lowPriority.active,
        emailNotification: lowPriority.emailNotification,
        offlineDurationMinutes: lowPriority.offlineDurationMinutes,
        maxDmaRank: 26,
      },
      suppressNotificationMinutes,
    };

    onToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const saveResponse = await API.Location.Alerts.Offline.update(data);

    if (get(saveResponse, '[0].type') !== 'SYSTEMSETTING.UPDATED') {
      onToast({
        type: 'error',
        title: 'Error updating setting',
        timeout: 10,
        allowClose: true,
      });

      return;
    }

    onToast({
      type: 'success',
      title: 'Saved!',
      allowClose: true,
      delay: 500,
    });
  };

  /**
   * Render custom priority settings
   */
  renderPrioritySettings(priority, priorityStr) {
    return (
      <div
        className="setting-container"
        style={{
          marginBottom: 16,
        }}
      >
        <Field
          type="label"
          label="Do Not Send Until Device is Offline More Than"
        />

        <div className="setting">
          <Field
            className="setting-input"
            type="number"
            name={priorityStr}
            value={get(priority, 'offlineDurationMinutes', '0')}
            numberProps={{
              min: 0,
            }}
            onChange={this.onChangeOfflineDurationMinutes}
          />

          <Field
            style={{
              marginLeft: 8,
            }}
            type="select"
            value="minutes"
            name="selectedUnit"
            options={[
              {
                label: 'minutes',
                value: 'minutes',
              },
            ]}
            disabled
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      className,
    } = this.props;

    const {
      configFetched,
      highPriority,
      mediumHighPriority,
      mediumPriority,
      lowPriority,
    } = this.state;

    if (!configFetched) {
      return (
        <VibeSplash
          type="loading"
          size={64}
          style={{
            color: color.violetVibe,
          }}
        />
      );
    }

    const sectionStyle = {
      padding: 8,
      marginBottom: 16,
      border: `1px solid ${color.whiteSmoke}`,
      borderRadius: 4,
    };

    return (
      <SidePanelContainer className={classNames('AlertSidebarConfig', 'AlertOfflineConfig', className)}>
        <SidePanelContent>
          <div
            className="section"
            style={sectionStyle}
          >
            <div className="section-title">
              <VibeToggle
                label="High Priority"
                height={24}
                color={color.aquaForest}
                checked={highPriority.active}
                onChange={this.onTogglePriority}
              />
            </div>

            {highPriority.active && (
              <div className="section-content">
                {this.renderPrioritySettings(highPriority, 'highPriority')}

                <AlertEmailSettings
                  emailFlaggedLocations={get(highPriority, 'emailNotification.emailFlaggedLocations', false)}
                  recipients={get(highPriority, 'emailNotification.recipients', [])}
                  hideSuppressNotificationMinutes
                  onUpdate={this.onUpdateHighPriorityEmailSettings}
                />
              </div>
            )}
          </div>

          <div
            className="section"
            style={sectionStyle}
          >
            <div className="section-title">
              <VibeToggle
                label="Medium High Priority"
                height={24}
                color={color.aquaForest}
                checked={mediumHighPriority.active}
                onChange={this.onTogglePriority}
              />
            </div>

            {mediumHighPriority.active && (
              <div className="section-content">
                {this.renderPrioritySettings(mediumHighPriority, 'mediumHighPriority')}

                <AlertEmailSettings
                  emailFlaggedLocations={get(mediumHighPriority, 'emailNotification.emailFlaggedLocations', false)}
                  recipients={get(mediumHighPriority, 'emailNotification.recipients', [])}
                  hideSuppressNotificationMinutes
                  onUpdate={this.onUpdateMediumHighPriorityEmailSettings}
                />
              </div>
            )}
          </div>

          <div
            className="section"
            style={sectionStyle}
          >
            <div className="section-title">
              <VibeToggle
                label="Medium Priority"
                height={24}
                color={color.aquaForest}
                checked={mediumPriority.active}
                onChange={this.onTogglePriority}
              />
            </div>

            {mediumPriority.active && (
              <div className="section-content">
                {this.renderPrioritySettings(mediumPriority, 'mediumPriority')}

                <AlertEmailSettings
                  emailFlaggedLocations={get(mediumPriority, 'emailNotification.emailFlaggedLocations', false)}
                  recipients={get(mediumPriority, 'emailNotification.recipients', [])}
                  hideSuppressNotificationMinutes
                  onUpdate={this.onUpdateMediumPriorityEmailSettings}
                />
              </div>
            )}
          </div>

          <div
            className="section"
            style={sectionStyle}
          >
            <div className="section-title">
              <VibeToggle
                label="Low Priority"
                height={24}
                color={color.aquaForest}
                checked={lowPriority.active}
                onChange={this.onTogglePriority}
              />
            </div>

            {lowPriority.active && (
              <div className="section-content">
                {this.renderPrioritySettings(lowPriority, 'lowPriority')}

                <AlertEmailSettings
                  emailFlaggedLocations={get(lowPriority, 'emailNotification.emailFlaggedLocations', false)}
                  recipients={get(lowPriority, 'emailNotification.recipients', [])}
                  hideSuppressNotificationMinutes
                  onUpdate={this.onUpdateLowPriorityEmailSettings}
                />
              </div>
            )}
          </div>
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            btnColor="green"
            textColor="white"
            onClick={this.save}
          />
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

AlertOfflineConfig.propTypes = {
  className: PropTypes.string,
  onToast: PropTypes.func,
};

AlertOfflineConfig.defaultProps = {
  className: '',
  onToast: () => { },
};

export default AlertOfflineConfig;
