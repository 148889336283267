import { createAction } from 'redux-actions';

export const addField = createAction('ADD_FIELD');
export const removeField = createAction('REMOVE_FIELD');
export const updateField = createAction('UPDATE_FIELD');
export const validateFields = createAction('VALIDATE_FIELDS');

export default {
  addField,
  removeField,
  updateField,
  validateFields,
};
