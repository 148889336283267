import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';
import Message from '../../models/Message';

const baseUrl = `${process.env.VAPI_BASE_URL}/messagelists`;

export default {
  list: async (data, options) => {
    options = addVersion('3.0.4', options);

    const response = await Http.post(`${baseUrl}/search`, data, options) || {};

    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const messageBlock = response.data[i];

        if (messageBlock.messages) {
          for (let y = 0; y < messageBlock.messages.length; y++) {
            const message = messageBlock.messages[y];
            messageBlock.messages[y] = new Message(message);
          }
        }
      }
    }

    return response;
  },
  getById: async (id, options = {}) => {
    options = addVersion('3.0.4', options);
    const response = await Http.get(`${baseUrl}/${id}`, options) || {};

    if (response.messages) {
      for (let i = 0; i < response.messages.length; i++) {
        const message = response.messages[i];
        response.messages[i] = new Message(message);
      }
    }

    return response;
  },
  create: (data, options = {}) => {
    options = addVersion('3.0.4', options);
    return Http.post(baseUrl, data, options);
  },
  modify: (id, data, options = {}) => {
    options = addVersion('3.0.4', options);
    return Http.put(`${baseUrl}/${id}`, data, options);
  },
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (messageBlocks) => Http.delete(baseUrl, {
    messageBlocks,
  }),
};
