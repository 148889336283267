import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  Field2 as Field,
  VibeModal,
  VibeIcon,
  viEdit,
  viCheck,
  viClose,
  color,
} from 'vibeguide';
import './ThirdPartyDataRow.scss';

class ThirdPartyDataRow extends PureComponent {
  constructor(props) {
    super(props);

    const {
      edit,
      externalId,
      startDate,
      endDate,
    } = props;

    this.state = {
      edit,
      externalId,
      startDate,
      endDate,
      // save the original data to reference on save
      originalData: {
        externalId,
        startDate,
        endDate,
      },
      showConfirm: false,
    };
  }

  /**
   * Check the length of the external ID
   */
  checkLength = (externalId) => {
    const {
      thirdPartyProviderName,
    } = this.props;

    if (
      ((thirdPartyProviderName === 'placekey' && externalId.length < 19)
      || externalId.length === 0)
    ) {
      return false;
    }
    return true;
  };

  /**
   * Edit Row
   */
  onClickEdit = () => {
    this.setState((state) => {
      return {
        edit: !state.edit,
      };
    });
  };

  onChangePartyId = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      externalId: value,
    });
  };

  onChangeStartDate = (date) => {
    const startDate = new moment(date).format('YYYY-MM-DD');

    this.setState({
      startDate,
    });
  };

  onChangeEndDate = (date) => {
    const endDate = new moment(date).format('YYYY-MM-DD');

    this.setState({
      endDate,
    });
  };

  onClickCancel = () => {
    const {
      isNew,
      onCancel,
      onRemove,
    } = this.props;

    // reset the changes back to the original data
    this.setState((state) => {
      const {
        externalId,
        startDate,
        endDate,
      } = state.originalData;

      return {
        externalId,
        startDate,
        endDate,
        edit: false,
      };
    });

    onCancel();

    // canceling changes on a new ID before saving, automatically remove
    if (isNew) {
      onRemove();
    }
  };

  onClickRemove = () => {
    this.setState({
      showConfirm: true,
    });
  };

  onCancelRemove = () => {
    this.setState({
      showConfirm: false,
    });
  };

  onConfirmRemove = () => {
    const {
      onRemove,
    } = this.props;

    const {
      externalId,
      startDate,
      endDate,
    } = this.state;

    onRemove({
      externalId,
      startDate,
      endDate,
    });

    this.setState({
      showConfirm: false,
    });
  };

  onClickSave = () => {
    const {
      onSave,
    } = this.props;

    const {
      originalData,
      externalId,
      startDate,
      endDate,
    } = this.state;

    // update the original data so if they edit and save again the parent component can reference it
    this.setState({
      originalData: {
        externalId,
        startDate,
        endDate,
      },
      edit: false,
    });

    onSave({
      originalData,
      data: {
        externalId,
        startDate,
        endDate,
      },
    });
  };

  render() {
    const {
      className,
      header,
      error,
      canManage,
    } = this.props;

    const {
      edit,
      externalId,
      startDate,
      endDate,
      showConfirm,
    } = this.state;

    // format start/end date strings
    const startDateStr = new moment(startDate).format('ddd, MMM DD, YYYY');
    const endDateStr = new moment(endDate).format('ddd, MMM DD, YYYY');

    // disable the save changes if the placekey fields aren't filled in
    const disableSave = !this.checkLength(externalId)
      || !startDate
      || !endDate;

    if (header) {
      return (
        <div className={classNames('ThirdPartyDataRow header', className)}>
          <div className="header data-col external-id">
            Party ID
          </div>

          <div className="header data-col start-date">
            Start
          </div>

          <div className="header data-col end-date">
            End
          </div>

          <div className="header data-col actions" />
        </div>
      );
    }

    return (
      <div className={classNames('ThirdPartyDataRow', className)}>
        <div className={classNames('data-col', 'external-id', { error })}>
          {edit || error ? (
            <Field
              type="text"
              placeholder="Party ID"
              value={externalId}
              error={error}
              onChange={this.onChangePartyId}
              autoFocus
            />
          ) : externalId}
        </div>

        <div className={classNames('data-col', 'start-date', { error })}>
          {edit || error ? (
            <Field
              type="datepicker"
              placeholder="mm/dd/yyyy"
              value={startDate}
              error={error}
              dateProps={{
                startOfDay: true,
              }}
              onChange={this.onChangeStartDate}
            />
          ) : startDateStr}
        </div>

        <div className={classNames('data-col', 'end-date', { error })}>
          {edit || error ? (
            <Field
              type="datepicker"
              placeholder="mm/dd/yyyy"
              value={endDate}
              error={error}
              dateProps={{
                endOfDay: true,
              }}
              onChange={this.onChangeEndDate}
            />
          ) : endDateStr}
        </div>

        <div className="data-col actions">
          {canManage ? (
            <div className="action-icons">
              {edit || error ? (
                <VibeIcon
                  className="action-icon action-save"
                  icon={viCheck}
                  color={color.aquaForest}
                  hoverColor={color.obsidian}
                  tooltip={disableSave
                    ? 'One or more requirements are not met'
                    : 'Apply Changes'}
                  tooltipProps={{
                    placement: 'top',
                  }}
                  size={24}
                  disabled={disableSave}
                  onClick={this.onClickSave}
                />
              ) : (
                <VibeIcon
                  className="action-icon action-edit"
                  icon={viEdit}
                  color={color.manatee}
                  hoverColor={color.obsidian}
                  tooltip="Edit Row"
                  tooltipProps={{
                    placement: 'top',
                  }}
                  size={24}
                  onClick={this.onClickEdit}
                />
              )}

              {edit || error ? (
                <VibeIcon
                  className="action-icon action-cancel"
                  icon={viClose}
                  color={color.fireBrick}
                  hoverColor={color.obsidian}
                  tooltip="Cancel Changes"
                  tooltipProps={{
                    placement: 'top',
                  }}
                  size={24}
                  onClick={this.onClickCancel}
                />
              ) : (
                <VibeIcon
                  className="action-icon action-remove"
                  icon={viClose}
                  color={color.manatee}
                  hoverColor={color.obsidian}
                  tooltip="Remove Row"
                  tooltipProps={{
                    placement: 'top',
                  }}
                  size={24}
                  onClick={this.onClickRemove}
                />
              )}
            </div>
          ) : null}
        </div>

        <VibeModal
          show={showConfirm}
          type="confirm"
          title="Remove"
          text={`Are you sure you want to remove ${externalId}?`}
          confirmProps={{
            text: 'Remove',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmRemove}
          onClose={this.onCancelRemove}
        />
      </div>
    );
  }
}

ThirdPartyDataRow.propTypes = {
  className: PropTypes.string,
  header: PropTypes.bool,
  edit: PropTypes.bool,
  isNew: PropTypes.bool,
  canManage: PropTypes.bool,
  thirdPartyProviderName: PropTypes.string,
  externalId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  error: PropTypes.bool,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
};

ThirdPartyDataRow.defaultProps = {
  className: '',
  header: false,
  edit: false,
  isNew: false,
  canManage: false,
  thirdPartyProviderName: '',
  externalId: '',
  startDate: '',
  endDate: '',
  error: false,
  onCancel: () => {},
  onRemove: () => {},
  onSave: () => {},
};

export default ThirdPartyDataRow;
