import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LocationsAssigned,
} from 'vibeguide';

class UserLocations extends PureComponent {
  constructor(props) {
    super(props);

    const {
      locations,
      locationsData,
    } = props;

    this.state = {
      locations,
      locationsData,
    };
  }

  /**
   * When the locations are assigned from an object
   */
  onUpdateLocations = ({
    locations,
    locationsData,
  }) => {
    const {
      onUpdate,
    } = this.props;

    this.setState({
      locations,
      locationsData,
    });

    onUpdate({
      locations,
      locationsData,
    });
  };

  render() {
    const {
      companyId,
      partnerId,
      disabled,
      adminCompanyOnly,
    } = this.props;

    const {
      locations,
      locationsData,
    } = this.state;

    // only allow selection of locations under the admin company
    const filtersUnassigned = adminCompanyOnly
      ? { companyId }
      : {};

    return (
      <LocationsAssigned
        companyId={companyId}
        partnerId={partnerId}
        locations={locations}
        locationsData={locationsData}
        disabled={disabled}
        filtersUnassigned={filtersUnassigned}
        onUpdate={this.onUpdateLocations}
        allowACAF
        allowCompanies={!adminCompanyOnly}
        adminCompanyOnly={adminCompanyOnly}
      />
    );
  }
}

UserLocations.propTypes = {
  companyId: PropTypes.string,
  partnerId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
  }),
  disabled: PropTypes.bool,
  /**
   * Only allow ACAF for the admin company if the admin company is the selected company
   * i.e. changes { companyId: '*' } to { companyId: 'ADMIN_COMPANY_ID', locationId: '*' }
  */
  adminCompanyOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
};

UserLocations.defaultProps = {
  companyId: '',
  partnerId: '',
  locations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  disabled: false,
  adminCompanyOnly: false,
  onUpdate: () => {},
};

export default UserLocations;
