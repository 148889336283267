import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  list as mediaFormatList,
  getIcon as getMediaFormatIcon,
  getTooltip as getMediaFormatTooltip,
} from '../../../helpers/MediaFormat';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import VibeIcon from '../../VibeIcon/VibeIcon';
import CellTooltip from '../CellTypes/CellTooltip';
import IconCell from '../CellTypes/IconCell';
import NumberCell from '../CellTypes/NumberCell';
import viSpeaker from '../../../icons/viSpeaker';
import viAddCircle from '../../../icons/viAddCircle';
import viCloseCircle from '../../../icons/viCloseCircle';
import color from '../../../sass/color.scss';

const tableId = 'table:target:groups';
const permissionPrefix = 'target_group';

class TableTargetGroups extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Add/Remove',
      defaultWidth: 77,
      icon: viAddCircle,
      locked: true,
      // prevent the ability to add/remove all rows
      allowAddRemoveAll: false,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Media Format',
      searchAttr: 'mediaFormat',
      icon: viSpeaker,
      defaultWidth: 95,
      resizable: false,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...mediaFormatList,
      ],
    },
    {
      name: 'Number of Locations',
      searchAttr: 'numberOfLocations',
      defaultWidth: 120,
      resizable: false,
      searchable: false,
      sortable: true,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveTargetGroup', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveTargetGroup', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = await API.TargetGroup.deactivate(_id);

        const successText = 'TARGETGROUP.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.TargetGroup.reactivate({
          _id,
        });

        const successText = 'TARGETGROUP.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Remove a Target Group from the table
   */
  onRemoveTargetGroup = (e, _rowId) => {
    e.stopPropagation();

    const {
      onRemove,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onRemove(row);
  };

  /**
   * Add a Target Group to the table
   */
  onAddTargetGroup = (e, _rowId) => {
    e.stopPropagation();

    const {
      onAdd,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onAdd(row);
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      highlight,
    } = this.props;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Add/Remove': {
        const assigned = find(highlight, { _id: row._id }) !== undefined;

        return (
          <VibeIcon
            name={row._rowId}
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.fireBrick
              : color.aquaForest}
            hoverColor={assigned
              ? color.fireBrick75
              : color.aquaForest75}
            size={24}
            tooltip={assigned
              ? 'Remove Target Group'
              : 'Add Target Group'}
            tooltipProps={{
              placement: 'right',
            }}
            onClick={assigned
              ? this.onRemoveTargetGroup
              : this.onAddTargetGroup}
          />
        );
      }

      case 'Media Format':
        return (
          <IconCell
            rowId={row._rowId}
            icon={getMediaFormatIcon(value)}
            color={color.manatee}
            tooltip={getMediaFormatTooltip(value)}
            tooltipProps={{
              placement: 'right',
            }}
          />
        );

      case 'Number of Locations':
        return (
          <NumberCell
            value={value}
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableTargetGroups', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          // bulkArchive={API.TargetGroup.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableTargetGroups.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableTargetGroups.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableTargetGroups);
