import React from 'react';

const viChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M10.9 6L9.5 7.4l4.6 4.6-4.6 4.6 1.4 1.4 6-6z" />
    </g>
  </svg>
);

export default viChevronRight;
