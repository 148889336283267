import {
  get,
  find,
  isNumber,
} from 'lodash';
import {
  getLocal,
} from '../../utils/StorageUtil';

/**
 * Get a specific table settings
 */
export function getSettings(id, mergeSettings = {}) {
  return async () => {
    const localSettings = getLocal(`table:${id}`, {
      id,
    });

    const defaultColumns = get(mergeSettings, 'columns', []);
    const localColumns = get(localSettings, 'columns', []);

    defaultColumns.forEach((column) => {
      const localColumn = find(localColumns, { name: column.name });

      if (localColumn) {
        if (localColumn.show
          || localColumn.show === undefined
          || localColumn.show === null
        ) {
          // the column needs to be shown unless specifically told not to
          column.show = true;
        }

        if (isNumber(localColumn.width)) {
          column.width = localColumn.width;
        }
      } else {
        // no local column is saved in localStorage yet
        column.show = true;
      }
    });

    // add the local settings for the table
    mergeSettings.density = get(localSettings, 'density', 'normal');
    mergeSettings.itemsPerPage = get(localSettings, 'itemsPerPage', 50);
    mergeSettings.sortBy = get(localSettings, 'sortBy', mergeSettings.defaultSortBy);

    return mergeSettings;
  };
}

/**
 * Get columns in the proper order for a table
 */
export function getColumns({
  propColumns = [],
  viewColumns = [],
  bulk,
}) {
  // Get columns that aren't hidden
  const showColumns = propColumns.filter(column => column.show === true && viewColumns.includes(column.name));
  const columns = [];

  if (bulk) {
    // Add the select row column
    columns.push({
      name: 'Select',
      defaultWidth: 40,
      show: true,
    });
  }

  viewColumns.forEach((columnName) => {
    // order the table columns from the prop
    const showColumn = find(showColumns, { name: columnName });

    if (showColumn) {
      columns.push(showColumn);
    }
  });

  // Add the row actions column
  columns.push({
    name: 'Row Actions',
    defaultWidth: 72,
    show: true,
  });

  // Total column width of all shown columns
  let totalColumnWidth = 0;

  columns.forEach((column) => {
    totalColumnWidth += column.width || column.defaultWidth;
  });

  // Estimated column size
  const estimatedColumnSize = totalColumnWidth / columns.length;

  return {
    columns,
    estimatedColumnSize,
  };
}

export default {
  getSettings,
  getColumns,
};
