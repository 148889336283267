import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import MessageListItem from './MessageListItem';
import TagList from '../Tags/TagList';
import VibeCard from '../VibeCard/VibeCard';
import VibeIcon from '../VibeIcon/VibeIcon';
import viFrequency from '../../icons/viFrequency';
import viRedo from '../../icons/viRedo';
import viRefresh from '../../icons/viRefresh';
import viMicrophone from '../../icons/viMicrophone';
import viLoop from '../../icons/viLoop';
import viTime from '../../icons/viTime';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import color from '../../sass/color.scss';
import './MessageBlockCard.scss';

class MessageBlockCard extends PureComponent {
  /**
   * When the swap button is clicked
   */
  onClickSwapButton = () => {
    const {
      message,
      onSwap,
    } = this.props;

    onSwap(message);
  };

  /**
   * Get the message block sequence text
   */
  getSequence = (sequence = '') => {
    switch (sequence.toLowerCase()) {
      case 'fixed-no-repeat':
        return 'Fixed no repeat';

      default:
        return sequence;
    }
  };

  toggleCard = () => {
    const {
      message,
      toggleSelected,
      allowClick,
      disabled,
      selected,
      onClick,
    } = this.props;

    if (!disabled && allowClick) {
      // Send selected state back to the parent component
      toggleSelected(message, !selected);
    } else {
      console.warn('Cannot select a disabled card');
    }

    onClick(message);
  };

  render() {
    const {
      className,
      width,
      height,
      message,
      companyId,
      locationId,
      allowClick,
      hideTags,
      day,
      swap,
      swapDirection,
      withoutCardStyle,
      selected,
      disabled,
      link,
    } = this.props;

    const {
      messages = [],
      tags,
    } = message;

    let totalTimeSeconds = 0.0;
    for (let i = 0; i < messages.length; i++) {
      totalTimeSeconds += messages[i].durationSeconds;
    }
    const totalTime = moment.duration(totalTimeSeconds, 'seconds');
    const totalTimeString = moment().startOf('day').add(totalTime).format('m:ss');

    return (
      <VibeCard
        className={classNames('MessageBlockCard', className, {
          'no-card-style': withoutCardStyle,
          disabled,
        })}
        width={width}
        height={height}
        style={{
          padding:
            withoutCardStyle
              ? 0
              : 16,
          marginRight:
            withoutCardStyle
              ? 0
              : 20,
          marginBottom:
            withoutCardStyle
              ? 0
              : 20,
        }}
        selected={selected}
        allowClick={allowClick}
        link={link}
        swap={swap}
        swapDirection={swapDirection}
        onClickSwapButton={this.onClickSwapButton}
        onClick={this.toggleCard}
        onSelectMenuItem={this.onSelectMenuItem}
      >
        <div className="card-header">
          <div className={classNames('title', { swap })}>
            <VibeTooltip
              title={message.name}
              enterDelay={500}
            >
              <span>
                {message.name}
              </span>
            </VibeTooltip>
          </div>
        </div>

        <div className="content">
          <div className="review-card">
            <div className="card-setting">
              <VibeIcon
                className="icon"
                icon={viRedo}
                color={color.manatee}
                size={16}
              />
              <span className="setting-value">
                {message.listType}
              </span>
            </div>

            <div className="card-setting">
              <VibeIcon
                className="icon"
                icon={viFrequency}
                color={color.manatee}
                size={16}
              />
              <span className="setting-value">
                {message.frequency}
              </span>
            </div>

            <div className="card-setting">
              <VibeIcon
                className="icon"
                icon={viMicrophone}
                color={color.manatee}
                size={16}
              />
              <span className="setting-value">
                {message.groupSize}
              </span>
            </div>
            <div className="card-setting">
              <VibeIcon
                className="icon"
                icon={viRefresh}
                color={color.manatee}
                size={16}
              />
              <span className="setting-value">
                {message.startType}
              </span>
            </div>
          </div>

          <div className="review-card">

            <VibeTooltip
              placement="top"
              title={message.sequence}
            >
              <div className="card-setting">
                <VibeIcon
                  className="icon"
                  icon={viLoop}
                  color={color.manatee}
                  size={16}
                />
                <span className="setting-value">
                  {this.getSequence(message.sequence)}
                </span>
              </div>
            </VibeTooltip>

            <VibeTooltip
              placement="top"
              title="Total includes all messages active or otherwise"
            >
              <div className="card-setting">
                <VibeIcon
                  className="icon"
                  icon={viTime}
                  color={color.manatee}
                  size={16}
                />
                <span className="setting-value">
                  {totalTimeString}
                </span>
              </div>
            </VibeTooltip>
          </div>

          <div className="list-container">
            <PerfectScrollbar>
              {messages.map((messageItem, index) => {
                return (
                  <MessageListItem
                    key={index}
                    index={index}
                    id={messageItem._id}
                    name={messageItem.name}
                    url={messageItem.url}
                    durationSeconds={messageItem.durationSeconds}
                    started={messageItem.started}
                    expired={messageItem.expired}
                    startDate={messageItem.startDate}
                    stopDate={messageItem.endDate}
                    // Days of the week the message will play
                    daysOfWeek={messageItem.daysOfWeek}
                    // Current day the message block is being shown on the schedule
                    day={day}
                    locations={messageItem.locations}
                    messageBlockLocations={message.locations}
                    locationId={locationId}
                    companyId={companyId}
                  />
                );
              })}
            </PerfectScrollbar>
          </div>

          {!hideTags ? (
            <div className="tags-container">
              <TagList
                tags={tags}
              />
            </div>
          ) : null}
        </div>
      </VibeCard>
    );
  }
}

MessageBlockCard.propTypes = {
  className: PropTypes.string,
  /** Width of the card */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Height of the card */
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Object that describes the message that's being displayed
   */
  message: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
    active: PropTypes.bool,
    locations: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    messageCount: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.shape({
      durationSeconds: PropTypes.number,
      messageId: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    createdDate: PropTypes.string,
  }).isRequired,
  locationId: PropTypes.string,
  companyId: PropTypes.string,
  selected: PropTypes.bool,
  allowClick: PropTypes.bool,
  hideTags: PropTypes.bool,
  /** Day of the week the message block is being shown */
  day: PropTypes.string,
  /** Remove all card styling from the message card */
  withoutCardStyle: PropTypes.bool,
  /** Show swap icon */
  swap: PropTypes.bool,
  /** Swap direction */
  swapDirection: PropTypes.oneOf([
    'left',
    'right',
  ]),
  disabled: PropTypes.bool,
  toggleSelected: PropTypes.func,
  /** When a card is clicked (if allowed) */
  onClick: PropTypes.func,
  /** When the swap button is clicked */
  onSwap: PropTypes.func,
};

MessageBlockCard.defaultProps = {
  className: '',
  locationId: '*',
  companyId: '*',
  width: 271,
  height: 400,
  selected: false,
  allowClick: false,
  hideTags: false,
  day: null,
  withoutCardStyle: false,
  swap: false,
  swapDirection: null,
  disabled: false,
  toggleSelected: () => {},
  onClick: () => {},
  onSwap: () => {},
};

export default MessageBlockCard;
