import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import color from '../../sass/color.scss';
import './VibeOptionsDialog.scss';

class VibeOptionsDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.radioGroupRef = React.createRef();

    const {
      options,
    } = this.props;

    this.state = {
      value: options[0].value,
    };
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleCancel = () => {
    const {
      onCancel,
    } = this.props;

    onCancel();
  };

  handleSubmit = () => {
    const {
      onConfirm,
    } = this.props;

    const {
      value,
    } = this.state;

    onConfirm(value);
  };

  render() {
    const {
      open,
      options,
      title,
      confirmText,
      cancelText,
    } = this.props;

    const {
      value,
    } = this.state;

    return (
      <Dialog
        className="VibeOptionsDialog"
        maxWidth="xs"
        open={open}
      >
        <div className="dialog-title">{title}</div>
        <DialogContent classes={{ root: 'options' }}>
          <RadioGroup
            ref={this.radioGroupRef}
            value={value}
            onChange={this.handleChange}
          >
            {options.map((option) => (
              <FormControlLabel
                classes={{ label: 'option-label', root: option.value === value ? 'option-checked' : 'option' }}
                value={option.value}
                key={option.value}
                control={<Radio classes={{ checked: 'option-control-checked', root: 'option-control' }} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <div className="dialog-actions">
          <Button autoFocus onClick={this.handleCancel} style={{ color: color.manatee }}>
            <span
              className="button-text"
            >
              {cancelText}
            </span>
          </Button>
          <Button onClick={this.handleSubmit} style={{ color: color.violetVibe }}>
            <span
              className="button-text"
            >
              {confirmText}
            </span>
          </Button>
        </div>
        {/*
          <div className="dialog-actions">
          <div
            className="dialog-action dialog-cancel"
            style={{ color: color.manatee }}
            onClick={this.handleCancel}
          >
            {cancelText}
          </div>
          <div
            className="dialog-action dialog-confirm"
            style={{ color: color.violetVibe }}
            onClick={this.handleSubmit}
          >
            {confirmText}
          </div>
        </div>
        */}
      </Dialog>
    );
  }
}

VibeOptionsDialog.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

VibeOptionsDialog.defaultProps = {
  open: false,
  options: [],
  confirmText: 'Okay',
  cancelText: 'Cancel',
  onConfirm: () => {},
  onCancel: () => {},
};

export default VibeOptionsDialog;
