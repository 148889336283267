import { combineReducers } from 'redux';
import AdProgramReducer from './Advertiser/AdProgramReducer';
import AudioReducer from './Audio/AudioReducer';
import CompanyReducer from './Company/CompanyReducer';
import ContentDrawerReducer from './ContentDrawer/ContentDrawerReducer';
import FormReducer from './Form/FormReducer';
import GlobalReducer from './Global/GlobalReducer';
import ImportLocationsReducer from './Import/ImportLocationsReducer';
import LibraryReducer from './Library/LibraryReducer';
import LoadingReducer from './Loading/LoadingReducer';
import LocationsReducer from './Locations/LocationsReducer';
import LoginReducer from './Login/LoginReducer';
import MessagesReducer from './Messages/Messages';
import MusicReducer from './Music/Music';
import NewEventReducer from './Events/NewEventReducer';
import NewLocationReducer from './Locations/NewLocationReducer';
import NewMessageRequestReducer from './Messages/NewMessageRequestReducer';
import NewMixReducer from './Mix/NewMixReducer';
import ProgramReducer from './Program/ProgramReducer';
import SearchFiltersReducer from './Search/SearchFiltersReducer';
import SocketReducer from './Socket/SocketReducer';
import TargetTagReducer from './TargetTag/TargetTagReducer';
import ToastReducer from './Toast/ToastReducer';
import UserReducer from './User/UserReducer';
import UpdatesReducer from './Updates';

// Combining the reducers
export default combineReducers({
  adProgram: AdProgramReducer,
  audio: AudioReducer,
  companies: CompanyReducer,
  contentDrawer: ContentDrawerReducer,
  form2: FormReducer,
  global: GlobalReducer,
  import: combineReducers({
    locations: ImportLocationsReducer,
  }),
  library: LibraryReducer,
  loading: LoadingReducer,
  locations: LocationsReducer,
  login: LoginReducer,
  messages: MessagesReducer,
  music: MusicReducer,
  newEvent: NewEventReducer,
  newLocation: NewLocationReducer,
  newMessageRequest: NewMessageRequestReducer,
  newMix: NewMixReducer,
  programs: ProgramReducer,
  searchFilters: SearchFiltersReducer,
  socket: SocketReducer,
  targetTag: TargetTagReducer,
  toast: ToastReducer,
  users: UserReducer,
  updates: UpdatesReducer,
});
