import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function ProfileContentFooter({
  className,
  children,
}) {
  return (
    <div
      className={classNames('ProfileContentFooter', className)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 16,
      }}
    >
      {children}
    </div>
  );
}

ProfileContentFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ProfileContentFooter.defaultProps = {
  className: '',
};

export default ProfileContentFooter;
