import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../../VibeIcon/VibeIcon';

function IconCell({
  className,
  rowId,
  icon,
  color,
  hoverColor,
  size,
  tooltip,
  tooltipProps,
  onClick,
}) {
  return (
    <div
      className={classNames('IconCell', className)}
      style={{
        textAlign: 'center',
      }}
    >
      <VibeIcon
        name={rowId}
        icon={icon}
        color={color}
        hoverColor={onClick
          ? hoverColor
          : null}
        size={size}
        tooltip={tooltip}
        tooltipProps={tooltipProps}
        onClick={onClick}
      />
    </div>
  );
}

IconCell.propTypes = {
  className: PropTypes.string,
  rowId: PropTypes.string,
  icon: PropTypes.func.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  size: PropTypes.number,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
  onClick: PropTypes.func,
};

IconCell.defaultProps = {
  className: '',
  rowId: null,
  color: '#6d6db5',
  hoverColor: '#332a4b',
  size: 24,
  tooltip: null,
  tooltipProps: {},
  onClick: null,
};

export default IconCell;
