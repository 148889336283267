import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  GlobalActions,
  TableUsers,
  EmptyState,
  viRefresh,
  color,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import PermissionRole from './Sidebar/Content/PermissionRole/PermissionRole';
import PermissionList from './Sidebar/Content/PermissionList/PermissionList';
import UserSidebar from './Sidebar/UserSidebar';
import defaultCompanyImage from '../../assets/default_company.png';
import './ViewUsersWithPermissions.scss';

class ViewUsersWithPermissions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      permissionIds: [],
      permissionSetId: null,
      // show the users table after the users have been fetched with the current selected permissions
      fetchedWithCurrentPermissions: false,
    };
  }

  /**
   * When the permissions are changed
   */
  onUpdate = ({
    permissionIds,
  }) => {
    this.setState({
      permissionIds,
      fetchedWithCurrentPermissions: false,
    });
  };

  /**
   * When the permission set is changed
   */
  onChangePermissionSet = (permissionSetId) => {
    this.setState({
      permissionSetId,
    });
  };

  /**
   * When a row is clicked in the user table. Show the user sidebar
   */
  onClickRow = (user = {}) => {
    this.sidebar(user._id);
  };

  /**
   * Get Users that Match ANY of the Selected Permissions
   */
  getUsersMatchingPermissionsAny = async () => {
    const {
      permissionIds,
    } = this.state;

    if (permissionIds.length > 0) {
      const response = await API.User.Permission.getAny(permissionIds);

      this.setState({
        users: response.data,
        fetchedWithCurrentPermissions: true,
      });
    }
  };

  /**
   * Get Users that Match ALL of the Selected Permissions
   */
  getUsersMatchingPermissionsAll = async () => {
    const {
      permissionIds,
    } = this.state;

    if (permissionIds.length > 0) {
      const response = await API.User.Permission.getAll(permissionIds);

      this.setState({
        users: response.data,
        fetchedWithCurrentPermissions: true,
      });
    }
  };

  sidebar = (userId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: true,
      width: 600,
      children: (
        <UserSidebar
          userId={userId}
        />
      ),
    });
  };

  render() {
    const {
      className,
      user,
    } = this.props;

    const {
      users,
      permissionIds,
      permissionSetId,
      fetchedWithCurrentPermissions,
    } = this.state;

    return (
      <div className={classNames('ViewUsersWithPermissions', className)}>
        <SubNavigation
          title="View Users with Specific Permissions"
          buttons={[
            {
              text: 'Find Users Matching ANY',
              icon: viRefresh,
              userCan: [
                'admin.system_admin',
              ],
              tooltip: 'Find users that have any of the selected permissions',
              disabled: permissionIds.length <= 0,
              color: color.violetVibe,
              onClick: this.getUsersMatchingPermissionsAny,
            },
            {
              text: 'Find Users Matching ALL',
              icon: viRefresh,
              userCan: [
                'admin.system_admin',
              ],
              tooltip: 'Find users that have all of the selected permissions',
              disabled: permissionIds.length <= 0,
              onClick: this.getUsersMatchingPermissionsAll,
            },
          ]}
        />

        <div className="split-content">
          <div className="split-permissions">
            {user.can('permissionset.view') ? (
              <PermissionRole
                className="permission-role"
                permissionIds={permissionIds}
                onChange={this.onChangePermissionSet}
              />
            ) : null}

            <PermissionList
              className="permission-list"
              permissionSetId={permissionSetId}
              permissionIds={permissionIds}
              onUpdate={this.onUpdate}
            />
          </div>

          <div className="split-users">
            {permissionIds.length > 0 && fetchedWithCurrentPermissions ? (
              <TableUsers
                columns={[
                  'First Name',
                  'Last Name',
                  'Title',
                  'Email',
                  'Phone #',
                  'Company',
                  'Tags',
                  '...',
                ]}
                collection={users}
                defaultSortBy={{
                  label: 'First Name',
                  attr: 'fname',
                  direction: 'asc',
                }}
                paginator
                paginatorProps={{
                  label: 'Users',
                  urlPaging: false,
                  urlFilters: false,
                }}
                menuItems={[
                  { name: 'Archive', userCan: 'user.delete' },
                ]}
                onClickRow={this.onClickRow}
              />
            ) : (
              <EmptyState
                image={defaultCompanyImage}
                title={permissionIds.length <= 0
                  ? 'No Permissions Selected'
                  : 'Get Users Matching these Permissions'}
                description={permissionIds.length <= 0
                  ? 'Choose permissions and/or a permission set to view users that match those permissions.'
                  : 'Use the buttons above to refresh the list anytime the permissions are changed.'}
                style={{
                  borderTop: `1px solid ${color.whiteSmoke}`,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ViewUsersWithPermissions.propTypes = {
  className: PropTypes.string,
};

ViewUsersWithPermissions.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUsersWithPermissions);
