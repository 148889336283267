import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  find,
  isEqual,
  toString,
  capitalize,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import VibeButton from '../../VibeButton/VibeButton';
import VibeIcon from '../../VibeIcon/VibeIcon';
import TelemetryStatus from '../../Telemetry/TelemetryStatus';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import TimestampCell from '../CellTypes/TimestampCell';
import Tag from '../../Tags/Tag';
import viClose from '../../../icons/viClose';
import color from '../../../sass/color.scss';
import './TableTelemetryLog.scss';

const tableId = 'table:telemetry:log';

class TableTelemetryLog extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Timestamp',
      searchAttr: 'timestamp',
      defaultWidth: 260,
    },
    {
      name: 'Device Status',
      searchAttr: 'deviceActivationStatus',
      defaultWidth: 165,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Live',
          value: 'live',
        },
        {
          label: 'Standby',
          value: 'standby',
        },
        {
          label: 'Setup',
          value: 'setup',
        },
        {
          label: 'Decommissioned',
          value: 'decommissioned',
        },
      ],
    },
    {
      name: 'Level',
      searchAttr: 'level',
      defaultWidth: 115,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Debug',
          value: 'debug',
        },
        {
          label: 'Info',
          value: 'info',
        },
        {
          label: 'Warn',
          value: 'warn',
        },
        {
          label: 'Error',
          value: 'error',
        },
        {
          label: 'Fatal',
          value: 'fatal',
        },
      ],
    },
    {
      name: 'View',
      defaultWidth: 115,
    },
    {
      name: 'Message',
      searchAttr: 'message',
      defaultWidth: 300,
      searchable: true,
      resizable: true,
      autoFocus: true,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      viewItem: null,
      viewItemData: null,
      activeTabLogModal: 'info',
    };

    // listen for when data changes
    document.addEventListener('onUpdateTableTelemetryLog', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onUpdateTableTelemetryLog', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    const {
      paginatorProps: {
        urlPaging,
      },
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        pageNumber: urlPaging
          ? parseInt(getParam('page') || 1, 10)
          : 1,
      },
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  /**
   * When the View Button is clicked
   */
  onClickButtonView = (e, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    const activeTabLogModal = row.detail
      ? 'info'
      : 'status';

    this.setState({
      viewItem: row,
      activeTabLogModal,
    });
  };

  /**
   * When the log modal is opened (view item button)
   */
  onOpenLogModal = async () => {
    const {
      viewItem,
    } = this.state;

    const {
      _id: logId,
      locationId: _id,
      deviceId,
      timestamp,
    } = viewItem;

    const telemetryData = await API.Location.Telemetry.Device.current({
      _id,
      deviceId,
      asOf: timestamp,
    });

    const logDetails = await API.Location.Telemetry.Device.getLogDetails({
      _id,
      deviceId,
      logId,
    });

    // add the log details to the telemetry data
    telemetryData.logDetails = logDetails;

    this.setState({
      viewItemData: telemetryData,
    });
  };

  /**
   * When the log modal is closed
   */
  onCloseLogModal = () => {
    this.setState({
      viewItem: null,
      viewItemData: null,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Get label style for the message type cell
   */
  getLabelStyle = (status) => {
    switch (status) {
      case 'live':
        return {
          text: 'Live',
          textStyle: {
            background: color.aquaForest16,
            color: color.aquaForest,
          },
        };

      case 'standby':
        return {
          text: 'Standby',
          textStyle: {
            background: color.flamingo16,
            color: color.flamingo,
          },
        };

      case 'setup':
        return {
          text: 'Setup',
          textStyle: {
            background: color.flamingo16,
            color: color.flamingo,
          },
        };

      case 'decommissioned':
        return {
          text: 'Decommissioned',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      default:
        return {
          text: capitalize(status),
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Get the Level Colors
   */
  getLevelColors = (level = '') => {
    switch (level.toLowerCase()) {
      case 'fatal':
        return {
          text: 'Fatal',
          textStyle: {
            background: color.fireBrick16,
            color: color.fireBrick,
          },
        };

      case 'error':
        return {
          text: 'Error',
          textStyle: {
            background: color.flamingo16,
            color: color.flamingo,
          },
        };

      case 'warn':
        return {
          text: 'Warning',
          textStyle: {
            background: color.carrot16,
            color: color.carrot,
          },
        };

      case 'info':
        return {
          text: 'Info',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'debug':
        return {
          text: 'Debug',
          textStyle: {
            background: color.aquaForest16,
            color: color.aquaForest,
          },
        };

      default:
        return {
          text: level,
          textStyle: {
            background: 'transparent',
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Set the active tab to info for the log modal
   */
  setActiveTabInfo = () => {
    const {
      viewItemData,
    } = this.state;

    if (!get(viewItemData, 'logDetails.detail')) {
      // No info to view
      return;
    }

    this.setState({
      activeTabLogModal: 'info',
    });
  };

  /**
   * Set the active tab to info for the log modal
   */
  setActiveTabStatus = () => {
    this.setState({
      activeTabLogModal: 'status',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Timestamp':
        return (
          <TimestampCell
            time={value}
            format="ddd, MMM DD, YYYY, h:mm:ssa"
          />
        );

      case 'Device Status': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Level': {
        const {
          text,
          textStyle,
        } = this.getLevelColors(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'View':
        return (
          <div className="cell-content">
            <VibeButton
              name={row._rowId}
              text="View"
              btnColor="purple"
              onClick={this.onClickButtonView}
            />
          </div>
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      activeTabLogModal,
      viewItem,
      viewItemData,
    } = this.state;

    const levelColor = viewItem
      ? this.getLevelColors(viewItem.level)
      : null;

    // is the info json
    const detail = toString(get(viewItemData, 'logDetails.detail', ''));
    const isJson = detail.substring(0, 1) === '{'
      || detail.substring(0, 1) === '[';

    // default the info to the detail string
    let prettyDetail = detail;

    if (isJson) {
      try {
        // pretty print the info
        prettyDetail = JSON.stringify(JSON.parse(detail), null, 4);
      } catch (err) {
        console.error('Error parsing the string to JSON', err);
      }
    }

    const bamboo = get(viewItemData, 'info.packageName') === 'Bamboo';

    return (
      <div className={classNames('Table', 'TableTelemetryLog', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        {viewItem ? (
          <VibeModal
            className="TableTelemetryLogModal"
            type="custom"
            height={552}
            fullWidth
            maxWidth="lg"
            text={(
              <div className="log-modal">
                <div className="modal-header">
                  <div className="title">
                    Log Notes
                  </div>

                  <div className="type">
                    <Tag
                      tag={{
                        name: viewItem.level,
                      }}
                      style={{
                        fontSize: 12,
                        textTransform: 'capitalize',
                        background: levelColor.textStyle.background,
                        color: levelColor.textStyle.color,
                      }}
                    />

                    {bamboo ? (
                      <Tag
                        tag={{
                          name: 'Bamboo',
                        }}
                        style={{
                          fontSize: 12,
                          textTransform: 'capitalize',
                          background:
                            bamboo
                              ? color.aquaForest16
                              : color.violetVibe16,
                          color:
                            bamboo
                              ? color.aquaForest
                              : color.violetVibe,
                        }}
                      />
                    ) : null}
                  </div>

                  <div className="modal-toolbar">
                    <VibeIcon
                      className="close"
                      icon={viClose}
                      color={color.manatee}
                      hoverColor={color.obsidian}
                      size={24}
                      onClick={this.onCloseLogModal}
                    />
                  </div>
                </div>

                <div className="modal-content">
                  <div className="timestamp">
                    {moment(viewItem.timestamp).format('ddd, MMM DD, YYYY, h:mm:ssa')}
                  </div>

                  <div className="message">
                    {viewItem.message}
                  </div>

                  <div className="switch-tabs">
                    <div
                      className={classNames('switch-tab', {
                        active: activeTabLogModal === 'info',
                        disabled: !detail,
                      })}
                      onClick={this.setActiveTabInfo}
                    >
                      Info
                    </div>

                    <div
                      className={classNames('switch-tab', { active: activeTabLogModal === 'status' })}
                      onClick={this.setActiveTabStatus}
                    >
                      Status
                    </div>
                  </div>

                  {activeTabLogModal === 'info' ? (
                    <div className="details">
                      {prettyDetail}
                    </div>
                  ) : (
                    <div className="status">
                      {viewItemData === null ? (
                        <div>
                          Loading...
                        </div>
                      ) : (
                        <TelemetryStatus
                          telemetry={viewItemData}
                          bamboo={bamboo}
                          style={{
                            padding: 0,
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            show
            onOpen={this.onOpenLogModal}
          />
        ) : null}
      </div>
    );
  }
}

TableTelemetryLog.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableTelemetryLog.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableTelemetryLog);
