import { handleActions } from 'redux-actions';

const initialState = {
  songs: [],
};

export default handleActions(
  {
    SET_SONGS: (state, action) => {
      const songs = action.payload;

      return {
        ...state,
        songs,
      };
    },

    CLEAR_SONGS: (state) => {
      return {
        ...state,
        songs: [],
      };
    },

    UPDATE_SONG: (state, action) => {
      const {
        songId,
        name,
        value,
        isArrayField,
      } = action.payload;

      return {
        ...state,
        songs: state.songs.map(song => {
          if (song._id === songId) {
            return {
              ...song,
              [name]: isArrayField ? value.split(', ') : value,
            };
          }
          return song;
        }),
      };
    },
  },
  // Initial State
  initialState,
);
