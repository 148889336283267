import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
  sortBy,
} from 'lodash';
import classNames from 'classnames';
import {
  FindReplace,
  Add,
} from '@mui/icons-material';
import API from '../../../api';
import LocationSelection from '../../Locations/LocationSelection';
import {
  handleLocationsData,
} from '../../../helpers/ExpandedLocations';
import TargetTag from '../TargetTag';
import VibeButton from '../../VibeButton/VibeButton';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import VibeDialog from '../../VibeDialog/VibeDialog';
import {
  setTargetTagActive,
  setLocationActiveMenuItem,
} from '../../../actions/TargetTag/TargetTagActions';
import color from '../../../sass/color.scss';
import './PanelCopyToLocations.scss';

class PanelCopyToLocations extends PureComponent {
  constructor(props) {
    super(props);

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    this.state = {
      companyId: adminCompanyId,
      locations: [],
      locationsData: {
        companies: [],
        locations: [],
      },
      mergeMode: null,
      showLocations: false,
    };
  }

  onSelect = (location) => {
    this.setState((state) => {
      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations: stateLocations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        stateLocations = stateLocations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...stateLocations, location],
      };
    });
  };

  onDeselect = (locationData) => {
    this.setState((state) => {
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    });
  };

  onDeselectAll = () => {
    this.setState({
      locationsData: {
        companies: [],
        locations: [],
      },
      locations: [],
    });
  };

  onClickReplace = () => {
    this.setState({
      showLocations: true,
      mergeMode: 'replace',
    });
  };

  onClickAddTo = () => {
    this.setState({
      showLocations: true,
      mergeMode: 'merge',
    });
  };

  onClickAddToThisLocation = async () => {
    const {
      location,
      selectedTargetTags,
      onUpdateLocationTags,
    } = this.props;

    const targetingTags = selectedTargetTags.map(tag => tag._id);

    const bulkUpdateResponse = await API.Location.bulkCopyTargetTags({
      mergeMode: 'merge',
      locations: [{
        companyId: location.companyId,
        locationId: location._id,
      }],
      targetingTags,
    });

    bulkUpdateResponse.forEach((response) => {
      if (response.type !== 'LOCATION.TARGETING_TAGS_UPDATED') {
        console.error('Error updating location targeting tags', response);
      }
    });

    // Fetch new location tags
    onUpdateLocationTags();

    // No longer copying to locations
    this.onClickCancel();
  };

  onClickRemoveFromThisLocation = async () => {
    const {
      location,
      selectedTargetTags,
      onUpdateLocationTags,
    } = this.props;

    const targetingTags = selectedTargetTags.map(tag => {
      return {
        _id: tag._id,
        action: 'remove',
      };
    });

    const updateTagResponse = await API.Location.updateTargetTags({
      _id: location._id,
      targetingTags,
    });

    updateTagResponse.forEach((response) => {
      if (response.type !== 'LOCATION.TARGETING_TAGS_UPDATED') {
        console.error('Error updating location targeting tags', response);
      }
    });

    // Fetch new location tags
    onUpdateLocationTags();

    // No longer copying to locations
    this.onClickCancel();
  };

  onClickCancel = () => {
    const {
      isLocation,
      setTargetTagActive,
      setLocationActiveMenuItem,
    } = this.props;

    // Set no active target tag
    setTargetTagActive({
      _id: null,
    });

    if (isLocation) {
      setLocationActiveMenuItem(null);
    }
  };

  onConfirmLocations = async () => {
    const {
      selectedTargetTags,
    } = this.props;

    const {
      locations,
      mergeMode,
    } = this.state;

    const targetingTags = selectedTargetTags.map(tag => tag._id);

    const bulkUpdateResponse = await API.Location.bulkCopyTargetTags({
      mergeMode,
      locations,
      targetingTags,
    });

    bulkUpdateResponse.forEach((response) => {
      if (response.type !== 'LOCATION.TARGETING_TAGS_UPDATED') {
        console.error('Error updating location targeting tags', response);
      }
    });

    this.onCloseLocations();
    // No longer copying to locations
    this.onClickCancel();
  };

  onCloseLocations = () => {
    this.setState({
      showLocations: false,
      locations: [],
      locationsData: {
        companies: [],
        locations: [],
      },
      mergeMode: null,
    });
  };

  render() {
    const {
      className,
      user,
      showAddToThisLocation,
      showRemoveFromThisLocation,
      selectedTargetTags,
    } = this.props;

    const {
      companyId,
      locations,
      locationsData,
      mergeMode,
      showLocations,
    } = this.state;

    const locationFunc = user.getLocationFunc(user.companyId) === 'user'
      ? API.User.getLocations
      : API.Company.getLocations;

    if (selectedTargetTags.length <= 0) {
      return (
        <div className="PanelCopyToLocations">
          <div className="empty">
            Select Target Tags
          </div>
        </div>
      );
    }

    return (
      <div className={classNames('PanelCopyToLocations', className)}>
        <div className="tag-section">
          <div className="tag-selected-count">
            <VibeCheckbox
              color={color.aquaForest}
              size={16}
              checked
            />

            <div className="text">
              {selectedTargetTags.length} selected
            </div>
          </div>
        </div>

        <div className="tag-section btn-section">
          {showRemoveFromThisLocation ? (
            <span>
              <VibeButton
                className="btn-action"
                text="Remove from this Location"
                btnColor="red"
                textColor="white"
                icon={(
                  <Add
                    className="btn-icon"
                  />
                )}
                iconPlacement="left"
                onClick={this.onClickRemoveFromThisLocation}
              />
            </span>
          ) : null}

          {showAddToThisLocation ? (
            <span>
              <VibeButton
                className="btn-action"
                text="Add to this Location"
                btnColor="green"
                textColor="white"
                icon={(
                  <Add
                    className="btn-icon"
                  />
                )}
                iconPlacement="left"
                onClick={this.onClickAddToThisLocation}
              />
            </span>
          ) : null}

          {!showRemoveFromThisLocation && !showAddToThisLocation ? (
            <span>
              <VibeButton
                className="btn-action"
                text="Replace..."
                btnColor="purple"
                textColor="white"
                icon={(
                  <FindReplace
                    className="btn-icon"
                  />
                )}
                iconPlacement="left"
                onClick={this.onClickReplace}
              />

              <VibeButton
                className="btn-action"
                text="Add To..."
                btnColor="purple"
                textColor="white"
                icon={(
                  <Add
                    className="btn-icon"
                  />
                )}
                iconPlacement="left"
                onClick={this.onClickAddTo}
              />
            </span>
          ) : null}

          <VibeButton
            className="btn-action"
            text="Cancel"
            btnColor="transparent"
            textColor={showRemoveFromThisLocation ? 'red' : 'purple'}
            onClick={this.onClickCancel}
          />
        </div>

        <div className="tag-section">
          {selectedTargetTags.map((tag) => {
            return (
              <TargetTag
                key={tag._id}
                className="copy-tag-item"
                qualifiedName={tag.qualifiedName}
                allowCopy
              />
            );
          })}
        </div>

        <VibeDialog
          title="Select Locations"
          open={showLocations}
          onClose={this.onCloseLocations}
          fullScreen
        >
          <div className="copy-tags-header">
            <div className="copy-tags-header-content">
              <div className="header-text-container">
                <p className="header-title">Choose Locations</p>
                <p className="header-sub-title">
                  Choose locations to {mergeMode === 'merge' ? 'add' : 'replace'} target tags:
                </p>

                <div className="target-tags">
                  {selectedTargetTags.map((tag) => {
                    return (
                      <TargetTag
                        key={tag._id}
                        className="copy-tag-item"
                        qualifiedName={tag.qualifiedName}
                      />
                    );
                  })}
                </div>
              </div>

              <div className="btn-container">
                <VibeButton
                  text="Confirm Locations"
                  btnColor="green"
                  textColor="white"
                  disabled={locations.length <= 0}
                  onClick={this.onConfirmLocations}
                />
              </div>
            </div>
          </div>

          <LocationSelection
            companyId={companyId}
            selectedLocations={locations}
            locationsData={locationsData}
            filterLocations={locationFunc}
            filterCompanies={API.User.getCompanies}
            onSelect={this.onSelect}
            onDeselect={this.onDeselect}
            onDeselectAll={this.onDeselectAll}
            allowChanges
            allowPaginator
            allowCompanies
            allowAssignCompany
          />
        </VibeDialog>
      </div>
    );
  }
}

PanelCopyToLocations.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Location */
  location: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Is Location Details */
  isLocation: PropTypes.bool,
  /** Show add to this location instead of replace/add to */
  showAddToThisLocation: PropTypes.bool,
  /** Show remove from this location instead of replace/add/add to location */
  showRemoveFromThisLocation: PropTypes.bool,
  /** When location tags are altered */
  onUpdateLocationTags: PropTypes.func,
};

PanelCopyToLocations.defaultProps = {
  className: '',
  location: {},
  isLocation: false,
  showAddToThisLocation: false,
  showRemoveFromThisLocation: false,
  onUpdateLocationTags: () => {},
};

function mapStateToProps(state) {
  const selectedTargetTags = state.targetTag.tags.filter(tag => tag.selected);

  return {
    user: state.login.user,
    selectedTargetTags: sortBy(selectedTargetTags, 'qualifiedName'),
  };
}

const mapDispatchToProps = {
  setTargetTagActive,
  setLocationActiveMenuItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelCopyToLocations);
