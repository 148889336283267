import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/targetingtags`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: id => Http.get(`${baseUrl}/${id}`),
  getChildren: (id, data, options) => Http.post(`${baseUrl}/${id}/children`, data, options),
  getLocations: (id, data, options) => Http.post(`${baseUrl}/${id}/locations`, data, options),
  create: data => Http.post(baseUrl, data),
  update: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
};
