import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import statesList from '../../../utils/states';

class Information extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the category field is changed
   */
  onChangeCategory = ({
    name,
    value,
  }) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  onChangeMessageApproval = () => {
    const {
      requireMessageApproval: prevMessageApproval,
      onUpdate,
    } = this.props;

    onUpdate({ requireMessageApproval: !prevMessageApproval });
  };

  /**
   * When the tag field is changed
  */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  render() {
    const {
      companyId,
      name,
      category,
      address1,
      address2,
      country,
      city,
      state,
      zip,
      salesforceId,
      requireMessageApproval,
      tags,
      disableInput,
      user,
    } = this.props;

    let label;
    if (['AT', 'AU', 'BR', 'GE', 'IN', 'MY', 'MX', 'FM', 'MM', 'NZ', 'NG', 'PW', 'SS'].includes(country)) {
      label = 'State';
    } else {
      label = 'Province';
    }

    return (
      <div
        className="Information"
        style={{
          marginBottom: 24,
        }}
      >
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={0}
          container
        >
          <Grid
            spacing={2}
            container
          >
            <Grid
              xs={6}
              item
            >
              <Field
                type="text"
                label="Company Name"
                placeholder="Company Name"
                name="name"
                value={name}
                tabIndex={1}
                marginBottom={25}
                disabled={disableInput}
                onChange={this.onChange}
                required
                autoFocus
              />
            </Grid>

            <Grid
              xs={6}
              item
            >
              {user.can('company_category.view') && (
                <Field
                  type="dropdown"
                  label="Category"
                  name="category"
                  value={category}
                  tabIndex={2}
                  dropdownProps={{
                    type: 'category',
                    attr: 'value',
                    fetch: API.Company.Category.list,
                    create: API.Company.Category.create,
                    canCreate: user.can('company_category.create'),
                    successMessage: 'COMPANYCATEGORY.CREATED',
                  }}
                  disabled={disableInput}
                  onChange={this.onChangeCategory}
                  required
                />
              )}
            </Grid>
          </Grid>

          <Grid
            spacing={2}
            container
          >
            <Grid
              xs={6}
              item
            >
              <Field
                type="text"
                label="Address 1"
                placeholder="123 Main St."
                name="address1"
                value={address1}
                tabIndex={3}
                marginBottom={25}
                onChange={this.onChange}
                disabled={disableInput}
              />
            </Grid>

            <Grid
              xs={6}
              item
            >
              <Field
                type="text"
                label="Address 2"
                placeholder="Optional"
                name="address2"
                value={address2}
                tabIndex={4}
                marginBottom={25}
                onChange={this.onChange}
                disabled={disableInput}
              />
            </Grid>
          </Grid>

          <Grid
            spacing={2}
            container
          >
            <Grid
              xs={6}
              item
            >
              <Field
                type="country"
                label="Country"
                name="country"
                value={country || ''}
                tabIndex={5}
                onChange={this.onChange}
                disabled={disableInput}
              />
            </Grid>

            <Grid
              xs={6}
              item
            >
              <Field
                type="text"
                label="City"
                placeholder="City"
                name="city"
                value={city}
                tabIndex={6}
                onChange={this.onChange}
                disabled={disableInput}
              />
            </Grid>

            <Grid
              xs={3}
              item
            >
              {country !== 'US' ? (
                <Field
                  type="text"
                  label={label}
                  name="state"
                  value={state}
                  tabIndex={6}
                  marginBottom={25}
                  onChange={this.onChange}
                  disabled={disableInput}
                />
              ) : (
                <Field
                  type="select"
                  label="State"
                  name="state"
                  value={state}
                  tabIndex={6}
                  marginBottom={25}
                  options={statesList}
                  onChange={this.onChange}
                  disabled={disableInput}
                />
              )}
            </Grid>

            <Grid
              xs={3}
              item
            >
              <Field
                type="text"
                label="Postal Code"
                placeholder="Postal Code"
                name="postalCode"
                value={zip}
                tabIndex={7}
                marginBottom={25}
                onChange={this.onChange}
                disabled={disableInput}
              />
            </Grid>
          </Grid>

          <Grid
            spacing={2}
            container
          >
            <Grid
              xs={6}
              item
            >
              <Field
                type="text"
                label="Salesforce ID"
                placeholder="000000000000000000"
                name="salesforceId"
                value={salesforceId}
                tabIndex={8}
                marginBottom={25}
                onChange={this.onChange}
                disabled={disableInput}
              />
            </Grid>

            <Grid
              xs={6}
              item
            >
              <Field
                type="toggle"
                label="Message Approval"
                toggleProps={{
                  label: 'Require Approval',
                }}
                name="requireMessageApproval"
                value={requireMessageApproval ? 'on' : 'off'}
                tabIndex={9}
                disabled={disableInput}
                onChange={this.onChangeMessageApproval}
              />

            </Grid>
          </Grid>

          <Grid
            spacing={2}
            container
          >
            <Grid
              xs={12}
              item
            >
              <Field
                type="tags"
                label="Tags"
                name="tags"
                tagProps={{
                  companyId,
                  tags,
                  allowTypes: ['admin'],
                  showAs: 'list',
                  assign: user.can('company.assign_tag'),
                }}
                disabled={disableInput}
                onChange={this.onChangeTag}
                tabIndex={10}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  companyId: PropTypes.string,
  category: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  salesforceId: PropTypes.string,
  requireMessageApproval: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  companyId: '',
  category: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  salesforceId: '',
  requireMessageApproval: false,
  tags: [],
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
