import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeIcon,
  color,
} from 'vibeguide';
import './DragItem.scss';

function DragItem({
  className,
  icon,
}) {
  return (
    <div className={classNames('DragItem', className)}>
      {icon && (
        <VibeIcon
          icon={icon}
          color={color.white}
          size={32}
        />
      )}
    </div>
  );
}

DragItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
};

DragItem.defaultProps = {
  className: '',
  icon: null,
};

export default DragItem;
