import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import LocationNavigation from './LocationNavigation';
import './LocationsContainer.scss';

function LocationsContainer({
  user,
}) {
  return (
    <div className="LocationsContainer">
      {user.sysAdmin && (
        <LocationNavigation />
      )}

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(LocationsContainer);
