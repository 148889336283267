import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SidePanelContent.scss';

class SidePanelContent extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const {
      onRef,
    } = this.props;

    const {
      ref: {
        current: ref,
      },
    } = this;

    if (ref) {
      onRef(ref);
    }
  }

  render() {
    const {
      className,
      style,
      children,
    } = this.props;

    return (
      <div
        ref={this.ref}
        className={classNames('SidePanelContent', className)}
        style={style}
      >
        {children}
      </div>
    );
  }
}

SidePanelContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** Send the scroll ref back to the parent component */
  onRef: PropTypes.func,
};

SidePanelContent.defaultProps = {
  className: null,
  style: {},
  onRef: () => {},
};

export default SidePanelContent;
