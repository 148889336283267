import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import MessageBlockCard from '../MessageBlockCard/MessageBlockCard';
import Paginator from '../Paginator/Paginator';
import './MessageBlocks.scss';

class MessageBlocks extends Component {
  toggleSelected = (messageBlock, selected) => {
    const {
      onSelect,
      onDeselect,
    } = this.props;

    if (selected) {
      onSelect(messageBlock);
    } else {
      onDeselect(messageBlock);
    }
  };

  render() {
    const {
      messageBlocks,
      selectedIds: selectedMessageBlockIds,
      total,
      allowClick,
      allowActions,
      showPaginator,
      onArchive,
    } = this.props;

    const messageBlockCards = [];

    messageBlocks.forEach((messageBlock, index) => {
      const isSelected = selectedMessageBlockIds.includes(messageBlock._id);

      messageBlockCards.push(
        <Grid item xs={12} sm={6} md={3} key={index}>
          <MessageBlockCard
            key={index}
            toggleSelected={this.toggleSelected}
            selected={isSelected}
            message={messageBlock}
            allowClick={allowClick}
            allowActions={allowActions}
            onArchive={onArchive}
          />
        </Grid>,
      );
    });

    return (
      <div className="MessageBlocks">
        {showPaginator ? (
          <Grid container>
            <Grid item xs={12}>
              <Paginator
                className="message-block-paginator"
                totalLoaded={messageBlocks.length}
                totalItems={total}
                label="Message Blocks"
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={3}>
          {messageBlockCards}
        </Grid>
      </div>
    );
  }
}

MessageBlocks.propTypes = {
  messageBlocks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    messageCount: PropTypes.number.isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape({
      durationSeconds: PropTypes.number,
      messageId: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    createdDate: PropTypes.string,
  })).isRequired,
  /** Total number of message blocks */
  total: PropTypes.number,
  /** Selected message block IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Are all message blocks selected */
  // selectAll: PropTypes.bool,
  allowClick: PropTypes.bool,
  /** Allow actions on the message block */
  allowActions: PropTypes.bool,
  // showAllToggle: PropTypes.bool,
  showPaginator: PropTypes.bool,
  /** When a message block is selected */
  onSelect: PropTypes.func,
  /** When a message block is deselected */
  onDeselect: PropTypes.func,
  /** When a message block is archived */
  onArchive: PropTypes.func,
};

MessageBlocks.defaultProps = {
  selectedIds: [],
  // selectAll: false,
  total: 0,
  allowClick: false,
  allowActions: false,
  // showAllToggle: false,
  showPaginator: false,
  onSelect: () => {},
  onDeselect: () => {},
  onArchive: () => {},
};

export default MessageBlocks;
