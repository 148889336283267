import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import ProfileContent from '../ProfileContent';
import VolumeControl from '../../Player/VolumeControl';
import Provisioning from '../../Player/Provisioning';
import ProfileDeviceManagement from './ProfileDeviceManagement';

function ProfilePlayer({
  className,
  style,
  location,
  user,
  onUpdate,
}) {
  const [deviceConfig, setDeviceConfig] = useState({});

  const canViewDeviceConfig = user.can('location.view_device_config');
  const canManageDeviceConfig = user.can('location.manage_device_config');

  /**
   * When the volume is changed
   */
  const onChangeVolume = ({
    master,
    message,
    music,
  }) => {
    onUpdate({
      ...location,
      volume: {
        ...location.volume,
        master,
        message,
        music,
      },
    });
  };

  /**
   * When the device config is updated
   */
  const onUpdateDeviceConfig = (data) => {
    setDeviceConfig({
      ...deviceConfig,
      ...data,
    });
  };

  /**
   * Fetch the device config once (if the user has permission)
   */
  useEffect(async () => {
    if (canViewDeviceConfig) {
      // fetch the device config
      const deviceConfigResponse = await API.Location.DeviceConfig.get(location._id);
      setDeviceConfig(deviceConfigResponse);
    }
  }, []);

  // do not render until the device config is fetched (optional based on user permissions)
  if (canViewDeviceConfig && !deviceConfig._id) {
    return null;
  }

  return (
    <div
      className={classNames('ProfilePlayer', className)}
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <div
        style={{
          padding: 24,
          paddingBottom: 0,
        }}
      >
        <Grid spacing={2} container>
          <Grid xs={12} lg={6} item>
            <ProfileContent title="Volume Control">
              <VolumeControl
                locationId={get(location, '_id', '')}
                master={get(location, 'volume.master', 0)}
                message={get(location, 'volume.message', 0)}
                music={get(location, 'volume.music', 0)}
                isActive={get(location, 'active', false)}
                canManageVolume={user.can('location.modify_volume')}
                onChange={onChangeVolume}
              />
            </ProfileContent>
          </Grid>

          {canViewDeviceConfig && (
            <Grid xs={12} lg={6} item>
              <ProfileContent title="Provisioning">
                <Provisioning
                  locationId={get(location, '_id', '')}
                  playerId={get(deviceConfig, 'playerId', '')}
                  onboardStatus={get(deviceConfig, 'onboardStatus', '')}
                  macAddress={get(deviceConfig, 'macAddress', '')}
                  logLevel={get(deviceConfig, 'logLevel', '')}
                  serviceAccountUserName={get(deviceConfig, 'serviceAccountUserName', '')}
                  serviceAccountPassword={get(deviceConfig, 'serviceAccountPassword', '')}
                  activationCode={get(deviceConfig, 'activationCode', '')}
                  canManageDeviceConfig={canManageDeviceConfig}
                  isAdmin={user.sysAdmin}
                  isActive={get(location, 'active', false)}
                  onUpdate={onUpdateDeviceConfig}
                />
              </ProfileContent>
            </Grid>
          )}
        </Grid>
      </div>

      <div
        style={{
          flexGrow: 1,
          minHeight: 400,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ProfileDeviceManagement
          location={location}
        />
      </div>
    </div>
  );
}

ProfilePlayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

ProfilePlayer.defaultProps = {
  className: '',
  style: {},
};

export default ProfilePlayer;
