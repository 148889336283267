import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * The UserCan component will be the parent of a component that we will either need to hide or show
 * depending on the permissions
 * Then pass those permissions to this component which will calculate whether or not a user has the permission or not
 *
 * <UserCan can={['message.modify', 'message.sitdown']}>
 *  {COMPONENT THAT YOU WANT TO WRAP IN PERMISSIONS GOES HERE}
 * </UserCan>
 *
 * Alternatively specify the anyOf attribute to determine whether to show the component
 * based on one or more different permissions
 *
 * <UserCan anyOf={["baseline.view", "event.view"]}>
 *  {COMPONENT THAT YOU WANT TO WRAP IN PERMISSIONS GOES HERE}
 * </UserCan>
 *
 */
class UserCan extends Component {
  checkPermission = () => {
    const {
      user,
      can,
      anyOf,
    } = this.props;

    let hasPermission = false;

    if (can.length > 0) {
      let hasAllPermissions = true;

      can.forEach((permission) => {
        if (!user.can(permission)) {
          hasAllPermissions = false;
        }
      });

      hasPermission = hasAllPermissions;
    } else if (anyOf.length > 0) {
      anyOf.forEach((permission) => {
        if (user.can(permission)) {
          hasPermission = true;
        }
      });
    }

    return hasPermission;
  };

  render() {
    const {
      children,
    } = this.props;

    const hasPermission = this.checkPermission();

    return hasPermission && children;
  }
}

UserCan.propTypes = {
  /**
   * Children that are either to be shown or hidden depending on what permissions are available to the user
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Array of what actions can be taken on this specific component that must match
   * what permissions the current user actually has in order for it to be viewable
   */
  can: PropTypes.arrayOf(PropTypes.string),
  /**
   * Array of fully-qualified permission names to check. Show content only when any of the permissions are matched.
  */
  anyOf: PropTypes.arrayOf(PropTypes.string),
};

UserCan.defaultProps = {
  can: [],
  anyOf: [],
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(UserCan);
