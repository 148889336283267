// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VibeIcon {
  display: inline-block;
  vertical-align: baseline;
}
.VibeIcon.disabled {
  cursor: default;
  opacity: 0.5;
}
.VibeIcon svg {
  vertical-align: top;
}

.VibeIconButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  user-select: none;
}
.VibeIconButton.disabled {
  cursor: default;
}
.VibeIconButton.no-right-border {
  border-right: none !important;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeIcon/VibeIcon.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,wBAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,6BAAA;AAFJ","sourcesContent":[".VibeIcon {\n  display: inline-block;\n  vertical-align: baseline;\n\n  &.disabled {\n    cursor: default;\n    opacity: 0.5;\n  }\n\n  svg {\n    vertical-align: top;\n  }\n}\n\n.VibeIconButton {\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n  border-radius: 4px;\n  user-select: none;\n\n  &.disabled {\n    cursor: default;\n  }\n\n  &.no-right-border {\n    border-right: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
