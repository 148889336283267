import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FieldGroupContainer.scss';

function FieldGroupContainer({
  className,
  style,
  allowAddRow,
  addRowLabel,
  children,
  onAddRow,
}) {
  return (
    <div
      className={classNames('FieldGroupContainer', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        ...style,
      }}
    >
      {children}

      {allowAddRow && (
        <div className="add-row">
          <span
            onClick={onAddRow}
          >
            + {addRowLabel ?? 'Add Row'}
          </span>
        </div>
      )}
    </div>
  );
}

FieldGroupContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  allowAddRow: PropTypes.bool,
  addRowLabel: PropTypes.string,
  onAddRow: PropTypes.func,
};

FieldGroupContainer.defaultProps = {
  className: '',
  style: {},
  allowAddRow: false,
  addRowLabel: null,
  onAddRow: () => {},
};

export default FieldGroupContainer;
