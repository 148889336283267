import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viPlaylist,
  viStation,
  viMusic,
} from 'vibeguide';

function MusicContainer({
  user,
}) {
  return (
    <div
      className="MusicContainer"
      style={{
        height: '100%',
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Playlists"
          url="/music/playlists"
          icon={viPlaylist}
          show={user.can('playlist.view')}
        />

        <NavigationSideItem
          text="Stations"
          url="/music/stations"
          icon={viStation}
          show={user.can('mix.view')}
        />

        <NavigationSideItem
          text="Songs"
          url="/music/songs"
          icon={viMusic}
          show={user.can('music.view')}
        />
      </NavigationSide>

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(MusicContainer);
