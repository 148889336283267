import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeTooltip,
} from 'vibeguide';
import GripHandle from './Items/GripHandle';
import './GridItem.scss';

function GridItem({
  className,
  style,
  width,
  height,
  left,
  type,
  _uniqueId,
  startTime,
  stopTime,
  layer,
  tooltip,
  tooltipProps,
  draggable,
  resizable,
  children,
  onMoving,
  onResizing,
  onMouseEnter,
  onMouseLeave,
}) {
  const [hideTooltip, setHideTooltip] = useState(false);
  const [resizing, setResizing] = useState(false);
  const startHour = parseInt(startTime.split(':')[0], 10);
  const startMinute = parseInt(startTime.split(':')[1], 10);
  const stopHour = parseInt(stopTime.split(':')[0], 10);
  const stopMinute = parseInt(stopTime.split(':')[1], 10);

  // each hour represents this percentage
  const oneHourPercentage = 100 / 24;
  // each minute represents this percentage
  const oneMinutePercentage = 100 / 1440;

  // get the percentage from the top before start time
  const startHourPercentage = startHour * oneHourPercentage;
  const startMinutePercentage = startMinute * oneMinutePercentage;
  const startPercentage = startHourPercentage + startMinutePercentage;

  // get the percentage from the bottom after stop time
  const stopHourPercentage = stopHour * oneHourPercentage;
  const stopMinutePercentage = stopMinute * oneMinutePercentage;
  const stopPercentage = 100 - (stopHourPercentage + stopMinutePercentage);

  const onMouseUp = (e) => {
    setResizing(false);
    document.removeEventListener('mouseup', onMouseUp);
    onResizing(e, {
      type: null,
      _uniqueId,
    });
  };

  const onMouseDownGrip = (e, data) => {
    setResizing(true);
    document.addEventListener('mouseup', onMouseUp);
    onResizing(e, {
      ...data,
      _uniqueId,
    });
  };

  const onDragStart = (e) => {
    setHideTooltip(true);

    const crt = e.target.cloneNode(true);

    // add ID to be able to remove on drag end
    crt.setAttribute('id', 'drag-ghost');

    crt.style.position = 'absolute';
    crt.style.top = '0px';
    crt.style.left = `-${width}px`;
    // crt.style.left = '0px';
    crt.style.zIndex = '2000';

    document.body.appendChild(crt);
    // Set the drag image to follow the drop line
    // dataTransfer.setDragImage(crt, 200, 10);

    setTimeout(() => {
      e.target.style.display = 'none';
    }, 0);

    onMoving(e, {
      type,
      _uniqueId,
    });
  };

  const onDragEnd = (e) => {
    setHideTooltip(false);

    e.target.style.display = 'block';
    document.getElementById('drag-ghost').remove();

    onMoving(e, {
      type: null,
      _uniqueId,
    });
  };

  return (
    <VibeTooltip
      placement="right"
      enterDelay={1500}
      enterNextDelay={300}
      leaveDelay={hideTooltip
        ? 0
        : 500}
      arrow
      title={tooltip && !hideTooltip ? (
        <div className={classNames('GridItemTooltip', { custom: tooltipProps.custom })}>
          {tooltip}
        </div>
      ) : ''}
    >
      <div
        className={classNames('GridItem', className)}
        style={{
          width,
          height:
            height > 0
              ? height
              : 'auto',
          left,
          top:
            height > 0
              // center the item on the timeline (for interrupts)
              ? `calc(${startPercentage}% - ${height / 2}px)`
              // start the item at the exact start time
              : `${startPercentage}%`,
          bottom:
            height <= 0
              ? `${stopPercentage}%`
              : 'auto',
          zIndex: layer,
          ...style,
        }}
        draggable={draggable && !resizing}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {resizable && (
          <GripHandle
            position="top"
            onMouseDown={onMouseDownGrip}
          />
        )}

        {resizable && (
          <GripHandle
            position="bottom"
            onMouseDown={onMouseDownGrip}
          />
        )}

        {children}
      </div>
    </VibeTooltip>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  height: PropTypes.number,
  left: PropTypes.number.isRequired,
  type: PropTypes.oneOf([
    'Playlist',
    'Mix',
    'Message Block',
    'Message',
    'Other',
  ]).isRequired,
  _uniqueId: PropTypes.string,
  startTime: PropTypes.string.isRequired,
  stopTime: PropTypes.string.isRequired,
  layer: PropTypes.number,
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  tooltipProps: PropTypes.shape({
    custom: PropTypes.bool,
  }),
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onMoving: PropTypes.func,
  onResizing: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

GridItem.defaultProps = {
  className: '',
  style: {},
  height: 0,
  _uniqueId: null,
  layer: 1,
  tooltip: '',
  tooltipProps: {
    custom: false,
  },
  draggable: false,
  resizable: false,
  children: (
    <div />
  ),
  onMoving: () => {},
  onResizing: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default GridItem;
