import React from 'react';
import './ArchivedBanner.scss';

function ArchivedBanner() {
  return (
    <div className="ArchivedBanner">
      ARCHIVED
    </div>
  );
}

export default ArchivedBanner;
