import React from 'react';

const viArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M2.667 8.667L10.8 8.667 7.067 12.4 8 13.333 13.333 8 8 2.667 7.067 3.6 10.8 7.333 2.667 7.333z" />
    </g>
  </svg>
);

export default viArrowRight;
