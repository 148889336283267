import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SidePanelHeader.scss';

function SidePanelHeader({
  className,
  style,
  children,
  icons,
}) {
  return (
    <div
      className={classNames('SidePanelHeader', className)}
      style={style}
    >
      <div className="header-container">
        <div className="header-content">
          {children}
        </div>

        {icons ? (
          <div className="toolbar">
            {icons}
          </div>
        ) : null}
      </div>
    </div>
  );
}

SidePanelHeader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  icons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SidePanelHeader.defaultProps = {
  className: null,
  style: {},
  icons: null,
};

export default SidePanelHeader;
