import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import color from '../../../sass/color.scss';

function LoadingContent({
  className,
}) {
  return (
    <div
      className={classNames('LoadingContent', className)}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        backdropFilter: 'blur(6px)',
        color: color.violetVibe,
        zIndex: 2,
      }}
    >
      <div>
        <CircularProgress
          color="inherit"
          size={60}
        />
      </div>
    </div>
  );
}

LoadingContent.propTypes = {
  className: PropTypes.string,
};

LoadingContent.defaultProps = {
  className: '',
};

export default LoadingContent;
