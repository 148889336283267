import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  rrulestr,
} from 'rrule';
import {
  get,
  find,
  head,
  sortBy,
  toInteger,
} from 'lodash';
import {
  API,
  VibeModal,
  StorageUtil,
  TimeUtil,
  GlobalActions,
  ExpandedLocationsHelper,
  LocationHelper,
  NavigationHelper,
  ScheduleHelper,
  EventModel,
  Drawer,
  viEvent,
  withRouter,
  color,
} from 'vibeguide';
import EventSidebar from '../../../Programs/Events/Sidebar/EventSidebar';
import BaselineSidebar from '../../../Programs/Baselines/Sidebar/BaselineSidebar';
import ScheduleHeader from './ScheduleHeader';
import ScheduleData from './ScheduleData';
import './ScheduleContainer.scss';

class ScheduleContainer extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    this.sidebarTimeout = null;
    // start the location current time when the next minute starts
    this.startJobIn = null;
    // interval which the job runs to set the current date
    this.runJobInterval = null;

    const activeDisplay = StorageUtil.getLocal('locations:scheduleDisplay', '24h');
    const viewAds = user.can('location-ad-program.view')
      && StorageUtil.getLocal('locations:showAdLayer', true);
    const locationDate = new moment();
    const drawerItems = [];

    if (user.can('baseline.view')) {
      drawerItems.push({
        label: 'Baselines',
        fetch: API.Baseline.list,
      });
    }

    if (user.can('event.create')) {
      drawerItems.push({
        label: 'Event',
        icon: viEvent,
        tooltip: 'Drag and drop to create a new event',
        draggable: true,
      });
    }

    this.state = {
      drawerItems,
      startOfWeek: locationDate
        .clone()
        .startOf('week'),
      endOfWeek: locationDate
        .clone()
        .endOf('week'),
      activeDisplay,
      viewAds,
      schedule: {},
      adPrograms: {},
      timezone: '',
      // Is the music being overridden from the schedule
      musicOverride: false,
      // The schedule for the overridden music
      musicOverrideSchedule: null,
      // Current date and time
      currTime: null,
      // Current baseline
      // currBaseline: find(schedule.baselines, { current: true }),
      // Baselines for the week
      // weekBaselines: [],
      // Cached baselines
      // cacheBaselines: [],
      // Archived baselines
      archivedBaselines: {},
      // Baselines that start during the week at midnight
      midnightBaselines: {},
      baselineSeparators: [],
      playlists: [],
      mixes: [],
      messageBlocks: [],
      interrupts: [],
      events: [],
      showConfirmBaseline: false,
      // baseline that is being dragged to add to the schedule
      dragBaseline: {},
    };

    // listen for when an item is dropped on the schedule
    document.addEventListener('onDropItemOnSchedule', this.onDropItemOnSchedule);
  }

  componentDidMount() {
    const {
      socket,
    } = this.props;

    const qs = NavigationHelper.getParams();
    const isNew = qs.type === 'new';
    const isViewEvent = qs.eventId && qs.type !== 'new';
    const isViewBaseline = qs.baselineId && qs.type !== 'new';

    if (isNew || isViewEvent) {
      this.sidebarTimeout = setTimeout(() => {
        this.eventSidebar(qs.type, qs.eventId);
      }, 1500);
    }

    if ((isNew && qs.baselineId) || isViewBaseline) {
      this.sidebarTimeout = setTimeout(() => {
        this.baselineSidebar(qs.type, qs.baselineId);
      }, 1500);
    }

    socket.on('VAPI_EVENT', this.onApiEvent);
    this.getScheduleData({
      useCache: false,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      currentLocation,
      location: {
        search,
      },
      socket,
    } = this.props;

    const {
      currentLocation: prevCurrentLocation,
      location: {
        search: prevSearch,
      },
      socket: prevSocket,
    } = prevProps;

    if (currentLocation && !prevCurrentLocation) {
      // location data was grabbed, fetch schedule data
      this.getScheduleData({
        useCache: false,
      });

      // Group the location spec
      const group = LocationHelper.group([{
        companyId: currentLocation.companyId,
        locationId: currentLocation._id,
      }]);

      const filters = {
        active: true,
        locations: {
          matchType: 'contains',
          locations: group,
        },
      };

      this.setState((state) => {
        return {
          drawerItems: state.drawerItems.map((item) => {
            if (item.label === 'Baselines') {
              return {
                ...item,
                filters,
              };
            }

            return item;
          }),
        };
      });
    }

    if (socket !== prevSocket) {
      socket.off('VAPI_EVENT', this.onApiEvent);
      socket.on('VAPI_EVENT', this.onApiEvent);
    }

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.eventId || qs.type === 'new') {
        this.eventSidebar(qs.type, qs.eventId);
      }

      if (qs.baselineId || (qs.type === 'new' && qs.baselineId)) {
        this.baselineSidebar(qs.type, qs.baselineId);
      }
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    document.removeEventListener('onDropItemOnSchedule', this.onDropItemOnSchedule);
    socket.off('VAPI_EVENT', this.onApiEvent);
    clearTimeout(this.sidebarTimeout);
    clearTimeout(this.startJobIn);
    clearInterval(this.runJobInterval);
  }

  onApiEvent = async (e) => {
    const {
      viewAds,
    } = this.state;

    switch (e.type) {
      // Location schedule changed
      case 'LOCATION.SCHEDULE_UPDATED':
      case 'BASELINE.UPDATED':
      case 'EVENT.CREATED':
      case 'EVENT.UPDATED':
      case 'EVENT.DEACTIVATED':
      case 'PLAYLIST.UPDATED':
      case 'MIX.UPDATED':
      case 'MESSAGE.UPDATED':
      case 'MESSAGELIST.UPDATED_V3_0_4':
        this.getScheduleData({
          useCache: false,
        });
        break;

      case 'LOCATION.AD_PROGRAM_UPDATED': {
        if (viewAds) {
          const adPrograms = await this.getAdPrograms();
          this.setState({
            adPrograms,
          });
        }
        break;
      }

      default:
        break;
    }
  };

  /**
   * When an item is dropped onto the schedule
   */
  onDropItemOnSchedule = (e) => {
    const {
      type,
      day,
      itemStart,
      itemStop,
      item,
    } = e.detail;

    const {
      currentLocation: {
        _id: locationId,
        companyId,
        companyName,
        name: locationName,
        imageUrl: locationImageUrl,
        address1: locationAddress1,
        address2: locationAddress2,
        city: locationCity,
        state: locationState,
        postalCode: locationPostalCode,
        adProgramEnabled: locationAdProgramEnabled,
        adNetworkEnabled: locationAdNetworkEnabled,
        tags: locationTags,
      },
    } = this.props;

    const {
      startOfWeek,
    } = this.state;

    switch (type) {
      case 'Event': {
        const dayIndex = TimeUtil.getDayOfWeekIndex(day);
        const startHour = parseInt(itemStart.split(':')[0], 10);
        const startMinute = parseInt(itemStart.split(':')[1], 10);
        const eventDate = startOfWeek.clone().add(dayIndex, 'days');

        const event = new EventModel({
          active: true,
          locations: [{
            companyId,
            locationId,
          }],
          locationsData: ExpandedLocationsHelper.getLocationsData([{
            _id: companyId,
            name: companyName,
            locations: [{
              _id: locationId,
              name: locationName,
              imageUrl: locationImageUrl,
              address: {
                line1: locationAddress1,
                line2: locationAddress2,
                city: locationCity,
                state: locationState,
                postalCode: locationPostalCode,
              },
              adProgramEnabled: locationAdProgramEnabled,
              adNetworkEnabled: locationAdNetworkEnabled,
              tags: locationTags,
            }],
          }]),
          companyId,
          companyName,
          date: eventDate.toDate(),
          startDate: eventDate.hour(startHour).minute(startMinute).toDate(),
          duration: TimeUtil.getDuration(itemStart, itemStop),
          startTime: itemStart,
          stopTime: itemStop,
        });

        this.eventSidebar('new', null, event);

        this.setState((state) => {
          return {
            events: [
              ...state.events,
              event,
            ],
          };
        });
        break;
      }

      case 'Baseline': {
        this.setState({
          showConfirmBaseline: true,
          dragBaseline: item,
        });
        break;
      }

      default:
        break;
    }
  };

  /**
   * Cancel scheduling the baseline
   */
  onCancelScheduleBaseline = () => {
    this.setState({
      showConfirmBaseline: false,
      dragBaseline: {},
    });
  };

  /**
   * Confirm scheduling the baseline to start now
   */
  onConfirmScheduleBaseline = async () => {
    const {
      currentLocation: {
        _id: locationId,
        companyId,
      },
    } = this.props;

    const {
      currTime,
      timezone,
      dragBaseline,
    } = this.state;

    // To prevent scheduling in the past, schedule 1 minute from now
    const currDate = currTime.add(1, 'minute');

    const data = {
      action: 'add',
      baselines: [{
        _id: dragBaseline._id,
        startDate: currDate.format('YYYY-MM-DD'),
        startTime: currDate.format('HH:mm'),
        originTimeZone: timezone,
      }],
      locations: [{
        companyId,
        locationId,
      }],
    };

    await API.Location.updateSchedule(data);

    this.setState({
      showConfirmBaseline: false,
      dragBaseline: {},
    });
  };

  /**
   * When the event sidebar is closed
   */
  onCloseEventSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      eventId: null,
      type: null,
    }, {
      keepPage: true,
    });

    this.setState((state) => {
      return {
        events: state.events.filter(event => event._id.indexOf('new-event') < 0),
      };
    });

    history(url);
  };

  /**
   * When the baseline sidebar is closed
   */
  onCloseBaselineSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      baselineId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
    this.refresh();
  };

  /**
   * When the week is changed via the navigation arrows
   */
  onChangeWeek = (type) => {
    const {
      startOfWeek: currStartOfWeek,
    } = this.state;

    if (type === 'prev') {
      // Go to the previous week
      const startOfWeek = currStartOfWeek
        .clone()
        .subtract(1, 'week')
        .startOf('week');
      const endOfWeek = startOfWeek
        .clone()
        .endOf('week');

      this.setState({
        startOfWeek,
        endOfWeek,
      }, this.getScheduleData);
    } else if (type === 'next') {
      // Go to the next week
      const startOfWeek = currStartOfWeek
        .clone()
        .add(1, 'week')
        .startOf('week');
      const endOfWeek = startOfWeek
        .clone()
        .endOf('week');

      this.setState({
        startOfWeek,
        endOfWeek,
      }, this.getScheduleData);
    }
  };

  /**
   * When the display is changed
   */
  onChangeDisplay = (activeDisplay) => {
    StorageUtil.setLocal('locations:scheduleDisplay', activeDisplay);

    this.setState({
      activeDisplay,
    });
  };

  /**
   * When the ad layer is toggled on/off
   */
  onToggleAdLayer = async (viewAds) => {
    const adPrograms = viewAds
      ? await this.getAdPrograms()
      : {};

    this.setState({
      viewAds,
      adPrograms,
    });
  };

  onDragEnter = (e) => {
    e.preventDefault();

    const {
      dragType,
    } = this.props;

    const {
      currTime,
    } = this.state;

    if (dragType !== 'Baseline') {
      // only allow a baseline to drop on the whole schedule
      return;
    }

    this.setState((state) => {
      const exists = find(state.baselineSeparators, { scheduleNow: true });
      if (exists) {
        return state;
      }

      return {
        baselineSeparators: [
          ...state.baselineSeparators,
          {
            day: currTime.format('ddd'),
            oldBaselineName: 'Schedule Baseline',
            newBaselineName: 'Start Now',
            startDate: currTime.clone().startOf('day').format(),
            startTime: currTime.format('HH:mm'),
            // used to identify if the schedule now baseline is active
            scheduleNow: true,
          },
        ],
      };
    });
  };

  onDragLeave = (e) => {
    e.preventDefault();

    const {
      dragType,
    } = this.props;

    if (dragType !== 'Baseline') {
      // only allow a baseline to drop on the whole schedule
      return;
    }

    this.setState((state) => {
      return {
        baselineSeparators: state.baselineSeparators.filter(sep => sep.scheduleNow !== true),
      };
    });
  };

  onDrop = (e) => {
    e.preventDefault();

    const {
      dataTransfer,
    } = e;

    const {
      dragType,
    } = this.props;

    if (dragType !== 'Baseline') {
      // only allow baselines to be dropped on the whole schedule
      return;
    }

    const dropData = JSON.parse(dataTransfer.getData('row:data'));
    const baseline = head(dropData);

    this.setState((state) => {
      return {
        baselineSeparators: state.baselineSeparators.filter(sep => sep.scheduleNow !== true),
      };
    });

    document.dispatchEvent(new CustomEvent('onDropItemOnSchedule', {
      detail: {
        type: dragType,
        item: baseline,
      },
    }));
  };

  /**
   * Get the schedule data for the week
   */
  getScheduleData = async (options = {}) => {
    const {
      user,
      currentLocation,
    } = this.props;

    const {
      startOfWeek,
      endOfWeek,
      viewAds,
    } = this.state;

    if (!currentLocation) {
      return;
    }

    if (options.useCache !== false) {
      // use the cached baselines
      options.useCache = true;
    }

    const companyId = get(currentLocation, 'companyId', null);
    const locationId = get(currentLocation, '_id', null);

    const schedule = await API.Location.getSchedule(locationId);
    const timezone = schedule.timeZone || schedule.timezone;
    const currBaseline = find(get(schedule, 'schedule.baselines', []), { current: true });
    const musicOverride = get(currBaseline, 'override', false);
    // API event data
    const scheduleEvents = get(schedule, 'schedule.events', []);
    const musicOverrideSchedule = await API.Location.ScheduleOverride.get(locationId);
    const currTime = ScheduleHelper.getCurrentDate(timezone);
    // event occurences
    const eventOccurences = [];

    scheduleEvents.forEach((e) => {
      // Set the scheduleOriginTimeZone to the location's time zone to ensure that
      // time offsets are handled properly within the schedule context.
      e.scheduleOriginTimeZone = timezone;
    });

    // get the seconds to the next minute
    const secondsUntilNextMinute = 60 - toInteger(currTime.format('s'));
    // start the location current time when the next minute starts
    clearTimeout(this.startJobIn);
    this.startJobIn = setTimeout(this.startJob, secondsUntilNextMinute * 1000);

    const {
      weekBaselines,
      midnightBaselines,
    } = user.can('baseline.view')
      ? await ScheduleHelper.getBaselinesForWeek({
        baselines: get(schedule, 'schedule.baselines', []),
        startOfWeek,
        endOfWeek,
        timezone,
        useCache: options.useCache,
      })
      : {
        weekBaselines: [],
        midnightBaselines: {},
      };

    const {
      archivedBaselines,
      baselineSeparators,
      playlists,
      mixes,
      messageBlocks,
      interruptions,
    } = ScheduleHelper.getBaselineContentForWeek({
      weekBaselines,
      musicOverride,
      musicOverrideSchedule,
      startOfWeek,
      companyId,
      locationId,
    });

    const {
      events,
    } = user.can('event.view')
      ? await ScheduleHelper.getEventsForWeek({
        events: scheduleEvents,
        companyId,
        locationId,
      })
      : {
        events: [],
      };

    events.forEach((eventData) => {
      // Find occurences one minute before/after the week starts/ends
      const occurenceStart = startOfWeek.clone().subtract(1, 'minute').toDate();
      const occurenceEnd = endOfWeek.toDate();
      // Adding one minute to the end of the week includes events that start on Sun@00:00 for the next week
      // const occurenceEnd = endOfWeek.clone().add(1, 'minute').toDate();
      // const repeatRule = RRule.fromString(eventData.schedule);

      // create temporary event to get the start date
      const tmpEvent = new EventModel(eventData);

      const repeatRule = rrulestr(eventData.schedule, {
        // show any occurences from the start of the day, not the start of the event day/time
        dtstart: new moment(tmpEvent.startDate).startOf('day').toDate(),
        // dtstart: new moment(tmpEventStartDate).utc().toDate(),
        // dtstart: new moment(tmpEventStartDate).toDate(),
        // dtstart: new moment(tmpEvent.startDate).toDate(),
        // dtstart: tmpEventStartDate,
        // dtstart: datetime(2023, 12, 17, 22, 15),
        // dtstart: datetime(startYear, startMonth, startDay, startHour, startMinute),
        // convert the repeat rule to the location timezone
        // tzid: timezone,
      });

      const occurences = repeatRule.between(occurenceStart, occurenceEnd);

      if (occurences.length <= 0) {
        console.log('No event occurences between these dates');
        return;
      }

      occurences.forEach((date) => {
        eventData.date = date;
        const event = new EventModel(eventData);
        eventOccurences.push(event);
      });
    });

    const adPrograms = viewAds
      ? await this.getAdPrograms()
      : {};

    this.setState({
      schedule: schedule.schedule,
      timezone,
      adPrograms,
      musicOverride,
      musicOverrideSchedule,
      currTime,
      // weekBaselines,
      midnightBaselines,
      archivedBaselines,
      baselineSeparators,
      playlists,
      mixes,
      messageBlocks,
      interrupts: interruptions,
      events: sortBy(eventOccurences, 'startTime'),
    });
  };

  /**
   * Fetch all ad programs
   */
  getAdPrograms = async () => {
    const {
      currentLocation: {
        _id: locationId,
      },
    } = this.props;

    const {
      startOfWeek,
      endOfWeek,
    } = this.state;

    const adProgramItems = await API.Location.AdPrograms.list({
      locationId,
      filters: {
        active: true,
        // only request ad programs for the schedule week
        // end date is exclusive so pad one extra day (returns ad programs ending on the day before at 11:59:59 PM)
        effectiveWindow: `${startOfWeek.format('YYYY-MM-DD')}|${endOfWeek.clone().add(1, 'day').format('YYYY-MM-DD')}`,
      },
    });

    const adPrograms = await ScheduleHelper.getAdProgramsForWeek({
      adProgramItems,
      locationId,
      startOfWeek,
    });

    return adPrograms;
  };

  /**
   * Start the job to get the current time at this location
   */
  startJob = () => {
    clearInterval(this.runJobInterval);

    this.runJobInterval = setInterval(this.runJob, 60000);

    // run the job immediately
    this.runJob();
  };

  /**
   * Run the job to get the current time
   */
  runJob = () => {
    const {
      timezone,
    } = this.state;

    const currTime = ScheduleHelper.getCurrentDate(timezone);

    this.setState({
      currTime,
    });
  };

  /**
   * Determine whether or not to show or hide the event sidebar
   */
  eventSidebar = (type, eventId = null, event = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 475,
      children: (
        <EventSidebar
          eventId={eventId}
          event={event}
          isNew={type === 'new'}
        />
      ),
      onClose: this.onCloseEventSidebar,
    });
  };

  /**
   * Determine whether or not to show or hide the baseline sidebar
   */
  baselineSidebar = (type, baselineId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: true,
      children: (
        <BaselineSidebar
          baselineId={baselineId}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && baselineId !== null}
        />
      ),
      onClose: this.onCloseBaselineSidebar,
    });
  };

  render() {
    const {
      className,
      currentLocation,
      dragType,
    } = this.props;

    const {
      drawerItems,
      startOfWeek,
      endOfWeek,
      activeDisplay,
      viewAds,
      schedule,
      adPrograms,
      timezone,
      musicOverride,
      musicOverrideSchedule,
      currTime,
      midnightBaselines,
      archivedBaselines,
      baselineSeparators,
      playlists,
      mixes,
      messageBlocks,
      interrupts,
      events,
      showConfirmBaseline,
    } = this.state;

    if (!currentLocation || !currTime) {
      return null;
    }

    return (
      <div className={classNames('ScheduleContainer', className)}>
        <div className="schedule-content">
          <ScheduleHeader
            type="schedule"
            schedule={schedule}
            musicOverride={musicOverride}
            musicOverrideSchedule={musicOverrideSchedule}
            timezone={timezone}
            viewAds={viewAds}
            startOfWeek={startOfWeek}
            endOfWeek={endOfWeek}
            activeDisplay={activeDisplay}
            archivedBaselines={archivedBaselines}
            midnightBaselines={midnightBaselines}
            onChangeWeek={this.onChangeWeek}
            onChangeDisplay={this.onChangeDisplay}
            onToggleAdLayer={this.onToggleAdLayer}
            // onGetSchedule={this.getScheduleData}
          />

          <ScheduleData
            type="schedule"
            style={{
              height:
                activeDisplay === '24h'
                  ? 'calc(100% - 170px)'
                  : 'auto',
            }}
            activeDisplay={activeDisplay}
            musicOverride={musicOverride}
            startOfWeek={startOfWeek}
            currTime={currTime}
            timezone={timezone}
            playlists={playlists}
            mixes={mixes}
            messageBlocks={messageBlocks}
            interrupts={interrupts}
            baselineSeparators={baselineSeparators}
            events={events}
            adPrograms={adPrograms}
          />

          <VibeModal
            show={showConfirmBaseline}
            type="confirm"
            title="Schedule Baseline"
            text="This will schedule your baseline to start right now. This action cannot be undone."
            confirmProps={{
              text: 'Schedule Now',
              color: color.aquaForest,
            }}
            cancelProps={{
              text: 'Cancel',
              color: color.manatee,
            }}
            onConfirm={this.onConfirmScheduleBaseline}
            onClose={this.onCancelScheduleBaseline}
          />
        </div>

        {dragType === 'Baseline' && (
          <div
            className="drag-overlay"
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
          />
        )}

        <Drawer
          items={drawerItems}
        />
      </div>
    );
  }
}

ScheduleContainer.propTypes = {
  className: PropTypes.string,
};

ScheduleContainer.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
    dragType: state.global.drag.dragType,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer));
