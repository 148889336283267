import React from 'react';

const viDensityComfortable = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M1 1h22v6H1V1zm0 8h22v6H1V9zm0 8h22v6H1v-6z" />
    </g>
  </svg>
);

export default viDensityComfortable;
