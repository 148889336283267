import React from 'react';

const viCheckCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.857 14.996L5.5 12.353l1.31-1.31 3.333 3.325L17.19 7.32 18.5 8.639l-8.357 8.357z" />
    </g>
  </svg>
);

export default viCheckCircle;
