import { handleActions } from 'redux-actions';
import { get, find, filter } from 'lodash';
import User from '../../models/User';

const initialState = {
  currentUser: {
    _id: '',
    companyName: '',
    companyId: '',
    email: '',
    fname: '',
    lname: '',
    role: '',
    notifications: {
      email: false,
      sms: false,
    },
    permissions: [],
    resetPass: {
      oldPass: '',
      newPass: '',
      confirmPass: '',
    },
    locationsAllowed: [],
    allLocations: false,
    imageFile: null,
    imageUrl: '',
    tags: [],
  },
  sysIntegrator: '',
  allLocations: [],
  allCompanies: [],
  masterPermissionsList: [],
  roles: [],
  assignedLocations: [],
  unassignedLocations: [],
  assignedCompanies: [],
  unassignedCompanies: [],
  superAdmin: false,
  adminControl: false,
  allCurrentFuture: false,
  vibenomicsAdmin: false,
  showLoggedInUserDetails: false,
  tags: [],
};

export default handleActions(
  {
    FETCH_USERS: (state, action) => {
      const activeUsers = action.payload;
      return {
        ...state,
        activeUsers,
      };
    },

    FETCH_USER: (state, action) => {
      const currentUser = action.payload;
      return {
        ...state,
        currentUser: new User({ ...state.currentUser, ...currentUser.getUser }),
        showLoggedInUserDetails: currentUser.showLoggedInUserDetails,
        allCurrentFuture:
          currentUser.getUser.locationsAllowed[0].companyId === '*'
          || currentUser.getUser.locationsAllowed[0].locationId === '*',
      };
    },

    FETCH_MASTER_PERMISSIONS: (state, action) => {
      const masterPermissionsList = action.payload;
      return {
        ...state,
        masterPermissionsList: masterPermissionsList.permissions,
        vibenomicsAdmin: masterPermissionsList.adminUserAllowed,
      };
    },

    FETCH_ROLES_LIST: (state, action) => {
      const roles = action.payload;
      return {
        ...state,
        roles: roles.filter(role => role.active === true),
      };
    },

    FETCH_USER_LOCATION_LIST: (state, action) => {
      const locations = action.payload;
      return {
        ...state,
        unassignedLocations: locations.unassignedLocations,
        assignedLocations: locations.assignedLocations,
        allLocations: locations.allLocations,
      };
    },

    RESET_CURRENT_USER: state => {
      return {
        ...state,
        currentUser: new User({ ...state.currentUser, ...initialState.currentUser }),
      };
    },

    FETCH_COMPANIES_AVAILABLE: (state, action) => {
      const companies = action.payload;
      const {
        allCompanies,
        assignedCompanies,
        unassignedCompanies,
        adminControl,
        superAdmin,
        userLoggedInCompany,
      } = companies;
      if (userLoggedInCompany) {
        return {
          ...state,
          currentUser: new User({
            ...state.currentUser,
            ...{
              companyId: userLoggedInCompany.companyId,
              companyName: userLoggedInCompany.companyName,
            },
          }),
          adminControl,
          superAdmin,
          allCompanies,
          assignedCompanies,
          unassignedCompanies,
        };
      }
      return {
        ...state,
        adminControl,
        superAdmin,
        allCompanies,
        assignedCompanies,
        unassignedCompanies,
      };
    },

    UPDATE_USER_COMPANY: (state, action) => {
      const company = action.payload;
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          ...{
            companyName: company.name,
            companyId: company._id,
            permissions: [],
            locationsAllowed: [],
          },
        }),
        assignedLocations: [],
        assignedCompanies: [],
        allCurrentFuture: false,
      };
    },

    SET_USER_COMPANY: (state, action) => {
      const companyName = action.payload;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          companyName,
        },
      };
    },

    APPLY_ROLE_BASED_PERMISSION: (state, action) => {
      const permissions = action.payload;
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          permissions,
        }),
      };
    },

    ADD_CREATED_ROLE: (state, action) => {
      const role = action.payload;
      return {
        ...state,
        roles: [
          ...state.roles,
          {
            _id: role.newRole._id,
            name: role.newRole.name,
            active: role.newRole.active,
          },
        ],
      };
    },

    REMOVE_ROLE: (state, action) => {
      const role = action.payload;

      return {
        ...state,
        roles: state.roles.filter(r => r._id !== role._id),
        currentUser: new User({
          ...state.currentUser,
          role: '',
        }),
      };
    },

    ADD_USER_ROLE: (state, action) => {
      const roleName = action.payload;
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          role: roleName,
        }),
      };
    },

    TOGGLE_ALL_PERMISSIONS: (state, action) => {
      const togglePermission = action.payload;
      const userPermissions = state.currentUser.permissions || [];

      if (togglePermission.toggled) {
        const addedPermissions = filter(
          userPermissions,
          permission => {
            // Get the permission type from the name
            const ptype = get(permission, 'name', '').split('.')[0];
            return ptype !== togglePermission.permission.title.toLowerCase();
          },
        );

        const validPermissions = filter(togglePermission.permission.permission_list, p => p.valid !== false);
        // Added permissions
        const validPermissionList = validPermissions.map((permission) => {
          return {
            _id: permission._id,
            name: `${permission.ptype}.${permission.pname}`,
          };
        });

        return {
          ...state,
          currentUser: new User({
            ...state.currentUser,
            permissions: [...addedPermissions, ...validPermissionList],
          }),
        };
      }

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          permissions: state.currentUser.permissions.filter(
            permission => {
              // Get the permission type from the name
              // const ptype = get(permission, 'name', '').split('.')[0];
              // return ptype !== togglePermission.permission.title.toLowerCase();
              const pIds = togglePermission.permission.permission_list.map(p => p._id);

              return !pIds.includes(permission._id);
            },
          ),
        }),
      };
    },

    UPDATE_PERMISSION: (state, action) => {
      const permissionId = action.payload;
      const { currentUser } = state;
      const findPermission = find(currentUser.permissions, permission => permission._id === permissionId);
      let addedPermission = {};
      if (findPermission) {
        return {
          ...state,
          currentUser: new User({
            ...state.currentUser,
            permissions: filter(currentUser.permissions, p => p._id !== permissionId),
          }),
        };
      }

      addedPermission = find(state.masterPermissionsList, { _id: permissionId });

      if (!addedPermission) {
        // permission not found in the master list
        return state;
      }

      const permission = {
        _id: addedPermission._id,
        name: `${addedPermission.ptype}.${addedPermission.pname}`,
      };

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          permissions: state.currentUser.permissions
            ? [...state.currentUser.permissions, permission]
            : [permission],
        }),
      };
    },

    UPDATE_USER_TAGS: (state, action) => {
      const tag = action.payload;
      const stateTags = state.currentUser.tags;
      const newTags = typeof tag === 'string' ? filter(stateTags, t => t.id !== tag) : [...stateTags, tag];

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          tags: newTags,
        }),
      };
    },

    UPDATE_USER_NOTIFICATIONS: (state, action) => {
      const notifications = action.payload;

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          notifications,
        }),
      };
    },

    TOGGLE_SELECT_ALL: (state, action) => {
      const toggle = action.payload;
      const { locationsAtOption, toggled } = toggle;
      const userAllowedCheck = find(
        state.currentUser.permissions,
        assigned => assigned.ptype === 'admin' && assigned.pname === 'system_admin',
      );

      if (locationsAtOption === 'LOCATION' && toggled) {
        const userCompany = state.currentUser.companyId;
        const assignedLocations = state.superAdmin && userAllowedCheck && userCompany === process.env.ADMIN_COMPANY_ID
          ? state.allLocations
          : filter(state.allLocations, location => location.companyId === userCompany);
        return {
          ...state,
          assignedLocations,
        };
      }

      if (locationsAtOption === 'LOCATION' && !toggled) {
        return {
          ...state,
          assignedLocations: [],
        };
      }

      if (locationsAtOption === 'COMPANY' && toggled) {
        const userCompany = state.currentUser.companyId;
        const assignedCompanies = state.superAdmin && userAllowedCheck && userCompany === process.env.ADMIN_COMPANY_ID
          ? state.allCompanies
          : filter(state.allCompanies, company => company._id === userCompany);
        return {
          ...state,
          assignedCompanies,
        };
      }

      if (locationsAtOption === 'COMPANY' && !toggled) {
        const userCompany = state.currentUser.companyId;
        return {
          ...state,
          assignedCompanies: [],
          unassignedCompanies:
            state.superAdmin && userAllowedCheck && userCompany === process.env.ADMIN_COMPANY_ID
              ? state.allCompanies
              : filter(state.allCompanies, company => company._id === userCompany),
        };
      }

      return state;
    },

    UPDATE_USER_LOCATIONS: (state, action) => {
      const assign = action.payload;
      const { locationsAtOption, id } = assign;
      if (locationsAtOption === 'LOCATION') {
        const currentAssignedLocations = state.assignedLocations;
        const currentUnassignedLocations = state.unassignedLocations;
        const duplicateLocation = find(currentAssignedLocations, location => location._id === id);
        const getSelectedLocation = find(currentUnassignedLocations, location => location._id === id);
        return {
          ...state,
          assignedLocations: duplicateLocation
            ? filter(currentAssignedLocations, location => location._id !== id)
            : [...currentAssignedLocations, getSelectedLocation],
        };
      }

      if (locationsAtOption === 'COMPANY') {
        const currentAssignedLocations = state.assignedLocations;
        const currentAssignedCompanies = state.assignedCompanies;
        const { allCompanies } = state;
        const duplicateCompany = find(currentAssignedCompanies, company => company._id === id);
        const getCompany = find(allCompanies, company => company._id === id);
        return {
          ...state,
          assignedLocations: duplicateCompany
            ? filter(currentAssignedLocations, location => location.companyId !== id)
            : [
              ...filter(currentAssignedLocations, location => location.companyId !== id),
              ...filter(state.allLocations, location => location.companyId === id),
            ],
          assignedCompanies: duplicateCompany
            ? filter(currentAssignedCompanies, company => company._id !== id)
            : [...currentAssignedCompanies, getCompany],
        };
      }
      const userCompany = state.currentUser.companyId;
      const userAllowedCheck = find(
        state.currentUser.permissions,
        assigned => assigned.ptype === 'admin' && assigned.pname === 'system_admin',
      );
      const assignedLocations = state.superAdmin && userAllowedCheck && userCompany === process.env.ADMIN_COMPANY_ID
        ? state.allLocations
        : filter(state.allLocations, location => location.companyId === userCompany);
      const assignedCompanies = state.superAdmin && userAllowedCheck && userCompany === process.env.ADMIN_COMPANY_ID
        ? state.allCompanies
        : filter(state.allCompanies, company => company._id === userCompany);
      if (state.allCurrentFuture) {
        return {
          ...state,
          assignedLocations: [],
          assignedCompanies: [],
          allCurrentFuture: false,
        };
      }
      return {
        ...state,
        assignedLocations,
        assignedCompanies,
        allCurrentFuture: true,
      };
    },

    ADD_CURRENT_USER_LOCATION: (state, action) => {
      const location = action.payload;
      const hasLocation = find(state.currentUser.locationsAllowed, location) !== undefined;

      if (hasLocation) {
        // Do not add the location twice
        return state;
      }

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          locationsAllowed: [...state.currentUser.locationsAllowed, location],
        }),
      };
    },

    REMOVE_CURRENT_USER_LOCATION: (state, action) => {
      const locationData = action.payload;
      const locationItem = find(state.currentUser.locationsAllowed, locationData);

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          locationsAllowed: state.currentUser.locationsAllowed.filter(location => location !== locationItem),
        }),
      };
    },

    SET_CURRENT_USER_ALL_LOCATIONS: (state, action) => {
      const {
        selectAll,
        allLocationsObj,
      } = action.payload;

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          locationsAllowed: allLocationsObj,
          allLocations: selectAll,
        }),
      };
    },

    SET_CURRENT_USER: (state, action) => {
      const currentUser = action.payload;

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          ...currentUser,
        }),
      };
    },

    SET_CURRENT_USER_IMAGE: (state, action) => {
      const image = action.payload;

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          image,
        }),
      };
    },

    SET_CURRENT_USER_COMPANY: (state, action) => {
      const data = action.payload;

      const companyId = data.companyId !== undefined
        ? data.companyId
        : state.companyId;

      const companyName = data.companyName !== undefined
        ? data.companyName
        : state.companyName;

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          companyId,
          companyName,
        }),
      };
    },

    UPDATE_CURRENT_USER_COMPANY: (state, action) => {
      const companyName = action.payload;
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          companyName,
          locationsAllowed: [],
          permissions: [],
        }),
      };
    },

    SET_CURRENT_USER_PERMISSIONS: (state, action) => {
      const permissions = action.payload;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          permissions,
        },
      };
    },

    RESET_PASS_OLD: (state, action) => {
      const oldPass = action.payload;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          resetPass: {
            ...state.currentUser.resetPass,
            oldPass,
          },
        },
      };
    },

    RESET_PASS_NEW: (state, action) => {
      const newPass = action.payload;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          resetPass: {
            ...state.currentUser.resetPass,
            newPass,
          },
        },
      };
    },

    RESET_PASS_CONFIRM: (state, action) => {
      const confirmPass = action.payload;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          resetPass: {
            ...state.currentUser.resetPass,
            confirmPass,
          },
        },
      };
    },

    UPDATE_CURRENT_USER: (state, action) => {
      const currentUser = action.payload;
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          ...currentUser,
        }),
      };
    },

    SET_CURRENT_USER_TAGS: (state, action) => {
      const tags = action.payload;

      tags.forEach((tag) => {
        tag.initialTag = true;
      });

      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          tags,
        }),
      };
    },

    ADD_CURRENT_USER_TAG: (state, action) => {
      const tag = action.payload;
      const existingTag = find(state.currentUser.tags, { _id: tag._id });

      // Tag is already added (and not queued for removal)
      if (existingTag && existingTag.status !== 'remove') {
        return state;
      }

      // Tag is already added (and queued for removal)
      // Update the tag to include its new status
      if (existingTag && existingTag.status === 'remove') {
        return {
          ...state,
          currentUser: new User({
            ...state.currentUser,
            tags: state.currentUser.tags.map((t) => {
              if (t._id === existingTag._id) {
                return {
                  ...t,
                  ...tag,
                  status: existingTag.initialTag
                    ? 'unedited'
                    : tag.status,
                };
              }

              return t;
            }),
          }),
        };
      }

      // Add the new tag
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          tags: [...state.currentUser.tags, tag],
        }),
      };
    },

    REMOVE_CURRENT_USER_TAG: (state, action) => {
      const tag = action.payload;
      // Find the tag if it already exists
      const existingTag = find(state.currentUser.tags, { _id: tag._id });

      // Tag exists, add a remove status
      if (existingTag && existingTag.initialTag) {
        return {
          ...state,
          currentUser: new User({
            ...state.currentUser,
            tags: state.currentUser.tags.map((t) => {
              if (t._id === existingTag._id) {
                return {
                  ...t,
                  ...tag,
                };
              }

              return t;
            }),
          }),
        };
      }

      // Remove the tag
      return {
        ...state,
        currentUser: new User({
          ...state.currentUser,
          tags: state.currentUser.tags.filter(t => t._id !== tag._id),
        }),
      };
    },

    RESET_USER_INFORMATION: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
