// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TargetGroupDetails .row {
  margin-top: 16px;
}
.TargetGroupDetails .details-title {
  font-size: 14px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/AdOps/TargetGroups/Sidebar/TargetGroupDetails.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;AADJ;AAIE;EACE,eAAA;EACA,iBAAA;AAFJ","sourcesContent":[".TargetGroupDetails {\n\n  .row {\n    margin-top: 16px;\n  }\n\n  .details-title {\n    font-size: 14px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
