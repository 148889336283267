import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import {
  CircularProgress,
} from '@mui/material';
import API from '../../api';
import PageLayout from '../PageLayout/PageLayout';
import Paginator from '../Paginator/Paginator';
import LocationItem from './LocationItem';
import SwapButton from '../SwapButton/SwapButton';
import {
  locations as searchByLocations,
  locationsLocal as searchByLocationsLocal,
} from '../../helpers/SearchBy';
import {
  locations as sortByLocations,
  locationsLocal as sortByLocationsLocal,
} from '../../helpers/SortBy';
import './CompanyLocations.scss';

class CompanyLocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      totalItems: 0,
      hasMoreItems: false,
      // The first time data is loaded
      loaded: false,
      // Whenever data is being loaded (on show more)
      loading: false,
      showMore: false,
    };
  }

  onFilter = (data) => {
    const {
      collection,
      totalItems,
      hasMoreItems,
    } = data;

    this.setState({
      locations: collection,
      totalItems,
      hasMoreItems,
      loaded: true,
      loading: false,
    });
  };

  /**
   * Assign all loaded locations for this company
   */
  onClickAssignAll = () => {
    const {
      company,
      assign,
      selectedLocations,
      onSelectLocation,
      onDeselectLocation,
    } = this.props;

    const {
      locations,
    } = this.state;

    // Get the locations that are not selected/unselected
    const unselectedLocations = locations.filter((location) => {
      const selected = find(selectedLocations, {
        companyId: location.companyId,
        locationId: location._id,
      }) !== undefined;

      if (!selected) {
        return location;
      }

      return false;
    });

    const locationData = assign
      ? unselectedLocations.map((location) => {
        return {
          location,
          companyId: location.companyId,
          locationId: location._id,
        };
      })
      : locations.map((location) => {
        return {
          location,
          companyId: location.companyId,
          locationId: location._id,
        };
      });

    if (assign) {
      // Select the locations
      locationData.forEach((location) => {
        onSelectLocation({
          company,
          ...location,
        });
      });
    } else {
      // Deselect the locations
      locationData.forEach((location) => {
        onDeselectLocation({
          company,
          ...location,
        });
      });
    }
  };

  onClickShowMore = () => {
    // Show more locations for this company
    this.setState({
      showMore: true,
      loading: true,
    }, () => {
      this.setState({
        showMore: false,
      });
    });
  };

  /**
   * When a location is selected
   */
  onSelectLocation = (data) => {
    const {
      company,
      onSelectLocation,
    } = this.props;

    onSelectLocation({
      ...data,
      company,
    });
  };

  /**
   * When a location is deselected
   */
  onDeselectLocation = (data) => {
    const {
      company,
      onDeselectLocation,
    } = this.props;

    onDeselectLocation({
      ...data,
      company,
    });
  };

  render() {
    const {
      company,
      assign,
      allowChanges,
      companyLocations,
      selectedLocations,
    } = this.props;

    const {
      locations,
      totalItems,
      hasMoreItems,
      loaded,
      loading,
      showMore,
    } = this.state;

    // Only show assigned locations
    const showAssignedLocations = companyLocations.length > 0;

    return (
      <div className="CompanyLocations">
        <PageLayout
          sortOptions={showAssignedLocations
            ? sortByLocationsLocal
            : sortByLocations}
          searchOptions={showAssignedLocations
            ? searchByLocationsLocal
            : searchByLocations}
          placeholder={`Search locations in ${company.name}...`}
          pageSize={5}
          loadMore={showMore}
          collection={showAssignedLocations
            ? companyLocations
            : []}
          // filterParamFirst={company._id}
          filter={showAssignedLocations
            ? null
            : API.Company.getLocations}
          filterProps={{
            fetchFilters: {
              _id: company._id,
            },
          }}
          onFilter={this.onFilter}
          disableView
        />

        <div className="locations-container">
          {loaded ? (
            <div>
              <div className="locations-header">
                <Paginator
                  totalLoaded={locations.length}
                  totalItems={totalItems}
                  label="Locations"
                />

                {allowChanges && locations.length > 0 ? (
                  <SwapButton
                    className="assign-btn"
                    text={locations.length > 1
                      ? `${assign ? 'Assign' : 'Unassign'} These ${locations.length} Locations`
                      : `${assign ? 'Assign' : 'Unassign'} This Location`}
                    arrowPlacement={assign ? 'right' : 'left'}
                    arrowPosition={assign ? 'right' : 'left'}
                    onClick={this.onClickAssignAll}
                  />
                ) : null}
              </div>

              <div className="locations-list">
                {locations.map((location) => {
                  // Is the location currently selected
                  const selected = find(selectedLocations, {
                    companyId: location.companyId,
                    locationId: location._id,
                  }) !== undefined;

                  return (
                    <LocationItem
                      key={location._id}
                      location={location}
                      selected={selected}
                      assign={assign}
                      allowChanges={allowChanges}
                      onSelect={this.onSelectLocation}
                      onDeselect={this.onDeselectLocation}
                    />
                  );
                })}
              </div>

              {loading ? (
                <div className="loading">
                  <CircularProgress
                    color="inherit"
                  />
                </div>
              ) : null}

              {hasMoreItems ? (
                <div className="has-more">
                  <span
                    className="show-more"
                    onClick={this.onClickShowMore}
                  >
                    Show more...
                  </span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="loading">
              <CircularProgress
                color="inherit"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

CompanyLocations.propTypes = {
  /** Company data */
  company: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    category: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  /** Company locations (Use only when showing currently assigned locations) */
  companyLocations: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    city: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
  })),
  /** Selected locations */
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Assign or Unassign the locations */
  assign: PropTypes.bool,
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** When a location is selected */
  onSelectLocation: PropTypes.func,
  /** When a location is selected */
  onDeselectLocation: PropTypes.func,
};

CompanyLocations.defaultProps = {
  companyLocations: [],
  selectedLocations: [],
  assign: false,
  allowChanges: false,
  onSelectLocation: () => {},
  onDeselectLocation: () => {},
};

export default CompanyLocations;
