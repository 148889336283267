import { handleActions } from 'redux-actions';

export default handleActions(
  {
    DRAWER_OPEN: (state) => {
      return {
        ...state,
        isOpen: true,
      };
    },

    DRAWER_CLOSE: (state) => {
      return {
        ...state,
        isOpen: false,
      };
    },

    SET_DRAGGING: (state, action) => {
      const {
        payload: {
          isDragging,
          dragType,
        },
      } = action;

      return {
        ...state,
        dragType: dragType || null,
        isDragging,
      };
    },
  },
  // Initial State
  {
    isOpen: false,
    isDragging: false,
    dragType: null,
  },
);
