import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import CellTooltip from './CellTooltip';

function TimestampCell({
  className,
  style,
  time,
  format = 'ddd, MMM DD, YYYY, h:mma',
}) {
  if (!time) {
    // No time to compare to
    return null;
  }

  const timestamp = new moment(time).format(format);

  return (
    <CellTooltip title={timestamp}>
      <div
        className={classNames('TimestampCell', className)}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...style,
        }}
      >
        {timestamp}
      </div>
    </CellTooltip>
  );
}

TimestampCell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  /** Timestamp to parse */
  time: PropTypes.string,
  /** Custom format of date/time */
  format: PropTypes.string,
};

TimestampCell.defaultProps = {
  className: '',
  style: {},
  time: null,
  format: 'ddd, MMM DD, YYYY, h:mma',
};

export default TimestampCell;
