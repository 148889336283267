import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  ImportLocationsActions,
  VibeDialog,
} from 'vibeguide';
import ImportHeader from './ImportHeader';
import ImportBulkInformation from './ImportBulkInformation';
import ImportFile from './ImportFile';
import ImportFields from './ImportFields';
import './ImportLocations.scss';

class ImportLocations extends PureComponent {
  componentWillUnmount() {
    const {
      resetImportLocations,
    } = this.props;

    resetImportLocations();
  }

  render() {
    return (
      <VibeDialog
        title="Import Locations"
        closeDialogLink="/locations"
        open
        fullScreen
      >
        <div className="ImportLocations">
          <PerfectScrollbar>
            <ImportHeader />

            <div className="import-content">
              <div className="left-col">
                <ImportBulkInformation />
              </div>

              <div className="right-col">
                <ImportFile />
                <ImportFields />
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </VibeDialog>
    );
  }
}

const mapDispatchToProps = {
  resetImportLocations: ImportLocationsActions.resetImportLocations,
};

export default connect(null, mapDispatchToProps)(ImportLocations);
