// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SongPRO {
  padding-bottom: 24px;
}
.SongPRO .confirmed-date {
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Music/Songs/Sidebar/SongPRO.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".SongPRO {\n  padding-bottom: 24px;\n\n  .confirmed-date {\n    margin-top: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
