import Http from '../Http';

const baseUrl = `${process.env.VAPI_BASE_URL}/authentication`;

export default {
  // authenticate: (data, options) => Http.post(`${baseUrl}/authenticate`, data, options),
  acceptChallenge: (data, options) => Http.post(`${baseUrl}/challenge`, data, options),
  resetPassword: (data, options) => Http.post(`${baseUrl}/password/reset`, data, options),
  confirmPassword: (data, options) => Http.post(`${baseUrl}/password/reset/confirm`, data, options),
};
