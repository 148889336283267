import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  find,
} from 'lodash';
import './SidePanelTabs.scss';

class SidePanelTabs extends PureComponent {
  /**
   * When a tab is selected
   */
  onSelectTab = (e) => {
    const {
      target: {
        dataset: {
          name,
        },
      },
    } = e;

    const {
      tabs,
      onSelectTab,
    } = this.props;

    const tab = find(tabs, { name });

    if (!tab.disabled) {
      onSelectTab(name);
    }
  };

  render() {
    const {
      className,
      tabs,
    } = this.props;

    return (
      <div className={classNames('SidePanelTabs', className)}>
        <div className="tabs">
          {tabs.map((tab) => {
            const tabHtml = (
              <div
                key={`tab-${tab.name}`}
                className={classNames('tab', { selected: tab.selected, disabled: tab.disabled })}
                data-name={tab.name}
                onClick={this.onSelectTab}
              >
                {tab.name}

                {tab.selected ? (
                  <div className="active-bar" />
                ) : null}
              </div>
            );

            if (tab.link && !tab.disabled) {
              return (
                <Link
                  key={`tab-link-${tab.name}`}
                  to={tab.link}
                >
                  {tabHtml}
                </Link>
              );
            }

            return tabHtml;
          })}
        </div>
      </div>
    );
  }
}

SidePanelTabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    matchType: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  })).isRequired,
  onSelectTab: PropTypes.func,
};

SidePanelTabs.defaultProps = {
  className: null,
  onSelectTab: () => {},
};

export default SidePanelTabs;
