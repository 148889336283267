import React from 'react';
import PropTypes from 'prop-types';
import {
  size,
  isNumber,
  toString,
} from 'lodash';

function formatPhoneNumber(phone = '') {
  if (isNumber(phone)) {
    phone = toString(phone);
  }

  const match = phone.match(/[0-9]+/g);
  const phoneNumber = size(match) > 0
    ? match.join('')
    : '';

  const phoneLength = size(phoneNumber);

  if (phoneLength === 10) {
    // Format 10 digit phone
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
  }

  if (phoneLength === 7) {
    // Format 7 digit phone
    return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3)}`;
  }

  return phone;
}

function PhoneNumber({
  value,
}) {
  const phoneNumber = formatPhoneNumber(value);

  return (
    <div className="PhoneNumber">
      {phoneNumber}
    </div>
  );
}

PhoneNumber.propTypes = {
  /** Value to parse */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default PhoneNumber;
