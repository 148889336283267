import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
  find,
  toNumber,
  uniqueId,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  LocationActions,
  NavigationHelper,
  VibeModal,
  VibeButtonNew,
  VibeIcon,
  viAlertCircle,
  viArchive,
  viCopy,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import LocationSidebar from '../../../../Sidebar/LocationSidebar';
import ProfileContent from '../ProfileContent';
import ProfileInfo from '../ProfileInfo';
import ProfileAddress from '../ProfileAddress';
import ProfileSettings from '../ProfileSettings';
import ProfileFooter from '../ProfileFooter';
import ThirdPartyData from '../../../ThirdPartyData/ThirdPartyData';
import ExternalInfo from '../../../ThirdPartyData/ExternalInfo';
import ProfileExternalIds from '../ProfileExternalIds';
import './DuplicateIDModal.scss';

function ProfileInformation({
  className,
  style,
  location,
  user,
  setPanel,
  queueToast,
  updateCurrentLocation,
  onUpdate,
}) {
  const [hasChanges, setHasChanges] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [selectedThirdPartyProvider, setSelectedThirdPartyProvider] = useState('');
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const [newExternalIDs, setNewExternalIDs] = useState([]);
  const [duplicateArray, setDuplicateArray] = useState([]);

  const history = useNavigate();
  const qs = NavigationHelper.getParams();
  const isActive = get(location, 'active', false);

  // use external ids
  const useExternalIds = get(location, 'externalIds', []).map(externalId => {
    if (externalId._id) {
      return externalId;
    }

    return {
      _id: uniqueId('external-id-'),
      ...externalId,
    };
  });

  /**
   * The location object was changed
   */
  const onChange = (data) => {
    setHasChanges(true);
    onUpdate(data);
  };

  /**
   * The selected third party provider has changed
   */
  const onChangeThirdPartyProvider = (providerName) => {
    setSelectedThirdPartyProvider(providerName);
  };

  /**
   * Get the active third party data item row
   */
  const getActiveThirdPartyItem = () => {
    const currDate = new moment();
    // get the item that is currently active based on start/end dates
    const activeItem = find(location.thirdPartyData, (item) => {
      // to make sure it's in between the dates use the end of the day for the stop date
      const endDate = moment(item.endDate).endOf('day');
      return currDate.isBetween(item.startDate, endDate);
    });

    return activeItem;
  };

  /**
   * Information Panel
   */
  const getInformation = () => {
    return (
      <ProfileContent title="Information">
        <ProfileInfo
          name={get(location, 'name', '')}
          companyId={get(location, 'companyId', '')}
          companyName={get(location, 'companyName', '')}
          bannerName={get(location, 'bannerName', '')}
          category={get(location, 'category', '')}
          salesforceId={get(location, 'salesforceId', '')}
          tags={get(location, 'tags', [])}
          isAdmin={user.sysAdmin}
          canEdit={user.can('location.modify')}
          canAssignTag={user.can('location.assign_tag')}
          onUpdate={onChange}
        />
      </ProfileContent>
    );
  };

  /**
   * Address Panel
   */
  const getAddress = () => {
    return (
      <ProfileContent title="Address">
        <ProfileAddress
          country={get(location, 'country', '')}
          address1={get(location, 'address1', '')}
          address2={get(location, 'address2', '')}
          city={get(location, 'city', '')}
          state={get(location, 'state', '')}
          province={get(location, 'province', '')}
          postalCode={get(location, 'postalCode', '')}
          geoCoordinatesLocked={get(location, 'geoCoordinatesLocked', true)}
          lat={get(location, 'lat', 0)}
          long={get(location, 'long', 0)}
          timezone={get(location, 'timeZone', '')}
          locality={get(location, 'locality', {})}
          isAdmin={user.sysAdmin}
          canEdit={user.can('location.modify')}
          onUpdate={onChange}
        />
      </ProfileContent>
    );
  };

  /**
   * Settings Panel
   */
  const getSettings = () => {
    return (
      <ProfileContent title="Ad Information">
        <ProfileSettings
          companyId={get(location, 'companyId', '')}
          contentRating={get(location, 'contentRating', '')}
          mediaFormat={get(location, 'mediaFormat', '')}
          contentDeliverySystem={get(location, 'contentDeliverySystem', '')}
          contentNetworkOwner={get(location, 'contentNetworkOwner', '')}
          adDeliverySystem={get(location, 'adDeliverySystem', '')}
          adNetworkOwner={get(location, 'adNetworkOwner', '')}
          adProgramEnabled={get(location, 'adProgramEnabled', false)}
          adNetworkEnabled={get(location, 'adNetworkEnabled', false)}
          isAdmin={user.sysAdmin}
          canEdit={user.can('location.modify')}
          canViewCompany={user.can('company.view')}
          canEnableAdProgram={user.can('location-ad-program.modify')}
          canCreateCompanyBanner={user.can('company_banner.create')}
          canModifyLocationAdProgram={user.can('location-ad-program.modify')}
          onUpdate={onChange}
        />
      </ProfileContent>
    );
  };

  /**
   * When the user clicks the unarchive button
   */
  const onClickUnarchive = async () => {
    const response = await API.Location.reactivate({
      _id: location._id,
    });

    const success = get(response, '[0].type') === 'LOCATION.REACTIVATED';

    if (success) {
      updateCurrentLocation({
        active: true,
      });

      onUpdate({
        ...location,
        active: true,
      });
    }
  };

  /**
   * When the user clicks the archive button
   */
  const onClickArchive = () => {
    setShowConfirmArchive(true);
  };

  /**
   * Archive the location
   */
  const onConfirmArchive = async () => {
    await API.Location.deactivate(location._id);

    setShowConfirmArchive(false);
    history('/locations');
  };

  /**
   * Cancel archiving the location
   */
  const onCancelArchive = () => {
    setShowConfirmArchive(false);
  };

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      type: null,
    });

    history(url);
  };

  /**
   * Open the sidebar
   */
  const onOpenSidebar = () => {
    setPanel({
      show: true,
      backdrop: true,
      children: (
        <LocationSidebar
          locationId={location._id}
          isNew
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  /**
   * Close Duplicate Modal
   */
  const closeDuplicateModal = () => {
    setShowDuplicateModal(false);
  };

  /**
   * Save the location profile
   */
  const onSave = async () => {
    // data object to send to the API to save the location
    let data = {};
    const modifyTags = location.tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

    if (user.can('location.modify') && user.sysAdmin) {
      // Update entire location profile
      data = {
        _id: location._id,
        name: location.name,
        companyId: location.companyId,
        category: location.category,
        address1: location.address1,
        address2: location.address2 || '',
        country: location.country,
        city: location.city,
        state: location.state,
        postalCode: location.postalCode,
        bannerName: location.bannerName,
        contentRating: location.contentRating,
        geoCoordinatesLocked: location.geoCoordinatesLocked,
        salesforceId: location.salesforceId,
        mediaFormat: location.mediaFormat,
        adDeliverySystem: location.adDeliverySystem,
        adNetworkOwner: location.adNetworkOwner,
        contentDeliverySystem: location.contentDeliverySystem,
        contentNetworkOwner: location.contentNetworkOwner,
        adProgramEnabled: location.adProgramEnabled,
        adNetworkEnabled: location.adNetworkEnabled,
        lat: toNumber(location.lat),
        long: toNumber(location.long),
        tags: modifyTags.map((tag) => {
          return {
            _id: tag._id,
            action: tag.status,
          };
        }),
      };
    } else if (user.can('location.modify')) {
      // Only allow non-admins to update rating, tags, and image
      data = {
        _id: location._id,
        contentRating: location.contentRating,
        tags: modifyTags.map((tag) => {
          return {
            _id: tag._id,
            action: tag.status,
          };
        }),
      };
    }

    if (user.can('location.manage_third_party_data')) {
      data.thirdPartyData = {
        ...location.thirdPartyData,
        // ensure there is a data array when sending up third party data
        data: get(location, 'thirdPartyData.data', []),
      };
    }

    queueToast({
      type: 'info',
      title: 'Saving Location...',
      allowClose: true,
    });

    try {
      // If duplicates, throw warning modal
      const duplicateArray1 = [];

      newExternalIDs.forEach((newID) => {
        useExternalIds.forEach((extID) => {
          if (newID.source === extID.source && newID.extId === extID.extId) {
            duplicateArray1.push(newID);
          }
        });
      });

      if (duplicateArray1.length > 0) {
        // Set to get unique objects
        const setObj = new Set(duplicateArray1.map(JSON.stringify));
        const dupeArray = Array.from(setObj).map(JSON.parse);
        // show duplicate modal
        setDuplicateArray(dupeArray);
        setShowDuplicateModal(true);
        return;
      }

      const savedExternalIds = await Promise.all(newExternalIDs.filter(id => id.source && id.extId).map(async (id) => {
        const externalID = API.Location.addExternalID({
          id: location._id,
          source: id.source,
          extid: id.extId,
        });
        return Promise.resolve(externalID);
      }));

      savedExternalIds.forEach((extid) => {
        if (extid[0].type === 'LOCATION.EXTERNAL_ID_REGISTERED') {
          queueToast({
            type: 'success',
            title: 'External ID Saved!',
            allowClose: true,
            delay: 500,
          });
        }
      });

      setNewExternalIDs([]);
    } catch (err) {
      console.error('Error Saving Location', err);

      queueToast({
        type: 'error',
        title: 'Error Saving External IDs',
        timeout: 10,
        allowClose: true,
      });
    }

    try {
      const updateResponse = await API.Location.update(data);
      const success = get(updateResponse, '[0].type') === 'LOCATION.UPDATED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Saved Location!',
          allowClose: true,
          delay: 500,
        });

        const locationObj = await API.Location.getById(location._id);
        onUpdate(locationObj);
        updateCurrentLocation(locationObj);
        setHasChanges(false);
      } else {
        queueToast({
          type: 'error',
          title: 'Error Saving Location',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error Saving Location', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Location',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  /**
   * Open the sidebar to duplicate the location
   */
  useEffect(() => {
    if (qs.type === 'new') {
      onOpenSidebar();
    }
  }, [qs.type]);

  return (
    <div
      className={classNames('ProfileInformation', className)}
      style={{
        flexGrow: 1,
        ...style,
      }}
    >
      <div
        style={{
          height: 'calc(100% - 68px)',
          padding: 24,
          paddingBottom: 0,
        }}
      >
        {user.can('location.view_third_party_data') || user.can('location.view_external_ids') ? (
          <Grid spacing={2} container>
            <Grid xs={12} lg={4} item>
              {getInformation()}
              {getSettings()}
              {getAddress()}
            </Grid>

            <Grid xs={12} lg={8} item>
              {user.can('location.view_external_ids') && (
                <ProfileContent title="External Source ID's">
                  <ProfileExternalIds
                    externalIds={useExternalIds}
                    locationId={location._id}
                    setHasChanges={setHasChanges}
                    newExternalIDs={newExternalIDs}
                    setNewExternalIDs={setNewExternalIDs}
                    onUpdate={onUpdate}
                    updateCurrentLocation={updateCurrentLocation}
                  />
                </ProfileContent>
              )}

              {user.can('location.view_third_party_data') && (
                <>
                  <ProfileContent title="Third Party Data">
                    <ThirdPartyData
                      thirdPartyData={get(location, 'thirdPartyData', {})}
                      onChangeThirdPartyProvider={onChangeThirdPartyProvider}
                      onUpdate={onChange}
                    />
                  </ProfileContent>

                  <ProfileContent title="External ID API Info">
                    <ExternalInfo
                      selectedThirdPartyProvider={selectedThirdPartyProvider}
                      activeThirdPartyItem={getActiveThirdPartyItem()}
                    />
                  </ProfileContent>
                </>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid spacing={2} container>
            <Grid xs={12} lg={4} item>
              {getInformation()}
            </Grid>

            <Grid xs={12} lg={4} item>
              {getSettings()}
            </Grid>

            <Grid xs={12} lg={4} item>
              {getAddress()}
            </Grid>
          </Grid>
        )}
      </div>

      <ProfileFooter>
        {user.can('location.modify') && isActive && (
          <VibeButtonNew
            text="Save Changes"
            color={color.violetVibe}
            disabled={!hasChanges || (location.adProgramEnabled && !location.adNetworkEnabled)}
            onClick={onSave}
          />
        )}

        {user.can('location.create') && isActive && (
          <VibeIcon
            icon={viCopy}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.violetVibe,
              style: {
                marginLeft: 8,
              },
            }}
            color={color.violetVibe}
            size={20}
            tooltip="Duplicate"
            link={NavigationHelper.updateParams({
              type: 'new',
            })}
          />
        )}

        {user.can('location.delete') && (
          <VibeIcon
            icon={viArchive}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: isActive
                ? color.fireBrick
                : color.aquaForest,
              style: {
                marginLeft: 8,
              },
            }}
            tooltip={isActive
              ? 'Archive'
              : 'Unarchive'}
            color={isActive
              ? color.fireBrick
              : color.aquaForest}
            size={20}
            onClick={isActive
              ? onClickArchive
              : onClickUnarchive}
          />
        )}
      </ProfileFooter>

      <VibeModal
        show={showConfirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${location.name}?`}
        confirmProps={{
          text: 'Archive',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCancelArchive}
      />
      <VibeModal
        className="DuplicateIDModal"
        type="custom"
        show={showDuplicateModal}
        text={(
          <div>
            <VibeIcon
              className="alert-circle-icon"
              icon={viAlertCircle}
              color={color.alertYellow}
              size={20}
            />
            <div className="external-delete-title">
              ID is already in use.
            </div>
            <div className="external-delete-text">
              There are one or more IDs already in use.
            </div>
            <div className="external-duplicates-list">
              {duplicateArray.map((dupe) => (
                <div className="external-duplicates-row">
                  <div className="external-duplicates-source">{dupe.source}</div>
                  <div className="external-duplicates-extid">{dupe.extId}</div>
                </div>
              ))}
            </div>
            <VibeButtonNew
              className="duplicate-cancel-btn"
              text="Cancel"
              variant="outlined"
              color={color.gray700}
              borderColor={color.gray300}
              height={44}
              tooltip="Close Duplicates Modal"
              tooltipProps={{
                placement: 'top',
              }}
              onClick={closeDuplicateModal}
            />
          </div>
        )}
      />
    </div>
  );
}

ProfileInformation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

ProfileInformation.defaultProps = {
  className: '',
  style: {},
};

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
  setPanel: GlobalActions.setPanel,
  updateCurrentLocation: LocationActions.updateCurrentLocation,
};

export default connect(null, mapDispatchToProps)(ProfileInformation);
