// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaselineDetails .row {
  margin-bottom: 8px;
}
.BaselineDetails .row.extra-space {
  margin-top: 8px;
}
.BaselineDetails .details-header {
  display: flex;
  align-items: center;
}
.BaselineDetails .details-title {
  font-size: 14px;
  font-weight: 900;
}
.BaselineDetails .panel-footer .btn-save,
.BaselineDetails .panel-footer .btn-edit {
  margin-right: 12px;
}
.BaselineDetails .panel-footer .toolbar-buttons {
  display: flex;
  align-items: center;
}
.BaselineDetails .panel-footer .toolbar-buttons .toolbar-button {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Programs/Baselines/Sidebar/BaselineDetails.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAKE;EACE,aAAA;EACA,mBAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;AAJJ;AASI;;EAEE,kBAAA;AAPN;AAUI;EACE,aAAA;EACA,mBAAA;AARN;AAUM;EACE,kBAAA;AARR","sourcesContent":[".BaselineDetails {\n\n  .row {\n    margin-bottom: 8px;\n\n    &.extra-space {\n      margin-top: 8px;\n    }\n  }\n\n  .details-header {\n    display: flex;\n    align-items: center;\n  }\n\n  .details-title {\n    font-size: 14px;\n    font-weight: 900;\n  }\n\n  .panel-footer {\n\n    .btn-save,\n    .btn-edit {\n      margin-right: 12px;\n    }\n\n    .toolbar-buttons {\n      display: flex;\n      align-items: center;\n\n      .toolbar-button {\n        margin-right: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
