/**
 * Get the cookie
 *
 * @param {string} key
 * @returns string
 */
export function getCookie(key) {
  const name = `__Host-${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

/**
 * Set the cookie
 *
 * @param {string} key
 * @param {string} value
 * @param {string} expireDate
 * @returns boolean
 */
export function setCookie({
  key,
  value,
  expireDate,
}) {
  if (!key || !value || !expireDate) {
    return false;
  }

  const isCloudFront = key.indexOf('CloudFront-') >= 0;

  if (!isCloudFront) {
    // Regular App Cookie
    document.cookie = `__Host-${key}=${value}; secure; SameSite=Strict; expires=${expireDate}; path=/`;
  } else {
    // CloudFront Cookie
    document.cookie = `${key}=${value}; secure; SameSite=Strict; domain=.vibenomics.com; expires=${expireDate}; path=/`;
  }

  return true;
}

/**
 * Remove a cookie
 *
 * @param {string} key
 * @returns boolean
 */
export function removeCookie(key) {
  if (!key) {
    return false;
  }

  const expireDate = 'Thu, 01 Jan 1970 00:00:00 UTC';
  const isCloudFront = key.indexOf('CloudFront-') >= 0;

  if (!isCloudFront) {
    // Regular App Cookie
    document.cookie = `__Host-${key}=; secure; SameSite=Strict; expires=${expireDate}; path=/`;
  } else {
    // CloudFront Cookie
    document.cookie = `${key}=; secure; SameSite=Strict; domain=.vibenomics.com; expires=${expireDate}; path=/`;
  }

  return true;
}

export default {
  getCookie,
  setCookie,
  removeCookie,
};
