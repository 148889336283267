import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
  VibeIcon,
  viLock,
  viUnlock,
  color,
} from 'vibeguide';
import statesList from '../../../utils/states';

class Address extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  onToggleLock = () => {
    const {
      geoCoordinatesLocked,
      onUpdate,
    } = this.props;

    onUpdate({
      geoCoordinatesLocked: !geoCoordinatesLocked,
    });
  };

  render() {
    const {
      user,
      address1,
      address2,
      country,
      city,
      locationState,
      postalCode,
      geoCoordinatesLocked,
      lat,
      long,
      disableInput,
      isNew,
    } = this.props;

    const canAdminEdit = user.sysAdmin && !disableInput;
    let label;
    if (['AT', 'AU', 'BR', 'GE', 'IN', 'MY', 'MX', 'FM', 'MM', 'NZ', 'NG', 'PW', 'SS'].includes(country)) {
      label = 'State';
    } else {
      label = 'Province';
    }
    return (
      <div className="Address location">
        <div className="details-title">
          Address
        </div>

        <div className="data-row">
          <div
            className="field-container"
            style={{
              width: 268,
              minWidth: 268,
            }}
          >
            <Field
              type="text"
              label="Address 1"
              name="address1"
              placeholder="Street Address"
              value={address1}
              tabIndex={4}
              disabled={disableInput}
              required
              onChange={this.onChange}
            />
          </div>

          <div
            className="field-container"
            style={{
              flexGrow: 1,
            }}
          >
            <Field
              type="text"
              label="Address 2"
              name="address2"
              placeholder="Optional"
              value={address2}
              tabIndex={5}
              disabled={disableInput}
              onChange={this.onChange}
            />
          </div>
        </div>

        <div className="data-row">
          <div
            className="field-container"
            style={{
              width: 268,
              minWidth: 268,
            }}
          >
            <Field
              type="country"
              label="Country"
              name="country"
              value={country}
              tabIndex={6}
              disabled={disableInput}
              required
              onChange={this.onChange}
            />
          </div>

          <div
            className="field-container"
            style={{
              flexGrow: 1,
            }}
          >
            <Field
              type="text"
              label="City"
              name="city"
              placeholder="City"
              value={city}
              tabIndex={6}
              disabled={disableInput}
              required
              onChange={this.onChange}
            />
          </div>
        </div>

        <div className="data-row">
          <div
            className="field-container"
            style={{
              width: 128,
            }}
          >
            {country !== 'US' ? (
              <Field
                type="text"
                label={label}
                name="state"
                value={locationState}
                tabIndex={8}
                disabled={!canAdminEdit}
                onChange={this.onChange}
                required
              />
            ) : (
              <Field
                type="select"
                label="State"
                name="state"
                value={locationState}
                tabIndex={8}
                options={statesList}
                disabled={!canAdminEdit}
                onChange={this.onChange}
                required
              />
            )}
          </div>

          <div
            className="field-container"
            style={{
              width: 128,
            }}
          >
            <Field
              type="text"
              label="Postal Code"
              name="postalCode"
              placeholder="00000"
              value={postalCode}
              tabIndex={8}
              disabled={disableInput}
              required
              onChange={this.onChange}
            />
          </div>
        </div>

        {!isNew && user.sysAdmin ? (
          <div className="data-row">
            <div
              className="field-container"
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                width: 32,
                margin: 0,
              }}
            >
              <VibeIcon
                type="button"
                icon={geoCoordinatesLocked
                  ? viLock
                  : viUnlock}
                color={geoCoordinatesLocked
                  ? color.fireBrick
                  : color.manatee}
                size={20}
                tooltip={geoCoordinatesLocked
                  ? 'Unlock Coordinates'
                  : 'Lock Coordinates'}
                buttonProps={{
                  size: 32,
                  color: geoCoordinatesLocked
                    ? color.fireBrick16
                    : color.lightGray,
                  borderColor: geoCoordinatesLocked
                    ? color.fireBrick
                    : color.whiteSmoke,
                }}
                disabled={!canAdminEdit}
                onClick={this.onToggleLock}
              />
            </div>

            <div
              className="field-container"
              style={{
                width: 268,
                minWidth: 268,
              }}
            >
              <Field
                type="text"
                label="Latitude"
                name="lat"
                placeholder="00.000000"
                value={lat}
                tabIndex={9}
                disabled={!canAdminEdit || geoCoordinatesLocked}
                onChange={this.onChange}
              />
            </div>

            <div
              className="field-container"
              style={{
                flexGrow: 1,
              }}
            >
              <Field
                type="text"
                label="Longitude"
                name="long"
                placeholder="00.000000"
                value={long}
                tabIndex={10}
                disabled={!canAdminEdit || geoCoordinatesLocked}
                onChange={this.onChange}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

Address.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  locationState: PropTypes.string,
  postalCode: PropTypes.string,
  geoCoordinatesLocked: PropTypes.bool,
  lat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  long: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disableInput: PropTypes.bool,
  isNew: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Address.defaultProps = {
  address1: '',
  address2: '',
  city: '',
  locationState: '',
  postalCode: '',
  geoCoordinatesLocked: false,
  lat: 0,
  long: 0,
  disableInput: false,
  isNew: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Address);
