import React from 'react';
import { connect } from 'react-redux';
import {
  get,
} from 'lodash';
import {
  Submenu,
  color,
  withRouter,
} from 'vibeguide';
import Messages from './Content/Messages';
import MessageBlocks from './Content/MessageBlocks';
import Playlists from './Content/Playlists';
import Mixes from './Content/Mixes';
import BlockedSongs from './Content/BlockedSongs';
import Baselines from './Content/Baselines';
import Events from './Content/Events';
import Users from './Content/Users';
import './Content.scss';

function renderContent(tab) {
  if (tab === 'messages') {
    return <Messages />;
  }

  if (tab === 'blocks') {
    return (
      <MessageBlocks />
    );
  }

  if (tab === 'playlists') {
    return <Playlists />;
  }

  if (tab === 'stations') {
    return (
      <Mixes />
    );
  }

  if (tab === 'blocked') {
    return <BlockedSongs />;
  }

  if (tab === 'baselines') {
    return (
      <Baselines />
    );
  }

  if (tab === 'events') {
    return (
      <Events />
    );
  }

  if (tab === 'users') {
    return <Users />;
  }

  return (
    <div>
      Unknown Content Type
    </div>
  );
}

function Content({
  currentLocation,
  params,
  user,
}) {
  const locationId = get(currentLocation, '_id', null);

  /**
   * Get the initial tab to load based on what permissions they have
   */
  const getInitialTab = () => {
    if (user.can('message.view') || user.can('advertisement.view')) {
      return 'messages';
    }

    if (user.can('messagelist.view')) {
      return 'blocks';
    }

    if (user.can('playlist.view')) {
      return 'playlists';
    }

    if (user.can('mix.view')) {
      return 'stations';
    }

    if (user.can('music.view')) {
      return 'blocked';
    }

    if (user.can('baseline.view')) {
      return 'baselines';
    }

    if (user.can('event.view')) {
      return 'events';
    }

    if (user.can('user.view')) {
      return 'users';
    }

    return '';
  };

  const activeTab = get(params, 'tab', getInitialTab());

  if (!currentLocation) {
    return null;
  }

  return (
    <div className="Content">
      <Submenu
        rootUrl={`/location/${locationId}/assignments`}
        matchParameter="tab"
        style={{
          position: 'sticky',
          top: 0,
          padding: '0 24px',
          backgroundColor: color.lightGray,
          // zIndex: 2,
        }}
        items={[
          {
            name: 'Messages',
            show: true,
            enabled: user.can('message.view') || user.can('advertisement.view'),
          },
          {
            name: 'Blocks',
            label: 'Message Blocks',
            show: true,
            enabled: user.can('messagelist.view'),
          },
          {
            name: 'Playlists',
            show: true,
            enabled: user.can('playlist.view'),
          },
          {
            name: 'Stations',
            show: true,
            enabled: user.can('mix.view'),
          },
          {
            name: 'Blocked',
            label: 'Blocked Songs',
            show: true,
            enabled: user.can('music.view'),
          },
          {
            name: 'Baselines',
            show: true,
            enabled: user.can('baseline.view'),
          },
          {
            name: 'Events',
            show: true,
            enabled: user.can('event.view'),
          },
          {
            name: 'Users',
            show: true,
            enabled: user.can('user.view'),
          },
        ]}
      />

      {renderContent(activeTab)}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default withRouter(connect(mapStateToProps)(Content));
