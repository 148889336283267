import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  get,
  size,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  LocationHelper,
  NavigationHelper,
  TimeUtil,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  VibeTooltip,
  VibeButton,
  VibeModal,
  VibeIcon,
  viClose,
  viCopy,
  viEdit,
  viStation,
  viArchive,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import BaselineLocations from './BaselineLocations';
import BaselineSchedule from './BaselineSchedule';
import Information from './Content/Information';
import Snapshot from './Content/Snapshot';
import './BaselineDetails.scss';

class BaselineDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.updateItems = {};

    this.state = {
      confirmArchive: false,
      activateLocations: [],
    };
  }

  /**
   * When a day is clicked from the snapshot
   */
  onClickSnapshotDay = (day) => {
    const {
      user,
      baseline,
    } = this.props;

    const disableEditContent = get(baseline, 'locations.length', 0) <= 0;

    if (!disableEditContent && user.can('baseline.modify')) {
      // open the edit content screen to a specific day
      this.onClickEditContent(day);
    }
  };

  /**
   * When the extra panel is closed
   */
  onCloseExtraPanel = () => {
    const {
      baseline,
      onUpdate,
      onCloseExtraPanel,
    } = this.props;

    if (size(this.updateItems) > 0) {
      onUpdate({
        data: {
          ...baseline.data,
          ...this.updateItems,
        },
        days: [
          this.getItemsForDay('Sun'),
          this.getItemsForDay('Mon'),
          this.getItemsForDay('Tue'),
          this.getItemsForDay('Wed'),
          this.getItemsForDay('Thu'),
          this.getItemsForDay('Fri'),
          this.getItemsForDay('Sat'),
        ],
      });

      this.updateItems = {};
    }

    onCloseExtraPanel();
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      baseline: {
        _id,
        locations,
      },
      onClose,
    } = this.props;

    await API.Baseline.reactivate({
      _id,
      locations,
    });
    onClose();

    document.dispatchEvent(new Event('onSaveBaseline'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      baseline: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Baseline.deactivate(_id);

    this.onCloseArchive();
    onClose();

    document.dispatchEvent(new Event('onSaveBaseline'));
  };

  /**
   * When locations assigned is clicked
   */
  onClickLocations = () => {
    const {
      user,
      baseline: {
        _id,
        companyId,
        locations,
        locationsData,
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('baseline.assign_locations')
      && ((_id && user.can('baseline.modify'))
        || (!_id && user.can('baseline.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <BaselineLocations
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When edit content is clicked
   */
  onClickEditContent = (day) => {
    const {
      baseline,
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <BaselineSchedule
            day={typeof day === 'string'
              ? day
              : null}
            baseline={baseline}
            onUpdateItems={this.onUpdateItems}
            onClose={this.onCloseExtraPanel}
          />
        ),
      },
    });
  };

  /**
   * When activate baseline is clicked
   */
  onClickActivate = () => {
    const {
      user,
      baseline: {
        companyId,
        locations,
      },
      setPanel,
    } = this.props;

    // reset the baseline activation locations
    this.setState({
      activateLocations: [],
    });

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('location.schedule');

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <BaselineLocations
            companyId={companyId}
            scopeLimit={locations}
            customToolbarAssigned={(
              <VibeButton
                text="Confirm Activation"
                btnColor="purple"
                textColor="white"
                style={{
                  marginRight: 12,
                }}
                onClick={this.onConfirmActivation}
              />
            )}
            allowChanges={allowChanges}
            onUpdate={this.onChangeActivateLocations}
          />
        ),
      },
    });
  };

  /**
   * When the locations are changed from activate panel
   */
  onChangeActivateLocations = ({
    locations,
    // locationsData,
  }) => {
    this.setState({
      activateLocations: locations,
    });
  };

  /**
   * When confirming activating a baseline to specific locations
   */
  onConfirmActivation = async () => {
    const {
      baseline: {
        _id,
      },
      setModal,
      queueToast,
    } = this.props;

    const {
      activateLocations,
    } = this.state;

    if (activateLocations.length <= 0) {
      setModal({
        type: 'error',
        message: 'No locations selected to activate',
      });

      return;
    }

    queueToast({
      type: 'info',
      title: 'Activating...',
      allowClose: true,
    });

    const currDate = moment().add(1, 'minute');

    try {
      const response = await API.Location.updateSchedule({
        action: 'add',
        baselines: [{
          _id,
          startDate: currDate.format('YYYY-MM-DD'),
          startTime: currDate.format('HH:mm'),
          originTimeZone: TimeUtil.resolveLocalTimeZone(),
        }],
        locations: activateLocations,
      });

      const scheduleUpdated = response.filter(item => item.type === 'LOCATION.SCHEDULE_UPDATED');

      if (response.length === scheduleUpdated.length) {
        // all schedules for the selected locations were updated
        queueToast({
          type: 'success',
          title: 'Baseline Activated!',
          allowClose: true,
        });

        this.onCloseExtraPanel();
      } else {
        // one or more schedules were not updated
        queueToast({
          type: 'error',
          title: 'Error Activating',
          allowClose: true,
        });
      }
    } catch (err) {
      queueToast({
        type: 'error',
        title: 'Error Activating',
        allowClose: true,
      });
    }
  };

  /**
   * When the baseline items are updated
   */
  onUpdateItems = (items) => {
    this.updateItems = {
      ...this.updateItems,
      ...items,
    };
  };

  /**
   * When a user is being saved
   */
  onSave = async () => {
    const {
      isNew,
      baseline: {
        _id,
        name,
        description,
        companyId,
        locations,
        tags = [],
      },
      history,
      queueToast,
    } = this.props;

    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

    const data = {
      name,
      description,
      companyId,
      locations,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
      days: [
        this.getItemsForDay('Sun'),
        this.getItemsForDay('Mon'),
        this.getItemsForDay('Tue'),
        this.getItemsForDay('Wed'),
        this.getItemsForDay('Thu'),
        this.getItemsForDay('Fri'),
        this.getItemsForDay('Sat'),
      ],
    };

    if (_id && !isNew) {
      // editing a baseline
      data._id = _id;
    }

    document.dispatchEvent(new Event('onSaveBaselineStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = _id && !isNew
        ? await API.Baseline.modify(data)
        : await API.Baseline.create(data);

      const baselineId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = _id && !isNew
        ? responseType === 'BASELINE.UPDATED'
        : responseType === 'BASELINE.CREATED';

      if (success) {
        // Successfully saved the baseline
        const saveMessage = _id && !isNew
          ? 'Baseline Updated!'
          : 'Baseline Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      // Refresh the user details and redirect to their edit screen
      if (success && isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          baselineId,
          type: null,
        });

        history(redirectUrl);
      }

      document.dispatchEvent(new Event('onSaveBaseline'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveBaselineError'));
    }
  };

  getItemsForDay = (day) => {
    const {
      baseline,
    } = this.props;

    // merge baseline items with items that are updated
    const baselineData = {
      ...baseline.data,
      ...this.updateItems,
    };

    const playlists = get(baselineData, 'playlists', []);
    const mixes = get(baselineData, 'mixes', []);
    const messageBlocks = get(baselineData, 'messageBlocks', []);
    const interruptions = get(baselineData, 'interruptions', []);

    const dayPlaylists = playlists.filter(playlist => playlist.day === day);
    const dayMixes = mixes.filter(mix => mix.day === day);
    const dayMessageBlocks = messageBlocks.filter(block => block.day === day);
    const dayInterruptions = interruptions.filter(interruption => interruption.day === day);
    const musicParts = [];
    const messageParts = [];
    const interrupts = [];

    dayPlaylists.forEach((playlist) => {
      const startHour = parseInt(playlist.startTime.split(':')[0], 10);
      const startMinute = playlist.startTime.split(':')[1];
      const stopHour = parseInt(playlist.stopTime.split(':')[0], 10);
      const stopMinute = playlist.stopTime.split(':')[1];
      const startTime = `${startHour < 10 ? `0${startHour}` : startHour}:${startMinute}`;
      const endTime = `${stopHour < 10 ? `0${stopHour}` : stopHour}:${stopMinute}`;

      musicParts.push({
        musicId: playlist.playlist._id,
        type: 'playlist',
        startTime,
        endTime,
      });
    });

    dayMixes.forEach((mix) => {
      const startHour = parseInt(mix.startTime.split(':')[0], 10);
      const startMinute = mix.startTime.split(':')[1];
      const stopHour = parseInt(mix.stopTime.split(':')[0], 10);
      const stopMinute = mix.stopTime.split(':')[1];
      const startTime = `${startHour < 10 ? `0${startHour}` : startHour}:${startMinute}`;
      const endTime = `${stopHour < 10 ? `0${stopHour}` : stopHour}:${stopMinute}`;

      musicParts.push({
        musicId: mix.mix._id,
        type: 'mix',
        startTime,
        endTime,
      });
    });

    dayMessageBlocks.forEach((block) => {
      const startHour = parseInt(block.startTime.split(':')[0], 10);
      const startMinute = block.startTime.split(':')[1];
      const stopHour = parseInt(block.stopTime.split(':')[0], 10);
      const stopMinute = block.stopTime.split(':')[1];
      const startTime = `${startHour < 10 ? `0${startHour}` : startHour}:${startMinute}`;
      const endTime = `${stopHour < 10 ? `0${stopHour}` : stopHour}:${stopMinute}`;

      messageParts.push({
        messageListId: block.messageBlock._id,
        startTime,
        endTime,
      });
    });

    dayInterruptions.forEach((interrupt) => {
      const startHour = parseInt(interrupt.startTime.split(':')[0], 10);
      const startMinute = interrupt.startTime.split(':')[1];
      // interrupts do not have a stop time
      const stopHour = parseInt(interrupt.startTime.split(':')[0], 10);
      const stopMinute = interrupt.startTime.split(':')[1];
      const startTime = `${startHour < 10 ? `0${startHour}` : startHour}:${startMinute}`;
      const endTime = `${stopHour < 10 ? `0${stopHour}` : stopHour}:${stopMinute}`;

      interrupts.push({
        messageId: interrupt.message._id,
        startTime,
        endTime,
      });
    });

    if (musicParts.length <= 0 && messageParts.length <= 0 && interrupts.length <= 0) {
      return {};
    }

    return {
      musicParts,
      messageParts,
      interrupts,
    };
  };

  render() {
    const {
      user,
      baseline,
      onClose,
      onUpdate,
      isNew,
    } = this.props;

    const {
      confirmArchive,
    } = this.state;

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId: baseline.companyId,
      locations: baseline.locations,
    });

    const locationCount = LocationHelper.getLocationCount(get(baseline, 'locationsData.companies', []), isACAF);

    const disableSave = !baseline.name
      || get(baseline, 'locations.length', 0) <= 0;

    const exceedsLocations = user.exceedsLocations(baseline.locations);
    const disableEditContent = get(baseline, 'locations.length', 0) <= 0;
    const disableCompany = !isNew;
    const disableInput = !user.hasAccessToCompany(baseline.companyId)
      || !exceedsLocations
      || (baseline._id && !user.can('baseline.modify'))
      || (!baseline._id && !user.can('baseline.create'));

    const showDuplicate = user.can('baseline.create')
      && exceedsLocations;

    const showArchive = user.can('baseline.delete')
      && user.hasAccessToCompany(baseline.companyId)
      && exceedsLocations;
    const isArchived = !baseline.active;

    return (
      <SidePanelContainer className="BaselineDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          <div className="details-header">
            {baseline._id ? (
              <div className="flex-horizontal">
                <div className="title">
                  <VibeTooltip title={baseline.name}>
                    <span>
                      {baseline.name}
                    </span>
                  </VibeTooltip>
                </div>
              </div>
            ) : (
              <div className="title">
                New Baseline
              </div>
            )}
          </div>
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            isNew={isNew}
            name={baseline.name}
            description={baseline.description}
            companyId={baseline.companyId}
            companyName={baseline.companyName}
            tags={baseline.tags}
            disableInput={disableInput}
            disableCompany={disableCompany}
            onUpdate={onUpdate}
          />

          <Assignments
            items={[{
              label: 'Locations Assigned',
              count: locationCount.display.element,
              tooltip: locationCount.display.tooltip,
              required: true,
              disabled: disableInput,
              warning: !exceedsLocations
                ? 'You do not have access to all the locations assigned to this object'
                : null,
              onClick: !disableInput
                ? this.onClickLocations
                : null,
            }]}
          />

          <Snapshot
            playlists={get(baseline, 'data.playlists', [])}
            mixes={get(baseline, 'data.mixes', [])}
            messageBlocks={get(baseline, 'data.messageBlocks', [])}
            interruptions={get(baseline, 'data.interruptions', [])}
            disabled={disableInput}
            onClickDay={this.onClickSnapshotDay}
          />
        </SidePanelContent>

        <SidePanelFooter className="panel-footer">
          <VibeButton
            className="btn-save"
            text="Save Changes"
            btnColor="purple"
            textColor="white"
            loadingEvent="onSaveBaseline"
            disabled={disableSave || disableInput}
            onClick={this.onSave}
          />

          <div className="toolbar-buttons">
            <div className="toolbar-button">
              <VibeIcon
                icon={viEdit}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.violetVibe,
                }}
                color={color.violetVibe}
                size={16}
                tooltip="Edit Content"
                disabled={disableEditContent || disableInput}
                onClick={this.onClickEditContent}
              />
            </div>

            {!isNew && showDuplicate ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viCopy}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.violetVibe,
                  }}
                  color={color.violetVibe}
                  size={16}
                  tooltip="Duplicate"
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                />
              </div>
            ) : null}

            {!isNew && user.can('location.schedule') ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viStation}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.aquaForest,
                  }}
                  color={color.aquaForest}
                  size={16}
                  tooltip="Activate"
                  onClick={this.onClickActivate}
                />
              </div>
            ) : null}

            {!isNew && showArchive && !isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.fireBrick,
                  }}
                  tooltip="Archive"
                  color={color.fireBrick}
                  size={20}
                  onClick={this.onClickArchive}
                />
              </div>
            ) : null}

            {!isNew && showArchive && isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.aquaForest,
                  }}
                  tooltip={locationCount.total > 0
                    ? 'Unarchive'
                    : 'Must have at least 1 active location to unarchive'}
                  color={color.aquaForest}
                  size={20}
                  disabled={locationCount.total <= 0}
                  onClick={this.onClickUnarchive}
                />
              </div>
            ) : null}
          </div>
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${baseline.name}?`}
          confirmProps={{
            text: 'Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />
      </SidePanelContainer>
    );
  }
}

BaselineDetails.propTypes = {
  isNew: PropTypes.bool,
  baseline: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onCloseExtraPanel: PropTypes.func,
  onUpdate: PropTypes.func,
};

BaselineDetails.defaultProps = {
  isNew: false,
  baseline: {},
  onClose: () => {},
  onCloseExtraPanel: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  setModal: GlobalActions.setModal,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaselineDetails));
