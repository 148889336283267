import {
  styled,
  InputBase,
} from '@mui/material';
import color from '../../../sass/color.scss';

const CustomSelect = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    height: 32,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    border: `1px solid ${color.whiteSmoke}`,
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
  },
}));

export default CustomSelect;
