import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  NewMixActions,
  GlobalActions,
  ExpandedLocationsHelper,
  LoadingContent,
} from 'vibeguide';
import MixDetails from './MixDetails';
import './MixSidebar.scss';

class MixSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mix: {},
    };
  }

  componentDidMount() {
    const {
      stationId,
    } = this.props;

    if (stationId) {
      this.getMix();
    } else {
      this.setCompany();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      stationId,
      isNew,
    } = this.props;

    const {
      stationId: prevStationId,
    } = prevProps;

    if (stationId && !isNew && stationId !== prevStationId) {
      this.getMix();
    }
  }

  /**
   * Update the mix
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        mix: {
          ...state.mix,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtra = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  getMix = async () => {
    const {
      stationId,
      resetNewMix,
      addMixPlaylists,
    } = this.props;

    resetNewMix();

    const mix = await API.Mix.getById(stationId);

    const expandedLocations = await API.Mix.getExpandedLocations(stationId);

    // Set the location data
    mix.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    this.setState({
      mix,
    });

    if (mix.playlists.length > 0) {
      addMixPlaylists(mix.playlists);
    }
  };

  setCompany = () => {
    const {
      user,
    } = this.props;

    const {
      mix,
    } = this.state;

    if (!mix.companyId && !user.sysAdmin) {
      const companyData = {
        companyId: user.companyId,
        companyName: user.companyName,
      };

      this.setState((state) => {
        return {
          mix: {
            ...state.mix,
            ...companyData,
          },
        };
      });
    }
  };

  render() {
    const {
      stationId,
      type,
      isNew,
      isDuplicate,
    } = this.props;

    const {
      mix,
    } = this.state;

    return (
      <div className="MixSidebar">
        <MixDetails
          type={type}
          mix={mix}
          isNew={isNew}
          isDuplicate={isDuplicate}
          onClose={this.onClose}
          onCloseExtra={this.onCloseExtra}
          onUpdate={this.onUpdate}
          onRefresh={this.getMix}
        />

        {stationId && !mix._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

MixSidebar.propTypes = {
  stationId: PropTypes.string,
  /** New Mix */
  isNew: PropTypes.bool,
  /** Duplicate Mix */
  isDuplicate: PropTypes.bool,
};

MixSidebar.defaultProps = {
  stationId: null,
  isNew: false,
  isDuplicate: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  addMixPlaylists: NewMixActions.addMixPlaylists,
  resetNewMix: NewMixActions.resetNewMix,
};

export default connect(mapStateToProps, mapDispatchToProps)(MixSidebar);
