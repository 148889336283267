import { createAction } from 'redux-actions';
import io from 'socket.io-client';
import {
  getCookie,
} from '../../utils/CookieUtil';
import {
  checkSession,
} from '../../utils/AuthUtil';

export const socketConnected = createAction('SOCKET_CONNECTED');
export const socketDisconnected = createAction('SOCKET_DISCONNECTED');

export function socketConnect(data) {
  return async (dispatch) => {
    const {
      url,
    } = data;

    const socket = io(url, {
      auth: {
        token: getCookie('app:user:idToken'),
      },
      transports: [
        'websocket',
        'polling',
      ],
    });

    socket.on('connect', () => {
      console.log('Socket Connected');
      dispatch(socketConnected(socket));
    });

    socket.on('connect_error', (err) => {
      console.error('Socket Connect Error', err);

      if (err.message === 'jwt expired') {
        // token has expired, fetch a new one
        checkSession();
      }

      dispatch(socketDisconnected());
    });

    socket.on('connect_timeout', () => {
      console.error('Socket Connect Timeout');
      dispatch(socketDisconnected());
    });

    socket.on('disconnect', () => {
      console.log('Socket Disconnected');

      // update the connection token
      socket.io.opts.auth.token = getCookie('app:user:idToken');
      dispatch(socketDisconnected());
    });

    socket.on('reconnect', () => {
      console.log('Socket Reconnected');
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.log('Socket Reconnect Attempt', attempt);
    });

    socket.on('reconnecting', () => {
      console.log('Socket Reconnecting...');
    });

    socket.on('reconnect_error', (err) => {
      console.error('Socket Reconnect Error', err);
    });

    socket.on('reconnect_failed', () => {
      console.error('Socket Reconnect Failed');
    });

    socket.on('VAPI_EVENT', (result) => {
      console.log('VAPI_EVENT', result);
    });
  };
}

export default {
  socketConnect,
  socketConnected,
  socketDisconnected,
};
