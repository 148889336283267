import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  ExpandedLocationsHelper,
  LoadingContent,
} from 'vibeguide';
import MessageBlockDetails from './MessageBlockDetails';
import './MessageBlockSidebar.scss';

class MessageBlockSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      messageBlock: {
        name: '',
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        allLocations: false,
        companyId: '',
        companyName: '',
        messages: [],
        listType: 'rotate',
        frequency: 1,
        groupSize: 1,
        startType: 'before',
        sequence: 'shuffled',
        tags: [],
      },
    };
  }

  componentDidMount() {
    const {
      messageBlockId,
      locationToAdd,
      companyToAdd,
      user,
    } = this.props;

    if (messageBlockId && messageBlockId !== 'new') {
      this.getMessageBlock();
    }

    if (!messageBlockId && !user.sysAdmin) {
      this.setMessageBlockCompany();
    }

    if (locationToAdd && companyToAdd) {
      this.getLocationDetails();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      messageBlockId,
      isNew,
      isDuplicate,
    } = this.props;

    const {
      messageBlock: {
        tags,
      },
    } = this.state;

    const {
      messageBlockId: prevMessageBlockId,
      isDuplicate: prevIsDuplicate,
    } = prevProps;

    if (messageBlockId && !isNew && messageBlockId !== prevMessageBlockId) {
      this.getMessageBlock();
    }

    if (isDuplicate && isDuplicate !== prevIsDuplicate) {
      const dupeTags = tags;
      dupeTags.forEach((tag) => {
        tag.status = 'add';
      });
      this.onUpdate({
        tags: dupeTags,
      });
    }
  }

  /**
   * Update the message block
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        messageBlock: {
          ...state.messageBlock,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtraPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Close the third panel on the sidebar
   */
  onCloseThirdPanel = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  setMessageBlockCompany = () => {
    const {
      user,
    } = this.props;

    this.setState({
      messageBlock: {
        companyName: user.companyName,
        companyId: user.companyId,
      },
    });
  };

  getMessageBlock = async () => {
    const {
      messageBlockId,
      user,
    } = this.props;

    const messageBlock = await API.MessageBlock.getById(messageBlockId);

    if (!messageBlock) {
      console.warn('Message Block ID Not Found', messageBlock);
      return;
    }

    const isACAF = user.isAllLocations({
      companyId: messageBlock.companyId,
      locations: messageBlock.locations,
    });

    // Only fetch expanded locations when all current and future is not selected
    const expandedLocations = isACAF
      ? []
      : await API.MessageBlock.getExpandedLocations(messageBlockId);

    // Set the location data
    messageBlock.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);
    messageBlock.acaf = isACAF;

    this.setState({
      messageBlock,
    });
  };

  getLocationDetails = async () => {
    const {
      locationToAdd,
      companyToAdd,
    } = this.props;

    if (locationToAdd.locationId) {
      const locationDetails = await API.Location.getById(locationToAdd.locationId);
      const locationsData = {
        companies: [{
          _id: companyToAdd.companyId,
          name: companyToAdd.companyName,
          allLocations: false,
        }],
        locations: [{
          _id: locationDetails._id,
          name: locationDetails.name,
          imageUrl: locationDetails.imageUrl,
          companyId: locationDetails.companyId,
          companyName: locationDetails.companyName,
          city: locationDetails.city,
          state: locationDetails.state,
        }],
      };
      this.setState({
        messageBlock: {
          locations: [locationToAdd],
          locationsData,
          companyId: companyToAdd.companyId,
          companyName: companyToAdd.companyName,
        },
      });
    }
  };

  render() {
    const {
      messageBlockId,
      isNew,
      isDuplicate,
      redirectTo,
    } = this.props;

    const {
      messageBlock,
    } = this.state;

    return (
      <div className="MessageBlockSidebar">
        <MessageBlockDetails
          messageBlock={messageBlock}
          isNew={isNew}
          isDuplicate={isDuplicate}
          redirectTo={redirectTo}
          onClose={this.onClose}
          onCloseExtraPanel={this.onCloseExtraPanel}
          onCloseThirdPanel={this.onCloseThirdPanel}
          onUpdate={this.onUpdate}
          onRefresh={this.getMessageBlock}
        />

        {messageBlockId && !messageBlock._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

MessageBlockSidebar.propTypes = {
  messageBlockId: PropTypes.string,
  /** New MessageBlock */
  isNew: PropTypes.bool,
  /** Duplicate MessageBlock */
  isDuplicate: PropTypes.bool,
  /** Add a default location to a new mb */
  locationToAdd: PropTypes.shape({
    locationId: PropTypes.string,
    companyId: PropTypes.string,
  }),
  /** Add a default company to a new mb */
  companyToAdd: PropTypes.shape({
    companyId: PropTypes.string,
    companyName: PropTypes.string,
  }),
  /** Link to redirect to on save or other sidebar close */
  redirectTo: PropTypes.string,
};

MessageBlockSidebar.defaultProps = {
  messageBlockId: null,
  isNew: false,
  isDuplicate: false,
  /** Add a default location to a new mb */
  locationToAdd: null,
  companyToAdd: null,
  redirectTo: '',
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlockSidebar);
