import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeToggle from '../VibeToggle/VibeToggle';
import color from '../../sass/color.scss';
import './ToggleField.scss';

class ToggleField extends PureComponent {
  onChange = () => {
    const {
      toggled,
      onChange,
    } = this.props;

    onChange(!toggled);
  };

  render() {
    const {
      className,
      label,
      helpText,
      helpTextStyle,
      toggled,
      disabled,
    } = this.props;

    return (
      <div className={classNames('ToggleField', className)}>
        <div className="toggle-content">
          <VibeToggle
            label={label}
            height={16}
            color={color.aquaForest}
            checked={toggled}
            disabled={disabled}
            onChange={this.onChange}
          />
        </div>

        {helpText ? (
          <div
            className="help-text"
            style={helpTextStyle}
          >
            {helpText}
          </div>
        ) : null}
      </div>
    );
  }
}

ToggleField.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Label for the field */
  label: PropTypes.string.isRequired,
  /** Help text for under the toggle */
  helpText: PropTypes.string,
  /** Help text style */
  helpTextStyle: PropTypes.object,
  /** Is the field toggled */
  toggled: PropTypes.bool.isRequired,
  /** Is the field disabled */
  disabled: PropTypes.bool,
  /** Callback to component with new time */
  onChange: PropTypes.func,
};

ToggleField.defaultProps = {
  className: '',
  helpText: null,
  helpTextStyle: {},
  disabled: false,
  onChange: () => {},
};

export default ToggleField;
