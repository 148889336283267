import React, { Component } from 'react';
import { VibeButton } from 'vibeguide';
import { Navigate } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import FormHelperText from '@mui/material/FormHelperText';
import './ForgotPasswordForm.scss';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccountFocus: false,
      redirect: false,
    };
  }

  onUsernameChange = (e) => {
    const {
      onUsernameChange,
    } = this.props;

    onUsernameChange(e);
  };

  onFocusAccount = () => {
    this.setState({
      isAccountFocus: true,
    });
  };

  onBlurAccount = () => {
    this.setState({
      isAccountFocus: false,
    });
  };

  sendActivationCode = (e) => {
    const {
      sendActivationCode,
    } = this.props;

    this.setState({
      redirect: true,
    });

    sendActivationCode(e);
  };

  render() {
    const {
      cancelForgotPassword,
      username,
      forgotPasswordError,
      forgotPasswordErrorMessage,
    } = this.props;

    const {
      isAccountFocus,
      redirect,
    } = this.state;

    if (redirect) {
      return (
        <Navigate
          to={{
            pathname: '/reset',
          }}
        />
      );
    }

    return (
      <div className="ForgotPasswordForm">
        <div className="form-title">
          Forgot Password?

          <div className="form-subtitle">
            To reset your password, confirm your e-mail below. We will send you a code to create a new password.
          </div>
        </div>

        <form>
          <div
            className={`input-container ${isAccountFocus ? 'active' : ''} ${forgotPasswordError ? 'has-error' : ''}`}
          >
            <input
              className="login-input"
              type="email"
              placeholder="Username"
              value={username}
              // defaultValue={username}
              onChange={this.onUsernameChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusAccount}
              onBlur={this.onBlurAccount}
              autoFocus
            />

            <AccountCircle className="login-icon account-icon" />

            {forgotPasswordError && (
              <FormHelperText className="login-error">
                {forgotPasswordErrorMessage}
              </FormHelperText>
            )}
          </div>

          <VibeButton
            className="login-btn"
            text="Send Activation Code"
            size="block"
            btnColor="purple"
            textColor="white"
            disabled={!username}
            onClick={this.sendActivationCode}
          />

          <VibeButton
            className="cancel-btn"
            text="Cancel"
            size="block"
            btnLink="/"
            btnColor="transparent"
            textColor="purple"
            onClick={cancelForgotPassword}
          />
        </form>
      </div>
    );
  }
}

export default ForgotPasswordForm;
