import { createAction } from 'redux-actions';
import { setLocal } from '../../utils/StorageUtil';

export const authenticateUser = createAction('AUTHENTICATE_USER');
export const updateUser = createAction('UPDATE_USER');
export const resetSuccess = createAction('RESET_SUCCESS');

// Function that determines whether the user is authenticated or not
export function doAuthenticateUser(authenticated, user) {
  return dispatch => {
    return new Promise(resolve => {
      if (!authenticated) {
        resolve(dispatch(authenticateUser({ authenticated })));
        return;
      }

      // Save user object to be fetched on bugsnag errors
      setLocal('app:user', user);
      resolve(dispatch(authenticateUser({ authenticated, user })));
    });
  };
}

// Function for displaying the modal for user successfully loggin in
export function doResetPasswordSuccess(reset) {
  return dispatch => {
    dispatch(resetSuccess(reset));
  };
}

export default {
  updateUser,
  doAuthenticateUser,
  doResetPasswordSuccess,
};
