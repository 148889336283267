import React from 'react';
import {
  get,
  find,
  forEach,
  reduce,
} from 'lodash';
import VibeIcon from '../components/VibeIcon/VibeIcon';
import viCompany from '../icons/viCompany';
import viLocationOn from '../icons/viLocationOn';
import color from '../sass/color.scss';

/**
 * Get the display for the location count based on the number of companies/locations assigned to the object
 */
function getLocationCountDisplay(numCompanies, numLocations, isACAF) {
  const itemDisplayStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    padding: 8,
    backgroundColor: color.manatee16,
    borderRadius: 4,
  };

  // only needed for objects not assigned to all current and future
  const element = !isACAF
    ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 24,
        }}
      >
        {numCompanies > 0 && (
          <div
            style={itemDisplayStyle}
          >
            <VibeIcon
              icon={viCompany}
              color={color.manatee}
              size={16}
              style={{
                marginRight: 4,
              }}
            />

            {numCompanies}
          </div>
        )}

        {numLocations > 0 && (
          <div
            style={itemDisplayStyle}
          >
            <VibeIcon
              icon={viLocationOn}
              color={color.manatee}
              size={16}
              style={{
                marginRight: 4,
              }}
            />

            {numLocations}
          </div>
        )}

        {numCompanies <= 0 && numLocations <= 0 && (
          <div
            style={itemDisplayStyle}
          >
            0
          </div>
        )}
      </div>
    )
    : 'All Current and Future';

  // only needed for objects not assigned to all current and future
  const tooltip = !isACAF
    ? (
      <div
        style={{
          lineHeight: 1.4,
        }}
      >
        {numCompanies > 0 && (
          <div>
            {numCompanies} {numCompanies === 1 ? 'Company' : 'Companies'} Assigned
          </div>
        )}

        {numLocations > 0 && (
          <div>
            {numLocations} {numLocations === 1 ? 'Location' : 'Locations'} Assigned
          </div>
        )}

        {numCompanies <= 0 && numLocations <= 0 && (
          <div>
            No Locations Assigned
          </div>
        )}
      </div>
    )
    : '';

  return {
    element,
    tooltip,
  };
}

/**
 * Get the total location count for the object
 */
export function getLocationCount(companies, isACAF) {
  // get companies assigned to ACAF
  const companiesACAF = companies.filter(company => company.locationCount === '*');
  // get companies with a specific location count
  const companiesWithLocations = companies.filter(company => get(company, 'locations', []).length > 0);

  const numCompanies = companiesACAF.length;
  const numLocations = reduce(companiesWithLocations, (total, company) => {
    return total + get(company, 'locations', []).length;
  }, 0);

  const display = getLocationCountDisplay(numCompanies, numLocations, isACAF);

  return {
    // if ACAF is selected, count as a location
    total: numCompanies + numLocations + (isACAF ? 1 : 0),
    numCompanies,
    numLocations,
    display,
  };
}

/**
 * Compare two sets of location specs
 */
export function compare(parentLocations, childLocations) {
  const response = {
    parent: {
      missing: [],
    },
    child: {
      missing: [],
    },
  };

  const parentAllLocations = find(parentLocations, { companyId: '*' }) !== undefined;
  const childAllLocations = find(childLocations, { companyId: '*' }) !== undefined;

  if (parentAllLocations || childAllLocations) {
    // Parent has all locations, all child locations are included
    return response;
  }

  parentLocations.forEach((location) => {
    const {
      companyId,
      locationId,
    } = location;

    // Parent location is included in the child location spec
    const childHasLocation = find(childLocations, { companyId, locationId }) !== undefined;

    if (!childHasLocation) {
      // Parent location is not included in the child location spec
      // Check if the child has access to all locations for this company
      const childHasAllLocationsForCompany = find(childLocations, { companyId, locationId: '*' }) !== undefined;

      if (!childHasAllLocationsForCompany) {
        // Child does not have access to all locations for the parent location company
        response.parent.missing.push(location);
      }
    }
  });

  childLocations.forEach((location) => {
    const {
      companyId,
      locationId,
    } = location;

    // Child location is included in the parent location spec
    const parentHasLocation = find(parentLocations, { companyId, locationId }) !== undefined;

    if (!parentHasLocation) {
      // Parent does not have this specific location
      // Check if the parent has access to all locations for that company
      const parentHasAllLocationsForCompany = find(parentLocations, { companyId, locationId: '*' }) !== undefined;

      if (!parentHasAllLocationsForCompany) {
        // Child location is not included in the parent location spec
        // Check if the child location is all locations for the company
        // and the parent location has at least one location for the company
        const childHasAllLocationsForCompany = locationId === '*' && find(parentLocations, { companyId }) !== undefined;

        if (!childHasAllLocationsForCompany) {
          // Child location does not have all locations for the company
          // child not included in the location spec
          response.child.missing.push(location);
        }
      }
    }
  });

  return response;
}

/**
 * Group a location spec into an object of company IDs with an array of location IDs
 */
export function group(locations = []) {
  const group = {};

  locations.forEach((location) => {
    const {
      companyId,
      locationId,
    } = location;

    if (!group[companyId]) {
      group[companyId] = [];
    }

    if (locationId) {
      group[companyId].push(locationId);
    }
  });

  return group;
}

/**
 * Convert a group of locations to an array used for all our location specs
 */
export function toSpec(group = {}) {
  const spec = [];

  forEach(group, (locations, companyId) => {
    forEach(locations, (locationId) => {
      spec.push({
        companyId,
        locationId,
      });
    });
  });

  return spec;
}

/**
 * Check to see if two sets of locations has any matches that overlap
 */
export function overlap(firstLocations, secondLocations) {
  let hasOverlap = false;

  const firstAllLocations = find(firstLocations, { companyId: '*' }) !== undefined;
  const secondAllLocations = find(secondLocations, { companyId: '*' }) !== undefined;

  if (firstAllLocations || secondAllLocations) {
    hasOverlap = true;
    return hasOverlap;
  }

  for (let i = 0; i < firstLocations.length; i++) {
    const {
      companyId,
      locationId,
    } = firstLocations[i];

    const secondHasLocation = locationId === '*'
      // location has access to the whole company, find any location with that company in the second locations
      ? find(secondLocations, { companyId }) !== undefined
      // does any second location set have the first location?
      : find(secondLocations, { companyId, locationId }) !== undefined;

    if (secondHasLocation) {
      hasOverlap = true;
      break;
    }

    // Check if the child has access to all locations for this company
    const secondHasAllLocationsForCompany = find(secondLocations, { companyId, locationId: '*' }) !== undefined;

    if (secondHasAllLocationsForCompany) {
      // Second locations set has access to all locations for the first location company
      hasOverlap = true;
      break;
    }
  }

  return hasOverlap;
}

export default {
  getLocationCount,
  compare,
  group,
  toSpec,
  overlap,
};
