import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClipboardJS from 'clipboard';
import VibeCheckbox from '../VibeCheckbox/VibeCheckbox';
import VibeIcon from '../VibeIcon/VibeIcon';
import viExact from '../../icons/viExact';
import viCopy from '../../icons/viCopy';
import color from '../../sass/color.scss';
import './TargetTag.scss';

class TargetTag extends PureComponent {
  constructor(props) {
    super(props);

    this.clipboardRef = React.createRef();
    this.defaultCopyTagText = 'Copy to Clipboard';
    // Timeout to reset the clipboard tooltip
    this.clipboardResetTimeout = null;

    this.state = {
      copyTagText: this.defaultCopyTagText,
    };
  }

  componentDidMount() {
    const {
      clipboardRef: {
        current: clipboardRef,
      },
    } = this;

    if (clipboardRef) {
      const clipboard = new ClipboardJS(clipboardRef);

      clipboard.on('success', (e) => {
        e.clearSelection();

        this.setState({
          copyTagText: 'Copied!',
        });

        this.clipboardResetTimeout = setTimeout(() => {
          this.setState({
            copyTagText: this.defaultCopyTagText,
          });
        }, 2000);
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.clipboardResetTimeout);
  }

  onClick = () => {
    const {
      tagId,
      qualifiedName,
      onClick,
    } = this.props;

    onClick({
      _id: tagId,
      qualifiedName,
    });
  };

  onChangeSelect = () => {
    const {
      tagId,
      onChangeSelect,
    } = this.props;

    onChangeSelect(tagId);
  };

  render() {
    const {
      className,
      qualifiedName = '',
      active,
      selected,
      allowSelect,
      allowCopy,
    } = this.props;

    const {
      copyTagText,
    } = this.state;

    return (
      <div className={classNames('TargetTag', className, { active })}>
        {allowSelect ? (
          <div className="select-tag">
            <VibeCheckbox
              size={16}
              color={color.aquaForest}
              checked={selected}
              onChange={this.onChangeSelect}
            />
          </div>
        ) : null}

        <div
          className="tag-name"
          onClick={this.onClick}
        >
          <VibeIcon
            className="tag-icon"
            icon={viExact}
            color={active ? color.violetVibe : color.manatee}
            size={16}
          />

          <div className="tag-text">
            {(qualifiedName || '').replace(/:/g, ' : ')}
          </div>
        </div>

        {allowCopy ? (
          <div
            ref={this.clipboardRef}
            className="copy-tag"
            data-clipboard-text={qualifiedName}
          >
            <VibeIcon
              className="copy-tag-icon"
              icon={viCopy}
              color={color.manatee}
              size={20}
              tooltip={copyTagText}
              tooltipProps={{
                placement: 'right',
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

TargetTag.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Tag ID */
  tagId: PropTypes.string,
  /** Tag Qualified Name */
  qualifiedName: PropTypes.string,
  /** Allow selection of target tags */
  allowSelect: PropTypes.bool,
  /** Tag is active */
  active: PropTypes.bool,
  /** Tag is selected */
  selected: PropTypes.bool,
  /** Allow Copy to clipboard of tag */
  allowCopy: PropTypes.bool,
  /** When the target tag is clicked */
  onClick: PropTypes.func,
  /** When the select box is changed */
  onChangeSelect: PropTypes.func,
};

TargetTag.defaultProps = {
  className: '',
  tagId: '',
  qualifiedName: '',
  allowSelect: false,
  active: false,
  selected: false,
  allowCopy: false,
  onClick: () => {},
  onChangeSelect: () => {},
};

export default TargetTag;
