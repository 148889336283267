import React from 'react';

const viDensityNormal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M1 1h22v4H1V1zm0 6h22v4H1V7zm0 6h22v4H1v-4zm0 6h22v4H1v-4z" />
    </g>
  </svg>
);

export default viDensityNormal;
