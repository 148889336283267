import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  API,
  TableAlerts,
} from 'vibeguide';

class AlertHistory extends PureComponent {
  onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `alerts-${timestamp}.csv`);
  };

  render() {
    const {
      user,
      locationId,
    } = this.props;

    return (
      <TableAlerts
        columns={[
          'Alert Category',
          'Alert Type',
          'Time Generated',
          'Time Resolved',
          '...',
        ]}
        fetch={API.Location.Alerts.listLocation}
        fetchProps={{
          filters: {
            _id: locationId,
          },
        }}
        defaultSortBy={{
          label: 'Time Generated',
          attr: 'createdDate',
          direction: 'desc',
        }}
        paginator
        paginatorProps={{
          label: 'Alerts',
          urlPaging: true,
          urlFilters: true,
        }}
        csv={user.sysAdmin}
        csvProps={{
          onExport: this.onExport,
        }}
      />
    );
  }
}

AlertHistory.propTypes = {
  locationId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(AlertHistory);
