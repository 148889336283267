import { createAction } from 'redux-actions';

export const setModal = createAction('SET_GLOBAL_MODAL');
export const clearModal = createAction('CLEAR_GLOBAL_MODAL');
export const setDrag = createAction('SET_GLOBAL_DRAG');
export const resetDrag = createAction('RESET_GLOBAL_DRAG');
export const setPanel = createAction('SET_GLOBAL_PANEL');
export const resetPanel = createAction('RESET_GLOBAL_PANEL');
export const setLastUrl = createAction('SET_LAST_URL');
export const resetLastUrl = createAction('CLEAR_LAST_URL');

export default {
  setModal,
  clearModal,
  setDrag,
  resetDrag,
  setPanel,
  resetPanel,
  setLastUrl,
  resetLastUrl,
};
