import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './Information.scss';

class Information extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    this.state = {
      canSetCompany: user.sysAdmin,
    };
  }

  componentDidMount() {
    const {
      user,
    } = this.props;

    const {
      canSetCompany,
    } = this.state;

    if (!canSetCompany) {
      this.onSetCompany({
        id: user.companyId,
        value: user.companyName,
      });
    }
  }

  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the company field is changed
   */
  onSetCompany = ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      companyId: currCompanyId,
      tags,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  render() {
    const {
      isNew,
      user,
      name,
      description,
      companyName,
      companyId,
      tags,
      disableInput,
      disableCompany,
    } = this.props;

    const {
      canSetCompany,
    } = this.state;

    return (
      <div className="Information info-baseline">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={canSetCompany ? 6 : 12}
            item
          >
            <Field
              type="text"
              label="Name"
              name="name"
              placeholder="Baseline Name"
              value={name}
              tabIndex={1}
              disabled={disableInput}
              validation={{
                check: true,
                checkEmpty: !isNew,
                events: [
                  'init',
                  'onChange',
                ],
              }}
              onChange={this.onChange}
              required
              autoFocus
            />
          </Grid>

          {canSetCompany ? (
            <Grid xs={6} item>
              <Field
                type="company"
                label="Company"
                name="companyName"
                value={companyName}
                tabIndex={2}
                companyProps={{
                  companyId,
                  companyName,
                }}
                disabled={disableCompany || disableInput}
                validation={{
                  check: true,
                  checkEmpty: !isNew,
                  events: [
                    'init',
                    'onChange',
                  ],
                }}
                onChange={this.onSetCompany}
                required
              />
            </Grid>
          ) : null}
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <Field
              className="description-textarea"
              type="textarea"
              label="Description"
              name="description"
              placeholder="Describe this baseline..."
              value={description}
              tabIndex={3}
              disabled={disableInput}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                companyId,
                tags,
                allowTypes: ['client', 'admin'],
                showAs: 'list',
                assign: user.can('baseline.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={4}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  isNew: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  disableCompany: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  isNew: false,
  name: '',
  description: '',
  companyId: '',
  companyName: '',
  tags: [],
  disableInput: false,
  disableCompany: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
