import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useOutletContext,
  useParams,
} from 'react-router-dom';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';
import {
  API,
  EmptyState,
  color,
} from 'vibeguide';
import CircularProgress from '@mui/material/CircularProgress';
import SubNavigation from '../../Header/SubNavigation';

let resizeTimeout;

function Report({
  className,
  style,
  user,
}) {
  const [token, setToken] = useState(null);
  const [tableauSize, setTableauSize] = useState({
    width: 0,
    height: 0,
  });

  const ref = useRef();

  const [
    reports,
    loading,
  ] = useOutletContext();

  const {
    reportName,
  } = useParams();

  /**
   * Set the available size for the embedded tableau report
   */
  const setSize = () => {
    const rect = ref.current.getBoundingClientRect();

    setTableauSize({
      width: rect.width,
      height: rect.height,
    });
  };

  /**
   * When the window is resized, update the available embedded space
   */
  const onResize = () => {
    clearTimeout(resizeTimeout);

    resizeTimeout = setTimeout(() => {
      setSize();
    }, 500);
  };

  /**
   * Generate a fresh tableau token when a report is loaded
   * Add the embedded tableau file
   */
  useEffect(async () => {
    // Add embedded tableau JS
    const script = document.createElement('script');
    script.type = 'module';
    script.src = process.env.TABLEAU_SCRIPT_URL;
    script.async = true;

    document.body.appendChild(script);

    // listen for resize to change the available size
    window.addEventListener('resize', onResize);

    // get the size of the available space to embed
    setSize();

    try {
      const tokenResponse = await API.Partner.Reports.token(user.partnerId);
      setToken(tokenResponse);
    } catch (err) {
      console.error('Failed to fetch a token');
    }

    return () => {
      document.body.removeChild(script);
      window.removeEventListener('resize', onResize);
      clearTimeout(resizeTimeout);
    };
  }, []);

  const report = find(reports, (report) => { return kebabCase(report.name) === reportName; }) || {};

  return (
    <div
      className={classNames('Report', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...style,
      }}
    >
      <SubNavigation
        style={{
          borderBottom: `1px solid ${color.whiteSmoke}`,
        }}
        title="Reporting Dashboard"
        breadcrumbs={[
          {
            title: 'Reporting Dashboard',
            linkUrl: '/reporting/dashboard',
          },
          {
            title: loading
              ? '...'
              : report.name,
          },
        ]}
      />

      <div
        ref={ref}
        style={{
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {(loading || !token) && (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              color="inherit"
              size={64}
              style={{
                marginTop: 32,
              }}
            />
          </div>
        )}

        {!loading && token && report.url && (
          <tableau-viz
            id="tableau-viz"
            src={report.url}
            width={tableauSize.width}
            height={tableauSize.height}
            toolbar="top"
            token={token}
          />
        )}

        {!loading && token && !report.url && (
          <EmptyState
            title="Report Not Found"
            description="We couldn't find that report."
          />
        )}
      </div>
    </div>
  );
}

Report.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

Report.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Report);
