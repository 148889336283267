import React from 'react';

const viFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M14.4 6L14 4 5 4 5 21 7 21 7 14 12.6 14 13 16 20 16 20 6z" />
    </g>
  </svg>
);

export default viFlag;
