import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  isUndefined,
  sortBy,
  uniqueId,
} from 'lodash';
import {
  GlobalActions,
  ScheduleHelper,
  StorageUtil,
  TimeUtil,
  VibeTooltip,
  VibeIcon,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  viClose,
  color,
} from 'vibeguide';
import DisplayOptions from '../../../Locations/IndividualLocation/Schedule/Controls/DisplayOptions';
import WeekDates from '../../../Locations/IndividualLocation/Schedule/Controls/WeekDates';
import ScheduleData from '../../../Locations/IndividualLocation/Schedule/ScheduleData';
import BaselineScheduleDay from './BaselineScheduleDay';
import './BaselineSchedule.scss';

function BaselineSchedule({
  day,
  baseline,
  onUpdateItems: propsOnUpdateItems,
  onClose,
  setPanel,
}) {
  const [activeDisplay, setActiveDisplay] = useState(StorageUtil.getLocal('baselines:scheduleDisplay', '24h'));

  const startOfWeek = moment().startOf('week');

  baseline = {
    ...baseline,
    startOn: startOfWeek,
  };

  const {
    playlists: tmpPlaylists,
    mixes: tmpMixes,
    messageBlocks: tmpMessageBlocks,
    interruptions: tmpInterruptions,
  } = ScheduleHelper.getBaselineContentForWeek({
    weekBaselines: [baseline],
    addBaselinesToCache: [baseline],
    startOfWeek,
    // companyId,
    // locationId,
  });

  // Add a _uniqueId for each item that can be edited in the schedule day view
  tmpPlaylists.forEach((playlist) => {
    playlist._uniqueId = `playlist-${uniqueId()}`;
  });

  tmpMixes.forEach((mix) => {
    mix._uniqueId = `station-${uniqueId()}`;
  });

  tmpMessageBlocks.forEach((messageBlock) => {
    messageBlock._uniqueId = `messageBlock-${uniqueId()}`;
  });

  tmpInterruptions.forEach((interrupt) => {
    interrupt._uniqueId = `interrupt-${uniqueId()}`;
  });

  const [playlists, setPlaylists] = useState(sortBy(tmpPlaylists, 'startTime'));
  const [mixes, setMixes] = useState(sortBy(tmpMixes, 'startTime'));
  const [messageBlocks, setMessageBlocks] = useState(sortBy(tmpMessageBlocks, 'startTime'));
  const [interruptions, setInterruptions] = useState(sortBy(tmpInterruptions, 'startTime'));

  const onChangeDisplay = (display) => {
    StorageUtil.setLocal('baselines:scheduleDisplay', display);
    setActiveDisplay(display);
  };

  /**
   * When items are updated in the schedule
   */
  const onUpdateItems = (newItems, type, dayStr) => {
    // format the day to the short abbrev.
    const dayDDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(dayStr), 'ddd');
    const newPlaylists = newItems.filter(item => !isUndefined(item.playlist));
    const newMixes = newItems.filter(item => !isUndefined(item.mix));
    const newMessageBlocks = newItems.filter(item => !isUndefined(item.messageBlock));
    const newInterruptions = newItems.filter(item => !isUndefined(item.message));

    if (type === 'Music') {
      // update playlists/stations in the schedule for the modified day
      const mergePlaylists = sortBy([
        ...playlists.filter(playlist => playlist.day !== dayDDD),
        ...newPlaylists.map((playlist) => {
          return {
            ...playlist,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      const mergeMixes = sortBy([
        ...mixes.filter(mix => mix.day !== dayDDD),
        ...newMixes.map((mix) => {
          return {
            ...mix,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      setPlaylists(mergePlaylists);
      setMixes(mergeMixes);
      propsOnUpdateItems({
        playlists: mergePlaylists,
        mixes: mergeMixes,
      });
    } else if (type === 'Message Blocks') {
      // update message blocks in the schedule for the modified day
      const mergeMessageBlocks = sortBy([
        ...messageBlocks.filter(messageBlock => messageBlock.day !== dayDDD),
        ...newMessageBlocks.map((messageBlock) => {
          return {
            ...messageBlock,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      setMessageBlocks(mergeMessageBlocks);
      propsOnUpdateItems({
        messageBlocks: mergeMessageBlocks,
      });
    } else if (type === 'Interrupts') {
      // update interrupts in the schedule for the modified day
      const mergeInterrupts = sortBy([
        ...interruptions.filter(interrupt => interrupt.day !== dayDDD),
        ...newInterruptions.map((interrupt) => {
          return {
            ...interrupt,
            day: dayDDD,
          };
        }),
      ], 'startTime');

      setInterruptions(mergeInterrupts);
      propsOnUpdateItems({
        interruptions: mergeInterrupts,
      });
    }
  };

  /**
   * When a day is being copied to other day(s)
   */
  const onCopyDay = ({
    from,
    to = [],
    playlists: copyPlaylists = [],
    mixes: copyMixes = [],
    messageBlocks: copyMessageBlocks = [],
    interruptions: copyInterrupts = [],
  }) => {
    const copyDayIndex = TimeUtil.getDayOfWeekIndex(from);
    const copyFromDay = TimeUtil.getDayFromIndex(copyDayIndex, 'ddd');

    // also copy the new data to the original day being copied from
    // (this issue is related to the redux store not auto-updating sidepanel components when data changes)
    to.push(copyFromDay);

    let newPlaylists = playlists;
    let newMixes = mixes;
    let newMessageBlocks = messageBlocks;
    let newInterrupts = interruptions;

    // replace the day content for each day the items are being copied to
    to.forEach((day) => {
      newPlaylists = sortBy([
        ...newPlaylists.filter(playlist => playlist.day !== day),
        ...copyPlaylists.map((playlist) => {
          console.log('copy playlist', playlist);
          // generate a new ID
          playlist._uniqueId = uniqueId('playlist-');

          return {
            ...playlist,
            day,
          };
        }),
      ], 'startTime');

      newMixes = sortBy([
        ...newMixes.filter(mix => mix.day !== day),
        ...copyMixes.map((mix) => {
          // generate a new ID
          mix._uniqueId = uniqueId('station-');

          return {
            ...mix,
            day,
          };
        }),
      ], 'startTime');

      newMessageBlocks = sortBy([
        ...newMessageBlocks.filter(messageBlock => messageBlock.day !== day),
        ...copyMessageBlocks.map((messageBlock) => {
          // generate a new ID
          messageBlock._uniqueId = uniqueId('messageBlock-');

          return {
            ...messageBlock,
            day,
          };
        }),
      ], 'startTime');

      newInterrupts = sortBy([
        ...newInterrupts.filter(interrupt => interrupt.day !== day),
        ...copyInterrupts.map((interrupt) => {
          // generate a new ID
          interrupt._uniqueId = uniqueId('interrupt-');

          return {
            ...interrupt,
            day,
          };
        }),
      ], 'startTime');
    });

    setPlaylists(newPlaylists);
    setMixes(newMixes);
    setMessageBlocks(newMessageBlocks);
    setInterruptions(newInterrupts);
    propsOnUpdateItems({
      playlists: newPlaylists,
      mixes: newMixes,
      messageBlocks: newMessageBlocks,
      interruptions: newInterrupts,
    });
  };

  /**
   * When a specific day is clicked on the schedule
   */
  const onClickDay = (dayStr) => {
    // format the day to its full string
    const dayIndex = TimeUtil.getDayOfWeekIndex(dayStr);
    // Monday, Tuesday, etc...
    const dayDDDD = TimeUtil.getDayFromIndex(dayIndex, 'dddd');
    // Mon, Tue, etc...
    const dayDDD = TimeUtil.getDayFromIndex(dayIndex, 'ddd');

    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <BaselineScheduleDay
            day={dayDDDD}
            activeDisplay={activeDisplay}
            locations={baseline.locations}
            playlists={playlists.filter(playlist => playlist.day === dayDDD)}
            mixes={mixes.filter(mix => mix.day === dayDDD)}
            messageBlocks={messageBlocks.filter(messageBlock => messageBlock.day === dayDDD)}
            interruptions={interruptions.filter(interrupt => interrupt.day === dayDDD)}
            onChangeDisplay={onChangeDisplay}
            onCopyDay={onCopyDay}
            onUpdateItems={onUpdateItems}
          />
        ),
      },
    });
  };

  /**
   * When a specific day is clicked from the mini-baseline schedule view
   */
  useEffect(() => {
    if (day) {
      onClickDay(day);
    }
  }, [day]);

  return (
    <SidePanelContainer className="BaselineSchedule">
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div
          className="details-header"
          style={{
            width: '100%',
          }}
        >
          <div className="flex-horizontal">
            <div
              className="title"
              style={{
                flexGrow: 1,
              }}
            >
              <VibeTooltip title={baseline.name || ''}>
                <span>
                  {baseline._id
                    ? baseline.name
                    : 'New Baseline'}
                </span>
              </VibeTooltip>
            </div>

            <DisplayOptions
              activeDisplay={activeDisplay}
              onChangeDisplay={onChangeDisplay}
            />
          </div>
        </div>
      </SidePanelHeader>

      <SidePanelContent className="sidepanel-content">
        <WeekDates
          style={{
            position: 'sticky',
            top: 0,
            // remove margin and use padding to hide the time labels when scrolling
            marginLeft: 0,
            paddingLeft: 55,
            zIndex: 999,
            backgroundColor: color.white,
          }}
          startOfWeek={startOfWeek}
          dayOfWeekOnly
        />

        <ScheduleData
          type="schedule"
          style={{
            height:
              activeDisplay === '24h'
                ? 'calc(100% - 65px)'
                : 'auto',
          }}
          activeDisplay={activeDisplay}
          startOfWeek={startOfWeek}
          playlists={playlists}
          mixes={mixes}
          messageBlocks={messageBlocks}
          interrupts={interruptions}
          allowClickDay
          onClickDay={onClickDay}
        />
      </SidePanelContent>
    </SidePanelContainer>
  );
}

BaselineSchedule.propTypes = {
  day: PropTypes.string,
  baseline: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onUpdateItems: PropTypes.func,
  onClose: PropTypes.func,
};

BaselineSchedule.defaultProps = {
  day: null,
  baseline: {},
  onUpdateItems: () => {},
  onClose: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(BaselineSchedule);
