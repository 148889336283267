import React from 'react';

const viFlagSlash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M3.414 3l16.97 16.97-1.413 1.415-7.547-7.547H8.046v7h-2L6.045 8.46 2 4.414 3.414 3zm11.632.839l.4 2h5.6v10h-3.379L6.045 4.217v-.378h9z" />
    </g>
  </svg>
);

export default viFlagSlash;
