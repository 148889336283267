import React from 'react';

const viChatNotice = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M21 6c.6 0 1 .4 1 1v15l-4-4H7c-.6 0-1-.4-1-1v-2h13V6zm-5-4c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1H6l-4 4V8.242C2.714 8.72 3.574 9 4.5 9 6.985 9 9 6.985 9 4.5c0-.925-.28-1.785-.758-2.5z" />
      <circle cx="4.5" cy="4.5" r="3" fill="currentColor" />
    </g>
  </svg>
);

export default viChatNotice;
