import React from 'react';

const viLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M18 8.667h-1V6.762C17 4.095 14.8 2 12 2S7 4.095 7 6.762v1.905H6c-1.1 0-2 .857-2 1.904v9.524C4 21.143 4.9 22 6 22h12c1.1 0 2-.857 2-1.905v-9.524c0-1.047-.9-1.904-2-1.904zm-6 8.762c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-8.762H8.9V6.762c0-1.62 1.4-2.952 3.1-2.952 1.7 0 3.1 1.333 3.1 2.952v1.905z" />
    </g>
  </svg>
);

export default viLock;
