import React from 'react';
import PropTypes from 'prop-types';
import ObjectSelection from '../ObjectSelection/ObjectSelection';
import LocationsUnassigned from './LocationsUnassigned';
import LocationsAssigned from './LocationsAssigned';

function LocationSelection({
  companyId,
  selectedLocations,
  selectAll,
  selectAllLabel,
  selectAllInitial,
  locationsData,
  availableLocations,
  customToolbarAssigned,
  allowChanges,
  allowPaginator,
  allowACAF,
  allowCompanies,
  allowAssignCompany,
  allowAssignObject,
  required,
  stickyScroll,
  filterParamFirst,
  filterCompanies,
  filterLocations,
  onSelect,
  onDeselect,
  onSelectAll,
  onDeselectAll,
  onAssignObjectLocations,
}) {
  return (
    <ObjectSelection
      className="LocationSelection"
      leftColumn={(
        <LocationsUnassigned
          companyId={companyId}
          selectAll={selectAll}
          selectAllLabel={selectAllLabel}
          selectAllInitial={selectAllInitial}
          selectedLocations={selectedLocations}
          locationsData={availableLocations}
          allowChanges={allowChanges}
          allowPaginator={allowPaginator}
          allowACAF={allowACAF}
          allowCompanies={allowCompanies}
          allowAssignCompany={allowAssignCompany}
          allowAssignObject={allowAssignObject}
          filterParamFirst={filterParamFirst}
          filterCompanies={filterCompanies}
          filterLocations={filterLocations}
          stickyScroll={stickyScroll}
          onSelectLocation={onSelect}
          onSelectAll={onSelectAll}
          onAssignObjectLocations={onAssignObjectLocations}
        />
      )}
      rightColumn={(
        <LocationsAssigned
          selectAll={selectAll}
          selectedLocations={selectedLocations}
          locationsData={locationsData}
          filterParamFirst={filterParamFirst}
          filterCompanies={filterCompanies}
          filterLocations={filterLocations}
          customToolbar={customToolbarAssigned}
          allowChanges={allowChanges}
          allowAssignCompany={allowAssignCompany}
          required={required}
          stickyScroll={stickyScroll}
          onDeselectAll={onDeselectAll}
          onDeselectLocation={onDeselect}
        />
      )}
    />
  );
}

LocationSelection.propTypes = {
  /** Company ID */
  companyId: PropTypes.string.isRequired,
  /** Select All locations */
  selectAll: PropTypes.bool,
  /** Label for the select all toggle */
  selectAllLabel: PropTypes.string,
  /** Select All locations initially (for new objects) */
  selectAllInitial: PropTypes.bool,
  /** Selected locations */
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Selected Locations Data */
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      allLocations: PropTypes.bool,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
    })),
    locations: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      city: PropTypes.string,
      companyId: PropTypes.string,
      companyName: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    })),
  }),
  /** Available Locations Data */
  availableLocations: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      allLocations: PropTypes.bool,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
    })),
    locations: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      city: PropTypes.string,
      companyId: PropTypes.string,
      companyName: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    })),
  }),
  /** Custom toolbar for assigned locations */
  customToolbarAssigned: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Sticky Scroll company header */
  stickyScroll: PropTypes.bool,
  /** Filter parameter for location search */
  filterParamFirst: PropTypes.string,
  /** Filter locations function */
  filterLocations: PropTypes.func,
  /** Filter companies function */
  filterCompanies: PropTypes.func,
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** Allow the Paginator to be displayed */
  allowPaginator: PropTypes.bool,
  /** Allow all current and future toggle */
  allowACAF: PropTypes.bool,
  /** Allow companies to be shown for admins */
  allowCompanies: PropTypes.bool,
  /** Allow companies to be assigned */
  allowAssignCompany: PropTypes.bool,
  /** Allow obect assignment */
  allowAssignObject: PropTypes.bool,
  /** At least one location is required */
  required: PropTypes.bool,
  /** When a location is selected */
  onSelect: PropTypes.func,
  /** When a location is unselected */
  onDeselect: PropTypes.func,
  /** When all locations are selected with ACAF */
  onSelectAll: PropTypes.func,
  /** When all locations are deselected */
  onDeselectAll: PropTypes.func,
  /** When the locations are assigned from an object */
  onAssignObjectLocations: PropTypes.func,
};

LocationSelection.defaultProps = {
  selectAll: false,
  selectAllLabel: 'Assign All Current and Future',
  selectAllInitial: false,
  selectedLocations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  availableLocations: {
    companies: [],
    locations: [],
  },
  customToolbarAssigned: null,
  stickyScroll: false,
  allowChanges: false,
  allowPaginator: false,
  allowACAF: false,
  allowCompanies: false,
  allowAssignCompany: false,
  allowAssignObject: false,
  required: false,
  filterParamFirst: null,
  filterLocations: () => {},
  filterCompanies: () => {},
  onSelect: () => {},
  onDeselect: () => {},
  onSelectAll: () => {},
  onDeselectAll: () => {},
  onAssignObjectLocations: () => {},
};

export default LocationSelection;
