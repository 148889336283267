import { createAction } from 'redux-actions';

export const addLocation = createAction('ADD_CURRENT_USER_LOCATION');
export const removeLocation = createAction('REMOVE_CURRENT_USER_LOCATION');
export const setAllLocations = createAction('SET_CURRENT_USER_ALL_LOCATIONS');
export const setCurrentUser = createAction('SET_CURRENT_USER');
export const setUserImage = createAction('SET_CURRENT_USER_IMAGE');
export const setCompany = createAction('SET_CURRENT_USER_COMPANY');
export const updateCompany = createAction('UPDATE_CURRENT_USER_COMPANY');
export const setPermissions = createAction('SET_CURRENT_USER_PERMISSIONS');
export const setTags = createAction('SET_CURRENT_USER_TAGS');
export const addTag = createAction('ADD_CURRENT_USER_TAG');
export const removeTag = createAction('REMOVE_CURRENT_USER_TAG');
export const updateCurrentUser = createAction('UPDATE_CURRENT_USER');
export const updateUserNotifications = createAction('UPDATE_USER_NOTIFICATIONS');
export const resetPassOld = createAction('RESET_PASS_OLD');
export const resetPassNew = createAction('RESET_PASS_NEW');
export const resetPassConfirm = createAction('RESET_PASS_CONFIRM');

export default {
  addLocation,
  removeLocation,
  setAllLocations,
  setCurrentUser,
  setUserImage,
  setCompany,
  updateCompany,
  setPermissions,
  setTags,
  addTag,
  removeTag,
  updateCurrentUser,
  updateUserNotifications,
  resetPassOld,
  resetPassNew,
  resetPassConfirm,
};
