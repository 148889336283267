import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import {
  Submenu,
  color,
} from 'vibeguide';
import ProfileInformation from './Profile/Sections/ProfileInformation';
import ProfilePlayer from './Profile/Sections/ProfilePlayer';

function ProfileContainer({
  user,
  currentLocation,
}) {
  if (!currentLocation) {
    return null;
  }

  const [location, setLocation] = useState(currentLocation);

  const {
    id,
    type,
  } = useParams();

  /**
   * Update the location
   */
  const onUpdateLocation = (data) => {
    setLocation({
      ...location,
      ...data,
    });
  };

  /**
   * Get the page content
   */
  const getPageContent = () => {
    switch (type) {
      case 'player':
        return (
          <ProfilePlayer
            location={location}
            user={user}
            onUpdate={onUpdateLocation}
          />
        );

      case 'information':
      default:
        return (
          <ProfileInformation
            location={location}
            user={user}
            onUpdate={onUpdateLocation}
          />
        );
    }
  };

  return (
    <div
      className="ProfileContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 150px)',
        overflow: 'auto',
      }}
    >
      <Submenu
        rootUrl={`/location/${id}/profile`}
        style={{
          position: 'sticky',
          top: 0,
          padding: '0 24px',
          // borderBottom: `1px solid ${color.whiteSmoke}`,
          backgroundColor: color.lightGray,
          zIndex: 2,
        }}
        items={[
          {
            name: 'Information',
            show: true,
            enabled: true,
          },
          {
            name: 'Player',
            show: user.can('location.modify_volume')
              || user.can('location.view_device_config'),
            enabled: true,
          },
        ]}
      />

      {getPageContent()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(ProfileContainer);
