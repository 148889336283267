import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { BrowserRouter as Router } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { hot } from 'react-hot-loader';
import {
  API,
  AudioPlayer,
  SidePanel,
  VibeModal,
  VibeIcon,
  UserModel,
  LoginActions,
  GlobalActions,
  Toasts,
  viCheck,
  viError,
  color,
} from 'vibeguide';
import Routes from './Routes/routes';
import LoadingSpinner from './Shared/LoadingSpinner';
import Navigation from './Header/Navigation/Navigation';
import ScrollRestoration from './Shared/ScrollRestoration';
import KonamiCode from './KonamiCode';
import 'normalize.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../sass/vibenomics.scss';

const local = process.env.APP_ENV === 'local';

class VibenomicsApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollRefReady: false,
    };

    this.socketUserListenerAdded = false;
    this._scrollRef = null;
  }

  componentDidMount() {
    this.setState(() => {
      return {
        scrollRefReady: true,
      };
    });
  }

  componentDidUpdate() {
    const {
      socket,
    } = this.props;

    if (!this.socketUserListenerAdded && socket.connected) {
      // Add the user updated listener
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketUserListenerAdded = true;
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    socket.off('VAPI_EVENT', this.onApiEvent);
  }

  onApiEvent = async (e) => {
    switch (e.type) {
      // User was updated
      case 'USER.UPDATED': {
        const {
          user,
          updateUser,
        } = this.props;

        const updatedUser = await API.User.getById(user._id);
        updateUser(updatedUser);
        break;
      }

      // Importing locations has successfully completed
      case 'LOCATIONIMPORT.JOB_COMPLETED': {
        const {
          setModal,
        } = this.props;

        const total = get(e, 'data.importedCount', 0);
        setModal({
          type: 'success',
          btnText: 'Hurray!',
          icon: (
            <VibeIcon
              icon={viCheck}
              color={color.aquaForest}
              size={32}
            />
          ),
          message: total !== 1
            ? `All ${total} locations have been successfully created!`
            : 'The location has been successfully created!',
        });
        break;
      }

      // Importing locations has failed
      case 'LOCATIONIMPORT.JOB_FAILED': {
        const {
          setModal,
        } = this.props;

        const message = get(e, 'data.error.message', 'Import failed. Please upload again.');
        setModal({
          type: 'error',
          btnText: 'Ok',
          icon: (
            <VibeIcon
              icon={viError}
              color={color.fireBrick}
              size={32}
            />
          ),
          message,
        });
        break;
      }

      default:
        break;
    }
  };

  onErrorClose = () => {
    const {
      clearModal,
    } = this.props;

    clearModal();
  };

  renderRoutes = () => {
    const {
      scrollRefReady,
    } = this.state;

    const {
      isAuthenticated,
    } = this.props;

    if (!scrollRefReady) {
      // Wait until scroll has a reference before rendering data
      return (
        <div />
      );
    }

    return (
      <Routes
        scrollRef={this._scrollRef}
        isAuthenticated={isAuthenticated}
      />
    );
  };

  render() {
    const {
      isAuthenticated,
      loading,
      globalModal: {
        type,
        title,
        message,
        btnText,
        confirmProps,
        cancelProps,
        onConfirm,
      },
      audio,
      panel,
    } = this.props;

    return (
      <Router>
        <ScrollRestoration>
          <PerfectScrollbar
            className="app-scrollbar"
            containerRef={(ref) => { this._scrollRef = ref; }}
            options={{
              suppressScrollX: true,
            }}
          >
            {isAuthenticated && (
              <Navigation />
            )}
            {this.renderRoutes()}
            {loading && <LoadingSpinner />}
          </PerfectScrollbar>

          <VibeModal
            className="GlobalModal"
            show={message !== null}
            type={type}
            title={title}
            text={message}
            confirmProps={confirmProps}
            cancelProps={cancelProps}
            btnText={btnText}
            onConfirm={onConfirm}
            onClose={this.onErrorClose}
          />
        </ScrollRestoration>

        <SidePanel
          title={panel.title}
          width={panel.width}
          show={panel.show}
          backdrop={panel.backdrop}
          icons={panel.icons}
          extraPanel={panel.extraPanel}
          thirdPanel={panel.thirdPanel}
          fourthPanel={panel.fourthPanel}
          onClose={panel.onClose}
          onCloseSecond={panel.onCloseSecond}
          onCloseThird={panel.onCloseThird}
          onCloseFourth={panel.onCloseFourth}
        >
          {panel.children}
        </SidePanel>

        {audio.id ? (
          <AudioPlayer
            name={audio.name}
            src={audio.src}
            paused={audio.paused}
            playText={audio.playText}
            pauseText={audio.pauseText}
            bottom={32}
            right={32}
          />
        ) : null}

        <Toasts />
        <KonamiCode />

        {/* root element for the datepicker to render */}
        <div
          id="datepicker-root-portal"
          style={{
            position: 'fixed',
            // keep on top of everything
            zIndex: 9001,
          }}
        />
      </Router>
    );
  }
}

VibenomicsApp.propTypes = {
  /** Boolean value determining whether or not a user is authenticated */
  isAuthenticated: PropTypes.bool.isRequired,
  /** User model reprsenting the current user */
  user: PropTypes.instanceOf(UserModel).isRequired,
  /** Boolean value stating whether or not something in the application is loading */
  loading: PropTypes.bool.isRequired,
};

// const VibenomicsApplication = DragDropContext(HTML5Backend)(VibenomicsApp);

function mapStateToProps(state) {
  return {
    isAuthenticated: state.login.authenticated,
    availablePermissions: state.login.availablePermissions,
    loading: state.loading.pageLoading,
    user: state.login.user,
    socket: state.socket.connection,
    audio: state.audio,
    globalModal: state.global.modal,
    panel: state.global.panel,
  };
}

const mapDispatchToProps = {
  setModal: GlobalActions.setModal,
  clearModal: GlobalActions.clearModal,
  updateUser: LoginActions.updateUser,
};

const App = !local
  ? connect(mapStateToProps, mapDispatchToProps)(VibenomicsApp)
  : hot(module)(connect(mapStateToProps, mapDispatchToProps)(VibenomicsApp));

export default App;
