import React from 'react';

const viMicrophoneLibrary = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M21 1c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2zM10.971 9.643H10c0 1.943 1.543 3.543 3.429 3.828v1.886h1.142v-1.886C16.457 13.186 18 11.586 18 9.643h-.971c0 1.714-1.429 2.914-3.029 2.914-1.6 0-3.029-1.2-3.029-2.914zM14 4.5c-.971 0-1.714.743-1.714 1.714v3.429c0 .971.743 1.714 1.714 1.714s1.714-.743 1.714-1.714V6.214c0-.971-.743-1.714-1.714-1.714zM1 21c0 1.1.9 2 2 2h16v-2H3V5H1v16z" />
    </g>
  </svg>
);

export default viMicrophoneLibrary;
