import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  color,
} from 'vibeguide';
import ProfileContentTitle from './ProfileContentTitle';

function ProfileContent({
  className,
  style,
  title,
  children,
}) {
  return (
    <div
      className={classNames('ProfileContent', className)}
      style={{
        position: 'relative',
        marginBottom: 16,
        padding: 16,
        backgroundColor: color.white,
        border: `1px solid ${color.whiteSmoke}`,
        fontSize: 12,
        userSelect: 'none',
        ...style,
      }}
    >
      {title && (
        <ProfileContentTitle>
          {title}
        </ProfileContentTitle>
      )}

      {children}
    </div>
  );
}

ProfileContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ProfileContent.defaultProps = {
  className: '',
  style: {},
  title: '',
};

export default ProfileContent;
