// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PanelItemLink {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 30px;
}
.PanelItemLink .text {
  flex-grow: 1;
  margin-left: 5px;
  text-transform: uppercase;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/TargetTags/PanelItems/PanelItemLink.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AACJ","sourcesContent":[".PanelItemLink {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  height: 32px;\n  padding: 0 30px;\n\n  .text {\n    flex-grow: 1;\n    margin-left: 5px;\n    text-transform: uppercase;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
