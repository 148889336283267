import React from 'react';

const viRedo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 24 0)">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12 5.5V2L7 7l5 5V7.5c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6H4c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8z" />
    </g>
  </svg>
);

export default viRedo;
