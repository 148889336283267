import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  withRouter,
} from 'vibeguide';
import MessageBlock from '../../../../../Programs/Baselines/Calendar/Items/MessageBlock';
import './MessageBlockItem.scss';

function MessageBlockItem({
  style,
  messageBlock,
  minStartTime,
  maxStopTime,
  color,
  fullView,
  params,
  readOnly,
}) {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const locationId = params.id;
  const archived = !get(messageBlock, 'messageBlock.active');
  const itemStyle = {
    background:
      // eslint-disable-next-line no-nested-ternary
      hover
        ? archived
          ? `repeating-linear-gradient(45deg, ${color}40, ${color}40 10px, ${color}59 10px, ${color}59 20px)`
          : `${color}40`
        : archived
          ? `repeating-linear-gradient(45deg, ${color}33, ${color}33 10px, ${color}4D 10px, ${color}4D 20px)`
          : `${color}33`,
    border: `1px solid ${color}4D`,
    boxSizing: 'border-box',
    ...style,
  };

  return (
    <div
      className="MessageBlockItem"
      style={itemStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {fullView && (
        <MessageBlock
          id={messageBlock._uniqueId}
          messageBlock={messageBlock.messageBlock}
          locationId={locationId}
          startTime={messageBlock.startTime}
          stopTime={messageBlock.stopTime}
          minStartTime={minStartTime}
          maxStopTime={maxStopTime}
          archived={archived}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}

MessageBlockItem.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  messageBlock: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Prevent starting before */
  minStartTime: PropTypes.string,
  /** Prevent stopping after */
  maxStopTime: PropTypes.string,
  color: PropTypes.string.isRequired,
  /** View entire item */
  fullView: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
};

MessageBlockItem.defaultProps = {
  style: {},
  messageBlock: {},
  minStartTime: '',
  maxStopTime: '',
  fullView: false,
  readOnly: false,
};

export default withRouter(MessageBlockItem);
