import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/events`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: (id, options = {}) => {
    options = addVersion('3.0.4', options);
    return Http.get(`${baseUrl}/${id}`, options);
  },
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  create: data => Http.post(baseUrl, data),
  update: data => Http.put(`${baseUrl}/${data._id}`, data),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
};
