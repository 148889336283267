// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SnippetCardList {
  margin: 2rem;
  display: flex;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/AdOps/Orders/OrderDashboard/SnippetCardList/SnippetCardList.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,6BAAA;AACF","sourcesContent":[".SnippetCardList {\r\n  margin: 2rem;\r\n  display: flex;\r\n  justify-content: space-around;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
