import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Field2 as Field,
} from 'vibeguide';

function Information({
  className,
  style,
  user,
  name,
  tags,
  disableInput,
  onUpdate,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onChangeTag = (data) => {
    onUpdate(data);
  };

  return (
    <div
      className={classNames('Information', 'partner', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        ...style,
      }}
    >
      <div
        style={{
          width: '50%',
        }}
      >
        <Field
          type="text"
          label="Partner Name"
          name="name"
          placeholder="Partner Name"
          value={name}
          disabled={disableInput}
          onChange={onChange}
          required
          autoFocus
        />
      </div>

      <div>
        <Field
          type="tags"
          label="Tags"
          name="tags"
          tagProps={{
            companyId: user.companyId,
            tags,
            allowTypes: ['admin'],
            showAs: 'list',
            assign: user.can('partner.assign_tag'),
          }}
          disabled={disableInput}
          onChange={onChangeTag}
        />
      </div>
    </div>
  );
}

Information.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  className: '',
  style: {},
  name: '',
  tags: [],
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
