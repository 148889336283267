import { createAction } from 'redux-actions';
import { uniqueId } from 'lodash';

export const addToast = createAction('ADD_TOAST');
export const updateToast = createAction('UPDATE_TOAST');
export const removeToast = createAction('REMOVE_TOAST');

/**
 * Add a Toast
 */
export function queueToast(data) {
  return async (dispatch) => {
    // Add a unique ID to the toast
    data.id = uniqueId('toast-');
    // Hide the toast by default
    data.show = false;
    // Timeout for the toast before it expires
    data.timeout = data.timeout || 3;

    // Add the toast to show in the UI
    if (data.delay) {
      // Delay showing toast for X milliseconds
      setTimeout(() => {
        dispatch(addToast(data));
      }, data.delay);
    } else {
      // Immediately show toast
      dispatch(addToast(data));
    }
  };
}

export default {
  queueToast,
  addToast,
  updateToast,
  removeToast,
};
