// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Script .script-textarea {
  height: 220px;
}
.Script .special-instructions-textarea {
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Messages/NewMessageRequest/Sidebar/Section/Script.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;AADJ;AAIE;EACE,YAAA;AAFJ","sourcesContent":[".Script {\n\n  .script-textarea {\n    height: 220px;\n  }\n\n  .special-instructions-textarea {\n    height: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
