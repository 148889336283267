import React from 'react';

const viEdit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="nonzero">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M2 11.467V14h2.533l7.334-7.4-2.534-2.533L2 11.467zm11.8-6.8a.644.644 0 0 0 0-.934L12.267 2.2a.644.644 0 0 0-.934 0l-1.2 1.2 2.534 2.533L13.8 4.667z" />
    </g>
  </svg>
);

export default viEdit;
