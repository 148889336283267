import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableUsers,
  withRouter,
} from 'vibeguide';
import UserSidebar from '../../../../Users/Sidebar/UserSidebar';
import './Baselines.scss';

function Users({
  currentLocation,
  setPanel,
}) {
  const history = useNavigate();
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      userId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, userId = null, userAccountType = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <UserSidebar
          userId={userId}
          accountType={userAccountType}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && userId !== null}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.userId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.userId, qs.accountType);
    }
  }, [qs.type, qs.userId]);

  const locationId = get(currentLocation, '_id', null);

  return (
    <div
      className="Users"
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <TableUsers
        columns={[
          '.',
          'First Name',
          'Last Name',
          'Title',
          'Email',
          'Phone #',
          'Company',
          '...',
        ]}
        fetch={API.Location.getUserList}
        fetchProps={{
          filters: {
            _id: locationId,
          },
        }}
        defaultSortBy={{
          label: 'First Name',
          attr: 'fname',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Users',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Users',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: false,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        rowLink={{
          userId: '_id',
          accountType: 'accountType',
        }}
        menuItems={[
          { name: 'Copy and Edit', userCan: 'user.create' },
          { name: 'Archive', userCan: 'user.delete' },
        ]}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
