import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SwapButton from '../SwapButton/SwapButton';
import defaultLocationImage from '../../assets/default_location.png';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import './LocationItem.scss';

class LocationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  onMouseEnter = () => {
    this.setState({
      isHover: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHover: false,
    });
  };

  toggleAssign = () => {
    const {
      location,
      selected,
      allowChanges,
      onSelect,
      onDeselect,
    } = this.props;

    const {
      isHover,
    } = this.state;

    if (!allowChanges) {
      console.warn('Location changes disabled');
      return;
    }

    const data = {
      company: {
        _id: location.companyId,
        name: location.companyName,
        imageUrl: location.companyImageUrl,
      },
      companyId: location.companyId,
      locationId: location._id,
      location,
    };

    if (isHover) {
      this.setState({
        isHover: false,
      });
    }

    if (selected) {
      // Deselect the location
      onDeselect(data);
    } else {
      // Select the location
      onSelect(data);
    }
  };

  render() {
    const {
      location,
      selected: isSelected,
      assign,
      showCompanyImage,
      allowChanges,
    } = this.props;

    const {
      isHover,
    } = this.state;

    // Only show as selected if in the unassigned panel
    const selected = isSelected && assign;

    return (
      <div
        className={classNames('LocationItem', { selected })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.toggleAssign}
      >
        <div className="location-item-header">
          <div className="location-item-details">
            <VibeTooltip title={location.name} placement="top-start">
              <div className="location-item-name">
                {location.name}
              </div>
            </VibeTooltip>
            <div className="location-item-location">
              {location.city}, {location.state}
            </div>
          </div>

          {!selected && allowChanges ? (
            <div className="location-item-assign">
              <SwapButton
                hover={isHover}
                arrowPosition={assign ? 'right' : 'left'}
                arrowOnly
              />
            </div>
          ) : null}

          {showCompanyImage && location.companyImageUrl ? (
            <img
              className="company-image"
              src={location.companyImageUrl}
              alt={location.companyName}
            />
          ) : null}
        </div>

        <div className="location-image-container">
          <img
            src={location.imageUrl || defaultLocationImage}
            className="location-image"
            alt={location.name}
          />
        </div>
      </div>
    );
  }
}

LocationItem.propTypes = {
  /** Location data */
  location: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    imageUrl: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    name: PropTypes.string,
    playerOnline: PropTypes.bool,
    postalCode: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  /** Is the location selected */
  selected: PropTypes.bool,
  /** Assign or Unassign the locations */
  assign: PropTypes.bool,
  /** Show the company image on the card */
  showCompanyImage: PropTypes.bool,
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** When the location is selected */
  onSelect: PropTypes.func,
  /** When the location is deselected */
  onDeselect: PropTypes.func,
};

LocationItem.defaultProps = {
  selected: false,
  assign: false,
  showCompanyImage: false,
  allowChanges: false,
  onSelect: () => {},
  onDeselect: () => {},
};

export default LocationItem;
