import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  find,
} from 'lodash';
import VibeIcon from '../VibeIcon/VibeIcon';
import viArrowDown from '../../icons/viArrowDown';
import color from '../../sass/color.scss';
import './Dropdown.scss';

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      activeItem: {},
      hoverTitle: false,
      hoverItem: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      value,
    } = this.props;

    const {
      value: prevValue,
    } = prevProps;

    const {
      activeItem,
    } = this.state;

    if (
      value
      && value !== prevValue
      && activeItem.value !== value
    ) {
      this.selectItem(value);
    }
  }

  componentWillUnmount() {
    // Remove listener to close the dropdown
    document.removeEventListener('mousedown', this.onMouseDown, false);
  }

  /**
   * Toggle the dropdown
   */
  onToggle = () => {
    const {
      disabled,
    } = this.props;

    // do not show dropdown if disabled
    if (disabled) {
      return;
    }

    this.setState((state) => {
      const open = !state.open;

      if (open) {
        // Add listener to close the dropdown
        document.addEventListener('mousedown', this.onMouseDown, false);
      } else {
        // Remove listener to close the dropdown
        document.removeEventListener('mousedown', this.onMouseDown, false);
      }

      return {
        open,
      };
    });
  };

  /**
   * When an item is selected
   */
  onClickItem = (e) => {
    const {
      target: {
        dataset: {
          value,
        },
      },
    } = e;

    this.selectItem(value);
  };

  /**
   * Global page listener for a mousedown event to close the dropdown
   */
  onMouseDown = (e) => {
    const {
      target,
    } = e;

    // Did they click an item in the dropdown list?
    const itemClicked = target.classList.contains('dropdown-item')
      || target.classList.contains('dropdown-items')
      || target.classList.contains('title-parent');

    if (!itemClicked) {
      // a dropdown item was not selected, close the dropdown
      this.setState({
        open: false,
      });

      // Remove listener to close the dropdown
      document.removeEventListener('mousedown', this.onMouseDown, false);
    }
  };

  /**
   * When the user starts hovering over the title
   */
  onMouseEnterTitle = () => {
    const {
      disabled,
    } = this.props;

    // do not change style when dropdown is disabled
    if (disabled) {
      return;
    }

    this.setState({
      hoverTitle: true,
    });
  };

  /**
   * When the user stops hovering over the title
   */
  onMouseLeaveTitle = () => {
    const {
      disabled,
    } = this.props;

    // do not change style when dropdown is disabled
    if (disabled) {
      return;
    }

    this.setState({
      hoverTitle: false,
    });
  };

  /**
   * When the user starts hovering over an item
   */
  onMouseEnterItem = (e) => {
    const {
      target: {
        dataset: {
          value,
        },
      },
    } = e;

    this.setState({
      hoverItem: value,
    });
  };

  /**
   * When the user stops hovering over the title
   */
  onMouseLeaveItem = () => {
    this.setState({
      hoverItem: null,
    });
  };

  /**
   * Select an item by value
   */
  selectItem = (value) => {
    const {
      items,
      defaultValue,
      onChange,
    } = this.props;

    const {
      activeItem,
    } = this.state;

    const item = find(items, { value });
    const resetActiveItem = activeItem.value === value;

    const newActiveItem = resetActiveItem
      // remove the active item
      ? {}
      // set the active item
      : item;

    this.setState({
      open: false,
      activeItem: newActiveItem,
    });

    // tell the parent component of the new object to list
    const activeItemValue = newActiveItem.value || defaultValue;
    onChange(activeItemValue);
  };

  render() {
    const {
      className,
      title,
      width,
      themeColor,
      items,
      disabled,
    } = this.props;

    const {
      open,
      activeItem,
      hoverTitle,
      hoverItem,
    } = this.state;

    return (
      <div
        className={classNames('Dropdown', className, { disabled })}
        style={{
          width,
        }}
      >
        <div
          className={classNames('title-parent', { open })}
          style={{
            border: themeColor
              ? `1px solid ${themeColor}`
              : `1px solid ${color.whiteSmoke}`,
            background: hoverTitle
              ? color.lightGray
              : color.white,
          }}
          onMouseEnter={this.onMouseEnterTitle}
          onMouseLeave={this.onMouseLeaveTitle}
          onClick={this.onToggle}
        >
          <div className="title-container">
            <div
              className="title"
              style={{
                color: themeColor || color.obsidian,
              }}
            >
              {activeItem.name ? (
                <div className="item-container">
                  {activeItem.icon ? (
                    <VibeIcon
                      className="dropdown-icon"
                      icon={activeItem.icon}
                      color={themeColor || color.manatee}
                      size={16}
                    />
                  ) : null}

                  <div className="name">
                    {activeItem.name}
                  </div>
                </div>
              ) : title}
            </div>

            <VibeIcon
              icon={viArrowDown}
              color={themeColor || color.manatee}
              size={24}
            />
          </div>
        </div>

        {open ? (
          <div
            className="dropdown-items"
            style={{
              border: themeColor
                ? `1px solid ${themeColor}`
                : `1px solid ${color.whiteSmoke}`,
            }}
          >
            {items.map((item) => {
              const active = activeItem.value === item.value;

              return (
                <div
                  key={`dropdown-item-${item.name}`}
                  className={classNames('dropdown-item', { active })}
                  style={{
                    color: themeColor || color.obsidian,
                    // eslint-disable-next-line no-nested-ternary
                    background: active || hoverItem === item.value
                      ? themeColor ? `${themeColor}29` : color.lightGray
                      : color.white,
                  }}
                  data-value={item.value}
                  onMouseEnter={this.onMouseEnterItem}
                  onMouseLeave={this.onMouseLeaveItem}
                  onClick={this.onClickItem}
                >
                  <div className="item-container">
                    {item.icon ? (
                      <VibeIcon
                        className="dropdown-icon"
                        icon={item.icon}
                        color={themeColor || color.manatee}
                        size={16}
                      />
                    ) : null}

                    <div
                      className="name"
                      style={{
                        fontWeight: active || hoverItem === item.value
                          ? 'bold'
                          : 'normal',
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  themeColor: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
  })),
  /** selected value */
  value: PropTypes.string,
  /** default item value */
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  /** when the selection is changed */
  onChange: PropTypes.func,
};

Dropdown.defaultProps = {
  className: '',
  width: 'auto',
  themeColor: null,
  items: [],
  value: '',
  defaultValue: '',
  disabled: false,
  onChange: () => {},
};

export default Dropdown;
