import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  color,
} from 'vibeguide';

function ProfileContentTitle({
  className,
  style,
  children,
}) {
  return (
    <div
      className={classNames('ProfileContentTitle', className)}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
        ...style,
      }}
    >
      <div
        style={{
          flexGrow: 1,
          fontSize: 14,
          fontWeight: 'bold',
          color: color.manatee,
        }}
      >
        {children}
      </div>
    </div>
  );
}

ProfileContentTitle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ProfileContentTitle.defaultProps = {
  className: '',
  style: {},
};

export default ProfileContentTitle;
