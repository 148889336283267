import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
  VibeButton,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import './DownloadCut.scss';

class DownloadCut extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      roughCutUrl,
      finalCutUrl,
    } = props;

    const isContentCurator = user.can('message.content_curator');
    const canDownloadRough = roughCutUrl && isContentCurator;
    const canDownloadFinal = finalCutUrl;

    this.state = {
      cutType: canDownloadRough
        ? 'rough'
        : 'final',
      canDownloadRough,
      canDownloadFinal,
    };
  }

  onDownload = () => {
    const {
      roughCutUrl,
      finalCutUrl,
    } = this.props;

    const {
      cutType,
    } = this.state;

    const url = cutType === 'rough'
      ? roughCutUrl
      : finalCutUrl;

    window.open(url);
  };

  setCutRough = () => {
    this.setState({
      cutType: 'rough',
    });
  };

  setCutFinal = () => {
    this.setState({
      cutType: 'final',
    });
  };

  render() {
    const {
      onClose,
    } = this.props;

    const {
      cutType,
      canDownloadRough,
      canDownloadFinal,
    } = this.state;

    return (
      <div className="DownloadCut">
        <div className="header">
          <div className="title">
            Please select the cut type
          </div>

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        </div>

        <div className="content">
          <Field
            type="radio"
            label="Rough Cut"
            radioProps={{
              selected: cutType === 'rough',
            }}
            disabled={!canDownloadRough}
            onClick={this.setCutRough}
          />

          <Field
            type="radio"
            label="Final Cut"
            radioProps={{
              selected: cutType === 'final',
            }}
            disabled={!canDownloadFinal}
            onClick={this.setCutFinal}
          />
        </div>

        <div className="footer">
          <VibeButton
            text="Download"
            btnColor="green"
            textColor="white"
            onClick={this.onDownload}
          />
        </div>
      </div>
    );
  }
}

DownloadCut.propTypes = {
  roughCutUrl: PropTypes.string,
  finalCutUrl: PropTypes.string,
  onClose: PropTypes.func,
};

DownloadCut.defaultProps = {
  roughCutUrl: null,
  finalCutUrl: null,
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(DownloadCut);
