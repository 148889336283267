import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  map,
} from 'lodash';
import {
  Field2 as Field,
  VibeIcon,
  viLock,
  viUnlock,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import statesList from '../../../../../utils/states';

function ProfileAddress({
  className,
  country,
  address1,
  address2,
  city,
  state,
  postalCode,
  geoCoordinatesLocked,
  lat,
  long,
  timezone,
  locality,
  isAdmin,
  canEdit,
  onUpdate,
}) {
  const canAdminEdit = isAdmin && canEdit;

  /**
   * When a field value is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the coordinates are locked/unlocked
   */
  const onToggleLock = () => {
    onUpdate({
      geoCoordinatesLocked: !geoCoordinatesLocked,
    });
  };

  /**
   * Get the locality label for the location
   */
  const getLocalityLabel = (key) => {
    switch (key) {
      case 'level_0':
        return 'Country';

      case 'level_1':
        return 'State';

      case 'level_2':
        return 'County';

      case 'level_3':
        return 'Township';

      default:
        return 'Unknown';
    }
  };
  let label;
  if (['AT', 'AU', 'BR', 'GE', 'IN', 'MY', 'MX', 'FM', 'MM', 'NZ', 'NG', 'PW', 'SS'].includes(country)) {
    label = 'State';
  } else {
    label = 'Province';
  }
  return (
    <div className={classNames('ProfileAddress', className)}>
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <Field
            type="text"
            label="Address 1"
            placeholder="123 Main St."
            name="address1"
            value={address1}
            tabIndex={16}
            onChange={onChange}
            disabled={!canAdminEdit}
            required
          />
        </Grid>

        <Grid xs={12} item>
          <Field
            type="text"
            label="Address 2"
            placeholder="Optional"
            name="address2"
            value={address2}
            tabIndex={17}
            disabled={!canAdminEdit}
            onChange={onChange}
          />
        </Grid>

        <Grid xs={12} lg={6} item>
          <Field
            type="country"
            label="Country"
            name="country"
            value={country}
            tabIndex={18}
            disabled={!canAdminEdit}
            onChange={onChange}
            required
          />
        </Grid>

        <Grid xs={12} lg={6} item>
          <Field
            type="text"
            label="City"
            placeholder="City"
            name="city"
            value={city}
            tabIndex={19}
            disabled={!canAdminEdit}
            onChange={onChange}
            required
          />
        </Grid>

        <Grid xs={12} lg={6} item>
          {country !== 'US' ? (
            <Field
              type="text"
              label={label}
              name="state"
              value={state}
              tabIndex={20}
              disabled={!canAdminEdit}
              onChange={onChange}
              required
            />
          ) : (
            <Field
              type="select"
              label="State"
              name="state"
              value={state}
              tabIndex={20}
              options={statesList}
              disabled={!canAdminEdit}
              onChange={onChange}
              required
            />
          )}
        </Grid>

        <Grid xs={12} lg={6} item>
          <Field
            type="text"
            label="Postal Code"
            placeholder="00000"
            name="postalCode"
            value={postalCode}
            tabIndex={21}
            disabled={!canAdminEdit}
            onChange={onChange}
            required
          />
        </Grid>

        <Grid xs={12} item>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                marginRight: 4,
              }}
            >
              <VibeIcon
                type="button"
                icon={geoCoordinatesLocked
                  ? viLock
                  : viUnlock}
                color={geoCoordinatesLocked
                  ? color.fireBrick
                  : color.manatee}
                size={16}
                tooltip={geoCoordinatesLocked
                  ? 'Unlock Coordinates'
                  : 'Lock Coordinates'}
                buttonProps={{
                  size: 32,
                  color: geoCoordinatesLocked
                    ? color.fireBrick16
                    : color.lightGray,
                  borderColor: geoCoordinatesLocked
                    ? color.fireBrick
                    : color.whiteSmoke,
                  style: {
                    background: 'transparent',
                  },
                }}
                disabled={!canAdminEdit}
                onClick={onToggleLock}
              />
            </div>

            <div
              style={{
                flexGrow: 1,
                marginRight: 8,
              }}
            >
              <Field
                type="text"
                label="Latitude"
                placeholder="0.00000"
                name="lat"
                value={lat}
                tabIndex={22}
                disabled={!canAdminEdit || geoCoordinatesLocked}
                onChange={onChange}
              />
            </div>

            <div
              style={{
                flexGrow: 1,
                marginLeft: 8,
              }}
            >
              <Field
                type="text"
                label="Longitude"
                placeholder="0.00000"
                name="long"
                value={long}
                tabIndex={22}
                disabled={!canAdminEdit || geoCoordinatesLocked}
                onChange={onChange}
              />
            </div>
          </div>
        </Grid>

        <Grid xs={6} item>
          <Field
            type="custom"
            label="Timezone"
          >
            {timezone}
          </Field>
        </Grid>

        {map(locality, (value, key) => {
          return (
            <Grid
              key={`locality-${key}`}
              xs={6}
              item
            >
              <Field
                type="custom"
                label={getLocalityLabel(key)}
              >
                {value}
              </Field>
            </Grid>
          );
        })}

      </Grid>
    </div>
  );
}

ProfileAddress.propTypes = {
  className: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  geoCoordinatesLocked: PropTypes.bool,
  lat: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  long: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  timezone: PropTypes.string,
  locality: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  onUpdate: PropTypes.func,
};

ProfileAddress.defaultProps = {
  className: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  geoCoordinatesLocked: true,
  lat: 0,
  long: 0,
  timezone: '',
  locality: {},
  isAdmin: false,
  canEdit: false,
  onUpdate: () => {},
};

export default ProfileAddress;
