import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isNumber,
} from 'lodash';
import {
  setAudio,
  resetAudio,
} from '../../actions/Audio/AudioActions';
import PlayControlPlayer from '../PlayControl/PlayControlPlayer';
import ProgressBar from '../ProgressBar/ProgressBar';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './AudioPlayer.scss';

class AudioPlayer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      scrubToPercent: 0,
      currentDuration: '',
    };
  }

  onPlay = () => {
    const {
      paused,
      setAudio,
    } = this.props;

    if (paused) {
      setAudio({
        paused: false,
      });
    }
  };

  onPause = () => {
    const {
      paused,
      setAudio,
    } = this.props;

    if (!paused) {
      setAudio({
        paused: true,
      });
    }
  };

  onTimeUpdate = ({
    currentPercent,
    currentDuration,
  }) => {
    this.setState({
      progress: currentPercent,
      currentDuration,
    });
  };

  onScrubTo = (e, percent) => {
    this.setState({
      scrubToPercent: percent,
    });
  };

  onClose = () => {
    const {
      resetAudio,
    } = this.props;

    resetAudio();
  };

  render() {
    const {
      className,
      name,
      src,
      playText,
      pauseText,
      top,
      left,
      bottom,
      right,
    } = this.props;

    const {
      progress,
      scrubToPercent,
      currentDuration,
    } = this.state;

    const style = {};

    if (isNumber(top)) {
      style.top = top;
    }

    if (isNumber(left)) {
      style.left = left;
    }

    if (isNumber(bottom)) {
      style.bottom = bottom;
    }

    if (isNumber(right)) {
      style.right = right;
    }

    return (
      <div
        className={classNames('AudioPlayer', className)}
        style={style}
      >
        <div className="audio-content">
          <PlayControlPlayer
            className="play-message"
            src={src}
            color={color.aquaForest}
            disabled={!src}
            scrubToPercent={scrubToPercent}
            autoPlay
            audioPlayer
            playText={playText}
            pauseText={pauseText}
            onTimeUpdate={this.onTimeUpdate}
            onPlay={this.onPlay}
            onPause={this.onPause}
          />

          <div className="name">
            {name}
          </div>

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={16}
            tooltip="Close Player"
            onClick={this.onClose}
          />
        </div>

        <ProgressBar
          className="audio-progress"
          progress={progress}
          currentDuration={currentDuration}
          onChange={this.onScrubTo}
        />
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  src: PropTypes.string,
  paused: PropTypes.bool.isRequired,
  playText: PropTypes.string,
  pauseText: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
};

AudioPlayer.defaultProps = {
  className: '',
  name: null,
  src: null,
  playText: 'Play',
  pauseText: 'Pause',
  top: null,
  left: null,
  bottom: null,
  right: null,
};

const mapDispatchToProps = {
  setAudio,
  resetAudio,
};

export default connect(null, mapDispatchToProps)(AudioPlayer);
