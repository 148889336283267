import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TagList from '../../Tags/TagList';
import './ContentTags.scss';

class ContentTags extends PureComponent {
  render() {
    const {
      className,
      textStyle,
      tags,
      hideIcon,
    } = this.props;

    return (
      <div className={classNames('ContentTags', className)}>
        <div
          className="tags"
          style={textStyle}
        >
          <TagList
            tags={tags}
            hideIcon={hideIcon}
          />
        </div>
      </div>
    );
  }
}

ContentTags.propTypes = {
  className: PropTypes.string,
  /** Location Tags */
  tags: PropTypes.arrayOf(PropTypes.object),
  /** Text Style */
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Hide the tag list icon */
  hideIcon: PropTypes.bool,
};

ContentTags.defaultProps = {
  className: '',
  tags: [],
  textStyle: {},
  hideIcon: false,
};

export default ContentTags;
