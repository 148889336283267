import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  StorageUtil,
  VibeButtonNew,
  viEye,
  viEyeSlash,
  color,
} from 'vibeguide';
import './AdControls.scss';

class AdControls extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    const canViewAdProgram = user.can('location-ad-program.view');
    const showAdLayer = StorageUtil.getLocal('locations:showAdLayer', true);

    this.state = {
      canViewAdProgram,
      isAdLayerChecked: canViewAdProgram && showAdLayer,
    };
  }

  /**
   * When the ad layer is toggled on/off
   */
  onToggleAdLayer = () => {
    const {
      onToggleAdLayer,
    } = this.props;

    this.setState((state) => {
      const isAdLayerChecked = !state.isAdLayerChecked;
      StorageUtil.setLocal('locations:showAdLayer', isAdLayerChecked);

      onToggleAdLayer(isAdLayerChecked);

      return {
        isAdLayerChecked,
      };
    });
  };

  render() {
    const {
      className,
      disableAdLayerToggle,
    } = this.props;

    const {
      canViewAdProgram,
      isAdLayerChecked,
    } = this.state;

    // Do not show if user cannot view the ad program
    if (!canViewAdProgram) {
      return null;
    }

    return (
      <div className={classNames('AdControls', className)}>
        <div className="toggle-ad-layer">
          <VibeButtonNew
            text="VIEW AD LAYER"
            variant={isAdLayerChecked
              ? 'contained'
              : 'outlined'}
            icon={isAdLayerChecked
              ? viEye
              : viEyeSlash}
            color={isAdLayerChecked
              ? color.aquaForest
              : color.violetVibe}
            disabled={disableAdLayerToggle}
            onClick={this.onToggleAdLayer}
          />
        </div>
      </div>
    );
  }
}

AdControls.propTypes = {
  className: PropTypes.string,
  disableAdLayerToggle: PropTypes.bool,
  onToggleAdLayer: PropTypes.func,
};

AdControls.defaultProps = {
  className: '',
  disableAdLayerToggle: false,
  onToggleAdLayer: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(AdControls);
