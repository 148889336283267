import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeCheckbox,
  VibeButtonGroup,
  color,
} from 'vibeguide';
import './PermissionItem.scss';

class PermissionItem extends PureComponent {
  /**
   * When a permission is toggled
   */
  onToggle = () => {
    const {
      permissionId,
      onToggle,
    } = this.props;

    onToggle(permissionId);
  };

  /**
   * When a bulk permission is set to on
   */
  onBulkPermissionOn = () => {
    const {
      permissionId,
      onBulkPermissionOn,
    } = this.props;

    onBulkPermissionOn(permissionId);
  };

  /**
   * When a bulk permission is set to off
   */
  onBulkPermissionOff = () => {
    const {
      permissionId,
      onBulkPermissionOff,
    } = this.props;

    onBulkPermissionOff(permissionId);
  };

  render() {
    const {
      permissionId,
      name,
      description,
      checked,
      disabled,
      bulk,
      bulkPermissionOn,
      bulkPermissionOff,
    } = this.props;

    return (
      <div className={classNames('PermissionItem', { disabled })}>
        <div className="permission-info">
          <div className="permission-title">
            {name}
          </div>

          <div className="permission-desc">
            {description}
          </div>
        </div>

        <div className="permission-value">
          {!bulk ? (
            <VibeCheckbox
              size={16}
              color={color.violetVibe}
              data-id={permissionId}
              checked={checked}
              disabled={disabled}
              onChange={this.onToggle}
            />
          ) : (
            <VibeButtonGroup>
              <VibeCheckbox
                className="on-off-btn"
                size={16}
                color={color.aquaForest}
                data-id={permissionId}
                checked={bulkPermissionOff}
                disabled={disabled}
                onChange={this.onBulkPermissionOff}
              />
              <VibeCheckbox
                className="on-off-btn"
                size={16}
                color={color.aquaForest}
                data-id={permissionId}
                checked={bulkPermissionOn}
                disabled={disabled}
                onChange={this.onBulkPermissionOn}
              />
            </VibeButtonGroup>
          )}
        </div>
      </div>
    );
  }
}

PermissionItem.propTypes = {
  permissionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  bulk: PropTypes.bool,
  bulkPermissionOn: PropTypes.bool,
  bulkPermissionOff: PropTypes.bool,
  onToggle: PropTypes.func,
  onBulkPermissionOn: PropTypes.func,
  onBulkPermissionOff: PropTypes.func,
};

PermissionItem.defaultProps = {
  description: '',
  checked: false,
  bulk: false,
  bulkPermissionOn: false,
  bulkPermissionOff: false,
  disabled: false,
  onToggle: () => {},
  onBulkPermissionOn: () => {},
  onBulkPermissionOff: () => {},
};

export default PermissionItem;
