import React from 'react';

const viDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M19 9.5h-4v-6H9v6H5l7 7 7-7zm-14 9v2h14v-2H5z" />
    </g>
  </svg>
);

export default viDownload;
