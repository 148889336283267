import React from 'react';

const viChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M14.9 7.4L13.5 6l-6 6 6 6 1.4-1.4-4.6-4.6z" />
    </g>
  </svg>
);

export default viChevronLeft;
