import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  toInteger,
} from 'lodash';
import {
  GlobalActions,
  NavigationHelper,
  ScheduleHelper,
  withRouter,
} from 'vibeguide';
import './AdPrograms.scss';
import AdProgramList from './List/AdProgramList';
import AdProgramSchedule from './Schedule/AdProgramSchedule';

let startJobIn;
let runJobInterval;

function AdPrograms({
  className,
  location,
}) {
  if (!location) {
    return null;
  }

  const [adProgramId, setAdProgramId] = useState('');
  const [adProgram, setAdProgram] = useState({});
  const [currTime, setCurrTime] = useState(ScheduleHelper.getCurrentDate(location.timeZone));

  const qs = NavigationHelper.getParams() || {};

  /**
   * When the ad program is changed
   * Update the list component with the changes so the save button sends the updated data
   */
  const onChangeAdProgram = (newAdProgram) => {
    setAdProgram(newAdProgram);
  };

  /**
   * Run the job to get the current time
   */
  const runJob = () => {
    setCurrTime(ScheduleHelper.getCurrentDate(location.timeZone));
  };

  /**
   * Start the job to get the current time at this location
   */
  const startJob = () => {
    clearInterval(runJobInterval);
    runJobInterval = setInterval(runJob, 60000);
    // run the job immediately
    runJob();
  };

  useEffect(() => {
    if (qs.adProgramId) {
      setAdProgramId(qs.adProgramId);
    } else if (!qs.adProgramId && adProgramId) {
      // reset the ad program ID
      setAdProgramId('');
    }
  }, [qs.adProgramId]);

  /**
   * Run once when the component mounts
   */
  useEffect(() => {
    // update the current time with the computer clock
    // get the seconds to the next minute
    const secondsUntilNextMinute = 60 - toInteger(currTime.format('s'));
    // start the location current time when the next minute starts
    clearTimeout(startJobIn);
    startJobIn = setTimeout(startJob, secondsUntilNextMinute * 1000);

    // when component unmounts
    return () => {
      // stop updating the current time
      clearTimeout(startJobIn);
      clearInterval(runJobInterval);
    };
  }, []);

  return (
    <div className={classNames('AdPrograms', className)}>
      <div className="content">
        <div className="col col-left">
          <AdProgramList
            locationId={location._id}
            adProgramId={adProgramId}
            adProgram={adProgram}
            currTime={currTime}
          />
        </div>

        <div className="col col-right">
          <AdProgramSchedule
            locationId={location._id}
            adProgramId={adProgramId}
            mediaFormat={location.mediaFormat}
            currTime={currTime}
            timezone={location.timeZone}
            adProgram={adProgram}
            onChange={onChangeAdProgram}
          />
        </div>
      </div>
    </div>
  );
}

AdPrograms.propTypes = {
  className: PropTypes.string,
};

AdPrograms.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    location: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdPrograms));
