import React from 'react';
import PropTypes from 'prop-types';
import FullObjectSelection from '../FullObjectSelection/FullObjectSelection';
import PartnersUnassigned from './PartnersUnassigned';
import PartnersAssigned from './PartnersAssigned';

function PartnerSelection({
  filters,
  selected,
  allowCustomOrder,
  allowChanges,
  customToolbar,
  assigning,
  onChange,
}) {
  if (assigning) {
    return (
      <FullObjectSelection className="PartnerSelection">
        <PartnersUnassigned
          selected={selected}
          customToolbar={customToolbar}
          filters={filters}
          onChange={onChange}
        />
      </FullObjectSelection>
    );
  }

  return (
    <FullObjectSelection className="PartnerSelection">
      <PartnersAssigned
        selected={selected}
        allowCustomOrder={allowCustomOrder}
        allowChanges={allowChanges}
        customToolbar={customToolbar}
        onChange={onChange}
      />
    </FullObjectSelection>
  );
}

PartnerSelection.propTypes = {
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected objects */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Allow custom order of objects in the assigned table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the object selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar for assigned locations */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Selector is assigning objects */
  assigning: PropTypes.bool,
  /** When the objects are changed */
  onChange: PropTypes.func,
};

PartnerSelection.defaultProps = {
  filters: null,
  selected: [],
  allowCustomOrder: false,
  allowChanges: false,
  assigning: false,
  customToolbar: null,
  onChange: () => {},
};

export default PartnerSelection;
