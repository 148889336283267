import React from 'react';

const viTimeHistory = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M12.5 2.5C9 2.5 5.9 4.4 4.3 7.3L2 5v6.5h6.5L5.7 8.7C7 6.2 9.5 4.5 12.5 4.5c4.1 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5c-3.3 0-6-2.1-7.1-5H3.3c1.1 4 4.8 7 9.2 7 5.3 0 9.5-4.3 9.5-9.5s-4.3-9.5-9.5-9.5zm-1.5 5v5.1l4.7 2.8.8-1.3-4-2.4V7.5H11z" />
    </g>
  </svg>
);

export default viTimeHistory;
