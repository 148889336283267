import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

function Information({
  name,
  isNew,
  disableInput,
  onUpdate,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  return (
    <div className="Advertiser">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Information
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          xs={12}
          item
        >
          <Field
            type="text"
            label="Name"
            name="name"
            placeholder="Advertiser Name"
            value={name}
            disabled={disableInput}
            validation={{
              check: true,
              checkEmpty: !isNew,
              events: [
                'init',
                'onChange',
              ],
            }}
            onChange={onChange}
            required
            autoFocus
          />
        </Grid>
      </Grid>
    </div>
  );
}

Information.propTypes = {
  isNew: PropTypes.bool,
  name: PropTypes.string,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  isNew: false,
  name: '',
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
