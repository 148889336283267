import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  transparent,
} from '../../utils/ColorUtil';
import color from '../../sass/color.scss';
import './DaySelectorDay.scss';

function DaySelectorDay({
  className,
  day,
  selectedColor,
  selected,
  disabled,
  onClick,
}) {
  const [hover, setHover] = useState(false);

  const getStyle = () => {
    return {
      cursor: !disabled
        ? 'pointer'
        : 'default',
      backgroundColor: selected
        // day is selected, keep it the selected color
        ? transparent(selectedColor, 16)
        // day is  not selected
        : color.manatee16,
      // eslint-disable-next-line no-nested-ternary
      color: selected
        // day is selected, keep it the selected color
        ? selectedColor
        // day is  not selected
        : hover && !disabled
          // hovering over the day
          ? selectedColor
          // not hovering over the day
          : color.manatee,
      border: selected
        ? `1px solid ${selectedColor}`
        : 'none',
      boxSizing: 'border-box',
    };
  };

  const onMouseEnter = () => {
    if (disabled) {
      return;
    }

    setHover(true);
  };

  const onMouseLeave = () => {
    if (disabled) {
      return;
    }

    setHover(false);
  };

  return (
    <div
      className={classNames('DaySelectorDay', className)}
      style={getStyle()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {day.substring(0, 1)}
    </div>
  );
}

DaySelectorDay.propTypes = {
  className: PropTypes.string,
  day: PropTypes.string.isRequired,
  selectedColor: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DaySelectorDay.defaultProps = {
  className: '',
  selectedColor: color.violetVibe,
  selected: false,
  disabled: false,
  onClick: () => {},
};

export default DaySelectorDay;
