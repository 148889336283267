// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertEmailSettings .setting-unit {
  margin-left: 8px;
  width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Dashboard/Alerts/AlertEmailSettings.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,YAAA;AADJ","sourcesContent":[".AlertEmailSettings {\n\n  .setting-unit {\n    margin-left: 8px;\n    width: 160px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
