// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentTags {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.ContentTags .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 8px;
}
.ContentTags .tags {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeTable/CellTypes/ContentTags.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".ContentTags {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n\n  .text {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    padding: 5px 8px;\n  }\n\n  .tags {\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
