import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  GlobalActions,
  LoadingContent,
  API,
} from 'vibeguide';
import AdvertiserDetails from './AdvertiserDetails';
import './AdvertiserSidebar.scss';

function AdvertiserSidebar({
  advertiserId,
  isNew,
  setPanel,
}) {
  const [advertiser, setAdvertiser] = useState({});

  const getAdvertiser = async (advertiserId) => {
    const advertiserResponse = await API.Advertiser.getById(advertiserId);

    setAdvertiser(advertiserResponse);
  };

  // Get advertiser if ID changes
  useEffect(() => {
    if (advertiserId && !isNew) {
      getAdvertiser(advertiserId);
    }
  }, [advertiserId]);

  const onUpdate = (data) => {
    setAdvertiser({
      ...advertiser,
      ...data,
    });
  };

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  return (
    <div className="AdvertiserSidebar">
      <AdvertiserDetails
        advertiser={advertiser}
        isNew={isNew}
        onClose={onClose}
        onUpdate={onUpdate}
        onRefresh={getAdvertiser}
      />

      {advertiserId && !advertiser._id && (
        <LoadingContent />
      )}
    </div>
  );
}

AdvertiserSidebar.propTypes = {
  advertiserId: PropTypes.string,
  isNew: PropTypes.bool,
};

AdvertiserSidebar.defaultProps = {
  advertiserId: null,
  isNew: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(AdvertiserSidebar);
