import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  find,
} from 'lodash';
import {
  GlobalActions,
  VibeIcon,
  viCalendar,
  color,
} from 'vibeguide';
import ScheduleOverride from '../../../Programs/Baselines/Calendar/ScheduleOverride/ScheduleOverride';
import './ScheduleToolbar.scss';
import BaselineSchedule from '../../../Programs/Baselines/BaselineSchedule/BaselineSchedule';

function ScheduleToolbar({
  className,
  user,
  currentLocation,
  schedule,
  musicOverride,
  musicOverrideSchedule,
  timezone,
  viewAds,
  setPanel,
  onGetSchedule,
}) {
  const canSchedule = user.can('location.schedule');
  const currBaseline = find(schedule.baselines, { current: true }) || {};
  let baselineAdProgramText = '';
  let baselineBackgroundColor = color.violetVibe;

  if (viewAds && currentLocation.adProgramEnabled) {
    baselineAdProgramText = 'Ad Program Enabled';
    baselineBackgroundColor = color.aquaForest;
  } else if (viewAds && !currentLocation.adProgramEnabled) {
    baselineAdProgramText = 'Ad Program Disabled';
    baselineBackgroundColor = color.fireBrick;
  }

  const onCloseSidebar = () => {
    setPanel({
      show: false,
    });
  };

  const toggleSidebar = () => {
    setPanel({
      show: true,
      width: 400,
      backdrop: true,
      children: (
        <BaselineSchedule
          baselines={schedule.baselines}
          timezone={timezone}
          onClose={onCloseSidebar}
        />
      ),
    });
  };

  return (
    <div
      className={classNames('ScheduleToolbar', className)}
      style={{
        backgroundColor: baselineBackgroundColor,
      }}
    >
      <div className="current-schedule">
        {canSchedule ? (
          <div
            className="schedule-button baseline-schedule"
            onClick={toggleSidebar}
          >
            <VibeIcon
              className="schedule-icon"
              icon={viCalendar}
              color={color.white}
              size={16}
            />

            Schedule
          </div>
        ) : null}

        {canSchedule ? (
          <ScheduleOverride
            companyId={currentLocation.companyId}
            locationId={currentLocation._id}
            musicOverride={musicOverride}
            musicOverrideSchedule={musicOverrideSchedule}
            onToggleMusicOverride={onGetSchedule}
            // onOpenEditOverride={this.onOpenEditOverride}
            // onCloseEditOverride={this.onCloseEditOverride}
          />
        ) : null}

        <div className="current-baseline-text">
          {currBaseline.name}
        </div>

        <div className="baseline-ad-program-text">
          {baselineAdProgramText}
        </div>
      </div>
    </div>
  );
}

ScheduleToolbar.propTypes = {
  className: PropTypes.string,
  schedule: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  musicOverride: PropTypes.bool,
  musicOverrideSchedule: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  timezone: PropTypes.string,
  viewAds: PropTypes.bool,
  onGetSchedule: PropTypes.func,
};

ScheduleToolbar.defaultProps = {
  className: '',
  schedule: {},
  musicOverride: false,
  musicOverrideSchedule: {},
  timezone: null,
  viewAds: false,
  onGetSchedule: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleToolbar);
