import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { API } from 'vibeguide';
import './NotificationItem.scss';

class NotificationItem extends Component {
  constructor(props) {
    super(props);

    const {
      notification: {
        readDate,
      },
    } = props;

    this.notificationLink = this.getLink();

    this.state = {
      isRead: readDate !== undefined,
    };

    moment.updateLocale('en', {
      relativeTime: {
        future: '%s',
        past: '%s ago',
        s: 'just now',
        ss: '%d seconds',
        m: '%d min',
        mm: '%d min',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    });
  }

  componentDidUpdate() {
    const {
      notification: {
        readDate,
      },
    } = this.props;
    const {
      isRead,
    } = this.state;

    if (readDate !== undefined && !isRead) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isRead: true });
    }
  }

  onClick = async () => {
    const {
      notification,
      onMarkRead,
    } = this.props;

    const {
      isRead,
    } = this.state;

    console.warn('Notification', notification);

    if (!isRead) {
      this.setState({
        isRead: true,
      });

      onMarkRead();

      await API.Notification.markRead(notification._id);
    }
  };

  getLink = () => {
    const {
      notification: {
        // documentId,
        documentType,
      },
    } = this.props;

    let link;

    switch (documentType) {
      case 'MESSAGEREQUEST':
        link = '/messages/requests';
        break;

      case 'LOCATIONIMPORT':
        break;

      default:
        console.warn('Unknown documentType', documentType);
        break;
    }

    return link;
  };

  render() {
    const {
      notification,
    } = this.props;

    const {
      isRead,
    } = this.state;

    const fromNow = new moment(notification.createdDate).fromNow(true);

    const contentHtml = (
      <div
        className="NotificationItem"
        onClick={this.onClick}
      >
        <div className="notification-header">
          {!isRead ? (
            <div className="unread-circle" />
          ) : null}

          <div className={`notification-title ${!isRead ? 'unread' : ''}`}>
            {notification.text}
          </div>
          <div className="notification-date">
            {fromNow}
          </div>
        </div>
      </div>
    );

    const notificationHtml = this.notificationLink ? (
      <Link
        className="notification-link"
        to={this.notificationLink}
      >
        {contentHtml}
      </Link>
    ) : contentHtml;

    return notificationHtml;
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    createdDate: PropTypes.string,
    documentId: PropTypes.string,
    documentType: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  onMarkRead: PropTypes.func,
};

NotificationItem.defaultProps = {
  onMarkRead: () => {},
};

export default NotificationItem;
