import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  NavigationHelper,
  GlobalActions,
  ToastActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  Assignments,
  VibeModal,
  VibeButtonNew,
  VibeTooltip,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  color,
} from 'vibeguide';
import Information from './Information';
import TargetGroupLocations from './TargetGroupLocations';
import './TargetGroupDetails.scss';

function TargetGroupDetails({
  className,
  style,
  targetGroup,
  user,
  setPanel,
  queueToast,
  onUpdate,
}) {
  const [confirmArchive, setConfirmArchive] = useState(false);
  const history = useNavigate();
  const exceedsLocations = user.exceedsLocations(targetGroup.locationSpec);

  /**
   * Get the warning message for the locations assigned (if disabled)
   */
  const getLocationWarning = () => {
    const warning = [];

    if (!exceedsLocations) {
      warning.push('You do not have access to all the locations assigned to this object');
    }

    if (!targetGroup.mediaFormat) {
      warning.push('You must choose a media format before selecting locations');
    }

    return warning.join(' ');
  };

  /**
   * When the user clicks Locations Assigned
   */
  const onClickLocations = () => {
    // only allow location changes when if the flight is uconfirmed (or no flight)
    const allowChanges = (targetGroup._id && user.can('target_group.modify'))
      || (!targetGroup._id && user.can('target_group.create'));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <TargetGroupLocations
            locations={targetGroup.locationSpec}
            locationsData={targetGroup.locationsData}
            mediaFormat={targetGroup.mediaFormat}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  /**
   * Archive the item
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * When the archive modal is closed
   */
  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * Archive the item
   */
  const onClickUnarchive = async () => {
    await API.TargetGroup.reactivate({
      _id: targetGroup._id,
    });

    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveTargetGroup'));
  };

  /**
   * When the archive modal is confirmed
   */
  const onConfirmArchive = async () => {
    await API.TargetGroup.deactivate(targetGroup._id);

    onCloseArchive();
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveTargetGroup'));
  };

  const onSave = async () => {
    const data = {
      name: targetGroup.name,
      mediaFormat: targetGroup.mediaFormat,
      locations: targetGroup.locations,
    };

    if (targetGroup._id) {
      // editing an object
      data._id = targetGroup._id;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = targetGroup._id
        ? await API.TargetGroup.update(data)
        : await API.TargetGroup.create(data);

      const targetGroupId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = data._id
        ? responseType === 'TARGETGROUP.UPDATED'
        : responseType === 'TARGETGROUP.CREATED';

      if (success) {
        // Successfully saved the object
        const saveMessage = data._id
          ? 'Target Group Updated!'
          : 'Target Group Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSaveTargetGroup'));

        // creating a new object, redirect to the proper URL
        if (!targetGroup._id) {
          const redirectUrl = NavigationHelper.updateParams({
            targetGroupId,
            type: null,
          });

          history(redirectUrl);
        }
      } else {
        console.error('Error saving target group to API', response);

        queueToast({
          type: 'error',
          title: 'Error Saving Target Group',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving target group', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Target Group',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  const disableSave = !targetGroup.name;

  const disableInput = (targetGroup._id && !user.can('target_group.modify'))
    || (!targetGroup._id && !user.can('target_group.create'));

  const showArchive = user.can('target_group.delete');
  const isArchived = !targetGroup.active;

  return (
    <SidePanelContainer
      className={classNames('TargetGroupDetails', className)}
      style={style}
    >
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="title">
          <VibeTooltip title={targetGroup.name || 'New Target Group'}>
            <span>
              {targetGroup._id
                ? targetGroup.name
                : 'New Target Group'}
            </span>
          </VibeTooltip>
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <Information
          name={targetGroup.name}
          mediaFormat={targetGroup.mediaFormat}
          locationSpec={targetGroup.locationSpec}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />

        <Assignments
          items={[{
            label: 'Locations Assigned',
            count: targetGroup.locationSpec.length,
            required: true,
            // media format must be selected (and the objects location scope doesn't exceed the users)
            disabled: !exceedsLocations || !targetGroup.mediaFormat,
            warning: getLocationWarning(),
            onClick: exceedsLocations && targetGroup.mediaFormat
              ? onClickLocations
              : null,
          }]}
        />
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButtonNew
          text="Save Target Group"
          style={{
            marginRight: 12,
          }}
          color={color.violetVibe}
          loadingEvent="onSaveTargetGroup"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {targetGroup._id && (
          <div className="toolbar-buttons">
            {showArchive && !isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.fireBrick,
                  }}
                  tooltip="Archive"
                  color={color.fireBrick}
                  size={20}
                  onClick={onClickArchive}
                />
              </div>
            ) : null}

            {showArchive && isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.aquaForest,
                  }}
                  tooltip="Unarchive"
                  color={color.aquaForest}
                  size={20}
                  onClick={onClickUnarchive}
                />
              </div>
            ) : null}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text="Would you like to archive this target group?"
        confirmProps={{
          text: 'Archive',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

TargetGroupDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  targetGroup: PropTypes.object,
  onUpdate: PropTypes.func,
};

TargetGroupDetails.defaultProps = {
  className: '',
  style: {},
  targetGroup: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetGroupDetails);
