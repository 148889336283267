import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  convertDuration,
} from '../../utils/TimeUtil';
import {
  setAudio,
} from '../../actions/Audio/AudioActions';
import VibeIcon from '../VibeIcon/VibeIcon';
import viPlayCircleOutline from '../../icons/viPlayCircleOutline';
import viPauseCircleOutline from '../../icons/viPauseCircleOutline';
import rootColor from '../../sass/color.scss';
import './PlayControl.scss';

class PlayControl extends PureComponent {
  constructor(props) {
    super(props);

    const {
      audioId,
      audioPaused,
      id,
      durationSeconds,
    } = props;

    const audioDuration = convertDuration(durationSeconds, 'm:ss');

    this.state = {
      playing: audioId === id && !audioPaused,
      audioDuration,
      currentDuration: '',
    };

    if (audioId === id) {
      // listen for play control updates if the audio player is playing this item
      document.addEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.addEventListener('onPlayControlPause', this.onPlayControlPause);
      document.addEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      audioId,
      audioPaused,
    } = this.props;

    const {
      audioId: prevAudioId,
    } = prevProps;

    const {
      playing,
    } = this.state;

    if (audioId === id && prevAudioId !== id) {
      // the audio player is playing this item, listen for time updates
      document.removeEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.removeEventListener('onPlayControlPause', this.onPlayControlPause);
      document.removeEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);

      document.addEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.addEventListener('onPlayControlPause', this.onPlayControlPause);
      document.addEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);
    } else if (audioId !== id && prevAudioId === id) {
      // the audio player is no longer playing this item, remove listener
      document.removeEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.removeEventListener('onPlayControlPause', this.onPlayControlPause);
      document.removeEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);

      // reset the duration played label
      this.setState({
        playing: false,
        currentDuration: '',
      });
    } else if (audioId !== id && playing) {
      // the play control had its source updated and is no longer the playing item
      document.removeEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.removeEventListener('onPlayControlPause', this.onPlayControlPause);
      document.removeEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);

      this.setState({
        playing: false,
        currentDuration: '',
      });
    } else if (audioId === id && !audioPaused && !playing) {
      // the play control had its source updated and is currently being played
      document.removeEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.removeEventListener('onPlayControlPause', this.onPlayControlPause);
      document.removeEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);

      document.addEventListener('onPlayControlPlay', this.onPlayControlPlay);
      document.addEventListener('onPlayControlPause', this.onPlayControlPause);
      document.addEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);

      this.setState({
        playing: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onPlayControlPlay', this.onPlayControlPlay);
    document.removeEventListener('onPlayControlPause', this.onPlayControlPause);
    document.removeEventListener('onPlayControlTimeUpdate', this.onPlayControlTimeUpdate);
  }

  onPlayControlPlay = () => {
    const {
      audioId,
      id,
    } = this.props;

    if (audioId === id) {
      this.setState({
        playing: true,
      });
    }
  };

  onPlayControlPause = () => {
    const {
      audioId,
      id,
    } = this.props;

    if (audioId === id) {
      this.setState({
        playing: false,
      });
    }
  };

  onPlayControlTimeUpdate = ({
    detail: currentDuration,
  }) => {
    this.setState({
      currentDuration,
    });
  };

  onPlay = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      id,
      name,
      src,
      playText,
      pauseText,
      setAudio,
    } = this.props;

    setAudio({
      id,
      name,
      src,
      playText,
      pauseText,
    });

    document.dispatchEvent(new Event('onPlayAudio'));
  };

  onPause = (e) => {
    e.preventDefault();
    e.stopPropagation();

    document.dispatchEvent(new Event('onPauseAudio'));
  };

  render() {
    const {
      className,
      color,
      playText,
      pauseText,
      disabled,
      size,
    } = this.props;

    const {
      playing,
      audioDuration,
      currentDuration,
    } = this.state;

    let tooltip = '';

    if (!disabled && playing) {
      tooltip = `${pauseText} (${currentDuration || audioDuration})`;
    } else if (!disabled && !playing) {
      tooltip = `${playText} (${currentDuration || audioDuration})`;
    }

    return (
      <div className={classNames('PlayControl', className)}>
        <VibeIcon
          icon={playing
            ? viPauseCircleOutline
            : viPlayCircleOutline}
          color={color}
          hoverColor={!disabled
            ? rootColor.violetVibe
            : color}
          size={size}
          tooltip={tooltip}
          tooltipProps={{
            placement: 'top',
          }}
          disabled={disabled}
          onClick={playing
            ? this.onPause
            : this.onPlay}
        />
      </div>
    );
  }
}

PlayControl.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  src: PropTypes.string,
  color: PropTypes.string,
  durationSeconds: PropTypes.number,
  playText: PropTypes.string,
  pauseText: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.number,
};

PlayControl.defaultProps = {
  className: '',
  name: '',
  src: '',
  color: '#6d6db5',
  durationSeconds: 0,
  playText: 'Play',
  pauseText: 'Pause',
  disabled: false,
  size: 16,
};

function mapStateToProps(state) {
  return {
    audioId: state.audio.id,
    audioPaused: state.audio.paused,
  };
}

const mapDispatchToProps = {
  setAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayControl);
