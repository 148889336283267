import React, { useState, useEffect } from 'react';
import {
  get,
} from 'lodash';
import {
  API,
} from 'vibeguide';
import SnippetCard from './SnippetCard';
import './SnippetCardList.scss';

function SnippetCardList() {
  const [statusSummary, setStatusSummary] = useState({});

  /**
   * Get the Order Status Summary
   */
  const getOrderStatusSummaries = async () => {
    const response = await API.Order.Reporting.getStatusSummaries();
    setStatusSummary(response);
  };

  useEffect(() => {
    getOrderStatusSummaries();
  }, []);

  return (
    <div className="SnippetCardList">
      <SnippetCard
        title="Live"
        route="live"
        data={get(statusSummary, 'live', '--')}
      />

      <SnippetCard
        title="Pending"
        route="pending"
        data={get(statusSummary, 'pending', '--')}
      />

      <SnippetCard
        title="Awaiting Creative"
        route="awaitingcreative"
        data={get(statusSummary, 'missingCreatives', '--')}
      />

      <SnippetCard
        title="Paused"
        route="paused"
        data={get(statusSummary, 'paused', '--')}
      />

      <SnippetCard
        title="Completed"
        route="completed"
        data={get(statusSummary, 'completed', '--')}
      />

      <SnippetCard
        title="Cancelled"
        route="cancelled"
        data={get(statusSummary, 'cancelled', '--')}
      />
    </div>
  );
}

export default SnippetCardList;
