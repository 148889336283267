import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';
import Message from '../../models/Message';

const baseUrl = `${process.env.VAPI_BASE_URL}/messages`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options) || {};

    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const message = response.data[i];
        response.data[i] = new Message(message);
      }
    }

    return response;
  },
  getById: id => Http.get(`${baseUrl}/${id}`),
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  update: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  changeMessageBlocks: (data, options = {}) => {
    options = addVersion('5.8.0', options);
    return Http.put(`${baseUrl}/${data._id}/messageblocks`, data, options);
  },
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (messages) => Http.delete(baseUrl, {
    messages,
  }),

  // Bulk add tags
  bulkAddTags: (data) => Http.put(`${baseUrl}/tags`, data),
};
