import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  TableTelemetryMediaPlayed,
} from 'vibeguide';

class TelemetryMediaPlayed extends PureComponent {
  constructor(props) {
    super(props);

    this.socketEventsRegistered = false;
  }

  componentDidMount() {
    const {
      socket,
    } = this.props;

    if (socket.connected) {
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketEventsRegistered = true;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      socket,
      device,
    } = this.props;

    const {
      device: prevDevice,
    } = prevProps;

    if (!this.socketEventsRegistered && socket.connected) {
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketEventsRegistered = true;
    }

    if (prevDevice._id && device._id !== prevDevice._id) {
      document.dispatchEvent(new Event('onUpdateTableTelemetryMediaPlayed'));
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    socket.off('VAPI_EVENT', this.onApiEvent);
  }

  onApiEvent = async (e) => {
    switch (e.type) {
      // When a media item is being played
      case 'LOCATION.CAPTURED_MEDIA_PLAYED': {
        // tell listening components to update the table
        document.dispatchEvent(new Event('onUpdateTableTelemetryMediaPlayed'));
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  render() {
    const {
      currentLocation: {
        _id,
      },
      device,
    } = this.props;

    if (!_id || !device._id) {
      return null;
    }

    return (
      <TableTelemetryMediaPlayed
        columns={[
          'Media Name',
          'Timestamp',
          'Device Status',
          'Type',
          'Play Type',
          'Duration',
          'Media ID',
          '...',
        ]}
        fetch={API.Location.Telemetry.Device.getMediaPlayed}
        fetchProps={{
          filters: {
            _id,
            deviceId: device._id,
          },
        }}
        defaultSortBy={{
          label: 'Timestamp',
          attr: 'timestamp',
          direction: 'desc',
        }}
        paginator
        paginatorProps={{
          label: 'Media Items',
          urlPaging: true,
          urlFilters: true,
        }}
      />
    );
  }
}

TelemetryMediaPlayed.propTypes = {
  device: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
  };
}

export default connect(mapStateToProps)(TelemetryMediaPlayed);
