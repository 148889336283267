import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  find,
} from 'lodash';
import {
  API,
} from 'vibeguide';
import DeliverySystemDetails from './DeliverySystemDetails';

function DeliverySystemSidebar({
  className,
  deliverySystemId,
}) {
  const [deliverySystem, setDeliverySystem] = useState({});

  /**
   * Get Delivery Systems
   */
  const getDeliverySystems = async () => {
    const response = await API.Flight.DeliverySystem.list();

    // get the delivery system being referenced in the sidebar
    const deliverySystemItem = find(response, { _id: deliverySystemId });

    if (deliverySystemItem) {
      setDeliverySystem(deliverySystemItem);
    } else {
      // no "active" item found, search inactive
      const response = await API.Flight.DeliverySystem.list({
        filters: {
          active: false,
        },
      });

      // get the delivery system being referenced in the sidebar
      const deliverySystemItem = find(response, { _id: deliverySystemId });
      setDeliverySystem(deliverySystemItem);
    }
  };

  /**
   * Update the delivery system value
   */
  const onUpdate = (data) => {
    setDeliverySystem({
      ...deliverySystem,
      ...data,
    });
  };

  /**
   * Get the segment if the ID changes
   */
  useEffect(() => {
    if (deliverySystemId) {
      getDeliverySystems();
    }
  }, [deliverySystemId]);

  return (
    <div
      className={classNames('DeliverySystemSidebar', className)}
      style={{
        height: '100%',
      }}
    >
      <DeliverySystemDetails
        deliverySystem={deliverySystem}
        onUpdate={onUpdate}
      />
    </div>
  );
}

DeliverySystemSidebar.propTypes = {
  className: PropTypes.string,
  deliverySystemId: PropTypes.string,
};

DeliverySystemSidebar.defaultProps = {
  className: '',
  deliverySystemId: '',
};

export default DeliverySystemSidebar;
