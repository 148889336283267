/**
 * Sort by options for Baselines
 */
export const baselines = [
  {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
  {
    label: 'Activated Locations',
    attr: 'activeLocationCount',
    direction: 'desc',
  },
];

/**
 * Sort by options for Locations
 */
export const locations = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: 'City',
    attr: 'address.city',
  },
  {
    label: 'State',
    attr: 'address.state',
  },
  {
    label: 'Online Status',
    attr: 'playerOnline',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
  },
];

/**
 * Sort by options for Locations from local data
 */
export const locationsLocal = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'City',
    attr: 'city',
  },
  {
    label: 'State',
    attr: 'state',
  },
];

/**
 * Sort by options for Events
 */
export const events = [
  {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
  {
    label: 'Length',
    attr: 'duration',
    direction: 'desc',
  },
];

/**
 * Sort by options for Message Blocks
 */
export const messageBlocks = [
  {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Company',
    attr: 'companyName',
  },
  {
    label: '# of Messages',
    attr: 'messageCount',
    direction: 'desc',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
];

/**
 * Sort by options for Message Requests
 */
export const messageRequests = [
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
  {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Company',
    attr: 'companyName',
    direction: 'asc',
  },
  {
    label: 'Requester',
    attr: 'createdByUserName',
    direction: 'asc',
  },
  {
    label: 'Script Type',
    attr: 'readType',
    direction: 'asc',
  },
];

/**
 * Sort by options for Playlists
 */
export const playlists = [
  {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Genre',
    attr: 'category',
  },
  {
    label: '# of Songs',
    attr: 'musicItemsCount',
  },
  {
    label: 'Duration',
    attr: 'durationSeconds',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
];

/**
 * Sort by options for Mixes
 */
export const mixes = [
  {
    label: 'Name',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
  {
    label: '# of Playlists',
    attr: 'playlistCount',
    direction: 'desc',
  },
];

/**
 * Sort by options for Advertisers
 */
export const advertisers = [
  {
    label: 'Name (A-Z)',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Name (Z-A)',
    attr: 'name',
    direction: 'desc',
  },
];

/**
 * Sort by options for Users
 */
export const users = [
  {
    label: 'Last Name',
    attr: 'lname',
    direction: 'asc',
  },
  {
    label: 'First Name',
    attr: 'fname',
    direction: 'asc',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
    direction: 'desc',
  },
];

/**
 * Sort by options for Companies
 */
export const companies = [
  {
    label: 'Name',
    attr: 'name',
  },
  {
    label: 'Category',
    attr: 'category',
  },
  {
    label: 'Address 1',
    attr: 'address.line1',
  },
  {
    label: 'Address 2',
    attr: 'address.line2',
  },
  {
    label: 'City',
    attr: 'address.city',
  },
  {
    label: 'State',
    attr: 'address.state',
  },
  {
    label: 'Zip',
    attr: 'address.postalCode',
  },
  {
    label: 'Last Updated',
    attr: 'modifiedDate',
  },
];

/**
 * Sort by options for Companies from local data
 */
export const companiesLocal = [
  {
    label: 'Name',
    attr: 'name',
  },
];

/**
 * Sort by options for Tags
 */
export const tags = [
  {
    label: 'Name (A-Z)',
    attr: 'name',
    direction: 'asc',
  },
  {
    label: 'Name (Z-A)',
    attr: 'name',
    direction: 'desc',
  },
];

export default {
  baselines,
  locations,
  events,
  messageBlocks,
  messageRequests,
  playlists,
  mixes,
  advertisers,
  users,
  companies,
  tags,
};
