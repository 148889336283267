import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  API,
  withRouter,
  viShortcut,
} from 'vibeguide';
import { Grid } from '@mui/material';
import OrderStatusCard from '../OrderStatusCard';

function CreativeOrderTables() {
  const [summaries, setSummaries] = useState({});

  /**
   * Get the Order Missing Creatives list
   */
  const getSummaries = async () => {
    const missingCreatives = await API.Order.Reporting.Summaries.Overview.missingCreative();

    setSummaries({
      missingCreatives,
    });
  };

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <Grid container spacing={2} className="CreativeOrderTables">
      <Grid item xs={12}>
        <OrderStatusCard
          title="All Orders Awaiting Creative"
          linkUrl="../../overview/awaitingcreative"
          icon={viShortcut}
          iconStyles={{
            transform: 'scaleY(-1)',
          }}
          tableData={{
            hideColumn: false,
            columns: ['name', 'status', 'deliveredImpressions', 'startDate', 'endDate'],
            data: get(summaries, 'missingCreatives', []),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(CreativeOrderTables);
