import { createAction } from 'redux-actions';
import {
  get,
} from 'lodash';
import {
  setModal,
} from '../Global/GlobalActions';
import API from '../../api';

export const setImportLocationsId = createAction('SET_IMPORT_LOCATIONS_ID');
export const setBulkImage = createAction('SET_IMPORT_LOCATIONS_BULK_IMAGE');
export const setData = createAction('SET_IMPORT_LOCATIONS_DATA');
export const setCompany = createAction('SET_IMPORT_LOCATIONS_COMPANY');
export const setUserAccountPermissionSet = createAction('SET_IMPORT_LOCATIONS_USER_ACCOUNT_PERMISSION_SET');
export const setTags = createAction('SET_IMPORT_LOCATIONS_TAGS');
export const setBulkInformationConfirmed = createAction('SET_IMPORT_LOCATIONS_BULK_INFORMATION_CONFIRMED');
export const resetImportLocations = createAction('RESET_IMPORT_LOCATIONS');

export function createImportJob() {
  return async (dispatch, getState) => {
    const {
      import: {
        locations: {
          bulkImage,
          companyId,
          userAccountPermissionSet: {
            _id: userAccountPermissionSetId,
          },
          tags,
        },
      },
    } = getState();

    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

    const data = {
      options: {
        companyId,
        tags: modifyTags.map((tag) => {
          return {
            _id: tag._id,
            action: tag.status,
          };
        }),
      },
    };

    // Only include user account permission set ID if selected
    if (userAccountPermissionSetId) {
      data.options.userAccountPermissionSetId = userAccountPermissionSetId;
    }

    const importJobResponse = await API.Location.Import.create(data);

    const importJob = get(importJobResponse, '[0]', {});

    // Job was not created
    if (importJob.type !== 'LOCATIONIMPORT.CREATED') {
      dispatch(setModal({
        message: importJob.type === 'LOCATIONIMPORT.VALIDATION_FAILED'
          ? 'Bulk Import Validation Failed'
          : importJob.type,
        type: 'error',
      }));

      return;
    }

    if (bulkImage) {
      await API.Location.Import.uploadImage(importJob.documentId, bulkImage);
    }

    dispatch(setImportLocationsId(importJob.documentId));
    dispatch(setBulkInformationConfirmed(true));
  };
}

export default {
  createImportJob,
  setImportLocationsId,
  setBulkImage,
  setData,
  setCompany,
  setUserAccountPermissionSet,
  setTags,
  setBulkInformationConfirmed,
  resetImportLocations,
};
