import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  get,
} from 'lodash';
import {
  API,
  SidePanelTabs,
  Copyable,
  TargetTagsList,
  DeviceAttributes,
  withRouter,
} from 'vibeguide';
import './TargetTags.scss';

class TargetTags extends PureComponent {
  constructor(props) {
    super(props);

    this.tagsFetched = false;

    this.state = {
      companyTag: '',
      locationTag: '',
      customTags: [],
    };
  }

  componentDidMount() {
    this.getLocationTargetTags();
  }

  componentDidUpdate() {
    if (!this.tagsFetched) {
      this.getLocationTargetTags();
    }
  }

  /**
   * Get Location Target Tags
   */
  getLocationTargetTags = async () => {
    const {
      currentLocation = {},
      params: {
        type,
      },
    } = this.props;

    if (!get(currentLocation, '_id', null)) {
      return;
    }

    // Only fetch target tags for the Triton sub-tab
    if (type === 'triton' || !type) {
      const targetTags = await API.Location.getTargetTags(currentLocation._id);
      this.tagsFetched = true;

      const {
        companyTag,
        locationTag,
        customTags,
      } = targetTags;

      this.setState({
        companyTag,
        locationTag,
        customTags,
      });
    }
  };

  render() {
    const {
      currentLocation,
      params: {
        type,
      },
      user,
    } = this.props;

    const {
      companyTag,
      locationTag,
      customTags,
    } = this.state;

    if (!currentLocation) {
      return null;
    }

    const isTriton = type === 'triton' || !type;

    return (
      <div className="TargetTags">
        <div className="content-header">
          <div className="content-title">
            Ad Targeting
          </div>

          {isTriton ? (
            <div className="location-target-tags">
              <Copyable
                className="location-tag"
                displayText="COMPANY ID"
                copyText={companyTag}
              />

              <Copyable
                className="location-tag"
                displayText="LOCATION ID"
                copyText={locationTag}
              />
            </div>
          ) : null}
        </div>

        <SidePanelTabs
          className="panel-tabs"
          tabs={[{
            name: 'Triton Target Tags',
            link: `/location/${currentLocation._id}/targeting/triton`,
            disabled: !user.can('location.assign_targeting_tag'),
            matchType: 'triton',
            selected: type === 'triton' || !type,
          },
          {
            name: 'Vistar Device Attributes',
            link: `/location/${currentLocation._id}/targeting/vistar`,
            disabled: !user.can('location.assign_device_attribute'),
            matchType: 'vistar',
            selected: type === 'vistar',
          }]}
        />

        <div className="targeting-panels">
          {this.tagsFetched && (type === 'triton' || !type) ? (
            <TargetTagsList
              location={currentLocation}
              locationTags={customTags}
              panelLocationList
              allowCreate
              allowCopy
              allowRemove
            />
          ) : null}

          {type === 'vistar' ? (
            <DeviceAttributes
              type="location"
              locationId={currentLocation._id}
              allowAssign={user.can('location.assign_device_attribute') && user.can('device_attribute.view')}
              allowCreate={user.can('device_attribute.create')}
              allowModify={user.can('device_attribute.modify')}
              allowRemove={user.can('device_attribute.delete')}
              allowCopy
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default withRouter(connect(mapStateToProps)(TargetTags));
