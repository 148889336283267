import { createAction } from 'redux-actions';

export const setMessageReqData = createAction('SET_MESSAGE_REQ_DATA');
export const addLocation = createAction('ADD_MESSAGE_REQ_LOCATION');
export const addLocations = createAction('ADD_MESSAGE_REQ_LOCATIONS');
export const removeLocation = createAction('REMOVE_MESSAGE_REQ_LOCATION');
export const removeAllLocations = createAction('REMOVE_MESSAGE_REQ_ALL_LOCATIONS');
export const setAllLocations = createAction('SET_MESSAGE_REQ_ALL_LOCATIONS');
export const setMessageName = createAction('SET_MESSAGE_REQ_NAME');
export const setMessageScript = createAction('SET_MESSAGE_REQ_SCRIPT');
export const setMessageCompany = createAction('SET_MESSAGE_REQ_COMPANY');
export const setMessageAdvertiser = createAction('SET_MESSAGE_REQ_ADVERTISER');
export const setMessageInstructions = createAction('SET_MESSAGE_REQ_INSTRUCTIONS');
export const setMessageStartDate = createAction('SET_MESSAGE_REQ_START_DATE');
export const setMessageEndDate = createAction('SET_MESSAGE_REQ_END_DATE');
export const setMessageBlockIds = createAction('SET_MESSAGE_REQ_BLOCKS');
export const setTags = createAction('SET_MESSAGE_REQ_TAGS');
export const addTag = createAction('ADD_MESSAGE_REQ_TAG');
export const removeTag = createAction('REMOVE_MESSAGE_REQ_TAG');
export const toggleMessageHelp = createAction('SET_MESSAGE_REQ_HELP');
export const togglePublishAutomatically = createAction('SET_MESSAGE_REQ_PUBLISH');
export const setMessageFile = createAction('SET_MESSAGE_REQ_FILE');
export const addMessageBlock = createAction('NEW_MESSAGE_ADD_MESSAGE_BLOCK');
export const removeMessageBlock = createAction('NEW_MESSAGE_REMOVE_MESSAGE_BLOCK');
export const resetNewMessageRequest = createAction('RESET_NEW_MESSAGE_REQ');

export default {
  setMessageReqData,
  addLocation,
  addLocations,
  removeLocation,
  removeAllLocations,
  setAllLocations,
  setMessageName,
  setMessageScript,
  setMessageCompany,
  setMessageAdvertiser,
  setMessageInstructions,
  setMessageStartDate,
  setMessageEndDate,
  setMessageBlockIds,
  setTags,
  addTag,
  removeTag,
  toggleMessageHelp,
  togglePublishAutomatically,
  setMessageFile,
  addMessageBlock,
  removeMessageBlock,
  resetNewMessageRequest,
};
