import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  convertDuration,
} from '../../utils/TimeUtil';
import API from '../../api';
import TagList from '../Tags/TagList';
import VibeModal from '../VibeModal/VibeModal';
import VibeCard from '../VibeCard/VibeCard';
import VibeIcon from '../VibeIcon/VibeIcon';
import viMusic from '../../icons/viMusic';
import viTime from '../../icons/viTime';
import defaultPlaylistImage from '../../assets/default_playlist.png';
import color from '../../sass/color.scss';
import './PlaylistCard.scss';

class PlaylistCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmArchive: false,
    };
  }

  /**
   * When the swap button is clicked
   */
  onClickSwapButton = () => {
    const {
      playlist,
      onSwap,
    } = this.props;

    onSwap(playlist);
  };

  /**
   * When a Menu Item is Selected
   */
  onSelectMenuItem = async (name) => {
    const {
      playlist: {
        _id,
      },
      onArchive,
    } = this.props;

    if (name === 'archive') {
      this.setState({
        showConfirmArchive: true,
      });
    } else if (name === 'unarchive') {
      const response = await API.Playlist.reactivate({
        _id,
      });
      const isSuccess = get(response, '[0].documentId', null);

      if (isSuccess) {
        onArchive(_id);
      }
    }
  };

  /**
   * Confirm Archive Message
   */
  onConfirmArchive = async (e) => {
    e.stopPropagation();

    const {
      playlist: {
        _id,
      },
      onArchive,
    } = this.props;

    const response = await API.Playlist.deactivate(_id);
    const isSuccess = get(response, '[0].documentId', null);

    if (isSuccess) {
      onArchive(_id);
    }

    this.setState({
      showConfirmArchive: false,
    });
  };

  /**
   * Cancel Archive Message
   */
  onCancelArchive = () => {
    this.setState({
      showConfirmArchive: false,
    });
  };

  toggleCard = () => {
    const {
      playlist: {
        _id,
      },
      toggleSelected,
      selected,
      disabled,
      allowClick,
      onClick,
    } = this.props;

    onClick(_id);

    if (!disabled && allowClick) {
      // Send selected state back to the parent component
      toggleSelected(_id, !selected);
    }
  };

  render() {
    const {
      className = '',
      disabled,
      selected,
      allowClick,
      playlist,
      swap,
      swapDirection,
    } = this.props;

    const {
      showConfirmArchive,
    } = this.state;

    const {
      name,
      category,
      description,
      tags,
      imageUrl,
      musicItemsCount,
      durationSeconds,
    } = playlist;

    const isSelected = selected;
    const duration = convertDuration(durationSeconds, '0h 0m');

    return (
      <div className="PlaylistCard">
        <VibeCard
          className={`card-container ${className}`}
          width={350}
          height={252}
          selected={isSelected}
          disabled={disabled}
          swap={swap}
          swapDirection={swapDirection}
          onClickSwapButton={this.onClickSwapButton}
          onClick={this.toggleCard}
          onSelectMenuItem={this.onSelectMenuItem}
          allowClick={allowClick}
        >
          <div className="playlist-content">
            <div className="playlist-image-container">
              {imageUrl ? (
                <img src={imageUrl} className="playlist-image" alt="profile" />
              ) : (
                <img className="playlist-image" src={defaultPlaylistImage} alt="Playlist" />
              )}
            </div>

            <div className="playlist-info">
              <div className="name">
                {name}
              </div>
              <div className="category">
                {category}
              </div>
              <div className="song-count">
                <VibeIcon
                  icon={viMusic}
                  color={color.manatee}
                  size={12}
                  style={{
                    marginRight: 8,
                  }}
                />
                {musicItemsCount}
              </div>
              <div className="duration">
                <VibeIcon
                  icon={viTime}
                  color={color.manatee}
                  size={12}
                  style={{
                    marginRight: 8,
                  }}
                />
                {duration}
              </div>
            </div>
          </div>
          <div className="description">
            {description}
          </div>

          <div className="tags-container">
            <TagList
              tags={tags}
              style={{
                margin: '0 16px',
              }}
            />
          </div>
        </VibeCard>

        <VibeModal
          show={showConfirmArchive}
          type="confirm"
          title="Archive"
          text={(
            <div>
              Are you sure you want to archive
              <br />
              <strong>{name}</strong>?
              <br /> <br />
              <span className="italics">
                This playlist will continue to play at currently
                <br />scheduled locations until it is unscheduled
                <br />and you will not be able to add it to any
                <br />future schedules.
              </span>
            </div>
          )}
          confirmProps={{
            text: 'Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );
  }
}

PlaylistCard.propTypes = {
  className: PropTypes.string,
  /**
   * Object that describes the playlist that's being displayed
   */
  playlist: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      active: PropTypes.bool,
      name: PropTypes.string,
      type: PropTypes.string,
    })),
    category: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    description: PropTypes.string,
    durationSeconds: PropTypes.number,
    imageUrl: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    musicItemsCount: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  allowClick: PropTypes.bool,
  /** Show swap icon */
  swap: PropTypes.bool,
  /** Swap direction */
  swapDirection: PropTypes.oneOf([
    'left',
    'right',
  ]),
  toggleSelected: PropTypes.func,
  onClick: PropTypes.func,
  /** When a card is archived */
  onArchive: PropTypes.func,
  /** When the swap button is clicked */
  onSwap: PropTypes.func,
};

PlaylistCard.defaultProps = {
  className: '',
  disabled: false,
  selected: false,
  allowClick: false,
  swap: false,
  swapDirection: null,
  toggleSelected: () => {},
  onClick: () => {},
  onArchive: () => {},
  onSwap: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(PlaylistCard);
