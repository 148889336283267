import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import color from '../../sass/color.scss';
import './BarChart.scss';

function CustomizedAxisTick({
  // x,
  y,
  // stroke,
  payload,
}) {
  return (
    <g>
      <text
        x={0}
        y={y}
        dy={2}
        textAnchor="start"
        fontSize={10}
        fill={color.obsidian}
      >
        {payload.value}
      </text>
    </g>
  );
}

function BarChart({
  title,
  width,
  height,
  style,
  data,
  onClick,
  barColor = color.fireBrick,
}) {
  return (
    <div
      className="BarChart"
      style={style}
    >
      {title ? (
        <div className="title">
          {title}
        </div>
      ) : null}

      <ReBarChart
        width={width}
        height={height}
        data={data}
        layout="vertical"
        barSize={12}
        onClick={onClick}
      >
        <CartesianGrid
          strokeDasharray="0 0"
          horizontal={false}
        />

        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: 10,
            marginTop: 5,
          }}
        />

        <YAxis
          type="category"
          dataKey="name"
          width={60}
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisTick />}
        />

        <Tooltip />

        <Bar
          dataKey="value"
          fill={barColor}
          stroke={barColor}
          animationDuration={2000}
        />
      </ReBarChart>
    </div>
  );
}

BarChart.propTypes = {
  /** Title of chart */
  title: PropTypes.string,
  /** Chart size */
  width: PropTypes.number,
  height: PropTypes.number,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Chart data */
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  /** When the chart is clicked */
  onClick: PropTypes.func,
};

BarChart.defaultProps = {
  title: null,
  width: 425,
  height: 174,
  style: {},
  onClick: () => {},
};

export default BarChart;
