import moment from 'moment';
import {
  get,
  find,
  findIndex,
  last,
  uniq,
  clone,
  sortBy,
} from 'lodash';
import {
  getMinutes,
  getDayFromIndex,
} from '../utils/TimeUtil';
import {
  compare,
} from './Location';
import API from '../api';

let cacheBaselines = [];
let cacheEvents = [];
let contentRefs = [];

/**
 * Adjust the start time of a days items
 */
function adjustStartTime(items, startTime) {
  const newItems = [];
  const newStartTimeMinutes = getMinutes(startTime);

  (items || []).forEach((item) => {
    item = clone(item);

    const startTimeMinutes = getMinutes(item.startTime);
    const stopTimeMinutes = getMinutes(item.endTime);

    if (!item.endTime) {
      // Treat item as an interrupt
      if (startTimeMinutes >= newStartTimeMinutes) {
        // include the interrupt
        newItems.push(item);
      }

      return;
    }

    if (newStartTimeMinutes >= stopTimeMinutes) {
      // new start time is after the scheduled stop time. Do not include.
      return;
    }

    if (startTimeMinutes < newStartTimeMinutes) {
      // Item starts before the forced start time
      // Store the original start time
      item.startTimeOriginal = item.startTime;
      // Set the new start time
      item.startTime = startTime;
    }

    newItems.push(item);
  });

  return newItems;
}

/**
 * Adjust the stop time of a days items
 */
function adjustStopTime(items, stopTime) {
  const newItems = [];
  const newStopTimeMinutes = getMinutes(stopTime);

  (items || []).forEach((item) => {
    item = clone(item);

    const startTimeMinutes = getMinutes(item.startTime);
    const stopTimeMinutes = item.endTime
      ? getMinutes(item.endTime)
      // treat as an interrupt and get the start time
      : getMinutes(item.startTime);

    if (!item.endTime) {
      // Treat item as an interrupt
      if (stopTimeMinutes < newStopTimeMinutes) {
        // include the interrupt
        newItems.push(item);
      }

      return;
    }

    if (newStopTimeMinutes <= startTimeMinutes) {
      // new stop time is before the scheduled start time. Do not include.
      return;
    }

    if (stopTimeMinutes > newStopTimeMinutes) {
      // Item stops after the forced stop time
      // Store the original stop time
      item.stopTimeOriginal = item.endTime;
      // Set the new stop time
      item.endTime = stopTime;
    }

    newItems.push(item);
  });

  return newItems;
}

/**
 * Fetch multiple events for the week from the cache
 * Or fetch details and add to the cache
 */
async function getEvents({
  events,
}) {
  // Baselines to add to the cache
  const addEventsToCache = [];

  // Deduped list of baseline IDs
  const eventIds = uniq(events.map(event => event._id));

  const fetchedEvents = await Promise.all(eventIds.map(async (eventId) => {
    // Does the event exist in the cache?
    const cacheEvent = find(cacheBaselines, { _id: eventId });

    if (cacheEvent) {
      return Promise.resolve(cacheEvent);
    }

    // Event is not cached and needs the necessary week content
    const eventDetails = await API.Event.getById(eventId);
    addEventsToCache.push(eventDetails);
    return Promise.resolve(eventDetails);
  }));

  const returnEvents = events.map((event) => {
    // Get the event from the fetched/cache list
    const fetchedEvent = find(fetchedEvents, { _id: event._id });

    return {
      ...event,
      ...fetchedEvent,
    };
  });

  if (addEventsToCache.length > 0) {
    // Add new events to the cache
    cacheEvents = [
      ...cacheEvents,
      ...addEventsToCache,
    ];
  }

  // Order by baseline start date to ensure being shown properly on the week view
  return returnEvents;
}

/**
 * Fetch multiple baselines for the week from the cache
 * Or fetch details and add to the cache
 */
async function getBaselines({
  baselines,
  timezone,
}) {
  // Baselines to add to the cache
  const addBaselinesToCache = [];
  const contentRefCache = [];

  // Deduped list of baseline IDs
  const baselineIds = uniq(baselines.map(baseline => baseline._id));

  const fetchedBaselines = await Promise.all(baselineIds.map(async (baselineId) => {
    // Does the baseline exist in the cache?
    const cacheBaseline = find(cacheBaselines, { _id: baselineId });

    if (cacheBaseline) {
      return Promise.resolve(cacheBaseline);
    }

    // Baseline is not cached and needs the necessary week content
    const baselineDetails = await API.Baseline.getById(baselineId);
    const {
      baseline: baselineContent,
      refs,
    } = baselineDetails;
    addBaselinesToCache.push(baselineContent);
    const refWithBaselineId = {
      baselineId: baselineContent._id,
      ...refs,
    };
    contentRefCache.push(refWithBaselineId);

    return Promise.resolve(baselineContent);
  }));

  const returnBaselines = baselines.map((baseline) => {
    // Add start date as a moment object
    const startOn = new moment(baseline.startDate).tz(timezone);
    // Get the baseline from the fetched/cache list
    const fetchedBaseline = find(fetchedBaselines, { _id: baseline._id });

    return {
      ...baseline,
      ...fetchedBaseline,
      startOn,
    };
  });

  if (addBaselinesToCache.length > 0) {
    // Add new baselines to the cache
    cacheBaselines = [
      ...cacheBaselines,
      ...addBaselinesToCache,
    ];

    contentRefs = [
      ...contentRefs,
      ...contentRefCache,
    ];
  }

  // Order by baseline start date to ensure being shown properly on the week view
  return sortBy(returnBaselines, 'startDate');
}

/**
 * Get the baselines for a day of the week
 */
function getBaselinesForDay({
  dayIndex,
  weekBaselines,
  startOfWeek,
}) {
  // Get day of week
  const day = startOfWeek.clone().add(dayIndex, 'days');

  // Get baselines that start on this day
  const startOnDayBaselines = weekBaselines
    .filter(baseline => day.format('MM/DD/YYYY') === baseline.startOn.format('MM/DD/YYYY'));

  // Get the baseline that starts at midnight for this day (if exists)
  const startAtMidnightBaseline = startOnDayBaselines
    .filter(baseline => day.format('MM/DD/YYYY H:m') === baseline.startOn.format('MM/DD/YYYY H:m'))[0] || null;

  // When no baselines start on this day, or none start at midnight
  // Get the last baseline that started before today
  const prevBaseline = startOnDayBaselines.length <= 0 || !startAtMidnightBaseline
    ? last(weekBaselines.filter(baseline => baseline.startOn.isBefore(day)))
    : null;

  // Baselines that are scheduled on this day
  let dayBaselines = [];

  if (prevBaseline) {
    dayBaselines.push(prevBaseline);
  }

  dayBaselines = [
    ...dayBaselines,
    ...startOnDayBaselines,
  ];

  return dayBaselines;
}

/**
 * Get the ad program for a day of the week
 */
function getAdProgramForDay({
  dayIndex,
  weekAdPrograms,
  startOfWeek,
}) {
  // Get day of week
  const day = startOfWeek.clone().add(dayIndex, 'days');

  const adProgram = find(weekAdPrograms, (adProgram) => {
    // the ad program end date is excluded (11:59:59 PM on the previous day)
    return day.isBetween(adProgram.startDate, adProgram.endDate, undefined, '[)');
  });

  return adProgram || {};
}

/**
 * Get the archived baselines for a specific day
 */
function getArchivedBaselines(dayBaselines) {
  return dayBaselines.filter(baseline => !baseline.active);
}

/**
 * Get the content for each baseline for the day index
 */
function getBaselineDayContent({
  dayIndex,
  dayBaselines,
  musicOverride,
  musicOverrideSchedule,
}) {
  const content = [];

  dayBaselines.forEach((baseline, index) => {
    // Get the baseline from the cache to give it all the details it needs
    const cacheBaseline = find(cacheBaselines, { _id: baseline._id });
    // Get the content for the specified day in the baseline
    const day = clone(get(cacheBaseline, `days[${dayIndex}]`));

    if (!day) {
      console.warn('getBaselineDayContent: day not found');
      return;
    }

    // is there another baseline scheduled to start before this one today?
    const prevBaselineForDay = get(dayBaselines, `[${index - 1}]`, null);
    // is there another baseline scheduled to start after this one today?
    const nextBaselineForDay = get(dayBaselines, `[${index + 1}]`, null);

    if (prevBaselineForDay) {
      // There is another baseline scheduled before this one for this day
      // Force all items to start when the baseline starts
      const forceStartTime = baseline.startOn.format('H:mm');

      if (!musicOverride) {
        // do not adjust music for the baseline if the music schedule is being overridden
        day.musicParts = adjustStartTime(day.musicParts, forceStartTime);
      }
      day.messageParts = adjustStartTime(day.messageParts, forceStartTime);
      day.interrupts = adjustStartTime(day.interrupts, forceStartTime);
    }

    if (nextBaselineForDay) {
      // There is another baseline scheduled after this one for this day
      // Force all items to stop when the next baseline starts
      const forceStopTime = nextBaselineForDay.startOn.format('H:mm');

      if (!musicOverride) {
        // do not adjust music for the baseline if the music schedule is being overridden
        day.musicParts = adjustStopTime(day.musicParts, forceStopTime);
      }
      day.messageParts = adjustStopTime(day.messageParts, forceStopTime);
      day.interrupts = adjustStopTime(day.interrupts, forceStopTime);
    }

    // only store the music parts for the first baseline on the day if the music is being overridden
    // the musicParts do not change if the baseline changes when the music is being overrideen
    if (musicOverride && index === 0) {
      // Add the music from the current scheduled baseline which is the overridden music
      day.musicParts = get(musicOverrideSchedule, `scheduleOverride.days[${dayIndex}].musicParts`, []);
    } else if (musicOverride && index !== 0) {
      // music override does not stop when the baseline changes
      day.musicParts = [];
    }

    // Store the baseline ID
    day.baselineId = baseline._id;
    // Store the day name for the content
    day.day = getDayFromIndex(dayIndex, 'ddd');
    // Add the start date to know when to cut-off the content in the calendar
    day.startOn = baseline.startOn;

    content.push(day);
  });

  return content;
}

/**
 * Get the baseline separators for a day of the week
 */
function getBaselineSeparators({
  dayIndex,
  baselines,
  weekBaselines,
  startOfWeek,
}) {
  // Get day of week
  const day = startOfWeek.clone().add(dayIndex, 'days');
  const dayMMDDYYYY = day.format('MM/DD/YYYY');
  const baselineSeparators = [];

  // Determine if there are baselines that happen mid-day
  baselines.forEach((baseline) => {
    const {
      startOn,
    } = baseline;

    if (dayMMDDYYYY === startOn.format('MM/DD/YYYY') && startOn.format('HH:mm') !== '00:00') {
      // baseline starts sometime during this day that isn't midnight
      // Where is this baseline in the week baseline list
      const index = findIndex(weekBaselines, { startDate: baseline.startDate });

      baselineSeparators.push({
        day: startOn.format('ddd'),
        oldBaselineName: get(weekBaselines, `[${index - 1}].name`, 'Unknown'),
        newBaselineName: baseline.name,
        startDate: baseline.startDate,
        startTime: startOn.format('H:mm'),
      });
    }
  });

  return baselineSeparators;
}

/**
 * Get the current date
 */
export function getCurrentDate(timezone) {
  return moment().tz(timezone);
}

/**
 * Get the baselines for the week
 */
export async function getBaselinesForWeek({
  baselines,
  startOfWeek,
  endOfWeek,
  timezone,
  useCache,
}) {
  // Get baselines that start at midnight
  const midnightBaselines = {};
  const weekBaselines = baselines.filter(baseline => endOfWeek.isSameOrAfter(baseline.startDate)
    && startOfWeek.isSameOrBefore(baseline.startDate),
  );

  // Get the baseline that starts at midnight for the start of the week (Sunday, if exists)
  const startOfWeekMidnightBaseline = weekBaselines
    .filter(baseline => startOfWeek
      .format('MM/DD/YYYY H:m') === moment(baseline.startDate)
      .format('MM/DD/YYYY H:m'))[0] || null;

  // Get the last baseline from the previous week if a new baseline doesn't start at the beginning of the week
  const prevBaseline = !startOfWeekMidnightBaseline
    ? last(baselines.filter(baseline => startOfWeek.isAfter(baseline.startDate)))
    : null;

  if (prevBaseline) {
    // Add the previous week final baseline to this weeks list of baselines
    weekBaselines.unshift(prevBaseline);
  }

  // if not using the cache, clear any cached baselines
  if (!useCache) {
    cacheBaselines = [];
    contentRefs = [];
  }

  // Baselines to return from the cache
  const cacheWeekBaselines = await getBaselines({
    baselines: weekBaselines,
    timezone,
  });

  cacheWeekBaselines.forEach((baseline) => {
    const {
      startOn,
    } = baseline;

    const startDay = startOn.format('ddd');

    if (startOn.isSameOrAfter(startOfWeek) && startOn.format('HH:mm') === '00:00') {
      // Baseline starts during the week at midnight
      midnightBaselines[startDay] = baseline;
    }
  });

  return {
    weekBaselines: cacheWeekBaselines,
    midnightBaselines,
  };
}

/**
 * Get the baseline content for all baselines during the week
 */
export function getBaselineContentForWeek({
  weekBaselines,
  // used for the baseline schedule view (no need to fetch baseline data since it already exists)
  addBaselinesToCache = [],
  musicOverride,
  musicOverrideSchedule,
  startOfWeek,
  companyId,
  locationId,
}) {
  // add any sent baseline(s) to the cache to grab content
  addBaselinesToCache.forEach((baseline) => {
    // does the baseline already exist in the cache? Replace it if so
    const exists = find(cacheBaselines, { _id: baseline._id }) !== undefined;

    const refPlaylists = {};
    const refMixes = {};
    const refMessageBlocks = {};
    const refInterrupts = {};

    get(baseline, 'data.playlists', []).forEach((playlist) => {
      refPlaylists[playlist.playlist._id] = playlist.playlist;
    });

    get(baseline, 'data.mixes', []).forEach((mix) => {
      refMixes[mix.mix._id] = mix.mix;
    });

    get(baseline, 'data.messageBlocks', []).forEach((messageBlock) => {
      refMessageBlocks[messageBlock.messageBlock._id] = messageBlock.messageBlock;
    });

    get(baseline, 'data.interruptions', []).forEach((interrupt) => {
      refInterrupts[interrupt.message._id] = interrupt.message;
    });

    if (exists) {
      // already cached, replace the cached baseline with the new one
      cacheBaselines = cacheBaselines.map((cacheBaseline) => {
        if (cacheBaseline._id === baseline._id) {
          // replace the cached baseline with the new one
          return baseline;
        }

        return cacheBaseline;
      });

      // update the content ref for this baseline
      contentRefs = contentRefs.map((contentRef) => {
        if (contentRef.baselineId === baseline._id) {
          // replace the cached baseline with the new one
          return {
            baselineId: baseline._id,
            playlists: {
              ...get(baseline, 'refs.playlists', {}),
              ...refPlaylists,
            },
            mixes: {
              ...get(baseline, 'refs.mixes', {}),
              ...refMixes,
            },
            messageBlocks: {
              ...get(baseline, 'refs.messageBlocks', {}),
              ...refMessageBlocks,
            },
            interrupts: {
              ...get(baseline, 'refs.interrupts', {}),
              ...refInterrupts,
            },
          };
        }

        return contentRef;
      });
    } else {
      // not already cached, add the baseline to the cache
      cacheBaselines.push(baseline);
      // add the content refs to the cache
      contentRefs.push({
        baselineId: baseline._id,
        playlists: {
          ...get(baseline, 'refs.playlists', {}),
          ...refPlaylists,
        },
        mixes: {
          ...get(baseline, 'refs.mixes', {}),
          ...refMixes,
        },
        messageBlocks: {
          ...get(baseline, 'refs.messageBlocks', {}),
          ...refMessageBlocks,
        },
        interrupts: {
          ...get(baseline, 'refs.interrupts', {}),
          ...refInterrupts,
        },
      });
    }
  });

  const overridePlaylistRefs = get(musicOverrideSchedule, 'refs.playlists', []);
  const overrideMixRefs = get(musicOverrideSchedule, 'refs.mixes', []);

  const sunBaselines = getBaselinesForDay({
    dayIndex: 0,
    weekBaselines,
    startOfWeek,
  });
  const monBaselines = getBaselinesForDay({
    dayIndex: 1,
    weekBaselines,
    startOfWeek,
  });
  const tueBaselines = getBaselinesForDay({
    dayIndex: 2,
    weekBaselines,
    startOfWeek,
  });
  const wedBaselines = getBaselinesForDay({
    dayIndex: 3,
    weekBaselines,
    startOfWeek,
  });
  const thuBaselines = getBaselinesForDay({
    dayIndex: 4,
    weekBaselines,
    startOfWeek,
  });
  const friBaselines = getBaselinesForDay({
    dayIndex: 5,
    weekBaselines,
    startOfWeek,
  });
  const satBaselines = getBaselinesForDay({
    dayIndex: 6,
    weekBaselines,
    startOfWeek,
  });

  // Get the archived baselines for a specific day
  const sunArchivedBaselines = getArchivedBaselines(sunBaselines);
  const monArchivedBaselines = getArchivedBaselines(monBaselines);
  const tueArchivedBaselines = getArchivedBaselines(tueBaselines);
  const wedArchivedBaselines = getArchivedBaselines(wedBaselines);
  const thuArchivedBaselines = getArchivedBaselines(thuBaselines);
  const friArchivedBaselines = getArchivedBaselines(friBaselines);
  const satArchivedBaselines = getArchivedBaselines(satBaselines);

  // Merge all the archived baselines (only days with an archived baseline)
  const archivedBaselines = {
    Sun: sunArchivedBaselines,
    Mon: monArchivedBaselines,
    Tue: tueArchivedBaselines,
    Wed: wedArchivedBaselines,
    Thu: thuArchivedBaselines,
    Fri: friArchivedBaselines,
    Sat: satArchivedBaselines,
  };

  // Get the content for this day for each baseline
  const sunContent = getBaselineDayContent({
    dayIndex: 0,
    dayBaselines: sunBaselines,
    musicOverride,
    musicOverrideSchedule,
  });
  const monContent = getBaselineDayContent({
    dayIndex: 1,
    dayBaselines: monBaselines,
    musicOverride,
    musicOverrideSchedule,
  });
  const tueContent = getBaselineDayContent({
    dayIndex: 2,
    dayBaselines: tueBaselines,
    musicOverride,
    musicOverrideSchedule,
  });
  const wedContent = getBaselineDayContent({
    dayIndex: 3,
    dayBaselines: wedBaselines,
    musicOverride,
    musicOverrideSchedule,
  });
  const thuContent = getBaselineDayContent({
    dayIndex: 4,
    dayBaselines: thuBaselines,
    musicOverride,
    musicOverrideSchedule,
  });
  const friContent = getBaselineDayContent({
    dayIndex: 5,
    dayBaselines: friBaselines,
    musicOverride,
    musicOverrideSchedule,
  });
  const satContent = getBaselineDayContent({
    dayIndex: 6,
    dayBaselines: satBaselines,
    musicOverride,
    musicOverrideSchedule,
  });

  // Store baselines that happen mid-day
  const sunSeparators = getBaselineSeparators({
    dayIndex: 0,
    baselines: sunBaselines,
    weekBaselines,
    startOfWeek,
  });
  const monSeparators = getBaselineSeparators({
    dayIndex: 1,
    baselines: monBaselines,
    weekBaselines,
    startOfWeek,
  });
  const tueSeparators = getBaselineSeparators({
    dayIndex: 2,
    baselines: tueBaselines,
    weekBaselines,
    startOfWeek,
  });
  const wedSeparators = getBaselineSeparators({
    dayIndex: 3,
    baselines: wedBaselines,
    weekBaselines,
    startOfWeek,
  });
  const thuSeparators = getBaselineSeparators({
    dayIndex: 4,
    baselines: thuBaselines,
    weekBaselines,
    startOfWeek,
  });
  const friSeparators = getBaselineSeparators({
    dayIndex: 5,
    baselines: friBaselines,
    weekBaselines,
    startOfWeek,
  });
  const satSeparators = getBaselineSeparators({
    dayIndex: 6,
    baselines: satBaselines,
    weekBaselines,
    startOfWeek,
  });

  // Merge all the baseline separators
  const baselineSeparators = [
    ...sunSeparators,
    ...monSeparators,
    ...tueSeparators,
    ...wedSeparators,
    ...thuSeparators,
    ...friSeparators,
    ...satSeparators,
  ];

  // Merge all the content
  const content = [
    ...sunContent,
    ...monContent,
    ...tueContent,
    ...wedContent,
    ...thuContent,
    ...friContent,
    ...satContent,
  ];

  const playlists = [];
  const mixes = [];
  const messageBlocks = [];
  const interruptions = [];

  // group all the normal baseline content for all baselines for the week
  content.forEach((baselineContent) => {
    const {
      baselineId,
      musicParts = [],
      messageParts = [],
      interrupts = [],
      day,
    } = baselineContent;

    // Get the locations for the baseline
    const baseline = find(weekBaselines, { _id: baselineId });
    const refs = find(contentRefs, { baselineId });
    const {
      playlists: playlistRefs,
      mixes: mixRefs,
      messageBlocks: msgBlockRefs,
      interrupts: interruptRefs,
    } = refs;

    musicParts.forEach((music) => {
      // determine which refs to search for playlist/mix data
      const usePlaylistRefs = musicOverride
        ? overridePlaylistRefs
        : playlistRefs;

      const useMixRefs = musicOverride
        ? overrideMixRefs
        : mixRefs;

      if (music.type === 'playlist') {
        const playlistDetail = find(usePlaylistRefs, { _id: music.musicId });

        playlists.push({
          playlist: playlistDetail,
          startTime: music.startTime,
          stopTime: music.endTime,
          day,
        });
      } else if (music.type === 'mix') {
        const mixDetail = find(useMixRefs, { _id: music.musicId });

        mixes.push({
          mix: mixDetail,
          startTime: music.startTime,
          stopTime: music.endTime,
          day,
        });
      }
    });

    messageParts.forEach((messageBlock) => {
      const mbDetail = find(msgBlockRefs, ref => messageBlock.messageListId === ref._id);
      if (get(mbDetail, 'messages.length', 0) > 0) {
        mbDetail.messages = sortBy(mbDetail.messages, 'sequence');
      }

      messageBlocks.push({
        messageBlock: mbDetail || {},
        startTime: messageBlock.startTime,
        stopTime: messageBlock.endTime,
        day,
      });
    });

    interrupts.forEach((interrupt) => {
      const interruptDetail = find(interruptRefs, ref => interrupt.messageId === ref._id);
      const interruptLocations = get(interruptDetail, 'locations', []);
      const compareResult = compare(baseline.locations, interruptLocations);
      // Determine if the interrupt has access to all the locations for this object
      const accessAllLocations = compareResult.parent.missing.length <= 0;
      // Determine if the interrupt has access to this location
      const accessCurrentLocation = accessAllLocations
        || find(interruptLocations, { companyId, locationId }) !== undefined
        || find(interruptLocations, { companyId, locationId: '*' }) !== undefined;

      interruptions.push({
        startTime: interrupt.startTime,
        message: interruptDetail,
        accessAllLocations,
        accessCurrentLocation,
        day,
      });
    });
  });

  return {
    baselineSeparators: sortBy(baselineSeparators, 'startTime'),
    archivedBaselines,
    playlists: sortBy(playlists, 'startTime'),
    mixes: sortBy(mixes, 'startTime'),
    messageBlocks: sortBy(messageBlocks, 'startTime'),
    interruptions: sortBy(interruptions, 'startTime'),
  };
}

/**
 * Get the events for the week
 */
export async function getEventsForWeek({
  events,
  companyId,
  locationId,
}) {
  // Baselines to return from the cache
  const cacheWeekEvents = await getEvents({
    events,
  });

  cacheWeekEvents.forEach((event) => {
    event.interrupts.forEach((interrupt) => {
      const interruptDetail = interrupt.detail;
      const interruptLocations = get(interruptDetail, 'locations', []);
      const compareResult = compare(event.locations, interruptLocations);
      // Determine if the interrupt has access to all the locations for this object
      const accessAllLocations = compareResult.parent.missing.length <= 0;
      // Determine if the interrupt has access to this location
      const accessCurrentLocation = accessAllLocations
        || find(interruptLocations, { companyId, locationId }) !== undefined
        || find(interruptLocations, { companyId, locationId: '*' }) !== undefined;

      interrupt.accessAllLocations = accessAllLocations;
      interrupt.accessCurrentLocation = accessCurrentLocation;
    });
  });

  return {
    events: cacheWeekEvents,
  };
}

/**
 * Get the ad programs for the week
 */
export async function getAdProgramsForWeek({
  adProgramItems,
  locationId,
  startOfWeek,
}) {
  // get an array of IDs to fetch (ad program must be within the scheduled week)
  const weekAdProgramIds = adProgramItems.map(adProgramItem => adProgramItem._id);

  // only fetch the ad programs that are within the scheduled week
  const adPrograms = await Promise.all(weekAdProgramIds.map(async (adProgramId) => {
    const adProgram = await API.Location.AdPrograms.get({
      locationId,
      adProgramId,
    });

    return Promise.resolve(adProgram);
  }));

  const sunAdProgram = getAdProgramForDay({
    dayIndex: 0,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  const monAdProgram = getAdProgramForDay({
    dayIndex: 1,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  const tueAdProgram = getAdProgramForDay({
    dayIndex: 2,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  const wedAdProgram = getAdProgramForDay({
    dayIndex: 3,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  const thuAdProgram = getAdProgramForDay({
    dayIndex: 4,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  const friAdProgram = getAdProgramForDay({
    dayIndex: 5,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  const satAdProgram = getAdProgramForDay({
    dayIndex: 6,
    weekAdPrograms: adPrograms,
    startOfWeek,
  });

  return {
    SU: sunAdProgram,
    MO: monAdProgram,
    TU: tueAdProgram,
    WE: wedAdProgram,
    TH: thuAdProgram,
    FR: friAdProgram,
    SA: satAdProgram,
  };
}

export default {
  getCurrentDate,
  getBaselinesForWeek,
  getBaselineContentForWeek,
  getEventsForWeek,
  getAdProgramsForWeek,
};
