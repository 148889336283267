import React from 'react';

const viDataFlow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M12.5 4V15.2C12.5 16.8802 12.5 17.7202 12.827 18.362C13.1146 18.9265 13.5735 19.3854 14.138 19.673C14.7798 20 15.6198 20 17.3 20H17.5M17.5 20C17.5 21.1046 18.3954 22 19.5 22C20.6046 22 21.5 21.1046 21.5 20C21.5 18.8954 20.6046 18 19.5 18C18.3954 18 17.5 18.8954 17.5 20ZM7.5 4L17.5 4M7.5 4C7.5 5.10457 6.60457 6 5.5 6C4.39543 6 3.5 5.10457 3.5 4C3.5 2.89543 4.39543 2 5.5 2C6.60457 2 7.5 2.89543 7.5 4ZM17.5 4C17.5 5.10457 18.3954 6 19.5 6C20.6046 6 21.5 5.10457 21.5 4C21.5 2.89543 20.6046 2 19.5 2C18.3954 2 17.5 2.89543 17.5 4ZM12.5 12H17.5M17.5 12C17.5 13.1046 18.3954 14 19.5 14C20.6046 14 21.5 13.1046 21.5 12C21.5 10.8954 20.6046 10 19.5 10C18.3954 10 17.5 10.8954 17.5 12Z" />
    </g>
  </svg>
);

export default viDataFlow;
