import React from 'react';
import PropTypes from 'prop-types';
import FullObjectSelection from '../FullObjectSelection/FullObjectSelection';
import MessagesUnassigned from './MessagesUnassigned';
import MessagesAssigned from './MessagesAssigned';

function MessageSelection({
  filters,
  selected,
  allowCustomOrder,
  allowChanges,
  customToolbar,
  assigning,
  onChange,
}) {
  if (assigning) {
    return (
      <FullObjectSelection className="MessageSelection">
        <MessagesUnassigned
          selected={selected}
          customToolbar={customToolbar}
          filters={filters}
          onChange={onChange}
        />
      </FullObjectSelection>
    );
  }

  return (
    <FullObjectSelection className="MessageSelection">
      <MessagesAssigned
        selected={selected}
        allowCustomOrder={allowCustomOrder}
        allowChanges={allowChanges}
        customToolbar={customToolbar}
        onChange={onChange}
      />
    </FullObjectSelection>
  );
}

MessageSelection.propTypes = {
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected messages */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Allow custom order of messages in the assigned table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the message selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar for assigned locations */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Selector is assigning messages */
  assigning: PropTypes.bool,
  /** When the messages are changed */
  onChange: PropTypes.func,
};

MessageSelection.defaultProps = {
  filters: null,
  selected: [],
  allowCustomOrder: false,
  allowChanges: false,
  assigning: false,
  customToolbar: null,
  onChange: () => {},
};

export default MessageSelection;
