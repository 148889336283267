import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  color,
} from 'vibeguide';

function OrderContainer() {
  return (
    <div
      className="OrderContainer"
      style={{
        height: '100%',
        backgroundColor: color.white,
        overflow: 'auto',
      }}
    >
      <Outlet />
    </div>
  );
}

export default OrderContainer;
