import React from 'react';
import PropTypes from 'prop-types';
import {
  get,
  isFinite,
} from 'lodash';
import {
  Link,
} from 'react-router-dom';
import {
  PieChart as RePieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
} from 'recharts';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCircleFilled from '../../icons/viCircleFilled';
import color from '../../sass/color.scss';
import './PieChart.scss';

const pieColors = [
  '#416f94',
  '#73b6ae',
  '#6f9a71',
  '#c0ba61',
  '#e28b40',
  '#b53d5a',
  '#c4647e',
  '#6b4f7c',
  '#6d91b0',
  '#557966',
  '#96ce9f',
  '#fab660',
  '#dd6455',
  '#e36b80',
  '#96396f',
  '#8d719c',
];

const renderColorfulLegendText = (value, entry) => {
  const {
    color,
  } = entry;

  const total = get(entry, 'payload.value', 0);

  return (
    <span
      style={{
        fontSize: 10,
        color,
      }}
    >
      {value} ({total})
    </span>
  );
};

const renderLegend = (props) => {
  const {
    title,
    payload,
  } = props;

  return (
    <div className="legend-container">
      <div className="legend-title">
        {title}
      </div>

      <div className="legend-items">
        {payload.map((entry, index) => {
          const url = get(entry, 'payload.url', null);
          const total = get(entry, 'payload.value', '?');
          const entryColor = entry.color || pieColors[index] || color.manatee;

          const totalStr = isFinite(total)
            ? total.toLocaleString()
            : total;

          const itemHtml = (
            <div
              className="legend-item"
              style={{
                color: entryColor,
              }}
            >
              <VibeIcon
                className="item-icon"
                icon={viCircleFilled}
                color={entryColor}
                size={8}
              />

              <div className="item-name">
                {entry.value} ({totalStr})
              </div>
            </div>
          );

          return url ? (
            <Link
              key={`item-${index}`}
              to={url}
            >
              {itemHtml}
            </Link>
          ) : (
            <div key={`item-${index}`}>
              {itemHtml}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function PieChart({
  title,
  width,
  height,
  style,
  data,
  onClick,
}) {
  // use the default colors if none specified
  const pieData = data.map((item, index) => {
    return {
      color: pieColors[index] || color.manatee,
      ...item,
    };
  });

  return (
    <div
      className="PieChart"
      style={style}
    >
      <div className="chart-container">
        <RePieChart
          width={width}
          height={height}
        >
          <Pie
            data={pieData}
            dataKey="value"
            // cx={150}
            cx={64}
            // cy={65}
            innerRadius={42}
            outerRadius={64}
            // paddingAngle={2}
            fill="#82ca9d"
            onClick={onClick}
          >
            {
              pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                />
              ))
            }
          </Pie>

          <Tooltip />

          <Legend
            title={title}
            iconType="circle"
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconSize={10}
            formatter={renderColorfulLegendText}
            content={renderLegend}
          />
        </RePieChart>
      </div>
    </div>
  );
}

PieChart.propTypes = {
  /** Title of chart */
  title: PropTypes.string,
  /** Chart size */
  width: PropTypes.number,
  height: PropTypes.number,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Chart data */
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
  })).isRequired,
  /** When the chart is clicked */
  onClick: PropTypes.func,
};

PieChart.defaultProps = {
  title: null,
  width: 300,
  height: 160,
  style: {},
  onClick: () => {},
};

export default PieChart;
