import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
  orderBy,
  isEmpty,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  Field2 as Field,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeButtonNew,
  VibeIcon,
  viArchive,
  viCopy,
  color,
} from 'vibeguide';
import AdProgramListItem from './AdProgramListItem';
import AdProgramCopy from '../Copy/AdProgramCopy';
import './AdProgramList.scss';

function AdProgramList({
  className,
  user,
  locationId,
  adProgramId,
  adProgram,
  currTime,
  setPanel,
  queueToast,
}) {
  const [adPrograms, setAdPrograms] = useState([]);
  const [confirmTerminate, setConfirmTerminate] = useState(false);
  const [terminateDate, setTerminateDate] = useState('');
  const history = useNavigate();

  const qs = NavigationHelper.getParams() || {};

  /**
   * Get the minimum start date to use for terminating an ad program
   */
  const getTerminateStartDate = () => {
    // use the ad program start date when the current time is before the ad program start date
    if (!confirmTerminate || currTime.isBefore(adProgram.startDate)) {
      return moment(adProgram.startDate);
    }

    return currTime
      .clone()
      .add(1, 'day')
      .startOf('day');
  };

  /**
   * When the copy ad program sidebar is closed
   */
  const onCloseCopy = () => {
    setPanel({
      show: false,
    });
  };

  /**
   * When the Copy button is clicked
   */
  const onClickCopy = () => {
    setPanel({
      // show nothing in the main panel, skip right to the location selection panels
      width: 1,
      show: true,
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <AdProgramCopy
            currTime={currTime}
            locationId={locationId}
            adProgramId={adProgramId}
            onClose={onCloseCopy}
          />
        ),
      },
    });
  };

  /**
   * When the Terminate button is clicked
   */
  const onClickTerminate = () => {
    // reset the termination date before showing the modal
    setTerminateDate('');
    setConfirmTerminate(true);
  };

  /**
   * Confirm terminating the ad program
   */
  const onConfirmTerminate = async () => {
    queueToast({
      type: 'info',
      title: 'Terminating...',
      allowClose: true,
    });

    try {
      const response = await API.Location.AdPrograms.terminate({
        locationId,
        adProgramId,
        effective: terminateDate,
      });

      const success = get(response, '[0].type') === 'LOCATIONADPROGRAM.TERMINATED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Terminated!',
          allowClose: true,
        });

        if (get(response, '[1].type') === 'LOCATIONADPROGRAM.CREATED') {
          // get the terminated ad program end date
          const terminatedAdProgramEndDate = get(response, '[0].type') === 'LOCATIONADPROGRAM.TERMINATED'
            ? get(response, '[0].data.endDate', '')
            : '';
          const createdAdProgram = get(response, '[1].data', {});
          // set the ID before adding it to the adPrograms list
          createdAdProgram._id = get(response, '[1].documentId');

          createdAdProgram.current = currTime.isBetween(
            createdAdProgram.startDate,
            createdAdProgram.endDate,
            undefined,
            '[]',
          );
          createdAdProgram.future = currTime && currTime.isBefore(createdAdProgram.startDate);

          // add the created ad program to the list of ad programs
          setAdPrograms(orderBy([
            ...adPrograms.map(adProgram => {
              if (adProgram._id === adProgramId) {
                // update the terminated ad program with the new end date
                return {
                  ...adProgram,
                  endDate: terminatedAdProgramEndDate,
                };
              }

              return adProgram;
            }),
            createdAdProgram,
          ], 'startDate', 'desc'));
        }
      } else {
        console.error('Error terminating ad program', response);

        queueToast({
          type: 'error',
          title: 'Error Terminating Ad Program',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error terminating ad program', err);

      queueToast({
        type: 'error',
        title: 'Error Terminating Ad Program',
        timeout: 10,
        allowClose: true,
      });
    }

    setConfirmTerminate(false);
  };

  /**
   * Cancel terminating the ad program
   */
  const onCancelTerminate = () => {
    setConfirmTerminate(false);
  };

  /**
   * When the termination date is changed
   */
  const onChangeTerminateDate = (date) => {
    setTerminateDate(date);
  };

  /**
   * When the Save button is clicked
   */
  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = await API.Location.AdPrograms.update({
        locationId,
        adProgramId,
        ...adProgram,
      });

      const success = get(response, '[0].type') === 'LOCATIONADPROGRAM.UPDATED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Saved!!',
          allowClose: true,
        });
      } else {
        console.error('Error saving ad program', response);

        queueToast({
          type: 'error',
          title: 'Error Saving Ad Program',
          timeout: 10,
          allowClose: true,
        });

        history(NavigationHelper.updateParams({
          refresh: true,
        }));

        setTimeout(() => {
          history(NavigationHelper.updateParams({
            refresh: null,
          }));
        }, 0);
      }
    } catch (err) {
      console.error('Error saving ad program', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Ad Program',
        timeout: 10,
        allowClose: true,
      });

      history(NavigationHelper.updateParams({
        refresh: true,
      }));

      setTimeout(() => {
        history(NavigationHelper.updateParams({
          refresh: null,
        }));
      }, 0);
    }
  };

  useEffect(async () => {
    const response = await API.Location.AdPrograms.list({
      locationId,
    });

    response.forEach((adProgram) => {
      adProgram.current = currTime.isBetween(adProgram.startDate, adProgram.endDate, undefined, '[]');
      adProgram.future = currTime && currTime.isBefore(adProgram.startDate);

      if (adProgram.current && !qs.adProgramId) {
        // this is the current ad program, navigate to it if no adProgramId specified
        history(NavigationHelper.updateParams({
          adProgramId: adProgram._id,
        }));
      }
    });

    setAdPrograms(orderBy(response, 'startDate', 'desc'));
  }, [locationId]);

  return (
    <SidePanelContainer className={classNames('AdProgramList', className)}>
      <SidePanelHeader className="sidepanel-header">
        <div className="title">
          Ad Programs
        </div>
      </SidePanelHeader>

      <SidePanelContent className="sidepanel-content">
        {adPrograms.map((adProgram) => (
          <AdProgramListItem
            key={`ad-program-${adProgram._id}`}
            adProgramId={adProgram._id}
            locationId={locationId}
            startDate={adProgram.startDate}
            endDate={adProgram.endDate}
            selected={adProgram._id === adProgramId}
            current={adProgram.current}
            future={adProgram.future}
          />
        ))}
      </SidePanelContent>

      {adProgramId && user.can('location-ad-program.modify') && (
        <SidePanelFooter>
          <VibeButtonNew
            text="Save Changes"
            style={{
              marginRight: 8,
            }}
            color={color.violetVibe}
            disabled={isEmpty(adProgram) || !adProgram.changed}
            onClick={onSave}
          />

          <VibeIcon
            icon={viCopy}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.violetVibe,
              style: {
                marginRight: 8,
              },
            }}
            tooltip="Copy"
            color={color.violetVibe}
            size={20}
            onClick={onClickCopy}
          />

          <VibeIcon
            icon={viArchive}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.fireBrick,
            }}
            tooltip={!adProgram.future && !adProgram.current
              ? 'Only Future Ad Programs can be Terminated'
              : 'Terminate'}
            color={color.fireBrick}
            size={20}
            disabled={!adProgram.future && !adProgram.current}
            onClick={onClickTerminate}
          />
        </SidePanelFooter>
      )}

      <VibeModal
        show={confirmTerminate}
        type="confirm"
        title="Terminate Ad Program"
        text={(
          <div>
            <div
              style={{
                marginBottom: 24,
              }}
            >
              Are you sure you want to terminate this ad program?<br />
              Effective date must be between:

              <div
                style={{
                  marginTop: 8,
                }}
              >
                {getTerminateStartDate().format('YYYY-MM-DD')} - {adProgram.endDate}
              </div>
            </div>

            <Field
              type="datepicker"
              label="Effective Date"
              placeholder="YYYY-MM-DD"
              value={terminateDate}
              dateProps={{
                format: 'YYYY-MM-DD',
                minDate: getTerminateStartDate().toDate(),
                maxDate: new moment(adProgram.endDate).toDate(),
                tooltipDate: terminateDate
                  ? moment(terminateDate)
                    .startOf('day')
                    .format('MMMM DD, YYYY [at] hh:mm:ss A')
                  : '',
              }}
              onChange={onChangeTerminateDate}
              required
            />
          </div>
        )}
        confirmProps={{
          text: 'Terminate',
          color: color.fireBrick,
          disabled: !terminateDate,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmTerminate}
        onClose={onCancelTerminate}
      />
    </SidePanelContainer>
  );
}

AdProgramList.propTypes = {
  className: PropTypes.string,
  locationId: PropTypes.string.isRequired,
  adProgramId: PropTypes.string,
  adProgram: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  currTime: PropTypes.instanceOf(moment).isRequired,
};

AdProgramList.defaultProps = {
  className: '',
  adProgramId: '',
  adProgram: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdProgramList);
