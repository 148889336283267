import React from 'react';

const viChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M16.6 10L12 14.6 7.4 10 6 11.4l6 6 6-6z" />
    </g>
  </svg>
);

export default viChevronDown;
