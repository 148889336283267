import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  getMinutes,
  convertToTwelveHour,
  convertToTwentyFourHour,
} from '../../utils/TimeUtil';
import VibeIcon from '../VibeIcon/VibeIcon';
import viChevronUp from '../../icons/viChevronUp';
import viChevronDown from '../../icons/viChevronDown';
import color from '../../sass/color.scss';
import './TimeField.scss';

class TimeField extends PureComponent {
  constructor(props) {
    super(props);

    const {
      time,
    } = props;

    // Timeout when the interval is set to automatically increment/decrement the time
    this.minuteTimeout = null;
    // Interval for the next/prev minute when arrow clicked
    this.minuteInterval = null;

    this.state = {
      time24: time,
      time12: convertToTwelveHour({ time }),
      hasError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      time,
    } = this.props;

    const {
      time: prevTime,
    } = prevProps;

    if (time !== prevTime) {
      this.setState({
        time24: time,
        time12: convertToTwelveHour({ time }),
      });
    }
  }

  onChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      time12: value,
    });
  };

  onBlur = () => {
    const {
      min,
      max,
      endOfDay,
      onChange,
    } = this.props;

    const {
      time12,
    } = this.state;

    // Convert the 12 hour time to military time
    const data = {
      time12,
      time24: convertToTwentyFourHour({
        time: time12,
        endOfDay,
      }),
      hasError: false,
    };

    const currMinutes = getMinutes(data.time24);
    const minMinutes = getMinutes(min);
    const maxMinutes = getMinutes(max);

    if (max && currMinutes > maxMinutes) {
      // The new time cannot be more than the maximum time
      console.error('Time cannot exceed maximum of', max);
      data.hasError = true;
    } else if (min && currMinutes < minMinutes) {
      // The new time cannot be less than the minimum time
      console.error('Time cannot exceed minimum of', min);
      data.hasError = true;
    }

    onChange(data);
    this.setState(data);
  };

  onMouseDownNext = () => {
    // Go to the next minute
    this.nextMinute();

    // Automatically increment time after 500ms
    this.minuteTimeout = setTimeout(() => {
      // Increment each minute every 100ms
      this.minuteInterval = setInterval(() => {
        this.nextMinute();
      }, 50);
    }, 500);

    // When the user lets go of the handle
    document.addEventListener('mouseup', this.onMouseUp, false);
  };

  onMouseDownPrev = () => {
    // Go to the next minute
    this.prevMinute();

    // Automatically increment time after 500ms
    this.minuteTimeout = setTimeout(() => {
      // Increment each minute every 100ms
      this.minuteInterval = setInterval(() => {
        this.prevMinute();
      }, 50);
    }, 500);

    // When the user lets go of the handle
    document.addEventListener('mouseup', this.onMouseUp, false);
  };

  onMouseUp = () => {
    const {
      onChange,
    } = this.props;

    const {
      time12,
      time24,
      hasError,
    } = this.state;

    clearTimeout(this.minuteTimeout);
    clearInterval(this.minuteInterval);

    onChange({
      time12,
      time24,
      hasError,
    });

    // Remove this event handler after firing
    document.removeEventListener('mouseup', this.onMouseUp, false);
  };

  prevMinute = () => {
    const {
      min,
      max,
    } = this.props;

    const {
      time24,
    } = this.state;

    let hour = parseInt(time24.split(':')[0], 10);
    let minute = parseInt(time24.split(':')[1], 10);

    // Previous minute
    minute -= 1;

    if (minute < 0) {
      // Go to previous hour
      hour -= 1;
      minute = 59;
    }

    const newTime = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;

    if (min) {
      // Get minutes to compare
      const minMinutes = getMinutes(min);
      const currMinutes = getMinutes(newTime);

      if (currMinutes < minMinutes) {
        // The new time cannot be less than the minumum time
        return;
      }
    }

    if (hour < 0) {
      // Do not allow going below midnight
      return;
    }

    const data = {
      time24: newTime,
      time12: convertToTwelveHour({ time: newTime }),
      hasError: false,
    };

    const currMinutes = getMinutes(data.time24);
    const minMinutes = getMinutes(min);
    const maxMinutes = getMinutes(max);

    if (max && currMinutes > maxMinutes) {
      // The new time cannot be more than the maximum time
      console.error('Time cannot exceed maximum of', max);
      data.hasError = true;
    } else if (min && currMinutes < minMinutes) {
      // The new time cannot be less than the minimum time
      console.error('Time cannot exceed minimum of', min);
      data.hasError = true;
    }

    this.setState(data);
  };

  nextMinute = () => {
    const {
      min,
      max,
    } = this.props;

    const {
      time24,
    } = this.state;

    let hour = parseInt(time24.split(':')[0], 10);
    let minute = parseInt(time24.split(':')[1], 10);

    // Next minute
    minute += 1;

    if (minute > 59) {
      // Go to next hour
      hour += 1;
      minute = 0;
    }

    const newTime = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;

    if (max) {
      // Get minutes to compare
      const maxMinutes = getMinutes(max);
      const currMinutes = getMinutes(newTime);

      if (currMinutes > maxMinutes) {
        // The new time cannot be more than the maximum time
        return;
      }
    }

    if (hour >= 24 && minute > 0) {
      return;
      // Do not allow past 12:00am
      // data = {
      //   time24: '24:00',
      //   time12: '12:00am',
      //   hasError: false,
      // };
    }

    const data = {
      time24: newTime,
      time12: convertToTwelveHour({ time: newTime }),
      hasError: false,
    };

    const currMinutes = getMinutes(data.time24);
    const minMinutes = getMinutes(min);
    const maxMinutes = getMinutes(max);

    if (max && currMinutes > maxMinutes) {
      // The new time cannot be more than the maximum time
      console.error('Time cannot exceed maximum of', max);
      data.hasError = true;
    } else if (min && currMinutes < minMinutes) {
      // The new time cannot be less than the minimum time
      console.error('Time cannot exceed minimum of', min);
      data.hasError = true;
    }

    this.setState(data);
  };

  render() {
    const {
      placeholder,
      inputStyle,
      tabIndex,
      disabled,
    } = this.props;

    const {
      time12,
      hasError,
    } = this.state;

    return (
      <div className={classNames('TimeField', { 'has-error': hasError })}>
        <input
          className="time-field-input"
          type="text"
          placeholder={placeholder}
          value={time12}
          style={inputStyle}
          tabIndex={tabIndex}
          disabled={disabled}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />

        {!disabled ? (
          <div className="time-field-controls">
            <VibeIcon
              className="time-field-control control-prev"
              icon={viChevronUp}
              color={color.manatee}
              size={16}
              onMouseDown={this.onMouseDownNext}
            />

            <VibeIcon
              className="time-field-control control-next"
              icon={viChevronDown}
              color={color.manatee}
              size={16}
              onMouseDown={this.onMouseDownPrev}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

TimeField.propTypes = {
  /** Time to show on the field */
  time: PropTypes.string.isRequired,
  /** Placeholder for empty input */
  placeholder: PropTypes.string,
  /** Minimum time this field can be */
  min: PropTypes.string,
  /** Maximum time this field can be */
  max: PropTypes.string,
  /** Use 24 instead of 12 for the end of the day (12:00am = 24:00 vs. 12:00am = 00:00) */
  endOfDay: PropTypes.bool,
  /** Input style */
  inputStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Index for tabbing through fields */
  tabIndex: PropTypes.number,
  /** Disable the field from being editted */
  disabled: PropTypes.bool,
  /** Callback to component with new time */
  onChange: PropTypes.func,
};

TimeField.defaultProps = {
  placeholder: '',
  min: '',
  max: '',
  endOfDay: false,
  inputStyle: {},
  tabIndex: -1,
  disabled: false,
  onChange: () => {},
};

export default TimeField;
