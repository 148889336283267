import React from 'react';
import PropTypes from 'prop-types';
// import toNumber from 'lodash/toNumber';
import {
  color,
  PlayControl,
  VibeButtonNew,
  Field2 as Field,
} from 'vibeguide';
import moment from 'moment';
import './DayPartCreative.scss';

function DayPartCreative({
  index,
  creative,
  totalCreativeWeight,
  onUpdate,
  onRemove,
}) {
  const onDeleteCreative = () => {
    onRemove(creative);
  };

  const onChangeWeightField = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      ...creative,
      [name]: value,
    });
  };

  const duration = moment.duration(creative.durationSeconds, 'seconds');

  return (
    <div className="DayPartCreative">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <div className="creative-title">
          <PlayControl
            id={`creative-${index}`}
            name={creative.name}
            src={creative.url}
            disabled={!creative.url}
            color={color.aquaForest}
            durationSeconds={creative.durationSeconds}
            playText="Play Media"
            pauseText="Pause Media"
          />

          {creative.name}

          <span
            style={{
              marginLeft: 4,
              fontSize: 12,
              color: color.manatee,
            }}
          >
            ({moment.utc(duration.asMilliseconds()).format('mm:ss')})
          </span>
        </div>

        <VibeButtonNew
          text="Delete"
          variant="text"
          color={color.fireBrick}
          onClick={onDeleteCreative}
        />
      </div>

      <div className="creative-weight">
        <div className="weight-label">
          Weight
        </div>
        <Field
          style={{
            width: 65,
          }}
          type="number"
          dataId={`creative-weight-${index}`}
          name="weight"
          placeholder="Add Weight..."
          value={creative.weight}
          onChange={onChangeWeightField}
          numberProps={{
            min: 0,
            max: 1,
            step: 0.1,
          }}
          error={totalCreativeWeight !== 1}
          validation={{
            check: true,
            checkEmpty: true,
            events: [
              'onBlur',
            ],
          }}
          required
        />
      </div>
    </div>
  );
}

DayPartCreative.propTypes = {
  index: PropTypes.number.isRequired,
  creative: PropTypes.object,
  totalCreativeWeight: PropTypes.number,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
};

DayPartCreative.defaultProps = {
  creative: {},
  totalCreativeWeight: 0,
  onUpdate: () => {},
  onRemove: () => {},
};

export default DayPartCreative;
