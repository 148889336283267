import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Avatar,
  VibeIcon,
  viTime,
  viCheck,
  color,
} from 'vibeguide';
import defaultUserImage from '../../../../assets/user.png';
import './ExternalInfoRow.scss';

function ExternalInfoRow({
  className,
  provider,
  value,
  time,
  username,
  imageUrl,
  match,
}) {
  return (
    <div className={classNames('ExternalInfoRow', className)}>
      <div className="provider">
        <div className="label">
          {provider}
        </div>

        <div className="value">
          {value}
        </div>
      </div>

      {match ? (
        <VibeIcon
          className="lookup-icon"
          icon={viCheck}
          color={color.aquaForest}
          size={24}
          tooltip="Matches Current"
        />
      ) : null}

      <VibeIcon
        className="lookup-icon"
        icon={viTime}
        color={color.manatee}
        size={24}
        tooltip={time}
      />

      <Avatar
        imageUrl={imageUrl}
        imageDefault={defaultUserImage}
        text={username}
        tooltipProps={{
          placement: 'bottom',
        }}
      />
    </div>
  );
}

ExternalInfoRow.propTypes = {
  className: PropTypes.string,
  provider: PropTypes.string,
  value: PropTypes.string,
  time: PropTypes.string,
  username: PropTypes.string,
  imageUrl: PropTypes.string,
  /** Does the most recent lookup match the active third party ID */
  match: PropTypes.bool,
};

ExternalInfoRow.defaultProps = {
  className: '',
  provider: '',
  value: '',
  time: '',
  username: '',
  imageUrl: '',
  match: false,
};

export default ExternalInfoRow;
