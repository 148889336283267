// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaselineSchedule .sidepanel-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Programs/Baselines/Sidebar/BaselineSchedule.scss"],"names":[],"mappings":"AAEE;EACE,UAAA;AADJ","sourcesContent":[".BaselineSchedule {\n\n  .sidepanel-content {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
