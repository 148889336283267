import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { get } from 'lodash';
import {
  MimeTypeHelper,
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import {
  AddCircle,
  Add,
  Edit,
} from '@mui/icons-material';
import defaultPlaylistImage from '../../../../assets/default_playlist.png';
import './Information.scss';

const categories = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Blues',
    value: 'blues',
  },
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Children',
    value: 'children',
  },
  {
    label: 'Dance/Electronic',
    value: 'dance/electronic',
  },
  {
    label: 'Holiday',
    value: 'holiday',
  },
  {
    label: 'Instrumental',
    value: 'instrumental',
  },
  {
    label: 'Jazz',
    value: 'jazz',
  },
  {
    label: 'Misc',
    value: 'misc',
  },
  {
    label: 'Pop',
    value: 'pop',
  },
  {
    label: 'Pop/Rock',
    value: 'pop/rock',
  },
  {
    label: 'Rock',
    value: 'rock',
  },
  {
    label: 'Rap',
    value: 'rap',
  },
  {
    label: 'R&B',
    value: 'r&b',
  },
  {
    label: 'Religion',
    value: 'religion',
  },
  {
    label: 'Specialty',
    value: 'specialty',
  },
  {
    label: 'Variety',
    value: 'variety',
  },
  {
    label: 'World',
    value: 'world',
  },
];

class Information extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  /**
   * When the company field is changed
   */
  onSetCompany = ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      companyId: currCompanyId,
      tags,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  onDrop = (acceptedFiles) => {
    const file = get(acceptedFiles, '[0]', false);

    if (file) {
      const {
        onUpdate,
        onUploadImage,
      } = this.props;

      const reader = new FileReader();
      reader.onload = () => {
        const fileAsDataURL = reader.result;
        // Save image data to state to show as a preview
        onUpdate({ imageUrl: fileAsDataURL });
      };

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');

      reader.readAsDataURL(file);

      onUploadImage(file);
    }
  };

  render() {
    const {
      name,
      category,
      description,
      image: playlistImage,
      companyName,
      companyId,
      tags,
      disableInput,
      user,
      isNew,
      isDuplicate,
    } = this.props;

    const canSetCompany = user.sysAdmin;

    return (
      <div className="Information">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={3}
            item
          >
            <div className="playlist-image">
              <Dropzone
                accept={MimeTypeHelper.images}
                multiple={false}
                onDrop={this.onDrop}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getRootProps()}
                      className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                    >
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input {...getInputProps()} />

                      {playlistImage ? (
                        <img src={playlistImage} alt="Playlist Preview" />
                      ) : (
                        <img src={defaultPlaylistImage} alt="Playlist" />
                      )}

                      {isDragActive ? (
                        <div className="icon-drop">
                          <AddCircle className="icon" />
                        </div>
                      ) : null}

                      <div className="icon-container">
                        {isDragActive ? (
                          <Add className="icon" />
                        ) : (
                          <Edit className="icon" />
                        )}
                      </div>
                    </div>
                  );
                }}
              </Dropzone>
            </div>
          </Grid>

          <Grid
            xs={9}
            item
          >
            <Grid
              spacing={2}
              container
            >
              <Grid
                xs={6}
                item
                className="short-item"
              >
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  placeholder="Name"
                  value={name}
                  tabIndex={1}
                  onChange={this.onChange}
                  disabled={disableInput}
                  required
                  autoFocus
                />
              </Grid>

              <Grid
                xs={6}
                item
                className="short-item"
              >
                {canSetCompany ? (
                  <div className="playlist-company">
                    <div className="form-container">
                      <Field
                        type="company"
                        label="Company"
                        name="companyName"
                        value={companyName}
                        tabIndex={2}
                        companyProps={{
                          companyId,
                          companyName,
                        }}
                        disabled={disableInput || (!isNew && !isDuplicate)}
                        onChange={this.onSetCompany}
                        required
                      />
                    </div>
                  </div>
                ) : null}
              </Grid>

              <Grid
                xs={12}
                item
              >
                <Field
                  type="select"
                  label="Genre"
                  name="category"
                  value={category}
                  options={categories}
                  tabIndex={3}
                  onChange={this.onChange}
                  disabled={disableInput}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              className="playlist-description"
              type="textarea"
              label="Description"
              name="description"
              placeholder="Description"
              value={description}
              tabIndex={4}
              onChange={this.onChange}
              disabled={disableInput}
              required
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              type="tags"
              label="Tags"
              name="tags"
              tagProps={{
                companyId,
                tags,
                allowTypes: ['client', 'admin'],
                showAs: 'list',
                assign: user.can('playlist.assign_tag'),
              }}
              disabled={disableInput}
              onChange={this.onChangeTag}
              tabIndex={5}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  isNew: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  onUploadImage: PropTypes.func,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  category: '',
  description: '',
  image: '',
  companyName: '',
  companyId: '',
  tags: [],
  disableInput: false,
  isNew: false,
  isDuplicate: false,
  onUploadImage: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
